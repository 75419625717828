import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const PageAuthorization = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Typography variant="h4" gutterBottom>
        Acesso não autorizado!
      </Typography>
      <Typography variant="body1" paragraph>
        Você não tem permissão para acessar esta página.
      </Typography>
      <Button component={Link} to="/" variant="contained" color="primary">
        Voltar à Página Inicial
      </Button>
    </Box>
  )
}
