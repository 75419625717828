import {
  Box,
  IconButton,
  Tooltip,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Card,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Skeleton,
} from '@mui/material'

import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Context } from '../../context/AuthContext'
import CardDashboard from './components/cardDashboard'
import { Person } from '@mui/icons-material'
import { AiOutlineReload } from 'react-icons/ai'
import {
  getReportPeople,
  getReportPeopleFound,
  getReportPeopleNotFound,
} from '../../services/productService'
import { convertToMeters } from '../../utils/convertToMeters'

const DashboardRTLS = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(subCompany[0].subCompanyId)
  const currentMonth = moment().startOf('month')
  const firstDay = currentMonth.format('YYYY-MM-DD')
  const lastDay = currentMonth.endOf('month').format('YYYY-MM-DD')
  const [startDate, setStartDate] = useState(firstDay)
  const [finishDate, setFinishDate] = useState(lastDay)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [totalReportPeopleFound, setTotalReportPeopleFound] = useState(0)
  const [totalReportPeopleNotFound, setTotalReportPeopleNotFound] = useState(0)
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(false)
  const [loading7, setLoading7] = useState(false)
  const [loading8, setLoading8] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loadingFailed7, setLoadingFailed7] = useState(false)
  const [loadingFailed8, setLoadingFailed8] = useState(false)
  const [loadingFailed2, setLoadingFailed2] = useState(false)

  const getFirstDayOfYear = () => {
    const firstDay = moment().startOf('year')
    return firstDay.format('YYYY-MM-DD')
  }

  const firstDayOfYear = getFirstDayOfYear()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleTotalReportPeopleFound = async () => {
    setLoading7(true)
    setLoading(true)
    setLoadingFailed7(false)

    try {
      const response = await getReportPeopleFound(
        token,
        nameUrl,
        subCompanyId,
        startDate,
        finishDate
      )
      const total = response.data.total
      setTotalReportPeopleFound(total)
      setLoading(false)
      setLoading7(false)
      setLoadingFailed7(false)
      return response.data
    } catch (error) {
      setLoading7(false)
      setLoading(false)
      setLoadingFailed7(true)
      console.error(error)
    }
  }

  const handleTotalReportPeopleNotFound = async () => {
    setLoading(true)
    setLoading8(true)
    setLoadingFailed8(false)

    try {
      const response = await getReportPeopleNotFound(
        token,
        nameUrl,
        subCompanyId,
        startDate,
        finishDate
      )
      const total = response.data.total
      setTotalReportPeopleNotFound(total)
      setLoading(false)
      setLoading8(false)
      setLoadingFailed8(false)
      return response.data
    } catch (error) {
      setLoading(false)
      setLoading8(false)
      setLoadingFailed8(true)
      console.error(error)
    }
  }

  const totalReportPeopleTotal = async () => {
    setLoading(true)
    setLoading2(true)
    setLoadingFailed2(false)

    try {
      const response = await getReportPeople(token, nameUrl, subCompanyId, startDate, finishDate)
      const data = response.data
      setReports(data)
      setLoading(false)
      setLoading2(false)
      setLoadingFailed2(false)
      return response.data
    } catch (error) {
      setLoading(false)
      setLoading2(false)
      setLoadingFailed2(true)
      console.error(error)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      handleTotalReportPeopleFound()
      handleTotalReportPeopleNotFound()
      totalReportPeopleTotal()
    }
  }, [subCompanyId, startDate, finishDate])

  return (
    <Box sx={{ mt: 7.5 }}>
      <Typography variant="h6">Dashboard</Typography>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
        }}
        display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
              Filial
            </InputLabel>
            <Select
              style={{ backgroundColor: '#fff' }}
              onChange={e => setSubCompanyId(e.target.value)}
              name="companySelect"
              sx={{ width: '100%' }}
              value={subCompanyId ? subCompanyId : ''}
              disabled={loading}
            >
              {subCompany &&
                subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
            </Select>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
              Data de criação
            </InputLabel>
            <TextField
              style={{ backgroundColor: '#fff', width: '100%' }}
              variant="outlined"
              value={startDate}
              onChange={values => {
                setStartDate(values.target.value)
              }}
              sx={{ width: '100%' }}
              name="createDate"
              type="date"
              disabled={loading}
              inputProps={{
                min: firstDayOfYear,
                max: finishDate,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
              Data final
            </InputLabel>
            <TextField
              style={{ backgroundColor: '#fff', width: '100%' }}
              variant="outlined"
              value={finishDate}
              disabled={loading}
              onChange={values => setFinishDate(values.target.value)}
              sx={{ width: '100%' }}
              name="endDate"
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {loadingFailed7 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '120px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={handleTotalReportPeopleFound}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <CardDashboard
                subCompanyId={subCompanyId}
                startDate={startDate}
                finishDate={finishDate}
                handleGetDashDash={handleTotalReportPeopleFound}
                loading={loading7}
                title={'Total de pessoas encontradas'}
                Icon={() => (
                  <Box
                    sx={{
                      width: '70px',
                      height: '70px',
                      backgroundColor: 'blue',
                      borderRadius: '50%',
                      padding: '1em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Person style={{ fontSize: '2em', color: '#FFF' }} />
                  </Box>
                )}
                stockValue={totalReportPeopleFound.toLocaleString('pt-BR')}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {loadingFailed8 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '120px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={handleTotalReportPeopleNotFound}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <CardDashboard
                subCompanyId={subCompanyId}
                startDate={startDate}
                finishDate={finishDate}
                title={'Total de pessoas Não encontrados'}
                handleGetDashDash={handleTotalReportPeopleNotFound}
                loading={loading8}
                Icon={() => (
                  <Box
                    sx={{
                      width: '70px',
                      height: '70px',
                      backgroundColor: 'red',
                      borderRadius: '50%',
                      padding: '1em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Person style={{ fontSize: '2em', color: '#FFF' }} />
                  </Box>
                )}
                stockValue={totalReportPeopleNotFound.toLocaleString('pt-BR')}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {loadingFailed2 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '120px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={totalReportPeopleTotal}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <TableContainer
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Table sx={{ width: '100%' }} aria-label="customized table">
                  <TableHead>
                    <TableRow sx={{ bgcolor: '#F4F4F6' }}>
                      <TableCell align="left" width="200px" sx={{ fontWeight: 700 }}>
                        Pessoa
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 700 }}>
                        Serial
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 700 }}>
                        Localidade
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 700 }}>
                        Media em metros
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 700 }}>
                        Tempo de permanência
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 700 }}>
                        Data de inicio
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 700 }}>
                        Data final
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {loading2 ? (
                    Array.from({ length: 10 }).map((_, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {Array.from({ length: 7 }).map((_, colIndex) => (
                          <TableCell key={colIndex}>
                            <Skeleton animation="wave" />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableBody>
                      {reports
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">{row.people}</TableCell>
                            <TableCell align="center">{row.serial}</TableCell>
                            <TableCell align="center">{row.location}</TableCell>
                            <TableCell align="center">{convertToMeters(row.metros, 'm')}</TableCell>
                            <TableCell align="center">{row.totalVisibleTimeInMinutes}</TableCell>
                            <TableCell align="center">
                              {new Date(row.intervalStart).toLocaleString()}
                            </TableCell>
                            <TableCell align="center">
                              {new Date(row.intervalEnd).toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
                <TablePagination
                  labelRowsPerPage={'Itens por página'}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={reports.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            )}
          </Grid>{' '}
        </Grid>
      </Box>
    </Box>
  )
}

export default DashboardRTLS
