import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
} from '@mui/material'
import { Buffer } from 'buffer'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  editPrinterZplService,
  getUniquePrinterZplService,
  getUniqueTemplateLabelService,
  updateTemplateLabelService,
} from '../../../services/configServices'
import { labelaryService } from '../../../services/printerZPLDefaultServices'

const TemplateLabelEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  templateId,
  handleFetchData,
  setExpanded,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const token = localStorage.getItem('token')
  const [name, setName] = useState('')
  const [density, setDensity] = useState('')
  const [height, setHeight] = useState('')
  const [width, setWidth] = useState('')
  const [unity, setUnity] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [zpl, setZpl] = useState('')
  const [active, setActive] = useState(true)
  const [templateLabel, setTemplateLabel] = useState('')
  const [createDate, setCreateDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [templateLabelLab, setTemplateLabelLab] = useState('')
  const [previewOpen, setPreviewOpen] = useState(false)

  const vertical = 'top'
  const horizontal = 'center'

  const editTemplateLabel = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      const template = {
        labelTemplateId: templateId,
        name,
        density,
        height,
        width,
        unity,
        imageUrl,
        zpl,
        active,
      }
      try {
        await updateTemplateLabelService(token, nameUrl, template)
        setSeverity('success')
        setTitle('Modelo de etiqueta editado com sucesso!')
        setLoading(false)
        setEditing(false)
        setExpanded(false)
        setAlerta(true)
        handleFetchData()
      } catch (err) {
        console.log(err)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente.')
        setLoading(false)
        setAlerta(true)
      }
    },
    [templateId, name, density, height, width, unity, imageUrl, zpl, active]
  )

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  const getTemplateLabelZpl = async () => {
    setLoading(true)
    try {
      const response = await getUniqueTemplateLabelService(token, nameUrl, templateId)
      setName(response.data.name)
      setDensity(response.data.density)
      setHeight(response.data.height)
      setWidth(response.data.width)
      setUnity(response.data.unity)
      setImageUrl(response.data.imageUrl)
      setZpl(response.data.zpl)
      setActive(response.data.active)
      setCreateDate(response.data.createDate)
      setSubCompanyId(response.data.subCompanyId)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const cancelEdit = () => {
    setEditing(false)
    setExpanded(false)
  }

  const handleClose = () => {
    setError(false)
    setPreviewOpen(false)
  }

  const handleZplChange = event => {
    const newZpl = event.target.value
    setTemplateLabel(newZpl)
    loadPreviewImage(newZpl)
  }

  useEffect(() => {
    getTemplateLabelZpl()
  }, [templateId])

  useEffect(() => {
    if (templateLabel) {
      loadPreviewImage()
    }
  }, [templateLabel])

  const loadPreviewImage = async () => {
    try {
      const templateLabelLabelary = await labelaryService(templateLabel)
      const base64ImageString = Buffer.from(templateLabelLabelary.data, 'binary').toString('base64')
      setTemplateLabelLab('data:image/png;base64,' + base64ImageString)
    } catch (error) {
      console.error(error)
      setTemplateLabelLab('')
    }
  }

  return (
    <>
      <Modal
        open={previewOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          {templateLabelLab ? (
            <img src={templateLabelLab} alt="Preview" height="500px" />
          ) : (
            'Carregando...'
          )}
        </Box>
      </Modal>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao editar!
          </Alert>
        </Snackbar>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-input-label">Nome</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                variant="outlined"
                type="text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-input-label">Densidade</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={density}
                onChange={values => setDensity(values.target.value)}
                id="outlined-basic"
                variant="outlined"
                type="text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-input-label">Altura</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={height}
                onChange={values => setHeight(values.target.value)}
                id="outlined-basic"
                variant="outlined"
                type="text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-input-label">Largura</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={width}
                onChange={values => setWidth(values.target.value)}
                id="outlined-basic"
                variant="outlined"
                type="text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-input-label">Unidade</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={unity}
                onChange={values => setUnity(values.target.value)}
                id="outlined-basic"
                variant="outlined"
                type="text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-input-label">Imagem</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={imageUrl}
                onChange={values => setImageUrl(values.target.value)}
                id="outlined-basic"
                variant="outlined"
                type="text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-textarea-label">ZPL</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={zpl}
                onChange={values => setZpl(values.target.value)}
                id="outlined-basic"
                variant="outlined"
                type="text"
                multiline
                rows={20}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center', mt: 3 }}
            >
              <InputLabel>Ativo</InputLabel>
              <Checkbox value={active} onChange={() => setActive(!active)} checked={active} />
            </Box>
          </Grid>
        </Grid>
        <Box component="form" onSubmit={editTemplateLabel}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" type="submit">
              Salvar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => cancelEdit(false)}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default TemplateLabelEdit
