import axios from 'axios'

export const getClientGetAll = async (token, nameUrl, subCompanyId) => {
  const response = await axios.get(`/${nameUrl}/api/Client/GetAll?SubCompanyId=${subCompanyId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getClientCode = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Client/GetClient?SubCompanyId=${data.subCompanyId}&CodClient=${data.codClient}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getProductCode = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Product/SearchProductId?CompanyId=${data.companyId}&IdPartnerOther=${data.idPartnerOther}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const uploadProductions = async (token, nameUrl, formData) => {
  var tokenFinal = 'Bearer ' + token

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
      Authorization: tokenFinal,
    },
  }

  try {
    const response = await axios.post(
      `/${nameUrl}/api/MovimentationOrder/UpdateOrders`,
      formData,
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

export const getClient = async (token, nameUrl, subCompanyId) => {
  const response = await axios.get(`/${nameUrl}/api/Client/GetAll?SubCompanyId=${subCompanyId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createClient = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Client/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getClientId = async (token, nameUrl, id) => {
  const response = await axios.get(`/${nameUrl}/api/Client/Get/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const searchClientService = async (token, nameUrl, data) => {
  console.log(data)
  const response = await axios.get(
    `/${nameUrl}/api/Client/SearchClient?SubCompanyId=${data.subCompanyId}&text=${data.search}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const updateClient = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Client/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteClient = async (token, nameUrl, deleId) => {
  const response = await axios.post(`/${nameUrl}/api/Client/Delete`, deleId, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const creatClientListUpdate = async (token, nameUrl, data) => {
  console.log(data, 'datasd')
  const response = await axios.post(`/${nameUrl}/api/Client/Upload`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getProducts = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/Product/GetAllProducts?CompanyId=${data.companyId}&limit=${data.limit}&offset=${data.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
