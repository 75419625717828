import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Help, Save } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { Context } from '../../../../context/AuthContext'
import { createTicket } from '../../../../services/helpdeskService'
import { useNavigate } from 'react-router-dom'
import { useQuill } from 'react-quilljs'
import { getCompanyService } from '../../../../services/admServices'
import {
  getEmployeeService,
  getSubCompanyCompanyIdService,
} from '../../../../services/configServices'

const ValidatorSchema = yup.object().shape({
  subCompanyId: yup.string().required('Campo obrigatório'),
  subject: yup.string().required('Campo obrigatório'),
  typeTicket: yup.string().required('Campo obrigatório'),
  priority: yup.string().required('Campo obrigatório'),
  derpatament: yup.string().required('Campo obrigatório'),
  message: yup.string(),
})

const Validator = yupResolver(ValidatorSchema)

export const TicketCreatePage = ({}) => {
  const formMethods = useForm({
    resolver: Validator,
  })
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    reset,
  } = formMethods
  const token = localStorage.getItem('token')
  const [companyLimit, setCompanyLimit] = useState(500)
  const [companyOffset, setCompanyOffset] = useState(0)
  const [companyOrder, setCompanyOrder] = useState('acs')
  const [subCompanys, setSubCompanys] = useState([])
  const [employees, setEmployees] = useState([])
  const [userId, setUserId] = useState('')
  const [nameEmployee, setNameEmployee] = useState('')
  const navigate = useNavigate()
  const { subCompany, userName, userEmail, nameUrl } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [company, setCompany] = useState([])
  const [companyId, setCompanyId] = useState('')
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const vertical = 'top'
  const horizontal = 'center'
  const [alert, setAlert] = useState(false)

  const handleGetCompany = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getCompanyService(
        token,
        nameUrl,
        companyLimit,
        companyOffset,
        companyOrder
      )
      setCompany(response.data.result)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (subCompany[0].companyId === '23262935-641b-49a5-ae6e-201a3d6a019d') {
      handleGetCompany()
    }
    console.log(subCompany, 'subCompany')
  }, [subCompany])

  const handleGetSubCompany = useCallback(async companyId => {
    setLoading(true)
    setSubCompanyId('')
    try {
      const response = await getSubCompanyCompanyIdService(token, nameUrl, companyId)
      setSubCompanys(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const onSubmit = async form => {
    setLoading(true)
    const data = {
      derpatament: parseInt(form.derpatament),
      priority: parseInt(form.priority),
      subject: form.subject,
      typeTicket: parseInt(form.typeTicket),
      status: 0,
      subCompanyId: form.subCompanyId,
      createUserId: userId,
      movimentationOrderTicketDetailsCollection: [
        {
          message: form.message,
          file: '-',
          createUserId: userId,
          visibility: false,
        },
      ],
    }

    try {
      const response = await createTicket(token, data)

      setTitle(response.data.message)
      setAlert(true)
      setSeverity('success')
      setValue('subCompanyId', '')
      setSubCompanyId('')
      setValue('name', '')
      setValue('email', '')
      setValue('subject', '')
      setValue('typeTicket', '')
      setValue('priority', '')
      setValue('derpatament', '')
      setValue('message', '')
      setSubCompanyId('')
      setCompanyId('')
      reset()
      setLoading(false)
    } catch (error) {
      setSeverity('error')
      setTitle('Erro ao criar, verifique os dados e tente novamente.')
      setLoading(false)
      setAlert(true)
    }
  }

  const handleGetEmployee = async subCompanyId => {
    try {
      const response = await getEmployeeService(token, nameUrl, 500, 0, 'des', subCompanyId)
      setEmployees(response.data.result)
    } catch (error) {
      setSeverity('error')
      setTitle('Erro ao criar, verifique os dados e tente novamente.')
      setLoading(false)
      setAlert(true)
    }
  }

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={8}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alert}
          onClose={e => setAlert(false)}
          key={vertical + horizontal}
        >
          <Alert onClose={e => setAlert(false)} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Criar TIcket
        </Typography>
        <Grid container spacing={2}>
          {subCompany[0].companyId === '23262935-641b-49a5-ae6e-201a3d6a019d' && (
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                <Select
                  {...register('companyId')}
                  name="company"
                  label="Empresa"
                  value={watch('companyId')}
                  onChange={values => {
                    const selectedValue = values.target.value
                    setValue('companyId', selectedValue)
                    const selectedCompany = company.find(item => item.companyId === selectedValue)
                    if (selectedCompany) {
                      const { companyId, segmentId } = selectedCompany
                      handleGetSubCompany(companyId)
                      clearErrors('companyId')
                    }
                  }}
                  error={!!errors.companyId}
                  sx={{ width: '100%' }}
                >
                  {company.map((item, index) => {
                    return (
                      <MenuItem value={item.companyId} key={item.companyId}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                {errors?.subCompanyId?.message && (
                  <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                    {errors?.subCompanyId?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              {subCompany[0].companyId === '23262935-641b-49a5-ae6e-201a3d6a019d' ? (
                <Select
                  {...register('subCompanyId')}
                  defaultValue=""
                  disabled={watch('companyId') === ''}
                  sx={{ width: '100%' }}
                  label="Escolher o Usuário"
                  onChange={e => {
                    setValue('subCompanyId', e.target.value)
                    clearErrors('subCompanyId')
                    handleGetEmployee(e.target.value)
                  }}
                  error={errors?.subCompanyId?.message}
                >
                  {subCompanys.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              ) : (
                <Select
                  {...register('subCompanyId')}
                  defaultValue=""
                  sx={{ width: '100%' }}
                  label="Escolher o Usuário"
                  onChange={e => {
                    setValue('subCompanyId', e.target.value)
                    clearErrors('subCompanyId')
                  }}
                  error={errors?.subCompanyId?.message}
                >
                  {subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}

              {errors?.subCompanyId?.message && (
                <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                  {errors?.subCompanyId?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Select
              {...register('email')}
              defaultValue=""
              sx={{ width: '100%' }}
              label="Escolher o Usuário"
              onChange={e => {
                setValue('email', e.target.value)
                setUserId(e.target.value)
                clearErrors('email')
                const selectedPrinter = employees.find(item => item.userId === e.target.value)

                if (selectedPrinter) {
                  setNameEmployee(selectedPrinter.nameEmployee)
                }
              }}
              error={errors?.email?.message}
            >
              {employees.map((item, index) => {
                return (
                  <MenuItem value={item.userId} key={item.userId}>
                    {item.emailEmployee}
                  </MenuItem>
                )
              })}
            </Select>
            {errors?.email?.message && (
              <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                {errors?.email?.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              onChange={e => {
                setValue('name', e.target.value)
                clearErrors('name')
              }}
              sx={{ width: '100%' }}
              value={nameEmployee}
              disabled
              label="Nome"
              helperText={errors?.name?.message}
              error={errors?.name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              {...register('subject')}
              defaultValue=""
              sx={{ width: '100%' }}
              label="Assunto"
              onChange={e => {
                setValue('subject', e.target.value)
                clearErrors('subject')
              }}
              helperText={errors?.subject?.message}
              error={errors?.subject?.message}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tipo de ticket</InputLabel>
              <Select
                {...register('typeTicket')}
                defaultValue=""
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //value={age}
                label="Age"
                onChange={e => {
                  setValue('typeTicket', e.target.value)
                  clearErrors('typeTicket')
                }}
                error={errors?.typeTicket?.message}
              >
                <MenuItem value={'1'}>Suporte Equipamento</MenuItem>
                <MenuItem value={'2'}>Suporte Sistemas</MenuItem>
                <MenuItem value={'3'}>Manutenção em Equipamentos</MenuItem>
                <MenuItem value={'4'}>Dúvidas</MenuItem>
              </Select>
              {errors?.typeTicket?.message && (
                <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                  {errors?.typeTicket?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Prioridade</InputLabel>
              <Select
                {...register('priority')}
                defaultValue=""
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //value={age}
                label="Age"
                onChange={e => {
                  setValue('priority', e.target.value)
                  clearErrors('priority')
                }}
                error={errors?.priority?.message}
              >
                <MenuItem value={'1'}>Urgente</MenuItem>
                <MenuItem value={'2'}>Emergencial</MenuItem>
                <MenuItem value={'3'}>Normal</MenuItem>
              </Select>
              {errors?.priority?.message && (
                <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                  {errors?.priority?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Departamento</InputLabel>
              <Select
                {...register('derpatament')}
                defaultValue=""
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //value={age}
                label="Age"
                onChange={e => {
                  setValue('derpatament', e.target.value)
                  clearErrors('derpatament')
                }}
                error={errors?.derpatament?.message}
              >
                <MenuItem value={'1'}>Financeiro</MenuItem>
                <MenuItem value={'0'}>Suporte</MenuItem>
              </Select>
              {errors?.derpatament?.message && (
                <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                  {errors?.derpatament?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register('message')}
              defaultValue=""
              sx={{ width: '100%' }}
              id="outlined-multiline-static"
              label="Menssagem"
              multiline
              onChange={e => {
                setValue('message', e.target.value)
              }}
              rows={10}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            mt: 3,
            justifyContent: 'center',
          }}
        >
          <Button variant="contained" type="submit">
            Salvar
          </Button>

          <Button
            variant="contained"
            type="button"
            onClick={() => navigate('/TicketSuport')}
            sx={{ ml: 2 }}
          >
            Voltar
          </Button>
        </Box>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
