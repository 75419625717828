import React, { Component, useContext, useRef } from 'react'
import { useCallback, useEffect, useState } from 'react'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'

import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'
import { createElearningService } from '../../../services/elearningService'
import { Context } from '../../../context/AuthContext'

const ElearningCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const counterRef = useRef()
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [pageName, setPageName] = useState('')
  const [htmlCode, setHtmlCode] = useState('')
  const [loading, setLoading] = useState(false)
  const { quill, quillRef } = useQuill()

  const createElearning = useCallback(async e => {
    e.preventDefault()
    setLoading(true)

    const data = {
      pageName: pageName,
      htmlCode: htmlCode,
    }

    try {
      await createElearningService(token, nameUrl, data)
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSeverity('success')
      setTitle('Elearning criado com sucesso!')
      handleFetchData()
    } catch (err) {
      console.log(err)
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao criar, verifique os dados e tente novamente.')
    }
  }, [])

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setHtmlCode(quillRef.current.firstChild.innerHTML)
      })
    }
  }, [quill])

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createElearning}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={pageName}
                onChange={values => setPageName(values.target.value)}
                id="outlined-basic"
                label="Nome da tela"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel>Editor</InputLabel>
              <Box
                sx={{ width: '100%', height: '350px', overflowX: 'hidden', overflowY: 'scroll' }}
              >
                <Box ref={quillRef} />
              </Box>
            </Box>

            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              marginTop={{ xs: 12, sm: 12, md: 1, lg: 1, xl: 1 }}
            >
              <InputLabel for="">Visualização do código HTML</InputLabel>
              <TextField
                value={htmlCode}
                multiline
                rows={14}
                disabled
                onChange={values => setHtmlCode(values.target.value)}
                type="text"
                placeholder="Insira a Mensagem"
                sx={{
                  resize: 'none',
                  height: '350px',
                  width: '100%',
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                  width: '100%',
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 6, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Cadastrar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ElearningCreate
