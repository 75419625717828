import { useCallback, useContext, useState } from 'react'
import { editTemplateEquipment } from '../../../services/configServices'
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const EquipmentSoldEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  equipmentId,
  handleFetchData,
}) => {
  const [name, setName] = useState('')
  const { nameUrl } = useContext(Context)

  const token = localStorage.getItem('token')
  const [typeEquipment, setTypeEquipment] = useState()
  const [imageUrl, setImageUrl] = useState('')
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)

  const editEquipment = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const equipment = {
        templateEquipmentId: equipmentId,
        name: name,
        typeEquipment: typeEquipment,
        imageUrl: imageUrl,
        active: active,
      }

      try {
        await editTemplateEquipment(token, nameUrl, equipment)
        setSeverity('success')
        setTitle('Equipamento editado com sucesso!')
        setEditing(false)
        setAlerta(true)
        setLoading(false)
        handleFetchData()
      } catch (err) {
        console.log(err)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente.')
        setEditing(false)
        setAlerta(true)
        setLoading(false)
      }
    },
    [typeEquipment, name, imageUrl, active, equipmentId]
  )

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={editEquipment}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: '100%' }}
                value={typeEquipment}
                onChange={values => setTypeEquipment(parseInt(values.target.value))}
                id="outlined-basic"
                label="Tipo do equipamento"
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: '100%' }}
                value={imageUrl}
                onChange={values => setImageUrl(values.target.value)}
                id="outlined-basic"
                label="URL da Imagem"
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Ativo</InputLabel>
              <Checkbox onChange={() => setActive(!active)} checked={active} value={active} />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" type="submit">
              Salvar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => setEditing(false)}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default EquipmentSoldEdit
