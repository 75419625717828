import React, { useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsMap from 'highcharts/modules/map'
import HighchartsReact from 'highcharts-react-official'

HighchartsMap(Highcharts)

const BrazilMapChart = ({ cities }) => {
  useEffect(() => {
    const data = cities.map(item => ({
      state: item.uf,
      cities: [
        {
          name: item.city,
          lat: item.latitude ? parseFloat(item.latitude.replace(',', '.')) : 0,
          lon: item.longitude ? parseFloat(item.longitude.replace(',', '.')) : 0,
          value: item.quantityStore,
        },
      ],
    }))

    const fetchData = async () => {
      try {
        const brazilMapResponse = await fetch(
          'https://raw.githubusercontent.com/codeforamerica/click_that_hood/master/public/data/brazil-states.geojson'
        )
        const brazilMap = await brazilMapResponse.json()

        Highcharts.mapChart('brazilMapChart', {
          chart: {
            map: brazilMap,
            zoom: 0.8,
            backgroundColor: 'transparent',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              type: 'map',
              name: 'Estados',
              showInLegend: false,
              borderColor: '#A0A0A0',
              nullColor: 'rgba(177, 244, 177, 0.5)',

              joinBy: 'hc-key',
              states: {
                hover: {
                  color: '#BADA55',
                },
              },
              dataLabels: {
                enabled: true,
                format: '{point.name}',
              },
              color: 'green',
            },
            {
              type: 'mapbubble',
              name: 'Cidade',
              showInLegend: false,
              data: data.flatMap(stateData =>
                stateData.cities.map(city => ({
                  'hc-key': `${stateData.state}.${city.name.replace(/\s/g, '')}`,
                  lat: city.lat,
                  lon: city.lon,
                  z: city.value,
                  name: city.name,
                  tooltipText: `Cidade: ${city.name}<br>Quantidade de Lojas: ${city.value}`, // Texto personalizado para o tooltip
                }))
              ),
              marker: {
                fillColor: 'rgba(255,0,0,0.5)',
                lineColor: 'rgba(255,0,0,1)',
                lineWidth: 1,
              },
              dataLabels: {
                enabled: true,
                format: '{point.name}',
                crop: false,
                overflow: 'allow',
              },
              tooltip: {
                pointFormat: '{point.tooltipText}',
              },
            },
          ],
          mapNavigation: {
            enabled: true,
            enableMouseWheelZoom: true,
            buttonOptions: {
              align: 'left',
            },
          },
        })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [cities])

  return (
    <div id="brazilMapChart" style={{ width: '100%', height: '500px' }}>
      <HighchartsReact highcharts={Highcharts} constructorType={'mapChart'} />
    </div>
  )
}

export default BrazilMapChart
