import React, { useContext, useRef } from 'react'
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Autocomplete,
  TextField,
  Typography,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'
import { useLocation } from 'react-router-dom'

function SelectSubCompany({ ...props }) {
  const { subCompany } = useContext(Context)
  const hiddenInputRef = useRef(null)

  const sortedSubCompany = [...subCompany].sort((a, b) => a.name.localeCompare(b.name))

  const handleSelectChange = event => {
    props.onChange && props.onChange(event)
    if (hiddenInputRef.current) {
      hiddenInputRef.current.focus()
    }
  }

  const handleAutocompleteChange = (event, newValue) => {
    props.onChange &&
      props.onChange({
        target: {
          name: props.name,
          value: newValue ? newValue.subCompanyId.trim() : '',
        },
      })
    if (hiddenInputRef.current) {
      hiddenInputRef.current.focus()
    }
  }

  return (
    <FormControl sx={{ width: '100%', mt: 2 }}>
      <input
        type="text"
        ref={hiddenInputRef}
        style={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }}
      />

      {sortedSubCompany.length > 5 ? (
        <Autocomplete
          options={sortedSubCompany}
          getOptionLabel={option => option.name.trim()}
          renderInput={params => <TextField {...params} label="Selecione a filial" />}
          onChange={handleAutocompleteChange}
          isOptionEqualToValue={(option, value) => option.subCompanyId === value.subCompanyId}
        />
      ) : (
        <>
          <InputLabel id="demo-simple-select-label">Selecione a filial</InputLabel>
          <Select
            {...props}
            label="Selecione a filial"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={sortedSubCompany.length === 1 ? sortedSubCompany[0].subCompanyId : ''}
            disabled={sortedSubCompany.length === 1}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 400,
                  width: 250,
                },
              },
            }}
            onChange={handleSelectChange}
          >
            {sortedSubCompany.map((item, index) => {
              return (
                <MenuItem value={item.subCompanyId.trim()} key={index}>
                  {item.name.trim()}
                </MenuItem>
              )
            })}
          </Select>
        </>
      )}
    </FormControl>
  )
}

export default SelectSubCompany
