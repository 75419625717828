import React, { useContext } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  createCompanyService,
  getPlanService,
  getSegmentService,
} from '../../../services/admServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
  TablePagination,
  FormControl,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from '@mui/material'

import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useForm } from 'react-hook-form'
import { Context } from '../../../context/AuthContext'
import {
  createOrder,
  getTemplateEquipament,
  getTemplateLabel,
} from '../../../services/helpdeskService'
import { DeleteForever } from '@mui/icons-material'
import { colorTableDefault } from '../../TemplateDefault'
import { consultaCEPService } from '../../../services/api'
import { formatCEP } from '../../../utils/formatCEP'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
]

const ValidatorSchema = yup.object().shape({
  email: yup.string().required('Campo obrigatório').email('Formato de e-mail inválido'),
  confirmEmail: yup
    .string()
    .required('Campo obrigatório')
    .email('Formato de e-mail inválido')
    .oneOf([yup.ref('email'), null], 'Os e-mails precisam ser iguais'),
  password: yup
    .string()
    .required('Campo obrigatório')
    .min(6, 'A senha deve ter pelo menos 6 caracteres'),
  confirmPassword: yup
    .string()
    .required('Campo obrigatório')
    .oneOf([yup.ref('password'), null], 'As senhas precisam ser iguais'),
  name: yup.string().required('Campo obrigatório'),
  cnpj: yup.string().required('Campo obrigatório'),
  contact: yup.string().required('Campo obrigatório'),
  sigla: yup.string().required('Campo obrigatório'),
  cep: yup
    .string()
    .required('Campo obrigatório')
    .transform(originalValue => formatCEP(originalValue)),
  city: yup.string().required('Campo obrigatório'),
  logradouro: yup.string().required('Campo obrigatório'),
  uf: yup.string().required('Campo obrigatório'),
  number: yup.string().required('Campo obrigatório'),
  complement: yup.string(),
  segment: yup.string().required('Campo obrigatório'),
  plan: yup.string().required('Campo obrigatório'),
  equipamentSelected: yup.array().of(yup.string()).required('Campo obrigatório'),
  tagSelected: yup.array().of(yup.string()).required('Campo obrigatório'),
})

const Validator = yupResolver(ValidatorSchema)

const OrderCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const [valueRow, setValueRow] = useState('1')
  const { subCompany, userName, userId, nameUrl } = useContext(Context)

  const formMethods = useForm({
    resolver: Validator,
  })
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    reset,
  } = formMethods
  const token = localStorage.getItem('token')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [planId, setPlanId] = useState('')
  const [plan, setPlan] = useState([])
  const [nameEmployer, setNameEmployer] = useState('')
  const [segmentId, setSegmentId] = useState('')
  const [segment, setSegment] = useState([])
  const [equipamentSelected, setEquipamentSelected] = useState([])
  const [tagSelected, setTagSelected] = useState([])

  const [equipament, setEquipament] = useState([])
  const [tags, setTags] = useState([])
  const [equipamentList, setEquipamentList] = useState([])
  const [tagsList, setTagsList] = useState([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const handleChange = (event, newValue) => {
    setValueRow(newValue)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleChangeSelect = event => {
    const {
      target: { value },
    } = event
    setEquipamentSelected(typeof value === 'string' ? value.split(',') : value)
  }

  const handleChangeTagsSelect = event => {
    const {
      target: { value },
    } = event
    setTagSelected(typeof value === 'string' ? value.split(',') : value)
  }

  const handleGetEquipament = async () => {
    try {
      const response = await getTemplateEquipament(token)
      setEquipament(response.data.result)
      setEquipamentSelected([])
    } catch (error) {}
  }

  const handleGetTags = async () => {
    try {
      const response = await getTemplateLabel(token)
      setTags(response.data.result)
      setTagSelected([])
    } catch (error) {}
  }

  useEffect(() => {
    handleGetEquipament()
    handleGetTags()
  }, [])

  const onSubmit = async form => {
    const data = {
      company: subCompany[0].nameCompany,
      plan: form.plan,
      cnpj: form.cnpj,
      email: form.email,
      address: {
        cep: form.cep,
        city: form.city,
        logradouro: form.logradouro,
        uf: form.uf,
        number: form.number,
        complement: form.complement,
      },
      User: {
        userId: userId,
        name: userName,
      },
      equipament: equipamentList.map(item => ({
        templateEquipmentId: item.templateEquipmentId,
        name: item.name,
        lastUpdate: new Date(),
        status: 1,
      })),
      tags: tagsList.map(item => ({
        labelTemplateId: item.labelTemplateId,
        zpl: item.zpl,
        lastUpdate: new Date(),
        status: 1,
      })),
      createDate: new Date(),
      lastUpdate: new Date(),
    }
    try {
      const response = await createOrder(token, nameUrl, data)
    } catch (error) {}
  }

  const getSegment = useCallback(async () => {
    try {
      const response = await getSegmentService(token, nameUrl, 100, 0, true)
      setSegment(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const getPlan = useCallback(async () => {
    try {
      const response = await getPlanService(token, nameUrl, 100, 0, true)
      setPlan(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const addListEquipament = () => {
    const selectedEquipmentDetails = equipament
      .filter(item => watch('equipamentSelected').includes(item.name))
      .map(({ templateEquipmentId, name, imageUrl }) => ({ templateEquipmentId, name, imageUrl }))
    setEquipamentList(prevList => [...prevList, ...selectedEquipmentDetails])

    setEquipamentSelected([])
  }

  const addListTags = () => {
    const selecteTagsDetails = tags
      .filter(item => watch('tagSelected').includes(item.zpl))
      .map(({ labelTemplateId, zpl, imageUrl }) => ({ labelTemplateId, zpl, imageUrl }))
    setTagsList(prevList => [...prevList, ...selecteTagsDetails])

    setTagSelected([])
  }

  const handleDeleteEquipament = templateEquipmentId => {
    const updatedEquipamentList = [...equipamentList]
    updatedEquipamentList.splice(templateEquipmentId, 1)

    setEquipamentList(updatedEquipamentList)
  }

  const handleDeleteTag = labelTemplateId => {
    const updatedTagList = [...tagsList]
    updatedTagList.splice(labelTemplateId, 1)

    setTagsList(updatedTagList)
  }

  const handleGetCep = async cep => {
    const formattedCep = formatCEP(cep)

    // Verifique se o CEP tem 9 caracteres antes de realizar a chamada
    if (formattedCep.length === 9) {
      setLoading(true)
      try {
        const response = await consultaCEPService(formattedCep)
        setValue('city', response.data.localidade)
        setValue('uf', response.data.uf)
        setValue('logradouro', response.data.logradouro)
        console.log(response.data, 'cep')
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    getSegment()
    getPlan()
  }, [])

  const Tabs = () => {
    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={valueRow}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="form tabs">
              <Tab label="Dados Cadastrais" value="1" />
              <Tab label="Endereço" value="2" />
              <Tab label="Planos" value="3" />
              <Tab label="Equipamentos" value="4" />
              <Tab label="Etiquetas" value="5" />
            </TabList>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TabPanel value="1">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <InputLabel id="demo-simple-select-label">*Email</InputLabel>
                    <TextField
                      {...register('email')}
                      defaultValue=""
                      sx={{ width: '100%' }}
                      helperText={errors?.email?.message}
                      error={!!errors?.email?.message}
                    />
                  </Box>
                  <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
                    <InputLabel id="demo-simple-select-label">*Repetir Email</InputLabel>
                    <TextField
                      {...register('confirmEmail')}
                      defaultValue=""
                      sx={{ width: '100%' }}
                      helperText={errors?.confirmEmail?.message}
                      error={!!errors?.confirmEmail?.message}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <InputLabel id="demo-simple-select-label">*Senha</InputLabel>
                    <TextField
                      {...register('password')}
                      defaultValue=""
                      sx={{ width: '100%' }}
                      helperText={errors?.password?.message}
                      error={!!errors?.password?.message}
                    />
                  </Box>
                  <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
                    <InputLabel id="demo-simple-select-label">*Repetir Senha</InputLabel>
                    <TextField
                      {...register('confirmPassword')}
                      defaultValue=""
                      sx={{ width: '100%' }}
                      helperText={errors?.confirmPassword?.message}
                      error={!!errors?.confirmPassword?.message}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">*Nome</InputLabel>
                  <TextField
                    {...register('name')}
                    defaultValue=""
                    sx={{ width: '100%' }}
                    helperText={errors?.name?.message}
                    error={!!errors?.name?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">*CNPJ</InputLabel>
                  <TextField
                    {...register('cnpj')}
                    defaultValue=""
                    sx={{ width: '100%' }}
                    helperText={errors?.cnpj?.message}
                    error={!!errors?.cnpj?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">*Contato</InputLabel>
                  <TextField
                    {...register('contact')}
                    defaultValue=""
                    sx={{ width: '100%' }}
                    helperText={errors?.contact?.message}
                    error={!!errors?.contact?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">*Sigla</InputLabel>
                  <TextField
                    {...register('sigla')}
                    defaultValue=""
                    sx={{ width: '100%' }}
                    helperText={errors?.sigla?.message}
                    error={!!errors?.sigla?.message}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">*CEP</InputLabel>
                  <TextField
                    {...register('cep')}
                    defaultValue=""
                    sx={{ width: '100%' }}
                    onChange={e => {
                      const formattedCep = formatCEP(e.target.value)
                      setValue('cep', formattedCep)
                      handleGetCep(e.target.value)
                    }}
                    inputProps={{ maxLength: 9 }}
                    helperText={errors?.cep?.message}
                    error={!!errors?.cep?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">Cidade</InputLabel>
                  <TextField
                    {...register('city')}
                    disabled
                    defaultValue=""
                    sx={{ width: '100%' }}
                    helperText={errors?.city?.message}
                    error={!!errors?.city?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                  <TextField
                    {...register('uf')}
                    disabled
                    defaultValue=""
                    sx={{ width: '100%' }}
                    helperText={errors?.uf?.message}
                    error={!!errors?.uf?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">Endereço</InputLabel>
                  <TextField
                    {...register('logradouro')}
                    disabled
                    defaultValue=""
                    sx={{ width: '100%' }}
                    helperText={errors?.logradouro?.message}
                    error={!!errors?.logradouro?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">*Número</InputLabel>
                  <TextField
                    {...register('number')}
                    defaultValue=""
                    sx={{ width: '100%' }}
                    helperText={errors?.number?.message}
                    error={!!errors?.number?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">Complemento</InputLabel>
                  <TextField {...register('complement')} defaultValue="" sx={{ width: '100%' }} />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">*Segmento</InputLabel>
                  <Select
                    {...register('segment')}
                    value={watch('segment') || ''}
                    onChange={event => setValue('segment', event.target.value)}
                    defaultValue=""
                    label="Segmento"
                    error={!!errors?.segment?.message}
                    sx={{ width: '100%' }}
                  >
                    {segment.map((item, index) => {
                      return (
                        <MenuItem value={item.segmentId} key={index}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {errors?.segment?.message && (
                    <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                      {errors?.segment?.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">Plano</InputLabel>
                  <Select
                    {...register('plan')}
                    value={watch('plan') || ''}
                    onChange={event => setValue('plan', event.target.value)}
                    defaultValue=""
                    error={!!errors?.plan?.message}
                    sx={{ width: '100%' }}
                  >
                    {plan.map((item, index) => {
                      return (
                        <MenuItem value={item.plansId} key={index}>
                          {item.plansName}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {errors?.plan?.message && (
                    <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                      {errors?.plan?.message}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="4">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-checkbox-label">Equipamentos</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      {...register('equipamentSelected')}
                      value={watch('equipamentSelected') || []}
                      onChange={event => setValue('equipamentSelected', event.target.value)}
                      error={!!errors?.equipamentSelected?.message}
                      input={<OutlinedInput label="Equipamentos" />}
                      renderValue={selected => selected.join(', ')}
                      MenuProps={{
                        ...MenuProps,
                        onClose: event => {
                          event.stopPropagation()
                        },
                      }}
                    >
                      {equipament.map((item, index) => (
                        <MenuItem key={index} value={item.name}>
                          <Checkbox
                            checked={watch('equipamentSelected')?.includes(item.name) || false}
                          />

                          <img src={item.imageUrl} width="25px" height="25px" />
                          <ListItemText sx={{ ml: 2 }} primary={item.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors?.equipamentSelected?.message && (
                    <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                      {errors?.equipamentSelected?.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    type="button"
                    sx={{ width: '100%', py: 2 }}
                    onClick={addListEquipament}
                  >
                    Adicionar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper} sx={{ mt: '2rem' }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">Imagem</StyledTableCell>
                          <StyledTableCell align="left">Nome</StyledTableCell>
                          <StyledTableCell width="30px"> </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {equipamentList
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="left">
                                {' '}
                                <img
                                  src={row.imageUrl}
                                  width="35px"
                                  height="35px"
                                  style={{ borderRadius: '5px' }}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="left">{row.name}</StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'left',
                                    justifyContent: 'space-between',
                                    width: '80px',
                                  }}
                                >
                                  <Tooltip title="Apagar" arrow>
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteEquipament(row.templateEquipmentId)
                                      }
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <DeleteForever />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={equipamentList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="5">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-checkbox-label">Etiquetas</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      {...register('tagSelected')}
                      value={watch('tagSelected') || []}
                      onChange={event => setValue('tagSelected', event.target.value)}
                      input={<OutlinedInput label="Etiquetas" />}
                      renderValue={selected => selected.join(', ')}
                      MenuProps={{
                        ...MenuProps,
                        onClose: event => {
                          event.stopPropagation()
                        },
                      }}
                    >
                      {tags.map((item, index) => (
                        <MenuItem key={item.labelTemplateId} value={item.zpl}>
                          <Checkbox checked={watch('tagSelected')?.includes(item.zpl) || false} />{' '}
                          <img src={item.imageUrl} width="25px" height="25px" />
                          <ListItemText sx={{ ml: 2 }} primary={item.zpl} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors?.tagSelected?.message && (
                    <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                      {errors?.tagSelected?.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    type="button"
                    sx={{ width: '100%', py: 2 }}
                    onClick={addListTags}
                  >
                    Adicionar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper} sx={{ mt: '2rem' }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">Imagem</StyledTableCell>
                          <StyledTableCell align="left">Nome</StyledTableCell>
                          <StyledTableCell width="30px"> </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tagsList
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="left">
                                <img
                                  src={row.imageUrl}
                                  width="35px"
                                  height="35px"
                                  style={{ borderRadius: '5px' }}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="left">{row.zpl}</StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'left',
                                    justifyContent: 'space-between',
                                    width: '80px',
                                  }}
                                >
                                  <Tooltip title="Apagar" arrow>
                                    <IconButton
                                      onClick={() => handleDeleteTag(row.labelTemplateId)}
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <DeleteForever />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={equipamentList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </Grid>
              </Grid>
            </TabPanel>
            {Object.keys(errors).length > 0 && (
              <Box display="flex" justifyContent="center" my={2}>
                <Typography color="error">*Campos precisam ser preenchidos corretamente</Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                mt: 3,
                justifyContent: 'center',
              }}
            >
              <Button variant="contained" type="submit">
                Salvar
              </Button>

              <Button
                variant="contained"
                type="button"
                onClick={() => setExpanded(false)}
                sx={{ ml: 2 }}
              >
                Cancelar
              </Button>
            </Box>
          </form>
        </TabContext>
      </Box>
    )
  }

  return (
    <>
      <Box>
        <Box>
          <Tabs />
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default OrderCreate
