import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  deleteMovimentationOrderService,
  getOrderPaginationService,
  returnOrderStatus,
} from '../../../services/productService'
import * as XLSX from 'xlsx'
import { RESULTS_PER_PAGE } from '../../../utils/excel'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TransferOrderCreate from '../../Create/TransferOrderCreate'
import TransferOrderItem from '../../Edit/TransferOrderItem'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { useNavigate } from 'react-router-dom'
//icones react-icons
import { AiFillFileExcel } from 'react-icons/ai'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import { returnStatus } from '../../../assets/statusTypes'
import PermissionComponent from '../../../permissions'
import { AcUnitTwoTone, PlayArrow, Visibility } from '@mui/icons-material'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const TransferOrder = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [transferOrder, setTransferOrder] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [transferOrderOrder, setTransferOrderOrder] = useState(false)

  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [viewElearning, setViewElearning] = useState(false)
  const [originalList, setOriginalList] = useState([])

  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [transferOrderEdit, setTransferOrderEdit] = useState()
  const [totalData, setTotalData] = useState(0)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const fetchData = async values => {
    if (!values || values === '') {
      return
    }
    setSubCompanyId(values)
    setLoading(true)
    try {
      const response = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: values ? values : subCompanyId, typeOrder: 5 },
        rowsPerPage,
        page,
        transferOrderOrder
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setTransferOrder([])
      } else {
        setTransferOrder(response.data.result)
        setOriginalList(response.data.result)
        setPageCount(response.data.totalpage + 1)
        setTotalData(response.data.total)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setTitle('Erro no servidor, tente novamente.')
      setAlerta(true)
      setSeverity('error')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId !== '') {
      fetchData()
    }
  }, [page, rowsPerPage])

  useEffect(() => {
    handleFetchData()
  }, [search])

  const handleFetchData = async () => {
    if (subCompanyId) {
      setSearching(true)
      const response = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: subCompanyId, typeOrder: 5 },
        rowsPerPage,
        0,
        transferOrderOrder,
        search
      )
      setTransferOrder(response.data.result)
      setOriginalList(response.data.result)
      setPageCount(response.data.totalpage + 1)
      setPage(0)
      setTotalData(response.data.total)
      setSearching(false)
    }
  }

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    await getOrderPaginationService(
      token,
      nameUrl,
      { subCompanyId, typeOrder: 5 },
      RESULTS_PER_PAGE,
      0,
      transferOrderOrder
    ).then(r => {
      setTotalPagesDownload(r.data.totalpage + 1)
      finishDownload(r.data.totalpage + 1)
    })
  }

  const finishDownload = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessage(`Baixando ${i} de ${pages}...`)
      setProgress((i / pages) * 100)
      const response = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: subCompanyId, typeOrder: 5 },
        RESULTS_PER_PAGE,
        i,
        transferOrderOrder
      )
      result = result.concat(response.data.result)
    }
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        'Ordem Bihands': item.movimentationOrderNumberBihands,
        'Ordem (Outro Sistema)': item.movimentationOrderNumberOtherSystem,
        'Filial Saída': item.subCompanyNameFrom,
        'Filial Entrada': item.subCompanyNameTo,

        Criado: new Date(item.createRequestMovimentationOrderDate).toLocaleString(),
        Status: returnOrderStatus(item.movimentationOrderStatus),
        Recebido: item.active ? 'Sim' : 'Não',
      })
    })
    setProgress(0)
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Pedido de Transferencia')
    XLSX.writeFile(workbook, `Pedido de Transferência.xlsx`)
  }

  useEffect(() => {}, [subCompanyId])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleSelectEdit = id => {
    setTransferOrderEdit(id)
    setExpanded(true)
    setEditing(true)
  }

  const deleteMovimentationOrder = async () => {
    setLoading(true)
    try {
      await deleteMovimentationOrderService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Transferencia deletada com sucesso!')
      setLoading(false)
      setAlerta(true)
      setOpen(false)
      fetchData()
    } catch (err) {
      console.log(err)
      setSeverity('error')
      setTitle('Erro ao deletar, tente novamente.')
      setLoading(false)
      setOpen(false)
      setAlerta(true)
    }
  }

  const handleDelete = id => {
    setIdDelete(id)
    setOpen(true)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Box width={{ xs: '90%', sm: '90%', md: '50%', lg: '50%', xl: '50%' }}>
          <Dialog
            open={downloading}
            keepMounted
            //onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Box
              sx={{
                height: '10rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyConent: 'center',
              }}
              width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
            >
              <DialogTitle>{downloadMessage}</DialogTitle>
              <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
            </Box>
          </Dialog>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'TransferOrder'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Transferências Entre Filiais</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        {/* Criar Transferencia */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editar Transferencia' : 'Cadastrar Transferencia'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="Edit_TransferOrder">
                <TransferOrderItem
                  setEditing={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={fetchData}
                  movimentationOrderId={transferOrderEdit}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="Create_TransferOrder">
                <TransferOrderCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={fetchData}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => deleteMovimentationOrder()}>Sim</Button>
          </DialogActions>
        </Dialog>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            fetchData(e.target.value)
          }}
        />
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            {transferOrder.length > 0 && (
              <Button onClick={downloadData} variant="contained" endIcon={<AiFillFileExcel />}>
                Excel
              </Button>
            )}
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <PermissionComponent role="Create_TransferOrder">
              <Button
                variant="contained"
                endIcon={<AiFillPlusCircle />}
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            variant="outlined"
            placeholder="Procurar por Ordem"
            type="search"
            onInput={e => setSearch(e.target.value)}
          />
          {searching && <Typography>Buscando...</Typography>}
        </Box>

        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell> </StyledTableCell>
                <StyledTableCell align="left">Ordem Bihands</StyledTableCell>
                <StyledTableCell align="left">Ordem (outro sistema)</StyledTableCell>
                <StyledTableCell align="left">Data Recebimento</StyledTableCell>
                <StyledTableCell align="left">Data Solicitação</StyledTableCell>
                <StyledTableCell align="left">Filial Saída</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Recebido</StyledTableCell>
              </TableRow>
            </TableHead>
            {transferOrder.length > 0 && (
              <TableBody>
                {transferOrder.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'left',
                          justifyContent: 'space-between',
                        }}
                      >
                        <PermissionComponent role="">
                          <Tooltip title="Contagem" arrow>
                            <IconButton
                              onClick={() => navigate(`/CountStockDesk/${5}`)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <PlayArrow />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                        <PermissionComponent role="Delete_TransferOrder">
                          <Tooltip title="Excluir" arrow>
                            <IconButton
                              onClick={() => handleDelete(row.movimentationOrderId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                        <PermissionComponent role="">
                          <Tooltip title="Ação" arrow>
                            <IconButton
                              onClick={() =>
                                navigate(`/MovimentationType/${row.movimentationOrderId}`)
                              }
                              sx={{ cursor: 'pointer' }}
                            >
                              <Visibility />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>

                        <PermissionComponent role="Edit_TransferOrder">
                          <Tooltip title="Editar" arrow>
                            <IconButton
                              onClick={() => handleSelectEdit(row.movimentationOrderId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <ModeEditOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </Box>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {row.movimentationOrderNumberBihands}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.movimentationOrderNumberOtherSystem}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {new Date(row.receiveMovimentationOrderDate).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {new Date(row.createRequestMovimentationOrderDate).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.subCompanyNameFrom}</StyledTableCell>
                    <StyledTableCell align="left">
                      {returnStatus(row.movimentationOrderStatus)}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.isReceived ? 'Sim' : 'Não'}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalData}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default TransferOrder
