import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import {
  editIntegrationDefaultService,
  getUniqueIntegrationDefaultService,
} from '../../../services/admServices'
import {
  createIntegrationFieldService,
  deleteIntegrationFieldService,
  getAllIntegrationFieldsService,
} from '../../../services/configServices'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  styled,
  Paper,
  Checkbox,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const IntegrationDefaultEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  intDefaultId,
  handleFetchData,
  setExpanded,
}) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [name, setName] = useState('')
  const [status, setStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [intFields, setIntFields] = useState([])
  const [newField, setNewField] = useState({
    fieldName: 'Escolher',
    value: '',
  })

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const possibleFields = [
    { fieldName: 'Tipo Token', value: 'tokenType' },
    { fieldName: 'Nome', value: 'name' },
    { fieldName: 'E-mail', value: 'email' },
    { fieldName: 'Nome Usuário', value: 'userName' },
    { fieldName: 'Senha', value: 'password' },
    { fieldName: 'Token Acesso', value: 'accessToken' },
    { fieldName: 'Token Cliente', value: 'clientToken' },
    { fieldName: 'Secret Cliente', value: 'clientSecret' },
    { fieldName: 'Refresh Token', value: 'refreshToken' },
    { fieldName: 'Expira em', value: 'expiresIn' },
    { fieldName: 'App ID', value: 'appId' },
  ]

  const getFields = async () => {
    setLoading(true)
    try {
      const response = await getAllIntegrationFieldsService(token, nameUrl, intDefaultId)
      setIntFields(response.data.result)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const createField = async () => {
    const labelFinal = possibleFields.find(x => x.value === newField)
    const data = {
      label: labelFinal.fieldName,
      integrationDefaultId: intDefaultId,
      type: newField,
    }
    try {
      const response = await createIntegrationFieldService(token, nameUrl, data)
      getFields()
    } catch (e) {
      console.log(e)
    }
  }

  const deleteField = async field => {
    const data = { integrationFieldsId: field }
    try {
      const response = await deleteIntegrationFieldService(token, nameUrl, data)
      getFields()
    } catch (e) {
      console.log(e)
    }
  }

  const editIntDefault = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      const intDefaultEdited = {
        integrationDefaultId: intDefaultId,
        name: name,
        urlRedirect: '',
        urlCalback: '',
        urlApi: '',
        scopeList: '',
        active: status,
      }

      try {
        await editIntegrationDefaultService(token, nameUrl, intDefaultEdited)
        setLoading(false)
        setEditing(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Integração editada com sucesso!')
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente.')
      }
    },
    [name, status]
  )

  const getIntDefault = async () => {
    try {
      const response = await getUniqueIntegrationDefaultService(token, nameUrl, intDefaultId)
      setName(response.data.name)
      setStatus(response.data.active)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getIntDefault()
    getFields()
  }, [intDefaultId])

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={editIntDefault}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel>Ativo</InputLabel>
              <Checkbox value={status} onChange={() => setStatus(!status)} checked={status} />
              {status ? 'Sim' : 'Não'}
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel>Adicionar</InputLabel>
              <Select
                sx={{ width: '100%' }}
                value={newField}
                onChange={values => setNewField(values.target.value)}
                id="outlined-basic"
                variant="outlined"
                type="text"
              >
                {possibleFields.map((item, index) => {
                  return (
                    <MenuItem value={item.value} key={index}>
                      {item.fieldName}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              sx={{ mt: 2, alignSelf: 'end' }}
            >
              <Button variant="contained" onClick={createField} sx={{ mt: 1 }}>
                Adicionar
              </Button>
            </Box>
          </Box>

          {intFields.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Table sx={{ minWidth: 300 }} aria-label="customized table">
                <TableBody>
                  {intFields.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center" sx={{ width: 50 }}>
                        <DeleteForeverIcon
                          onClick={() => deleteField(row.integrationFieldsId)}
                          sx={{ cursor: 'pointer' }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.label}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => {
                  setExpanded(false)
                  setEditing(false)
                }}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default IntegrationDefaultEdit
