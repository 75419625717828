import React, { useCallback, useContext } from 'react'
import { useEffect, useState } from 'react'

import { Box, Button, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import { Context } from '../../../context/AuthContext'
import { getCarWeightService } from '../../../services/carWeightServices'
import {
  editMovRouteService,
  getDriverListService,
} from '../../../services/movimentationRouteServices'

const MovimentationRouteEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  movRoute,
  handleFetchData,
  setExpanded,
  setLoading,
}) => {
  const { userId, subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [subCompanyId, setSubCompanyId] = useState(movRoute.subCompanyId)
  const [carWeightId, setCarWeightId] = useState(movRoute.carWeightId)
  const [carWeight, setCarWeight] = useState([])
  const [drivers, setDrivers] = useState([])
  const [driverId, setDriverId] = useState(movRoute.userDriverId)
  const [movimentationOrderRouteShipping, setMovimentationOrderRouteShipping] = useState(
    movRoute.movimentationOrderRouteShipping
  )

  const getCarWeight = useCallback(async () => {
    if (subCompanyId === '') {
      return
    }
    try {
      const data = {
        SubCompanyId: subCompanyId,
        limit: 500,
        offset: 0,
      }

      const response = await getCarWeightService(token, nameUrl, data)
      setCarWeight(response.data.result)
    } catch (e) {
      console.log(e)
    }
  }, [subCompanyId])

  const getDrivers = async () => {
    if (subCompanyId === '') {
      return
    }
    try {
      const response = await getDriverListService(token, nameUrl, subCompanyId)
      setDrivers(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  const editMovimentationRoute = async e => {
    e.preventDefault()
    try {
      const data = {
        movimentationOrderRouteId: movRoute.movimentationOrderRouteId,
        subCompanyId,
        userId,
        userDriverId: driverId,
        carWeightId,
        listMovimentation: movRoute.listMovimentation,
        movimentationOrderRouteShipping,
      }
      setLoading(true)
      await editMovRouteService(token, nameUrl, data)
      setEditing(false)
      handleFetchData()
      setSeverity('success')
      setTitle('Rota editada com sucesso')
      setAlerta(true)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setSeverity('warning')
      setTitle('Erro ao editar rota')
      setAlerta(true)
      setLoading(false)

      console.log(e)
    }
  }

  useEffect(() => {
    getCarWeight()
    getDrivers()
  }, [subCompanyId])

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', width: 1 }}>
      {/* <Typography variant="h6">Editar Rota</Typography> */}

      <Box component="form" sx={{ mt: '2rem', width: 1 }} onSubmit={editMovimentationRoute}>
        <Box
          sx={{ width: '100%', alignItems: 'center', gap: 2, justifyContent: 'center' }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
            <InputLabel id="demo-simple-select-label">Filial</InputLabel>
            <Select
              label="Filial"
              value={subCompanyId}
              onChange={values => {
                setSubCompanyId(values.target.value)
              }}
              sx={{ width: '100%' }}
            >
              {subCompany.map((item, index) => {
                return (
                  <MenuItem value={item.subCompanyId} key={index}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
          <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
            <InputLabel id="demo-simple-select-label">Carro</InputLabel>
            <Select
              label="Carro"
              value={carWeightId}
              onChange={values => {
                setCarWeightId(values.target.value)
              }}
              sx={{ width: '100%' }}
              disabled={!subCompanyId}
            >
              {carWeight.map((item, index) => {
                return (
                  <MenuItem value={item.carWeightId} key={index}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
        </Box>
        <Box
          sx={{ width: '100%', alignItems: 'center', gap: 2, justifyContent: 'center', mt: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
            <InputLabel id="demo-simple-select-label">Motorista</InputLabel>
            <Select
              label="Motorista"
              value={driverId}
              onChange={values => {
                setDriverId(values.target.value)
              }}
              sx={{ width: '100%' }}
              disabled={!subCompanyId}
            >
              {drivers.map((item, index) => {
                return (
                  <MenuItem value={item.userIdDriver} key={index}>
                    {item.driverName}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
          <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
            <InputLabel id="demo-simple-select-label">Número Pedido</InputLabel>
            <TextField
              sx={{ width: '100%' }}
              type="text"
              value={movimentationOrderRouteShipping}
              onChange={values => {
                setMovimentationOrderRouteShipping(values.target.value)
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
          <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
            <Button type="submit" variant="contained" sx={{ width: '100%' }}>
              Salvar
            </Button>
          </Box>
          <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
            <Button
              type="button"
              variant="contained"
              onClick={() => setExpanded(false)}
              sx={{ width: '100%', ml: 1 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MovimentationRouteEdit
