import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { createAlocationStockService } from '../../../services/alocationServices'

const AlocationStockCreate = ({
  expanded,
  setExpanded,
  alerta,
  setAlerta,
  severity,
  setSeverity,
  title,
  setTitle,
  getAlocation,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const token = localStorage.getItem('token')
  const [street, setStreet] = useState('')
  const [address, setAddress] = useState('')
  const [column, setColumn] = useState('')
  const [level, setLevel] = useState('')
  const [serial, setSerial] = useState('')

  const [loading, setLoading] = useState(false)

  const createAlocationStock = async e => {
    e.preventDefault()
    setLoading(true)
    const AlocationStock = {
      street,
      address,
      column,
      level,
      subCompanyId,
      serial,
    }

    try {
      await createAlocationStockService(token, nameUrl, AlocationStock)
      setTitle('Alocação cadastrada com sucesso!')
      setSeverity('success')
      setLoading(false)
      setAlerta(true)
      setExpanded(!expanded)
      setLevel('')
      setStreet('')
      setAddress('')
      setColumn('')
      setSerial('')
      setSubCompanyId('')
      getAlocation()
    } catch (err) {
      console.log(err)
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
      setSeverity('error')
      setLoading(false)
      setAlerta(true)
    }
  }

  return (
    <>
      <Box>
        <Box component="form" onSubmit={createAlocationStock} sx={{ mt: '2rem' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                <Select
                  value={subCompanyId}
                  //sx={{width:'100%'}}
                  placeholder="Selecione a empresa"
                  onChange={values => setSubCompanyId(values.target.value)}
                  sx={{ width: 1 }}
                >
                  <MenuItem disabled selected value="">
                    <em>Filial</em>
                  </MenuItem>
                  {subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Endereço</InputLabel>
                <TextField
                  sx={{ width: 1 }}
                  value={address}
                  id="standard-basic"
                  placeholder="Endereço"
                  //variant="standard"
                  onChange={values => setAddress(values.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Nome da Rua</InputLabel>
                <TextField
                  sx={{ width: 1 }}
                  value={street}
                  id="standard-basic"
                  placeholder="Nome da Rua"
                  //variant="standard"
                  onChange={values => setStreet(values.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Nome da Rua</InputLabel>
                <TextField
                  sx={{ width: 1 }}
                  value={level}
                  id="standard-basic"
                  placeholder="Andar"
                  //variant="standard"
                  onChange={values => setLevel(values.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Nome da Rua</InputLabel>
                <TextField
                  sx={{ width: 1 }}
                  id="standard-basic"
                  placeholder="Coluna"
                  //variant="standard"
                  value={column}
                  onChange={values => setColumn(values.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Nome da Rua</InputLabel>
                <TextField
                  sx={{ width: 1 }}
                  value={serial}
                  id="standard-basic"
                  placeholder="Serial"
                  onChange={values => setSerial(values.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}></Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              mt: '1rem',
            }}
            flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
          >
            <Box
              width={{ xs: '80%', sm: '40%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button variant="contained" sx={{ width: '100%' }} type="submit">
                Salvar
              </Button>
            </Box>
            <Box
              width={{ xs: '80%', sm: '40%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                type="button"
                onClick={() => setExpanded(!expanded)}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default AlocationStockCreate
