import { useContext, useRef, useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ContactSupport } from '@mui/icons-material'
import {
  FormControl,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'
import { Context } from '../../../context/AuthContext'
import { createTicket } from '../../../services/helpdeskService'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    zIndex: '2000',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const ValidatorSchema = yup.object().shape({
  subCompanyId: yup.string().required('Campo obrigatório'),
  subject: yup.string().required('Campo obrigatório'),
  typeTicket: yup.string().required('Campo obrigatório'),
  priority: yup.string().required('Campo obrigatório'),
  derpatament: yup.string().required('Campo obrigatório'),
  message: yup.string(),
})

const Validator = yupResolver(ValidatorSchema)

export const WidgetTicket = () => {
  const formMethods = useForm({
    resolver: Validator,
  })
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    reset,
  } = formMethods
  const [open, setOpen] = useState(false)
  const iconRef = useRef(null)
  const token = localStorage.getItem('token')
  const { subCompany, userName, userEmail, userId, nameUrl } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('')
  const [subCompanyId, setSubCompanyId] = useState('')
  const vertical = 'top'
  const horizontal = 'center'

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setAlerta(false)
  }

  const onSubmit = async form => {
    setLoading(true)
    const data = {
      derpatament: parseInt(form.derpatament),
      priority: parseInt(form.priority),
      subject: form.subject,
      typeTicket: parseInt(form.typeTicket),
      status: 0,
      subCompanyId: form.subCompanyId,
      createUserId: userId,
      movimentationOrderTicketDetailsCollection: [
        {
          message: form.message,
          file: 'b-',
          createUserId: userId,
          visibility: false,
        },
      ],
    }

    try {
      const response = await createTicket(token, nameUrl, data)
      setSeverity('success')
      setTitle(response.data.message)
      setAlerta(true)
      setValue('subCompanyId', '')
      setSubCompanyId('')
      setValue('name', '')
      setValue('email', '')
      setValue('subject', '')
      setValue('typeTicket', '')
      setValue('priority', '')
      setValue('derpatament', '')
      setValue('message', '')
      reset()
      setLoading(false)
      setOpen(false)
    } catch (error) {
      setSeverity('error')
      setTitle('Erro ao editar, verifique os dados e tente novamente.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alerta}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          position: 'fixed',
          bottom: 15,
          right: 15,
          zIndex: '300',
        }}
      >
        <IconButton onClick={handleClickOpen} ref={iconRef}>
          <ContactSupport
            sx={{
              fontSize: '50px',
              bgcolor: '#fff',
              borderRadius: '100%',
              color: '#2359D4',
              '&:hover': {
                transform: 'scale(1.2)',
              },
            }}
          />
        </IconButton>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          ref={iconRef}
        >
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                width: '100%',
                height: '100%',
                zIndex: '4000',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Criar ticket
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                    <Select
                      {...register('subCompanyId')}
                      defaultValue=""
                      sx={{ width: '100%' }}
                      label="Escolher o Usuário"
                      onChange={e => {
                        setValue('subCompanyId', e.target.value)
                        clearErrors('subCompanyId')
                      }}
                      error={errors?.subCompanyId?.message}
                    >
                      {subCompany.map((item, index) => {
                        return (
                          <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                            {item.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    {errors?.subCompanyId?.message && (
                      <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                        {errors?.subCompanyId?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={e => {
                      setValue('name', e.target.value)
                      clearErrors('name')
                    }}
                    sx={{ width: '100%' }}
                    value={userName}
                    disabled
                    label="Nome"
                    helperText={errors?.name?.message}
                    error={errors?.name?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={userEmail}
                    disabled
                    label="Email"
                    onChange={e => {
                      setValue('email', e.target.value)
                      clearErrors('email')
                    }}
                    helperText={errors?.email?.message}
                    error={errors?.email?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('subject')}
                    defaultValue=""
                    sx={{ width: '100%' }}
                    label="Assunto"
                    onChange={e => {
                      setValue('subject', e.target.value)
                      clearErrors('subject')
                    }}
                    helperText={errors?.subject?.message}
                    error={errors?.subject?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Tipo de ticket</InputLabel>
                    <Select
                      {...register('typeTicket')}
                      defaultValue=""
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      //value={age}
                      label="Age"
                      onChange={e => {
                        setValue('typeTicket', e.target.value)
                        clearErrors('typeTicket')
                      }}
                      error={errors?.typeTicket?.message}
                    >
                      <MenuItem value={'1'}>Suporte Equipamento</MenuItem>
                      <MenuItem value={'2'}>Suporte Sistemas</MenuItem>
                      <MenuItem value={'3'}>Manutenção em Equipamentos</MenuItem>
                      <MenuItem value={'4'}>Dúvidas</MenuItem>
                    </Select>
                    {errors?.typeTicket?.message && (
                      <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                        {errors?.typeTicket?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Prioridade</InputLabel>
                    <Select
                      {...register('priority')}
                      defaultValue=""
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      //value={age}
                      label="Age"
                      onChange={e => {
                        setValue('priority', e.target.value)
                        clearErrors('priority')
                      }}
                      error={errors?.priority?.message}
                    >
                      <MenuItem value={'1'}>Urgente</MenuItem>
                      <MenuItem value={'2'}>Emergencial</MenuItem>
                      <MenuItem value={'3'}>Normal</MenuItem>
                    </Select>
                    {errors?.priority?.message && (
                      <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                        {errors?.priority?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Departamento</InputLabel>
                    <Select
                      {...register('derpatament')}
                      defaultValue=""
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      //value={age}
                      label="Age"
                      onChange={e => {
                        setValue('derpatament', e.target.value)
                        clearErrors('derpatament')
                      }}
                      error={errors?.derpatament?.message}
                    >
                      <MenuItem value={'1'}>Financeiro</MenuItem>
                      <MenuItem value={'0'}>Suporte</MenuItem>
                    </Select>
                    {errors?.derpatament?.message && (
                      <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                        {errors?.derpatament?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('message')}
                    defaultValue=""
                    sx={{ width: '100%' }}
                    id="outlined-multiline-static"
                    label="Menssagem"
                    multiline
                    onChange={e => {
                      setValue('message', e.target.value)
                    }}
                    rows={10}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type="submit">
                Salvar
              </Button>
            </DialogActions>
          </Box>
        </BootstrapDialog>
      </Box>
    </>
  )
}
