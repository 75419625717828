import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Autocomplete,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import { useContext, useEffect, useMemo, useState } from 'react'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import { Add, Clear, Help } from '@mui/icons-material'
import { Context } from '../../../context/AuthContext'
import { getCountStockService, getOnlyOrder } from '../../../services/reportServices'
import {
  createAssociationItensList,
  getOrderPaginationService,
  searchProductService,
} from '../../../services/productService'
import { useDebounce } from '../Product/Components/useDebounce'
import ComponentQuantityCreate from './ComponentRfid/Create'
import { getAllMovOrderList, getAllMovOrderType } from '../../../services/movBreakServices'
import { getAllPickingListServiceSearch } from '../../../services/movOrderItemERP'

const typeOrderOptions = [
  { id: 4, typeOrderName: 'Expedição' },
  { id: 6, typeOrderName: 'Contagem de Estoque' },
  { id: 7, typeOrderName: 'Entrada em Estoque' },
  { id: 8, typeOrderName: 'Saída do Estoque' },
  { id: 2, typeOrderName: 'Vendas' },
  { id: 26, typeOrderName: 'Retorno' },
  { id: 27, typeOrderName: 'Rejeito' },
  { id: 11, typeOrderName: 'Conferência de peças' },
  { id: 5, typeOrderName: 'Transferências Entre Filiais' },
  { id: 19, typeOrderName: 'Transferências entre Locações' },
]

export const CountOnline = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const { debounce } = useDebounce(1000, false)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [search, setSearch] = useState('')
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingCount, setLoadingCount] = useState(false)
  const [orderSelect, setOrderSelect] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pagination, setPagination] = useState({})
  const [rowsProducts, setRowsProducts] = useState([])
  const [rowsFilter, setRowsFilter] = useState([])
  const [viewElearning, setViewElearning] = useState(false)
  const [countStock, setCountStock] = useState([])
  const [searchFilter, setSearchFilter] = useState('')
  const [product, setProduct] = useState([])
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [selectedId, setSelectedId] = useState(undefined)
  const [severity, setSeverity] = useState('warning')
  const [count, setCount] = useState(0)
  const [counting, setCounting] = useState(true)
  const [finished, setFinished] = useState(false)
  const [shouldStopCounting, setShouldStopCounting] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [selectedEquipment, setSelectedEquipment] = useState(0)
  const [formattedIP, setFormattedIP] = useState('')
  const [formattedPort, setFormattedPort] = useState('')
  const [products, setProducts] = useState('')
  const [error, setError] = useState('')
  const [typeOrder, setTypeOrder] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [intervalId, setIntervalId] = useState(null)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [productItem, setProductItem] = useState([])
  const [messageError, setMessageError] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const formatIP = ip => {
    if (!ip) return ''

    const numericValue = ip.replace(/[^0-9]/g, '')
    const formattedValue = numericValue.replace(/(\d{3})(?=\d)/g, '$1.')

    return formattedValue
  }

  const formatPort = port => {
    if (!port) return ''

    const numericValue = port.replace(/\D/g, '')
    return numericValue
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  const getOrders = async e => {
    setIsLoading(true)

    const data = {
      SubCompanyId: e,
      limit: 10,
      offset: 0,
      typeOrder: typeOrder,
      order: 'desc',
      text: search,
    }
    setSubCompanyId(e)
    try {
      const response = await getAllPickingListServiceSearch(token, nameUrl, data)
      setOrders(response.data.result)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    console.log(search, ' sadsa')
    if (search !== '') {
      debounce(() => {
        getOrders(subCompanyId)
      })
    }
  }, [search])

  const getCountStock = async () => {
    setIsLoading(true)

    setLoading(true)
    const countStockDTO = {
      subCompanyId: subCompanyId,
      movimentationOrderId: selectedId,
    }
    try {
      const response = await getCountStockService(token, nameUrl, countStockDTO)
      setCountStock(response.data)
      setPagination(response.data)
      setIsLoading(false)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setIsLoading(false)

      console.log(err)
    }
  }

  const handleEquipmentChange = e => {
    const selectedEquipment = e.target.value
    setSelectedEquipment(selectedEquipment)
    if (selectedEquipment === 'MID40') {
      setShowInput(true)
    } else if (selectedEquipment === 1) {
      setShowInput(true)
    } else {
      setShowInput(false)
    }
    setCounting(false)
  }

  useEffect(() => {
    setCounting(false)
  }, [orderSelect, countStock, shouldStopCounting])

  useEffect(() => {
    if (selectedEquipment === 1) {
      setOpenModalCreate(true)
    }
  }, [selectedEquipment])

  const autoCompleteSelectedOptions = useMemo(() => {
    if (!selectedId) return undefined

    const selectedOptions = orders.find(item => item.movimentationOrderId === selectedId)

    return selectedOptions
  }, [selectedId, orders])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning
          pageName={'ReportCountStock'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Leitura online</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <Help onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <ComponentQuantityCreate
          open={openModalCreate}
          subCompanyId={subCompanyId}
          onClose={() => {
            setOpenModalCreate(false)
            setSelectedEquipment(0)
          }}
          product={productItem}
          movimentationOrderNumberOtherSystem={movimentationOrderNumberOtherSystem}
          message={messageError}
          typeOrder={typeOrder}
          onGetCount={() => {
            getCountStock()
          }}
        />
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
            mt: 2,
          }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <FormControl sx={{ width: '100%', mt: 0 }}>
                  <InputLabel id="demo-simple-select-label">Tipo de contagem</InputLabel>
                  <Select
                    value={typeOrder}
                    disabled={isLoading}
                    onChange={event => {
                      setTypeOrder(event.target.value)
                      setSubCompanyId('')
                    }}
                    sx={{ width: '100%' }}
                    label="Tipo de contagem"
                  >
                    {typeOrderOptions.map((item, index) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.typeOrderName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <FormControl sx={{ width: '100%', mt: 0 }}>
                  <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                  <Select
                    value={subCompanyId}
                    disabled={!typeOrder || isLoading}
                    onChange={event => {
                      setSubCompanyId(event.target.value)
                      getOrders(event.target.value)
                    }}
                    sx={{ width: '100%' }}
                    label="Filial"
                  >
                    {subCompany.map((item, index) => (
                      <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/* <FormControl sx={{ width: '100%', mt: 0 }}>
                <InputLabel id="demo-simple-select-label">Selecione o pedido</InputLabel>
                <Select
                  label="Selecione o pedido"
                  value={orderSelect}
                  disabled={!subCompanyId}
                  onChange={handleOrder}
                  sx={{ width: '100%' }}
                >
                  {orders.map((item, index) => {
                    return (
                      <MenuItem value={item.movimentationOrderId} key={index}>
                        {item.movimentationOrderNumberOtherSystem}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl> */}
              <Autocomplete
                openText="Abrir"
                closeText="Fechar"
                clearText="Apagar"
                noOptionsText={isLoading ? 'Carregando...' : 'Nenhum resultado encontrado'}
                loadingText="Carregando..."
                disablePortal
                value={selectedId}
                loading={isLoading}
                onInputChange={(_, newValue) => setSearch(newValue)}
                disabled={!subCompanyId}
                popupIcon={isLoading ? <CircularProgress color="warning" size={20} /> : undefined}
                onChange={(_, newValue) => {
                  setSelectedId(newValue?.movimentationOrderId)
                }}
                onClear={() => {
                  setSelectedId('')
                  setOrders([])
                }}
                options={orders}
                getOptionLabel={option =>
                  option
                    ? `${option.movimentationOrderNumberBihands} - ${
                        option.movimentationOrderNumberOtherSystem || ''
                      }`
                    : ''
                }
                isOptionEqualToValue={(option, value) =>
                  option.movimentationOrderId === value.movimentationOrderId
                }
                renderInput={params => <TextField {...params} label="Pesquisar pedido" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <FormControl sx={{ width: '100%', mt: 0 }}>
                  <InputLabel id="demo-simple-select-label">Escolha o equipamento</InputLabel>
                  <Select
                    value={selectedEquipment}
                    disabled={!selectedId || isLoading}
                    onChange={event => {
                      setSelectedEquipment(event.target.value)
                      handleEquipmentChange(event)
                    }}
                    sx={{ width: '100%' }}
                    label="Selecione o tipo de impressora"
                  >
                    <MenuItem selected value={1}>
                      HID
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Escolha a conexão do equipamento
                </FormLabel>
                <RadioGroup
                  sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedEquipment}
                  onChange={handleEquipmentChange}
                >
                  <FormControlLabel
                    disabled={!orderSelect || counting}
                    value="USB"
                    control={<Radio />}
                    label="USB"
                  />
                  <FormControlLabel
                    disabled={!orderSelect || counting}
                    value="MID40"
                    control={<Radio />}
                    label="MID40"
                  />
                  <FormControlLabel
                    disabled={!orderSelect || counting}
                    value="MID10W"
                    control={<Radio />}
                    label="MID10W"
                  />
                  <FormControlLabel
                    sx={{ mr: 0 }}
                    disabled={!orderSelect || counting}
                    value="HID"
                    control={<Radio />}
                    label="HID"
                  />
                </RadioGroup>
              </FormControl>
            </Grid> */}

            {showInput && (
              <>
                {selectedEquipment === 'MID40' && (
                  <>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        id="outlined-basic"
                        label="IP"
                        variant="outlined"
                        sx={{ width: '100%' }}
                        value={formattedIP}
                        error={!!error}
                        onChange={e => {
                          setFormattedIP(formatIP(e.target.value))
                        }}
                        inputProps={{
                          maxLength: 15,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        id="outlined-basic"
                        label="Porta"
                        variant="outlined"
                        value={formattedPort}
                        error={!!error}
                        onChange={e => {
                          setFormattedPort(formatPort(e.target.value))
                        }}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                  </>
                )}
                {error && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color="error">
                      {error}
                    </Typography>
                  </Grid>
                )}
              </>
            )}
            {/* 
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-start"
                width="100%"
                alignItems="start"
                height="100%"
              >
                <Button
                  sx={{ mr: 2 }}
                  variant="contained"
                  disabled={!orderSelect || counting}
                  onClick={initialize}
                >
                  Iniciar Leitura
                </Button>
                <Button variant="contained" onClick={stopCounting} disabled={!counting}>
                  Parar Leitura
                </Button>
              </Box>
            </Grid> */}

            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-end"
                width="100%"
                alignItems="end"
                height="100%"
              >
                <Button
                  sx={{ mr: 2 }}
                  variant="outlined"
                  startIcon={<Clear />}
                  onClick={() => setCountStock([])}
                  disabled={!selectedId || counting || isLoading}
                >
                  Limpar
                </Button>
                <Button
                  sx={{ mr: 2 }}
                  startIcon={<Add />}
                  variant="contained"
                  onClick={() => setOpenModalCreate(true)}
                  disabled={!selectedId || counting || isLoading}
                >
                  Adicionar Codigo
                </Button>
                {/* <Button
                  variant="contained"
                  onClick={getCountStock}
                  disabled={!orderSelect || counting}
                >
                  Enviar informação
                </Button> */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box display="flex">
                {finished && (
                  <Typography variant="h6" mr={2}>
                    {countStock.length} Peças contadas
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
            <Typography variant="h5">{`Total de produtos: ${countStock.length}`}</Typography>
            <Typography variant="h5">{`Total de itens contados: ${countStock.reduce(
              (total, item) => total + item.quantityPallet,
              0
            )}`}</Typography>
          </Box>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"> Código ERP</StyledTableCell>
                  <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                  <StyledTableCell align="left">Quantidade</StyledTableCell>
                  <StyledTableCell align="left">Cor</StyledTableCell>
                  <StyledTableCell align="left">Tamanho</StyledTableCell>
                  <StyledTableCell align="left">Referencia</StyledTableCell>
                  <StyledTableCell align="left">CNPJ</StyledTableCell>
                  <StyledTableCell align="left">Data da Leitura</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {countStock.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                    <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    <StyledTableCell align="left">{row.quantityPallet}</StyledTableCell>
                    <StyledTableCell align="left">{row.color}</StyledTableCell>
                    <StyledTableCell align="left">{row.size}</StyledTableCell>
                    <StyledTableCell align="left">{row.reference}</StyledTableCell>
                    <StyledTableCell align="left">{row.subCompanyCNPJ}</StyledTableCell>
                    <StyledTableCell align="left">
                      {new Date(row.createDate).toLocaleString()}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={countStock.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}
