import { useContext, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'

const validatorSchema = yup.object().shape({
  des: yup.string().required('Campo obrigatório'),
  var: yup.string().required('Campo obrigatório'),
  caixa: yup.string().required('Campo obrigatório'),
  base: yup.string().required('Campo obrigatório'),
  cliente: yup.string().required('Campo obrigatório'),
  dataProd: yup.string().required('Campo obrigatório'),
})

const NewSampleControlCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
  onClick,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validatorSchema),
  })
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyIdFrom, setSubCompanyIdFrom] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async form => {
    setLoading(true)

    form.id = uuidv4()
    form.observation = null
    form.quem = null
    form.saida = null
    form.devolution = null
    form.dataProd = new Date(form.dataProd).toLocaleDateString('pt-BR')

    setTimeout(() => {
      onClick(form)
      setAlerta(true)
      setSeverity('success')
      setTitle('Registro feito com sucesso')
      setLoading(false)
      setExpanded(false)
      reset()
    }, 1000)
  }

  const formatDate = dateString => {
    if (!dateString) return ''

    const date = new Date(dateString)
    if (isNaN(date.getTime())) return ''

    const formattedDate = date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })

    return formattedDate
  }

  return (
    <>
      <Box>
        <Box sx={{ mt: '2rem' }} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">Selecione a filial</InputLabel>
              <Select
                label="Filial Saída"
                value={subCompanyIdFrom}
                onChange={values => {
                  setSubCompanyIdFrom(values.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">DES:</InputLabel>
              <TextField
                {...register('des')}
                sx={{ width: '100%' }}
                id="standard-basic"
                variant="outlined"
                fullWidth
                type="text"
                error={!!errors.des?.message}
                helperText={errors.des?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">VAR:</InputLabel>
              <TextField
                {...register('var')}
                sx={{ width: '100%' }}
                id="standard-basic"
                variant="outlined"
                fullWidth
                type="text"
                error={!!errors.var?.message}
                helperText={errors.var?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">Caixa</InputLabel>
              <TextField
                {...register('caixa')}
                sx={{ width: '100%' }}
                id="standard-basic"
                variant="outlined"
                fullWidth
                type="text"
                error={!!errors.caixa?.message}
                helperText={errors.caixa?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">Base</InputLabel>
              <TextField
                {...register('base')}
                sx={{ width: '100%' }}
                id="standard-basic"
                variant="outlined"
                fullWidth
                type="text"
                error={!!errors.base?.message}
                helperText={errors.base?.message}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">Cliente</InputLabel>
              <TextField
                {...register('cliente')}
                sx={{ width: '100%' }}
                id="standard-basic"
                variant="outlined"
                fullWidth
                type="text"
                error={!!errors.cliente?.message}
                helperText={errors.cliente?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">Data de Produção</InputLabel>
              <TextField
                {...register('dataProd')}
                sx={{ width: '100%' }}
                id="standard-basic"
                variant="outlined"
                fullWidth
                type="date"
                error={!!errors.dataProd?.message}
                helperText={errors.dataProd?.message}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      `
    </>
  )
}

export default NewSampleControlCreate
