import React, { useContext } from 'react'
import { useCallback, useState } from 'react'
import { createRoleService } from '../../../services/admServices'

import { Backdrop, Box, Button, CircularProgress, TextField } from '@mui/material'
import { Context } from '../../../context/AuthContext'

const RuleCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)

  const createRole = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const role = {
        name: name,
        description: description,
      }

      try {
        const response = await createRoleService(token, nameUrl, role)
        console.log(role, 'createRoles')
        setLoading(false)
        setExpanded(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Regra criada com sucesso!')
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro ao criar regra, verifique os dados e tente novamente.')
      }
    },
    [name, description]
  )

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createRole}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={description}
                onChange={values => setDescription(values.target.value)}
                id="outlined-basic"
                label="Descrição"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default RuleCreate
