import { resetPassword, userLogin } from '../../services/api'
import { useCallback, useContext, useState } from 'react'
import { Link, useHistory, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../context/AuthContext'
import bgImage from '../../assets/b2.jpg'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'

const ResetPassword = ({ setLoading, setTitle, setSeverity, setOpen }) => {
  const { handleLogin, userEmail, nameUrl } = useContext(Context)

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [invalidUser, setInvalideUser] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const navigate = useNavigate()
  const [error, setError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [heaveError, setHeaveErrorMsg] = useState()

  const query = new URLSearchParams(useLocation().search)
  const token = localStorage.getItem('token')
  const email = userEmail

  const resetPass = useCallback(async () => {
    if (password !== confirmPassword) {
      setHeaveErrorMsg('error')
      setError(true)
      setErrorMsg('Senhas não conferem')
      return
    } else {
      const user = {
        token: token,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
      }

      setLoading(true)
      setInvalideUser(false)
      setErrorMsg()
      try {
        const response = await resetPassword(nameUrl, user)
        setSeverity('success')
        setOpen(true)
        setTitle('Senha alterada com sucesso')
        setLoading(false)
      } catch (err) {
        setLoading(false)
        setSeverity('error')
        setOpen(true)
        setTitle('Erro ao alterar a senha, verifique os dados e tente novamente.')
      }
    }
  }, [email, password, confirmPassword])

  const handleClose = () => {
    setError(false)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%' }}>
        <Paper
          elevation={3}
          sx={{
            textAlign: 'center',
            padding: 2,
          }}
        >
          <Typography variant="h5" sx={{ mb: 5 }}>
            Redefinição de senha
          </Typography>
          <Box component="form" sx={{ mt: '2rem' }} onSubmit={resetPass}>
            <Box>
              <TextField
                sx={{ width: '100%' }}
                value={password}
                onChange={values => setPassword(values.target.value)}
                id="outlined-basic"
                label="Senha"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={confirmPassword}
                onChange={values => setConfirmPassword(values.target.value)}
                id="outlined-basic"
                label="Confirmação de senha"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                mt: 3,
                justifyContent: 'space-evenly',
              }}
            >
              <Button type="submit" variant="contained">
                Redefinir a senha
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default ResetPassword
