import { useState, useContext } from 'react'
import {
  uploadProducts,
  uploadVendorClient,
  uploadVendorNewClient,
} from '../../../services/productService'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import Alert from '@mui/material/Alert'
import PropTypes from 'prop-types'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import ViewElearning from '../../ViewElearning'

const ChargerDashboardClient = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [file, setFile] = useState(null)
  const token = localStorage.getItem('token')
  const [severity, setSeverity] = useState()
  const [loading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  let formData = new FormData()
  const [sending, setSending] = useState(false)
  const [progress, setProgress] = useState(0)
  const [viewElearning, setViewElearning] = useState(false)

  const sendFile = async () => {
    setSending(true)
    formData.append('file', file)
    formData.append('SubCompanyId', subCompany[0].subCompanyId)

    try {
      for (let x = 0; x <= 80; x++) {
        setProgress(x)
      }
      const response = await uploadVendorClient(token, nameUrl, formData)
      console.log(response, 'res')
      for (let x = 80; x <= 100; x++) {
        setProgress(x)
      }
      setSeverity('success')
      setSending(false)
    } catch (error) {
      setSeverity('error')
      setSending(false)
    }
  }

  const handleSelectFile = e => {
    //setSeverity('success')
    const types = ['xls', 'xlsx', 'ods']
    const inputType = e.target.files[0].name.split('.').pop()
    const validation = types.filter(type => type === inputType)
    if (validation.length > 0) {
      setFile(e.target.files[0])
    } else {
      setSeverity('error')
    }
  }

  const handleClose = () => {
    setSeverity()
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={severity === 'error'}
          onClose={handleClose}
          //key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Verifique o formato do arquivo, a extensão deve ser xlsx ou xls
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={severity === 'success'}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Arquivo enviado com sucesso
          </Alert>
        </Snackbar>
        <ViewElearning
          pageName={'UploadProducts'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Dialog open={sending} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>Enviando...</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
          </Box>
        </Dialog>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Carga de Cliente</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box
          sx={{ justifyContent: 'space-evenly', w: 1, minWidth: 1 }}
          display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              mt: 2,
              border: '1px solid black',
              width: { xs: 1, md: 0.3 },
              minWidth: { xs: 1, md: 0.3 },
              p: 2,
            }}
          >
            <a
              className="dropzone"
              target="_blank"
              rel="noreferrer"
              href="https://file.bihands.com/template/UploadClient.xlsx"
              download
            >
              <FileDownloadIcon sx={{ fontSize: 100 }} />
            </a>
            <InputLabel>Faça o download do exemplo de carga</InputLabel>
          </Box>
          {/* <Box display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }}>
            <Divider />
          </Box> */}
          <Box
            sx={{ mt: 2, border: { md: '1px solid black' }, p: 2 }}
            width={{ xs: '100%', sm: '100%', md: '65%', lg: '65%', xl: '65%' }}
          >
            <InputLabel>Insira aqui o documento para enviar</InputLabel>
            <TextField
              sx={{ width: '100%', mt: 1 }}
              id="standard-basic"
              //label="Nome"
              variant="standard"
              //value={name}
              onChange={handleSelectFile}
              type="file"
              accept=".xls, .xlsx, .ods"
            />
            <Box
              sx={{
                mt: 4,
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
              <Button onClick={() => sendFile()} variant="contained">
                Enviar
              </Button>
            </Box>
          </Box>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ChargerDashboardClient
