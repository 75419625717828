import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider, Context } from './context/AuthContext'
import Routes from './routes'
import './styles.css'

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes />
      </Router>
    </AuthProvider>
  )
}

export default App
