import React, { useContext } from 'react'
import { useCallback, useState } from 'react'
import { createPlanService } from '../../../services/admServices'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const PlanCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [plansCode, setPlansCode] = useState('')
  const [plansName, setPlansName] = useState('')
  const [roleGroupId, setRoleGroupId] = useState('3fa85f64-5717-4562-b3fc-2c963f66afa6')
  const [status, setStatus] = useState(false)
  const [recurrence, setRecurrence] = useState(false)
  const [urlCheckout, setUrlCheckout] = useState('')
  const [price, setPrice] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const createPlan = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const plan = {
        plansCode: plansCode,
        plansName: plansName,
        active: status,
        roleGroupId: roleGroupId,
        recurrence: recurrence,
        urlCheckout: urlCheckout,
        price: price,
      }

      console.log(plan)

      try {
        const response = await createPlanService(token, nameUrl, plan)
        setExpanded(false)
        setAlerta(true)
        setLoading(false)
        setSeverity('success')
        setTitle('Plano cadastrado com sucesso!')
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro ao criar plano, verifique os dados e tente novamente')
      }
    },
    [plansName, plansCode, roleGroupId, status, recurrence, urlCheckout, price]
  )

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao Criar Plano!
          </Alert>
        </Snackbar>
        <Typography variant="h5" sx={{ mb: 5 }}>
          Criar Plano
        </Typography>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createPlan}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={plansName}
                onChange={values => setPlansName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={plansCode}
                onChange={values => setPlansCode(values.target.value)}
                id="outlined-basic"
                label="Código do Plano"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={urlCheckout}
                onChange={values => setUrlCheckout(values.target.value)}
                id="outlined-basic"
                label="Url Checkout"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={price}
                onChange={values => setPrice(values.target.value)}
                id="outlined-basic"
                label="Preço"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-evenly',
              mt: 2,
              alignItems: 'center',
            }}
          >
            <Box sx={{ mt: 2, width: '45%' }}>
              <InputLabel>Ativo</InputLabel>
              <Checkbox value={status} onChange={() => setStatus(!status)} checked={status} />
              {status ? 'Sim' : 'Não'}
            </Box>
            <Box sx={{ mt: 2, width: '45%' }}>
              <InputLabel>Recorrente</InputLabel>
              <Checkbox
                value={recurrence}
                onChange={() => setRecurrence(!recurrence)}
                checked={recurrence}
              />
              {recurrence ? 'Sim' : 'Não'}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PlanCreate
