import {
  Box,
  IconButton,
  Tooltip,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Card,
  Typography,
} from '@mui/material'
import {
  getDashStockActual,
  getDashStockEntry,
  getDashStockOut,
  getDashStockTopEntry,
  getDashStockTopOut,
  getDashStockTopUserEntry,
  getDashStockTopUserOut,
  getEntryOutMonth,
  getEntryOutYears,
} from '../../services/dashboardServices'
import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Context } from '../../context/AuthContext'
import CardDashboard from './components/cardDashboard'
import BarGrafic from './components/barGrafic'
import { ArrowDownward, ArrowUpward, Inventory } from '@mui/icons-material'
import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import BarGraficEmploye from './components/barGraficEmploye'
import BarGraficProduct from './components/barGraficProduct'
import TopUserOutList from './components/topUserOutList'
import TotalEntryOutMonthsList from './components/totalEntryOutMonthsList'
import TotalEntryOutYearList from './components/totalEntryOutYearList'
import TopEntryList from './components/topEntryList'
import TopOutListt from './components/topOutListt'
import TopUserEntryList from './components/topUserEntryList'
import { AiOutlineReload } from 'react-icons/ai'

const DasboardStock = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(subCompany[0].subCompanyId)
  const currentMonth = moment().startOf('month')
  const firstDay = currentMonth.format('YYYY-MM-DD')
  const lastDay = currentMonth.endOf('month').format('YYYY-MM-DD')
  const [startDate, setStartDate] = useState(firstDay)
  const [finishDate, setFinishDate] = useState(lastDay)
  const [totalEntryOutMonthsList, setTotalEntryOutMonthsList] = useState([])
  const [totalEntryOutYearList, setTotalEntryOutYearList] = useState([])
  const [topEntryList, setTopEntryList] = useState([])
  const [topOutList, setTopOutList] = useState([])
  const [topUserEntryList, setTopUserEntryList] = useState([])
  const [topUserOutList, setTopUserOutList] = useState([])

  const [quantityStockTotal, setQuantityStockTotal] = useState(0)
  const [quantityStockTotalEntry, setQuantityStockTotalEntry] = useState(0)
  const [quantityStockTotalOut, setQuantityStockTotalOut] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loading3, setLoading3] = useState(false)
  const [loading4, setLoading4] = useState(false)
  const [loading5, setLoading5] = useState(false)
  const [loading6, setLoading6] = useState(false)
  const [loading7, setLoading7] = useState(false)
  const [loading8, setLoading8] = useState(false)
  const [loading9, setLoading9] = useState(false)
  const [loadingFailed1, setLoadingFailed1] = useState(false)
  const [loadingFailed2, setLoadingFailed2] = useState(false)
  const [loadingFailed3, setLoadingFailed3] = useState(false)
  const [loadingFailed4, setLoadingFailed4] = useState(false)
  const [loadingFailed5, setLoadingFailed5] = useState(false)
  const [loadingFailed6, setLoadingFailed6] = useState(false)
  const [loadingFailed7, setLoadingFailed7] = useState(false)
  const [loadingFailed8, setLoadingFailed8] = useState(false)
  const [loadingFailed9, setLoadingFailed9] = useState(false)
  const getFirstDayOfYear = () => {
    const firstDay = moment().startOf('year')
    return firstDay.format('YYYY-MM-DD')
  }

  const firstDayOfYear = getFirstDayOfYear()

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const convertToMonthName = dateString => {
    const date = parse(dateString, 'MM/yyyy', new Date())
    const monthName = format(date, 'MMMM', { locale: ptBR })
    const year = format(date, 'yyyy')
    return `${capitalizeFirstLetter(monthName)}/${year}`
  }

  const totalStock = async () => {
    setLoading7(true)
    setLoading(true)
    setLoadingFailed7(false)
    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }

    try {
      const response = await getDashStockActual(token, nameUrl, data)
      const total = response.data[0].quantityTotal
      setQuantityStockTotal(total)
      setLoading(false)
      setLoading7(false)
      setLoadingFailed7(false)
      return response.data
    } catch (error) {
      setLoading7(false)
      setLoading(false)
      setLoadingFailed7(true)
      console.error(error)
    }
  }

  const totalStockEntry = async () => {
    setLoading(true)
    setLoading8(true)
    setLoadingFailed8(false)

    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }

    try {
      const response = await getDashStockEntry(token, nameUrl, data)
      const total = response.data[0].quantityTotal
      setQuantityStockTotalEntry(total)
      setLoading(false)
      setLoading8(false)
      setLoadingFailed8(false)
      return response.data
    } catch (error) {
      setLoading(false)
      setLoading8(false)
      setLoadingFailed8(true)
      console.error(error)
    }
  }

  const totalStockOut = async () => {
    setLoading(true)
    setLoading9(true)
    setLoadingFailed9(false)
    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }

    try {
      const response = await getDashStockOut(token, nameUrl, data)
      const total = response.data[0].quantityTotal
      setQuantityStockTotalOut(total)
      setLoading(false)
      setLoading9(false)
      setLoadingFailed9(false)
      return response.data
    } catch (error) {
      setLoading(false)
      setLoading9(false)
      setLoadingFailed9(true)
      console.error(error)
    }
  }

  const totalEntryOutMonths = async subCompanyId => {
    setLoading1(true)
    setLoading(true)
    setLoadingFailed1(false)

    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }

    try {
      const response = await getEntryOutMonth(token, nameUrl, data)

      const reversedResponse = response.data.reverse()

      const dataWithMonthNames = reversedResponse.map(item => ({
        ...item,
        dateAndDay: convertToMonthName(item.dateAndDay),
      }))
      dataWithMonthNames.sort((a, b) => {
        const monthA = new Date(a.dateAndDay).getMonth()
        const monthB = new Date(b.dateAndDay).getMonth()
        return monthA - monthB
      })
      dataWithMonthNames.sort((a, b) => a.months - b.months)
      setTotalEntryOutMonthsList(dataWithMonthNames)
      setLoading1(false)
      setLoading(false)
      setLoadingFailed1(false)
    } catch (error) {
      setLoading(false)
      setLoading1(false)
      setLoadingFailed1(true)
      console.error(error)
    }
  }

  const totalEntryOutYear = async subCompanyId => {
    setLoading(true)
    setLoading2(true)
    setLoadingFailed2(false)

    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }
    try {
      const response = await getEntryOutYears(token, nameUrl, data)

      const reversedResponse = response.data.reverse()

      const dataWithMonthNames = reversedResponse.map(item => ({
        ...item,
        dateAndDay: convertToMonthName(item.dateAndDay),
      }))

      dataWithMonthNames.sort((a, b) => a.months - b.months)

      setTotalEntryOutYearList(dataWithMonthNames)
      setLoading2(false)
      setLoading(false)
      setLoadingFailed2(false)
    } catch (error) {
      setLoading(false)
      setLoading2(false)
      setLoadingFailed2(true)
      console.error(error)
    }
  }

  const topEntry = async subCompanyId => {
    setLoading(true)
    setLoading3(true)
    setLoadingFailed3(false)

    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }
    try {
      const response = await getDashStockTopEntry(token, nameUrl, data)
      const dataWithMonthNames = response.data.map(item => ({
        ...item,
        dateAndDay: convertToMonthName(item.dateAndDay),
      }))
      setTopEntryList(dataWithMonthNames)
      setLoading3(false)
      setLoading(false)
      setLoadingFailed3(false)
    } catch (error) {
      setLoading(false)
      setLoading3(false)
      setLoadingFailed3(true)
      console.error(error)
    }
  }

  const topOut = async subCompanyId => {
    setLoading(true)
    setLoading4(true)
    setLoadingFailed4(false)

    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }
    try {
      const response = await getDashStockTopOut(token, nameUrl, data)
      const dataWithMonthNames = response.data.map(item => ({
        ...item,
        dateAndDay: convertToMonthName(item.dateAndDay),
      }))

      const dataList = dataWithMonthNames.map((item, index) => ({
        name: item.name,
        dateAndDay: item.dateAndDay,
        quantityOut: item.quantityTotal,
      }))
      setTopOutList(dataList)
      setLoading4(false)
      setLoading(false)
      setLoadingFailed4(false)
    } catch (error) {
      setLoading(false)
      setLoading4(false)
      setLoadingFailed4(true)
      console.error(error)
    }
  }

  const topUserEntry = async subCompanyId => {
    setLoading(true)
    setLoading5(true)
    setLoadingFailed5(false)

    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
    }
    try {
      const response = await getDashStockTopUserEntry(token, nameUrl, data)
      const dataWithMonthNames = response.data.map(item => ({
        ...item,
        dateAndDay: convertToMonthName(item.dateAndDay),
        nameEmploye: item.name2Out || '',
      }))
      const sortedData = dataWithMonthNames.sort((a, b) => b.quantity - a.quantity)

      setTopUserEntryList(sortedData)
      setLoading5(false)
      setLoading(false)
      setLoadingFailed5(false)
    } catch (error) {
      setLoading(false)
      setLoading5(false)
      setLoadingFailed5(true)
      console.error(error)
    }
  }

  const topUserOut = async subCompanyId => {
    setLoading(true)
    setLoading6(true)
    setLoadingFailed6(false)

    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
    }

    try {
      const response = await getDashStockTopUserOut(token, nameUrl, data)
      const dataWithMonthNames = response.data.map(item => ({
        ...item,
        dateAndDay: convertToMonthName(item.dateAndDay),
        nameEmploye: item.name || '',
      }))

      const sortedData = dataWithMonthNames.sort((a, b) => b.quantity - a.quantity)

      setTopUserOutList(sortedData)
      setLoading6(false)
      setLoading(false)
      setLoadingFailed6(false)
    } catch (error) {
      setLoading6(false)
      setLoading(false)
      setLoadingFailed6(true)
      console.error(error)
    }
  }

  const handleFuncions = async subCompanyId => {
    totalEntryOutMonths(subCompanyId)
    totalEntryOutYear(subCompanyId)
    topEntry(subCompanyId)
    topOut(subCompanyId)
    topUserEntry(subCompanyId)
    topUserOut(subCompanyId)
  }

  useEffect(() => {
    if (subCompanyId) {
      handleFuncions(subCompanyId)
    }
  }, [subCompanyId, startDate, finishDate])

  return (
    <Box sx={{ mt: 7.5 }}>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
        }}
        display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
              Filial
            </InputLabel>
            <Select
              style={{ backgroundColor: '#fff' }}
              onChange={e => setSubCompanyId(e.target.value)}
              name="companySelect"
              sx={{ width: '100%' }}
              value={subCompanyId ? subCompanyId : ''}
              disabled={loading}
            >
              {subCompany &&
                subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
            </Select>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
              Data de criação
            </InputLabel>
            <TextField
              style={{ backgroundColor: '#fff', width: '100%' }}
              variant="outlined"
              value={startDate}
              onChange={values => {
                setStartDate(values.target.value)
              }}
              sx={{ width: '100%' }}
              name="createDate"
              type="date"
              disabled={loading}
              inputProps={{
                min: firstDayOfYear,
                max: finishDate,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
              Data final
            </InputLabel>
            <TextField
              style={{ backgroundColor: '#fff', width: '100%' }}
              variant="outlined"
              value={finishDate}
              disabled={loading}
              onChange={values => setFinishDate(values.target.value)}
              sx={{ width: '100%' }}
              name="endDate"
              type="date"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            {loadingFailed7 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '120px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={totalStock}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <CardDashboard
                subCompanyId={subCompanyId}
                startDate={startDate}
                finishDate={finishDate}
                handleGetDashDash={totalStock}
                loading={loading7}
                title={'Total Estoque'}
                Icon={() => (
                  <Box
                    sx={{
                      width: '70px',
                      height: '70px',
                      backgroundColor: 'blue',
                      borderRadius: '50%',
                      padding: '1em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Inventory style={{ fontSize: '2em', color: '#FFF' }} />
                  </Box>
                )}
                stockValue={quantityStockTotal.toLocaleString('pt-BR')}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {loadingFailed8 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '120px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={totalStockEntry}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <CardDashboard
                subCompanyId={subCompanyId}
                startDate={startDate}
                finishDate={finishDate}
                title={'Total Entradas'}
                handleGetDashDash={totalStockEntry}
                loading={loading8}
                Icon={() => (
                  <Box
                    sx={{
                      width: '70px',
                      height: '70px',
                      backgroundColor: 'green',
                      borderRadius: '50%',
                      padding: '1em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ArrowUpward style={{ fontSize: '2em', color: '#FFF' }} />
                  </Box>
                )}
                stockValue={quantityStockTotalEntry.toLocaleString('pt-BR')}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {loadingFailed9 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '120px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={totalStockOut}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <CardDashboard
                subCompanyId={subCompanyId}
                startDate={startDate}
                finishDate={finishDate}
                title={'Total Saídas'}
                handleGetDashDash={totalStockOut}
                loading={loading9}
                Icon={() => (
                  <Box
                    sx={{
                      width: '70px',
                      height: '70px',
                      backgroundColor: 'red',
                      borderRadius: '50%',
                      padding: '1em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ArrowDownward style={{ fontSize: '2em', color: '#FFF' }} />
                  </Box>
                )}
                stockValue={quantityStockTotalOut.toLocaleString('pt-BR')}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {loadingFailed1 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '500px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={() => totalEntryOutMonths(subCompanyId)}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <TotalEntryOutMonthsList
                subCompanyId={subCompanyId}
                startDate={startDate}
                finishDate={finishDate}
                getGrafic={totalEntryOutMonthsList}
                loading={loading1}
                title={'Entrada e saída no mês'}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {loadingFailed2 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '500px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={() => totalEntryOutYear(subCompanyId)}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <TotalEntryOutYearList
                subCompanyId={subCompanyId}
                startDate={startDate}
                finishDate={finishDate}
                getGrafic={totalEntryOutYearList}
                loading={loading2}
                title={'Entrada e Saída no ano'}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {loadingFailed3 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '500px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={() => topEntry(subCompanyId)}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <TopEntryList
                subCompanyId={subCompanyId}
                startDate={startDate}
                finishDate={finishDate}
                getGrafic={topEntryList}
                loading={loading3}
                title={'Top 10 entradas de produtos'}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {loadingFailed4 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '500px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={() => topOut(subCompanyId)}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <TopOutListt
                subCompanyId={subCompanyId}
                startDate={startDate}
                finishDate={finishDate}
                getGrafic={topOutList}
                loading={loading4}
                title={'Top 10 saídas de produtos'}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {loadingFailed5 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '500px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={() => topUserEntry(subCompanyId)}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <TopUserEntryList
                subCompanyId={subCompanyId}
                startDate={startDate}
                finishDate={finishDate}
                getGrafic={topUserEntryList}
                loading={loading5}
                title={'Top 10 Colaboradores que deram mais entrada no mês'}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {loadingFailed6 ? (
              <Card
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '500px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Tente novamente">
                  <Box textAlign="center">
                    <IconButton onClick={() => topUserOut(subCompanyId)}>
                      <AiOutlineReload />
                    </IconButton>
                    <Typography>Tente Novamente</Typography>
                  </Box>
                </Tooltip>
              </Card>
            ) : (
              <TopUserOutList
                subCompanyId={subCompanyId}
                startDate={startDate}
                finishDate={finishDate}
                getGrafic={topUserOutList}
                loading={loading6}
                title={'Top 10 Colaboradores que deram mais Saida no mês'}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default DasboardStock
