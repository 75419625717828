import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { v4 as uuidv4 } from 'uuid'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Modal,
  Snackbar,
} from '@mui/material'

import {
  labelaryPDFService,
  labelaryService,
  updatePrintTag,
} from '../../../services/printerZPLDefaultServices'
import { AiFillPlusCircle } from 'react-icons/ai'
import { searchProductService } from '../../../services/productService'
import { useNavigate } from 'react-router-dom'
import { updatesPrintTag } from '../../../services/admServices'

const PrintTagSerialCreate = ({
  productName,
  serialProduct,
  setAlerta2,
  setSeverity2,
  setTitle2,
  productItensId,
  movimentationOrderItemId,
  fetch,
  setReloadItems,
  setMvimentationOrderId,
  zplId,
  allSelectedIds,
  selectedCheckboxes,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [zplPdf, setZplPdf] = useState('')
  const [listZplPdf, setListZplPdf] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [zplLab, setZplLab] = useState('')
  const [loadingPrev, setLoadingPrev] = useState('')
  const [nf, setNf] = useState('')
  const [search, setSearch] = useState('')
  const [produtos, setProdutos] = useState([])
  const [searching, setSearching] = useState(false)

  const vertical = 'top'
  const horizontal = 'center'

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  useEffect(() => {}, [zplId])

  const visualizarZPL = async () => {
    setPreviewOpen(true)
    setLoadingPrev(true)

    try {
      let mtags = ''
      const batchSize = 3 // Tamanho máximo de cada lote
      const batchIds = allSelectedIds.slice(0, batchSize) // Pega o primeiro lote de IDs

      batchIds.forEach((item, index) => {
        mtags += `
          ^FT${item.positionTag}^BQN,2,4
          ^FH^FDLA,${item.serial}^FS
          ^FPH,3^FT${item.positionProd}^A0N,11,10^FH^CI28^FD${item.productName}^FS^CI27
          ^FPH,3^FT${item.positionSeria}^A0N,11,10^FH^CI28^FD${item.serial}^FS^CI27
        `
      })

      const formattedZpl = zplId.replace(/{MTAG}/g, mtags)
      const zplLabelary = await labelaryService(formattedZpl)

      if (zplLabelary && zplLabelary.data) {
        const base64ImageString = btoa(
          new Uint8Array(zplLabelary.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )
        setZplLab('data:image/png;base64,' + base64ImageString)
      } else {
        setZplLab('')
      }
    } catch (error) {
      console.error(error)
      setZplLab('')
    } finally {
      setLoadingPrev(true)
    }
  }

  useEffect(() => {
    console.log(allSelectedIds, 'selectedCheckboxes')
  }, [allSelectedIds])

  const loadPrintTag = async () => {
    if (zplId && allSelectedIds.length > 0) {
      setLoadingPrev(true)
      let formattedZpl2 = zplId
      const batchSize = 3 // Tamanho máximo de cada lote
      const batches = []

      for (let i = 0; i < allSelectedIds.length; i += batchSize) {
        const batchIds = allSelectedIds.slice(i, i + batchSize)
        let mtags = ''

        batchIds.forEach((item, index) => {
          mtags += `
            ^FT${item.positionTag}^BQN,2,4
            ^FH^FDLA,${item.serial}^FS
            ^FPH,3^FT${item.positionProd}^A0N,11,10^FH^CI28^FD${item.productName}^FS^CI27
            ^FPH,3^FT${item.positionSeria}^A0N,11,10^FH^CI28^FD${item.serial}^FS^CI27
          `
        })

        const batchZpl = formattedZpl2.replace(/{MTAG}/g, mtags)
        batches.push(batchZpl)
      }
      setListZplPdf(batches)
      for (const batchZpl of batches) {
        await loadPreviewImage(batchZpl)
        setZplPdf(batchZpl)
      }
    }
  }

  useEffect(() => {
    loadPrintTag()
  }, [
    productName,
    setMvimentationOrderId,
    serialProduct,
    movimentationOrderItemId,
    productItensId,
    zplId,
    allSelectedIds,
  ])

  const handleClose = () => {
    setError(false)
    setPreviewOpen(false)
  }

  const loadPreviewImage = async value => {
    try {
      const zplLabelary = await labelaryService(value)
      if (zplLabelary && zplLabelary.data) {
        const base64ImageString = btoa(
          String.fromCharCode.apply(null, new Uint8Array(zplLabelary.data))
        )
        setZplLab('data:image/png;base64,' + base64ImageString)
      } else {
        setZplLab('')
      }
    } catch (error) {
      console.error(error)
      setZplLab('')
    }
  }

  const handlePrintStatus = async () => {
    try {
      for (const item of allSelectedIds) {
        const dataUpdate = {
          productItensId: item.productItensId,
          movimentationOrderItensId: item.movimentationOrderItemId,
        }
        const responseData = await updatesPrintTag(token, nameUrl, dataUpdate)
        setTitle2(responseData.data)
        fetch(item.movimentationOrderId)
      }
    } catch (error) {}
  }

  const convertZplToPdf = async () => {
    setLoading(true)
    setReloadItems(true)
    try {
      for (const item of listZplPdf) {
        const response = await labelaryPDFService(item)

        setAlerta2(true)
        setSeverity2('success')
        if (response && response.data) {
          const pdfBlob = new Blob([response.data])

          const url = URL.createObjectURL(pdfBlob)
          const a = document.createElement('a')
          a.href = url
          a.download = `${uuidv4()}.pdf`
          a.click()

          URL.revokeObjectURL(url)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
    handlePrintStatus()
  }

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  return (
    <>
      <Modal
        open={previewOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          {zplLab ? <img src={zplLab} alt="Preview" height="500px" /> : 'Carregando...'}
        </Box>
      </Modal>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao criar!
          </Alert>
        </Snackbar>
        <Box component="form" sx={{ mt: '2rem' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button
              disabled={
                (!zplId && allSelectedIds.length === 0) || (zplId && allSelectedIds.length === 0)
              }
              variant="contained"
              endIcon={<AiFillPlusCircle />}
              onClick={convertZplToPdf}
            >
              Salvar
            </Button>
            <Button
              disabled={
                (!zplId && allSelectedIds.length === 0) || (zplId && allSelectedIds.length === 0)
              }
              variant="contained"
              type="button"
              onClick={() => visualizarZPL()}
              sx={{ ml: 2 }}
            >
              Pré-visualizar
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => navigate('/PrintTagSerial')}
              sx={{ ml: 2 }}
            >
              Voltar
            </Button>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box width="100%" height={500} overflow="hidden">
              <InputLabel id="demo-simple-image-label">Pré-Visualizar</InputLabel>
              <Box
                width="100%"
                height={500}
                overflow="hidden"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {loadingPrev ? (
                  <img
                    src={zplLab}
                    alt="Preview"
                    height="100%"
                    style={{ cursor: 'pointer' }}
                    onClick={() => visualizarZPL()}
                  />
                ) : (
                  'Selecione um item'
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PrintTagSerialCreate
