import { useContext, useEffect, useState } from 'react'
import ScaleIcon from '@mui/icons-material/Scale'
import HelpIcon from '@mui/icons-material/Help'
import {
  Alert,
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

//icones react-icons
import { AiFillPlusCircle } from 'react-icons/ai'
import '../../../../assets/global.css'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { blue, grey, red } from '@mui/material/colors'
import { useParams } from 'react-router-dom'
import {
  deleteMovOrderRouteService,
  editMovRouteService,
  getAllNFService,
  getMovimentationOrderRouteService,
  releaseMovRouteService,
} from '../../../../services/movimentationRouteServices'
import RouteCard from './card'
import RouteMap from './map'
import AddRoute from './createRoute'
import SaveIcon from '@mui/icons-material/Save'
import { Edit, Save } from '@mui/icons-material'
import { Context } from '../../../../context/AuthContext'

const RouteEditor = () => {
  const alfabeto = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const [rotas, setRotas] = useState([])
  const { nameUrl } = useContext(Context)

  const [movRoute, setMovRoute] = useState({})
  const [mapScreen, setMapScreen] = useState({ latitude: false })
  const [geral, setGeral] = useState(true)
  const [nfList, setNfList] = useState([])
  const [loadingNF, setLoadingNF] = useState(false)

  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [creating, setCreating] = useState(false)
  const [changeRoute, setChangeRoute] = useState(true)

  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [title, setTitle] = useState('')
  const [alerta, setAlerta] = useState(false)
  const [totalKg, setTotalKg] = useState(0)
  const vertical = 'top'
  const horizontal = 'center'

  const { id, subCompanyId } = useParams()

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const getAllNF = async () => {
    setLoadingNF(true)
    const data = {
      subCompanyId,
      limit: 10,
      offset: 0,
    }
    const response = await getAllNFService(token, nameUrl, data)
    setNfList(response.data.result)
    setLoadingNF(false)
  }

  useEffect(() => {
    if (subCompanyId.length > 2) {
      getAllNF()
    }
  }, [subCompanyId])

  const changeNF = async item => {
    const rotasOrdenadas = item.listMovimentation.sort((a, b) => a.sequence - b.sequence)
    setRotas(rotasOrdenadas)
    setMovRoute(item)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteMovOrderRouteService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Entrada deletada com sucesso!')
      setAlerta(true)
      setLoading(false)
      getRouteId()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const resetScreen = () => {
    // navigate(0)
    getRouteId()
  }

  useEffect(() => {
    getRouteId()
  }, [])

  const salvarOrdem = async () => {
    setLoading(true)
    const rotaFinal = rotas
    rotaFinal.map((x, i) => {
      x.sequence = i + 1
    })
    const keysToKeep = ['movimentationOrderId', 'sequence']
    const redux = array =>
      array.map(o =>
        keysToKeep.reduce((acc, curr) => {
          acc[curr] = o[curr]
          return acc
        }, {})
      )
    const finalList = redux(rotaFinal)
    const dataFinal = {
      movimentationOrderRouteId: movRoute.movimentationOrderRouteId,
      subCompanyId: movRoute.subCompanyId,
      userId: movRoute.userId,
      userDriverId: movRoute.userDriverId,
      carWeightId: movRoute.carWeightId,
      listMovimentation: finalList,
    }
    try {
      const response = await editMovRouteService(token, nameUrl, dataFinal)
      setTitle(response.data.message)
      setSeverity('success')
      setAlerta(true)
      getRouteId()
    } catch (e) {
      console.log(e)
      setLoading(false)
      setTitle('Erro ao alterar a rota')
      setSeverity('warning')
      setAlerta(true)
    }
  }

  const realeaseOrdem = async () => {
    setLoading(true)
    try {
      await salvarOrdem()
      const response = await releaseMovRouteService(
        token,
        nameUrl,
        movRoute.movimentationOrderRouteId
      )
      setTitle(response.data.message)
      setSeverity('success')
      setAlerta(true)
      getRouteId()
    } catch (e) {
      console.log(e)
      setLoading(false)
      setTitle('Erro ao alterar a rota')
      setSeverity('warning')
      setAlerta(true)
    }
  }

  const getRouteId = async () => {
    setLoading(true)
    try {
      const response = await getMovimentationOrderRouteService(token, nameUrl, { routeId: id })
      const rotasOrdenadas = response.data.listMovimentation.sort((a, b) => a.sequence - b.sequence)
      setRotas(rotasOrdenadas)
      setMovRoute(response.data)
      setTotalKg(response.data.reduce((accumulator, current) => accumulator + current.netWeight, 0))
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleDragEnd = (result, provided) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    setRotas(prev => {
      const temp = [...prev]
      const d = temp[result.destination.index]
      temp[result.destination.index] = temp[result.source.index]
      temp[result.source.index] = d
      setGeral(true)
      setChangeRoute(true)
      // setTimeout(() => setChangeRoute(false))
      return temp
    })
  }

  const StrictModeDroppable = ({ children, ...props }) => {
    const [enabled, setEnabled] = useState(false)
    useEffect(() => {
      const animation = requestAnimationFrame(() => setEnabled(true))
      return () => {
        cancelAnimationFrame(animation)
        setEnabled(false)
      }
    }, [])
    if (!enabled) {
      return null
    }
    return <Droppable {...props}>{children}</Droppable>
  }

  return (
    <>
      <Box mt={{ xs: 9, sm: 9, md: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Editor de Remessa</Typography>

          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            width: 1,
            gap: 4,
          }}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          alignItems={{ xs: 'center', sm: 'center', md: 'flex-start' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              border: '1px solid black',
              borderRadius: 1,
              p: 2,
              gap: 2,
            }}
            width={{ xs: 1, sm: 1, md: 0.3 }}
          >
            <RouteCard
              totalKg={totalKg}
              movRoute={movRoute}
              setGeral={setGeral}
              setMapScreen={setMapScreen}
            />
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  // sx={{ backgroundColor: '#000000' }}
                  endIcon={<AiFillPlusCircle />}
                  onClick={() => {
                    setCreating(true)
                    setMapScreen({ latitude: false })
                  }}
                >
                  Adicionar Remessa
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  endIcon={<Save />}
                  onClick={salvarOrdem}
                >
                  Salvar
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  disabled={rotas < 1}
                  endIcon={<SaveIcon />}
                  onClick={realeaseOrdem}
                >
                  Liberar Roteiro
                </Button>
              </Grid>
            </Grid>
            {/* <Box sx={{ width: 1 }}>
              <Autocomplete
                disablePortal
                id="lista-produtos"
                options={nfList}
                //sx={{ width: 300 }}
                loading={loadingNF}
                loadingText="Procurando..."
                noOptionsText="Sem NFs cadastradas"
                // isOptionEqualToValue={(option, value) => option.productId === value.value}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.movimentationOrderId}>
                      {option.movimentationOrderNumberOtherSystem}
                    </li>
                  )
                }}
                renderInput={params => <TextField {...params} label="Procurar Nota Fiscal" />}
                onChange={(e, v) => changeNF(v.movimentationOrderId)}
              />
            </Box> */}
            <Box sx={{ border: '1px solid black', width: 1 }}>
              <TableContainer sx={{ width: 1 }}>
                <Table>
                  <colgroup>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '35%' }} />
                    <col style={{ width: '20%' }} />
                  </colgroup>
                  <TableRow sx={{ backgroundColor: grey[100] }}>
                    <TableCell align="left">
                      <Box>
                        <Avatar sx={{ width: 24, height: 24, bgcolor: blue[500] }}>A</Avatar>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ width: 1 }}>
                      <Box>
                        <Typography variant="body2">{movRoute.subCompanyName}</Typography>
                        <Typography variant="caption">Início</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <StrictModeDroppable droppableId="droppable" direction="vertical">
                      {droppableProvided => (
                        <TableBody
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {rotas.map((item, index) => (
                            <Draggable
                              key={item.movimentationOrderId}
                              draggableId={item.movimentationOrderId}
                              index={index}
                            >
                              {(draggableProvided, snapshot) => {
                                return (
                                  <TableRow
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    style={{
                                      ...draggableProvided.draggableProps.style,
                                      background: snapshot.isDragging
                                        ? 'rgba(245,245,245, 0.75)'
                                        : 'none',
                                    }}
                                  >
                                    {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
                                    <TableCell
                                      align="left"
                                      style={{
                                        background:
                                          mapScreen.movimentationOrderId ===
                                          item.movimentationOrderId
                                            ? 'rgba(200,200,200, 0.75)'
                                            : 'none',
                                      }}
                                    >
                                      <Box
                                        {...draggableProvided.dragHandleProps}
                                        onClick={() => {
                                          setMapScreen(item)
                                          setGeral(false)
                                          setCreating(false)
                                        }}
                                      >
                                        <Avatar sx={{ width: 24, height: 24, bgcolor: blue[500] }}>
                                          {alfabeto[index + 1]}
                                        </Avatar>
                                        {item.moreWeight && (
                                          <Avatar
                                            sx={{ mt: 1, width: 24, height: 24, bgcolor: red[900] }}
                                          >
                                            <ScaleIcon />
                                          </Avatar>
                                        )}
                                      </Box>
                                    </TableCell>
                                    <TableCell
                                      {...draggableProvided.dragHandleProps}
                                      sx={{ width: 1 }}
                                    >
                                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body2">
                                          {item.companyName} -{' '}
                                          {item.movimentationOrderNumberOtherSystem}
                                        </Typography>
                                        <Typography variant="caption">{item.address}</Typography>
                                        <Typography variant="caption">{item.distancia}</Typography>
                                        <Typography variant="caption">
                                          Peso {item.netWeight}kg
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => deleteAlert(item.movimentationOrderId)}
                                      >
                                        <DeleteForeverIcon sx={{ cursor: 'pointer' }} />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )
                              }}
                            </Draggable>
                          ))}
                          {droppableProvided.placeholder}
                          <TableRow sx={{ backgroundColor: grey[200] }}>
                            <TableCell align="left">
                              <Box>
                                <Avatar sx={{ width: 24, height: 24, bgcolor: blue[500] }}>
                                  {alfabeto[rotas.length + 1]}
                                </Avatar>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ width: 1 }}>
                              <Box>
                                <Typography variant="body2">{movRoute.subCompanyName}</Typography>
                                <Typography variant="caption">Final</Typography>
                              </Box>
                            </TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </StrictModeDroppable>
                  </DragDropContext>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          {creating ? (
            <AddRoute
              nfList={nfList}
              resetScreen={resetScreen}
              setCreating={setCreating}
              getRouteId={getRouteId}
              setAlerta={setAlerta}
              setSeverity={setSeverity}
              setTitle={setTitle}
              setMapScreen={setMapScreen}
              setGeral={setGeral}
              subCompanyId={subCompanyId}
            />
          ) : (
            <RouteMap mapScreen={mapScreen} geral={geral} movRoute={movRoute} rotas={rotas} />
          )}
        </Box>
      </Box>
    </>
  )
}

export default RouteEditor
