import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Editor from '../../ComponentsItems/TextEdit'
import { useContext, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { Save } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { createAttendants, createTicket } from '../../../services/helpdeskService'

const ValidatorSchema = yup.object().shape({
  subCompanyId: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
})

const Validator = yupResolver(ValidatorSchema)

export const AttendantsCreate = ({
  setName,
  setExpanded,
  id,
  setTitle,
  setSeverity,
  setRegister,
  handleGetListAttendants,
  setEditing,
}) => {
  const formMethods = useForm({
    resolver: Validator,
  })
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    reset,
  } = formMethods
  const token = localStorage.getItem('token')
  const { subCompany, userName, userId, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = async values => {
    if (!values || values === '') {
      return
    }

    setSubCompanyId(values.target.value)
    setLoading(true)
    try {
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onSubmit = async form => {
    setLoading(true)
    const data = {
      dataCreated: new Date(),
      name: form.name,
      subCompanyId: form.subCompanyId,
    }

    console.log(data, 'value')

    try {
      const response = await createAttendants(token, nameUrl, data)
      setLoading(false)
      setExpanded(false)
      handleGetListAttendants()
      setValue('subCompanyId', '')
      setSubCompanyId('')
      setValue('name', '')
      reset()
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Filial</InputLabel>
            <Select
              {...register('subCompanyId')}
              defaultValue=""
              sx={{ width: '100%' }}
              label="Escolher o Usuário"
              onChange={e => {
                setValue('subCompanyId', e.target.value)
                clearErrors('subCompanyId')
              }}
              error={Boolean(errors?.subCompanyId?.message)}
            >
              {subCompany.map((item, index) => {
                return (
                  <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
            {errors?.subCompanyId?.message && (
              <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                {errors?.subCompanyId?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('name')}
            defaultValue=""
            onChange={e => {
              setValue('name', e.target.value)
              clearErrors('name')
            }}
            sx={{ width: '100%' }}
            label="Nome"
            helperText={errors?.name?.message}
            error={errors?.name?.message}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          mt: 3,
          justifyContent: 'center',
        }}
      >
        <Button variant="contained" type="submit">
          Salvar
        </Button>

        <Button variant="contained" type="button" onClick={() => setExpanded(false)} sx={{ ml: 2 }}>
          Cancelar
        </Button>
      </Box>
    </Box>
  )
}
