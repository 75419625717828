import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Editor from '../../ComponentsItems/TextEdit'
import { useContext, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { Save } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { createTicket } from '../../../services/helpdeskService'

const ValidatorSchema = yup.object().shape({
  subCompanyId: yup.string().required('Campo obrigatório'),
  subject: yup.string().required('Campo obrigatório'),
  typeTicket: yup.string().required('Campo obrigatório'),
  priority: yup.string().required('Campo obrigatório'),
  derpatament: yup.string().required('Campo obrigatório'),
  message: yup.string(),
})

const Validator = yupResolver(ValidatorSchema)

export const TicketCreate = ({
  setName,
  setExpanded,
  id,
  setTitle,
  setSeverity,
  setAlerta,
  handleGetTicket,
  setEditing,
}) => {
  const formMethods = useForm({
    resolver: Validator,
  })
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    reset,
  } = formMethods
  const token = localStorage.getItem('token')
  const { subCompany, userName, userEmail, userId } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [subCompanyId, setSubCompanyId] = useState('')

  const fetchData = async values => {
    if (!values || values === '') {
      return
    }

    setSubCompanyId(values.target.value)
    setLoading(true)
    try {
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onSubmit = async form => {
    setLoading(true)
    const data = {
      derpatament: parseInt(form.derpatament),
      priority: parseInt(form.priority),
      subject: form.subject,
      typeTicket: parseInt(form.typeTicket),
      status: 0,
      subCompanyId: form.subCompanyId,
      createUserId: userId,
      movimentationOrderTicketDetailsCollection: [
        {
          message: form.message,
          file: 'b-',
          createUserId: userId,
          visibility: false,
        },
      ],
    }

    try {
      const response = await createTicket(token, data)
      setSeverity('success')
      setTitle(response.data.message)
      await handleGetTicket(form.subCompanyId)
      setValue('subCompanyId', '')
      setSubCompanyId('')
      setValue('name', '')
      setValue('email', '')
      setValue('subject', '')
      setValue('typeTicket', '')
      setValue('priority', '')
      setValue('derpatament', '')
      setValue('message', '')
      reset()
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
    } catch (error) {
      setSeverity('error')
      setTitle('Erro ao criar, verifique os dados e tente novamente.')
      setLoading(false)
      setAlerta(true)
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Filial</InputLabel>
            <Select
              {...register('subCompanyId')}
              defaultValue=""
              sx={{ width: '100%' }}
              label="Escolher o Usuário"
              onChange={e => {
                setValue('subCompanyId', e.target.value)
                clearErrors('subCompanyId')
              }}
              error={errors?.subCompanyId?.message}
            >
              {subCompany.map((item, index) => {
                return (
                  <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
            {errors?.subCompanyId?.message && (
              <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                {errors?.subCompanyId?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            onChange={e => {
              setValue('name', e.target.value)
              clearErrors('name')
            }}
            sx={{ width: '100%' }}
            value={userName}
            disabled
            label="Nome"
            helperText={errors?.name?.message}
            error={errors?.name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            sx={{ width: '100%' }}
            value={userEmail}
            disabled
            label="Email"
            onChange={e => {
              setValue('email', e.target.value)
              clearErrors('email')
            }}
            helperText={errors?.email?.message}
            error={errors?.email?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            {...register('subject')}
            defaultValue=""
            sx={{ width: '100%' }}
            label="Assunto"
            onChange={e => {
              setValue('subject', e.target.value)
              clearErrors('subject')
            }}
            helperText={errors?.subject?.message}
            error={errors?.subject?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Tipo de ticket</InputLabel>
            <Select
              {...register('typeTicket')}
              defaultValue=""
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              //value={age}
              label="Age"
              onChange={e => {
                setValue('typeTicket', e.target.value)
                clearErrors('typeTicket')
              }}
              error={errors?.typeTicket?.message}
            >
              <MenuItem value={'1'}>Suporte Equipamento</MenuItem>
              <MenuItem value={'2'}>Suporte Sistemas</MenuItem>
              <MenuItem value={'3'}>Manutenção em Equipamentos</MenuItem>
              <MenuItem value={'4'}>Dúvidas</MenuItem>
            </Select>
            {errors?.typeTicket?.message && (
              <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                {errors?.typeTicket?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Prioridade</InputLabel>
            <Select
              {...register('priority')}
              defaultValue=""
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              //value={age}
              label="Age"
              onChange={e => {
                setValue('priority', e.target.value)
                clearErrors('priority')
              }}
              error={errors?.priority?.message}
            >
              <MenuItem value={'1'}>Urgente</MenuItem>
              <MenuItem value={'2'}>Emergencial</MenuItem>
              <MenuItem value={'3'}>Normal</MenuItem>
            </Select>
            {errors?.priority?.message && (
              <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                {errors?.priority?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Departamento</InputLabel>
            <Select
              {...register('derpatament')}
              defaultValue=""
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              //value={age}
              label="Age"
              onChange={e => {
                setValue('derpatament', e.target.value)
                clearErrors('derpatament')
              }}
              error={errors?.derpatament?.message}
            >
              <MenuItem value={'1'}>Financeiro</MenuItem>
              <MenuItem value={'0'}>Suporte</MenuItem>
            </Select>
            {errors?.derpatament?.message && (
              <Typography color="#d32f2f" fontSize="0.75rem" mt="3px" ml="14px">
                {errors?.derpatament?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('message')}
            defaultValue=""
            sx={{ width: '100%' }}
            id="outlined-multiline-static"
            label="Menssagem"
            multiline
            onChange={e => {
              setValue('message', e.target.value)
            }}
            rows={10}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          mt: 3,
          justifyContent: 'center',
        }}
      >
        <Button variant="contained" type="submit">
          Salvar
        </Button>

        <Button variant="contained" type="button" onClick={() => setExpanded(false)} sx={{ ml: 2 }}>
          Cancelar
        </Button>
      </Box>
    </Box>
  )
}
