import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  confirmOrder,
  getDeposit,
  getProductAssociationService,
  returnOrderStatus,
} from '../../../services/productService'
import { Context } from '../../../context/AuthContext'
import {
  editTransferOrderService,
  getUniqueTransferOrderService,
} from '../../../services/requestServices'
import * as XLSX from 'xlsx'
import PropTypes from 'prop-types'
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { deleteProductMovimentationOrderByIdPartner } from '../../../services/requestServices'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

const CountStockOrderEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  labelOrderId,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const subCompanyId = subCompany[0].subCompanyId

  const token = localStorage.getItem('token')
  const [product, setProduct] = useState([])
  const [requestProductsConverted, setRequestProductsConverted] = useState([])
  const [selectProductId, setSelectProductId] = useState('')
  let requestProductsModel = {}
  const navigate = useNavigate()

  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [subCompanyIdFrom, setSubCompanyIdFrom] = useState('')
  const [movimentationOrderNumberBihands, setMovimentationOrderNumberBihands] = useState('')
  const [receiveMovimentationOrderDate, setReceiveMovimentationOrderDate] = useState('')
  const [createRequestMovimentationOrderDate, setCreateRequestMovimentationOrderDate] = useState('')
  const [typeOrderName, setTypeOrderName] = useState('')
  const [subCompanyNameFrom, setSubCompanyNameFrom] = useState('')
  const [movimentationOrderStatus, setMovimentationOrderStatus] = useState()
  const [isReceived, setIsReceived] = useState()
  const [finalizado, setFinalizado] = useState(false)

  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)

  const [alertSuccess, setAlertSuccess] = useState(false)
  const [alertFailed, setAlertFailed] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [newItem, setNewItem] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [loading, setLoading] = useState(false)
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [deposit, setDeposit] = useState([])
  const [depositId, setDepositId] = useState('')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const handleClose = () => {
    setAlertSuccess(false)
    setAlertFailed(false)
  }

  const requestProductsConverter = useCallback(rp => {
    let array = []

    rp.forEach(element => {
      requestProductsModel = {
        idProductPartner: element.idProductPartner,
        productName: element.productName,
        sku: element.sku,
        brand: element.brand,
        stockCount: element.stockCount,
        barCode: element.barCode,
        stockActual: element.stockActual,
        diferrence: element.diferrence,
        quantity: element.quantity,
        price: element.price,
        serial: element.serial,
        createDate: element.createDate,
      }

      array.push(requestProductsModel)
    })

    setRequestProductsConverted(array)
  }, [])

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    finishDownload()
  }

  const finishDownload = async () => {
    let result = requestProductsConverted

    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    const relatorioFinal = []
    console.log(requestProductsConverted, 'adsda')
    result.map(item => {
      relatorioFinal.push({
        'Identificação ERP': item.idProductPartner,
        Serial: item.serial,
        'Nome Produto': item.productName,
        Preço: parseFloat(item.price).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
        }),
        SKU: item.sku,
        'Peças contadas': item.stockCount,
        'Peças em estoque': item.stockActual,
        Diferença: item.diferrence,

        Criado: new Date(item.createDate).toLocaleString(),
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contagem Estoque')
    XLSX.writeFile(workbook, `Contagem Estoque.xlsx`)
  }

  const getCountStockOrder = async () => {
    setLoading(true)
    setFinalizado(false)
    try {
      const response = await getUniqueTransferOrderService(token, nameUrl, labelOrderId)
      console.log(labelOrderId)
      requestProductsConverter(response.data.stockCountSumaryCollection)
      setSubCompanyIdFrom(response.data.subCompanyIdFrom)
      setMovimentationOrderNumberOtherSystem(response.data.movimentationOrderNumberOtherSystem)
      setMovimentationOrderNumberBihands(response.data.movimentationOrderNumberBihands)
      setReceiveMovimentationOrderDate(response.data.receiveMovimentationOrderDate)
      setCreateRequestMovimentationOrderDate(response.data.createRequestMovimentationOrderDate)
      setTypeOrderName('Contagem de Estoque')
      setSubCompanyNameFrom(response.data.subCompanyNameFrom)
      setMovimentationOrderStatus(returnOrderStatus(response.data.movimentationOrderStatus))
      setIsReceived(response.data.isReceived)
      if (
        response.data.movimentationOrderStatus === 2 ||
        response.data.movimentationOrderStatus === -2
      ) {
        setFinalizado(true)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const editCountStockOrder = useCallback(async () => {
    if (labelOrderId && Array.isArray(labelOrderId)) {
      let array = []
      requestProductsConverted.forEach(element => {
        const data = {
          movimentationOrderId: labelOrderId,
          idProductPartner: element.idProductPartner,
          productItensId: element.productItensId,
          productName: element.productName,
          sku: element.sku,
          serial: element.serial,
          quantity: element.quantity,
          lote: element.lote,
        }
        array.push(data)
      })
      console.log(array, 'datadatsa')

      setLoading(true)

      try {
        await editTransferOrderService(token, nameUrl, array)
        setSeverity('success')
        setTitle('Contagem editada com sucesso!')
        setEditing(false)
        setAlerta(true)
        setLoading(false)
        handleFetchData()
      } catch (err) {
        console.log(err)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente.')
        setAlerta(true)
        setLoading(false)
      }
    }
  }, [movimentationOrderNumberOtherSystem, subCompanyIdFrom, requestProductsConverted])

  const getProduct = useCallback(async () => {
    console.log('conso')

    try {
      const response = await getProductAssociationService(
        token,
        nameUrl,
        10000,
        0,
        false,
        subCompanyId
      )
      setProduct(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  // const setTreeView = useCallback(async () => {
  //   const script = document.createElement('script')

  //   script.src = `../js/treeView.js`
  //   script.async = true

  //   document.body.appendChild(script)
  // }, [])

  const addRequestProducts = useCallback(() => {
    const result = product.find(item => item.productItensId === selectProductId)

    setRequestProductsConverted([...requestProductsConverted, result])
    console.log(requestProductsConverted, 'result')
    setNewItem(false)
  }, [product, requestProductsConverted, selectProductId])

  const removeRequestProducts = useCallback(
    productItensId => {
      setRequestProductsConverted(
        requestProductsConverted.filter(item => item.productItensId !== productItensId)
      )
    },
    [product, requestProductsConverted, selectProductId]
  )

  const deleteItem = async item => {
    setLoading(true)

    try {
      const response = await deleteProductMovimentationOrderByIdPartner(
        token,
        nameUrl,
        labelOrderId,
        item
      )
      getCountStockOrder()
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getProduct()

    getCountStockOrder()

    // setTreeView()
  }, [labelOrderId])

  useEffect(() => {}, [depositId])

  const handleConfirm = async () => {
    try {
      const response = await confirmOrder(token, nameUrl, labelOrderId, depositId)
      navigate('/CountStockOrder')
      setSeverity('success')
      setTitle('Resgistro enviado com sucesso!')
      setEditing(false)
      setAlerta(true)
      setLoading(false)
      setDialogConfirm(false)
      handleFetchData()
    } catch (e) {
      console.log(e)
      setAlertSuccess(false)
      setAlertFailed(true)
    }
  }

  const handleSelectedDeposit = async () => {
    setDialogConfirm(true)
    try {
      const response = await getDeposit(token, nameUrl, subCompanyId)
      setDeposit(response.data.result)
    } catch (error) {}
  }

  return (
    <>
      <Box>
        <Dialog open={downloading} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
          </Box>
        </Dialog>

        {/* Add Product */}
        <Dialog
          open={newItem}
          keepMounted
          //onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
            sx={{ padding: 3 }}
          >
            <Box>
              <InputLabel id="demo-simple-select-label">Produtos</InputLabel>
              <Select
                label="Produtos"
                value={selectProductId}
                onChange={values => setSelectProductId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {product.map((item, index) => {
                  return (
                    <MenuItem value={item.productItensId} key={index}>
                      {item.productName}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button type="button" variant="contained" onClick={() => setNewItem(false)}>
                Fechar
              </Button>
              <Button
                type="button"
                variant="contained"
                sx={{ ml: 1 }}
                onClick={() => addRequestProducts()}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Dialog>

        <Snackbar anchorOrigin={{ vertical, horizontal }} open={alertSuccess} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Itens Salvos com Sucesso!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alertFailed}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Algo deu Errado!
          </Alert>
        </Snackbar>
        <Dialog
          open={dialogConfirm}
          onClose={() => setDialogConfirm(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText sx={{ mb: 4 }}>Selecione o depósito!</DialogContentText>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Depósito</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={depositId}
                label="Depósito"
                onChange={e => setDepositId(e.target.value)}
              >
                {deposit.map((dp, index) => (
                  <MenuItem key={dp.depositId} value={dp.depositId}>
                    {dp.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setDialogConfirm(false)}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleConfirm}>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <Box component="form" sx={{ mt: '2rem' }}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={movimentationOrderNumberBihands}
                name="movimentationOrderNumberBihands"
                id="standard-basic"
                label="Ordem Bihands"
                variant="outlined"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Ordem Outro Sistema"
                variant="outlined"
                name="MovimentationOrderNumberOtherSystem"
                value={movimentationOrderNumberOtherSystem}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            {/* <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="ReceiveMovimentationOrderDate"
                value={new Date(receiveMovimentationOrderDate).toLocaleString()}
                id="outlined-basic"
                label="Data do Recebimento"
                variant="outlined"
                disabled
                type="text"
              />
            </Box> */}
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Data da Solicitação"
                variant="outlined"
                name="CreateRequestMovimentationOrderDate"
                value={new Date(createRequestMovimentationOrderDate).toLocaleString()}
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="TypeOrder"
                value={typeOrderName}
                id="outlined-basic"
                label="Tipo de Pedido"
                variant="outlined"
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Filial"
                variant="outlined"
                name="SubCompanyNameFrom"
                value={subCompanyNameFrom}
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="MovimentationOrderStatus"
                value={movimentationOrderStatus ? movimentationOrderStatus : ''}
                id="outlined-basic"
                label="Status da movimentação"
                variant="outlined"
                disabled
                type="text"
              />
            </Box>
          </Box>
          {/* <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
          
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Recebido"
                variant="outlined"
                name="IsReceived"
                value={isReceived ? 'Sim' : 'Não'}
                disabled
                type="text"
              />
            </Box>
          </Box> */}
          <Box
            width={{ xs: '40%', sm: '40%', md: '20%', lg: '20%', xl: '20%' }}
            sx={{ mt: '1rem', alignItems: 'center' }}
          >
            {!finalizado && (
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                type="button"
                onClick={() => setEditing(false)}
              >
                Cancelar
              </Button>
            )}
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          {!finalizado && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ width: '80%' }}>
                {movimentationOrderStatus === 'Aberto' ? (
                  <Box>
                    <Button
                      variant="contained"
                      width={{ xs: '40%', sm: '40%', md: '20%', lg: '20%', xl: '20%' }}
                      onClick={() => setNewItem(true)}
                    >
                      + Novo Item
                    </Button>
                    <Button
                      sx={{ ml: 1 }}
                      onClick={() => editCountStockOrder()}
                      variant="contained"
                      width={{ xs: '40%', sm: '40%', md: '20%', lg: '20%', xl: '20%' }}
                    >
                      Salvar Itens
                    </Button>
                    <Button
                      sx={{ ml: 1 }}
                      onClick={() => {
                        handleSelectedDeposit()
                      }}
                      variant="contained"
                    >
                      Confirmar
                    </Button>
                  </Box>
                ) : (
                  <Button
                    onClick={() => {
                      handleSelectedDeposit()
                    }}
                    variant="contained"
                  >
                    Confirmar
                  </Button>
                )}
              </Box>
              {requestProductsConverted && (
                <Button onClick={downloadData}>
                  <DocumentScannerOutlinedIcon sx={{ fontSize: '3em' }} />
                </Button>
              )}
            </Box>
          )}
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="center">Identificação ERP</StyledTableCell>
                  <StyledTableCell align="center">Nome Produto</StyledTableCell>
                  <StyledTableCell align="center">SKU</StyledTableCell>
                  <StyledTableCell align="center">Marca</StyledTableCell>
                  <StyledTableCell align="center">Peças contadas</StyledTableCell>
                  <StyledTableCell align="center">Peças em estoque</StyledTableCell>
                  <StyledTableCell align="center">Diferença</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requestProductsConverted
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '80px',
                          }}
                        >
                          {!finalizado && (
                            <DeleteForeverIcon
                              // onClick={() => removeRequestProducts(row.productItensId)}
                              onClick={() => deleteItem(row.idProductPartner)}
                              sx={{ cursor: 'pointer' }}
                            />
                          )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                      <StyledTableCell align="center">{row.productName}</StyledTableCell>
                      <StyledTableCell align="center">{row.sku}</StyledTableCell>
                      <StyledTableCell align="center">{row.brand}</StyledTableCell>
                      <StyledTableCell align="center">{row.stockCount}</StyledTableCell>
                      <StyledTableCell align="center">{row.stockActual}</StyledTableCell>
                      <StyledTableCell align="center">{row.diferrence}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={requestProductsConverted.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default CountStockOrderEdit
