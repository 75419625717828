import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { styled, tableCellClasses } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ListItemText from '@mui/material/ListItemText'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import TablePagination from '@mui/material/TablePagination'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import { formatNumbers } from '../../../../../utils/formatNumbers'
import { Context } from '../../../../../context/AuthContext'
import { Add, Delete, Edit } from '@mui/icons-material'
import { colorTableDefault } from '../../../../TemplateDefault'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function ComponentQuantityCreate({
  open,
  onClose,
  product,
  productRows,
  message,
  subCompanyId,
  onSaveQuantity,
  factionId,
  alocationStockId,
}) {
  const { subCompany, userId, nameUrl } = useContext(Context)
  const [loading, setLoading] = useState('')
  const [valueText, setValueText] = useState('')
  const [title, setTitle] = useState('')
  const [messageError, setMessageError] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [register, setRegister] = useState(false)
  const [codeList, setCodeList] = useState([])
  const vertical = 'top'
  const horizontal = 'center'
  const inputRef = useRef(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function Row(props) {
    const { row, onDelete } = props

    const handleDeleteClick = () => {
      onDelete(row)
    }

    return (
      <React.Fragment>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell align="center" width="200px">
            <Tooltip title="Apagar" arrow>
              <IconButton onClick={handleDeleteClick}>
                <Delete />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {row.name}
          </StyledTableCell>
          <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
          <StyledTableCell align="left">{row.serial}</StyledTableCell>
          <StyledTableCell align="left">{row.sku}</StyledTableCell>
          <StyledTableCell align="left">{row.referencia}</StyledTableCell>
          <StyledTableCell align="left">{row.price}</StyledTableCell>
          <StyledTableCell align="left">{row.barCode}</StyledTableCell>
          <StyledTableCell align="left">{row.color}</StyledTableCell>
          <StyledTableCell align="left">{new Date(row.created).toLocaleString()}</StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    )
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus()
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [open])

  const handleAddList = () => {
    if (valueText === '') {
      setMessageError('Digite um codigo Rfid.')
      setLoading(false)
      return
    }
    const codeExists = codeList.some(item => item.serial === valueText)

    if (codeExists) {
      setTitle('Este código RFID ja foi lido.')
      setSeverity('error')
      setValueText('')
      setRegister(true)
      setLoading(false)
      return
    }

    const newProduct = {
      ...product,
      serial: valueText,
      subCompanyId: subCompanyId,
      userId: userId,
      ...(alocationStockId && { alocationStockId }),
      ...(factionId && { factionId }),
    }
    setCodeList(prevList => [...prevList, newProduct])
    setValueText('')
  }

  const handleSave = async () => {
    setLoading(false)

    const duplicateCode = codeList.find(codeListItem => {
      return productRows.some(productItem => productItem.serial === codeListItem.serial)
    })

    if (duplicateCode) {
      setTitle(
        `O código RFID '${duplicateCode.serial}' já está associado a outro produto na lista.`
      )
      setSeverity('error')
      setValueText('')
      setRegister(true)
      setLoading(false)
    } else {
      setLoading(false)
      onSaveQuantity(codeList)
      setCodeList([])
      onClose()
    }
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      return handleAddList()
    }
  }

  const handleDelete = itemToDelete => {
    const updatedRows = codeList.filter(item => item !== itemToDelete)
    setCodeList(updatedRows)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={register}
        onClose={() => setRegister(false)}
        key={vertical + horizontal}
      >
        <Alert onClose={() => setRegister(false)} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={() => {
          onClose()
          setCodeList([])
          setValueText('')
        }}
        onEnter={() => inputRef.current.focus()}
        sx={{ m: 2 }}
        fullScreen
      >
        {loading ? (
          <Box
            width="100%"
            maxHeight="500px"
            height="500px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle>Associar código RFID ao produto</DialogTitle>
            <DialogContent>
              <ListItemText primary="Produto" secondary={product.name} />
              <Divider sx={{ my: 2 }} />
              <DialogContentText> Insira o código RFID correspondente:</DialogContentText>
              <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                <TextField
                  inputRef={inputRef}
                  sx={{ my: 2 }}
                  autoFocus
                  margin="dense"
                  id="rfid-code"
                  type="text"
                  onKeyDown={handleKeyPress}
                  value={valueText}
                  error={!!messageError}
                  helperText={messageError}
                  onChange={e => setValueText(e.target.value)}
                  fullWidth
                  variant="outlined"
                  inputProps={{ min: 1 }}
                />
                <Button
                  startIcon={<Add />}
                  variant="contained"
                  sx={{ width: '250px', mb: messageError ? 2 : 0 }}
                  onClick={handleAddList}
                >
                  Adicionar [ENTER]
                </Button>
              </Box>

              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left"></StyledTableCell>
                      <StyledTableCell align="left">Nome</StyledTableCell>
                      <StyledTableCell align="left">Identificação ERP</StyledTableCell>
                      <StyledTableCell align="left">Serial</StyledTableCell>
                      <StyledTableCell align="left">SKU</StyledTableCell>
                      <StyledTableCell align="left">Referência</StyledTableCell>
                      <StyledTableCell align="left">Preço</StyledTableCell>
                      <StyledTableCell align="left">Cód. Barras</StyledTableCell>
                      <StyledTableCell align="left">Cor</StyledTableCell>
                      <StyledTableCell align="left">Criação</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {codeList
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(row => (
                        <Row key={row.serial} row={row} onDelete={handleDelete} />
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={codeList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose()
                  setCodeList([])
                  setValueText('')
                }}
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button type="submit" variant="contained" onClick={handleSave}>
                Salvar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}
