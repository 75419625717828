import { Box, createTheme, Link, ThemeProvider, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { Context } from '../../context/AuthContext'

const Footer = () => {
  const theme = createTheme()
  const themes = useTheme()
  const {
    handleLogout,
    userRoles,
    userName,
    colorPrimary,
    colorSecondary,
    urlLogo,
    colorMenu,
    letterSecondary,
    sizeLogoMenu,
    sizeLogoSideMenu,
    fontColorMenuPrimary,
    fontColorMenuSecondary,
  } = useContext(Context)

  theme.typography.h3 = {
    fontSize: '0.7rem',
    '@media (min-width:600px)': {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        mt: '1rem',
        textAlign: 'center',
        borderTop: '1px solid #cccccc',
        // backgroundColor: "#3D5D84",
        backgroundColor: colorPrimary,
        color: colorSecondary,
        // color:'white'
      }}
    >
      <Box sx={{ width: '90%' }}>
        <ThemeProvider theme={theme}>
          <Box padding={3}>
            <Typography variant="body2">
              Copyright 2024{' '}
              <Link href="#" underline="none">
                Inventário
              </Link>
              . All rights reserved. <b>Version</b> 1.0.0{' '}
            </Typography>
            <Typography variant="h3"></Typography>
          </Box>
        </ThemeProvider>
      </Box>
    </Box>
  )
}

export default Footer
