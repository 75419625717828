import React, { Component, useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  editCompanyService,
  getPlanService,
  getSegmentService,
  getUniqueCompanyService,
} from '../../../services/admServices'
import { Context } from '../../../context/AuthContext'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { editCarWeightService, getCarWeightIdService } from '../../../services/carWeightServices'

const CarWeightEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  carWeightId,
  handleFetchData,
  setExpanded,
}) => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [name, setName] = useState('')
  const [kgMin, setKgMin] = useState(0)
  const [kgMax, setKgMax] = useState(0)
  const [subCompaniesId, setSubCompaniesId] = useState([])
  const [createdDate, setCreatedDate] = useState('')
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)

  const editCarWeight = async e => {
    e.preventDefault()
    setLoading(true)

    const promises = subCompaniesId.map(async (subCompanie, index) => {
      const data = {
        carWeightId,
        name,
        kgMin: parseInt(kgMin),
        kgMax: parseInt(kgMax),
        active,
        createdDate,
        subCompaniesId: subCompanie,
      }
      await editCarWeightService(token, nameUrl, data)
    })

    try {
      await Promise.all(promises)
      setLoading(false)
      setEditing(false)
      setAlerta(true)
      setSeverity('success')
      setTitle('Limite de peso editado com sucesso!')
      handleFetchData()
    } catch (err) {
      console.log(err)
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao editar, verifique os dados e tente novamente.')
    }
  }

  const getCarWeight = async () => {
    setLoading(true)
    try {
      const response = await getCarWeightIdService(token, nameUrl, carWeightId)
      setName(response.data.name)
      setKgMax(response.data.kgMax)
      setSubCompaniesId([response.data.subCompaniesId])
      setCreatedDate(response.data.createdDate)
      setActive(response.data.active)

      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    getCarWeight()
  }, [carWeightId])

  const handleAddSubCompanies = id => {
    const {
      target: { value },
    } = id
    setSubCompaniesId(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={editCarWeight}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Filiais</InputLabel>
              <Select
                label="Filial"
                value={subCompaniesId}
                onChange={handleAddSubCompanies}
                sx={{ width: '100%' }}
                multiple
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              sx={{
                mt: 2,
                flexDirection: 'column',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Ativo
              <Checkbox checked={active} onClick={() => setActive(!active)} />
              {active ? 'Sim' : 'Não'}
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              justifyContent: 'space-evenly',
              mt: 2,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="standard-basic"
                label="Nome"
                variant="standard"
                value={name}
                onChange={values => setName(values.target.value)}
                type="text"
              />
            </Box>
            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              sx={{ mt: 2, display: 'flex', justifyContent: 'space-evenly' }}
            >
              <TextField
                sx={{ width: '45%', mt: '1rem' }}
                id="standard-basic"
                label="Peso Mínimo"
                variant="standard"
                value={kgMin}
                onChange={values => setKgMin(values.target.value)}
                type="text"
                InputProps={{
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                }}
              />
              <TextField
                sx={{ width: '45%', mt: '1rem' }}
                id="standard-basic"
                label="Peso Máximo"
                variant="standard"
                value={kgMax}
                onChange={values => setKgMax(values.target.value)}
                type="text"
                InputProps={{
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Editar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default CarWeightEdit
