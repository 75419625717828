import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { editParamGroupService } from '../../../services/admServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'
import { createSegmentGroup, getSegmentList } from '../../../services/permission'

const FunctionsPositionsEdiit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  setExpanded,
  segmentGroup,
  handleFetchData,
}) => {
  const token = localStorage.getItem('token')
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [status, setStatus] = useState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [segmentSelect, setSegmentSelect] = useState('')
  const [selectSegment, setSelectSegment] = useState([])

  const editFunctions = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const functionsEdited = {
        segmentGroupId: segmentGroup.segmentGroupId,
        name: name,
        active: segmentGroup.active,
        description: description,
        segmentId: segmentGroup.segmentId,
      }

      try {
        await editParamGroupService(token, nameUrl, functionsEdited)
        setLoading(false)
        setEditing(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Funções/Cargo editado com sucesso')
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Verifique os dados e tente novamente.')
      }
    },
    [name, description, status]
  )

  const getFunctions = async () => {
    setLoading(true)
    try {
      setName(segmentGroup.name)
      setDescription(segmentGroup.description)
      setSegmentSelect(segmentGroup.segmentId)
      setSubCompanyId(segmentGroup.id)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const selectedSegment = async () => {
    try {
      const response = await getSegmentList(token)
      setSelectSegment(response.data.result)
    } catch (error) {
      setError(true)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao criar, verifique os dados e tente novamente.')
    }
  }

  const createFunction = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      if (name === '' || description === '' || subCompanyId === '') {
        setAlerta(true)
        setSeverity('error')
        setTitle('Preencha os campos obrigatorio!')
      } else {
        const data = {
          segmentId: segmentSelect,
          name: name,
          description: description,
        }

        console.log(data, 'datadas')

        const response = await createSegmentGroup(token, nameUrl, data)

        handleFetchData({ target: { value: subCompanyId } })

        setExpanded(false)
        setAlerta(true)
        setSeverity('success')
        setTitle(response.data.message)
        setName('')
        setDescription('')
        setSegmentSelect('')
        setSubCompanyId('')
        setError(false)
      }
    } catch (error) {
      console.log(error)
      setError(true)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao criar, verifique os dados e tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getFunctions()
    selectedSegment()
  }, [segmentGroup])

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={editFunctions}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                <Select
                  value={subCompanyId}
                  sx={{ width: '100%' }}
                  label="Filial"
                  onChange={e => setSubCompanyId(e.target.value)}
                >
                  {subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Segmento</InputLabel>
                <Select
                  value={segmentSelect}
                  sx={{ width: '100%' }}
                  label="Segmento"
                  onChange={e => setSegmentSelect(e.target.value)}
                >
                  {selectSegment.map((item, index) => {
                    return (
                      <MenuItem value={item.segmentId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Box width="100%">
                <TextField
                  sx={{ width: '100%' }}
                  value={name}
                  onChange={values => setName(values.target.value)}
                  id="outlined-basic"
                  label="Nome"
                  variant="outlined"
                  type="text"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box width="100%">
                <TextField
                  sx={{ width: '100%' }}
                  value={description}
                  onChange={values => setDescription(values.target.value)}
                  id="outlined-basic"
                  label="Descrição"
                  variant="outlined"
                  type="text"
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="submit"
                variant="contained"
                sx={{ width: '100%' }}
                onClick={createFunction}
              >
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => {
                  setEditing(false)
                  setExpanded(false)
                }}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default FunctionsPositionsEdiit
