import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState, useContext } from 'react'
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { createClient } from '../../../services/clientService'
import { Context } from '../../../context/AuthContext'
import { Controller, useForm } from 'react-hook-form'
import { createTemplateEquipament } from '../../../services/helpdeskService'

const ValidatorSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  typeEquipment: yup.number(),
  imageUrl: yup.string().required('Campo obrigatório'),
  active: yup.boolean().required('Campo obrigatório'),
})

const Validator = yupResolver(ValidatorSchema)

const TemplateEquipamentCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
  companyId,
}) => {
  const formMethods = useForm({
    resolver: Validator,
  })
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    reset,
  } = formMethods
  const { nameUrl } = useContext(Context)

  const token = localStorage.getItem('token')
  const [subCompanyId, setSubCompanyId] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCreateEquipament = async form => {
    setLoading(true)

    const data = {
      name: form.name,
      imageUrl: form.imageUrl,
      active: form.active,
      typeEquipment: form.typeEquipment,
    }
    try {
      const response = await createTemplateEquipament(token, nameUrl, data)
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSeverity('success')
      setTitle(response.data.message)
      //handleFetchData(object.subCompanyId)
      reset()
      setSubCompanyId('')
    } catch (err) {
      console.log(err)
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
    }
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleSubmit(handleCreateEquipament)}>
          <Grid container spacing={2}>
            {/*<Grid item xs={12} md={12}>
              <FormControl fullWidth variant="outlined" error={!!errors.subCompanyId}>
                <InputLabel id="subCompanyId-label">Tipo de Equipamento</InputLabel>
                <Select label="Tipo de Equipamento" {...register('typeEquipment')}>
                  {subCompany.map((item, index) => (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.subCompanyId?.message}</FormHelperText>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('name')}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Nome"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('imageUrl')}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Imagem do equipamento"
                  error={!!errors.imageUrl}
                  helperText={errors.imageUrl?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register('active')}
                    error={!!errors.active}
                    helperText={errors.active?.message}
                    defaultChecked
                  />
                }
                label="Status"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  mt: 3,
                  justifyContent: 'center',
                }}
              >
                <Button variant="contained" type="submit">
                  Salvar
                </Button>

                <Button
                  variant="contained"
                  type="button"
                  onClick={() => setExpanded(false)}
                  sx={{ ml: 2 }}
                >
                  Cancelar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default TemplateEquipamentCreate
