import { useCallback, useContext, useEffect, useState } from 'react'
import {
  createCountStockOrderService,
  searchProductService,
} from '../../../services/productService'
import { Context } from '../../../context/AuthContext'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'

const CountStockOrderCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [error, setError] = useState(false)
  const [countStockOrderNumberOtherSystem, setCountStockOrderNumberOtherSystem] = useState('')
  const [cicle, setCicle] = useState('')
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [productSelected, setProductSelected] = useState({})
  const [produtos, setProdutos] = useState([])

  const createCountStockOrder = async e => {
    e.preventDefault()
    setLoading(true)
    let countStockOrder = {
      movimentationOrderNumberOtherSystem: countStockOrderNumberOtherSystem,
      typeOrder: 6,
      subCompanyId,
      cicle,
    }

    if (cicle === 'Rotativo') {
      countStockOrder = {
        movimentationOrderNumberOtherSystem: countStockOrderNumberOtherSystem,
        typeOrder: 6,
        subCompanyId,
        cicle,
        productId: productSelected,
      }
    }

    console.log(countStockOrder, 'Rotation')

    const requiredFields = [
      { field: 'subCompanyId', label: 'Filial' },
      { field: 'cicle', label: 'Ciclico ou Rotativo' },
    ]

    const emptyFields = requiredFields.filter(field => !countStockOrder[field.field])

    if (emptyFields.length === requiredFields.length) {
      const missingFields = emptyFields.map(field => field.label).join(', ')
      setLoading(false)
      setSeverity('error')
      setTitle(
        <Typography textAlign="center" variant="body2" sx={{ color: 'darkred' }}>
          Erro ao cadastrar, preencha o(s) campo(s) obrigatório(s):{' '}
          <Typography textAlign="center" padding={1} variant="body2" fontWeight={700}>
            {missingFields}.
          </Typography>
        </Typography>
      )
      setAlerta(true)
      return
    }

    if (!cicle) {
      setLoading(false)
      setSeverity('error')
      setTitle(
        <Typography textAlign="center" variant="body2" sx={{ color: 'darkred' }}>
          Erro ao cadastrar, preencha o(s) campo(s) obrigatório(s):
          <Typography textAlign="center" padding={1} variant="body2" fontWeight={700}>
            Ciclico ou Rotativo.
          </Typography>
        </Typography>
      )
      setAlerta(true)
      return
    }

    try {
      await createCountStockOrderService(token, nameUrl, countStockOrder)
      console.log(countStockOrder, 'countStockOrder')
      setSeverity('success')
      setTitle('Contagem cadastrada com sucesso!')
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSubCompanyId('')
      setCicle('')
      setProductSelected()
      handleFetchData(countStockOrder.subCompanyId)
    } catch (err) {
      console.log(err)
      setSeverity('error')
      setTitle('Erro ao criar, verifique oa dados e tente novamente.')
      setLoading(false)
      setAlerta(true)
    }
  }

  const handleClose = () => {
    setError(false)
  }

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao cadastrar a Contagem!
          </Alert>
        </Snackbar>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createCountStockOrder}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Filial"
                value={subCompanyId}
                onChange={values => {
                  setSubCompanyId(values.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={countStockOrderNumberOtherSystem}
                onChange={values => setCountStockOrderNumberOtherSystem(values.target.value)}
                id="standard-basic"
                label="Insira o número (opcional)"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center', mt: 1 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box
              sx={{ textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    onChange={() => setCicle('Ciclico')}
                    checked={cicle === 'Ciclico'}
                  />
                }
                label="Ciclico"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    onChange={() => setCicle('Rotativo')}
                    checked={cicle === 'Rotativo'}
                  />
                }
                label="Rotativo"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              {cicle === 'Rotativo' && (
                <Autocomplete
                  disablePortal
                  id="lista-produtos"
                  options={produtos}
                  autoHighlight
                  autoSelect
                  //sx={{ width: 300 }}
                  loading={searching}
                  loadingText="Procurando..."
                  noOptionsText="Nenhum produto"
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.serial}>
                        {option.label}
                      </li>
                    )
                  }}
                  renderInput={params => <TextField {...params} label="Procurar produto" />}
                  onInputChange={(e, v) => {
                    setSearch(v)
                  }}
                  // onChange={(e, v) => setProductId(v)}
                  onChange={(e, v) => {
                    if (v === null) {
                      setProductSelected()
                    } else {
                      setProductSelected(v.productId)
                    }
                  }}
                />
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default CountStockOrderCreate
