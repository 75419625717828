import React, { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Box, Card, CardHeader, CircularProgress } from '@mui/material'

const TopUserEntryList = ({ getGrafic, loading, title, subCompanyId, startDate, finishDate }) => {
  const [dataGrafic, setDataGrafic] = useState([])

  const abreviateMonth = dateAndDay => {
    const [month, year] = dateAndDay.split('/')
    const abbreviatedMonth = month.slice(0, 3).toUpperCase()
    const formattedMonth = `${abbreviatedMonth.charAt(0)}${abbreviatedMonth.slice(1).toLowerCase()}`
    return `${formattedMonth}/${year}`
  }

  const standardizeData = dataArray => {
    if (!Array.isArray(dataArray)) {
      return []
    }
    return dataArray.map(item => ({
      dateAndDay: abreviateMonth(item.dateAndDay),
      quantity: item.quantity2Out || 0,
      quantity2Out: item.quantity || 0,
      quantityTotal: item.quantityTotal || 0,
      quantityOut: item.quantityOut || 0,
      name: item.name2Out || '',
      nameEmploye: item.nameEmploye,
    }))
  }
  const handleDataGrafic = async () => {
    if (getGrafic) {
      const standardizedData = await standardizeData(getGrafic)

      setDataGrafic(standardizedData)
    }
  }

  const generateChartData = () => {
    const categories = dataGrafic.map(item => item.dateAndDay)
    const nameEmploye = dataGrafic.map(item => item.nameEmploye).filter(name => name !== undefined)
    const series = []

    if (dataGrafic.length > 0) {
      const entrySeries = {
        name: 'Entrada',
        data: dataGrafic.map(item => item.quantity),
        color: '#008000',
      }

      const exitSeries = {
        name: 'Saída',
        data: dataGrafic.map(item => item.quantity2Out),
        color: '#FF0000',
      }

      // Adiciona a série de entrada se houver dados e a quantidade for diferente de zero
      if (entrySeries.data.some(value => value !== 0)) {
        series.push(entrySeries)
      }

      // Adiciona a série de saída se houver dados e a quantidade for diferente de zero
      if (exitSeries.data.some(value => value !== 0)) {
        series.push(exitSeries)
      }

      const entryTotalData = dataGrafic.map(item => ({
        x: item.dateAndDay, // Use 'x' para representar a categoria (data)
        y: item.quantityTotal,
        name: item.quantityTotal !== 0 ? item.name : '',
      }))

      const outTotalData = dataGrafic.map(item => ({
        x: item.dateAndDay,
        y: item.quantityOut,
        name: item.quantityOut !== 0 ? item.name : '', // Adiciona o nome do produto apenas se quantityOut for diferente de zero
      }))

      const entryTotalSeries = {
        name: 'Entrada Total',
        data: entryTotalData,
        color: '#008000',
      }

      const outTotalSeries = {
        name: 'Saída Total',
        data: outTotalData,
        color: '#FF0000',
      }

      if (entryTotalSeries.data.some(entry => entry.y !== 0)) {
        series.push(entryTotalSeries)
      }

      if (outTotalSeries.data.some(out => out.y !== 0)) {
        series.push(outTotalSeries)
      }
    }

    return {
      categories,
      nameEmploye,
      series,
    }
  }

  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '70%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top',
          },
          maxColumnWidth: 50,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          const value = val.toLocaleString('pt-BR')
          return value
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#000'],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            const data = w.config.series[seriesIndex].data[dataPointIndex]
            const value = val.toLocaleString('pt-BR')

            if (data.name === undefined) {
              return value
            } else {
              return `${data.name}`
            }
          },
        },
      },
    },
  })

  useEffect(() => {
    handleDataGrafic()
  }, [getGrafic])

  useEffect(() => {
    const { categories, series, nameEmploye } = generateChartData()

    setChartData(prevData => ({
      ...prevData,
      categories,
      series,
      options: {
        ...prevData.options,
        xaxis: {
          categories: nameEmploye,
        },
      },
    }))
  }, [dataGrafic])

  return (
    <Card
      sx={{ borderRadius: 2 }}
      variant="outlined"
      style={{ width: '100%', height: loading ? '500px' : '100%', position: 'relative' }}
    >
      <Box position="relative">
        {loading ? (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 260,
              left: '48%',
            }}
            color="primary"
          />
        ) : (
          dataGrafic && (
            <>
              <CardHeader title={title} sx={{ p: 1, textAlign: 'center' }} />
              <div id="chart">
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height={350}
                />
              </div>
            </>
          )
        )}
      </Box>
    </Card>
  )
}

export default TopUserEntryList
