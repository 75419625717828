import React from 'react'
import {
  Card,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  Container,
} from '@mui/material'
import { Home, Message, Money } from '@mui/icons-material'
import CircularProgressComponent from './Components/CircularProgressComponent'
import CardDashboard from './Components/CardDashboard/cardDashboard'
import CountReceiver from './Components/CardDashboard/countReceiver'
import CardDashboardTwo from './Components/CardDashboard/cardDashboardTwo'
import CardDashboardThreere from './Components/CardDashboard/cardDashboardThreere'
import CardDashboardT2 from './Components/CardDashboard/cardDashboardT2'

const contasReceberAPagarNumeros = [
  {
    cliente: 'ALAN GLEDSON DA SILVA',
    valorOriginal: 8446.39,
    saldoAtual: 8446.39,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 8446.39,
  },
  {
    cliente: 'ANA CLEMENTINA DE OLIVEIRA LIMA',
    valorOriginal: 3624.32,
    saldoAtual: 3624.32,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 3624.32,
  },
  {
    cliente: 'ANDERSON ALVES SIQUEIRA',
    valorOriginal: 22697.95,
    saldoAtual: 22697.95,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 22697.95,
  },
  {
    cliente: 'ANDRÉ LEODEL DE SOUZA',
    valorOriginal: 9086.12,
    saldoAtual: 9086.12,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 9086.12,
  },
  {
    cliente: 'ANDRES DOS SANTOS GARCIA',
    valorOriginal: 36230.77,
    saldoAtual: 36230.77,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 36230.77,
  },
  {
    cliente: 'BRUNA SOARES DOS SANTOS',
    valorOriginal: 343.18,
    saldoAtual: 343.18,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 343.18,
  },
  {
    cliente: 'CAROLINE DE QUEIROZ',
    valorOriginal: 363.11,
    saldoAtual: 363.11,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 363.11,
  },
  {
    cliente: 'CLAUDETE FORTINO ALVARENGA',
    valorOriginal: 4432.41,
    saldoAtual: 4432.41,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 4432.41,
  },
  {
    cliente: 'CLAUDIO DA SILVA',
    valorOriginal: 449.12,
    saldoAtual: 449.12,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 449.12,
  },
  {
    cliente: 'EDICLÉA APARECIDA FONSECA DA SILVA',
    valorOriginal: 9462.38,
    saldoAtual: 9462.38,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 9462.38,
  },
  {
    cliente: 'EDNA APOLINÁRIO DOS SANTOS SILVA',
    valorOriginal: 236.31,
    saldoAtual: 236.31,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 236.31,
  },
  {
    cliente: 'EDSON CAMARGO',
    valorOriginal: 222.12,
    saldoAtual: 222.12,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 222.12,
  },
  {
    cliente: 'EDSON PEDROSO DE RAMOS JUNIOR',
    valorOriginal: 700.8,
    saldoAtual: 700.8,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 700.8,
  },
  {
    cliente: 'ÉRICA FERREIRA DE QUEIROZ SILVA',
    valorOriginal: 20053.12,
    saldoAtual: 20053.12,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 20053.12,
  },
  {
    cliente: 'FABIANO FERRAZ MACHADO',
    valorOriginal: 3667.57,
    saldoAtual: 3480.12,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 3480.12,
  },
  {
    cliente: 'FABIO APARECIDO ALVES DA SILVA',
    valorOriginal: 8475.52,
    saldoAtual: 8210.66,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 8210.66,
  },
  {
    cliente: 'GLEICE NASCIMENTO COSTA',
    valorOriginal: 2311.13,
    saldoAtual: 2311.13,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 2311.13,
  },
  {
    cliente: 'GUILHERME RAFAEL VASQUE',
    valorOriginal: 27403.11,
    saldoAtual: 27403.11,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 27403.11,
  },
  {
    cliente: 'JESSICA VAZ DOMINGUES RODRIGUES',
    valorOriginal: 3945.5,
    saldoAtual: 3945.5,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 3945.5,
  },
  {
    cliente: 'JOÃO PAULO DE MORAES',
    valorOriginal: 353.36,
    saldoAtual: 353.36,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 353.36,
  },
  {
    cliente: 'JONAS VIEIRA PEDROSO',
    valorOriginal: 2347.02,
    saldoAtual: 2347.02,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 2347.02,
  },
  {
    cliente: 'JOSE MARIA DIAS DE PONTES',
    valorOriginal: 9234.18,
    saldoAtual: 9234.18,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 9234.18,
  },
  {
    cliente: 'JOYCE CRISTINA ANDRADE DA CUNHA LEMES',
    valorOriginal: 290.03,
    saldoAtual: 290.03,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 290.03,
  },
  {
    cliente: 'JUCELENA SILVA',
    valorOriginal: 3060.45,
    saldoAtual: 3060.45,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 3060.45,
  },
  {
    cliente: 'LUIS OTAVIO DOS SANTOS CORREA DE OLIVEIRA',
    valorOriginal: 17569.42,
    saldoAtual: 17569.42,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 17569.42,
  },
  {
    cliente: 'LUIZ CARLOS RODRIGUES',
    valorOriginal: 228.35,
    saldoAtual: 228.35,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 228.35,
  },
  {
    cliente: 'MARCOS FERNANDO DE OLIVEIRA',
    valorOriginal: 11495.7,
    saldoAtual: 11495.7,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 11495.7,
  },
  {
    cliente: 'MARCUS VINICIUS DE ALMEIDA CORDEIRO',
    valorOriginal: 763.02,
    saldoAtual: 763.02,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 763.02,
  },
  {
    cliente: 'MARLI DE JESUS SILVA',
    valorOriginal: 403.54,
    saldoAtual: 403.54,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 403.54,
  },
  {
    cliente: 'MEIRE DIAS DE MORAES',
    valorOriginal: 21891.54,
    saldoAtual: 21891.54,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 21891.54,
  },
  {
    cliente: 'PAULO RIBEIRO BOTELHO',
    valorOriginal: 4347.08,
    saldoAtual: 4347.08,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 4347.08,
  },
  {
    cliente: 'PEDRO PAULO CORREIA MOREIRA',
    valorOriginal: 20840.85,
    saldoAtual: 20840.85,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 20840.85,
  },
  {
    cliente: 'RAFAELA FERNANDA DOS SANTOS BARBOSA',
    valorOriginal: 11761.02,
    saldoAtual: 11761.02,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 11761.02,
  },
  {
    cliente: 'RENAN IZIDORO DOS ANJOS',
    valorOriginal: 235.99,
    saldoAtual: 235.99,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 235.99,
  },
  {
    cliente: 'RICARDO CESAR MARANHÃO DA SILVA',
    valorOriginal: 5737.24,
    saldoAtual: 5737.24,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 5737.24,
  },
  {
    cliente: 'RODINEI ALMEIDA RODRIGUES',
    valorOriginal: 5386.22,
    saldoAtual: 5386.22,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 5386.22,
  },
  {
    cliente: 'VALDIRENE OLIVEIRA PACHECO PROENÇA',
    valorOriginal: 11989.0,
    saldoAtual: 11797.18,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 11797.18,
  },
  {
    cliente: 'VIVIAN STEFANI LEMES DA SILVA',
    valorOriginal: 785.67,
    saldoAtual: 785.67,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 785.67,
  },
  {
    cliente: 'WELISON CARLOS PESSOA DE OLIVEIRA',
    valorOriginal: 10931.96,
    saldoAtual: 10931.96,
    acrescimo: 0.0,
    desconto: 0.0,
    seguro: 0.0,
    taxaAdm: 0.0,
    total: 10931.96,
  },
]

const resumoContas = [
  {
    total: 0,
    cliente: 'João Romão',
    inadimplentescronicos: 0,
    areceber: 0,
  },
  {
    total: 1430.59,
    cliente: 'Terras do Sol',
    inadimplentescronicos: 840.92,
    areceber: 589.67,
  },
  {
    total: 9256.22,
    cliente: 'Aroeira',
    inadimplentescronicos: 617.85,
    areceber: 8638.37,
  },
]

const contasAReceber = [
  {
    data_vecto: '17/03/2024',
    client: 'ALAN PETERSON CARVALHO PEREIRA',
    document: '(193) CT.ARO-065',
    title: 387,
    parc: 16,
    tc: 'PM',
    unid_princ: 65,
    original_value: 601.58,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 601.58,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 601.58,
  },
  {
    data_vecto: '18/03/2024',
    client: 'SAULO CANDIDO DA SILVA',
    document: '(212) CT.ARO-012',
    title: 419,
    parc: 13,
    tc: 'PM',
    unid_princ: 12,
    original_value: 413.76,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 413.76,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 413.76,
  },
  {
    data_vecto: '20/03/2024',
    client: 'ALINE AMORIM DOS SANTOS',
    document: '(201) CT.ARO-138',
    title: 408,
    parc: 16,
    tc: 'PM',
    unid_princ: 138,
    original_value: 265.65,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 265.65,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 265.65,
  },
  {
    data_vecto: '20/03/2024',
    client: 'BRUNA APARECIDA GODINHO DA SILVA',
    document: '(266) CT.ARO--086',
    title: 500,
    parc: 2,
    tc: 'PM',
    unid_princ: 86,
    original_value: 1334.45,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 1334.45,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 1334.45,
  },
  {
    data_vecto: '20/03/2024',
    client: 'CRISTIANE DOMINGUES DIAS',
    document: '(204) CT.ARO-15',
    title: 405,
    parc: 15,
    tc: 'PM',
    unid_princ: 15,
    original_value: 732.2,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 732.2,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 732.2,
  },
  {
    data_vecto: '21/03/2024',
    client: 'MAYLA CRISTINA CARRIEL ALMEIDA',
    document: '(231) CT.ARO-018',
    title: 436,
    parc: 11,
    tc: 'PM',
    unid_princ: 18,
    original_value: 316.25,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 316.25,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 316.25,
  },
  {
    data_vecto: '22/03/2024',
    client: 'ALINE FABRINE DE MORAES',
    document: '(175) CT.ARO-079',
    title: 378,
    parc: 17,
    tc: 'PM',
    unid_princ: 79,
    original_value: 263.4,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 263.4,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 263.4,
  },
  {
    data_vecto: '22/03/2024',
    client: 'ANDERSON HENRIQUE GONÇALVES',
    document: '(148) CT.ARO-135',
    title: 348,
    parc: 17,
    tc: 'PM',
    unid_princ: 135,
    original_value: 535.5,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 535.5,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 535.5,
  },
  {
    data_vecto: '22/03/2024',
    client: 'JOSUE ARAO CALEB MARCONDES BARBOSA',
    document: '(173) CT.ARO059',
    title: 363,
    parc: 16,
    tc: 'PM',
    unid_princ: 59,
    original_value: 683.9,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 683.9,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 683.9,
  },
  {
    data_vecto: '22/03/2024',
    client: 'LAURO NOETZOLD JUNIOR',
    document: '(196) CT.ARO-099',
    title: 388,
    parc: 17,
    tc: 'PM',
    unid_princ: 99,
    original_value: 685.92,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 685.92,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 685.92,
  },
  {
    data_vecto: '22/03/2024',
    client: 'LUCAS VINICIUS ALVES DA SILVA',
    document: '(165) CT.ARO-010',
    title: 362,
    parc: 20,
    tc: 'PM',
    unid_princ: 10,
    original_value: 558.23,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 558.23,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 558.23,
  },
  {
    data_vecto: '22/03/2024',
    client: 'QUETILIN DE LIMA PEREIRA',
    document: '(156) CT.ARO-107',
    title: 357,
    parc: 17,
    tc: 'PM',
    unid_princ: 107,
    original_value: 641.33,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 641.33,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 641.33,
  },
  {
    data_vecto: '22/03/2024',
    client: 'RAFAEL ALMEIDA DA COSTA',
    document: '(178) CT.ARO--084',
    title: 376,
    parc: 17,
    tc: 'PM',
    unid_princ: 84,
    original_value: 374.26,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 374.26,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 374.26,
  },
  {
    data_vecto: '22/03/2024',
    client: 'VANDA DA SILVA',
    document: '(194) CT.ARO-081',
    title: 391,
    parc: 15,
    tc: 'PM',
    unid_princ: 81,
    original_value: 1231.94,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 1231.94,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 1231.94,
  },
  {
    data_vecto: '22/03/2024',
    client: 'VIVIANE CONSUELO EZIDIO',
    document: '(155) CT.ARO-087',
    title: 356,
    parc: 17,
    tc: 'PM',
    unid_princ: 87,
    original_value: 617.85,
    id: 0,
    calculation_date: '01/03/2024',
    current_balance: 617.85,
    days: 0,
    increase: 0.0,
    discount: 0.0,
    insurance: 0.0,
    admin_fee: 0.0,
    total: 617.85,
  },
]

const contasAPagarFicticias = [
  { client: 'Maria Silva', original_value: 500.0 },
  { client: 'José Oliveira', original_value: 750.0 },
  { client: 'Ana Santos', original_value: 1200.0 },
  { client: 'Paulo Souza', original_value: 350.0 },
  { client: 'Fernanda Costa', original_value: 900.0 },
  { client: 'Carlos Lima', original_value: 650.0 },
  { client: 'Juliana Pereira', original_value: 800.0 },
  { client: 'Rafael Almeida', original_value: 1100.0 },
  { client: 'Luciana Martins', original_value: 430.0 },
  { client: 'Daniel Ferreira', original_value: 980.0 },
  { client: 'Aline Santos', original_value: 550.0 },
  { client: 'Pedro Oliveira', original_value: 720.0 },
  { client: 'Mariana Alves', original_value: 880.0 },
  { client: 'André Costa', original_value: 620.0 },
  { client: 'Camila Lima', original_value: 1050.0 },
  { client: 'Gustavo Fernandes', original_value: 480.0 },
  { client: 'Isabela Souza', original_value: 770.0 },
  { client: 'Vinícius Pereira', original_value: 1130.0 },
  { client: 'Fernanda Oliveira', original_value: 390.0 },
  { client: 'Roberto Santos', original_value: 850.0 },
]

function DashboardMock() {
  return (
    <Box mt={6}>
      <Typography variant="h5" fontWeight={700}>
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <Box display="flex" alignItems="center" justifyContent="end" gap={2} width="100%">
            <TextField fullWidth type="date" />
            <TextField fullWidth type="date" />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Card
            variant="outlined"
            sx={{
              borderRadius: 4,
              height: '120px',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              height="100%"
              p={3}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#1EA47E ',
                    width: '3rem',
                    height: '3rem',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Home sx={{ color: 'white', fontSize: 25 }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  <Typography textAlign="end" variant="body1">
                    Total de casas vendidas
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    108
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* <Divider />

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body1">+14%</Typography>
      </Box> */}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card
            variant="outlined"
            sx={{
              borderRadius: 4,
              height: '120px',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              height="100%"
              p={3}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#E33F2B',
                    width: '3rem',
                    height: '3rem',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Home sx={{ color: 'white', fontSize: 25 }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  <Typography textAlign="end" variant="body1">
                    Total de casas não vendias
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    52
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* <Divider />

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body1">+14%</Typography>
      </Box> */}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card
            variant="outlined"
            sx={{
              borderRadius: 4,
              pb: 8,
              px: 2,
              pt: 2,
              height: '600px',
            }}
          >
            <Typography pb={2} variant="h6" fontWeight={700}>
              Contas a receber
            </Typography>
            <CardDashboard handleGetDashDash={contasAReceber} />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card
            variant="outlined"
            sx={{
              borderRadius: 4,
              pb: 8,
              px: 2,
              pt: 2,
              height: '600px',
            }}
          >
            <Typography pb={2} variant="h6" fontWeight={700}>
              Contas a pagar
            </Typography>
            <CardDashboardTwo handleGetDashDash={contasAPagarFicticias} />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card
            variant="outlined"
            sx={{
              borderRadius: 4,
              pb: 8,
              px: 2,
              pt: 2,
              height: '600px',
            }}
          >
            <Typography pb={2} variant="h6" fontWeight={700}>
              Casas vendidas no mês
            </Typography>
            <CardDashboardThreere handleGetDashDash={contasAReceber} />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card
            variant="outlined"
            sx={{
              borderRadius: 4,
              pb: 8,
              px: 2,
              pt: 2,
              height: '600px',
            }}
          >
            <Typography pb={2} variant="h6" fontWeight={700}>
              Casas não vendidas no mês
            </Typography>
            <CardDashboardT2 handleGetDashDash={contasReceberAPagarNumeros} />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DashboardMock
