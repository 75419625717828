import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  createIntegrationDefaultService,
  getIntegrationDefaultService,
} from '../../../services/admServices'
import { createIntegrationFieldService } from '../../../services/configServices'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  styled,
  Paper,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const IntegrationDefaultCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
}) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [name, setName] = useState('')
  const [urlRedirect, setUrlRedirect] = useState('')
  const [urlCallback, setUrlCallback] = useState('')
  const [urlApi, setUrlApi] = useState('')
  const [scopeList, setScopeList] = useState('')
  const navigate = useNavigate()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const [process, setProcess] = useState(false)
  const [processMsg, setProcessMsg] = useState('Carregando')
  const [oldList, setOldList] = useState([])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const [newField, setNewField] = useState({
    fieldName: 'Escolher',
    value: '',
  })

  const possibleFields = [
    { fieldName: 'Tipo Token', value: 'tokenType' },
    { fieldName: 'Nome', value: 'name' },
    { fieldName: 'E-mail', value: 'email' },
    { fieldName: 'Nome Usuário', value: 'userName' },
    { fieldName: 'Senha', value: 'password' },
    { fieldName: 'Token Acesso', value: 'accessToken' },
    { fieldName: 'Token Cliente', value: 'clientToken' },
    { fieldName: 'Secret Cliente', value: 'clientSecret' },
    { fieldName: 'Refresh Token', value: 'refreshToken' },
    { fieldName: 'Expira em', value: 'expiresIn' },
    { fieldName: 'App ID', value: 'appId' },
  ]

  const [addedFields, setAddedFields] = useState([
    { fieldName: 'Tipo Token', value: 'tokenType', added: false },
    { fieldName: 'Nome', value: 'name', added: false },
    { fieldName: 'E-mail', value: 'email', added: false },
    { fieldName: 'Nome Usuário', value: 'userName', added: false },
    { fieldName: 'Senha', value: 'password', added: false },
    { fieldName: 'Token Acesso', value: 'accessToken', added: false },
    { fieldName: 'Token Cliente', value: 'clientToken', added: false },
    { fieldName: 'Secret Cliente', value: 'clientSecret', added: false },
    { fieldName: 'Refresh Token', value: 'refreshToken', added: false },
    { fieldName: 'Expira em', value: 'expiresIn', added: false },
    { fieldName: 'App ID', value: 'appId', added: false },
  ])

  const addField = () => {
    const newState = addedFields.map(field => {
      if (field.value === newField) {
        return { ...field, added: true }
      }

      return field
    })
    setAddedFields(newState)
  }

  const deleteField = f => {
    const newState = addedFields.map(field => {
      if (field.value === f) {
        return { ...field, added: false }
      }
      return field
    })
    setAddedFields(newState)
  }

  const createIntDefault = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      const intDefault = {
        name: name,
        urlRedirect: urlRedirect,
        urlCalback: urlCallback,
        urlApi: urlApi,
        scopeList: scopeList,
        active: true,
      }

      try {
        setProcess(true)
        setProcessMsg('Criando Integração...')
        const response = await createIntegrationDefaultService(token, nameUrl, intDefault)
        const response2 = await getIntegrationDefaultService(token, nameUrl, 100000, 0, false)
        const newList = response2.data.result
        setProcessMsg('Comparando listas...')
        const diffIntegration = newList.filter(
          ({ integrationDefaultId: id1 }) =>
            !oldList.some(({ integrationDefaultId: id2 }) => id2 === id1)
        )
        setProcessMsg('Adicionando campos...')
        const campos = addedFields.filter(x => x.added === true)
        await Promise.all(
          campos.map(async item => {
            await addIntegrationField(item, diffIntegration[0].integrationDefaultId)
          })
        )
        setLoading(false)
        setExpanded(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Integração criada com sucesso!')
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro ao criar integração, verifique os dados e tente novamente.')
      }
    },
    [name, urlRedirect, urlCallback, urlApi, scopeList, addedFields]
  )

  const addIntegrationField = async (campo, defaultId) => {
    const dados = {
      label: campo.fieldName,
      integrationDefaultId: defaultId,
      type: campo.value,
    }
    try {
      const response = await createIntegrationFieldService(token, nameUrl, dados)
    } catch (e) {
      console.log(e)
    }
  }

  const getOldList = async () => {
    setProcess(true)
    setProcessMsg('Carregando lista...')
    try {
      const response = await getIntegrationDefaultService(token, nameUrl, 100000, 0, false)
      setOldList(response.data.result)
      setProcess(false)
      setProcessMsg('Pronto')
    } catch (e) {
      setProcess(false)
      setProcessMsg('Ocorreu um erro')
      console.log(e)
    }
  }

  const getNewList = async () => {
    let newList
    setProcess(true)
    setProcessMsg('Carregando nova lista...')
    try {
      const response = await getIntegrationDefaultService(token, nameUrl, 100000, 0, false)
      newList = response.data.result
      setProcess(false)
      setProcessMsg('Comparando listas...')
      let diffIntegration = oldList.filter(x => !newList.includes(x.integrationDefaultId))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getOldList()
  }, [])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createIntDefault}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={urlRedirect}
                onChange={values => setUrlRedirect(values.target.value)}
                id="outlined-basic"
                label="Url de Redirecionamento"
                variant="outlined"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={urlCallback}
                onChange={values => setUrlCallback(values.target.value)}
                id="outlined-basic"
                label="Url de Retorno"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={urlApi}
                onChange={values => setUrlApi(values.target.value)}
                id="outlined-basic"
                label="Url da API"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={scopeList}
                onChange={values => setScopeList(values.target.value)}
                id="outlined-basic"
                label="Lista de Escopo"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel>Adicionar Campos</InputLabel>
              <Select
                sx={{ width: '100%' }}
                value={newField}
                onChange={values => setNewField(values.target.value)}
                id="outlined-basic"
                variant="outlined"
                type="text"
              >
                {possibleFields.map((item, index) => {
                  return (
                    <MenuItem value={item.value} key={index}>
                      {item.fieldName}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              sx={{ mt: 2, alignSelf: 'end' }}
            >
              <Button variant="contained" onClick={addField} sx={{ mt: 1 }}>
                Adicionar
              </Button>
            </Box>
          </Box>

          {addedFields.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Table sx={{ minWidth: 300 }} aria-label="customized table">
                <TableBody>
                  {addedFields.map(
                    row =>
                      row.added && (
                        <StyledTableRow key={row.name}>
                          <StyledTableCell align="center" sx={{ width: 50 }}>
                            <DeleteForeverIcon
                              onClick={() => deleteField(row.value)}
                              sx={{ cursor: 'pointer' }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center" sx={{ width: 200 }}>
                            {row.fieldName}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => navigate('/IntegrationDefault')}
                sx={{ width: '100%', ml: 1 }}
              >
                Voltar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default IntegrationDefaultCreate
