import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  createCompanyService,
  getPlanService,
  getSegmentService,
} from '../../../services/admServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const CompanyCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [code, setCode] = useState('')
  const [cnpj, setCnpj] = useState()
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [cep, setCep] = useState('')
  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const [ddd, setDdd] = useState('')
  const [numberAddress, setNumberAddress] = useState()
  const [status, setStatus] = useState(true)
  const [urlSend, setUrlSend] = useState('')
  const [planId, setPlanId] = useState('')
  const [plan, setPlan] = useState([])
  const [nameEmployer, setNameEmployer] = useState('')
  const [segmentId, setSegmentId] = useState('')
  const [segment, setSegment] = useState([])
  const [quantitySubCompanyActual, setQuantitySubCompanyActual] = useState(0)
  const [quantitySubCompanyTotal, setQuantitySubCompanyTotal] = useState(0)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const createCompany = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const date = new Date()

      const company = {
        code: code,
        cnpj: parseInt(cnpj),
        name: name,
        mobile: mobile,
        email: email,
        cep: cep,
        city: city,
        address: address,
        ddd: ddd,
        numberAddress: parseInt(numberAddress),
        active: status,
        urlSend: urlSend,
        plansId: planId,
        nameEmployer: nameEmployer,
        segmentId: segmentId,
        created: date,
        quantitySubCompanyActual: quantitySubCompanyActual,
        quantitySubCompanyTotal: quantitySubCompanyTotal,
      }
      try {
        await createCompanyService(token, nameUrl, company)
        setLoading(false)
        setExpanded(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Empresa cadastrada com sucesso!')
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
      }
    },
    [
      code,
      cnpj,
      name,
      mobile,
      email,
      cep,
      city,
      address,
      ddd,
      numberAddress,
      status,
      urlSend,
      planId,
      nameEmployer,
      segmentId,
      quantitySubCompanyActual,
      quantitySubCompanyTotal,
    ]
  )

  const getSegment = useCallback(async () => {
    try {
      const response = await getSegmentService(token, nameUrl, 100, 0, true)
      setSegment(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const getPlan = useCallback(async () => {
    try {
      const response = await getPlanService(token, nameUrl, 100, 0, true)
      setPlan(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    getSegment()
    getPlan()
  }, [])

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createCompany}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel id="demo-simple-select-label">Segmento</InputLabel>
              <Select
                label="Segmento"
                value={segmentId}
                onChange={values => {
                  setSegmentId(values.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {segment.map((item, index) => {
                  return (
                    <MenuItem value={item.segmentId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              marginTop={{ xs: '1rem', sm: '1rem', md: 0, lg: 0, xl: 0 }}
            >
              <InputLabel id="demo-simple-select-label">Plano</InputLabel>
              <Select
                label="Plano"
                value={planId}
                onChange={values => {
                  setPlanId(values.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {plan.map((item, index) => {
                  return (
                    <MenuItem value={item.plansId} key={index}>
                      {item.plansName}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={cnpj}
                onChange={values => setCnpj(values.target.value)}
                id="outlined-basic"
                label="Cnpj"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={ddd}
                onChange={values => setDdd(values.target.value)}
                id="outlined-basic"
                label="DDD"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{ mt: 3, textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <TextField
                sx={{ width: '100%' }}
                value={email}
                onChange={values => setEmail(values.target.value)}
                id="outlined-basic"
                label="Email da Empresa"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={city}
                onChange={values => setCity(values.target.value)}
                id="outlined-basic"
                label="Cidade"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{ mt: 3, textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <TextField
                sx={{ width: '100%' }}
                value={numberAddress}
                onChange={values => setNumberAddress(values.target.value)}
                id="outlined-basic"
                label="Número do Local"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={nameEmployer}
                onChange={values => setNameEmployer(values.target.value)}
                id="outlined-basic"
                label="Sigla"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{ mt: 3, textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <TextField
                sx={{ width: '100%' }}
                value={code}
                onChange={values => setCode(values.target.value)}
                id="outlined-basic"
                label="Código"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={mobile}
                onChange={values => setMobile(values.target.value)}
                id="outlined-basic"
                label="Telefone"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{ mt: 3, textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <TextField
                sx={{ width: '100%' }}
                value={cep}
                onChange={values => setCep(values.target.value)}
                id="outlined-basic"
                label="CEP"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box
              sx={{ mt: 3 }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              marginTop={{ xs: 3, sm: 3, md: 6, lg: 6, xl: 6 }}
            >
              <TextField
                sx={{ width: '100%' }}
                value={address}
                onChange={values => setAddress(values.target.value)}
                id="outlined-basic"
                label="Endereço"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{ mt: 3, textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <InputLabel color="error">Opicional</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={urlSend}
                onChange={values => setUrlSend(values.target.value)}
                id="outlined-basic"
                label="Url de Envio dos Dados"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" type="submit">
              Salvar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => setExpanded(false)}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default CompanyCreate
