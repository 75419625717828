import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

export const ComponentTableAccordion = ({
  data,
  columns,
  columnsFilds,
  arrayFiled,
  placeholderSearch,
  iconBtn,
  heightTbodyMessage = '500px',
  titleBtn,
  header = true,
  numberPage = 10,
  loading,
  pagination = true,
  value,
  onChange,
  grid1,
  grid2,
  tableRow,
  additionalStyles = {},
  onclickBtn,
  user,
}) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(numberPage)

  const indexOfLastItem = (page + 1) * rowsPerPage
  const indexOfFirstItem = indexOfLastItem - rowsPerPage
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const selectedRows = []

  function Row({ row, columns, arrayField, onToggle }) {
    const [open, setOpen] = useState(false)

    const handleToggle = () => {
      setOpen(!open)
      if (onToggle) {
        onToggle(!open, row.name, row)
      }
    }

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={handleToggle}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          {columns.map((column, index) => (
            <TableCell key={`accordion-cell-${index}`} sx={{ padding: '15px 15px' }}>
              {column.element(row)}
            </TableCell>
          ))}
        </TableRow>

        <TableRow>
          <TableCell colSpan={columns.length + 1} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="nested table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ bgcolor: '#000', color: '#fff', padding: '15px 15px' }}
                    ></TableCell>
                    {columnsFilds.map((column, index) => (
                      <TableCell
                        sx={{ bgcolor: '#000', color: '#fff', padding: '15px 15px' }}
                        key={`column-${index}`}
                        style={{
                          maxWidth: column.maxWidth,
                          width: column.maxWidth,
                          textAlign: column.textAlignPosition || 'start',
                        }}
                      >
                        {column.heading}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.map((arrayItem, arrayIndex) => (
                    <TableRow key={`nested-row-${arrayIndex}`}>
                      <TableCell>{arrayItem.heading}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <Box
      sx={{
        border: '1px solid rgba(224, 224, 224, 1)',
        height: '100%',
        borderRadius: 2,
        ...additionalStyles,
      }}
    >
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          borderRadius: '0px',
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          ...additionalStyles,
        }}
      >
        <TableContainer>
          {header && (
            <Typography sx={{ mb: 1, ml: 2 }}>
              {currentData.length > 1
                ? currentData.length + ' Resultados'
                : currentData.length + ' Resultado'}{' '}
            </Typography>
          )}
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ bgcolor: '#000', color: '#fff', padding: '15px 15px' }}
                ></TableCell>
                {columns.map((column, index) => (
                  <TableCell
                    sx={{ bgcolor: '#000', color: '#fff', padding: '15px 15px' }}
                    key={`column-${index}`}
                    style={{
                      maxWidth: column.maxWidth,
                      width: column.maxWidth,
                      textAlign: column.textAlignPosition || 'start',
                    }}
                  >
                    {column.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody sx={{ height: heightTbodyMessage }}>
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    align="center"
                    sx={{ flexGrow: 2, p: '15px 15px' }}
                  >
                    {/* <ComponentLoading /> */}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {currentData.length > 0 && (
                  <TableBody>
                    {currentData.map((row, rowIndex) => (
                      <Row key={`row-${rowIndex}`} row={row} columns={columns} />
                    ))}
                  </TableBody>
                )}

                {!currentData.length && (
                  <TableBody sx={{ height: heightTbodyMessage }}>
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center" sx={{ flexGrow: 2 }}>
                        {/* <CustomNoRowsOverlay /> */}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            labelRowsPerPage={'Itens por página'}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            rowsPerPageOptions={[numberPage, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </Box>
  )
}
