import { Context } from '../../../context/AuthContext'
import { useCallback, useContext, useEffect, useState } from 'react'
import { RESULTS_PER_PAGE } from '../../../utils/excel'
import { handleAntiTheft, handleAntiTheftGet } from '../../../services/reportServices'
import * as XLSX from 'xlsx'
import { AnthiTheft } from '../../../const/RoleConstants'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import HelpIcon from '@mui/icons-material/Help'

import { setPermission } from '../../../permissions/userPermissions'
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined'
import PropTypes from 'prop-types'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

//icones react-icons
import { AiFillFileExcel, AiOutlineFileExcel } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import moment from 'moment'

const AntiTheftReports = () => {
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [totalPagesDownloadCount, setTotalPagesDownloadCount] = useState('')
  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [authorized, setAuthorized] = useState(true)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [canSearch, setCanSearch] = useState(false)

  const currentMonth = moment().startOf('month')
  const firstDay = currentMonth.format('YYYY-MM-DD')
  const lastDay = currentMonth.endOf('month').format('YYYY-MM-DD')

  const [form, setForm] = useState({
    createDate: {
      value: firstDay,
      error: false,
    },
    endDate: {
      value: lastDay,
      error: false,
    },
    companySelect: {
      value: '',
      error: false,
    },
  })

  useEffect(() => {
    const data = {
      createDateTime: form.createDate.value,
      endDateTime: form.endDate.value,
      subCompanyId: form.companySelect.value,
    }
    if (data.createDateTime !== '' && data.endDateTime !== '' && data.subCompanyId !== '') {
      setCanSearch(true)
    }
  }, [form])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const handleInput = e => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: {
        value,
      },
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    let hasError = false
    const data = {
      createDateTime: form.createDate.value,
      endDateTime: form.endDate.value,
      subCompanyId: form.companySelect.value, //"8c013f2d-4f38-49b4-92ba-97cd888ca7df"
      limit: 10,
      offset: 1,
      order: 'desc',
    }
    if (!data.createDateTime || data.createDateTime === '') {
      setForm({
        ...form,
        createDate: {
          error: true,
        },
      })
    }

    if (!data.endDateTime || data.endDateTime === '') {
      setForm({
        ...form,
        endDate: {
          error: true,
        },
      })
    }

    if (!data.subCompanyId || data.subCompanyId === '') {
      setForm({
        ...form,
        companySelect: {
          error: true,
        },
      })
    }

    if (!hasError) {
      setLoading(true)
      try {
        //const response = await handleAntiTheft(token, nameUrl, data)

        const response = await handleAntiTheftGet(token, nameUrl, data)
        if (response.data.result.length === 0) {
          setTitle('Não existem dados para essa consulta.')
          setAlerta(true)
          setSeverity('error')
        } else {
          setTableData(response.data.result)
        }
        setLoading(false)
      } catch (e) {
        console.log(e)
        setLoading(false)
      }
    }
  }

  //excel
  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)

    const data = {
      createDateTime: form.createDate.value,
      endDateTime: form.endDate.value,
      subCompanyId: form.companySelect.value, //"8c013f2d-4f38-49b4-92ba-97cd888ca7df"
      limit: 10,
      offset: 1,
      order: 'desc',
    }
    await handleAntiTheftGet(token, nameUrl, data).then(r => {
      setTotalPagesDownloadCount(r.data.totalpage + 1)
      finishDownloadCount(r.data.totalpage + 1)
    })
    //finishDownloadCount(tableData.length)
  }

  const finishDownloadCount = async pages => {
    let result = []
    for (let i = 0; i <= pages; i++) {
      setDownloadMessageCount(`Baixando ${i} de ${pages}...`)
      setProgressCount((i / pages) * 100)
      const data = {
        subCompanyId: form.companySelect.value,
        createDateTime: form.createDate.value,
        endDateTime: form.endDate.value,
        limit: 10,
        order: 'desc',
        offset: i + 1,
      }
      console.log(form, 'sadosajjdaso')
      const response = await handleAntiTheftGet(token, nameUrl, data)
      result = result.concat(response.data.result)
    }
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalContagem = []
    result.map(item => {
      relatorioFinalContagem.push({
        'Código do produto': item.idProductPartner,
        'Nome do produto': item.productName,
        'Código de barras': item.barCode,
        Marca: item.brand,
        Cor: item.color,
        Referencia: item.referencia,
        Tamanho: item.size,
        SKU: item.sku,
        Criação: new Date(item.receiveCreate).toLocaleString(),
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalContagem)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Anti-Furto')
    XLSX.writeFile(workbook, `Relatório Anti-Furto.xlsx`)
    setProgressCount(0)
    setDownloadingCount(false)
  }
  //fim excel

  const handleClose = () => {
    setAlerta(false)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={!authorized}
          // TransitionComponent={Transition}
          // keepMounted
          //onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
        >
          <DialogTitle style={{ fontSize: '1.2em' }} id="alert-dialog-title">
            Voce não tem permissão para acessar esses dados.
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ fontSize: '1.2em' }} id="alert-dialog-slide-description">
              Verifique suas permissões
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ fontSize: '1em' }} onClick={() => window.history.back()}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'AntiTheftReports'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Anti Theft</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box component="form" onSubmit={handleSubmit}>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 2, width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Selecione a filial"
                onChange={e => handleInput(e)}
                name="companySelect"
                sx={{ width: '100%' }}
                value={form.companySelect.value ? form.companySelect.value : ''}
              >
                <MenuItem value="" disabled key="disabled">
                  Filial
                </MenuItem>
                {subCompany &&
                  subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Data de criação</InputLabel>
              <TextField
                variant="outlined"
                value={form.createDate.value}
                onChange={e => handleInput(e)}
                sx={{ width: '100%' }}
                name="createDate"
                type="date"
              />
              {form.createDate.error && (
                <Typography variant="body1" style={{ color: 'red' }}>
                  Insira a data de criação
                </Typography>
              )}
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Data final</InputLabel>
              <TextField
                variant="outlined"
                value={form.endDate.value}
                onChange={e => handleInput(e)}
                sx={{ width: '100%' }}
                name="endDate"
                type="date"
              />
              {form.endDate.error && (
                <Typography variant="body1" style={{ color: 'red' }}>
                  Insira a data final
                </Typography>
              )}
            </Box>
          </Box>
          <Button variant="contained" type="submit" disabled={!canSearch}>
            Pesquisar
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6">Resultados</Typography>
          {tableData.length > 0 && (
            <Button variant="contained" endIcon={<AiFillFileExcel />} onClick={downloadDataCount}>
              Excel
            </Button>
          )}
        </Box>
        {tableData.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Código do produto</StyledTableCell>
                  <StyledTableCell align="left">Nome do produto</StyledTableCell>
                  <StyledTableCell align="left">Código de Barras</StyledTableCell>
                  <StyledTableCell align="left">Marca</StyledTableCell>
                  <StyledTableCell align="left">Cor</StyledTableCell>
                  <StyledTableCell align="left">Referencia</StyledTableCell>
                  <StyledTableCell align="left">Tamanho</StyledTableCell>
                  <StyledTableCell align="left">SKU</StyledTableCell>
                  <StyledTableCell align="left">Criação</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                      <StyledTableCell align="left">{row.productName}</StyledTableCell>
                      <StyledTableCell align="left">{row.barCode}</StyledTableCell>
                      <StyledTableCell align="left">{row.brand}</StyledTableCell>
                      <StyledTableCell align="left">{row.color}</StyledTableCell>
                      <StyledTableCell align="left">{row.referencia}</StyledTableCell>
                      <StyledTableCell align="left">{row.size}</StyledTableCell>
                      <StyledTableCell align="left">{row.sku}</StyledTableCell>
                      <StyledTableCell align="left">{row.receiveCreate}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default AntiTheftReports
