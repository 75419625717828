import axios from 'axios'

//Produtos
export const getProductService = async (token, nameUrl, item) => {
  let orderToString
  if (item.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    //'8F95728F-258F-4542-B8A2-FFB9B05156F2'
    `/${nameUrl}/api/Product/GetAll?CompanyId=${item.companyId}&limit=${item.limit}&offset=${item.offset}&Order=${orderToString}&Reference=${item.Reference}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getSingleProductService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Product/Get/${data.productId}?ProductId=${data.productId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const getProductList = async (token, nameUrl, companyId, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/Product/GetIdProduct?CompanyId=${companyId}`,
    data,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const getClientList = async (token, nameUrl, subCompanyId, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/Client/GetClientCode?SubCompanyId=${subCompanyId}`,
    data,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const getAllProductsService = async (token, nameUrl, companyId) => {
  const response = await axios.get(`/${nameUrl}/api/Product/GetAll/${companyId}`, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const getProductAll = async (token, nameUrl, item) => {
  let orderToString
  if (item.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    //'8F95728F-258F-4542-B8A2-FFB9B05156F2'
    `/${nameUrl}/api/Product/GetAllProducts?CompanyId=${item.companyId}&limit=${item.limit}&offset=${item.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//produtos por compania
export const getAllReference = async (token, nameUrl, company) => {
  // let orderToString;
  // if (order) {
  //   orderToString = "asc";
  // } else {
  //   orderToString = "desc";
  // }

  const response = await axios.get(`/${nameUrl}/api/Product/GetAllReference?CompanyId=${company}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getUniqueProductService = async (token, nameUrl, productId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Product/Get/${productId}?ProductId=${productId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getAllGatewayLocation = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/AlocationStock/GetAll?SubCompanyId=${data.subCompanyId}&limit=${data.limit}&offset=${data.offset}&Order=${data.order}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getReportPeople = async (token, nameUrl, subCompanyId, intervalStart, intervalEnd) => {
  const response = await axios.get(
    `/${nameUrl}/api/Mqtt/ReportPeople?SubCompanyId=${subCompanyId}&IntervalStart=${intervalStart}&IntervalEnd=${intervalEnd}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getReportPeopleFound = async (
  token,
  nameUrl,
  subCompanyId,
  intervalStart,
  intervalEnd
) => {
  const response = await axios.get(
    `/${nameUrl}/api/Mqtt/ReportPeopleFound?SubCompanyId=${subCompanyId}&IntervalStart=${intervalStart}&IntervalEnd=${intervalEnd}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
export const getReportPeopleNotFound = async (
  token,
  nameUrl,
  subCompanyId,
  intervalStart,
  intervalEnd
) => {
  const response = await axios.get(
    `/${nameUrl}/api/Mqtt/ReportPeopleNotFound?SubCompanyId=${subCompanyId}&IntervalStart=${intervalStart}&IntervalEnd=${intervalEnd}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createGatewayLocation = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/AlocationStock/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const updateGatewayLocation = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/AlocationStock/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteGatewayLocation = async (token, nameUrl, id) => {
  const response = await axios.post(
    `/${nameUrl}/api/AlocationStock/Delete/${id}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const createSensorAndPerson = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Mqtt/CreateSensorAndPerson`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const updateSensorAndPerson = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Mqtt/EditSensorAndPerson`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteSensorAndPerson = async (token, nameUrl, productItensId) => {
  const response = await axios.post(
    `/${nameUrl}/api/Mqtt/DeleteSensorAndPerson/${productItensId}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const createProductService = async (token, nameUrl, product) => {
  const response = await axios.post(`/${nameUrl}/api/Product/Create`, product, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createListProductService = async (token, nameUrl, product) => {
  const response = await axios.post(`/${nameUrl}/api/Product/CreateBulks`, product, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteProductService = async (token, nameUrl, productId) => {
  const response = await axios.post(
    `/${nameUrl}/api/Product/Delete`,
    {
      productId: productId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editProductService = async (token, nameUrl, product) => {
  const response = await axios.post(
    `/${nameUrl}/api/Product/Edit`,

    product,
    // productId: product.productId,
    // name: product.name,
    // size: product.size,
    // color: product.color,
    // idProductPartner: product.idProductPartner,
    // referencia: product.referencia,
    // brand: product.brand,
    // supplier: product.supplier,
    // price: product.price,
    // description: product.description,
    // info: product.info,
    // created: product.created,
    // active: product.active,
    // companyId: product.companyId,
    // sku: product.sku,
    // codColor: product.codColor,
    // laboratorio: product.laboratorio,
    // dataExpiration: product.dataExpiration,
    // guidOtherversion: product.guidOtherversion,

    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const uploadProducts = async (token, nameUrl, formData) => {
  var tokenFinal = 'Bearer ' + token

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
      Authorization: tokenFinal,
    },
  }
  const response = await axios.post(`/${nameUrl}/api/Product/Upload`, formData, config)

  return response
}

export const uploadQuantity = async (token, nameUrl, formData) => {
  var tokenFinal = 'Bearer ' + token

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
      Authorization: tokenFinal,
    },
  }
  const response = await axios.post(`/${nameUrl}/api/Product/UploadQuantity`, formData, config)

  return response
}

export const uploadDashboardVendor = async (token, nameUrl, formData) => {
  var tokenFinal = 'Bearer ' + token

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
      Authorization: tokenFinal,
    },
  }
  const response = await axios.post(
    `/${nameUrl}/api/DashboardVendor/UploadOrderVendor`,
    formData,
    config
  )

  return response
}

export const UploadBillstopay = async (token, nameUrl, formData) => {
  var tokenFinal = 'Bearer ' + token

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
      Authorization: tokenFinal,
    },
  }
  const response = await axios.post(
    `/${nameUrl}/api/DashboardVendor/UploadBillstopay`,
    formData,
    config
  )

  return response
}
export const uploadVendorClient = async (token, nameUrl, formData) => {
  var tokenFinal = 'Bearer ' + token

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
      Authorization: tokenFinal,
    },
  }
  const response = await axios.post(
    `/${nameUrl}/api/DashboardVendor/UploadVendor`,
    formData,
    config
  )

  return response
}

export const uploadSeller = async (token, nameUrl, formData) => {
  var tokenFinal = 'Bearer ' + token

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
      Authorization: tokenFinal,
    },
  }
  const response = await axios.post(
    `/${nameUrl}/api/DashboardVendor/UploadSeller`,
    formData,
    config
  )

  return response
}

export const uploadRoute = async (token, nameUrl, formData) => {
  var tokenFinal = 'Bearer ' + token

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
      Authorization: tokenFinal,
    },
  }
  const response = await axios.post(
    `/${nameUrl}/api/DashboardVendor/UploadVendorRoute`,
    formData,
    config
  )

  return response
}

export const getProductAssociationService = async (
  token,
  nameUrl,
  limit,
  offset,
  order,
  subCompanyId
) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/ProductItens/GetAll/Pagination?SubCompanyId=${subCompanyId}&offset=${offset}&limit=${limit}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const getProductAssociationSeearchService = async (
  token,
  nameUrl,
  limit,
  offset,
  order,
  subCompanyId,
  Search
) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/ProductItens/GetAllPagination/Pagination?SubCompanyId=${subCompanyId}&offset=${offset}&limit=${limit}&Order=${orderToString}&Search=${Search}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

//Produtos pelo Codigo
export const getProductCodService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/ProductItens/Get?SubCompanyCnpj=${data.subCompanyId}&RfidCode=${data.rfidCode}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
export const postProductCodService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/ProductItens/Get?CompanyCnpjId=${data.subCompanyId}`,
    [data.rfidCode],
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
export const createSalesBucket = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/PointOfSaleSignal/SalesBucket`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getAllProductCodService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/ProductItens/Get?CompanyCnpjId=${data.subCompanyId}`,
    data.rfidCode,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const simulationAssociation = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Simulation/SimulationAssociation`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const simulationCountStockOrder = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Simulation/SimulationCountStockOrder`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const createOrder = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MovimentationOrder/CreateOrder`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const simulationConferenceOrder = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Simulation/SimulationConferenceOrder`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const getUniqueProductAssociationService = async (token, nameUrl, productItensId) => {
  const response = await axios.get(`/${nameUrl}/api/ProductItens/Get/${productItensId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getProductItensRfid = async (token, nameUrl, subCompanyId, rfid) => {
  const response = await axios.get(
    `/${nameUrl}/api/ProductItens/Get?RfidCode=${rfid}&SubCompanyCnpj=${subCompanyId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createProductAssociationService = async (token, nameUrl, association) => {
  const response = await axios.post(
    `/${nameUrl}/api/AssociationItens/AssociationItensUnique`,
    {
      productName: association.productName,
      alocationStockId: association.alocationStockId,
      subCompanyId: association.subCompanyId,
      productId: association.productId,
      barcode: association.barcode,
      lote: association.lote,
      serial: association.serial,
      factionId: association.factionId,
      laboratory: association.laboratory,
      depositId: association.depositId,
      dataExpiration: association.dataExpiration,
      userId: association.userId,
      op: association.op,
      opPrincipal: association.opPrincipal,
      movimentationOrderId: association.movimentationOrderId,
      movimentationOrderBreakId: association.movimentationOrderBreakId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createUploadDepreciation = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/ProductItens/UploadDepreciation`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const createAssociationItensList = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/AssociationItens/AssociationItensList`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const uplaodQuantity = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/ProductItens/UplaodQuantity?SubCompanyId=${data.subCompanyId}&Serial=${data.serial}&Quantity=${data.quantity}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const createBuckCreateMobileList = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/ReceiveSignal/BuckCreateMobile`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const createDisassociationItemRemovesList = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/DisassociationItem/DisassociationItemRemoves`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const editProductAssociationService = async (token, nameUrl, association) => {
  const response = await axios.post(
    `/${nameUrl}/api/AssociationItens/Edit`,
    {
      productItensId: association.productItensId,
      subCompanyId: association.subCompanyId,
      productId: association.productId,
      created: association.created,
      active: association.active,
      barcode: association.barcode,
      lote: association.lote,
      serial: association.serial,
      laboratory: association.laboratory,
      dataExpiration: association.dataExpiration,
      userId: association.userId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteProductAssociationService = async (token, nameUrl, productItensId) => {
  const response = await axios.post(
    `/${nameUrl}/api/AssociationItens/Delete`,
    {
      productItensId: productItensId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getCountStockOrderService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetCountStockPagination?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const createCountStockOrderService = async (token, nameUrl, movimentationOrder) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/CreateOrder`,
    {
      movimentationOrderNumberOtherSystem: movimentationOrder.movimentationOrderNumberOtherSystem,
      typeOrder: 6,
      subCompanyIdFrom: movimentationOrder.subCompanyId,
      subCompanyIdTo: movimentationOrder.subCompanyId,
      cicle: movimentationOrder.cicle,
      productId: movimentationOrder.productId,
    },
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const CreateOrderFullActiveControl = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/CreateOrderFull_ActiveControl`,
    data,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const createMovimentationOrderService = async (token, nameUrl, movimentationOrder) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/CreateOrder`,
    {
      reasonfororderId: movimentationOrder.reasonfororderId,
      typeOrder: movimentationOrder.typeOrder,
      movimentationOrderNumberOtherSystem: movimentationOrder.movimentationOrderNumberOtherSystem,
      subCompanyIdFrom: movimentationOrder.subCompanyIdFrom,
      subCompanyIdTo: movimentationOrder.subCompanyIdTo,
      clientId: movimentationOrder.clientId,
    },
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const deleteMovimentationOrderService = async (token, nameUrl, movimentationOrderId) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/DeleteOrder`,
    { movimentationOrderId: movimentationOrderId },
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const updateConfirOrder = async (
  token,
  nameUrl,
  movimentationOrderNumberOtherSystem,
  movimentationOrderNumberBihands
) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/SendFinishOrder`,
    {
      movimentationOrderNumberOtherSystem: movimentationOrderNumberOtherSystem,
      movimentationOrderNumberBihands: movimentationOrderNumberBihands,
    },
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const downloadPrintLabelZPL = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/PrinterZpl/PrintLabelZPL`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response.data
}

export const getStockEntryOrderService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetStockEntryPagination?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getOrderPaginationService = async (
  token,
  nameUrl,
  orderInfo,
  limit,
  offset,
  order,
  search
) => {
  let orderToString
  let searching = ''
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  if (search) {
    searching = search
  }

  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetOrderPagination?SubCompanyId=${orderInfo.subCompanyId}&TypeOrder=${orderInfo.typeOrder}&limit=${limit}&offset=${offset}&Order=${orderToString}&Search=${searching}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getOrderPaginationServiceSearch = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetOrderPagination?SubCompanyId=${data.subCompanyId}&TypeOrder=${data.TypeOrder}&limit=${data.limit}&offset=${data.offset}&Order=${data.order}&Search=${data.SearchWord}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getOrderItemPaginationService = async (
  token,
  nameUrl,
  movimentationOrderId,
  limit,
  offset,
  order
) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItem/GetPagination?MovimentationOrderId=${movimentationOrderId}&limit=${500}&offset=${0}&Order=${'desc'}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getOutStockOrderService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetOutOfStockPagination?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}
export const addProductOrderList = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderItem/AddProductMovimentationOrderList`,
    data,
    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const movimentationOrderId = async (token, nameUrl, movimentationOrderId) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/GetId`,
    { movimentationOrderId },
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getMovimentationOrderConference = async (token, nameUrl, movimentationOrderId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationOrderConference?MovimentationOrderId=${movimentationOrderId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const getMovimentationIdServices = async (token, nameUrl, id) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetId?MovimentationOrderId=${id}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getProductOrder = async (token, nameUrl, id) => {
  const response = await axios.get(
    `/${nameUrl}/api/Product/SearchProductMovimentationOrderId=${id}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

//editar link de toda a referencia
export const updateBulkLink = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Product/UpdateLinkBulk`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

//editar link de uma referencia
export const updateLink = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Product/UpdateLink`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const reloadProductService = async (token, nameUrl, text, subCompanyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Product/ReloadProduct?Text=${text}&SubCompanyId=${subCompanyId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const reloadSeparatorService = async (token, nameUrl, text, subCompanyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/ReloadOrderSeparationApi?Text=${text}&SubCompanyId=${subCompanyId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

//?
export const getRelatory = async (token, nameUrl, data) => {
  const response = await axios.get(
    `https://qr.bihands.com.br/BD5B00BC0C00000000000345/OBJCCMM/04/AZUL/345`
  )
  return response
}

export const getPrintLabelOrderService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetOutOfStockPagination?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const returnOrderStatus = status => {
  const orderStatus = [
    {
      name: 'Capturar todos',
      number: -99,
    },
    {
      name: 'Rascunho',
      number: -1,
    },
    {
      name: 'Aberto',
      number: 1,
    },
    {
      name: 'Em conferência',
      number: 0,
    },
    {
      name: 'Finalizado',
      number: 2,
    },
    {
      name: 'Cancelado',
      number: -2,
    },
  ]
  const result = orderStatus.find(order => order.number === parseInt(status))
  return result.name
}

export const updatePieceListService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Product/UpdatePieceList`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const confirmOrder = async (token, nameUrl, movimentationOrderId, depositId) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/SendConfirmOrder`,
    {
      movimentationOrderId: movimentationOrderId,
      depositId: depositId,
    },
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const getDeposit = async (token, nameUrl, subCompanyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Deposit/GetAll?SubCompanyId=${subCompanyId}&limit=500&offset=0&Order=desc`,

    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const updateTimeExpirationService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Product/UpdateTimeExpirationList`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const getGenerateTagRfid = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/ProductItens/GenerateTagRfid`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const searchProductService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Product/SearchProduct?CompanyId=${data.companyId}&SearchWord=${data.SearchWord}&limit=${data.limit}&offset=${data.offset}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const searchCategories = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Country/Get
`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const searchProductSubCompanyService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/ProductItens/SearchProduct?SubCompanyId=${data.subCompanyId}&SearchWord=${data.SearchWord}&limit=${data.limit}&offset=${data.offset}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const searchProductAll = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Product/SearchAll?CompanyId=${data.CompanyId}&text=${data.SearchWord}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const updateLockService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/ProductItens/UpdateLock?ProductItensId=${data.productItens}&Lock=${data.Lock}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getDataLockService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/ProductItens/GetDataLock?SubCompanyId=${data.subCompanyId}&ProductId=${data.productId}&Lote=${data.lote}&NumberPallet=${data.numberPallet}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const receiveOrderPickingMobileService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderMobile/ReceiveOrderPickingOnlineMobile`,
    data,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const receiveManualEntryMobileService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderMobile/ReceiveOrderEntryIntegralMobile`,
    data,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}
