import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { editEmailParamService, getUniqueEmailParamService } from '../../../services/admServices'

import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const EmailParametersEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  emailParamId,
  handleFetchData,
  setExpanded,
}) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [name, setName] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState(false)
  const [emailParam, setEmailParam] = useState({})
  const [atributed, setAtributed] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const { quill, quillRef } = useQuill()

  const editEmailParam = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      const emailParamEdited = {
        emailParametersId: emailParam.emailParametersId,
        name: name,
        subject: subject,
        mensage: message,
        isDelete: false,
        active: status,
      }

      try {
        await editEmailParamService(token, nameUrl, emailParamEdited)
        setLoading(false)
        setEditing(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Parametro de email editado com sucesso!')
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente.')
      }
    },
    [emailParam, name, subject, message, status]
  )

  const getEmailParam = async () => {
    setLoading(true)
    try {
      const response = await getUniqueEmailParamService(token, nameUrl, emailParamId)
      setEmailParam(response.data)
      setName(response.data.name)
      setSubject(response.data.subject)
      setMessage(response.data.mensage)
      setStatus(response.data.active)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (quill && !atributed && message) {
      quill.clipboard.dangerouslyPasteHTML(message)
      setAtributed(true)
    }
  }, [quill, message])

  useEffect(() => {
    getEmailParam()
  }, [emailParamId])

  const handleClose = () => {
    setError(false)
  }

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setMessage(quillRef.current.firstChild.innerHTML)
      })
    }
  }, [quill])

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao Editar Parâmetro de Email!
          </Alert>
        </Snackbar>
        <Box component="form" onSubmit={editEmailParam}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={subject}
                onChange={values => setSubject(values.target.value)}
                id="outlined-basic"
                label="Assunto"
                variant="outlined"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel>Editor</InputLabel>
              <Box sx={{ width: '100%', height: 300 }}>
                <Box ref={quillRef} />
              </Box>
            </Box>

            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              marginTop={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            >
              <InputLabel for="">Visualização do código HTML</InputLabel>
              <TextField
                value={message}
                multiline
                rows={14}
                disabled
                onChange={values => setMessage(values.target.value)}
                type="text"
                placeholder="Insira a Mensagem"
                sx={{ resize: 'none', height: '20rem', width: '100%' }}
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 5, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel>Ativo</InputLabel>
              <Checkbox value={status} onChange={() => setStatus(!status)} checked={status} />
              {status ? 'Sim' : 'Não'}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => {
                  setExpanded(false)
                  setEditing(false)
                }}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default EmailParametersEdit
