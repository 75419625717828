import { useCallback, useContext, useEffect, useState } from 'react'
import {
  createReprocessingTags,
  getSubCompanyCompanyIdService,
} from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'

import {
  Backdrop,
  Box,
  Button,
  Alert,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  IconButton,
  Snackbar,
} from '@mui/material'
import { Help } from '@mui/icons-material'
import { getCompanyService } from '../../../services/admServices'

const TagReprocessing = () => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [companyId, setCompanyId] = useState('')
  const [subCompanyId, setSubCompanyId] = useState('')
  const [company, setCompany] = useState([])
  const [subCompany, setSubCompany] = useState([])
  const [listCodRfid, setListCodRfid] = useState('')
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')

  const handleGetCompanyAll = async () => {
    setLoading(true)

    try {
      const response = await getCompanyService(token, nameUrl, 500, 0, 'desc')
      setCompany(response.data.result)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetCompanyAll()
  }, [])

  const handleSubCompanyAll = async companyId => {
    setLoading(true)
    try {
      const response = await getSubCompanyCompanyIdService(token, nameUrl, companyId)
      setSubCompany(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleTextFieldChange = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setListCodRfid(prevList => prevList + ',')
    } else {
      setListCodRfid(e.target.value.toUpperCase())
    }
  }

  const handleCreateReprocessingTags = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const codRfidArray = listCodRfid.split(',').map(codigo => codigo.trim())
      const RemoveDuplicateCodes = Array.from(new Set(codRfidArray))

      try {
        const response = await createReprocessingTags(
          token,
          nameUrl,
          subCompanyId,
          RemoveDuplicateCodes
        )
        setLoading(false)
        setAlerta(true)
        setSeverity('success')
        setTitle(response.data.message)
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
      }
    },
    [listCodRfid, subCompanyId]
  )

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={() => setAlerta(false)}
          key={vertical + horizontal}
        >
          <Alert onClose={() => setAlerta(false)} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Reprocessamento de Tags</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <Help />
          </IconButton>
        </Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleCreateReprocessingTags}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select
                label="Empresa"
                value={companyId}
                onChange={values => {
                  setCompanyId(values.target.value)
                  handleSubCompanyAll(values.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {company.map((item, index) => {
                  return (
                    <MenuItem value={item.companyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Filial"
                disabled={!companyId}
                value={subCompanyId}
                onChange={values => {
                  setSubCompanyId(values.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} md={12}>
              <InputLabel id="demo-simple-select-label">Lista RFID</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                id="outlined-multiline-static"
                multiline
                value={listCodRfid}
                onChange={handleTextFieldChange}
                onKeyDown={handleTextFieldChange}
                rows={10}
                helperText="Ao adicionar manualmente, use 'Enter' para avançar para o próximo código. Experimente agora!"
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default TagReprocessing
