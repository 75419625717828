import React, { useContext } from 'react'
import { useEffect, useState } from 'react'

import * as XLSX from 'xlsx'
import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import '../../../../assets/global.css'
import ViewElearning from '../../../ViewElearning'
import { ArrowDownward, ArrowUpward, ChatBubble } from '@mui/icons-material'
import { format } from 'date-fns'
import { getMovimentationOrderConference } from '../../../../services/productService'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../../context/AuthContext'
import {
  divergenceStatus,
  getOnlyOrder,
  getReportInventoryService,
  getUpdateInfoStockActive,
} from '../../../../services/reportServices'
import { colorTableDefault } from '../../../TemplateDefault'

const ScalableIconButton = styled(IconButton)`
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`

const CountStockClient = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { company, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [orders, setOrders] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [orderSelect, setOrderSelect] = useState('')
  const [itemstockWmsId, setItemstockWmsId] = useState('')
  const token = localStorage.getItem('token')
  const [inventoryBalance, setInventoryBalance] = useState([])
  const [pagination, setPagination] = useState({})
  const [statusJustify, setStatusJustify] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [justify, setJustify] = useState('')
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = React.useState(false)

  // apagar daqui pra baixo se der erro e apagar o import xlsx

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [resultData, setResultData] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [operationStatus, setOperationStatus] = useState({})
  const [filterValue, setFilterValue] = useState('')
  const [totalResults, setTotalResults] = useState(0)
  const [filteredTotalResults, setFilteredTotalResults] = useState(0)
  const [balanceStockFilterData, setBalanceStockFilterData] = useState([])
  const [filteringInventory, setFilteringInventory] = useState(false)
  const [filteredInventoryBalance, setFilteredInventoryBalance] = useState([])
  const [originalInventoryBalance, setOriginalInventoryBalance] = useState([])
  const [currentInventoryBalance, setCurrentInventoryBalance] = useState([])

  const [selectAll, setSelectAll] = useState(false)

  const [notSearch, setNotSearch] = useState(false)

  const [sortDirection, setSortDirection] = useState({
    location: null,
    product: null,
    resultFinal: null,
    shippingStatus: null,
  })
  const [orderBy, setOrderBy] = useState('')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
    '&.ok': {
      backgroundColor: 'green',
      color: 'white',
    },
    '&.nok': {
      backgroundColor: '#D21919',
      color: 'white',
    },
    '&.selected': {
      backgroundColor: 'yellow',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const formatDate = date => {
    const dateHr = new Date(date)
    return format(dateHr, 'dd/MM/yyyy HH:mm:ss')
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleGetMovimentationOrderConference = async () => {
    setIsLoading(true)
    try {
      const response = await getMovimentationOrderConference(token, nameUrl, id)
      setResultData(response.data)
      console.log(response, 'dsadas')
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    console.log(id, ' idsadsas')
    if (id) {
      handleGetMovimentationOrderConference()
    }
  }, [id])

  const handleChangeRowsPerPage = event => {
    const rowsPerPageValue = event.target.value
    setRowsPerPage(rowsPerPageValue)

    setSelectAll(rowsPerPageValue === 'All')

    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)

    finishDownloadCount(resultData.length)
  }

  useEffect(() => {
    if (selectedItems.length === balanceStockFilterData.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedItems, balanceStockFilterData])

  useEffect(() => {
    const filter = inventoryBalance.filter(item => item.locationOld === 'A6304')

    console.log(filter, 'filtesr')
  }, [])

  useEffect(() => {
    const handleFilter = () => {
      if (filterValue !== '') {
        setNotSearch(false)
        setFilteringInventory(true)
        const newData = inventoryBalance.filter(
          data =>
            data.locationActual.toLowerCase().includes(filterValue.toLowerCase()) ||
            data.locationOld.toLowerCase().includes(filterValue.toLowerCase()) ||
            data.productNameActual.toLowerCase().includes(filterValue.toLowerCase()) ||
            data.productNameOld.toLowerCase().includes(filterValue.toLowerCase())
        )

        setFilteringInventory(false)
        if (newData.length > 0) {
          setBalanceStockFilterData(newData)
          setPage(0)
          setFilteredTotalResults(newData.length)
          setCurrentInventoryBalance(newData)
        } else {
          setNotSearch(true)

          setBalanceStockFilterData([])
          setFilteredTotalResults(0)
          setCurrentInventoryBalance([])
        }
      } else {
        setBalanceStockFilterData(inventoryBalance)
        setFilteredTotalResults(totalResults)
        setCurrentInventoryBalance(inventoryBalance)
      }
    }
    handleFilter()
  }, [filterValue, inventoryBalance, totalResults])

  const clearFilter = () => {
    setFilterValue('')
    setSelectedItems([])
    setOrderBy('')
    setSortDirection({
      location: null,
      products: null,
      resultFinal: null,
      shippingStatus: null,
    })
    setRowsPerPage(10)
    setFilteredInventoryBalance(inventoryBalance)
  }

  const finishDownloadCount = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessageCount(`Baixando ${i} de ${pages}...`)
      setProgressCount((i / pages) * 100)
    }

    const resp = await getMovimentationOrderConference(token, nameUrl, id)
    result = result.concat(resp.data)
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalContagem = []
    result.map(item => {
      relatorioFinalContagem.push({
        Setor: item.picking,
        'Codigo do produto': item.idProductPartner,
        'Nome do Produto': item.productName,
        'Usuário responsável': item.responsibleEmployee,
        Status: item.checkInfo,
      })
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalContagem)

    const sheetName = 'Relatório de Conferência'
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)

    XLSX.writeFile(workbook, `Relatório de Conferência de estoque.xlsx`)
    setProgressCount(0)
  }

  const getInventoryBalance = async () => {
    setLoading(true)
    const InventoryBalanceDTO = {
      movimentationOrderId: orderSelect,
    }

    try {
      const response = await getReportInventoryService(
        token,
        InventoryBalanceDTO.movimentationOrderId
      )

      setItemstockWmsId(response.data)

      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setInventoryBalance(response.data)
        console.log(response.data, 'dsata')
        setPagination(response.data)
        setFilteredInventoryBalance(response.data)
        setTotalResults(response.data.length)
        setFilteredTotalResults(response.data.length)
        setOriginalInventoryBalance(response.data)
        setBalanceStockFilterData(response.data)
        setCurrentInventoryBalance(response.data)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const actionStatus = async () => {
    setOpen(false)
    setLoading(true)
    setStatusJustify(true)

    const data = selectedItems.map(item => ({
      companyId: company.companyId,
      responsibleEmployee: item.responsibleEmployee,
      picking: item.picking,
      idProductPartner: item.idProductPartner,
    }))

    for (const item of data) {
      setOperationStatus(prevStatusMap => ({
        ...prevStatusMap,
        [item.idProductPartner]: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress sx={{ marginRight: '5px' }} size={20} /> Enviando...
          </div>
        ),
      }))

      try {
        const response = await getUpdateInfoStockActive(token, nameUrl, item)
        setOperationStatus(prevStatusMap => ({
          ...prevStatusMap,
          [item.idProductPartner]: response.data,
        }))

        setInventoryBalance(prevInventory =>
          prevInventory.filter(invItem => invItem.idProductPartner !== item.idProductPartner)
        )

        setSelectedItems(prevSelectedItems =>
          prevSelectedItems.filter(id => id.idProductPartner !== item.idProductPartner)
        )

        setJustify('')
      } catch (error) {
        console.log(error)
      }
    }
    await new Promise(resolve => setTimeout(resolve, 1000))
    setLoading(false)
  }

  const actionStatusUnit = async () => {
    if (justify === '') {
      setSeverity('error')
      setTitle('Descreva sua Justificativa!')
      setAlerta(true)
    } else {
      setOpen(false)
      setLoading(true)
      setStatusJustify(true)
      setOperationStatus({})
      setOperationStatus(prevStatus => ({ ...prevStatus, [itemstockWmsId]: 'Carregando...' }))
      await new Promise(resolve => setTimeout(resolve, 0))

      const data = selectedItems.map(item => ({
        companyId: company.companyId,
        responsibleEmployee: item.responsibleEmployee,
        picking: item.picking,
        idProductPartner: item.idProductPartner,
      }))

      for (const item of data) {
        try {
          const response = await getUpdateInfoStockActive(token, nameUrl, item)

          setOperationStatus(prevStatus => ({
            ...prevStatus,
            [item.idProductPartner]: response.data.message,
          }))
          setJustify('')
          setInventoryBalance(prevInventory =>
            prevInventory.filter(invItem => invItem.idProductPartner !== item.idProductPartner)
          )

          setSelectedItems(prevSelectedItems =>
            prevSelectedItems.filter(id => id.idProductPartner !== item.idProductPartner)
          )
        } catch (error) {
          console.log(error)
        } finally {
          await new Promise(resolve => setTimeout(resolve, 500))
          setLoading(false)
        }
      }
    }
  }

  useEffect(() => {
    setFilteredTotalResults(balanceStockFilterData.length)
  }, [balanceStockFilterData])

  const handleAction = id => {
    console.log(selectedItems, ' id')
    if (selectedItems.length === 0) {
      setSeverity('error')
      setTitle('Selecione um item')
      setAlerta(true)
    } else {
      setItemstockWmsId(id)
      setOpen(true)
    }
  }

  const handleActionUnit = id => {
    setItemstockWmsId(id)
    setOpen(true)
  }

  const getOrders = async e => {
    setSubCompanyId(e.target.value)
    try {
      const response = await getOnlyOrder(token, nameUrl, e.target.value)

      setOrders(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleOrder = e => {
    const newOrder = e.target.value
    setOrderSelect(newOrder)
  }

  const handleClose = () => {
    setJustify('')
    setOpen(false)
    setAlerta(false)
  }

  const handleItemSelect = row => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(row)) {
        return prevSelectedItems.filter(item => item !== row)
      } else {
        return [...prevSelectedItems, row]
      }
    })
  }

  const handleSelectAll = event => {
    if (event.target.checked) {
      const newSelectedItems = resultData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .filter(row => !row.removed)
      setSelectedItems(newSelectedItems)
    } else {
      setSelectedItems([])
    }
  }

  const isAllSelected = resultData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .filter(row => !row.removed)
    .every(row => selectedItems.includes(row))

  const sortItems = (items, field) => {
    const sortedItems = [...items]
    sortedItems.sort((a, b) => {
      if (field === 'location') {
        if (a.locationActual < b.locationActual) return -1
        if (a.locationActual > b.locationActual) return 1
        return 0
      } else if (field === 'products') {
        if (a.productNameActual < b.productNameActual) return -1
        if (a.productNameActual > b.productNameActual) return 1
        return 0
      } else if (field === 'resultFinal') {
        if (a.resultFinal < b.resultFinal) return -1
        if (a.resultFinal > b.resultFinal) return 1
        return 0
      } else if (field === 'shippingStatus') {
        if (a.shippingStatus < b.shippingStatus) return -1
        if (a.shippingStatus > b.shippingStatus) return 1
      }
    })
    if (sortDirection[field] === 'desc') {
      sortedItems.reverse()
    }

    return sortedItems
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {selectedItems.length === 0
              ? 'Deseja enviar as alterações dos dados ativos?.'
              : `Deseja enviar as alterações dos dados ativos para ${selectedItems.length} Itens?`}
          </DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancela
            </Button>
            <Button variant="contained" onClick={actionStatus}>
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
        <ViewElearning
          pageName={'ReportInventory'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de Conferência de estoque</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
            width: '100%',
          }}
        >
          <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="h6">{resultData.length} Resultados</Typography>
              {selectedItems.length > 0 && (
                <Typography ml={2} variant="h6">
                  {selectedItems.length}{' '}
                  {selectedItems.length === 1 ? 'Item Selecionado' : 'Itens Selecionados'}
                </Typography>
              )}
            </Box>
            <Box display="flex" gap={2}>
              <Button variant="outlined" onClick={() => navigate('/ConferenceClient')}>
                Voltar
              </Button>
              {resultData.length > 0 && (
                <Button
                  variant="contained"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: ' space-between',
                    fontSize: '.9em',
                    width: '6.5rem',
                    marginLeft: '5px',
                  }}
                  onClick={downloadDataCount}
                >
                  Excel <AiOutlineFileExcel size="2em" />
                </Button>
              )}
            </Box>
          </Box>
          {inventoryBalance.length > 0 && (
            <Box display="flex" justifyContent="space-between" width="100%" sx={{ mt: 2 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={{ xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }}
              >
                <Box>
                  <TextField
                    variant="outlined"
                    label={!notSearch ? 'Filtrar Tabela' : 'Produto Não encontrado'}
                    type="text"
                    error={notSearch}
                    value={filterValue}
                    onChange={e => setFilterValue(e.target.value)}
                  />
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: '.9em',
                  }}
                  onClick={clearFilter}
                >
                  Limpar Filtros
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                      backgroundColor: '#011528',
                    }}
                    onClick={handleAction}
                  >
                    Alterar dados dos ativos
                  </Button>

                  <Tooltip title="Selecionar Todos" arrow>
                    <span>
                      <Checkbox
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                        color="primary"
                        disabled={loading}
                      />
                    </span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="left">Setor</StyledTableCell>
                <StyledTableCell align="left">Codigo do produto</StyledTableCell>
                <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                <StyledTableCell align="left">Usuário responsável</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? Array.from(new Array(5)).map((_, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <Skeleton variant="rectangular" width={24} height={24} />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Skeleton variant="text" width={100} />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Skeleton variant="text" width={150} />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Skeleton variant="text" width={200} />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Skeleton variant="text" width={150} />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton variant="text" width={50} />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Skeleton variant="text" width={100} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : sortItems(resultData, orderBy)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      (row, index) =>
                        !row.removed && (
                          <StyledTableRow
                            key={row.idProductPartner}
                            style={
                              selectedItems.includes(row)
                                ? { backgroundColor: '#ccc', zIndex: '5' }
                                : {}
                            }
                          >
                            <StyledTableCell align="center">
                              <Checkbox
                                checked={selectedItems.includes(row)}
                                onChange={() => handleItemSelect(row)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.picking}</StyledTableCell>
                            <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                            <StyledTableCell align="left">{row.productName}</StyledTableCell>
                            <StyledTableCell align="left">
                              {row.responsibleEmployee}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.checkInfo === 'Encontrado' ? 'ok' : 'nok'}
                            >
                              {row.checkInfo === 'Encontrado' ? 'OK' : 'NOK'}
                            </StyledTableCell>
                            {statusJustify && (
                              <StyledTableCell align="left">
                                {operationStatus[row.idProductPartner] || ''}
                              </StyledTableCell>
                            )}
                          </StyledTableRow>
                        )
                    )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={resultData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default CountStockClient
