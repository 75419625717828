import { userLogin } from '../../services/api'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../context/AuthContext'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
} from '@mui/material'
import { useFormik } from 'formik'
import bgImage from '../../assets/login.png'
import bihands from '../../assets/Ativo-3@4x-bihands-300x64.png'
import infoiot from '../../assets/info.png'
import * as yup from 'yup'
import { Close } from '@mui/icons-material'

const LoginForm = () => {
  const { handleLogin, nameUrl } = useContext(Context)
  const nameUrlLocal = localStorage.getItem('url')

  //const [email, setEmail] = useState("");
  const [invalidUser, setInvalideUser] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [errorLogin, setErrorLogin] = useState(false)
  const [openDialogError, setOpenDialogError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const subdomain = window.location.hostname.split('.')[0]

  useEffect(() => {
    console.log(window.location, ' susb')
    if (subdomain !== 'inventory' && subdomain !== 'inventoryteste' && subdomain !== 'localhost') {
      localStorage.setItem('url', subdomain)
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('url')
      localStorage.removeItem('companyId')
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup.string().required('Insira seu e-mail'),
      password: yup.string().required('Insira sua senha'),
    }),
    onSubmit: async values => {
      setLoading(true)
      setInvalideUser(false)

      try {
        const response = await userLogin(values)
        if (nameUrlLocal) {
          if (nameUrlLocal !== response.data.user.listSubCompany[0].nameUrl.toLowerCase()) {
            setErrorLogin(true)
            setLoading(false)
            setInvalideUser(true)
            localStorage.removeItem('token')
            localStorage.removeItem('url')
            return
          }
        }
        localStorage.setItem('token', response.data.token)
        await handleLogin(response)

        const currentUrl = window.location.href

        if (
          currentUrl.includes('https://help.bihands.com.br') ||
          currentUrl.includes('https://helpDesk.bihands.com.br')
        ) {
          navigate('/TicketSuport')
        }

        const differentCompanies = response.data.user.listSubCompany.filter(subCompany => {
          return subCompany.companyId !== response.data.user.listSubCompany[0].companyId
        })

        if (differentCompanies.length > 0) {
          console.log('Objetos com companyId diferentes encontrados:')
          console.log(differentCompanies)
          navigate('/')
          localStorage.setItem('selectedList', true)
        } else {
          navigate('/')
          console.log('Todos os objetos possuem o mesmo companyId.')
        }
      } catch (err) {
        setErrorLogin(true)
        setLoading(false)
        setInvalideUser(true)
        localStorage.removeItem('token')
        localStorage.removeItem('url')
      }
    },
  })

  const handleClose = () => {
    setErrorLogin(false)
  }

  return (
    <>
      <Dialog
        open={openDialogError}
        onClose={() => setOpenDialogError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ bgcolor: 'red', color: '#fff' }} id="alert-dialog-title">
          Comunicado
          <Box position="absolute" top={10} right={10}>
            <IconButton>
              <Close sx={{ color: '#fff' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ bgcolor: 'red', color: '#fff' }}>
          <DialogContentText id="alert-dialog-description" sx={{ color: '#fff' }}>
            Estamos enfrentando problemas em nossos serviços. A equipe já está realizando a
            manutenção e em breve voltaremos. Qualquer dúvida, sinta-se a vontade para
            contactar nosso suporte.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Grid
        container
        component="main"
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={errorLogin}
          onClose={handleClose}
          //message="Erro no login, verifique seu e-mail e senha"
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Verifique seu e-mail e senha
          </Alert>
        </Snackbar>
        <Box
          display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }}
          sx={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '65%',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundImage: `url(${bgImage})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
          />
          <Box
            sx={{
              width: '35%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{ width: '90%', textAlign: 'center' }}
              component="form"
              onSubmit={formik.handleSubmit}
            >
              <Box my={2}>
                <img
                  width={350}
                  src={subdomain === 'rfidinfoiot' ? infoiot : bihands}
                  alt="Bihands"
                />
              </Box>

              <Box>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  autoFocus
                />
                {formik.touched.email && formik.errors.email ? (
                  <Typography variant="body2" color="secondary">
                    {formik.errors.email}
                  </Typography>
                ) : null}
              </Box>
              <Box>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Senha"
                  name="password"
                  autoComplete="password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <Typography variant="body2" color="secondary">
                    {formik.errors.password}
                  </Typography>
                ) : null}
              </Box>
              <Button type="submit" variant="contained" sx={{ marginTop: '1rem' }}>
                Acessar Conta
              </Button>
            </Box>
            <Button sx={{ mt: 2, fontSize: '.8em' }} onClick={() => navigate('/ForgotPassword')}>
              Esqueci minha senha
            </Button>
          </Box>
        </Box>

        {/* Mobile */}
        <Box
          display={{ xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}
          sx={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            // backgroundImage: `url(${bgImage})`,
            // backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat',
            // backgroundPosition: 'center center',
            position: 'relative',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              textAlign: 'center',
              padding: 2,
              width: '90%',
            }}
          >
            <Box component="form" onSubmit={formik.handleSubmit}>
              <Box my={2}>
                <img
                  width={350}
                  src={subdomain === 'rfidinfoiot' ? infoiot : bihands}
                  alt="Bihands"
                />
              </Box>

              <Box>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  autoFocus
                />
                {formik.touched.email && formik.errors.email ? (
                  <Typography variant="body2" color="secondary">
                    {formik.errors.email}
                  </Typography>
                ) : null}
              </Box>
              <Box>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Senha"
                  name="password"
                  autoComplete="password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <Typography variant="body2" color="secondary">
                    {formik.errors.password}
                  </Typography>
                ) : null}
              </Box>
              <Button type="submit" variant="contained" sx={{ marginTop: '1rem' }}>
                Acessar Conta
              </Button>
            </Box>
            <Button sx={{ mt: 2, fontSize: '.8em' }} onClick={() => navigate('/ForgotPassword')}>
              Esqueci minha senha
            </Button>
          </Paper>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </>
  )
}

export default LoginForm
