import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../context/AuthContext'
import { setPermission } from '../../permissions/userPermissions'
import { ProductDELETE } from '../../const/RoleConstants'
import '../../assets/global.css'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  DialogContent,
  InputLabel,
  MenuItem,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Select,
  Snackbar,
  FormControl,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  Checkbox,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

//icons
import { colorTableDefault } from '../TemplateDefault'
import ViewElearning from '../ViewElearning'
import HelpIcon from '@mui/icons-material/Help'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import CachedIcon from '@mui/icons-material/Cached'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

//services
import {
  getOrderPaginationService,
  deleteProductAssociationService,
  getProductAssociationService,
  movimentationOrderId,
  deleteMovimentationOrderService,
  deleteProductService,
  getProductAll,
  simulationAssociation,
  createOrder,
  simulationCountStockOrder,
  simulationConferenceOrder,
} from '../../services/productService'

const IntegrationProcess = () => {
  const token = localStorage.getItem('token')

  const vertical = 'top'
  const horizontal = 'center'

  const chosenItem = {
    product: 'product',
    association: 'association',
    order: 'order',
  }

  const { userRoles, subCompany, nameUrl } = useContext(Context)
  const [companies, setCompanies] = useState([])
  const [companyId, setCompanyId] = useState('')

  const [loading, setLoading] = useState(false)
  const [register, setRegister] = useState(false)
  const [errorEmpresa, setErrorEmpresa] = useState(false)

  const [viewElearning, setViewElearning] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [permissionProductDelete, setPermissionProductDelete] = useState(false)
  const [deletingAll, setDeletingAll] = useState(false)
  const [whichDeleteAll, setWhichDeleteAll] = useState('')
  const [whichDelete, setWhichDelete] = useState('')

  const [saving, setSaving] = useState({
    product: false,
    association: false,
    order: false,
  })
  const [handleReload, setHandleReload] = useState('')

  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')

  //PRODUTOS
  const [product, setProduct] = useState([])
  const [productPage, setProductPage] = useState(0)
  const [totalProducts, setTotalProducts] = useState(0)
  const [rowsPerPageProduct, setRowsPerPageProduct] = useState(5)
  const [productId, setProductId] = useState('')

  //ASSOCIAÇÃO
  const [association, setAssociation] = useState([])
  const [associationTotal, setAssociationTotal] = useState(0)
  const [associationPage, setAssociationPage] = useState(0)
  const [rowsPerPageAssociation, setRowsPerPageAssociation] = useState(5)
  const [associationId, setAssociationId] = useState('')
  const [generateCodeOpen, setGenerateCodeOpen] = useState(false)
  const [generateCodeQuantity, setGenerateCodeQuantity] = useState(0)

  //PEDIDOS
  const [order, setOrder] = useState([])
  const [orderPage, setOrderPage] = useState(0)
  const [rowsPerPageOrder, setRowsPerPageOrder] = useState(5)
  const [orderId, setOrderId] = useState('')
  const [openViewOrder, setOpenViewOrder] = useState(false)
  const [updateOrder, setUpdateOrder] = useState('1')

  const [barCode, setBarCode] = useState('')
  const [color, setColor] = useState('')
  const [finishGood, setFinishGood] = useState()
  const [idProductPartner, setIdProductPartner] = useState('')
  const [productName, setProductName] = useState('')
  const [quantity, setQuantity] = useState(0)
  const [quantityFound, setQuantityFound] = useState(0)
  const [quantityFraction, setQuantityFraction] = useState(0)
  const [quantityNotFound, setQuantityNotFound] = useState(0)
  const [reference, setReference] = useState('')
  const [size, setSize] = useState('')
  const [typePiece, setTypePiece] = useState(0)

  const [simulateCountOpen, setSimulateCountOpen] = useState(false)
  const [simulateConferenceOpen, setSimulateConferenceOpen] = useState(false)
  const [countQuantity, setCountQuantity] = useState(0)
  const [countOrder, setCountOrder] = useState()
  const [conferenceOrder, setConferenceOrder] = useState()
  const [conferenceDivergence, setConferenceDivergence] = useState(false)

  const [createDate, setCreateDate] = useState('')
  const [isIntegrationBihands, setIsIntegrationBihands] = useState()
  const [isProcessInformation, setIsProcessInformation] = useState()
  const [movimentationOrderItemId, setMovimentationOrderItemId] = useState('')
  const [movimentationOrderNumberBihands, setMovimentationOrderNumberBihands] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [piece, setPiece] = useState(0)
  const [price, setPrice] = useState('')
  const [productItensId, setProductItensId] = useState('')
  const [referencia, setReferencia] = useState('')
  const [serial, setSerial] = useState('')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  useEffect(() => {
    fetchCompanies()
    getPermissions()
  }, [])

  useEffect(() => {
    setProduct([])
    setAssociation([])
    setOrder([])
    fillTables('')
  }, [companyId])

  const fetchCompanies = () => {
    let arrayCompanies = []
    subCompany.forEach(element => {
      const filter = arrayCompanies.some(company => company.companyId === element.companyId)
      if (!filter) {
        arrayCompanies.push(element)
      }
    })
    setCompanies(arrayCompanies)
  }

  const fillTables = async handleReload => {
    setLoading(true)
    try {
      if (companyId) {
        if (handleReload === chosenItem.product || handleReload === '') {
          setProduct([])
          const dataProduct = {
            limit: 500,
            offset: 0,
            companyId: companyId.companyId,
          }
          const responseProduct = await getProductAll(token, nameUrl, dataProduct)
          const resProduct = responseProduct.data.result
          setProduct(resProduct)
          setTotalProducts(responseProduct.data.total)
        }

        if (handleReload === chosenItem.association || handleReload === '') {
          setAssociation([])
          try {
            const responseAssociation = await getProductAssociationService(
              token,
              nameUrl,
              500,
              0,
              'desc',
              companyId.subCompanyId
            )
            const resAssociation = responseAssociation.data.result
            setAssociation(resAssociation)
            setAssociationTotal(responseAssociation.data.total)
          } catch (e) {
            setErrorEmpresa(true)
            setLoading(false)
          }
        }

        if (handleReload === chosenItem.order || handleReload === '') {
          setOrder([])
          const getId = await getOrderPaginationService(
            token,
            nameUrl,
            { subCompanyId: companyId.subCompanyId, typeOrder: 6 },
            20,
            0,
            false
          )
          const result = getId.data.result

          result.forEach(async item => {
            const response = await movimentationOrderId(token, nameUrl, item.movimentationOrderId)
            const res = response.data

            setOrder(order => [...order, res])
          })
        }
      }
      setHandleReload('')

      setLoading(false)
    } catch (e) {
      setErrorEmpresa(true)
      setLoading(false)
    }
  }

  const handleGetOrder = async () => {
    try {
      const getId = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: companyId.subCompanyId, typeOrder: 6 },
        20,
        0,
        false
      )
      const result = getId.data.result

      result.forEach(async item => {
        const response = await movimentationOrderId(token, nameUrl, item.movimentationOrderId)
        const res = response.data

        setOrder(order => [...order, res])
      })
    } catch (error) {}
  }

  const generateCode = () => {
    if (companyId) {
      setGenerateCodeOpen(true)
    } else {
      setTitle('Selecione uma filial')
      setSeverity('error')
      setRegister(true)
    }
  }

  const handleGenerateCode = async () => {
    setGenerateCodeOpen(false)
    setLoading(true)

    const data = {
      quantityItemAssociation: generateCodeQuantity,
      subcompanyCNPJ: companyId.cnpjCompany,
    }

    try {
      const response = await simulationAssociation(token, nameUrl, data)
      const res = response.data

      setTitle(res.message)
      setSeverity('success')
      setRegister(true)
    } catch (error) {
      setTitle('Erro ao gerar código, tente novamente')
      setSeverity('error')
      setRegister(true)
    }

    setLoading(false)
  }

  //UPDATE
  const reloadProducts = async which => {
    if (companyId) {
      switch (which) {
        case chosenItem.product:
          setSaving({ ...saving, product: true })
          break
        case chosenItem.association:
          setSaving({ ...saving, association: true })
          break
        case chosenItem.order:
          setSaving({ ...saving, order: true })
          break
      }

      setHandleReload(which)

      await fillTables(which)

      setSaving({
        product: false,
        association: false,
        order: false,
      })
    } else {
      setTitle('Selecione uma filial')
      setSeverity('error')
      setRegister(true)
    }
  }

  //DELETE
  const getPermissions = useCallback(async () => {
    setPermissionProductDelete(setPermission(userRoles, ProductDELETE))
  }, [])

  const handleConfirmDelete = (id, which) => {
    setDeleting(true)
    switch (which) {
      case chosenItem.product:
        setProductId(id)
        break
      case chosenItem.association:
        setAssociationId(id)
        break
      case chosenItem.order:
        setOrderId(id)
    }
    setWhichDelete(which)
  }

  const deleteProduct = useCallback(async () => {
    setDeleting(false)
    setLoading(true)

    try {
      if (whichDelete === chosenItem.product) {
        await deleteProductService(token, nameUrl, productId)
      }
      if (whichDelete === chosenItem.association) {
        await deleteProductAssociationService(token, nameUrl, associationId)
      }
      if (whichDelete === chosenItem.order) {
        await deleteMovimentationOrderService(token, nameUrl, orderId)
      }
      setTitle('Item deletado com sucesso!')
      setSeverity('success')

      setLoading(false)
      setRegister(true)
      //getAProducts()
    } catch (err) {
      setTitle('Erro ao deletar item, tente novamente')
      setSeverity('error')
      setLoading(false)
      setRegister(true)
      //getAProducts()
    }
  }, [productId, associationId, orderId])

  const deleteAll = which => {
    if (companyId) {
      setWhichDeleteAll(which)
      setDeletingAll(true)
    } else {
      setTitle('Selecione uma filial')
      setSeverity('error')
      setRegister(true)
    }
  }

  const deleteAllProduct = async () => {
    if (product.length > 0) {
      setDeletingAll(true)
      setLoading(true)

      try {
        product.forEach(async item => {
          const eachProductId = item.productId
          await deleteProductService(token, nameUrl, eachProductId)
        })
        setTitle('Produtos deletados com sucesso!')
        setSeverity('success')
        setLoading(false)
        setRegister(true)
        setDeletingAll(false)
      } catch (erro) {
        setTitle('Erro ao deletar os produtos, tente novamente')
        setSeverity('error')
        setLoading(false)
        setRegister(true)
        setDeletingAll(false)
      }
    } else {
      setTitle('Erro ao deletar os produtos, selecione uma filial')
      setSeverity('error')
      setRegister(true)
      setDeletingAll(false)
    }
  }

  const deleteAllAssociation = async () => {
    if (association.length > 0) {
      setDeletingAll(true)
      setLoading(true)

      try {
        association.forEach(async item => {
          const eachAssociationId = item.productItensId
          await deleteProductAssociationService(token, nameUrl, eachAssociationId)
        })
        setTitle('Associações deletadas com sucesso!')
        setSeverity('success')
        setLoading(false)
        setRegister(true)
        setDeletingAll(false)
      } catch (erro) {
        setTitle('Erro ao deletar as associações, tente novamente')
        setSeverity('error')
        setLoading(false)
        setRegister(true)
        setDeletingAll(false)
      }
    } else {
      setTitle('Erro ao deletar as associações, selecione uma filial')
      setSeverity('error')
      setRegister(true)
      setDeletingAll(false)
    }
  }

  //PAGE
  const handleChangePageProduct = (event, newPage) => {
    setProductPage(newPage)
  }

  const handleChangePageAssociation = (event, newPage) => {
    setAssociationPage(newPage)
  }

  const handleChangePageOrder = (event, newPage) => {
    setOrderPage(newPage)
  }

  const handleChangeRowsPerPageProduct = event => {
    setRowsPerPageProduct(+event.target.value)
  }

  const handleChangeRowsPerPageAssociation = event => {
    setRowsPerPageAssociation(+event.target.value)
  }

  const handleChangeRowsPerPageOrder = event => {
    setRowsPerPageOrder(+event.target.value)
  }

  //ORDER
  const simulateCount = async () => {
    if (companyId) {
      setSimulateCountOpen(true)
    } else {
      setTitle('Selecione uma filial')
      setSeverity('error')
      setRegister(true)
    }
  }

  const handleSimulateCount = async () => {
    if (countOrder) {
      if (countQuantity) {
        setSimulateCountOpen(false)
        setLoading(true)

        let movimentationOrderNumberBihands
        let movimentationOrderNumberOtherSystem

        try {
          const data = {
            typeOrder: 6,
            movimentationOrderNumberOtherSystem: countOrder,
            subCompanyIdFrom: companyId.subCompanyId,
          }
          const response = await createOrder(token, nameUrl, data)
          const res = response.data

          movimentationOrderNumberBihands = res.movimentationOrderNumberBihands
          movimentationOrderNumberOtherSystem = res.movimentationOrderNumberOtherSystem

          try {
            const data = {
              movimentationOrderNumberBihands: movimentationOrderNumberBihands,
              movimentationOrderNumberOtherSystem: movimentationOrderNumberOtherSystem,
              productQuantity: countQuantity,
              subcompanyCNPJ: companyId.cnpjCompany,
            }

            await simulationCountStockOrder(token, nameUrl, data)

            setTitle('Simulação feita com sucesso')
            setSeverity('success')
            setRegister(true)
          } catch (error) {
            setTitle('Erro ao simular a contagem, tente novamente')
            setSeverity('error')
            setRegister(true)
          }
        } catch (error) {
          setTitle('Erro ao simular a contagem, tente novamente')
          setSeverity('error')
          setRegister(true)
        }
        setLoading(false)
      } else {
        setTitle('Selecione uma quantidade')
        setSeverity('error')
        setRegister(true)
      }
    } else {
      setTitle('Selecione um pedido')
      setSeverity('error')
      setRegister(true)
    }
  }

  const simulateConference = async () => {
    if (companyId) {
      setSimulateConferenceOpen(true)
    } else {
      setTitle('Selecione uma filial')
      setSeverity('error')
      setRegister(true)
    }
  }

  const handleSimulateConference = async () => {
    if (conferenceOrder) {
      setSimulateConferenceOpen(false)
      setLoading(true)

      let movimentationOrderNumberBihands
      let movimentationOrderNumberOtherSystem

      try {
        const data = {
          typeOrder: 6,
          movimentationOrderNumberOtherSystem: conferenceOrder,
          subCompanyIdFrom: companyId.subCompanyId,
        }
        const response = await createOrder(token, nameUrl, data)
        const res = response.data

        movimentationOrderNumberBihands = res.movimentationOrderNumberBihands
        movimentationOrderNumberOtherSystem = res.movimentationOrderNumberOtherSystem

        try {
          const data = {
            subcompanyCNPJ: companyId.cnpjCompany,
            movimentationOrderNumberOtherSystem: movimentationOrderNumberOtherSystem,
            movimentationOrderNumberBihands: movimentationOrderNumberBihands,
            issue: conferenceDivergence,
          }

          await simulationConferenceOrder(token, nameUrl, data)

          setTitle('Simulação feita com sucesso')
          setSeverity('success')
          setRegister(true)
        } catch (error) {
          setTitle('Erro ao simular a conferência, tente novamente')
          setSeverity('error')
          setRegister(true)
        }
      } catch (error) {
        setTitle('Erro ao simular a conferência, tente novamente')
        setSeverity('error')
        setRegister(true)
      }
      setLoading(false)
    } else {
      setTitle('Selecione um pedido')
      setSeverity('error')
      setRegister(true)
    }
  }

  const viewOrderItem = async id => {
    setLoading(true)

    if (updateOrder === '1') {
      setBarCode(id.barCode)
      setColor(id.color)
      setFinishGood(id.finishGood)
      setIdProductPartner(id.idProductPartner)
      setProductName(id.productName)
      setQuantity(id.quantity)
      setQuantityFound(id.quantityFound)
      setQuantityFraction(id.quantityFraction)
      setQuantityNotFound(id.quantityNotFound)
      setReference(id.reference)
      setSize(id.size)
      setTypePiece(id.typePiece)
    } else {
      setCreateDate(id.createDate)
      setIdProductPartner(id.idProductPartner)
      setIsIntegrationBihands(id.isIntegrationBihands)
      setIsProcessInformation(id.isProcessInformation)
      setMovimentationOrderItemId(id.movimentationOrderItemId)
      setMovimentationOrderNumberBihands(id.movimentationOrderNumberBihands)
      setMovimentationOrderNumberOtherSystem(id.movimentationOrderNumberOtherSystem)
      setPiece(id.piece)
      setPrice(id.price)
      setProductItensId(id.productItensId)
      setProductName(id.productName)
      setQuantity(id.quantity)
      setReferencia(id.referencia)
      setSerial(id.serial)
      setTypePiece(id.typePiece)
    }

    setOpenViewOrder(true)
    setLoading(false)
  }

  const handleClose = () => {
    setErrorEmpresa(false)
    setRegister(false)
    setDeleting(false)
    setDeletingAll(false)
    setGenerateCodeOpen(false)
    setSimulateCountOpen(false)
    setSimulateConferenceOpen(false)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={errorEmpresa} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Erro na consulta, tente novamente mais tarde
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={register}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>

        <Dialog
          open={deleting}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Tem certeza que deseja excluir esse item?
          </DialogTitle>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Não
            </Button>
            <Button onClick={deleteProduct}>Sim</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deletingAll}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Tem certeza que deseja excluir{' '}
            {whichDeleteAll === chosenItem.product ? 'todos os produtos?' : 'todas as associações?'}
          </DialogTitle>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Não
            </Button>
            <Button
              onClick={() => {
                if (whichDeleteAll === chosenItem.product) {
                  deleteAllProduct()
                } else if (whichDeleteAll === chosenItem.association) {
                  deleteAllAssociation()
                }
              }}
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <ViewElearning pageName={'LoteReport'} open={viewElearning} setOpen={setViewElearning} />
          <Typography variant="h5">Processo de Integração</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        <FormControl sx={{ width: '100%', mt: 2 }}>
          <InputLabel id="demo-simple-select-label">Filial</InputLabel>

          <Select
            label="Selecione a filial"
            onChange={e => setCompanyId(e.target.value)}
            value={companyId}
          >
            {subCompany.map((item, index) => {
              return (
                <MenuItem value={item} key={index}>
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <Box
          sx={{
            width: 1,
            display: 'flex',
            flexDirection: 'column',
            mt: '2rem',
            mb: '3rem',
          }}
        >
          <Box
            sx={{
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6" sx={{ flex: '1 0 auto' }}>
              1 - Produtos
            </Typography>
            <Box sx={{ flex: '0 0 auto' }}>
              <Button
                variant="contained"
                disabled={saving.product}
                endIcon={<CachedIcon />}
                onClick={() => reloadProducts(chosenItem.product)}
                sx={{ margin: '0 0.5rem' }}
              >
                Atualizar
              </Button>
              <Button
                variant="contained"
                onClick={() => deleteAll(chosenItem.product)}
                sx={{ margin: '0 0.5rem' }}
              >
                Apagar tudo
              </Button>
            </Box>
          </Box>

          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                  <StyledTableCell align="left">Descrição</StyledTableCell>
                  <StyledTableCell align="left">Identificação ERP</StyledTableCell>
                  <StyledTableCell align="left">Referência</StyledTableCell>
                  <StyledTableCell align="left">Tipo Produto</StyledTableCell>
                  <StyledTableCell align="left">Marca</StyledTableCell>
                  <StyledTableCell align="left">Fabricante</StyledTableCell>
                  <StyledTableCell align="left">Preço</StyledTableCell>
                  <StyledTableCell align="left">SKU</StyledTableCell>
                  <StyledTableCell align="left">Informação Adicional</StyledTableCell>
                  <StyledTableCell align="left">Ativo</StyledTableCell>
                  <StyledTableCell align="left">Cód. Barras</StyledTableCell>
                  <StyledTableCell align="left">Cor</StyledTableCell>
                  <StyledTableCell align="left">Data Validade</StyledTableCell>
                  <StyledTableCell align="left">Laboratório</StyledTableCell>
                  <StyledTableCell align="left">Link</StyledTableCell>
                  <StyledTableCell align="left">Posição de baixa</StyledTableCell>
                  <StyledTableCell align="left">Peça</StyledTableCell>
                  <StyledTableCell align="left">Quantidade Tempo</StyledTableCell>
                  <StyledTableCell align="left">Tamanho</StyledTableCell>
                  <StyledTableCell align="left">Estoque Atual</StyledTableCell>
                  <StyledTableCell align="left">Tipo Rótulo</StyledTableCell>
                  <StyledTableCell align="left">Tipo Peça</StyledTableCell>
                  <StyledTableCell align="left">Variação</StyledTableCell>
                  <StyledTableCell align="left">Criação</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                </TableRow>
              </TableHead>
              {product.length > 0 && (
                <TableBody>
                  {product
                    .slice(
                      productPage * rowsPerPageProduct,
                      productPage * rowsPerPageProduct + rowsPerPageProduct
                    )
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                          {permissionProductDelete && (
                            <DeleteForeverIcon
                              onClick={() => handleConfirmDelete(row.productId, chosenItem.product)}
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.description}</StyledTableCell>
                        <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                        <StyledTableCell align="left">{row.referencia}</StyledTableCell>
                        <StyledTableCell align="left">{row.typeProduct || '-'}</StyledTableCell>
                        <StyledTableCell align="left">{row.brand}</StyledTableCell>
                        <StyledTableCell align="left">{row.supplier}</StyledTableCell>
                        <StyledTableCell align="left">{row.price}</StyledTableCell>
                        <StyledTableCell align="left">{row.sku}</StyledTableCell>
                        <StyledTableCell align="left">{row.info}</StyledTableCell>
                        <StyledTableCell align="left">{row.active ? 'Sim' : 'Não'}</StyledTableCell>
                        <StyledTableCell align="left">{row.barcode}</StyledTableCell>
                        <StyledTableCell align="left">{row.color}</StyledTableCell>
                        <StyledTableCell align="left">
                          {new Date(row.dataExpiration).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.laboratorio}</StyledTableCell>
                        <StyledTableCell align="left">{row.link}</StyledTableCell>
                        <StyledTableCell align="left">{row.picking}</StyledTableCell>
                        <StyledTableCell align="left">{row.piece}</StyledTableCell>
                        <StyledTableCell align="left">{row.quantityTime}</StyledTableCell>
                        <StyledTableCell align="left">{row.size}</StyledTableCell>
                        <StyledTableCell align="left">{row.stockActual}</StyledTableCell>
                        <StyledTableCell align="left">{row.typeLabel}</StyledTableCell>
                        <StyledTableCell align="left">{row.typePiece}</StyledTableCell>
                        <StyledTableCell align="left">{row.variation}</StyledTableCell>
                        <StyledTableCell align="left">
                          {new Date(row.created).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.info}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              )}
            </Table>
            <TablePagination
              labelRowsPerPage={'Produtos por página'}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={totalProducts}
              rowsPerPage={rowsPerPageProduct}
              page={productPage}
              onPageChange={handleChangePageProduct}
              onRowsPerPageChange={handleChangeRowsPerPageProduct}
            />
          </TableContainer>
        </Box>

        <Dialog
          open={generateCodeOpen}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Escolha a quantidade de códigos a gerar
          </DialogTitle>
          <DialogContent>
            <Box width={{ xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="quantity"
                id="outlined-basic"
                label="Quantidade"
                variant="outlined"
                type="number"
                onInput={e => setGenerateCodeQuantity(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={handleGenerateCode}>Gerar</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            width: 1,
            display: 'flex',
            flexDirection: 'column',
            mt: '2rem',
            mb: '3rem',
          }}
        >
          <Box
            sx={{
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6" sx={{ flex: '1 0 auto' }}>
              2 - Associação
            </Typography>

            <Box sx={{ flex: '0 0 auto' }}>
              <Button
                variant="contained"
                disabled={saving.association}
                endIcon={<CachedIcon />}
                onClick={() => reloadProducts(chosenItem.association)}
                sx={{ margin: '0 0.5rem' }}
              >
                Atualizar
              </Button>
              <Button
                variant="contained"
                onClick={() => deleteAll(chosenItem.association)}
                sx={{ margin: '0 0.5rem' }}
              >
                Apagar tudo
              </Button>
              <Button
                variant="contained"
                onClick={() => generateCode()}
                sx={{ margin: '0 0.5rem' }}
              >
                Gerar códigos
              </Button>
            </Box>
          </Box>

          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="left">Identificação ERP</StyledTableCell>
                  <StyledTableCell align="left">Filial</StyledTableCell>
                  <StyledTableCell align="left">Serial</StyledTableCell>
                  <StyledTableCell align="left">Nome do produto</StyledTableCell>
                  <StyledTableCell align="left">Alocação</StyledTableCell>
                  <StyledTableCell align="left">Peça</StyledTableCell>
                  <StyledTableCell align="left">Preço</StyledTableCell>
                  <StyledTableCell align="left">Lote</StyledTableCell>
                  <StyledTableCell align="left">Tipo Peça</StyledTableCell>
                  <StyledTableCell align="left">Tempo digitado</StyledTableCell>
                  <StyledTableCell align="left">Laboratório</StyledTableCell>
                  <StyledTableCell align="left">Quantidade Tempo</StyledTableCell>
                  <StyledTableCell align="left">Ativo</StyledTableCell>
                  <StyledTableCell align="left">Data de expiração</StyledTableCell>
                  <StyledTableCell align="left">Data</StyledTableCell>
                </TableRow>
              </TableHead>
              {association.length > 0 && (
                <TableBody>
                  {association
                    .slice(
                      associationPage * rowsPerPageAssociation,
                      associationPage * rowsPerPageAssociation + rowsPerPageAssociation
                    )
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                          {permissionProductDelete && (
                            <DeleteForeverIcon
                              onClick={() =>
                                handleConfirmDelete(row.productItensId, chosenItem.association)
                              }
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                        <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                        <StyledTableCell align="left">{row.serial}</StyledTableCell>
                        <StyledTableCell align="left">{row.productName}</StyledTableCell>
                        <StyledTableCell align="left">{row.alocationStockName}</StyledTableCell>
                        <StyledTableCell align="left">{row.piece}</StyledTableCell>
                        <StyledTableCell align="left">{row.price}</StyledTableCell>
                        <StyledTableCell align="left">{row.lote}</StyledTableCell>
                        <StyledTableCell align="left">
                          {row.typePiece === 0 ? 'Peça' : row.typePiece === 1 ?? 'Box'}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.typeTime}</StyledTableCell>
                        <StyledTableCell align="left">{row.laboratory}</StyledTableCell>
                        <StyledTableCell align="left">{row.quantityTime}</StyledTableCell>
                        <StyledTableCell align="left">{row.active}</StyledTableCell>
                        <StyledTableCell align="left">
                          {new Date(row.dataExpiration).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {new Date(row.created).toLocaleString()}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              )}
            </Table>
            <TablePagination
              labelRowsPerPage={'Associações por página'}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={associationTotal}
              rowsPerPage={rowsPerPageAssociation}
              page={associationPage}
              onPageChange={handleChangePageAssociation}
              onRowsPerPageChange={handleChangeRowsPerPageAssociation}
            />
          </TableContainer>
        </Box>

        <Dialog
          open={simulateCountOpen}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Simular Contagem
          </DialogTitle>
          <DialogContent>
            <Box width={{ xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="order"
                id="outlined-basic"
                label="Pedido"
                variant="outlined"
                type="text"
                onInput={e => setCountOrder(e.target.value)}
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="quantity"
                id="outlined-basic"
                label="Quantidade"
                variant="outlined"
                type="number"
                onInput={e => setCountQuantity(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={handleSimulateCount}>Simular</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={simulateConferenceOpen}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Simular Conferência
          </DialogTitle>
          <DialogContent>
            <Box width={{ xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="order"
                id="outlined-basic"
                label="Pedido"
                variant="outlined"
                type="text"
                onInput={e => setConferenceOrder(e.target.value)}
              />
              <Box
                width={{ xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }}
                sx={{ textAlign: 'center' }}
              >
                <InputLabel sx={{ width: '100%', mt: '1rem' }}>
                  Deseja simular com divergência
                </InputLabel>
                <Checkbox
                  checked={conferenceDivergence}
                  onChange={i => setConferenceDivergence(i.target.checked)}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={handleSimulateConference}>Simular</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openViewOrder}
          onClose={() => setOpenViewOrder(false)}
          aria-labelledby="dialog-title"
          PaperProps={{
            style: {
              width: '70vw',
              maxWidth: 'none',
              maxHeight: 'none',
            },
          }}
        >
          <DialogTitle id="dialog-title">Dados do Pedido</DialogTitle>
          <DialogContent>
            {updateOrder === '1' ? (
              <>
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
                >
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      value={barCode}
                      name="barCode"
                      id="standard-basic"
                      label="Código de Barras"
                      variant="outlined"
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Cor"
                      variant="outlined"
                      name="color"
                      value={color}
                      disabled
                      type="text"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
                >
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Produto"
                      variant="outlined"
                      name="productName"
                      value={productName}
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      name="quantity"
                      value={quantity}
                      id="outlined-basic"
                      label="Quantidade"
                      variant="outlined"
                      disabled
                      type="number"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
                >
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Referência"
                      variant="outlined"
                      name="reference"
                      value={reference}
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      name="size"
                      value={size}
                      id="outlined-basic"
                      label="Tamanho"
                      variant="outlined"
                      disabled
                      type="text"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
                >
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Ordem de Produção Finalizada"
                      variant="outlined"
                      name="finishGood"
                      value={finishGood ? 'Sim' : 'Não'}
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="ID do Produto Parceiro"
                      variant="outlined"
                      name="idProductPartner"
                      value={idProductPartner}
                      disabled
                      type="text"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
                >
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Quantidade Encontrada"
                      variant="outlined"
                      name="quantityFound"
                      value={quantityFound}
                      disabled
                      type="number"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Quantidade Fracionada"
                      variant="outlined"
                      name="quantityFraction"
                      value={quantityFraction}
                      disabled
                      type="number"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
                >
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Quantidade Não Encontrada"
                      variant="outlined"
                      name="quantityNotFound"
                      value={quantityNotFound}
                      disabled
                      type="number"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      name="typePiece"
                      value={typePiece}
                      id="outlined-basic"
                      label="Tipo de Peça"
                      variant="outlined"
                      disabled
                      type="number"
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
                >
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      value={Date(createDate).toLocaleString()}
                      name="createDate"
                      id="standard-basic"
                      label="Data de Criação"
                      variant="outlined"
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="ID do Produto Parceiro"
                      variant="outlined"
                      name="idProductPartner"
                      value={idProductPartner}
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Integração Bihands"
                      variant="outlined"
                      name="isIntegrationBihands"
                      value={isIntegrationBihands ? 'Sim' : 'Não'}
                      disabled
                      type="text"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
                >
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Informação do Processo"
                      variant="outlined"
                      name="isProcessInformation"
                      value={isProcessInformation ? 'Sim' : 'Não'}
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Movimentação Id"
                      variant="outlined"
                      name="Id do item do pedido de movimentação"
                      value={movimentationOrderItemId ? 'Sim' : 'Não'}
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Número da Ordem Bihands"
                      variant="outlined"
                      name="movimentationOrderNumberBihands"
                      value={movimentationOrderNumberBihands}
                      disabled
                      type="text"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
                >
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Número da Ordem em Outro Sistema"
                      variant="outlined"
                      name="movimentationOrderNumberOtherSystem"
                      value={movimentationOrderNumberOtherSystem}
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Peça"
                      variant="outlined"
                      name="piece"
                      value={piece}
                      disabled
                      type="number"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Preço"
                      variant="outlined"
                      name="price"
                      value={price}
                      disabled
                      type="text"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
                >
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      value={productItensId}
                      name="productItensId"
                      id="standard-basic"
                      label="ID dos Itens do Produto"
                      variant="outlined"
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Nome do Produto"
                      variant="outlined"
                      name="productName"
                      value={productName}
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Quantidade"
                      variant="outlined"
                      name="quantity"
                      value={quantity}
                      disabled
                      type="number"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
                >
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Referência"
                      variant="outlined"
                      name="reference"
                      value={referencia}
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Serial"
                      variant="outlined"
                      name="serial"
                      value={serial}
                      disabled
                      type="text"
                    />
                  </Box>
                  <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                    <TextField
                      sx={{ width: '100%', mt: '1rem' }}
                      id="outlined-basic"
                      label="Tipo de Peça"
                      variant="outlined"
                      name="typePiece"
                      value={typePiece}
                      disabled
                      type="number"
                    />
                  </Box>
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenViewOrder(false)}>Fechar</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            width: 1,
            display: 'flex',
            flexDirection: 'column',
            mt: '2rem',
            mb: '3rem',
          }}
        >
          <Box
            sx={{
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                width: 1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Typography variant="h6" sx={{ mr: '2rem' }}>
                3 - Pedidos
              </Typography>

              <FormControl sx={{ width: '30%', minWidth: '10rem', p: '0 0.3rem' }}>
                <InputLabel id="demo-simple-select-label">Atualizar </InputLabel>

                <Select
                  label="Selecione a filial"
                  onChange={e => setUpdateOrder(e.target.value)}
                  value={updateOrder}
                >
                  <MenuItem value={'1'} key={1}>
                    Contagem
                  </MenuItem>
                  <MenuItem value={'2'} key={2}>
                    Conferência
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ flex: '0 0 auto' }}>
              <Button
                variant="contained"
                disabled={saving.order}
                endIcon={<CachedIcon />}
                onClick={() => reloadProducts(chosenItem.order)}
                sx={{ margin: '0 0.5rem' }}
              >
                Atualizar
              </Button>
              <Button
                variant="contained"
                onClick={() => simulateCount()}
                sx={{ margin: '0 0.5rem' }}
              >
                Simular contagem
              </Button>
              <Button
                variant="contained"
                onClick={() => simulateConference()}
                sx={{ margin: '0 0.5rem' }}
              >
                Simular conferência
              </Button>
            </Box>
          </Box>

          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="left">Ordem Bihands</StyledTableCell>
                  <StyledTableCell align="left">Ordem Outro Sistema</StyledTableCell>
                  <StyledTableCell align="left">Data da Solicitação</StyledTableCell>
                  <StyledTableCell align="left">Tipo de Pedido</StyledTableCell>
                  <StyledTableCell align="left">Empresa</StyledTableCell>
                  <StyledTableCell align="left">Status da movimentação</StyledTableCell>
                </TableRow>
              </TableHead>
              {order.length > 0 && (
                <TableBody>
                  {order
                    .slice(
                      orderPage * rowsPerPageOrder,
                      orderPage * rowsPerPageOrder + rowsPerPageOrder
                    )
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                          {permissionProductDelete && (
                            <DeleteForeverIcon
                              onClick={() =>
                                handleConfirmDelete(row.movimentationOrderId, chosenItem.order)
                              }
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                          <RemoveRedEyeIcon
                            onClick={() =>
                              viewOrderItem(
                                updateOrder === '1'
                                  ? row.movimentationERPitemsCollections
                                  : row.movimentationOrderItemCollection
                              )
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.movimentationOrderNumberBihands}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.movimentationOrderNumberOtherSystem}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {Date(row.createRequestMovimentationOrderDate).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.typeOrderName}</StyledTableCell>
                        <StyledTableCell align="left">{row.subCompanyNameFrom}</StyledTableCell>
                        <StyledTableCell align="left">
                          {row.movimentationOrderStatus}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              )}
            </Table>
            <TablePagination
              labelRowsPerPage={'Pedidos por página'}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={order.length}
              rowsPerPage={rowsPerPageOrder}
              page={orderPage}
              onPageChange={handleChangePageOrder}
              onRowsPerPageChange={handleChangeRowsPerPageOrder}
            />
          </TableContainer>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default IntegrationProcess
