import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import {
  editCompanyService,
  getPlanService,
  getSegmentService,
  getUniqueCompanyService,
} from '../../../services/admServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const CompanyEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  companyId,
  handleFetchData,
  setExpanded,
}) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [code, setCode] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [cep, setCep] = useState('')
  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const [ddd, setDdd] = useState('')
  const [numberAddress, setNumberAddress] = useState('')
  const [status, setStatus] = useState(true)
  const [urlSend, setUrlSend] = useState('')
  const [planId, setPlanId] = useState('')
  const [plan, setPlan] = useState([])
  const [nameEmployer, setNameEmployer] = useState('')
  const [segmentId, setSegmentId] = useState('')
  const [segment, setSegment] = useState([])
  const [segmentName, setSegmentName] = useState('')
  const [quantitySubCompanyActual, setQuantitySubCompanyActual] = useState(0)
  const [quantitySubCompanyTotal, setQuantitySubCompanyTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const editCompany = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const date = new Date()

      const company = {
        companyId: companyId,
        code: code,
        cnpj: cnpj,
        name: name,
        mobile: mobile,
        email: email,
        cep: cep,
        city: city,
        address: address,
        ddd: ddd,
        numberAddress: numberAddress,
        active: status,
        urlSend: urlSend,
        plansId: planId,
        segmentId: segmentId,
        segment: segmentName,
        created: date.toISOString(),
        quantitySubCompanyActual: quantitySubCompanyActual,
        quantitySubCompanyTotal: quantitySubCompanyTotal,
      }

      try {
        console.log(company, 'company')
        await editCompanyService(token, nameUrl, company)
        setLoading(false)
        setEditing(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Empresa editada com sucesso!')
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente.')
      }
    },
    [
      code,
      cnpj,
      name,
      mobile,
      email,
      cep,
      city,
      address,
      ddd,
      numberAddress,
      status,
      urlSend,
      planId,
      segmentId,
      segmentName,
      quantitySubCompanyActual,
      quantitySubCompanyTotal,
    ]
  )

  const getCompany = async () => {
    setLoading(true)
    try {
      const response = await getUniqueCompanyService(token, nameUrl, companyId)
      setCode(response.data.code)
      setCnpj(response.data.cnpj)
      setName(response.data.name)
      setMobile(response.data.mobile)
      setEmail(response.data.email)
      setCep(response.data.cep)
      setCity(response.data.city)
      setAddress(response.data.address)
      setDdd(response.data.ddd)
      setNumberAddress(response.data.numberAddress)
      setStatus(response.data.active)
      setUrlSend(response.data.urlSend)
      setNameEmployer(response.data.nameEmployer)
      setPlanId(response.data.plansId)
      setSegmentId(response.data.segmentId)
      setSegmentName(response.data.segment)
      setQuantitySubCompanyActual(response.data.quantitySubCompanyActual)
      setQuantitySubCompanyTotal(response.data.quantitySubCompanyTotal)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const getSegment = useCallback(async () => {
    try {
      const response = await getSegmentService(token, nameUrl, 100, 0, true)
      setSegment(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const getPlan = useCallback(async () => {
    try {
      const response = await getPlanService(token, nameUrl, 100, 0, true)
      setPlan(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    getCompany()
    getSegment()
    getPlan()
  }, [companyId])

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={editCompany}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel id="demo-simple-select-label">Segmento</InputLabel>
              <Select
                label="Segmento"
                value={segmentId ? segmentId : ''}
                onChange={values => {
                  const selectedSegmentId = values.target.value
                  setSegmentId(selectedSegmentId)
                  const selectedSegment = segment.find(item => item.segmentId === selectedSegmentId)
                  const selectedSegmentName = selectedSegment ? selectedSegment.name : ''
                  setSegmentName(selectedSegmentName)
                }}
                sx={{ width: '100%' }}
              >
                {segment.map((item, index) => {
                  return (
                    <MenuItem value={item.segmentId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              marginTop={{ xs: '1rem', sm: '1rem', md: 0, lg: 0, xl: 0 }}
            >
              <InputLabel id="demo-simple-select-label">Plano</InputLabel>
              <Select
                label="Plano"
                value={planId ? planId : ''}
                onChange={values => {
                  setPlanId(values.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {plan.map((item, index) => {
                  return (
                    <MenuItem value={item.plansId} key={index}>
                      {item.plansName}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={name ? name : ''}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={cnpj ? cnpj : ''}
                onChange={values => setCnpj(values.target.value)}
                id="outlined-basic"
                label="Cnpj"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={ddd ? ddd : ''}
                onChange={values => setDdd(values.target.value)}
                id="outlined-basic"
                label="DDD"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{ mt: 3, textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <TextField
                sx={{ width: '100%' }}
                value={email ? email : ''}
                onChange={values => setEmail(values.target.value)}
                id="outlined-basic"
                label="Email da Empresa"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={city ? city : ''}
                onChange={values => setCity(values.target.value)}
                id="outlined-basic"
                label="Cidade"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{ mt: 3, textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <TextField
                sx={{ width: '100%' }}
                value={numberAddress ? numberAddress : ''}
                onChange={values => setNumberAddress(values.target.value)}
                id="outlined-basic"
                label="Número do Local"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={nameEmployer ? nameEmployer : ''}
                onChange={values => setNameEmployer(values.target.value)}
                id="outlined-basic"
                label="Sigla"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{ mt: 3, textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <TextField
                sx={{ width: '100%' }}
                value={code ? code : ''}
                onChange={values => setCode(values.target.value)}
                id="outlined-basic"
                label="Código"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={mobile ? mobile : ''}
                onChange={values => setMobile(values.target.value)}
                id="outlined-basic"
                label="Telefone"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{ mt: 3, textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <TextField
                sx={{ width: '100%' }}
                value={cep ? cep : ''}
                onChange={values => setCep(values.target.value)}
                id="outlined-basic"
                label="CEP"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box
              sx={{ mt: 3 }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              marginTop={{ xs: 3, sm: 3, md: 6, lg: 6, xl: 6 }}
            >
              <TextField
                sx={{ width: '100%' }}
                value={address ? address : ''}
                onChange={values => setAddress(values.target.value)}
                id="outlined-basic"
                label="Endereço"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{ mt: 3, textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <InputLabel color="error">Opicional</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={urlSend ? urlSend : ''}
                onChange={values => setUrlSend(values.target.value)}
                id="outlined-basic"
                label="Url de Envio dos Dados"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" type="submit">
              Salvar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => {
                setExpanded(false)
                setEditing(false)
              }}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default CompanyEdit
