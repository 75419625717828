import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const QuillEditor = ({ value, onChange }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
      ['code-block'],
    ],
    clipboard: {
      matchVisual: false,
    },
  }

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      style={{ height: '100%' }}
      modules={modules}
    />
  )
}

export default QuillEditor
