import React, { useContext, useEffect, useState, useCallback } from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  movBreakCancelService,
  movBreakCompletedService,
  movBreakProgressService,
  resetProcess,
} from '../../../../services/movBreakServices'
import MovBreakSingle from './movBreakSingle'
import { Context } from '../../../../context/AuthContext'
import { setPermission } from '../../../../permissions/userPermissions'
import { ClientView, PRODUCAO } from '../../../../const/RoleConstants'
import PermissionComponent from '../../../../permissions'

const Fracionado = props => {
  const { movBreak, totalQtd, movOrder, getMovBreak, StyledTableCell, fracionado, StyledTableRow } =
    props
  const { userRoles, nameUrl } = useContext(Context)

  const [frac, setFrac] = useState(fracionado)
  const [dialogOpen, setDialogOpen] = useState(false)
  const token = localStorage.getItem('token')
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)
  const [complete, setComplete] = useState('0')
  const [obs, setObs] = useState('')
  const [currentBreakId, setCurrentBreakId] = useState('')
  const [modalType, setModalType] = useState(0)
  const [movBreakSingle, setMovBreakSingle] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [permissionClientView, setPermissionClientView] = useState(false)

  const getPermissions = useCallback(async () => {
    setPermissionClientView(setPermission(userRoles, PRODUCAO))
  }, [])
  useEffect(() => {
    getPermissions()
  }, [])

  const openModal = (tipo, currBreakId) => {
    setSent(false)
    setSending(false)
    setDialogOpen(true)
    setComplete('0')
    setObs('')
    setCurrentBreakId(currBreakId)
    setModalType(tipo)
  }

  const handleMovBreakSingle = movBreakSingle => {
    console.log(movBreakSingle, 'movBreakSinglemovBreakSingle')
    setMovBreakSingle(movBreakSingle)
    setExpanded(true)
  }

  useEffect(() => {
    setFrac(fracionado)
  }, [fracionado])

  const closeDialog = () => {
    setDialogOpen(false)
  }

  const ReturnStatus = props => {
    const { numero } = props
    let colorFont = '#000000'
    let texto = 'Aberto'

    if (numero === 1) {
      texto = 'Aberto'
      colorFont = '#000000'
    } else if (numero === 2) {
      colorFont = '#008000'
      texto = 'Completo'
    } else if (numero === 3) {
      colorFont = '#FFA500'
      texto = 'Em andamento'
    } else if (numero === -2) {
      colorFont = '#FF0000'
      texto = 'Cancelado'
    } else if (numero === -1) {
      texto = 'Rascunho'
    }
    const styleStatus = { color: `${colorFont}` }
    return <span style={styleStatus}>{texto}</span>
  }

  const setCheckBox = (id, value) => {
    setFrac(
      frac.map(item => {
        if (item.movimentationOrderBreakId === id) {
          return { ...item, checked: value }
        } else {
          return item
        }
      })
    )
  }

  const ProgressBar = props => {
    const { value, total, colorType } = props
    const percent = Math.floor((value / total) * 100)
    let colorFinal = 'blue'
    if (colorType === 1) {
      colorFinal = '#000000'
    } else if (colorType === 2) {
      colorFinal = '#008000'
    } else if (colorType === 3) {
      colorFinal = '#FFA500'
    } else if (colorType === -2) {
      colorFinal = '#FF0000'
    }
    const styleProg = {
      width: `${percent}%`,
      backgroundColor: `${colorFinal}`,
    }
    return <LinearProgress variant="determinate" value={percent} />
  }

  const iniciaPedido = async breakId => {
    try {
      const response = await movBreakProgressService(token, nameUrl, breakId)
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => getMovBreak(), 1000)
  }

  const startSelected = async () => {
    const startPedidoBulk = async id => {
      try {
        const response = await movBreakProgressService(token, nameUrl, id)
      } catch (e) {
        console.log(e)
      }
    }
    frac.map(item => {
      if (item.checked) {
        startPedidoBulk(item.movimentationOrderBreakId)
      } else {
        return item
      }
    })
    setTimeout(() => getMovBreak(), 1000)
  }

  const completaPedido = async () => {
    const data = {
      quantityFinishOrder: complete,
      observation: obs,
      movimentationOrderBreakId: currentBreakId,
    }
    try {
      setSending(true)
      const response = await movBreakCompletedService(token, nameUrl, data)
      setSent(true)
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => getMovBreak(), 1000)
    setDialogOpen(false)
  }
  const cancelaPedido = async () => {
    const data = {
      observation: obs,
      movimentationOrderBreakId: currentBreakId,
    }
    try {
      setSending(true)
      const response = await movBreakCancelService(token, nameUrl, data)
      setSent(true)
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => getMovBreak(), 1000)
    setDialogOpen(false)
  }

  const handleReset = async mov => {
    setLoading(true)
    try {
      await resetProcess(token, nameUrl, mov)
      getMovBreak()
    } catch (e) {}
    setLoading(false)
  }

  return (
    <>
      <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2 }}>
        <FormControl sx={{ width: 0.25 }}>
          <TextField
            sx={{ width: '100%' }}
            value={movOrder.quantityTotal ? movOrder.quantityTotal : ''}
            id="outlined-basic"
            variant="outlined"
            label="Quantidade total"
            type="text"
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: 0.25 }}>
          <TextField
            sx={{ width: '100%' }}
            value={fracionado[0].numberParter ? fracionado[0].numberParter : ''}
            id="outlined-basic"
            variant="outlined"
            label="Quantidade frações"
            type="text"
            disabled
          />
        </FormControl>
        <Box
          sx={{ width: 0.5, alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}
        >
          <Button variant="contained" onClick={startSelected}>
            Iniciar Selecionados
          </Button>
        </Box>
      </Box>
      <Accordion sx={{ mt: 2 }} expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded(!expanded)}>
          <Typography>Informações Adicionais</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MovBreakSingle
            movBreakSingle={movBreakSingle}
            StyledTableCell={StyledTableCell}
            StyledTableRow={StyledTableRow}
          />
        </AccordionDetails>
      </Accordion>

      <TableContainer component={Paper} sx={{ mt: '2rem' }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Ordem Bihands</StyledTableCell>
              <StyledTableCell align="center">Ordem (outro sistema)</StyledTableCell>
              <StyledTableCell align="center">Ciclo</StyledTableCell>
              <PermissionComponent role="PRODUCAO">
                <>
                  <StyledTableCell align="center">Nome do Usuário</StyledTableCell>
                  <StyledTableCell align="center">Nome do cliente</StyledTableCell>
                </>
              </PermissionComponent>
              <StyledTableCell align="center">Progresso</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Criado</StyledTableCell>
              <StyledTableCell align="center">Última atualização</StyledTableCell>
              <StyledTableCell align="center">Ação</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {frac.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">
                  {row.movimentationOrderNumberBihandsBreak}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.movimentationOrderNumberOtherSystemBreak}
                </StyledTableCell>
                <StyledTableCell align="center">{row.cicle}</StyledTableCell>
                <PermissionComponent role="PRODUCAO">
                  <>
                    <StyledTableCell align="center">{row.userName}</StyledTableCell>
                    <StyledTableCell align="center">{row.clientName}</StyledTableCell>
                  </>
                </PermissionComponent>
                <StyledTableCell>
                  <ProgressBar
                    value={row.quantityFinishOrder}
                    total={row.quantityInicial}
                    colorType={row.orderStatus}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <ReturnStatus numero={row.orderStatus} /> ({row.quantityFinishOrder} /
                  {row.quantityInicial})
                </StyledTableCell>
                <StyledTableCell align="center">
                  {new Date(row.createRequestMovimentationOrderDate).toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {new Date(row.lastModification).toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.orderStatus === 1 && (
                    <>
                      <Tooltip title="Iniciar">
                        <IconButton onClick={() => iniciaPedido(row.movimentationOrderBreakId)}>
                          <PlayCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {row.orderStatus === 3 && (
                    <>
                      <Tooltip title="Completar">
                        <IconButton onClick={() => openModal(0, row.movimentationOrderBreakId)}>
                          <CheckBoxIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancelar">
                        <IconButton onClick={() => openModal(1, row.movimentationOrderBreakId)}>
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}

                  <Tooltip title="Detalhes">
                    <IconButton onClick={() => handleMovBreakSingle(row.movimentationOrderBreakId)}>
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip>
                    <IconButton onClick={() => handleReset(row.movimentationOrderBreakId)}>
                      <RestartAltIcon />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  {row.orderStatus === 1 && (
                    <Tooltip title="Selecionar">
                      <Checkbox
                        onChange={e => setCheckBox(row.movimentationOrderBreakId, e.target.checked)}
                      />
                    </Tooltip>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle id="alert-dialog-title">
          {modalType === 0 ? 'Completar pedido' : 'Cancelar pedido'}
        </DialogTitle>
        <DialogContent>
          <DialogContent>
            <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2 }}>
              {modalType === 0 && (
                <FormControl sx={{ minWidth: 0.5, width: 0.5 }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={complete}
                    onChange={e => setComplete(e.target.value)}
                    variant="outlined"
                    label="Quantidade completada"
                    type="text"
                  />
                </FormControl>
              )}
              <FormControl sx={{ width: 1 }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={obs}
                  variant="outlined"
                  onChange={values => setObs(values.target.value)}
                  label="Observação (opcional)"
                  type="text"
                />
              </FormControl>
            </Box>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          {modalType === 0 && (
            <>
              <Button disabled={sending} onClick={completaPedido} variant="contained">
                {sent ? 'Sucesso!' : 'Completar'}
              </Button>
            </>
          )}
          {modalType === 1 && (
            <>
              <Button disabled={sending} onClick={cancelaPedido} variant="contained">
                {sent ? 'Sucesso!' : 'Cancelar'}
              </Button>
            </>
          )}
          <Button onClick={closeDialog}>Fechar</Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default Fracionado
