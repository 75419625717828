import { useCallback, useContext, useEffect, useState } from 'react'
import { editEquipmentService, getUniqueEquipmentService } from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material'
import { formatNumbers } from '../../../utils/formatNumbers'
import { formatIP } from '../../../utils/formatIP'

const EquipmentEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  equipmentId,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const token = localStorage.getItem('token')
  const [ip, setIp] = useState('')
  const [name, setName] = useState('')
  const [port, setPort] = useState('')
  const [typeEquipmentHardware, setTypeEquipmentHardware] = useState(0)
  const [location, setLocation] = useState('')
  const [potencia, setPotencia] = useState(0)
  const [typeProcess, setTypeProcess] = useState(0)
  const [mac, setMac] = useState('')
  const [createDate, setCreateDate] = useState('')
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)

  const editEquipment = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const equipment = {
        equipmentId: equipmentId,
        ip: ip,
        name: name,
        port: port,
        location: location,
        createDate: createDate,
        active: active,
        subCompanyId: subCompanyId,
        typeEquipmentHardware: typeEquipmentHardware,
        mac: mac,
        power: potencia,
        typeOrder: typeProcess,
      }

      try {
        const response = await editEquipmentService(token, nameUrl, equipment)
        setSeverity('success')
        setTitle(response.data.message)
        setEditing(false)
        setAlerta(true)
        setLoading(false)
        handleFetchData()
      } catch (err) {
        console.log(err)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente.')
        setEditing(false)
        setAlerta(true)
        setLoading(false)
      }
    },
    [
      equipmentId,
      ip,
      name,
      typeEquipmentHardware,
      port,
      location,
      mac,
      createDate,
      active,
      subCompanyId,
      potencia,
      typeProcess,
    ]
  )

  const getEquipment = async () => {
    setLoading(true)
    try {
      const response = await getUniqueEquipmentService(token, nameUrl, equipmentId)
      setIp(response.data.ip)
      setName(response.data.name)
      setPort(response.data.port)
      setPotencia(response.data.power)
      setTypeProcess(response.data.typeOrder)
      setTypeEquipmentHardware(response.data.typeEquipmentHardware)

      setLocation(response.data.location)
      setMac(response.data.mac)
      setCreateDate(response.data.createDate)
      setActive(response.data.active)
      setSubCompanyId(response.data.subCompanyId)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    getEquipment()
  }, [equipmentId])

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={editEquipment}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ position: 'relative' }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '18px',
                  transform: 'translateY(-50%)',
                }}
              >
                Filial
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                onChange={values => {
                  setSubCompanyId(values.target.value)
                }}
                sx={{ width: '100%' }}
                value={subCompanyId}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: '100%' }}
                value={ip}
                onChange={values => setIp(formatIP(values.target.value))}
                id="outlined-basic"
                label="IP"
                variant="outlined"
                type="text"
              />
              <Typography variant="caption" color="#ccc">
                Ex: 192.168.000.001
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: '100%' }}
                value={port}
                onChange={values => setPort(formatNumbers(values.target.value))}
                id="outlined-basic"
                label="Porta"
                variant="outlined"
                type="text"
              />
              <Typography variant="caption" color="#ccc">
                Ex: 8080
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="dropdown-label" sx={{ marginBottom: 1 }}>
                Tipos de equipamentos
              </InputLabel>
              <Select
                sx={{ width: '100%' }}
                id="dropdown"
                value={typeEquipmentHardware}
                onChange={event => {
                  setTypeEquipmentHardware(event.target.value)
                }}
              >
                <MenuItem value={0}>Nenhum</MenuItem>
                <MenuItem value={1}>Impressora</MenuItem>
                <MenuItem value={2}>Reader RFID</MenuItem>
                <MenuItem value={3}>Máquina</MenuItem>
              </Select>
            </Grid>
            {typeEquipmentHardware === 2 && (
              <Grid item xs={12} md={6}>
                <InputLabel id="dropdown-label" sx={{ marginBottom: 1 }}>
                  Tipos de processo
                </InputLabel>
                <Select
                  sx={{ width: '100%' }}
                  id="dropdown"
                  value={typeProcess}
                  onChange={event => {
                    setTypeProcess(event.target.value)
                  }}
                >
                  <MenuItem value={0}>Nenhum</MenuItem>
                  <MenuItem value={7}>Entrada</MenuItem>
                  <MenuItem value={8}>Saida</MenuItem>
                </Select>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={6}
              sx={{ marginTop: { xs: 0, md: typeEquipmentHardware === 2 ? 0 : 4 } }}
            >
              <TextField
                sx={{ width: '100%' }}
                value={location}
                onChange={values => setLocation(values.target.value)}
                id="outlined-basic"
                label="Localização do Equipamento"
                variant="outlined"
                type="text"
              />
            </Grid>
            {typeEquipmentHardware === 2 && (
              <Grid item xs={12} md={6}>
                <InputLabel>Potência </InputLabel>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%' }}
                >
                  <Slider
                    sx={{ width: '90%' }}
                    max={30}
                    value={potencia}
                    valueLabelDisplay="auto"
                    onChange={values => setPotencia(values.target.value)}
                    aria-label="Range slider"
                    color="secondary"
                  />
                  <InputLabel sx={{ width: '10%', textAlign: 'end', ml: 2 }}>
                    {potencia.toString()}db
                  </InputLabel>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: '100%' }}
                value={mac}
                onChange={values => setMac(values.target.value)}
                id="outlined-basic"
                label="MAC"
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Ativo</InputLabel>
              <Checkbox onChange={() => setActive(!active)} checked={active} value={active} />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" type="submit">
              Salvar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => setEditing(false)}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default EquipmentEdit
