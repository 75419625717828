import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { editParamGroupService } from '../../../services/admServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const ListUserEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  setExpanded,
  functions,
  handleFetchData,
}) => {
  const token = localStorage.getItem('token')
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const [name, setName] = useState('')
  const [login, setLogin] = useState('')
  const [position, setPosition] = useState([])
  const [positionFunction, setPositionFunction] = useState([])
  const [selectedPositionFunction, setSelectedPositionFunction] = useState('')
  const [positionName, setPositionName] = useState('')
  const [status, setStatus] = useState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const editFunctions = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const functionsEdited = {
        subCompanyId: subCompanyId,
        position: {
          positionId: selectedPositionFunction,
          positionName: position.name,
        },
        name: name,
        login: setLogin,
      }

      console.log(functionsEdited, 'edit')

      try {
        await editParamGroupService(token, nameUrl, functionsEdited)
        setLoading(false)
        setEditing(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Funções/Cargo editado com sucesso')
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Verifique os dados e tente novamente.')
      }
    },
    [name, login, status]
  )

  const getFunctions = async () => {
    console.log(functions, 'dsa')
    setLoading(true)
    try {
      setName(functions.name)
      setLogin(functions.login)
      setPositionName(functions.position.positionName)
      setSelectedPositionFunction(functions.position.id)
      setSubCompanyId(functions.subCompanyId)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const handleFunctionPosition = () => {
    try {
      const response = localStorage.getItem('functionsFilial')
      const funcoesPorFilial = response ? JSON.parse(response) : []
      const objetoEncontrado = funcoesPorFilial.filter(objeto => objeto.id === subCompanyId)
      setPositionFunction(objetoEncontrado)
      console.log(funcoesPorFilial, 'neww')
    } catch (error) {}
  }

  useEffect(() => {
    getFunctions()
    console.log(positionFunction, 'positionFunctionpositionFunction')
  }, [functions, positionFunction])

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={editFunctions}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Selecione a Filial</InputLabel>
                <Select
                  value={subCompanyId}
                  sx={{ width: '100%' }}
                  label="Selecione a Filial"
                  onChange={e => {
                    setSubCompanyId(e.target.value)
                    handleFunctionPosition()
                  }}
                >
                  {subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Selecione o Função/Cargo</InputLabel>
                <Select
                  value={selectedPositionFunction}
                  sx={{ width: '100%' }}
                  label="Selecione o Função/Cargo"
                  onChange={e => setSelectedPositionFunction(e.target.value)}
                >
                  {positionFunction.length === 0 ? (
                    <MenuItem value="">Carregando... </MenuItem>
                  ) : (
                    positionFunction.map((item, index) => {
                      return (
                        <MenuItem value={item.positionId} key={item.positionId}>
                          {item.name}
                        </MenuItem>
                      )
                    })
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Box width="100%">
                <TextField
                  sx={{ width: '100%' }}
                  value={name}
                  onChange={values => setName(values.target.value)}
                  id="outlined-basic"
                  label="Nome"
                  variant="outlined"
                  type="text"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box width="100%">
                <TextField
                  sx={{ width: '100%' }}
                  value={login}
                  onChange={values => setLogin(values.target.value)}
                  id="outlined-basic"
                  label="Login"
                  variant="outlined"
                  type="text"
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          ></Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ListUserEdit
