import { useCallback, useContext, useEffect, useState } from 'react'
import { createSubCompanyService } from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

const SubCompanyCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const { subCompany, nameUrl } = useContext(Context)

  const token = localStorage.getItem('token')
  const [name, setName] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [cep, setCep] = useState('')
  const [phone, setPhone] = useState('')
  const [active, setActive] = useState(false)
  const [companyId, setCompanyId] = useState('')
  const [loading, setLoading] = useState(false)

  const createSubCompany = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const subCompany = {
        name: name,
        cnpj: cnpj,
        active: active,
        companyId: companyId,
        address,
        city,
        neighborhood,
        cep,
        phone,
      }

      try {
        await createSubCompanyService(token, nameUrl, subCompany)
        setSeverity('success')
        setTitle('Filial cadastrada com sucesso!')
        setExpanded(false)
        setAlerta(true)
        setLoading(false)
        handleFetchData()
      } catch (err) {
        setSeverity('error')
        setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
        setAlerta(true)
        setLoading(false)
      }
    },
    [name, cnpj]
  )

  const handleInputCnpj = event => {
    const regex = /^[0-9\b]+$/ // permite apenas números
    const input = event.target.value

    if (regex.test(input)) {
      setCnpj(input)
    }
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createSubCompany}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select
                label="Empresa"
                value={companyId}
                onChange={values => setCompanyId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.companyId} key={index}>
                      {item.nameCompany}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={address}
                onChange={values => setAddress(values.target.value)}
                id="outlined-basic"
                label="Endereço"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={city}
                onChange={values => setCity(values.target.value)}
                id="outlined-basic"
                label="Cidade"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={neighborhood}
                onChange={values => setNeighborhood(values.target.value)}
                id="outlined-basic"
                label="Bairro"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={cep}
                onChange={values => setCep(values.target.value)}
                id="outlined-basic"
                label="Cep"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={phone}
                onChange={values => setPhone(values.target.value)}
                id="outlined-basic"
                label="Telefone"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={cnpj}
                onChange={handleInputCnpj}
                id="outlined-basic"
                label="CNPJ"
                variant="outlined"
                type="text"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'center', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 2 }}>
              <InputLabel>Ativo</InputLabel>
              <Checkbox value={active} onChange={() => setActive(!active)} checked={active} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default SubCompanyCreate
