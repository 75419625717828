import { useCallback, useContext, useEffect, useState } from 'react'
import { searchProductService } from '../../../../services/productService'
import { consultaCEPService } from '../../../../services/api'
import { Context } from '../../../../context/AuthContext'
import { getAllReasonService } from '../../../../services/admServices'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import {
  createOrderRouteService,
  getAllOrdersService,
} from '../../../../services/movimentationRouteServices'
import styled from '@emotion/styled'
import { colorTableDefault } from '../../../TemplateDefault'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useParams } from 'react-router-dom'

const EditRoute = ({ movRoute }) => {
  const { subCompany, nameUrl } = useContext(Context)
  // const [subCompanyId, setSubCompanyId] = useState(movRoute.subCompanyId)
  const token = localStorage.getItem('token')
  const [address, setAddress] = useState(movRoute.address)
  const [city, setCity] = useState(movRoute.city)
  const [cep, setCep] = useState(movRoute.cep)
  const [companyName, setCompanyName] = useState(movRoute.companyName)
  const [phone, setPhone] = useState(movRoute.phone)
  const [neighborhood, setNeighborhood] = useState(movRoute.neighborhood)
  // const [observation, setObservation] = useState(movRoute.observation)
  // const [quantityFaction, setQuantityFaction] = useState(movRoute.quantityFaction)
  // const [quantityTotal, setQuantityTotal] = useState(movRoute.quantityTotal)

  // const [search, setSearch] = useState('')
  // const [searching, setSearching] = useState(false)
  // const [products, setProducts] = useState([])
  // const [singleProd, setSingleProd] = useState({
  //   name: '',
  //   idProductPartner: '',
  //   quantity: '',
  //   finishGood: false,
  // })

  // const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  // const [reason, setReason] = useState(movRoute.reason)
  // const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState(movRoute.movimentationOrderNumberOtherSystem)
  // const [reasonfororderId, setReasonfororderId] = useState('')
  // const [itensOrder, setItensOrder] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  useEffect(() => {
    setAddress(movRoute.address)
    setCity(movRoute.city)
    setCep(movRoute.cep)
    setCompanyName(movRoute.companyName)
    setPhone(movRoute.phone)
    setNeighborhood(movRoute.neighborhood)
  }, [movRoute])

  // useEffect(() => {
  //   if (search === '') {
  //     const checagem = setTimeout(() => {
  //       setProducts([{ label: '', value: '' }])
  //     }, 1000)
  //     return () => clearTimeout(checagem)
  //   } else {
  //     setSearching(true)
  //     const checagem = setTimeout(() => {
  //       const dados = {
  //         CompanyId: subCompany[0].companyId,
  //         SearchWord: search,
  //         limit: 1000,
  //         offset: 1,
  //       }
  //       searchProductService(token, nameUrl, dados).then(response => {
  //         const prodsFinal = []
  //         response.data.result.map(produto => {
  //           const obj = produto
  //           obj.value = produto.idProductPartner
  //           obj.label = `${produto.name}`
  //           prodsFinal.push(obj)
  //         })
  //         setProducts(prodsFinal)
  //         setSearching(false)
  //       })
  //     }, 1000)
  //     return () => clearTimeout(checagem)
  //   }
  // }, [search])

  // const addToList = () => {
  //   const testeNumber = parseInt(singleProd.quantity)
  //   if (isNaN(testeNumber)) {
  //     setSingleProd(curr => ({
  //       ...curr,
  //       quantity: 'Quantidade inválida',
  //     }))
  //     return
  //   }
  //   const repetido = itensOrder.find(x => x.idProductPartner === singleProd.idProductPartner)
  //   if (repetido) {
  //     return
  //   } else {
  //     const prod = singleProd
  //     prod.quantity = parseInt(singleProd.quantity)
  //     console.log(singleProd)
  //     setItensOrder(curr => [...curr, singleProd])
  //   }
  // }

  // const getReason = useCallback(async () => {
  //   try {
  //     const response = await getAllReasonService(token, nameUrl)
  //     setReason(response.data.result)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }, [reason])

  // const getMovOrders = useCallback(async () => {
  //   if (subCompanyId === '') {
  //     return
  //   }
  //   try {
  //     const response = await getAllOrdersService(token)
  //     setMovOrders(response.data.result)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }, [subCompanyId, token])

  // const addToList = () => {
  //   const testeNumber = parseInt(singleProd.quantity)
  //   if (isNaN(testeNumber)) {
  //     setSingleProd(curr => ({
  //       ...curr,
  //       quantity: 'Quantidade inválida',
  //     }))
  //     return
  //   }
  //   const repetido = itensOrder.find(x => x.idProductPartner === singleProd.idProductPartner)
  //   if (repetido) {
  //     return
  //   } else {
  //     const prod = singleProd
  //     prod.quantity = parseInt(singleProd.quantity)
  //     console.log(singleProd)
  //     setItensOrder(curr => [...curr, singleProd])
  //   }
  // }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      //color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleClose = () => {
    setLoading(false)
  }

  return (
    <>
      <Box sx={{ width: 0.75 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao criar o Pedido!
          </Alert>
        </Snackbar>
        <Box sx={{ mt: '2rem' }}>
          {/* <Box
            sx={{ width: '100%', justifyContent: 'space-between' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          > */}
          {/* <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Filial"
                value={subCompanyId}
                onChange={values => setSubCompanyCNPJ(values.target.value)}
                disabled
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box> */}
          {/* <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Razão</InputLabel>
              <Select
                label="Razão"
                value={reasonfororderId}
                onChange={e => setReasonfororderId(e.target.value)}
                sx={{ width: '100%' }}
              >
                {reason &&
                  reason.map((item, index) => {
                    return (
                      <MenuItem value={item.reasonfororderId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </Box> */}
          {/* <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Número da Ordem</InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={movimentationOrderNumberOtherSystem}
                id="standard-basic"
                label="Número da Ordem"
                //variant="standard"
                onChange={values => setMovimentationOrderNumberOtherSystem(values.target.value)}
                type="text"
              />
            </Box>
          </Box> */}

          <Box
            sx={{ width: '100%', justifyContent: 'space-between' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              {/* <InputLabel id="demo-simple-select-label">Nome Empresa</InputLabel> */}
              <TextField
                sx={{ width: 1 }}
                value={companyName}
                id="standard-basic"
                label="Nome Empresa"
                //variant="standard"
                onChange={values => setCompanyName(values.target.value)}
                type="text"
                disabled
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              {/* <InputLabel id="demo-simple-select-label">Telefone</InputLabel> */}
              <TextField
                sx={{ width: 1 }}
                value={phone}
                id="standard-basic"
                label="Telefone"
                //variant="standard"
                onChange={values => setPhone(values.target.value)}
                type="text"
                disabled
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              {/* <InputLabel id="demo-simple-select-label">CEP</InputLabel> */}
              <TextField
                sx={{ width: 1 }}
                value={cep}
                id="standard-basic"
                label="CEP"
                //variant="standard"
                // onChange={values => changeCep(values.target.value)}
                type="text"
                disabled
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-between' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              {/* <InputLabel id="demo-simple-select-label">Endereço</InputLabel> */}
              <TextField
                sx={{ width: 1 }}
                value={address}
                id="standard-basic"
                label="Endereço"
                //variant="standard"
                onChange={values => setAddress(values.target.value)}
                type="text"
                disabled
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              {/* <InputLabel id="demo-simple-select-label">Cidade</InputLabel> */}
              <TextField
                sx={{ width: 1 }}
                value={city}
                id="standard-basic"
                label="Cidade"
                //variant="standard"
                onChange={values => setCity(values.target.value)}
                type="text"
                disabled
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              {/* <InputLabel id="demo-simple-select-label">Bairro</InputLabel> */}
              <TextField
                sx={{ width: 1 }}
                value={neighborhood}
                id="standard-basic"
                label="Bairro"
                //variant="standard"
                onChange={values => setNeighborhood(values.target.value)}
                type="text"
                disabled
              />
            </Box>
          </Box>
          {/* <Box
            sx={{ width: '100%', justifyContent: 'space-between' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          > */}
          {/* <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Observação</InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={observation}
                id="standard-basic"
                label="Observação"
                //variant="standard"
                onChange={values => setObservation(values.target.value)}
                type="text"
              />
            </Box> */}
          {/* <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Quantidade Facção</InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={quantityFaction}
                id="standard-basic"
                label="Quantidade Facção"
                //variant="standard"
                onChange={values => setQuantityFaction(values.target.value)}
                type="text"
              />
            </Box> */}
          {/* <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Quantidade Total</InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={quantityTotal}
                id="standard-basic"
                label="Quantidade Total"
                //variant="standard"
                onChange={values => setQuantityTotal(values.target.value)}
                type="text"
              />
            </Box>
          </Box> */}
          {/* <Box
            sx={{
              width: 1,
              mt: 2,
              display: 'flex',
              gap: 2,
              border: '1px solid black',
              borderRadius: 2,
              p: 2,
              flexDirection: 'column',
            }}
          >
            <Box sx={{ width: 1, gap: 2 }}>
              <Box sx={{ width: 1, display: 'flex', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, width: 1 }}>
                  <FormControl sx={{ width: 0.4 }}>
                    <Autocomplete
                      disablePortal
                      id="lista-produtos"
                      options={products}
                      //sx={{ width: 300 }}
                      loading={searching}
                      loadingText="Procurando..."
                      noOptionsText="Nenhum produto encontrado"
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.productId}>
                            {option.label}
                          </li>
                        )
                      }}
                      renderInput={params => <TextField {...params} label="Procurar produto" />}
                      onInputChange={(e, v) => {
                        setSearch(v)
                      }}
                      //onChange={(e, v) => setProductId(v)}
                      onChange={(e, v) => {
                        setSingleProd(curr => ({
                          ...curr,
                          idProductPartner: v.value,
                          name: v.name,
                        }))
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ width: 0.2 }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.quantity}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Quantidade"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          quantity: e.target.value,
                        }))
                      }
                    />
                  </FormControl>

                  <Box sx={{ width: 0.25, alignItems: 'center', display: 'flex' }}>
                    <Button variant="outlined" onClick={addToList}>
                      Adicionar à lista
                    </Button>
                  </Box>
                </Box>
              </Box>
              <TableContainer component={Paper} sx={{ mt: '2rem' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell> </StyledTableCell>
                      <StyledTableCell align="center">Produto</StyledTableCell>
                      <StyledTableCell align="center">ERP</StyledTableCell>
                      <StyledTableCell align="center">Quantidade</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itensOrder.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setItensOrder(curr => curr.filter((v, i) => i !== index))}
                        >
                          <DeleteForeverIcon />
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.name}</StyledTableCell>
                        <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                        <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* </Box> */}
          {/* </Box> */}
          {/* </Box> */}
          {/* terminou */}

          {/* <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box> */}
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default EditRoute
