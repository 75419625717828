import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { editTagService } from '../../../services/movimentationRouteServices'
import { searchProductService } from '../../../services/productService'
import { useDebounce } from '../../View/ProductClient/Components/useDebounce'
import { getPrinterZplService } from '../../../services/configServices'

const EditTable = ({
  open,
  setOpen,
  data,
  product,
  setTitleDelete,
  setAlertSuccessDelete,
  setAlertFailedDelete,
  getLabelOrder,
  subCompanyIdItem,
}) => {
  const { debounce } = useDebounce(1000, true)

  const { subCompany, nameUrl, company } = useContext(Context)
  const token = localStorage.getItem('token')
  const [movimentationOrderItemId, setMovimentationOrderItemId] = useState(
    data.movimentationOrderItemId
  )
  const [subCompanyId, setSubCompanyId] = useState(subCompanyIdItem)
  const [quantity, setQuantity] = useState(data.quantity)
  const [dataExpiration, setDataExpiration] = useState(
    new Date(data.datetimeExpiration).toLocaleString()
  )
  const [lote, setLote] = useState(data.lote)
  const [numberPallet, setNumberPallet] = useState(data.numberPallet)
  const [loading, setLoading] = useState(false)
  const [productSelect, setProductSelect] = useState(data.productId)
  const [produtos, setProdutos] = useState([])
  const [searching, setSearching] = useState(false)
  const [search, setSearch] = useState(data.idProductPartner)
  const [prodSelect, setProdSelect] = useState(data.productId)
  const [row, setRow] = useState()
  const [selectedId, setSelectedId] = useState(data.idProductPartner)
  const [searchingPrintZpl, setSearchingPrintZpl] = useState(false)
  const [rowPrintZpl, setRowPrintZpl] = useState()
  const [printZplId, setPrintZplId] = useState('')
  const [searchPrintZpl, setSearchPrintZpl] = useState('')
  const [printerZpl, setPrinterZpl] = useState([])

  const handleClose = () => {
    setOpen(!open)
  }

  useEffect(() => {
    const dataRow = {
      idProductPartner: data.idProductPartner,
      name: data.name,
    }

    setRow(dataRow)
    setSearch(dataRow.idProductPartner)
    console.log(data, 'das')
  }, [data])

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const data = {
        movimentationOrderItemId,
        subCompanyId,
        quantity,
        dataExpiration,
        lote,
        numberPallet,
        productId: productSelect,
      }
      await editTagService(token, nameUrl, data)
      setTitleDelete('Editado com sucesso!')
      setAlertSuccessDelete(true)
      setLoading(false)
      setOpen(false)
      getLabelOrder()
    } catch (e) {
      console.log(e)
      setAlertFailedDelete(true)
      setLoading(false)
    }
  }

  const handleGetAllProductsSearch = async () => {
    setSearching(true)
    const dataProduct = {
      companyId: subCompany[0].companyId,
      SearchWord: search,
      limit: 10,
      offset: 0,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProdutos(data)
        setSearching(false)
      })
    } catch (error) {
      console.error(error)
      setSearching(false)
    }
  }

  useEffect(() => {
    if (company.companyId) {
      setSearching(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search])

  const getPrinterZpl = async () => {
    setSearchingPrintZpl(true)
    try {
      const response = await getPrinterZplService(
        token,
        nameUrl,
        subCompanyId,
        500,
        0,
        'desc',
        searchPrintZpl
      )
      const data = response.data

      const sortedData = data.sort(
        (a, b) => new Date(b.create).getTime() - new Date(a.create).getTime()
      )

      setPrinterZpl(sortedData)
      setSearchingPrintZpl(false)
    } catch (err) {
      console.log(err)
      setSearchingPrintZpl(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      setSearchingPrintZpl(true)
      debounce(() => {
        getPrinterZpl()
      })
    }
  }, [searchPrintZpl])

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Editando</DialogTitle>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleSubmit}>
          <DialogContent>
            <Box
              sx={{ width: '100%', justifyContent: 'space-between' }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box sx={{ width: '100%' }}>
                <InputLabel id="filial">Filial</InputLabel>
                <Select
                  label="Filial"
                  name="filial"
                  sx={{ width: '100%' }}
                  onChange={e => setSubCompanyId(e.target.value)}
                  value={subCompanyId}
                >
                  {subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
            </Box>
            <Box
              sx={{ width: '100%', justifyContent: 'space-between', mt: 1 }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box sx={{ width: '100%' }}>
                <InputLabel id="produto">Produto</InputLabel>
                <Autocomplete
                  openText="Abrir"
                  closeText="Fechar"
                  clearText="Apagar"
                  noOptionsText={searching ? 'Carregando...' : 'Nenhum resultado encontrado'}
                  loadingText="Carregando..."
                  disablePortal
                  value={row}
                  loading={searching}
                  onInputChange={(_, newValue) => setSearch(newValue)}
                  popupIcon={searching ? <CircularProgress color="warning" size={20} /> : undefined}
                  onChange={(_, newValue) => {
                    if (newValue === null) {
                      setSelectedId(null)
                      setRow(null)
                      setSearch('')
                      handleGetAllProductsSearch()
                    } else {
                      setSelectedId(newValue?.idProductPartner)
                      setRow(newValue)
                      setSearch(newValue.referencia)
                    }
                  }}
                  options={produtos.map(prod => ({
                    idProductPartner: prod.idProductPartner,
                    name: prod.name,
                  }))}
                  getOptionLabel={option => `${option.name}`}
                  renderInput={params => <TextField {...params} label="Pesquisar produtos" />}
                />
              </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
              <InputLabel id="produto">Modelo de etiqueta</InputLabel>
              <Autocomplete
                openText="Abrir"
                closeText="Fechar"
                clearText="Apagar"
                noOptionsText={searchingPrintZpl ? 'Carregando...' : 'Nenhum resultado encontrado'}
                loadingText="Carregando..."
                disablePortal
                value={rowPrintZpl}
                disabled={!subCompanyId}
                loading={searchingPrintZpl}
                onInputChange={(_, newValue) => setSearchPrintZpl(newValue)}
                popupIcon={
                  searchingPrintZpl ? <CircularProgress color="warning" size={20} /> : undefined
                }
                onChange={(_, newValue) => {
                  if (newValue === null) {
                    setPrintZplId(null)
                    setRowPrintZpl(null)
                    setSearchPrintZpl('')
                    getPrinterZpl()
                  } else {
                    setPrintZplId(newValue?.printerZplId)
                    setRow(newValue)
                    setSearchPrintZpl(newValue.name)
                  }
                }}
                options={printerZpl}
                getOptionLabel={option => `${option.name}`}
                renderInput={params => (
                  <TextField {...params} label="Pesquisar Modelo de etiqueta" />
                )}
              />
            </Box>
            <Box
              sx={{ width: '100%', justifyContent: 'space-between' }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                <TextField
                  sx={{ width: '100%', mt: '1rem' }}
                  value={quantity}
                  name="quantity"
                  id="standard-basic"
                  label="Quantidade"
                  onChange={e => setQuantity(e.target.value)}
                  variant="outlined"
                  type="text"
                />
              </Box>
              <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                <TextField
                  sx={{ width: '100%', mt: '1rem' }}
                  id="outlined-basic"
                  label="Validade"
                  variant="outlined"
                  name="dataExpiration"
                  value={dataExpiration}
                  onChange={e => setDataExpiration(e.target.value)}
                  type="date"
                  pattern="\d{2}-\d{2}"
                  min="01-20"
                  max="12-50"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{ width: '100%', justifyContent: 'space-between' }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                <TextField
                  sx={{ width: '100%', mt: '1rem' }}
                  name="lote"
                  value={lote}
                  id="outlined-basic"
                  label="Lote"
                  variant="outlined"
                  onChange={e => setLote(e.target.value)}
                  type="text"
                />
              </Box>
              <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                <TextField
                  sx={{ width: '100%', mt: '1rem' }}
                  id="outlined-basic"
                  label="Número Pallet"
                  variant="outlined"
                  name="numberPallet"
                  value={numberPallet}
                  onChange={e => setNumberPallet(e.target.value)}
                  type="text"
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="contained" type="submit" autoFocus>
              Salvar
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default EditTable
