import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../context/AuthContext'
import { createDepositService } from '../../../services/depositService'

const DepositCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, fetchData }) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [depositName, setDepositName] = useState('')
  const [idDepositPartner, setIdDepositPartner] = useState('')
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [isDefault, setIsDefault] = useState(false)
  const navigate = useNavigate()

  const createDeposit = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      const deposit = {
        name: depositName,
        default: isDefault,
        idDepositPartner: idDepositPartner,
        subCompanyId,
      }

      try {
        await createDepositService(token, nameUrl, deposit)
        setLoading(false)
        setSeverity('success')
        setTitle('Depósito cadastrado com sucesso!')
        setExpanded(false)
        setAlerta(true)
        fetchData()
        setDepositName('')
        setIsDefault(false)
        setIdDepositPartner('')
        setSubCompanyId('')
      } catch (err) {
        console.log(err)
        setLoading(false)
        setSeverity('error')
        setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
        setAlerta(true)
      }
    },
    [depositName, subCompanyId]
  )

  return (
    <>
      <Box>
        <Box component="form" onSubmit={createDeposit} sx={{ mt: '1rem' }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-label">Filial</InputLabel>
            <Select
              value={subCompanyId}
              //sx={{width:'100%'}}
              label="Selecione a empresa"
              onChange={values => {
                setSubCompanyId(values.target.value)
              }}
            >
              {subCompany.map((item, index) => {
                return (
                  <MenuItem value={item.subCompanyId} key={index}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Box
            sx={{ width: '100%', justifyContent: 'space-between' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={depositName}
                id="standard-basic"
                label="Nome"
                variant="standard"
                onChange={values => setDepositName(values.target.value)}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="standard-basic"
                label="ID outro sistema"
                variant="standard"
                value={idDepositPartner}
                onChange={values => setIdDepositPartner(values.target.value)}
                type="text"
              />
            </Box>
          </Box>
          <Box sx={{ width: '50%', mt: 2, textAlign: 'center' }}>
            <InputLabel>Default</InputLabel>
            <Checkbox onChange={() => setIsDefault(!isDefault)} checked={isDefault} />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              mt: '1rem',
            }}
            flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
          >
            <Box
              width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button variant="contained" sx={{ width: '100%' }} type="submit">
                Salvar
              </Button>
            </Box>
            <Box
              width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                type="button"
                onClick={() => navigate('/Deposit')}
              >
                Voltar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default DepositCreate
