import { useEffect, useState, useContext } from 'react'

import React from 'react'
import { Context } from '../../../context/AuthContext'
import { getAllReference } from '../../../services/productService'
import {
  editZplReferenceService,
  generateViewZPLService,
  getSingleZplReferenceService,
  getZplDefault,
  labelaryService,
} from '../../../services/printerZPLDefaultServices'
import { getLaundry } from '../../../services/laundryServices'
import { useNavigate, useParams } from 'react-router-dom'
import { getFaction } from '../../../services/factionService'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const ZPLRefEdit = ({ match }) => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const { id } = useParams()
  const [previewImage, setPreviewImage] = useState()
  const [selectedFaccao, setSelectedFaccao] = useState('0')
  const [expanded, setExpanded] = useState('')
  const [previewOpen, setPreviewOpen] = useState(false)

  const [simbolos, setSimbolos] = useState([
    {
      name: 'Nenhum',
      printerLaundryId: '0',
      sequence: 0,
    },
    {
      name: 'Nenhum',
      printerLaundryId: '0',
      sequence: 1,
    },
    {
      name: 'Nenhum',
      printerLaundryId: '0',
      sequence: 2,
    },
    {
      name: 'Nenhum',
      printerLaundryId: '0',
      sequence: 3,
    },
    {
      name: 'Nenhum',
      printerLaundryId: '0',
      sequence: 4,
    },
    {
      name: 'Nenhum',
      printerLaundryId: '0',
      sequence: 5,
    },
    {
      name: 'Selecionar símbolo',
      printerLaundryId: '0',
      sequence: 6,
    },
    {
      name: 'Selecionar símbolo',
      printerLaundryId: '0',
      sequence: 7,
    },
    {
      name: 'Selecionar símbolo',
      printerLaundryId: '0',
      sequence: 8,
    },
  ])

  const navigate = useNavigate()
  const [infoZplRef, setInfoZplRef] = useState('')
  const [faction, setFaction] = useState()

  const [desc1, setDesc1] = useState('')
  const [desc2, setDesc2] = useState('')
  const [desc3, setDesc3] = useState('')
  const [desc4, setDesc4] = useState('')
  const [desc5, setDesc5] = useState('')
  const [desc6, setDesc6] = useState('')
  const [desc7, setDesc7] = useState('')
  const [desc8, setDesc8] = useState('')
  const [desc9, setDesc9] = useState('')

  const [loading, setLoading] = useState(true)

  const [loading2, setLoading2] = useState(false)
  const [ready, setReady] = useState(false)
  const [nameRef, setNameRef] = useState('')

  const [prodReference, setProdReference] = useState()
  const [selectedReference, setSelectedReference] = useState('0')

  const [ZPLDefaults, setZPLDefaults] = useState()
  const [selectedDefault, setSelectedDefault] = useState({
    quantityLaundry: 0,
    quantityDescription: 0,
    printerZPLDefaultId: '0',
  })

  const [laundry, setLaundry] = useState()

  const [company, setCompany] = useState()

  const getRefsDefaultsLaundry = async () => {
    setLoading(true)
    const r = await getAllReference(token, nameUrl, subCompany[0].companyId)
    setProdReference(r.data)

    const reqZPL = {
      order: true,
      companyId: subCompany[0].companyId,
      limit: 1000,
      offset: 0,
    }
    const r2 = await getZplDefault(token, nameUrl, reqZPL)
    setZPLDefaults(r2.data.result)

    const reqLaundry = {
      order: true,
      companyId: subCompany[0].companyId,
      limit: 1000,
      offset: 0,
    }
    const r3 = await getLaundry(token, nameUrl, reqLaundry)
    setLaundry(r3.data)
    const data = {
      limit: 10,
      offset: 1,
      companyId: subCompany[0].companyId,
    }
    const r4 = await getFaction(token, nameUrl, data)
    setFaction(r4.data.result)
    setLoading(false)
  }

  const generateView = async () => {
    setLoading(true)
    const companyId = company
    const descCollection = []

    const simbFinal = []

    descCollection.push({
      name: desc1,
      sequence: 0,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc2,
      sequence: 1,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc3,
      sequence: 2,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc4,
      sequence: 3,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc5,
      sequence: 4,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc6,
      sequence: 5,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc7,
      sequence: 6,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc8,
      sequence: 7,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc9,
      sequence: 8,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })

    const simbFull = simbolos.slice(0, selectedDefault.quantityLaundry)

    const descFull = descCollection.slice(0, selectedDefault.quantityDescription)

    simbFull.forEach(simb => {
      if (simb.printerLaundryId !== '0') {
        simbFinal.push(simb)
      }
    })

    const descFinal = descFull.filter(desc => desc.name !== '')

    const data = {
      companyId: company,
      printerZPLDefaultId: selectedDefault.printerZPLDefaultId,
      reference: selectedReference,
      printerZPLDescriptionCollection: descFinal,
      PrinterLaundryCollection: simbFinal,
    }
    console.log(data, 'Collection')
    try {
      //Gera codigo ZPL
      const zplFinal = await generateViewZPLService(token, nameUrl, data)
      //Reetorna o codigo ZPL Gerado
      console.log(zplFinal, ' zplFinal')

      //Transforma em img
      const zplLabelary = await labelaryService(zplFinal.data.zpl)
      console.log(zplLabelary, ' zplLabelary')

      const blob = new Blob([zplLabelary.data], { type: 'application/octet-stream' })

      const url = URL.createObjectURL(blob)

      setPreviewImage(url)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const editZplRef = async () => {
    setLoading2(true)
    const companyId = company
    const descCollection = []
    descCollection.push({
      name: desc1,
      sequence: 0,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc2,
      sequence: 1,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc3,
      sequence: 2,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc4,
      sequence: 3,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc5,
      sequence: 4,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc6,
      sequence: 5,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc7,
      sequence: 6,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc8,
      sequence: 7,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })
    descCollection.push({
      name: desc9,
      sequence: 8,
      companyId,
      printerZPLReferenceId: selectedReference.printerZPLReferenceId,
    })

    const simbFull = simbolos.slice(0, selectedDefault.quantityLaundry)
    const descFull = descCollection.slice(0, selectedDefault.quantityDescription)

    const simbFinal = simbFull.filter(simb => simb.printerLaundryId != '0')
    const descFinal = descFull.filter(desc => desc.name != '')

    const data = {
      printerZPLReferenceId: id,
      companyId: company,
      printerZPLDefaultId: selectedDefault.printerZPLDefaultId,
      name: nameRef,
      reference: selectedReference,
      printerZPLDescriptionCollection: descFinal,
      printerZplLaundryCollection: simbFinal,
    }
    try {
      editZplReferenceService(token, nameUrl, data).then(() => {
        setTimeout(() => navigate('/ZPLRef'), 3000)
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setLoading(false)
    setLoading2(false)
    setCompany(subCompany[0].companyId)
    getRefsDefaultsLaundry()
  }, [subCompany])

  useEffect(() => {
    if (ZPLDefaults && laundry && prodReference && !ready) getZplReferenceEdit()
  }, [ZPLDefaults, laundry, prodReference])

  useEffect(() => {
    if (ready) {
      generateView()
    }
  }, [ready])

  useEffect(() => {}, [previewImage])

  const getZplReferenceEdit = async () => {
    try {
      const response = await getSingleZplReferenceService(token, nameUrl, id)
      setInfoZplRef(response.data)
      setSelectedReference(response.data.reference)
      setNameRef(response.data.name)
      changeDefault(response.data.printerZPLDefaultId)
      const updatedSimbolos = [...simbolos]

      response.data.printerLaundryCollection.map((s, i) => {
        const simb = s
        simb.image = laundry.find(x => x.printerLaundryId === s.printerLaundryId).image
        updatedSimbolos[i] = simb
      })

      setSimbolos(updatedSimbolos)
      setDesc1(
        response.data.printerZPLDescriptionCollection[0] !== undefined
          ? response.data.printerZPLDescriptionCollection[0].name
          : ''
      )
      setDesc2(
        response.data.printerZPLDescriptionCollection[1] !== undefined
          ? response.data.printerZPLDescriptionCollection[1].name
          : ''
      )
      setDesc3(
        response.data.printerZPLDescriptionCollection[2] !== undefined
          ? response.data.printerZPLDescriptionCollection[2].name
          : ''
      )
      setDesc4(
        response.data.printerZPLDescriptionCollection[3] !== undefined
          ? response.data.printerZPLDescriptionCollection[3].name
          : ''
      )
      setDesc5(
        response.data.printerZPLDescriptionCollection[4] !== undefined
          ? response.data.printerZPLDescriptionCollection[4].name
          : ''
      )
      setDesc6(
        response.data.printerZPLDescriptionCollection[5] !== undefined
          ? response.data.printerZPLDescriptionCollection[5].name
          : ''
      )
      setDesc7(
        response.data.printerZPLDescriptionCollection[6] !== undefined
          ? response.data.printerZPLDescriptionCollection[6].name
          : ''
      )
      setDesc8(
        response.data.printerZPLDescriptionCollection[7] !== undefined
          ? response.data.printerZPLDescriptionCollection[7].name
          : ''
      )
      setDesc9(
        response.data.printerZPLDescriptionCollection[8] !== undefined
          ? response.data.printerZPLDescriptionCollection[8].name
          : ''
      )
      setReady(true)
    } catch (e) {
      console.log(e)
    }
  }

  const SymbolBar = ({ simbolos }) => {
    return (
      <div
        style={{
          display: 'flex',
          gap: '8px',
          justifyContent: 'center',
          marginBottom: '1rem',
        }}
      >
        {simbolos.map((simbolo, index) => (
          <div key={index}>
            <img src={simbolo.name} alt="" width="20px" height="20px" />
          </div>
        ))}
      </div>
    )
  }

  const changeSimbolo = (simbolo, sim) => {
    setExpanded('')

    if (sim.printerLaundryId === '0' || sim === '0') {
      const newSimbols = simbolos.map((s, i) => {
        if (i === simbolo) {
          return {
            name: 'Selecionar símbolo',
            printerLaundryId: '0',
            companyId: company,
          }
        } else {
          return {
            name: s.image,
            printerLaundryId: s.printerLaundryId,
            companyId: s.companyId,
          }
        }
      })
      setSimbolos(newSimbols)
    } else {
      const newSimbols = simbolos.map((s, i) => {
        if (i === simbolo) {
          return {
            name: sim.image,
            companyId: company,
            printerLaundryId: sim.printerLaundryId,
          }
        } else {
          return s
        }
      })
      setSimbolos(newSimbols)
    }
  }

  const previwZPL = () => {
    setPreviewOpen(true)
  }

  const handleClose = () => {
    setPreviewOpen(false)
  }

  const changeDefault = id => {
    if (id === '0') {
      setSelectedDefault({
        quantityLaundry: 0,
        quantityDescription: 0,
        printerZPLDefaultId: '0',
      })
    } else {
      const defZPL = ZPLDefaults.find(x => x.printerZPLDefaultId === id)
      setSelectedDefault(defZPL)
    }
  }

  return (
    <>
      <Modal
        open={previewOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          {previewImage ? <img src={previewImage} alt="Preview" height="500px" /> : 'Carregando...'}
        </Box>
      </Modal>
      <Box
        sx={{
          alignItems: 'center',
          margin: '0 auto',
          mt: { md: 8 },
          width: '90vw',
          maxWidth: '90vw',
        }}
      >
        <Typography variant="h4" sx={{ mb: 5 }}>
          ZPL Designer
        </Typography>
        <Box
          style={{
            display: 'flex',
            //flexDirection: 'column',
            marginBottom: '2rem',
            justifyContent: 'flex-start',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Box sx={{ border: '1px solid black', p: 2, height: '100%', width: '100%' }}>
                <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <InputLabel>Empresa</InputLabel>
                      <Select disabled sx={{ width: '100%' }} value={subCompany[0].nameCompany}>
                        <MenuItem value={subCompany[0].nameCompany} selected>
                          {subCompany[0].nameCompany}
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel>Facção</InputLabel>
                      <Select
                        sx={{ width: '100%' }}
                        value={selectedFaccao}
                        onChange={values => setSelectedFaccao(values.target.value)}
                      >
                        <MenuItem value="0">Facção (opcional)</MenuItem>
                        {faction &&
                          faction.map((f, i) => {
                            return (
                              <MenuItem value={f.factionId} key={i}>
                                {f.name}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputLabel>Nome</InputLabel>
                      <TextField
                        variant="outlined"
                        style={{ width: '100%', marginBottom: '1rem' }}
                        value={nameRef}
                        onChange={e => setNameRef(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel>Referência</InputLabel>
                      <Select
                        value={selectedReference}
                        sx={{ width: '100%' }}
                        onChange={values => setSelectedReference(values.target.value)}
                      >
                        <MenuItem value="0">Nenhum</MenuItem>
                        {prodReference &&
                          prodReference.map((f, i) => {
                            return (
                              <MenuItem value={f.referencia} key={i}>
                                {f.referencia}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel>Modelo da etiqueta</InputLabel>
                      <Select
                        sx={{ width: '100%' }}
                        disabled={selectedReference === '0'}
                        value={selectedDefault.printerZPLDefaultId}
                        onChange={values => changeDefault(values.target.value)}
                      >
                        <MenuItem value="0">Nenhum</MenuItem>
                        {ZPLDefaults &&
                          ZPLDefaults.map((f, i) => {
                            return (
                              <MenuItem value={f.printerZPLDefaultId} key={i}>
                                {f.name}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box
                        width={{ width: '100%', height: '100%' }}
                        sx={{ border: '1px solid black', p: 2 }}
                        style={
                          selectedDefault.quantityLaundry === 0
                            ? {
                                display: 'hidden',
                              }
                            : { display: 'block' }
                        }
                      >
                        <Typography variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
                          Inserir {selectedDefault.quantityLaundry} símbolos
                        </Typography>
                        {laundry && (
                          <>
                            {simbolos && <SymbolBar simbolos={simbolos} />}
                            <Box>
                              <Accordion
                                sx={{ marginBottom: '1rem' }}
                                expanded={expanded === 'panel1'}
                                onChange={() => setExpanded('panel1')}
                              >
                                <AccordionSummary>
                                  <Typography>Primeiro Símbolo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <List>
                                    <ListItem onClick={() => changeSimbolo(0, '0')}>
                                      Nenhum
                                    </ListItem>
                                    {laundry.map((sim, i) => (
                                      <>
                                        <Divider />
                                        <ListItem
                                          sx={{ cursor: 'pointer' }}
                                          onClick={() => changeSimbolo(0, sim)}
                                        >
                                          <ListItemAvatar>
                                            <Avatar
                                              alt={sim.name}
                                              src={sim.image}
                                              sx={{ width: 20, height: 15, cursor: 'pointer' }}
                                            />
                                          </ListItemAvatar>
                                        </ListItem>
                                      </>
                                    ))}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                style={
                                  selectedDefault.quantityLaundry > 1
                                    ? { marginBottom: '1rem' }
                                    : {
                                        marginBotton: '1rem',
                                        display: 'none',
                                      }
                                }
                                expanded={expanded === 'panel2'}
                                onChange={() => setExpanded('panel2')}
                              >
                                <AccordionSummary>
                                  <Typography>Segundo Símbolo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <List>
                                    <ListItem onClick={() => changeSimbolo(1, '0')}>
                                      Nenhum
                                    </ListItem>
                                    {laundry.map((sim, i) => (
                                      <>
                                        <Divider />
                                        <ListItem onClick={() => changeSimbolo(1, sim)}>
                                          <ListItemAvatar>
                                            <Avatar
                                              alt={sim.name}
                                              src={sim.image}
                                              sx={{ width: 20, height: 15, cursor: 'pointer' }}
                                            />
                                          </ListItemAvatar>
                                        </ListItem>
                                      </>
                                    ))}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                style={
                                  selectedDefault.quantityLaundry > 2
                                    ? { marginBottom: '1rem' }
                                    : {
                                        marginBotton: '1rem',
                                        display: 'none',
                                      }
                                }
                                expanded={expanded === 'panel3'}
                                onChange={() => setExpanded('panel3')}
                              >
                                <AccordionSummary>
                                  <Typography>Terceiro Símbolo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <List>
                                    <ListItem onClick={() => changeSimbolo(2, '0')}>
                                      Nenhum
                                    </ListItem>
                                    {laundry.map((sim, i) => (
                                      <>
                                        <Divider />
                                        <ListItem onClick={() => changeSimbolo(2, sim)}>
                                          <ListItemAvatar>
                                            <Avatar
                                              alt={sim.name}
                                              src={sim.image}
                                              sx={{ width: 20, height: 15, cursor: 'pointer' }}
                                            />
                                          </ListItemAvatar>
                                        </ListItem>
                                      </>
                                    ))}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                style={
                                  selectedDefault.quantityLaundry > 3
                                    ? { marginBottom: '1rem' }
                                    : {
                                        marginBotton: '1rem',
                                        display: 'none',
                                      }
                                }
                                expanded={expanded === 'panel4'}
                                onChange={() => setExpanded('panel4')}
                              >
                                <AccordionSummary>
                                  <Typography>Quarto Símbolo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <List>
                                    <ListItem onClick={() => changeSimbolo(3, '0')}>
                                      Nenhum
                                    </ListItem>
                                    {laundry.map((sim, i) => (
                                      <>
                                        <Divider />
                                        <ListItem onClick={() => changeSimbolo(3, sim)}>
                                          <ListItemAvatar>
                                            <Avatar
                                              alt={sim.name}
                                              src={sim.image}
                                              sx={{ width: 20, height: 15, cursor: 'pointer' }}
                                            />
                                          </ListItemAvatar>
                                        </ListItem>
                                      </>
                                    ))}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                style={
                                  selectedDefault.quantityLaundry > 4
                                    ? { marginBottom: '1rem' }
                                    : {
                                        marginBotton: '1rem',
                                        display: 'none',
                                      }
                                }
                                expanded={expanded === 'panel5'}
                                onChange={() => setExpanded('panel5')}
                              >
                                <AccordionSummary>
                                  <Typography>Quinto Símbolo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <List>
                                    <ListItem onClick={() => changeSimbolo(4, '0')}>
                                      Nenhum
                                    </ListItem>
                                    {laundry.map((sim, i) => (
                                      <>
                                        <Divider />
                                        <ListItem onClick={() => changeSimbolo(4, sim)}>
                                          <ListItemAvatar>
                                            <Avatar
                                              alt={sim.name}
                                              src={sim.image}
                                              sx={{ width: 20, height: 15, cursor: 'pointer' }}
                                            />
                                          </ListItemAvatar>
                                        </ListItem>
                                      </>
                                    ))}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                style={
                                  selectedDefault.quantityLaundry > 5
                                    ? { marginBottom: '1rem' }
                                    : {
                                        marginBotton: '1rem',
                                        display: 'none',
                                      }
                                }
                                expanded={expanded === 'panel6'}
                                onChange={() => setExpanded('panel6')}
                              >
                                <AccordionSummary>
                                  <Typography>Sexto Símbolo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <List>
                                    <ListItem onClick={() => changeSimbolo(5, '0')}>
                                      Nenhum
                                    </ListItem>
                                    {laundry.map((sim, i) => (
                                      <>
                                        <Divider />
                                        <ListItem onClick={() => changeSimbolo(5, sim)}>
                                          <ListItemAvatar>
                                            <Avatar
                                              alt={sim.name}
                                              src={sim.image}
                                              sx={{ width: 20, height: 15, cursor: 'pointer' }}
                                            />
                                          </ListItemAvatar>
                                        </ListItem>
                                      </>
                                    ))}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                style={
                                  selectedDefault.quantityLaundry > 6
                                    ? { marginBottom: '1rem' }
                                    : {
                                        marginBotton: '1rem',
                                        display: 'none',
                                      }
                                }
                                expanded={expanded === 'panel7'}
                                onChange={() => setExpanded('panel7')}
                              >
                                <AccordionSummary>
                                  <Typography>Sétimo Símbolo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <List>
                                    <ListItem onClick={() => changeSimbolo(6, '0')}>
                                      Nenhum
                                    </ListItem>
                                    {laundry.map((sim, i) => (
                                      <>
                                        <Divider />
                                        <ListItem onClick={() => changeSimbolo(6, sim)}>
                                          <ListItemAvatar>
                                            <Avatar
                                              alt={sim.name}
                                              src={sim.image}
                                              sx={{ width: 20, height: 15, cursor: 'pointer' }}
                                            />
                                          </ListItemAvatar>
                                        </ListItem>
                                      </>
                                    ))}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                style={
                                  selectedDefault.quantityLaundry > 7
                                    ? { marginBottom: '1rem' }
                                    : {
                                        marginBotton: '1rem',
                                        display: 'none',
                                      }
                                }
                                expanded={expanded === 'panel8'}
                                onChange={() => setExpanded('panel8')}
                              >
                                <AccordionSummary>
                                  <Typography>Oitavo Símbolo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <List>
                                    <ListItem onClick={() => changeSimbolo(7, '0')}>
                                      Nenhum
                                    </ListItem>
                                    {laundry.map((sim, i) => (
                                      <>
                                        <Divider />
                                        <ListItem onClick={() => changeSimbolo(7, sim)}>
                                          <ListItemAvatar>
                                            <Avatar
                                              alt={sim.name}
                                              src={sim.image}
                                              sx={{ width: 20, height: 15, cursor: 'pointer' }}
                                            />
                                          </ListItemAvatar>
                                        </ListItem>
                                      </>
                                    ))}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                style={
                                  selectedDefault.quantityLaundry > 8
                                    ? { marginBottom: '1rem' }
                                    : {
                                        marginBotton: '1rem',
                                        display: 'none',
                                      }
                                }
                                expanded={expanded === 'panel9'}
                                onChange={() => setExpanded('panel9')}
                              >
                                <AccordionSummary>
                                  <Typography>Nono Símbolo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <List>
                                    <ListItem onClick={() => changeSimbolo(8, '0')}>
                                      Nenhum
                                    </ListItem>
                                    {laundry.map((sim, i) => (
                                      <>
                                        <Divider />
                                        <ListItem onClick={() => changeSimbolo(8, sim)}>
                                          <ListItemAvatar>
                                            <Avatar
                                              alt={sim.name}
                                              src={sim.image}
                                              sx={{ width: 20, height: 15, cursor: 'pointer' }}
                                            />
                                          </ListItemAvatar>
                                        </ListItem>
                                      </>
                                    ))}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box
                        width={{ width: '100%', height: '100%' }}
                        sx={{ border: '1px solid black', p: 2 }}
                        style={
                          selectedDefault.quantityDescription === 0
                            ? { dispĺay: 'none' }
                            : { display: 'block' }
                        }
                      >
                        <Typography variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
                          Inserir {selectedDefault.quantityDescription} descrições
                        </Typography>
                        <TextField
                          variant="outlined"
                          label="Descrição"
                          style={{ width: '100%', marginBottom: '1rem' }}
                          value={desc1}
                          onChange={e => setDesc1(e.target.value)}
                        />
                        <TextField
                          variant="outlined"
                          value={desc2}
                          onChange={e => setDesc2(e.target.value)}
                          label="Descrição"
                          style={{
                            marginBottom: '1rem',
                            width: '100%',
                            display: selectedDefault.quantityDescription <= 1 && 'none',
                          }}
                        />
                        <TextField
                          variant="outlined"
                          value={desc3}
                          onChange={e => setDesc3(e.target.value)}
                          label="Descrição"
                          style={{
                            marginBottom: '1rem',
                            width: '100%',
                            display: selectedDefault.quantityDescription <= 2 && 'none',
                          }}
                        />
                        <TextField
                          variant="outlined"
                          value={desc4}
                          onChange={e => setDesc4(e.target.value)}
                          label="Descrição"
                          style={{
                            marginBottom: '1rem',
                            width: '100%',
                            display: selectedDefault.quantityDescription <= 3 && 'none',
                          }}
                        />
                        <TextField
                          variant="outlined"
                          value={desc5}
                          onChange={e => setDesc5(e.target.value)}
                          label="Descrição"
                          style={{
                            marginBottom: '1rem',
                            width: '100%',
                            display: selectedDefault.quantityDescription <= 4 && 'none',
                          }}
                        />
                        <TextField
                          variant="outlined"
                          value={desc6}
                          onChange={e => setDesc6(e.target.value)}
                          label="Descrição"
                          style={{
                            marginBottom: '1rem',
                            width: '100%',
                            display: selectedDefault.quantityDescription <= 5 && 'none',
                          }}
                        />
                        <TextField
                          variant="outlined"
                          value={desc7}
                          onChange={e => setDesc7(e.target.value)}
                          label="Descrição"
                          style={{
                            marginBottom: '1rem',
                            width: '100%',
                            display: selectedDefault.quantityDescription <= 6 && 'none',
                          }}
                        />
                        <TextField
                          variant="outlined"
                          value={desc8}
                          onChange={e => setDesc8(e.target.value)}
                          label="Descrição"
                          style={{
                            marginBottom: '1rem',
                            width: '100%',
                            display: selectedDefault.quantityDescription <= 7 && 'none',
                          }}
                        />
                        <TextField
                          variant="outlined"
                          value={desc9}
                          onChange={e => setDesc9(e.target.value)}
                          label="Descrição"
                          style={{
                            marginBottom: '1rem',
                            width: '100%',
                            display: selectedDefault.quantityDescription <= 8 && 'none',
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  padding: 2,
                  width: '100%',
                  height: '100%',
                  border: '1px solid black',
                }}
              >
                {loading ? (
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: 'center',
                      width: '100%',
                      height: '100%',
                      alignSelf: 'center',
                    }}
                  >
                    Carregando...
                  </Typography>
                ) : previewImage ? (
                  <img
                    src={previewImage}
                    alt="preview"
                    style={{ maxWidth: '100%', cursor: 'pointer' }}
                    onClick={() => previwZPL()}
                  />
                ) : (
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: 'center',
                      width: '100%',
                      alignSelf: 'center',
                    }}
                  >
                    Nenhuma Imagem para pré-visualizar
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box width="100%" display="flex" justifyContent="center">
                <Button variant="contained" type="button" onClick={() => navigate('/ZPLRef')}>
                  <b>Voltar</b>
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={generateView}
                  disabled={selectedDefault.printerZPLDefaultId === '0' || loading}
                  sx={{ mx: 2 }}
                >
                  <b>Pré-visualizar</b>
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={editZplRef}
                  disabled={selectedDefault.printerZPLDefaultId === '0' || loading2}
                >
                  <b>{loading2 ? 'Salvando...' : 'Salvar'}</b>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default ZPLRefEdit
