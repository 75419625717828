import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { editDepositService, getDepositIdService } from '../../../services/depositService'

const DepositEdit = ({ setEditing, setAlerta, setSeverity, setTitle, depositId, fetchData }) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const token = localStorage.getItem('token')
  const [depositName, setDepositName] = useState('')
  const [active, setActive] = useState(false)
  const [isDefault, setIsDefault] = useState(false)
  const [idDepositPartner, setIdDepositPartner] = useState('')
  const [loading, setLoading] = useState(false)

  const editDeposit = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      const depositEditData = {
        depositId: depositId,
        active,
        subCompanyId,
        default: isDefault,
        name: depositName,
        idDepositPartner,
      }
      console.log(depositEditData)
      try {
        await editDepositService(token, nameUrl, depositEditData)
        setSeverity('success')
        setTitle('Depósito editado com sucesso!')
        fetchData()
        setEditing(false)
        setAlerta(true)
        setActive(false)
        setSubCompanyId('')
        setIsDefault(false)
        setDepositName('')
        setIdDepositPartner()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente.')
        setAlerta(true)
      }
    },
    [depositId, active, subCompanyId]
  )

  const getDeposit = async () => {
    setLoading(true)
    try {
      const response = await getDepositIdService(token, nameUrl, depositId)
      setDepositName(response.data.name)
      setActive(response.data.active)
      setSubCompanyId(response.data.subCompanyId)
      setIsDefault(response.data.default)
      setIdDepositPartner(response.data.idDepositPartner)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    getDeposit()
  }, [depositId])

  return (
    <>
      <Box>
        <Box component="form" onSubmit={editDeposit} sx={{ mt: '2rem' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                label="Selecione a empresa"
                onChange={values => {
                  setSubCompanyId(values.target.value)
                }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-between' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={depositName}
                id="standard-basic"
                label="Nome"
                variant="standard"
                onChange={values => setDepositName(values.target.value)}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="standard-basic"
                label="ID outro sistema"
                variant="standard"
                value={idDepositPartner}
                onChange={values => setIdDepositPartner(values.target.value)}
                type="text"
              />
            </Box>
          </Box>
          <Box sx={{ width: '100%', justifyContent: 'space-evenly', display: 'flex' }}>
            <Box sx={{ width: '50%', mt: 2, textAlign: 'center' }}>
              <InputLabel>Default</InputLabel>
              <Checkbox onChange={() => setIsDefault(!isDefault)} checked={isDefault} />
            </Box>
            <Box sx={{ width: '50%', mt: 2, textAlign: 'center' }}>
              <InputLabel>Ativo</InputLabel>
              <Checkbox onChange={() => setActive(!active)} checked={active} />
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              mt: '1rem',
            }}
            flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
          >
            <Box
              width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button variant="contained" sx={{ width: '100%' }} type="submit">
                Salvar
              </Button>
            </Box>
            <Box
              width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                type="button"
                onClick={() => setEditing(false)}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default DepositEdit
