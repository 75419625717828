import { useState, useContext, useEffect, useRef } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import Alert from '@mui/material/Alert'
import * as XLSX from 'xlsx'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
  Chip,
  Select,
  MenuItem,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import styled from '@emotion/styled'
import { colorTableDefault } from '../../TemplateDefault'
import { Add, DeleteForever, Edit, ExpandMore, Replay } from '@mui/icons-material'

import { createDepositService, getDepositService } from '../../../services/depositService'
import { getProductList } from '../../../services/productService'
import { deleteProductsUpload, updateQuantityStock } from '../../../services/movBreakServices'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const UploadDeleteProducts = () => {
  const { subCompany, company, nameUrl } = useContext(Context)
  const [file, setFile] = useState(null)
  const [valueFile, setValueFile] = useState('')
  const token = localStorage.getItem('token')
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [titleDialog, setTitleDialog] = useState('')
  const [severity, setSeverity] = useState('error')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusMessage, setStatusMessage] = useState({})
  const [statusTable, setStatusTable] = useState(false)
  const [statusInfo, setStatusInfo] = useState(false)
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0)
  const [progress, setProgress] = useState(0)

  const [dialogAction, setDialogAction] = useState(null)

  const [singleProd, setSingleProd] = useState({
    id: '',
    barcode: '',
    name: '',
    description: '',
    price: '',
  })

  const [status, setStatus] = useState('Pronto')
  const [deleteId, setDeleteId] = useState('')
  const [search, setSearch] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')

  const [edit, setEdit] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [itemExcel, setItemExceç] = useState([])
  const [itemProd, setItensProd] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const [filter, setFilter] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const [sentProdsCount, setSentProdsCount] = useState(0)
  const [failedProds, setFailedProds] = useState([])
  const [numberItem, setNumberItem] = useState([])
  const [listOrderExit, setListOrderExist] = useState([])
  const [totaProdsSelectedCount, setTotaProdsSelectedCount] = useState(0)
  const ref = useRef()
  const [loadingMap, setLoadingMap] = useState({})
  const [subCompanyId, setSubCompanyId] = useState('')
  const [depositId, setDepositId] = useState('')
  const [subCompanyName, setSubCompanyName] = useState('')
  const [depositName, setDepositName] = useState('')
  const [deposit, setDeposit] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setSelectAll(event.target.value === 'All')
  }

  const handleItemSelect = itemId => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter(id => id !== itemId)
      } else {
        return [...prevSelectedItems, itemId]
      }
    })
  }

  const handleSelectAll = event => {
    const isChecked = event.target.checked

    setSelectAll(isChecked)
    setSelectedItems(prevSelectedItems => {
      if (!selectAll) {
        return filter.map((item, index) => item.barcode)
      } else {
        return []
      }
    })
    setRowsPerPage(isChecked ? filter.length + 1 : 10)
  }

  useEffect(() => {
    console.log(subCompany, 'subCOmpanu')
    if (selectedItems.length === filter.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedItems, filter])

  useEffect(() => {}, [singleProd])

  useEffect(() => {}, [itemExcel, statusMessage])

  const handleSelectFile = e => {
    setLoading(true)
    setItensProd([])
    setValueFile(e.target.name[0])
    const selectedFile = e.target.files[0]

    if (!selectedFile) {
      setFile(null)
      return
    }
    setFile(selectedFile)
    //sendFile(selectedFile)
    readExcel(selectedFile)
  }

  const readExcel = async file => {
    const reader = new FileReader()

    reader.onload = async e => {
      const data = e.target.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]

      const excelData = []
      const range = XLSX.utils.decode_range(worksheet['!ref'])

      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        const row = []
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellAddress = { c: colNum, r: rowNum }
          const cellRef = XLSX.utils.encode_cell(cellAddress)
          const cell = worksheet[cellRef]
          row.push(cell ? cell.v : undefined)
        }
        excelData.push(row)
      }

      console.log(excelData, 'excelData')
      const dataList = excelData.map(item => ({
        name: String(item[3]),
        addressCode: String(item[0]),
        responsibleEmployee: item[1] !== undefined && item[1] !== null ? Number(item[1]) : '0',
        barcode: String(item[2]),
        companyId: subCompany[0].companyId,
        subCompanyId: subCompanyId,
        subCompanyName: subCompanyName,
      }))
      setItensProd(dataList)
      setLoading(false)
      setValueFile('')
      resetFile()
    }

    reader.readAsBinaryString(file)
  }

  const resetFile = () => {
    ref.current.value = ''
  }

  useEffect(() => {}, [itemProd])

  useEffect(() => {}, [numberItem])

  const handleActionList = action => {
    if (selectedItems.length === 0) {
      setSeverity('error')
      setTitle('Selecione um item')
      setAlerta(true)
    } else {
      setOpen(true)
      setTitleDialog('Tem certeza que enviar esses itens?')
      setDialogAction(action)
    }
  }

  const handleSaveUnity = async data => {
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])

    try {
      const updatedFilter = filter.map(row => {
        if (row.barcode === data.barcode) {
          return {
            ...row,
            loading: true,
          }
        }
        return row
      })

      const dataList = {
        name: data.name,
        addressCode: data.addressCode,
        responsibleEmployee: Number(data.responsibleEmployee),
        barcode: data.barcode,
        serial: data.barcode,
        companyId: data.companyId,
        subCompanyId: data.subCompanyId,
      }

      setFilter(updatedFilter)
      await new Promise(resolve => setTimeout(resolve, 1000))

      const response = await deleteProductsUpload(token, nameUrl, dataList)

      const updatedFilterWithStatus = updatedFilter.map(row => {
        if (row.barcode === data.barcode) {
          return {
            ...row,
            loading: false,
            status: response.data.message,
          }
        }
        return row
      })
      setFilter(updatedFilterWithStatus)
      setSeverity('success')
      await new Promise(resolve => setTimeout(resolve, 1000))

      const updatedFilterWithoutSuccess = updatedFilterWithStatus.filter(
        row => row.barcode !== data.barcode
      )

      setSentProdsCount(prevSentProdsCount => prevSentProdsCount + 1)
      setFilter(updatedFilterWithoutSuccess)
    } catch (error) {
      const updatedFilter = filter.map(row => {
        if (row.barcode === data.barcode) {
          return {
            ...row,
            loading: false,
            status: 'Falha ao enviar, tente novamente!',
          }
        }
        return row
      })
      setSeverity('error')
      setFilter(updatedFilter)
      setFailedProds(prevSentProdsCount => prevSentProdsCount + 1)
    }
  }

  const handleSaveProductsSelected = async () => {
    setStatusInfo(true)
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])
    setProgress(0)

    const selectedProducts = itemProd.filter(prod => selectedItems.includes(prod.barcode))
    setTotaProdsSelectedCount(selectedProducts.length)

    const updatedFilter = filter.map(row => {
      if (row.barcode === data.barcode) {
        return {
          ...row,
          loading: true,
        }
      }
      return row
    })

    setFilter(updatedFilter)
    const data = selectedProducts.map(item => ({
      name: item.name,
      addressCode: item.addressCode,
      barcode: item.barcode,
      serial: item.barcode,
      responsibleEmployee: Number(item.responsibleEmployee),
      companyId: item.companyId,
      subCompanyId: item.subCompanyId,
    }))

    for (const selectedProduct of data) {
      try {
        console.log(filter, 'filter')
        // Set status to 'Enviando...' and loading to true for the current product
        setFilter(prevFilter =>
          prevFilter.map(row => {
            if (row.barcode === selectedProduct.barcode) {
              return {
                ...row,
                loading: true,
                status: 'Enviando...',
              }
            }
            return row
          })
        )

        await new Promise(resolve => setTimeout(resolve, 1000))
        const response = await deleteProductsUpload(token, nameUrl, selectedProduct)

        // Set status to 'Registro feito com sucesso' and loading to false for the current product
        setFilter(prevFilter =>
          prevFilter.map(row => {
            if (row.barcode === selectedProduct.barcode) {
              return {
                ...row,
                loading: false,
                status: 'Registro feito com sucesso',
              }
            }
            return row
          })
        )

        setSeverity('success')
        await new Promise(resolve => setTimeout(resolve, 1000))

        // Remove the successfully sent product from the filter
        setFilter(prevFilter => prevFilter.filter(row => row.barcode !== selectedProduct.barcode))

        // Update sent products count
        setSentProdsCount(prevSentProdsCount => prevSentProdsCount + 1)

        // Remove the successfully sent product from itemProd
        setItensProd(prevItemProd =>
          prevItemProd.filter(item => item.barcode !== selectedProduct.barcode)
        )
      } catch (error) {
        // Set status to 'Falha ao enviar, tente novamente!' and loading to false for the current product
        setFilter(prevFilter =>
          prevFilter.map(row => {
            if (row.barcode === selectedProduct.barcode) {
              return {
                ...row,
                loading: false,
                status: 'Falha ao enviar, tente novamente!',
              }
            }
            return row
          })
        )

        setSeverity('error')
        setFailedProds(prevFailedProdsCount => prevFailedProdsCount + 1)
      }
    }

    setStatusTable(false)
    setSelectedItems([])
    setStatusInfo(false)
  }

  const handleSaveProd = async () => {
    setStatusInfo(true)
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])
    setProgress(0)

    const totalItems = itemProd.length
    setNumberItem(totalItems)

    let updatedFilter = [...itemProd]

    const simultaneousRequests = 50

    const numBatches = Math.ceil(totalItems / simultaneousRequests)

    for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
      try {
        setCurrentBatchIndex(batchIndex + 1)

        const batchStart = batchIndex * simultaneousRequests
        const batchEnd = Math.min((batchIndex + 1) * simultaneousRequests, totalItems)

        const promises = []

        for (let i = batchStart; i < batchEnd; i++) {
          const product = itemProd[i]

          setFilter(prevFilter =>
            prevFilter.map(row =>
              row.barcode === product.barcode
                ? { ...row, loading: true, status: 'Enviando...' }
                : row
            )
          )

          const promise = new Promise(async (resolve, reject) => {
            try {
              const data = {
                name: product.name,
                addressCode: product.addressCode,
                barcode: product.barcode,
                serial: product.barcode,
                responsibleEmployee: Number(product.responsibleEmployee),
                companyId: product.companyId,
                subCompanyId: product.subCompanyId,
              }

              await deleteProductsUpload(token, nameUrl, data)

              setFilter(prevFilter =>
                prevFilter.map(row =>
                  row.barcode === product.barcode
                    ? { ...row, loading: false, status: 'Registro feito com sucesso' }
                    : row
                )
              )

              await new Promise(resolve => setTimeout(resolve, 1000))

              setFilter(prevFilter => prevFilter.filter(row => row.barcode !== product.barcode))
              updatedFilter = updatedFilter.filter(row => row.barcode !== product.barcode)
              setSentProdsCount(prevSentProdsCount => prevSentProdsCount + 1)

              resolve()
            } catch (error) {
              setFilter(prevFilter =>
                prevFilter.map(row =>
                  row.barcode === product.barcode
                    ? { ...row, loading: false, status: 'Falha ao enviar, tente novamente!' }
                    : row
                )
              )
              setSeverity('error')
              setFailedProds(prevFailedProds => [...prevFailedProds, product]) // Adicionar apenas o produto falho à lista
              reject(error)
            }
          })

          promises.push(promise)
        }

        await Promise.all(promises)

        const newProgress = ((batchIndex + 1) / numBatches) * 100
        setProgress(newProgress)
      } catch (error) {
        console.error('Erro ao processar lote:', error)
        setSeverity('error')
        // Aqui você não precisa adicionar todos os produtos do lote como falhas novamente
        // Apenas trate o erro do lote como necessário para exibir feedback adequado
      }

      await new Promise(resolve => setTimeout(resolve, 1000))
    }

    setStatusTable(false)
    setStatusInfo(false)
  }

  const deleteDialog = (clientId, del) => {
    setDeleteId(clientId)
    setOpen(true)
    setTitleDialog('Tem certeza que deseja excluir este item?')
    setDialogAction(del)
  }

  const handleDeleteItem = () => {
    const updatedItems = filter.filter(item => item.barcode !== deleteId)
    setSeverity('success')
    setTitle('Item apagado com sucesso!')
    setAlerta(true)
    setItensProd(updatedItems)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
    setAlerta(false)
  }

  const filterRow = () => {
    const filtered = itemProd.filter(
      row =>
        row.barcode.toLowerCase().includes(search.toLowerCase()) ||
        row.productName.toLowerCase().includes(search.toLowerCase())
    )
    setFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [search, itemProd])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        {statusInfo && (
          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={statusInfo}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              position="relative"
            >
              <CircularProgress value={progress} sx={{ color: colorTableDefault }} size={60} />
              <Box
                sx={{
                  position: 'absolute',
                  top: '26%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Typography>{Math.round(progress)}%</Typography>
              </Box>
              <Box textAlign="center" p={2}>
                {selectedItems.length > 0 ? (
                  <Typography>
                    {totaProdsSelectedCount.toLocaleString()} de {Math.floor(sentProdsCount)}{' '}
                    Produtos Enviadas...
                  </Typography>
                ) : (
                  <Typography>
                    {numberItem.toLocaleString()} de {sentProdsCount.toLocaleString()} Produtos
                    Enviadas...
                  </Typography>
                )}
              </Box>
            </Box>
          </Backdrop>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>{titleDialog}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                handleClose()
                if (dialogAction === 'delete') {
                  handleDeleteItem()
                } else if (dialogAction === 'save') {
                  handleSaveProductsSelected()
                }
                setDialogAction(null)
              }}
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Carga para apagar produtos</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%', mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                sx={{ width: '100%' }}
                label="Escolher o Usuário"
                onChange={e => {
                  setSubCompanyId(e.target.value)

                  const subCompanyName = subCompany.find(sub => sub.subCompanyId === e.target.value)
                  setSubCompanyName(subCompanyName.name)
                }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box
          sx={{ justifyContent: 'space-between', width: 1, minWidth: 1 }}
          display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              mt: 2,
              border: '1px solid black',
              width: { xs: 1, md: 0.34 },
              minWidth: { xs: 1, md: 0.3 },
              p: 2,
            }}
          >
            <a
              className="dropzone"
              target="_blank"
              rel="noreferrer"
              href="https://file.bihands.com/template/UploadDeleteProducts.xlsx"
              download
            >
              <FileDownloadIcon sx={{ fontSize: 100 }} />
            </a>
            <InputLabel>Faça o download do exemplo de carga</InputLabel>
          </Box>
          <Box
            sx={{
              mt: 2,
              border: { md: '1px solid black' },
              p: 2,
            }}
            width={{ xs: '100%', sm: '100%', md: '65%', lg: '65%', xl: '65%' }}
          >
            <InputLabel>Insira aqui o documento para enviar</InputLabel>
            <TextField
              sx={{ width: '100%', mt: 1 }}
              id="standard-basic"
              variant="standard"
              value={valueFile}
              disabled={!subCompanyId}
              onChange={handleSelectFile}
              ref={ref}
              type="file"
              accept=".xls, .xlsx, .ods"
            />
            <Box
              sx={{
                mt: 4,
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    sx={{ width: '100%', mb: 1 }}
                    id="standard-basic"
                    variant="outlined"
                    label="Procurar"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    type="text"
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="space-between" alignItems="end">
                <Box>
                  <Typography variant="body" sx={{ marginBottom: 2, mr: 0 }}>
                    {filter.length <= 1
                      ? `${filter.length} Resultado`
                      : `${filter.length} Resultados`}
                  </Typography>
                  {selectedItems.length > 0 && (
                    <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                      {selectedItems.length <= 1
                        ? `${selectedItems.length} Item Selecionado`
                        : `${selectedItems.length} Itens Selecionados`}
                    </Typography>
                  )}
                  {statusTable && (
                    <>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {listOrderExit.length > 0 && `${listOrderExit.length} ordens existentes`}
                      </Typography>
                    </>
                  )}
                  {statusTable && (
                    <>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {sentProdsCount <= 1
                          ? `${sentProdsCount} Itens Enviado`
                          : `${sentProdsCount} Itens Enviados`}
                      </Typography>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {failedProds.length > 1
                          ? `${failedProds.length} Itens não enviados`
                          : `${failedProds.length} Iten não enviados`}
                      </Typography>
                    </>
                  )}
                </Box>
                {selectedItems.length > 0 ? (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleActionList('save')}
                  >
                    Enviar Selecionados
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleSaveProd()}
                  >
                    Enviar Lista
                  </Button>
                )}
              </Box>
            </Box>

            <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Descrição do ativo</StyledTableCell>
                  <StyledTableCell align="center">Codigo do endereço</StyledTableCell>
                  <StyledTableCell align="center">Responsável matricula</StyledTableCell>
                  <StyledTableCell align="center">Codigo de barras</StyledTableCell>
                  <StyledTableCell align="center">Filial</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Ação</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter
                  .filter(
                    row => selectedStatus === '' || statusMessage[row.barcode] === selectedStatus
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align="left">{row.addressCode}</StyledTableCell>
                      <StyledTableCell align="left">{row.responsibleEmployee}</StyledTableCell>
                      <StyledTableCell align="left">{row.barcode}</StyledTableCell>
                      <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.loading ? (
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <CircularProgress size={30} />
                            <Typography ml={2} variant="caption">
                              Enviando...
                            </Typography>
                          </Box>
                        ) : (
                          <Chip
                            color={
                              row.status === 'Falha ao enviar, tente novamente!'
                                ? 'error'
                                : row.status === 'Registro feito com sucesso'
                                ? 'success'
                                : 'info'
                            }
                            label={
                              row.status === 'Falha ao enviar, tente novamente!'
                                ? 'Falha ao enviar'
                                : row.status === 'Registro feito com sucesso'
                                ? 'Registro feito com sucesso'
                                : 'Pronto para enviar'
                            }
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Tooltip title="Reenviar" arrow>
                            <IconButton onClick={() => handleSaveUnity(row)}>
                              <Replay sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Apagar" arrow>
                            <IconButton onClick={() => deleteDialog(row.barcode, 'delete')}>
                              <DeleteForever sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filter.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default UploadDeleteProducts
