import { Box, Divider } from '@mui/material'
import { useEffect, useState } from 'react'

const RotaGeral = ({ legs, rotas }) => {
  const [totals, setTotals] = useState({ distance: 0, duration: 0 })

  useEffect(() => {
    let kmTotal = 0
    let tempoTotal = 0
    legs.map(leg => {
      kmTotal = kmTotal + leg.distance.value
      tempoTotal = tempoTotal + leg.duration.value
    })
    const temp = tempoTotal / 60
    const horas = Math.floor(temp / 60)
    const min = temp % 60
    const textoHoras = `00${horas}`.slice(-2)
    const textoMinutos = `00${Math.floor(min)}`.slice(-2)

    setTotals({
      distance: (kmTotal / 1000).toFixed(1).replace('.', ','),
      // duration: Math.round(tempoTotal / 60),
      duration: `${
        textoHoras > 0 ? (textoHoras > 1 ? `${textoHoras} horas` : `${textoHoras} hora`) : ''
      } ${textoMinutos > 0 ? `${textoMinutos} minutos` : ''}`,
    })
  }, [legs])

  // const handleClick = leg => {
  //   const dirFinal = { origin: { lat: null, lng: null }, destination: { lat: null, lng: null } }
  //     const wpts = []

  //     dirFinal.origin.lat = parseFloat(rotas[0].latitude.replace(',', '.'))
  //     dirFinal.origin.lng = parseFloat(rotas[0].longitude.replace(',', '.'))
  //     dirFinal.destination.lat = parseFloat(rotas[rotas.length - 1].latitude.replace(',', '.'))
  //     dirFinal.destination.lng = parseFloat(rotas[rotas.length - 1].longitude.replace(',', '.'))
  // }

  const CardRotaTotal = ({ leg, chave }) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }} key={`c-${chave}`}>
        <Box sx={{ width: 0.35 }}>{leg.start_address}</Box>
        <Box sx={{ width: 0.35 }}>{leg.end_address}</Box>
        <Box sx={{ width: 0.1 }}>{leg.duration.text}</Box>
        <Box sx={{ width: 0.1 }}>{leg.distance.text}</Box>
      </Box>
    )
  }
  return (
    <Box sx={{ width: 1 }}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-around', backgroundColor: '#42a5f5', p: 2 }}
      >
        <Box sx={{ width: 0.35 }}>Partida</Box>
        <Box sx={{ width: 0.35 }}>Destino</Box>
        <Box sx={{ width: 0.1 }}>Duração</Box>
        <Box sx={{ width: 0.1 }}>Distância</Box>
      </Box>
      <Divider sx={{ mb: 2 }} />

      {legs &&
        legs.map((leg, i) => (
          <>
            <CardRotaTotal chave={i} leg={leg} key={i} />
            <Divider key={`d-${i}`} sx={{ my: 1 }} />
          </>
        ))}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <Box sx={{ width: 0.35 }}></Box>
        <Box sx={{ width: 0.35 }}>Total</Box>
        <Box sx={{ width: 0.1 }}>{totals.duration}</Box>
        <Box sx={{ width: 0.1 }}>{totals.distance} km</Box>
      </Box>
    </Box>
  )
}

export default RotaGeral
