import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

const DashCard = ({ title, icon, numero, subCompanyId }) => {

  const [numb, setNumb] = useState("--")

  useEffect(() => {
    if (subCompanyId !== "") {
      setNumb(numero)
    }
  }, [subCompanyId, numero])
  return (
    <>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: '120px',
          height: 1,
        }}
      >
        <Box>
          <Typography variant="overline">{title}</Typography>
          {/* <Typography variant="h4">{numero ? numero : <CircularProgress />}</Typography> */}
          <Typography variant="h4">{numb ? numb : <CircularProgress />}</Typography>
        </Box>
        {icon}
      </Paper>
    </>
  )
}

export default DashCard
