import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { createParamService, getParamGroupService } from '../../../services/admServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const ParametersCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [paramGroup, setParamGroup] = useState([])
  const [paramGroupId, setParamGroupId] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const createParam = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const date = new Date()

      const param = {
        name: name,
        description: description,
        active: true,
        created: date,
        parameterGroupId: paramGroupId,
      }

      try {
        await createParamService(localStorage.getItem('token'), param)
        setLoading(false)
        setExpanded(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Parametro cadastrado com sucesso!')
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
      }
    },
    [name, description]
  )

  const getParamGroup = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getParamGroupService(token, nameUrl, 1000, 0, true)
      setParamGroup(response.data.result)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [paramGroup])

  useEffect(() => {
    getParamGroup()
  }, [])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createParam}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel id="demo-simple-select-label">Grupo de Parâmetros</InputLabel>
              <Select
                label="Grupo de Parâmetros"
                value={paramGroupId}
                onChange={values => {
                  setParamGroupId(values.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {paramGroup.map((item, index) => {
                  return (
                    <MenuItem value={item.parameterGroupId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={description}
                onChange={values => setDescription(values.target.value)}
                id="outlined-basic"
                label="Descrição"
                variant="outlined"
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ParametersCreate
