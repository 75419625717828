import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { editFaction, getFactionId } from '../../../services/factionService'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from '@mui/material'

const FactionEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  factionId,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [companyId, setCompanyId] = useState('')

  const token = localStorage.getItem('token')
  const [sequence, setSequence] = useState('')
  const [name, setName] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [active, setActive] = useState(false)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [editForm, setEditForm] = useState(false)

  const handleEditFaction = async e => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    const data = {
      companyId,
      factionId: factionId,
      name: name,
      cnpj: cnpj,
      sequence: sequence,
      active,
    }

    try {
      await editFaction(token, nameUrl, data)
      setLoading(false)
      setEditing(false)
      setAlerta(true)
      setSeverity('success')
      setTitle('Facção editada com sucesso!')
      handleFetchData()
    } catch (err) {
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao editar, verifique os dados e tente novamente.')
    }
  }

  const getFaction = async () => {
    setEditForm(false)
    setLoading(true)
    try {
      const response = await getFactionId(token, nameUrl, factionId)
      setEditForm(true)
      setCnpj(response.data.cnpj)
      setName(response.data.name)
      setSequence(response.data.sequence)
      setCompanyId(response.data.companyId)
      setActive(response.data.active)
      setLoading(false)
    } catch (err) {
      setEditForm(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    getFaction()
  }, [factionId])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleEditFaction}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel>Empresa</InputLabel>
              <Select
                label="Empresa"
                value={companyId}
                onChange={e => setCompanyId(e.target.value)}
                sx={{ width: '100%' }}
              >
                {subCompany.map(item => {
                  return (
                    <MenuItem value={item.companyId}>
                      {item.nameCompany} - {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={sequence}
                onChange={values => setSequence(values.target.value)}
                id="outlined-basic"
                label="Sequência"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={cnpj}
                onChange={values => setCnpj(values.target.value)}
                id="outlined-basic"
                label="CNPJ"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ mt: 3, textAlign: 'center' }}
            width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
          >
            <InputLabel>Ativo</InputLabel>
            <Checkbox onChange={() => setActive(!active)} checked={active} value={active} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" type="submit">
              Salvar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => setEditing(false)}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default FactionEdit
