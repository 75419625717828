import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { createOrderFullService } from '../../../services/requestServices'
import { getAllReasonService } from '../../../services/admServices'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import { getProductCodService, searchProductService } from '../../../services/productService'
import { Search } from '@mui/icons-material'

const ExpeditionOrderCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
  StyledTableCell,
  StyledTableRow,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [reason, setReason] = useState([])
  const [reasonId, setReasonId] = useState('')
  const [observation, setObservation] = useState('')
  const [itensOrder, setItensOrder] = useState([])
  const token = localStorage.getItem('token')
  const [singleProd, setSingleProd] = useState({
    name: '',
    idProductPartner: '',
    quantity: '',
    finishGood: false,
  })

  const [insertionType, setInsertionType] = useState('manual')
  const [selectedProduct, setSelectedProduct] = useState('')
  const [product, setProduct] = useState([])
  const [listProductEnv, setListProductEnv] = useState([])
  const [textareaValue, setTextareaValue] = useState('')
  const [showTable, setShowTable] = useState(false)

  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([{ label: '', value: '' }])
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [error, setError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProducts([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.idProductPartner
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProducts(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  const addToList = () => {
    const testeNumber = parseInt(singleProd.quantity)
    if (isNaN(testeNumber)) {
      setSingleProd(curr => ({
        ...curr,
        quantity: 'Quantidade inválida',
      }))
      return
    }
    const repetido = itensOrder.find(x => x.idProductPartner === singleProd.idProductPartner)
    if (repetido) {
      return
    } else {
      const prod = singleProd
      prod.quantity = parseInt(singleProd.quantity)
      console.log(singleProd)
      setItensOrder(curr => [...curr, singleProd])
    }
  }

  const handleInputChange = event => {
    setSelectedProduct(event.target.value)
  }

  const getProduct = async () => {
    setLoading(true)
    const data = {
      subCompanyId: subCompanyCNPJ,
      rfidCode: selectedProduct,
    }

    try {
      const response = await getProductCodService(token, nameUrl, data)
      const product = response.data

      const listProduct = {
        serial: product.serial,
        created: product.created,
        productName: product.productName,
        price: product.price,
        sku: product.sku,
        createDateRead: product.createDateRead,
        productId: product.productId,
        idProductPartner: product.idProductPartner,
        productItensId: product.productItensId,
      }

      setListProductEnv(prevListProductEnv => [...prevListProductEnv, listProduct])

      setProduct(product)
      setLoading(false)
      setSeverity('success')
      setTitle('Produto adicionado com sucesso!')
      setAlerta(true)
      setSelectedProduct('')
    } catch (err) {
      console.log(err)
      setLoading(false)
      setSeverity('error')
      setTitle('Erro ao adicionar produtos, verifique os dados e tente novamente.')
      setAlerta(true)
    }
  }

  const createExpeditionOrder = async e => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    const keysToKeep = ['idProductPartner', 'quantity', 'finishGood']
    const redux = array =>
      array.map(o =>
        keysToKeep.reduce((acc, curr) => {
          acc[curr] = o[curr]
          return acc
        }, {})
      )
    const finalList = redux(itensOrder)

    finalList.forEach(i => {
      i.subCompanyCNPJ = subCompanyCNPJ
      i.movimentationOrderNumberOtherSystem = movimentationOrderNumberOtherSystem
    })

    const expeditionOrder = {
      typeOrder: 4,
      observation: observation,
      subCompanyCNPJ,
      itensOrder: finalList,
      reasonfororderId: reasonId,
      movimentationOrderNumberOtherSystem,
    }

    console.log(expeditionOrder, 'respopo')

    try {
      //await createExpeditionOrderService(token, nameUrl, expeditionOrder)
      await createOrderFullService(token, nameUrl, expeditionOrder)
      setSeverity('success')
      setTitle('Expedição cadastrada com sucesso!')
      setExpanded(false)
      setAlerta(true)
      setLoading(false)
      setSubCompanyCNPJ('')
      setReasonId('')
      setMovimentationOrderNumberOtherSystem('')
      handleFetchData()
    } catch (err) {
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
      setAlerta(true)
      setLoading(false)
      console.log(err)
    }
  }

  const getReason = useCallback(async () => {
    try {
      const response = await getAllReasonService(token, nameUrl)
      setReason(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [reason])

  useEffect(() => {
    getReason()
  }, [])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao cadastrar o Pedido de Expedição!
          </Alert>
        </Snackbar>
        <Typography variant="h5" sx={{ mb: 5 }}>
          Criar Pedido de Expedição
        </Typography>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createExpeditionOrder}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">Filial Saída</InputLabel>
              <Select
                label="Filial Saída"
                value={subCompanyCNPJ}
                onChange={values => setSubCompanyCNPJ(values.target.value)}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">Razão do Pedido</InputLabel>
              <Select
                label="Filial Entrada"
                value={reasonId}
                onChange={values => setReasonId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {reason.map((item, index) => {
                  return (
                    <MenuItem value={item.reasonfororderId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={movimentationOrderNumberOtherSystem}
                onChange={values => setMovimentationOrderNumberOtherSystem(values.target.value)}
                id="outlined-basic"
                label="Ordem Outro Sistema"
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={observation}
                onChange={values => setObservation(values.target.value)}
                id="outlined-basic"
                label="Observação"
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={(insertionType === 'batch', showTable)}
                    onChange={e => {
                      setInsertionType(e.target.checked ? 'batch' : 'manual ')
                      setShowTable(e.target.checked)
                    }}
                  />
                }
                label="Inserção em manual"
              />{' '}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {insertionType === 'batch' ? (
                <Box position="relative">
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <TextField
                    label="Inserção manual"
                    sx={{ width: '100%' }}
                    value={selectedProduct}
                    onChange={handleInputChange}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        getProduct()
                      }
                    }}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={getProduct} onKe3>
                          <Search />
                        </IconButton>
                      ),
                    }}
                  />
                </Box>
              ) : (
                <Box>
                  <TextareaAutosize
                    aria-label="Inser;áo"
                    pad="true"
                    value={textareaValue}
                    style={{
                      width: '100%',
                      minHeight: '100px',
                      maxHeight: '200px',
                      padding: '10px',
                    }}
                    onChange={e => setTextareaValue(e.target.value)}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                sx={{ width: '100%' }}
                value={singleProd.quantity}
                id="outlined-basic"
                variant="outlined"
                type="text"
                label="Quantidade"
                onChange={e =>
                  setSingleProd(curr => ({
                    ...curr,
                    quantity: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={singleProd.finishGood}
                    onChange={e =>
                      setSingleProd(curr => ({
                        ...curr,
                        finishGood: e.target.checked,
                      }))
                    }
                  />
                }
                label="Produto Final"
              />
            </Grid>
            <Grid item xs={12} textAlign="end">
              <Button variant="outlined" onClick={addToList}>
                Adicionar à lista
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="center">Nome Produto</StyledTableCell>
                  <StyledTableCell align="center">ERP</StyledTableCell>
                  <StyledTableCell align="center">Quantidade</StyledTableCell>
                  <StyledTableCell align="center">Produto Final</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itensOrder.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setItensOrder(curr => curr.filter((v, i) => i !== index))}
                    >
                      <DeleteForeverIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                    <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.finishGood ? 'Sim' : 'Não'}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ExpeditionOrderCreate
