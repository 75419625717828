import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import React from 'react'

import DriveEtaIcon from '@mui/icons-material/DriveEta'
import PersonIcon from '@mui/icons-material/Person'
import ScaleIcon from '@mui/icons-material/Scale'
import { useNavigate } from 'react-router-dom'

const RouteCard = ({ totalKg, movRoute, setGeral, setMapScreen }) => {
  const navigate = useNavigate()

  return (
    <Card sx={{ width: 1 }}>
      <CardContent sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box
            sx={{
              height: 1,
              justifyItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              //gap: 1,
            }}
          >
            <Typography>
              <PersonIcon />
            </Typography>
            <Typography>
              <DriveEtaIcon />
            </Typography>
            <Typography>
              <ScaleIcon />
            </Typography>
            {/* <Typography>
            <ReceiptIcon />
          </Typography> */}
          </Box>
          <Box
            sx={{
              height: 1,
              justifyItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography>{movRoute.userNameDriver}</Typography>
            <Typography>
              {movRoute.carWeightName} ({movRoute.carWeightKGMax}kg-Máx)
            </Typography>
            <Typography>{totalKg}kg-Total</Typography>
            <Typography>Remessa - {movRoute.movimentationOrderRouteShipping}</Typography>
          </Box>
        </Box>
        <Button
          variant="text"
          onClick={() => {
            setGeral(true)
            setMapScreen({ latitude: false })
          }}
        >
          Visão Geral
        </Button>
        <Button variant="contained" onClick={() => navigate('/MovimentationRoute')}>
          Voltar
        </Button>
      </CardContent>
    </Card>
  )
}

export default RouteCard
