import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { createOrderFullService } from '../../../services/requestServices'
import { getAllReasonService } from '../../../services/admServices'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { searchProductService } from '../../../services/productService'
import { createPickingListService } from '../../../services/movOrderItemERP'
import { useDebounce } from '../../View/Product/Components/useDebounce'

const PickingListCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
  StyledTableCell,
  StyledTableRow,
}) => {
  const { subCompany, nameUrl, company } = useContext(Context)
  const { debounce } = useDebounce(1000, true)
  const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [reason, setReason] = useState([])
  const [reasonId, setReasonId] = useState('')
  const [observation, setObservation] = useState('')
  const [itensOrder, setItensOrder] = useState([])
  const token = localStorage.getItem('token')
  const [singleProd, setSingleProd] = useState({
    subCompanyCNPJ: '',
    movimentationOrderNumberOtherSystem: '',
    idProductPartner: '',
    quantity: '',
    finishGood: false,
  })

  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([{ label: '', value: '' }])
  const [search, setSearch] = useState('')
  const [error, setError] = useState(false)
  const [quantityFaction, setQuantityFaction] = useState(0)
  const [quantityTotal, setQuantityTotal] = useState(0)
  const vertical = 'top'
  const horizontal = 'center'
  const [searching, setSearching] = useState(false)
  const [row, setRow] = useState()
  const [selectedId, setSelectedId] = useState(undefined)

  useEffect(() => {
    if (company.companyId) {
      setSearching(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search, company])

  const handleGetAllProductsSearch = async () => {
    setSearching(true)
    const dataProduct = {
      companyId: company.companyId,
      SearchWord: search,
      limit: 10,
      offset: 0,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProducts(data)
        setSearching(false)
      })
    } catch (error) {
      console.error(error)
      setSearching(false)
    }
  }

  const addToList = () => {
    const testeNumber = parseInt(singleProd.quantity)
    if (isNaN(testeNumber)) {
      setSingleProd(curr => ({
        ...curr,
        quantity: 'Quantidade inválida',
      }))
      return
    }
    const repetido = itensOrder.find(x => x.idProductPartner === singleProd.idProductPartner)
    if (repetido) {
      return
    } else {
      const prod = singleProd
      prod.quantity = parseInt(singleProd.quantity)
      setItensOrder(curr => [...curr, prod])
    }
  }

  const createExpeditionOrder = async e => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    const keysToKeep = ['idProductPartner', 'quantity', 'finishGood']
    const redux = array =>
      array.map(o =>
        keysToKeep.reduce((acc, curr) => {
          acc[curr] = o[curr]
          return acc
        }, {})
      )
    const finalList = redux(itensOrder)
    finalList.forEach(i => {
      i.subCompanyCNPJ = subCompanyCNPJ
      i.movimentationOrderNumberOtherSystem = movimentationOrderNumberOtherSystem
    })

    const expeditionOrder = {
      movimentationOrderNumberOtherSystem,
      reasonfororderId: reasonId,
      typeOrder: 20,
      observation,
      quantityFaction,
      quantityTotal,
      subCompanyCNPJ,
      itensOrder: finalList,
    }

    try {
      //await createExpeditionOrderService(token, nameUrl, expeditionOrder)
      await createPickingListService(token, nameUrl, expeditionOrder)
      setSeverity('success')
      setTitle('PickingList cadastrada com sucesso!')
      setExpanded(false)
      setAlerta(true)
      setLoading(false)
      setSubCompanyCNPJ('')
      setReasonId('')
      setMovimentationOrderNumberOtherSystem('')
      handleFetchData()
    } catch (err) {
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
      setAlerta(true)
      setLoading(false)
      console.log(err)
    }
  }

  const getReason = useCallback(async () => {
    try {
      const response = await getAllReasonService(token, nameUrl)
      setReason(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [reason])

  useEffect(() => {
    getReason()
  }, [])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao cadastrar o Pedido de Expedição!
          </Alert>
        </Snackbar>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createExpeditionOrder}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 1 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Filial"
                value={subCompanyCNPJ}
                onChange={values => setSubCompanyCNPJ(values.target.value)}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.cnpj} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={movimentationOrderNumberOtherSystem}
                onChange={values => setMovimentationOrderNumberOtherSystem(values.target.value)}
                id="outlined-basic"
                label="Ordem Outro Sistema"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box
              sx={{ gap: 2 }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <InputLabel id="demo-simple-select-label">Razão do Pedido</InputLabel>
              <Select
                label="Filial Entrada"
                value={reasonId}
                onChange={values => setReasonId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {reason?.map((item, index) => {
                  return (
                    <MenuItem value={item.reasonfororderId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box
              sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={observation}
                onChange={values => setObservation(values.target.value)}
                id="outlined-basic"
                label="Observação"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 1, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={quantityFaction}
                onChange={values => setQuantityFaction(values.target.value)}
                id="outlined-basic"
                label="Quantidade Facção"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={quantityTotal}
                onChange={values => setQuantityTotal(values.target.value)}
                id="outlined-basic"
                label="Quantidade Total"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: 1,
              mt: 2,
              display: 'flex',
              gap: 2,
              border: '1px solid black',
              borderRadius: 2,
              p: 2,
              flexDirection: 'column',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    openText="Abrir"
                    closeText="Fechar"
                    clearText="Apagar"
                    noOptionsText={searching ? 'Carregando...' : 'Nenhum resultado encontrado'}
                    loadingText="Carregando..."
                    disablePortal
                    value={row}
                    loading={searching}
                    onInputChange={(_, newValue) => setSearch(newValue)}
                    popupIcon={
                      searching ? <CircularProgress color="warning" size={20} /> : undefined
                    }
                    onChange={(_, newValue) => {
                      if (newValue === null) {
                        setSelectedId(null)
                        setRow(null)
                        setSearch('')
                        handleGetAllProductsSearch()
                      } else {
                        setSelectedId(newValue?.productId)
                        setRow(newValue)
                        setSearch(newValue.referencia)
                      }
                    }}
                    options={products}
                    getOptionLabel={option => `${option.referencia} - ${option.name}`}
                    renderInput={params => <TextField {...params} label="Pesquisar produtos" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: '100%' }}
                  value={singleProd.quantity}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Quantidade"
                  onChange={e =>
                    setSingleProd(curr => ({
                      ...curr,
                      quantity: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={singleProd.finishGood}
                        onChange={e =>
                          setSingleProd(curr => ({
                            ...curr,
                            finishGood: e.target.checked,
                          }))
                        }
                      />
                    }
                    label="Produto Final"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant="outlined" onClick={addToList}>
                  Adicionar à lista
                </Button>
              </Grid>
            </Grid>
          </Box>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="center">Quantidade</StyledTableCell>
                  <StyledTableCell align="center">Produto Final</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itensOrder.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setItensOrder(curr => curr.filter((v, i) => i !== index))}
                    >
                      <DeleteForeverIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.finishGood ? 'Sim' : 'Não'}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PickingListCreate
