import React, { useContext, useRef } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setPermission } from '../../../permissions/userPermissions'
import { PRODUCAO } from '../../../const/RoleConstants'
import { Context } from '../../../context/AuthContext'
import {
  getProductAll,
  getSingleProductService,
  searchProductService,
} from '../../../services/productService'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { colorTableDefault } from '../../TemplateDefault'
import { getProcess } from '../../../services/processServices'
import { AiFillPlusCircle } from 'react-icons/ai'
import { BiArrowBack } from 'react-icons/bi'
import { createOrderProcessFullService } from '../../../services/movBreakServices'
import { orderReceiveService } from '../../../services/movOrderItemERP'
import { useDebounce } from '../../View/Product/Components/useDebounce'
import PermissionComponent from '../../../permissions'

const MovimentationBreakCreate = () => {
  const { userRoles, company, nameUrl, subCompany } = useContext(Context)
  const { debounce } = useDebounce(1000, true)

  const [permissionIntegral, setPermissionIntegral] = useState(false)
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [processId, setProcessId] = useState('')
  const [processList, setProcessList] = useState([])
  const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [observation, setObservation] = useState('')
  const [produtos, setProdutos] = useState([])
  const [search, setSearch] = useState('')
  const [quantity, setQuantity] = useState('')
  const [quantityTotal, setQuantityTotal] = useState()
  const [lote, setLote] = useState('')
  const [type, setType] = useState('')
  const [datetimeExpiration, setDateTimeExpiration] = useState('')
  const [numberPallet, setNumberPallet] = useState('')
  const [picking, setPicking] = useState('')
  const [subCompanySelect, setSubCompanySelect] = useState('')
  const [quantityList, setQuantityList] = useState('')
  const [loadingProds, setLoadingProds] = useState(true)
  const [searching, setSearching] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [error, setError] = useState(false)
  const [title, setTitle] = useState()
  const [singleProd, setSingleProd] = useState({
    name: '',
    referencia: '',
    sku: '',
    quantity: '',
    finishGood: false,
    idProductPartner: '',
    picking: '',
  })
  const [itensOrder, setItensOrder] = useState([])
  const [idProd, setIdProd] = useState()
  const [prodSelected, setProdSelected] = useState()
  const [row, setRow] = useState()
  const [selectedId, setSelectedId] = useState(undefined)

  const navigate = useNavigate()
  const inputChangeRef = useRef(false)

  useEffect(() => {
    const currentDate = new Date()
    const formattedDate = formatDate(currentDate)
    setDateTimeExpiration(formattedDate)
  }, [])

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  useEffect(() => {
    if (company.companyId && !inputChangeRef.current) {
      setSearching(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search])

  const handleGetAllProductsSearch = async () => {
    const dataProduct = {
      companyId: subCompany[0].companyId,
      SearchWord: search,
      limit: 10,
      offset: 0,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProdutos(data)
        setSearching(false)
      })
    } catch (error) {
      console.error(error)
      setSearching(false)
    }
  }

  useEffect(() => {
    if (row) {
      setSingleProd({
        idProductPartner: row.idProductPartner,
        name: row.name,
        quantity: row.quantity,
        referencia: row.referencia,
        sku: row.sku,
        picking: row.picking,
      })
    }
  }, [row])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const changeProd = async id => {
    //const prod = produtos.find(x => x.productId === id)
    if (id) {
      setIdProd(id)
    }
    const prod = await getSingleProductService(token, nameUrl, { productId: id ? id : idProd })
    const finalProd = { ...prod.data }
    finalProd.finishGood = true
    setSingleProd(finalProd)
  }

  const getPermissions = useCallback(async () => {
    setPermissionIntegral(setPermission(userRoles, 'Create_LabelOrder_Integral'))

    console.log(
      setPermission(userRoles, 'Create_LabelOrder_Integral'),
      'Create_LabelOrder_Integral',
      'permission'
    )
  }, [])

  useEffect(() => {
    getPermissions()
    getProcessList()
    //getProducts()
  }, [])

  const addToList = () => {
    if (permissionIntegral) {
      if (singleProd && lote && quantityList && numberPallet) {
        const prod = {
          ...singleProd,
          quantity: parseInt(quantityList),
          lote,
          dataExpiration: datetimeExpiration,
          numberPallet,
        }

        setItensOrder(curr => [...curr, prod])
      } else {
        setTitle('Preencha todos os dados.')
        setError(true)
      }
    } else {
      if (type === '2') {
        if (singleProd && lote && quantityList) {
          const prod = {
            ...singleProd,
            quantity: parseInt(quantityList),
            lote,
            dataExpiration: datetimeExpiration,
            numberPallet,
          }

          setItensOrder(curr => [...curr, prod])
        } else {
          setTitle('Preencha todos os dados.')
          setError(true)
        }
      } else if (type === '1') {
        if (singleProd && lote && quantityList) {
          const prod = {
            ...singleProd,
            quantity: 1,
            lote,
            dataExpiration: datetimeExpiration,
            numberPallet,
          }

          const itemsToAdd = Array(parseInt(quantityList)).fill(prod)

          setItensOrder(curr => [...curr, ...itemsToAdd])
        } else {
          setTitle('Preencha todos os dados.')
          setError(true)
        }
      }
    }
  }

  const handleClose = () => {
    setError(false)
  }

  const getProcessList = useCallback(async () => {
    try {
      const data = {
        subCompanyId: subCompany[0].subCompanyId,
        limit: 1000,
        offset: 0,
        order: true,
      }
      const response = await getProcess(token, nameUrl, data)
      setProcessList(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const createOrderReceive = async () => {
    if (!movimentationOrderNumberOtherSystem || movimentationOrderNumberOtherSystem.trim() === '') {
      setTitle('O número da ordem de movimentação de outro sistema é obrigatório.')
      setError(true)
      return
    }

    if (!subCompanySelect || subCompanySelect.trim() === '') {
      setTitle('A Filial é obrigatória.')
      setError(true)
      return
    }

    if (!itensOrder || itensOrder.length === 0) {
      setTitle('A ordem deve conter ao menos um item.')
      setError(true)
      return
    }

    setLoading(true)

    // Ordena os itensOrder por idProductPartner
    const sortedItems = [...itensOrder].sort((a, b) => {
      if (a.idProductPartner < b.idProductPartner) return -1
      if (a.idProductPartner > b.idProductPartner) return 1
      return 0
    })

    const keysToKeep = ['idProductPartner', 'quantity', 'finishGood', 'dataExpiration']
    const redux = array =>
      array.map(o =>
        keysToKeep.reduce((acc, curr) => {
          acc[curr] = o[curr]
          return acc
        }, {})
      )

    const finalList = []
    let count = 0

    // Itera sobre os itens ordenados
    for (let x = 0; x < sortedItems.length; x++) {
      count++
      const newData = {
        idProductPartner: sortedItems[x].idProductPartner,
        quantity: sortedItems[x].quantity,
        finishGood: sortedItems[x].finishGood,
        dataExpiration: permissionIntegral
          ? `${sortedItems[x].dataExpiration}-28`
          : `${sortedItems[x].dataExpiration}`,
        subCompanyCNPJ: subCompanyCNPJ,
        movimentationOrderNumberOtherSystem: movimentationOrderNumberOtherSystem,
        lote: sortedItems[x].lote,
        datetimeExpiration: sortedItems[x].datetimeExpiration,
        numberPallet: sortedItems[x].numberPallet,
        sequence: count,
      }
      finalList.push(newData)
    }

    const data = {
      movimentationOrderNumberOtherSystem,
      observation: observation || '-',
      quantity: parseInt(quantity) || 0,
      subCompanyId: subCompanySelect,
      itensOrder: finalList,
    }

    await orderReceiveService(token, nameUrl, data)
      .then(response => {
        navigate('/labelOrder')
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setTitle(error.response.data.message)
        setError(true)
      })
  }

  const handleSelectFilial = e => {
    setSubCompanySelect(e.target.value)

    const selectCnpj = subCompany.filter(sub => sub.subCompanyId === e.target.value)
    setSubCompanyCNPJ(selectCnpj[0].cnpj)
  }

  return (
    <>
      <Box sx={{ mt: 7.5, display: 'flex', flexDirection: 'column' }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          //key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Cadastrar Impressão de Etiqueta</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2 }}>
          {/* <FormControl sx={{ width: 0.5 }}>
            <TextField
              sx={{ width: '100%' }}
              value={quantity}
              onChange={values => setQuantity(values.target.value)}
              id="outlined-basic"
              label="Quantidade"
              variant="outlined"
              type="text"
            />
          </FormControl> */}
          <FormControl sx={{ width: 0.5 }}>
            <InputLabel id="filial">Filial</InputLabel>
            <Select
              label="Filial"
              name="filial"
              onChange={handleSelectFilial}
              value={subCompanySelect}
            >
              {subCompany.map((item, index) => {
                return (
                  <MenuItem value={item.subCompanyId} key={index}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 0.5 }}>
            <TextField
              sx={{ width: '100%' }}
              value={movimentationOrderNumberOtherSystem}
              onChange={values => setMovimentationOrderNumberOtherSystem(values.target.value)}
              id="outlined-basic"
              label="Número Order (outro sistema)"
              variant="outlined"
              type="text"
            />
          </FormControl>
        </Box>
        <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2 }}>
          <Box sx={{ width: 0.5, gap: 2, display: 'flex' }}>
            <FormControl sx={{ width: 1 }}>
              <TextField
                sx={{ width: '100%' }}
                value={observation}
                onChange={values => setObservation(values.target.value)}
                id="outlined-basic"
                label="Observação"
                variant="outlined"
                type="text"
              />
            </FormControl>
          </Box>
          <FormControl sx={{ width: 0.5 }}>
            <Autocomplete
              openText="Abrir"
              closeText="Fechar"
              clearText="Apagar"
              noOptionsText={searching ? 'Carregando...' : 'Nenhum resultado encontrado'}
              loadingText="Carregando..."
              disablePortal
              value={row}
              onInputChange={(_, newValue) => {
                if (!inputChangeRef.current) {
                  setSearch(newValue)
                }
                inputChangeRef.current = false
              }}
              //disabled={searching}
              popupIcon={searching ? <CircularProgress color="warning" size={20} /> : undefined}
              onChange={(_, newValue) => {
                inputChangeRef.current = true
                if (newValue === null) {
                  setSelectedId(null)
                  setRow(null)
                } else {
                  setSelectedId(newValue?.productId)
                  setRow(newValue)
                }
              }}
              options={produtos}
              getOptionLabel={option => `${option.referencia} - ${option.name}`}
              renderInput={params => <TextField {...params} label="Pesquisar produtos" />}
            />
          </FormControl>
        </Box>
        {/* <Divider sx={{ width: 1, mt: 2 }} /> */}
        <Card variant="outlined" sx={{ p: 2, mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  fullWidth
                  value={singleProd.name}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Nome Produto"
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={singleProd.referencia}
                  id="outlined-basic"
                  disabled
                  variant="outlined"
                  type="text"
                  label="Referência"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              {' '}
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={singleProd.sku}
                  id="outlined-basic"
                  variant="outlined"
                  label="SKU"
                  type="text"
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              {' '}
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={lote}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Lote"
                  onChange={e => setLote(e.target.value)}
                />
              </FormControl>
            </Grid>
            {!permissionIntegral && (
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    fullWidth
                    label="Tipo"
                    name="tipo"
                    onChange={e => setType(e.target.value)}
                    value={type}
                  >
                    <MenuItem value="1">Unidade</MenuItem> <MenuItem value="2">Pacote</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={quantityList}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Quantidade"
                  onChange={e => setQuantityList(e.target.value)}
                />
              </FormControl>
            </Grid>
            <PermissionComponent role="Create_LabelOrder_Integral">
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={datetimeExpiration}
                    id="outlined-basic"
                    variant="outlined"
                    type="month"
                    label="Data de validade"
                    onChange={e => setDateTimeExpiration(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </PermissionComponent>

            <PermissionComponent role="Create_LabelOrder_Integral">
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={numberPallet}
                    id="outlined-basic"
                    variant="outlined"
                    label="Número de palete"
                    type="text"
                    onChange={e => {
                      setNumberPallet(e.target.value)
                    }}
                  />
                </FormControl>
              </Grid>
            </PermissionComponent>

            <Grid item xs={12}>
              <Button variant="outlined" onClick={addToList}>
                Adicionar à lista
              </Button>
            </Grid>
          </Grid>
        </Card>

        {itensOrder && (
          <Box sx={{ mt: '2rem', display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6">
              {itensOrder.length} {itensOrder.length === 1 ? 'etiqueta' : 'etiquetas'} para
              cadastrar
            </Typography>
          </Box>
        )}
        <TableContainer component={Paper} sx={{ mt: 1 }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell> </StyledTableCell>
                <StyledTableCell align="center">Identificação</StyledTableCell>
                <StyledTableCell align="center">Nome Produto</StyledTableCell>
                <StyledTableCell align="center">Referência</StyledTableCell>
                <StyledTableCell align="center">SKU</StyledTableCell>
                <StyledTableCell align="center">Lote</StyledTableCell>
                <StyledTableCell align="center">Quantidade</StyledTableCell>
                <PermissionComponent role="Create_LabelOrder_Integral">
                  <StyledTableCell align="center">Data Expiração</StyledTableCell>
                  <StyledTableCell align="center">Número Palete</StyledTableCell>
                </PermissionComponent>

                <StyledTableCell align="center">Picking</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...itensOrder]
                .sort((a, b) => {
                  if (a.idProductPartner < b.idProductPartner) return -1
                  if (a.idProductPartner > b.idProductPartner) return 1
                  return 0
                })
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setItensOrder(itensOrder.filter((v, i) => i !== index))}
                    >
                      <DeleteForeverIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">{row.referencia}</StyledTableCell>
                    <StyledTableCell align="center">{row.sku}</StyledTableCell>
                    <StyledTableCell align="center">{row.lote}</StyledTableCell>
                    <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                    <PermissionComponent role="Create_LabelOrder_Integral">
                      <StyledTableCell align="center">
                        {typeof row.dataExpiration === 'string'
                          ? `${row.dataExpiration.slice(-2)}/${row.dataExpiration.slice(0, 4)}`
                          : ''}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.numberPallet}</StyledTableCell>
                    </PermissionComponent>
                    <StyledTableCell align="center">{row.picking}</StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ gap: 2, display: 'flex', mt: 2 }}>
          <Button
            variant="contained"
            color="success"
            startIcon={<AiFillPlusCircle />}
            onClick={createOrderReceive}
          >
            Salvar
          </Button>
          <Button
            color="error"
            variant="contained"
            startIcon={<BiArrowBack />}
            onClick={() => navigate('/labelOrder')}
          >
            Voltar
          </Button>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default MovimentationBreakCreate
