import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { Box, CircularProgress, Paper } from '@mui/material'
import randomColor from 'randomcolor'

const CardDashboard = ({
  subCompanyId,
  startDate,
  finishDate,
  handleGetDashDash,
  title,
  loading,
  Icon,
  stockValue,
}) => {
  const [error, setError] = useState(false)
  const [dataCard, setDataCard] = useState([])
  const randomColorValue = randomColor()

  useEffect(() => {
    handle()
  }, [subCompanyId, startDate, finishDate])

  const handle = async () => {
    await handleGetDashDash()
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        width: '100%',
        position: 'relative',
        p: 2,
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        minHeight: '120px',
        height: loading ? 1 : 1,
      }}
    >
      {loading ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '30%',
            left: '48%',
          }}
          color="primary"
        />
      ) : error ? (
        'Deu erro'
      ) : (
        <>
          <Box
            sx={{
              width: '100%',
              padding: '0 2.5em 0 2.5em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="overline">{title}</Typography>
              <Typography variant="h4">{stockValue ? stockValue : '0'}</Typography>
              {/*<Typography variant="h5">{title}</Typography>*/}
              {/*<Typography variant="h6">{stockValue ? stockValue : ''}</Typography>*/}
            </Box>
            <Icon sx={{ fontSize: '4em', color: randomColorValue }} />
          </Box>
        </>
      )}
    </Paper>
  )
}

export default CardDashboard
