import React, { useContext, useCallback, useEffect, useState } from 'react'

import { getRolesCategoryService, getSegmentService } from '../../../services/admServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  styled,
  Grid,
  Collapse,
  Typography,
  IconButton,
} from '@mui/material'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { colorTableDefault } from '../../TemplateDefault'
import { Context } from '../../../context/AuthContext'
import { createProcess } from '../../../services/processServices'
import { createSegmentGroup } from '../../../services/permission'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const SegmentGroupCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [segment, setSegment] = useState([])
  const [roleCategory, setRoleCategory] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [roleCategoryId, setRoleCategoryId] = useState([])
  const [segmentId, setSegmentId] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [statusApp, setStatusApp] = useState('Pronto')
  const [permissionArray, setPermissionArray] = useState([])
  const [errors, setErrors] = useState({
    segment: false,
    name: false,
    description: false,
  })

  const errorMessages = {
    segment: 'Por favor, selecione um segmento.',
    name: 'Por favor, digite o nome do grupo.',
    description: 'Por favor, digite uma descrição.',
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleItemDelete = roleCategoryId => {
    const updatedPermissionArray = permissionArray.filter(
      item => item.roleCategoryId !== roleCategoryId
    )
    setPermissionArray(updatedPermissionArray)
  }

  useEffect(() => {}, [permissionArray])

  const addToList = listUser => {
    const categoryRole = roleCategory.filter(item => item.roleCategoryId === roleCategoryId)

    setPermissionArray(prevArray => [...prevArray, categoryRole[0]])

    setStatusApp('Adicionado')
  }

  const handleDelete = row => {
    const ruleId = row.roleId
  }

  const validateFields = () => {
    let isValid = true
    const newErrors = {
      segment: !segmentId,
      name: !name,
      description: !description,
    }

    setErrors(newErrors)

    if (newErrors.segment || newErrors.name || newErrors.description) {
      isValid = false
    }

    return isValid
  }

  const getSegment = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getSegmentService(token, nameUrl, 10000, 0, false)

      setSegment(response.data.result)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const getRolesCategory = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getRolesCategoryService(token, nameUrl, 10000, 0, false)
      setRoleCategory(response.data.result)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getSegment()
    getRolesCategory()
  }, [permissionArray])

  const handleSaveSegmentGroup = async () => {
    setLoading(true)
    if (!validateFields()) {
      return
    }
    const data = {
      name: name,
      description: description,
      segmentId: segmentId,
      roleCategoryId: permissionArray.map(item => item.roleCategoryId),
    }

    try {
      const response = await createSegmentGroup(token, nameUrl, data)
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSeverity('success')
      setTitle(response.data.message)
      handleFetchData(segmentId)
    } catch (error) {
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao criar, verifique os dados e tente novamente.')
    }
  }

  function Row(props) {
    const { row, onDelete } = props
    const [open, setOpen] = React.useState(false)

    return (
      <React.Fragment key={row.roleCategoryId}>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {row.name}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {row.description}
          </StyledTableCell>
          <StyledTableCell align="right">
            <IconButton
              aria-label="delete item"
              size="small"
              onClick={() => onDelete(row.roleCategoryId)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Permissao</StyledTableCell>
                      <StyledTableCell>Descrição</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {row.permissions.map((role, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {role.name}
                        </StyledTableCell>
                        <StyledTableCell>{role.description}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }}>
          <Box component="form" sx={{ mt: '2rem', width: 1 }} onSubmit={createProcess}>
            <Box sx={{ display: { md: 'flex' }, gap: 2, alignItems: 'center' }}>
              <Box sx={{ width: 1 }}>
                <InputLabel id="demo-simple-select-label">Segmento</InputLabel>
                <Select
                  value={segmentId}
                  sx={{ width: '100%' }}
                  onChange={values => setSegmentId(values.target.value)}
                  error={errors.segment}
                >
                  {segment.map((item, index) => {
                    return (
                      <MenuItem value={item.segmentId} key={item.segmentId}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                {errors.segment && (
                  <Typography variant="caption" color="error">
                    {errorMessages.segment}
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: 1 }}>
                <InputLabel id="demo-simple-select-label">Nome do Grupo</InputLabel>
                <TextField
                  sx={{ width: '100%' }}
                  value={name}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  onChange={e => setName(e.target.value)}
                  error={errors.name}
                />
                {errors.name && (
                  <Typography variant="caption" color="error">
                    {errorMessages.name}
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: 1 }}>
                <InputLabel id="demo-simple-select-label">Descrição</InputLabel>
                <TextField
                  sx={{ width: '100%' }}
                  value={description}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  onChange={e => setDescription(e.target.value)}
                  error={errors.description}
                />
                {errors.description && (
                  <Typography variant="caption" color="error">
                    {errorMessages.description}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                width: 1,
                mt: 2,
                display: 'flex',
                gap: 2,
                border: '1px solid black',
                borderRadius: 2,
                p: 2,
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel id="demo-simple-select-label">Grupo de Permissoes</InputLabel>
                    <Select
                      value={roleCategoryId}
                      sx={{ width: '100%' }}
                      onChange={values => {
                        setRoleCategoryId(values.target.value)
                      }}
                    >
                      {roleCategory.map((item, index) => {
                        return (
                          <MenuItem value={item.roleCategoryId} key={item.roleCategoryId}>
                            {item.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <TextField
                      sx={{ width: '100%' }}
                      value={statusApp}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: 1, display: 'flex', gap: 2 }}>
                <Button variant="outlined" onClick={() => addToList()}>
                  Adicionar Permissão
                </Button>
              </Box>
            </Box>

            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center"></StyledTableCell>
                    <StyledTableCell align="left">Nome do Grupo de regra</StyledTableCell>
                    <StyledTableCell align="left">Descrição</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {permissionArray.map((row, index) => (
                    <Row row={row} onDelete={handleItemDelete} key={index} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
              <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                <Button variant="contained" sx={{ width: '100%' }} onClick={handleSaveSegmentGroup}>
                  Salvar
                </Button>
              </Box>
              <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => setExpanded(false)}
                  sx={{ width: '100%', ml: 1 }}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default SegmentGroupCreate
