import React, { Component, useContext, useState } from 'react'
import { useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { deleteSegmentService } from '../../../services/admServices'

import { Alert, Backdrop, Button, CircularProgress, Snackbar, Typography, Box } from '@mui/material'
import { deleteZplReferenceService } from '../../../services/printerZPLDefaultServices'
import { Context } from '../../../context/AuthContext'

const ZPLRefDelete = ({ match }) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const { id } = useParams()
  const navigate = useNavigate()
  const vertical = 'top'
  const horizontal = 'center'

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const deleteZPLRef = useCallback(async e => {
    e.preventDefault()
    setLoading(true)

    try {
      const response = await deleteZplReferenceService(token, nameUrl, id)
      console.log(response)
      navigate('/ZPLRef')
    } catch (err) {
      console.log(err)
      setLoading(false)
      setError(true)
    }
  }, [])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '60vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Erro no sistema, tente novamente mais tarde
          </Alert>
        </Snackbar>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography variant="h5">Deseja realmente excluir essa Impressão?</Typography>
          <Box
            sx={{
              mt: '2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <Button variant="contained" onClick={deleteZPLRef}>
              Excluir
            </Button>
            <Button variant="contained" color="error" onClick={() => navigate('/ZPLRef')}>
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ZPLRefDelete
