import { useCallback, useContext, useState, useEffect } from 'react'
import { Context } from '../../../context/AuthContext'
import { getAllReasonService } from '../../../services/admServices'
import {
  addProductOrderList,
  createMovimentationOrderService,
  getAllProductCodService,
  getProductAssociationService,
  getProductCodService,
} from '../../../services/productService'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Typography,
} from '@mui/material'
import { Search } from '@mui/icons-material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import { colorTableDefault } from '../../TemplateDefault'

const TransferOrderCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
}) => {
  const { subCompany, nameUrl, company } = useContext(Context)
  const [subCompanyIdFrom, setSubCompanyIdFrom] = useState('')
  const [subCompanyCnpj, setSubCompanyCnpj] = useState('')
  const [subCompanyFrom, setSubCompanyFrom] = useState(subCompany)
  const [subCompanyIdTo, setSubCompanyIdTo] = useState('')
  const [subCompanyTo, setSubCompanyTo] = useState(subCompany)
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [reasonForOrder, setReasonForOrder] = useState([])
  const [reasonForOrderId, setReasonForOrderId] = useState('')
  const [movimentationOrderId, setMovimentationOrderId] = useState('')
  const [product, setProduct] = useState([])
  const [productList, setProductList] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [finalizado, setFinalizado] = useState(false)

  const token = localStorage.getItem('token')
  const vertical = 'top'
  const horizontal = 'center'

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [insertionType, setInsertionType] = useState('manual')
  const [selectedProduct, setSelectedProduct] = useState('')
  const [codProduct, setCodProduct] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)

  const [showTable, setShowTable] = useState(false)
  const [textareaValue, setTextareaValue] = useState([])
  const [listProductEnv, setListProductEnv] = useState([])
  const [saveListProductEnv, setSaveListProductEnv] = useState([])
  const [deletedItems, setDeletedItems] = useState([])

  const [filteredProducts, setFilteredProducts] = useState(listProductEnv)
  const [search, setSearch] = useState('')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const searchTable = () => {
    if (search.trim() === '') {
      setFilteredProducts(listProductEnv)
    } else {
      const filtered = listProductEnv.filter(product =>
        product.productName.toLowerCase().includes(search.toLowerCase())
      )
      setFilteredProducts(filtered)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  useEffect(() => {
    searchTable()
  }, [
    listProductEnv,
    page,
    rowsPerPage,
    search,
    subCompanyIdFrom,
    subCompanyIdTo,
    movimentationOrderNumberOtherSystem,
    reasonForOrderId,
  ])

  const handleInputChange = event => {
    setSelectedProduct(event.target.value)
  }

  const getProduct = async () => {
    setLoading(true)
    const data = {
      subCompanyId: subCompanyIdFrom,
      rfidCode: selectedProduct,
    }

    try {
      const response = await getProductCodService(token, nameUrl, data)
      const dataProduct = response.data
      const product = {
        serial: dataProduct.serial,
        created: dataProduct.created,
        productName: dataProduct.productName,
        price: dataProduct.price,
        sku: dataProduct.sku,
        createDateRead: dataProduct.createDateRead,
        productId: dataProduct.productId,
        idProductPartner: dataProduct.idProductPartner,
        productItensId: dataProduct.productItensId,
      }

      const isDuplicate = listProductEnv.some(
        existingItem => existingItem.serial === product.serial
      )

      if (isDuplicate) {
        setLoading(false)
        setSeverity('error')
        setTitle(`O produto com serial ${product.serial} já está na lista.`)
        setAlerta(true)
      } else {
        setListProductEnv(prevListProductEnv => [...prevListProductEnv, product])

        setLoading(false)
        setSeverity('success')
        setTitle('Produto adicionado com sucesso!')
        setAlerta(true)
        setSelectedProduct('')
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
      setSeverity('error')
      setTitle('Erro ao adicionar produtos, verifique os dados e tente novamente.')
      setAlerta(true)
    }
  }

  const getProductList = async () => {
    setLoading(true)
    const data = {
      subCompanyId: company.companyId,
      rfidCode: textareaValue
        .split(',')
        .map(item => item.trim())
        .filter(item => item.length > 0),
    }

    try {
      const response = await getAllProductCodService(token, nameUrl, data)
      const dataProduct = response.data

      const product = dataProduct.map(prod => ({
        serial: prod.serial,
        created: prod.created,
        productName: prod.productName,
        price: prod.price,
        sku: prod.sku,
        createDateRead: prod.createDateRead,
        productId: prod.productId,
        idProductPartner: prod.idProductPartner,
        productItensId: prod.productItensId,
      }))

      const duplicates = product.filter(newItem =>
        listProductEnv.some(existingItem => existingItem.serial === newItem.serial)
      )

      if (duplicates.length > 0) {
        setLoading(false)
        setSeverity('error')
        setTitle(
          `Os seguintes produtos já estão na lista: ${duplicates
            .map(item => item.serial)
            .join(', ')}`
        )
        setAlerta(true)
      } else {
        setListProductEnv(prevListProductEnv => [...prevListProductEnv, ...product])

        setLoading(false)
        setSeverity('success')
        setTitle('Produto adicionado com sucesso!')
        setAlerta(true)
        setShowTable(true)
        setSelectedProduct('')
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
      setSeverity('error')
      setTitle('Erro ao adicionar produtos, verifique os dados e tente novamente.')
      setAlerta(true)
    }
  }

  const deleteItemTableOne = async productItensId => {
    const updatedList = listProductEnv.filter(item => item.productItensId !== productItensId)
    const deletedItem = listProductEnv.find(item => item.productItensId === productItensId)

    setListProductEnv(updatedList)
    setDeletedItems(prevDeletedItems => [...prevDeletedItems, deletedItem])
  }

  const processTextareaData = () => {
    const lines = textareaValue.trim().split('\n')
    const products = lines.map(line => {
      const [serial, created, productName, price, sku] = line.split(',')

      return {
        serial: serial.trim(),
        created: created.trim(),
        productName: productName.trim(),
        price: price.trim(),
        sku: sku.trim(),
      }
    })
    return products
  }

  const createTransferOrder = async () => {
    setLoading(true)
    setError(false)

    const transferOrder = {
      reasonfororderId: reasonForOrderId,
      typeOrder: 5,
      movimentationOrderNumberOtherSystem: movimentationOrderNumberOtherSystem,
      subCompanyIdFrom: subCompanyIdFrom,
      subCompanyIdTo: subCompanyIdTo,
    }

    try {
      if (listProductEnv.length > 0) {
        const response = await createMovimentationOrderService(token, nameUrl, transferOrder)

        const newListProduct = listProductEnv.map(item => ({
          idProductPartner: item.idProductPartner,
          serial: item.serial,
          createDate: item.createDate,
          productName: item.productName,
          price: item.price,
          sku: item.sku,
          movimentationOrderId: response.data.movimentationOrderId,
          productItensId: item.productItensId,
        }))

        await addProductOrderList(token, nameUrl, newListProduct)
        setListProductEnv([])
      } else {
        await createMovimentationOrderService(token, nameUrl, transferOrder)
        setListProductEnv([])
        setLoading(false)
      }

      setLoading(false)
      setSeverity('success')
      setTitle('Ordem criada com sucesso!')
      setExpanded(false)
      setAlerta(true)
      handleFetchData()
    } catch (err) {
      setLoading(false)
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
      setAlerta(true)
    }
    setLoading(false)
  }

  const getReason = useCallback(async () => {
    try {
      const response = await getAllReasonService(token, nameUrl)
      setReasonForOrder(response.data.result)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }, [])

  const valuesFrom = useCallback(
    selectedValue => {
      setSubCompanyIdFrom(selectedValue)

      setSubCompanyTo(subCompany.filter(item => item.subCompanyId !== selectedValue))
    },
    [subCompanyIdFrom, subCompanyTo]
  )

  const valuesTo = useCallback(
    selectedValue => {
      setSubCompanyIdTo(selectedValue)

      setSubCompanyFrom(subCompany.filter(item => item.subCompanyId !== selectedValue))
    },
    [subCompanyIdTo, subCompanyFrom]
  )

  const handleClose = () => {
    setError(false)
  }

  const handleTextFieldChange = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setTextareaValue(prevValue => prevValue + ',')
    } else {
      setTextareaValue(e.target.value.toUpperCase())
    }
  }

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao cadastrar a Transferência!
          </Alert>
        </Snackbar>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">Filial Saída</InputLabel>
              <Select
                label="Filial Saída"
                value={subCompanyIdFrom}
                onChange={values => {
                  const subCompanyCnpj = subCompany.find(
                    item => item.subCompanyId === values.target.value
                  )
                  setSubCompanyCnpj(subCompanyCnpj.cnpj)
                  console.log(subCompanyCnpj, 'subCompanyCnpj')
                  valuesFrom(values.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {subCompanyFrom.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">Filial Entrada</InputLabel>
              <Select
                label="Filial Entrada"
                value={subCompanyIdTo}
                onChange={values => {
                  valuesTo(values.target.value)
                  getReason()
                }}
                sx={{ width: '100%' }}
              >
                {subCompanyTo.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">Razão da Transferência</InputLabel>
              <Select
                label="Razão da Transferência"
                value={reasonForOrderId}
                disabled={!subCompanyIdTo}
                onChange={values => setReasonForOrderId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {reasonForOrder.map((item, index) => {
                  return (
                    <MenuItem value={item.reasonfororderId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel id="demo-simple-select-label">Ordem Outro Sistema</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={movimentationOrderNumberOtherSystem}
                onChange={values => setMovimentationOrderNumberOtherSystem(values.target.value)}
                id="standard-basic"
                disabled={!reasonForOrderId}
                variant="outlined"
                fullWidth
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!reasonForOrderId}
                    checked={insertionType === 'batch'}
                    onChange={e => {
                      setInsertionType(e.target.checked ? 'batch' : 'manual ')
                    }}
                  />
                }
                label="Inserção em manual"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {insertionType === 'batch' ? (
                <Box position="relative">
                  <InputLabel id="demo-simple-select-label">Inserção manual</InputLabel>
                  <TextField
                    sx={{ width: '100%' }}
                    value={selectedProduct}
                    onChange={handleInputChange}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        getProduct()
                      }
                    }}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={getProduct} onKe3>
                          <Search />
                        </IconButton>
                      ),
                    }}
                  />
                </Box>
              ) : (
                <Box>
                  <InputLabel id="demo-simple-select-label">Inserção em lotes</InputLabel>
                  <TextField
                    sx={{ width: '100%' }}
                    id="outlined-multiline-static"
                    multiline
                    value={textareaValue}
                    onChange={handleTextFieldChange}
                    onKeyDown={handleTextFieldChange}
                    rows={10}
                    helperText="Ao adicionar manualmente, use 'Enter' para avançar para o próximo código. Experimente agora!"
                  />
                  <Button variant="contained" onClick={getProductList}>
                    Buscar lista
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <TextField
                    variant="outlined"
                    placeholder="Procurar por produto"
                    type="search"
                    onInput={e => setSearch(e.target.value)}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography>Total: {filteredProducts.length}</Typography>
                </Box>
              </Box>
              <TableContainer component={Paper} sx={{ mt: '2rem' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell> </StyledTableCell>
                      <StyledTableCell align="center">Serial</StyledTableCell>
                      <StyledTableCell align="center">Data de Criação</StyledTableCell>
                      <StyledTableCell align="center">Data do Recebimento</StyledTableCell>
                      <StyledTableCell align="center">Nome do Produto</StyledTableCell>
                      <StyledTableCell align="center">Preço</StyledTableCell>
                      <StyledTableCell align="center">SKU</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {filteredProducts.length > 0 && (
                    <TableBody>
                      {filteredProducts
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: '80px',
                                }}
                              >
                                {!finalizado && (
                                  <DeleteForeverIcon
                                    onClick={() => deleteItemTableOne(row.productItensId)}
                                    sx={{ cursor: 'pointer' }}
                                  />
                                )}
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.serial}</StyledTableCell>
                            <StyledTableCell align="center">
                              {new Date(row.created).toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.createDateRead
                                ? new Date(row.createDateRead).toLocaleString()
                                : ''}
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.productName}</StyledTableCell>
                            <StyledTableCell align="center">{row.price}</StyledTableCell>
                            <StyledTableCell align="center">{row.sku}</StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={listProductEnv.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                onClick={createTransferOrder}
                type="submit"
                variant="contained"
                sx={{ width: '100%' }}
              >
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      `
    </>
  )
}

export default TransferOrderCreate
