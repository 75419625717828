import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  editProductAssociationService,
  getProductAll,
  getProductService,
  getUniqueProductAssociationService,
} from '../../../services/productService'
import { Context } from '../../../context/AuthContext'
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'

const ProductAssociationEdit = ({
  setEditing,
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  productItensId,
  handleFetchData,
}) => {
  const { subCompany, userId, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const token = localStorage.getItem('token')
  const [product, setProduct] = useState([])
  const [productId, setProductId] = useState('')
  const [serial, setSerial] = useState('')
  const [barcode, setBarcode] = useState('')
  const [lote, setLote] = useState('')
  const [laboratory, setLaboratory] = useState('')
  const [dataExpiration, setDataExpiration] = useState('')
  const [created, setCreated] = useState('')
  const [active, setActive] = useState(false)

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editForm, setEditForm] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const navigate = useNavigate()

  const editProductAssociation = async e => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    const association = {
      productItensId: productItensId,
      subCompanyId: subCompanyId,
      productId: productId,
      created: created,
      active: active,
      barcode: barcode,
      lote: lote,
      serial: serial,
      laboratory: laboratory,
      dataExpiration: dataExpiration,
      userId: userId,
    }

    try {
      const response = await editProductAssociationService(token, nameUrl, association)
      setLoading(false)
      setEditing(false)
      setExpanded(false)
      setAlerta(true)
      setSeverity('success')
      setTitle('Associação de Produto Editada com sucesso!')
      handleFetchData()
    } catch (err) {
      setLoading(false)
      console.log(err)
      setSeverity('error')
      setTitle('Erro ao editar, verifique os dados e tente novamente.')
    }
  }

  const getProductTotal = useCallback(async () => {
    //CompanyId=${item.companyId}&limit=${item.limit}&offset=${item.offset}&Order=${orderToString}&Reference=${item.reference}
    const item = {
      companyId: subCompany[0].companyId,
      limit: 500,
      offset: 0,
    }
    try {
      const response = await getProductAll(token, nameUrl, item)
      //   getProduct(response.data.total)
      setProduct(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const getProduct = useCallback(async total => {
    const item = {
      companyId: subCompany[0].companyId,
      limit: total,
      offset: 0,
      Reference: false,
    }
    try {
      const response = await getProductService(token, nameUrl, item)
      setProduct(response.data.result)
      console.log('segundo de certo ', response)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const getProductAssociation = async () => {
    setLoading(true)
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    }

    const formatter = Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    })

    try {
      const response = await getUniqueProductAssociationService(token, nameUrl, productItensId)
      setEditForm(true)
      setSubCompanyId(response.data.subCompanyId)
      setProductId(response.data.productId)
      setCreated(response.data.created)
      setActive(response.data.active)
      setBarcode(response.data.barcode)
      setLote(response.data.lote)
      setSerial(response.data.serial)
      setLaboratory(response.data.laboratory)
      setDataExpiration(formatDate(Date.parse(response.data.dataExpiration)))
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setEditForm(false)
      console.log(err)
    }
  }

  useEffect(() => {
    getProductTotal()
    getProductAssociation()
  }, [productItensId])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Erro no sistema, tente novamente mais tarde
          </Alert>
        </Snackbar>
        <Box component="form" onSubmit={editProductAssociation}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                <Select
                  value={subCompanyId}
                  label="Selecione a filial"
                  onChange={values => setSubCompanyId(values.target.value)}
                >
                  {subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Produto</InputLabel>
                <Select
                  value={productId ? productId : ''}
                  label="Selecione o produto"
                  onChange={values => setProductId(values.target.value)}
                >
                  {product.map(item => {
                    return <MenuItem value={item.productId}>{item.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={barcode ? barcode : ''}
                id="standard-basic"
                label="Código de Barras"
                variant="standard"
                onChange={values => setBarcode(values.target.value)}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={lote ? lote : ''}
                id="standard-basic"
                label="Lote"
                variant="standard"
                onChange={values => setLote(values.target.value)}
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={laboratory ? laboratory : ''}
                id="standard-basic"
                label="Laboratório"
                variant="standard"
                onChange={values => setLaboratory(values.target.value)}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel id="demo-simple-select-label" sx={{ mt: '1rem' }}>
                Data de Validade
              </InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={dataExpiration ? dataExpiration : ''}
                id="standard-basic"
                //label="Data de Validade"
                variant="standard"
                onChange={values => setDataExpiration(values.target.value)}
                type="date"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={serial ? serial : ''}
                id="standard-basic"
                label="Serial"
                variant="standard"
                onChange={values => setSerial(values.target.value)}
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              mt: '1rem',
            }}
            flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
          >
            <Box
              width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button variant="contained" sx={{ width: '100%' }} type="submit">
                Editar
              </Button>
            </Box>
            <Box
              width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                type="button"
                onClick={() => setExpanded(false)}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ProductAssociationEdit
