import { useCallback, useContext, useEffect, useState } from 'react'
import { setPermission } from '../../../permissions/userPermissions'
import { Context } from '../../../context/AuthContext'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import HelpIcon from '@mui/icons-material/Help'
import { CarWeightt } from '../../../const/RoleConstants'

import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'

//icones react-icons
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import CarWeightCreate from '../../Create/CarWeightCreate'
import { deleteCarWeightService, getCarWeightService } from '../../../services/carWeightServices'
import CarWeightEdit from '../../Edit/CarWeightEdit'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const CarWeight = () => {
  const { userRoles, subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const token = localStorage.getItem('token')
  const [intCredencial, setIntCredencial] = useState([])
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      wordBreak: 'break-word',
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getCarWeight = async id => {
    setLoading(true)
    setSubCompanyId(id)

    const data = {
      SubCompanyId: id,
      limit: 500,
      offset: 0,
    }

    try {
      const response = await getCarWeightService(token, nameUrl, data)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setIntCredencial([])
      } else {
        setIntCredencial(response.data.result)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      getCarWeight(subCompanyId)
    }
  }, [subCompanyId])

  const handleGetCarWeight = async () => {
    setLoading(true)

    const data = {
      SubCompanyId: subCompanyId,
      limit: 500,
      offset: 0,
    }

    try {
      const response = await getCarWeightService(token, nameUrl, data)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setIntCredencial([])
      } else {
        setIntCredencial(response.data.result)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      setError(true)
      setLoading(false)
    }
  }

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteCarWeightService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Limite de peso deletado com sucesso!')

      setAlerta(true)
      setLoading(false)
      handleGetCarWeight()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Limite de Peso</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        <ViewElearning pageName={'CarWeight'} open={viewElearning} setOpen={setViewElearning} />

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing ? 'Editar Limite de Peso' : 'Cadastrar Limite de Peso'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="CarWeight">
                <CarWeightEdit
                  setEditing={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  carWeightId={idEditing}
                  handleFetchData={handleGetCarWeight}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="CarWeight">
                <CarWeightCreate
                  expanded={expanded}
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={handleGetCarWeight}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            getCarWeight(e.target.value)
          }}
        />

        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <PermissionComponent role="CarWeight">
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
            >
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </Box>
          </PermissionComponent>
        </Box>

        {intCredencial.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center">Nome</StyledTableCell>
                  <StyledTableCell align="center">Kg Mínimo</StyledTableCell>
                  <StyledTableCell align="center">Kg Máximo</StyledTableCell>
                  <StyledTableCell align="center">Filial</StyledTableCell>
                  <StyledTableCell align="center">Ativo</StyledTableCell>
                  <StyledTableCell align="center">Criação</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {intCredencial
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center" sx={{ width: 100 }}>
                        <PermissionComponent role="CarWeight">
                          <DeleteForeverIcon
                            onClick={() => deleteAlert(row.carWeightId)}
                            sx={{ cursor: 'pointer' }}
                          />
                        </PermissionComponent>
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 100 }}>
                        <PermissionComponent role="CarWeight">
                          <ModeEditOutlineIcon
                            onClick={() => handleEdit(row.carWeightId)}
                            sx={{ cursor: 'pointer' }}
                          />
                        </PermissionComponent>
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.kgMin}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.kgMax}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.subCompanyName}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.active ? 'Sim' : 'Não'}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {new Date(row.createDate).toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={intCredencial.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default CarWeight
