import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  createCountStockOrderService,
  CreateOrderFullActiveControl,
  searchCategories,
} from '../../../services/productService'
import { getFilters } from '../../../services/reportServices'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const productSchema = yup.object().shape({
  movimentationOrderNumberOtherSystem: yup.string().required('O campo é obrigatório'),
  subCompanyId: yup.string().required('O campo é obrigatório'),
  setor: yup.string().required('O campo é obrigatório'),
})

const ConferenceClientForm = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(productSchema),
    defaultValues: {},
  })
  const { subCompany, nameUrl, company } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [error, setError] = useState(false)
  const [cicle, setCicle] = useState('')
  const [categoryId, setCategoryId] = useState('')

  const createCountStockOrder = async data => {
    setLoading(true)

    try {
      await CreateOrderFullActiveControl(token, nameUrl, data)
      setSeverity('success')
      setTitle('Pedido aberto com sucesso!')
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSubCompanyId('')
      setCicle('')
      setCategoryId()
      handleFetchData(data.subCompanyId)
    } catch (err) {
      console.log(err)
      setSeverity('error')
      setTitle('Erro ao criar, verifique oa dados e tente novamente.')
      setLoading(false)
      setAlerta(true)
    }
  }

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao cadastrar a Contagem!
          </Alert>
        </Snackbar>
        <Box component="form" onSubmit={handleSubmit(createCountStockOrder)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                {...register('subCompanyId')}
                label="Filial"
                sx={{ width: '100%' }}
                error={!!errors?.subCompanyId}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
              {errors?.subCompanyId && (
                <Typography sx={{ color: '#d32f2f', fontSize: '0.75rem', pl: '14px' }}>
                  {errors?.subCompanyId?.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                error={!!errors?.movimentationOrderNumberOtherSystem}
                helperText={errors?.movimentationOrderNumberOtherSystem?.message}
                {...register('movimentationOrderNumberOtherSystem')}
                label="Número da ordem"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('setor')}
                error={!!errors?.setor}
                helperText={errors?.setor?.message}
                fullWidth
                label="Setor"
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
                <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                  <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                    Salvar
                  </Button>
                </Box>
                <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => setExpanded(false)}
                    sx={{ width: '100%', ml: 1 }}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ConferenceClientForm
