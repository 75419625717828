import { useCallback, useContext, useEffect, useState } from 'react'
import {
  editMonitoringEquipmentService,
  getUniqueMonitoringEquipmentService,
} from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'

import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

const MonitorEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  monitorId,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const token = localStorage.getItem('token')
  const [ip, setIp] = useState('')
  const [mac, setMac] = useState('')
  const [port, setPort] = useState('')
  const [location, setLocation] = useState('')
  const [typeEquipmentHardware, setTypeEquipmentHardware] = useState('')
  const [createDate, setCreateDate] = useState('')
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const editMonitor = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const monitor = {
        monitorId: monitorId,
        ip: ip,
        port: port,
        location: location,
        typeEquipmentHardware: typeEquipmentHardware,
        createDate: createDate,
        active: active,
        subCompanyId: subCompanyId,
      }

      try {
        await editMonitoringEquipmentService(token, nameUrl, monitor)
        setLoading(false)
        setEditing(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Monitor automático editado com sucesso!')
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente.')
      }
    },
    [monitorId, ip, port, location, typeEquipmentHardware, createDate, active, subCompanyId]
  )

  const getMonitor = async () => {
    setLoading(true)
    try {
      const response = await getUniqueMonitoringEquipmentService(token, nameUrl, monitorId)
      setIp(response.data.ip)
      setMac(response.data.mac)
      setPort(response.data.port)
      setLocation(response.data.location)
      setTypeEquipmentHardware(response.data.typeEquipmentHardware)
      setCreateDate(response.data.createDate)
      setActive(response.data.active)
      setSubCompanyId(response.data.subCompanyId)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMonitor()
  }, [monitorId])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={editMonitor}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Filial"
                onChange={values => {
                  setSubCompanyId(values.target.value)
                }}
                value={subCompanyId}
                sx={{ width: '100%' }}
              >
                {subCompany.map(item => {
                  return <MenuItem value={item.subCompanyId}>{item.name}</MenuItem>
                })}
              </Select>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={ip}
                onChange={values => setIp(values.target.value)}
                id="outlined-basic"
                label="Ip"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={port}
                onChange={values => setPort(values.target.value)}
                id="outlined-basic"
                label="Porta"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={location}
                onChange={values => setLocation(values.target.value)}
                id="outlined-basic"
                label="Localização do Equipamento"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={typeEquipmentHardware}
                onChange={values => setTypeEquipmentHardware(values.target.value)}
                id="outlined-basic"
                label="Tipo Equipamento"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={mac}
                onChange={values => setMac(values.target.value)}
                id="outlined-basic"
                label="Mac"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box
              sx={{ mt: 3, textAlign: 'center' }}
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
            >
              <InputLabel>Ativo</InputLabel>
              <Checkbox onChange={() => setActive(!active)} checked={active} value={active} />
              {active ? 'Sim' : 'Não'}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" type="submit">
              Salvar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => setEditing(false)}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default MonitorEdit
