import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  Box,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  IconButton,
  Autocomplete,
  FormControl,
  tableCellClasses,
  styled,
  Tooltip,
  InputAdornment,
  Button,
  Alert,
  Snackbar,
  Backdrop,
  TablePagination,
} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Context } from '../../../context/AuthContext'
import { Add, Clear, Delete, Edit, Help, Save, Search } from '@mui/icons-material'
import { getPrinterZplService } from '../../../services/configServices'
import { createAssociationItensList, searchProductService } from '../../../services/productService'
import { useDebounce } from '../Product/Components/useDebounce'
import { colorTableDefault } from '../../TemplateDefault'
import ComponentQuantityCreate from './ComponentRfid/Create'
import ComponentQuantityEdit from './ComponentRfid/Edit'
import { getFaction } from '../../../services/factionService'
import { getAlocationStockGetAll } from '../../../services/alocationServices'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function AssociationBarCode() {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const { debounce } = useDebounce(1000, true)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [alocationStockId, setAlocationStockId] = useState('')
  const [factionId, setFactionId] = useState('')
  const [typePrint, setTypePrint] = useState(0)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [product, setProduct] = useState([])
  const [selectedId, setSelectedId] = useState(undefined)
  const [productItem, setProductItem] = useState([])
  const [isExternalLoading, setIsExternalLoading] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [rowsProducts, setRowsProducts] = useState([])
  const [rowsFilter, setRowsFilter] = useState([])
  const [factions, setFactions] = useState([])
  const [alocationStocks, setAlocationStocks] = useState([])
  const [messageError, setMessageError] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [alert, setAlerta] = useState(false)

  function Row(props) {
    const { row, onDelete } = props

    const handleOpenEditModal = row => {
      setIsEditModalOpen(true)
      setProductItem(row)
    }
    const handleDeleteClick = () => {
      onDelete(row)
    }

    return (
      <React.Fragment>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell component="th" scope="row">
            {row.name}
          </StyledTableCell>
          <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
          <StyledTableCell align="left">{row.serial}</StyledTableCell>
          <StyledTableCell align="left">{row.sku}</StyledTableCell>
          <StyledTableCell align="left">{row.referencia}</StyledTableCell>
          <StyledTableCell align="left">{row.price}</StyledTableCell>
          <StyledTableCell align="left">{row.barCode}</StyledTableCell>
          <StyledTableCell align="left">{row.color}</StyledTableCell>
          <StyledTableCell align="left">{new Date(row.created).toLocaleString()}</StyledTableCell>
          <StyledTableCell align="center" width="200px">
            <Tooltip title="Editar" arrow>
              <IconButton onClick={() => handleOpenEditModal(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Apagar" arrow>
              <IconButton onClick={handleDeleteClick}>
                <Delete />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleDelete = itemToDelete => {
    const updatedRows = rowsProducts.filter(item => item.serial !== itemToDelete)
    setRowsProducts(updatedRows)
  }

  const handleGetAllProductsSearch = async () => {
    setPage(0)
    setIsLoading(true)
    const dataProduct = {
      companyId: subCompany[0].companyId,
      limit: 10,
      offset: 0,
      order: 'desc',
      SearchWord: search,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProduct(data)

        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (search !== '') {
      setIsLoading(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search])

  const handleFaction = async () => {
    const data = {
      companyId: subCompany[0].companyId,
      limit: 500,
      offset: 0,
      order: 'desc',
    }
    try {
      const response = await getFaction(token, nameUrl, data)
      setFactions(response.data.result)
    } catch (error) {}
  }

  const handleAlocationStock = async () => {
    const data = {
      subCompanyId: subCompany[0].subCompanyId,
      limit: 500,
      offset: 0,
      order: 'desc',
    }
    try {
      const response = await getAlocationStockGetAll(token, nameUrl, data)
      setAlocationStocks(response.data.result)
    } catch (error) {}
  }

  useEffect(() => {
    if (subCompany[0].companyId) {
      handleFaction()
      handleAlocationStock()
    }
  }, [subCompany[0].companyId])

  useEffect(() => {}, [product, productItem])

  useEffect(() => {
    if (product.length === 1 && !isLoading) {
      setOpenModalCreate(true)
      setSelectedId(product[0]?.productId)
      setProductItem(product[0])
    }
  }, [product, isLoading])

  useEffect(() => {
    if (selectedId && !isEditModalOpen) {
      setSearch('')
      setOpenModalCreate(true)
      setProductItem(product[0])
    }
  }, [selectedId, productItem])

  useEffect(() => {
    if (selectedId && isEditModalOpen) {
      setOpenModalCreate(false)
    }
  }, [selectedId, isEditModalOpen])

  const autoCompleteSelectedOptions = useMemo(() => {
    if (!selectedId) return undefined

    const selectedOptions = product.find(item => item.id === selectedId)

    return selectedOptions
  }, [selectedId, product])

  const handleSaveCodeRfidCreate = async () => {
    setLoading(true)

    try {
      console.log(rowsProducts, 'rowsProducts')
      const response = await createAssociationItensList(token, nameUrl, rowsProducts)
      setTitle(response.data.message)
      setAlerta(true)
      setSeverity('success')
      setLoading(false)
      setRowsProducts([])
    } catch (error) {
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setSeverity('error')
      setLoading(false)
    }
  }

  const handleSaveCodeRfidEdit = newProduct => {
    const updatedProducts = rowsProducts.filter(product => product.serial !== productItem.serial)

    updatedProducts.push(newProduct)

    setRowsProducts(updatedProducts)
  }

  const filterRow = () => {
    const filtered = rowsProducts.filter(
      item =>
        item.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item.idProductPartner.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item.serial.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item.barCode.toLowerCase().includes(searchFilter.toLowerCase())
    )
    setRowsFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [searchFilter, rowsProducts])

  return (
    <Box sx={{ mt: 7 }}>
      <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Associação por codigo de barras</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }}>
          <Help />
        </IconButton>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alert}
        onClose={() => setAlerta(false)}
        key={vertical + horizontal}
      >
        <Alert onClose={() => setAlerta(false)} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <ComponentQuantityCreate
        open={openModalCreate}
        subCompanyId={subCompanyId}
        alocationStockId={alocationStockId}
        factionId={factionId}
        onClose={() => {
          setOpenModalCreate(false)
        }}
        product={productItem}
        productRows={rowsProducts}
        message={messageError}
        onSaveQuantity={e => {
          setRowsProducts(prevRows => prevRows.concat(e))
          setSearch('')
        }}
      />
      <ComponentQuantityEdit
        open={isEditModalOpen}
        subCompanyId={subCompanyId}
        onClose={() => {
          setIsEditModalOpen(false)
        }}
        product={productItem}
        onSaveQuantity={handleSaveCodeRfidEdit}
        isEdit={isEditModalOpen}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={typePrint === 4 ? 4 : 6}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                onChange={event => {
                  setSubCompanyId(event.target.value)
                  //handlePrintZpl(event.target.value)
                }}
                sx={{ width: '100%' }}
                label="Filial"
              >
                {subCompany.map((item, index) => (
                  <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={typePrint === 4 ? 4 : 6}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Escolha o equipamento</InputLabel>
              <Select
                value={typePrint}
                disabled={!subCompanyId}
                onChange={event => {
                  setTypePrint(event.target.value)
                }}
                sx={{ width: '100%' }}
                label="Selecione o tipo de impressora"
              >
                <MenuItem selected value={1}>
                  HID
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Facção(Opcional)</InputLabel>
              <Select
                value={factionId}
                disabled={!typePrint}
                onChange={event => {
                  setFactionId(event.target.value)
                }}
                sx={{ width: '100%' }}
                label="Facção(Opcional)"
              >
                {factions.map(item => (
                  <MenuItem value={item.factionId} key={item.factionId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Alocação de estoque(Opcional)</InputLabel>
              <Select
                value={alocationStockId}
                disabled={!typePrint}
                onChange={event => {
                  setAlocationStockId(event.target.value)
                }}
                sx={{ width: '100%' }}
                label="Alocação de estoque(Opcional)"
              >
                {alocationStocks.map(item => (
                  <MenuItem value={item.alocationStockId} key={item.alocationStockId}>
                    {item.address}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box width="100%">
            <Autocomplete
              openText="Abrir"
              closeText="Fechar"
              clearText="Apagar"
              noOptionsText={isLoading ? 'Carregando...' : 'Nenhum resultado encontrado'}
              loadingText="Carregando..."
              disablePortal
              value={autoCompleteSelectedOptions}
              loading={isLoading}
              onInputChange={(_, newValue) => setSearch(newValue)}
              disabled={!typePrint}
              popupIcon={
                isExternalLoading || isLoading ? (
                  <CircularProgress color="warning" size={20} />
                ) : undefined
              }
              onChange={(_, newValue) => {
                setSelectedId(newValue?.productId)
                setSearch(newValue ? newValue.idProductPartner : '')
                if (newValue === null) {
                  handleGetAllProductsSearch()
                }
              }}
              onClear={() => {
                setSelectedId('')
                setProduct([])
              }}
              options={product}
              getOptionLabel={option => (option ? `${option.barCode} - ${option.name || ''}` : '')}
              isOptionEqualToValue={(option, value) => option.productId === value.productId}
              renderInput={params => <TextField {...params} label="Pesquisar produtos" />}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              sx={{ maxWidth: { xs: 'auto', md: '500px' }, width: '100%' }}
              type="text"
              variant="outlined"
              placeholder="Pesquisar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={searchFilter}
              disabled={!subCompanyId}
              onChange={e => setSearchFilter(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            gap={2}
            mt={2}
            sx={{ flexDirection: { xs: 'column', md: 'row' } }}
          >
            <Button
              onClick={() => setRowsProducts([])}
              startIcon={<Clear />}
              variant="outlined"
              sx={{ width: '100%', maxWidth: { xs: 'auto', md: '250px' } }}
            >
              Limpar
            </Button>
            <Button
              startIcon={<Add />}
              variant="contained"
              disabled={!selectedId}
              sx={{ width: '100%', maxWidth: { xs: 'auto', md: '250px' } }}
              onClick={() => {
                if (!selectedId) {
                  setTitle('Selecione um produto.')
                  setAlerta(true)
                  setSeverity('error')
                } else {
                  setOpenModalCreate(true)
                }
              }}
            >
              Adicionar codigo
            </Button>
            <Button
              startIcon={<Save />}
              disabled={!selectedId}
              variant="contained"
              sx={{ width: '100%', maxWidth: { xs: 'auto', md: '250px' } }}
              onClick={handleSaveCodeRfidCreate}
            >
              Salvar dados
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
            <Typography variant="h5">{`Total de produtos: ${rowsFilter.length}`}</Typography>
            {/* <Typography variant="h5">{`Total de códigos gerados ${rowsFilter.reduce(
              (total, item) => total + (item.tagsRfid ? item.tagsRfid.length : 0),
              0
            )}`}</Typography> */}
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Nome</StyledTableCell>
                    <StyledTableCell align="left">Identificação ERP</StyledTableCell>
                    <StyledTableCell align="left">Serial</StyledTableCell>
                    <StyledTableCell align="left">SKU</StyledTableCell>
                    <StyledTableCell align="left">Referência</StyledTableCell>
                    <StyledTableCell align="left">Preço</StyledTableCell>
                    <StyledTableCell align="left">Cód. Barras</StyledTableCell>
                    <StyledTableCell align="left">Cor</StyledTableCell>
                    <StyledTableCell align="left">Criação</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsFilter.map(row => (
                    <Row key={row.serial} row={row} onDelete={() => handleDelete(row.serial)} />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rowsFilter.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default AssociationBarCode
