import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { searchCategories } from '../../../services/productService'
import { getFilters } from '../../../services/reportServices'

const InventoryClientEdit = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
}) => {
  const { subCompany, nameUrl, company } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [error, setError] = useState(false)
  const [countStockOrderNumberOtherSystem, setCountStockOrderNumberOtherSystem] = useState('')
  const [cicle, setCicle] = useState('')
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [categoryId, setCategoryId] = useState('')
  const [categories, setCategories] = useState([])
  const [productColor, setProductColor] = useState('')
  const [productType, setProductType] = useState('')
  const [country, setCountry] = useState('')
  const [collection, setCollection] = useState('')
  const [department, setDepartment] = useState('')
  const [subGroup, setSubGroup] = useState('')

  const [productColors, setProductColors] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [countries, setCountries] = useState([])
  const [collections, setCollections] = useState([])
  const [departments, setDepartments] = useState([])
  const [subGroups, setSubGroups] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [loadingColor, setLoadingColor] = useState(false)
  const [loadingProductType, setLoadingProductType] = useState(false)
  const [loadingCountry, setLoadingCountry] = useState(false)
  const [loadingCollection, setLoadingCollection] = useState(false)
  const [loadingDepartment, setLoadingDepartment] = useState(false)
  const [loadingSubGroup, setLoadingSubGroup] = useState(false)

  const fetchData = async (filter, value) => {
    try {
      const response = await getFilters(token, nameUrl, filter, company.companyId, value)
      return response.data
    } catch (error) {
      console.error('Erro ao buscar dados', error)
    }
  }

  const handleFilters = async (filter, value, setter, setLoading) => {
    setLoading(true)
    try {
      const data = await fetchData(filter, value)
      setter(data || [])
    } catch (error) {
      console.error('Erro ao buscar os dados dos filtros', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleFilters('GetColor', '', setProductColors, setLoadingColor)
    handleFilters('GetTypeProduct', '', setProductTypes, setLoadingProductType)
    handleFilters('GetCountry', '', setCountries, setLoadingCountry)
    handleFilters('GetCollection', '', setCollections, setLoadingCollection)
    handleFilters('GetDepartment', '', setDepartments, setLoadingDepartment)
    handleFilters('GetSubGroup', '', setSubGroups, setLoadingSubGroup)
  }, [])

  const createCountStockOrder = async e => {
    e.preventDefault()
    setLoading(true)
    const countStockOrder = {
      movimentationOrderNumberOtherSystem: countStockOrderNumberOtherSystem,
      typeOrder: 6,
      subCompanyId,
      cicle,
      productId: categoryId,
    }

    try {
      //await createCountStockOrderService(token, nameUrl, countStockOrder)
      setSeverity('success')
      setTitle('Contagem cadastrada com sucesso!')
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSubCompanyId('')
      setCicle('')
      setCategoryId()
      handleFetchData(countStockOrder.subCompanyId)
    } catch (err) {
      console.log(err)
      setSeverity('error')
      setTitle('Erro ao criar, verifique oa dados e tente novamente.')
      setLoading(false)
      setAlerta(true)
    }
  }

  const handleClose = () => {
    setError(false)
  }

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setCategories([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchCategories(token, nameUrl).then(response => {
          const prodsFinal = []
          response.data.map(category => {
            const obj = category
            obj.value = category.countryId
            obj.label = `${category.name}`
            prodsFinal.push(obj)
          })
          setCategories(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao cadastrar a Contagem!
          </Alert>
        </Snackbar>
        <Box component="form" onSubmit={createCountStockOrder}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Filial"
                value={subCompanyId}
                onChange={values => {
                  setSubCompanyId(values.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={productTypes}
                value={productType}
                onChange={(event, newValue) => setProductType(newValue)}
                onInputChange={(event, value) =>
                  handleFilters('GetTypeProduct', value, setProductTypes, setLoadingProductType)
                }
                getOptionLabel={option => option.name || ''}
                filterOptions={options => options.slice(0, 10)}
                loading={loadingProductType}
                noOptionsText="Nenhum resultado"
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Tipo de Produto"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingProductType ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={productColors}
                value={productColor}
                onChange={(event, newValue) => setProductType(newValue)}
                onInputChange={(event, value) =>
                  handleFilters('GetTypeProduct', value, setProductTypes, setLoadingColor)
                }
                getOptionLabel={option => option.name || ''}
                filterOptions={options => options.slice(0, 10)}
                loading={loadingColor}
                noOptionsText="Nenhum resultado"
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Cor"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingColor ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={countries}
                value={country}
                onChange={(event, newValue) => setCountry(newValue)}
                onInputChange={(event, value) =>
                  handleFilters('GetCountry', value, setCountries, setLoadingCountry)
                }
                getOptionLabel={option => option.name || ''}
                filterOptions={options => options.slice(0, 10)}
                loading={loadingCountry}
                noOptionsText="Nenhum resultado"
                renderInput={params => (
                  <TextField
                    {...params}
                    label="País"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingCountry ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={collections}
                value={collection}
                onChange={(event, newValue) => setCollection(newValue)}
                onInputChange={(event, value) =>
                  handleFilters('GetCollection', value, setCollections, setLoadingCollection)
                }
                getOptionLabel={option => option.name || ''}
                filterOptions={options => options.slice(0, 10)}
                loading={loadingCollection}
                noOptionsText="Nenhum resultado"
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Coleção"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingCollection ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={departments}
                value={department}
                onChange={(event, newValue) => setDepartment(newValue)}
                onInputChange={(event, value) =>
                  handleFilters('GetDepartment', value, setDepartments, setLoadingDepartment)
                }
                getOptionLabel={option => option.name || ''}
                filterOptions={options => options.slice(0, 10)}
                loading={loadingDepartment}
                noOptionsText="Nenhum resultado"
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Departamento"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingDepartment ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={subGroups}
                value={subGroup}
                onChange={(event, newValue) => setSubGroup(newValue)}
                onInputChange={(event, value) =>
                  handleFilters('GetSubGroup', value, setSubGroups, setLoadingSubGroup)
                }
                getOptionLabel={option => option.name || ''}
                filterOptions={options => options.slice(0, 10)}
                loading={loadingSubGroup}
                noOptionsText="Nenhum resultado"
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Subgrupos"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingSubGroup ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
                <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                  <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                    Salvar
                  </Button>
                </Box>
                <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => setExpanded(false)}
                    sx={{ width: '100%', ml: 1 }}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default InventoryClientEdit
