import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { Box, CircularProgress, Paper } from '@mui/material'
import randomColor from 'randomcolor'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const CountReceiver = ({
  subCompanyId,
  startDate,
  finishDate,
  handleGetDashDash,
  title,
  Icon,
  stockValue,
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [dataCard, setDataCard] = useState([])
  const randomColorValue = randomColor()

  useEffect(() => {
    console.log(handleGetDashDash, 'dsdadssa')
  }, [])

  const handle = async () => {
    setLoading(true)
    const response = await handleGetDashDash()
    setLoading(false)
  }

  const customTooltipFormatter = (value, name, props) => {
    const customNames = {
      inadimplentescronicos: 'Inadimplentes Crônicos',
      areceber: 'A Receber',
    }

    const customName = customNames[name] || name

    return [`R$ ${value.toLocaleString('pt-BR')}`, customName]
  }

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {loading ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '30%',
            left: '48%',
          }}
          color="primary"
        />
      ) : error ? (
        'Deu erro'
      ) : (
        <>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={800}
              data={handleGetDashDash.slice(0, 30)}
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="cliente" />
              <YAxis tickFormatter={value => `R$ ${value.toLocaleString('pt-BR')}`} />
              <Tooltip
                labelFormatter={value => `Cliente: ${value}`}
                formatter={customTooltipFormatter}
              />
              <Legend />
              <Bar
                name="Inadimplentes Crônicos"
                dataKey="inadimplentescronicos"
                fill="red"
                activeBar={<Rectangle fill="red" stroke="red" />}
              />
              <Bar
                name="A receber"
                dataKey="areceber"
                fill="#82CA9D"
                activeBar={<Rectangle fill="#82CA9D" stroke="#82CA9D" />}
              />
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </Box>
  )
}

export default CountReceiver
