import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  editAlocationStockService,
  getAlocationStockService,
} from '../../../services/alocationServices'

import Button from '@mui/material/Button'
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

const AlocationStockEdit = ({
  editing,
  setEditing,
  alerta,
  setAlerta,
  severity,
  setSeverity,
  title,
  setTitle,
  setExpanded,
  expanded,
  getAlocation,
  subCompanyId,
  alocationId,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)

  const [subCompanyIdEdit, setSubCompanyIdEdit] = useState(subCompanyId)
  const [street, setStreet] = useState('')
  const [address, setAddress] = useState('')
  const [level, setLevel] = useState('')
  const [column, setColumn] = useState('')
  const [serial, setSerial] = useState('')

  useEffect(() => {
    setLoading(true)
    const getAlocationStock = async () => {
      const companyGet = subCompany.filter(company => company.subCompanyId === subCompanyId)

      try {
        const response = await getAlocationStockService(
          token,
          subCompanyId,
          companyGet[0].companyId
        )
        let respFilter = response.data.filter(resp => resp.alocationStockId === alocationId)
        setStreet(respFilter[0].street)
        setAddress(respFilter[0].address)
        setLevel(respFilter[0].level)
        setColumn(respFilter[0].column)
        setSerial(respFilter[0].serial)
        setLoading(false)
      } catch (e) {
        console.log(e)
        setLoading(false)
      }
    }

    getAlocationStock()
  }, [subCompanyId, alocationId])

  const EditAlocationStock = async e => {
    e.preventDefault()
    setLoading(true)
    const data = {
      alocationStockId: alocationId,
      street,
      address,
      level,
      column,
      subCompanyId: subCompanyIdEdit,
      serial,
      active: true,
    }

    try {
      await editAlocationStockService(token, nameUrl, data)
      setLoading(false)
      setAlerta(true)
      setSeverity('success')
      setTitle('Alocação editada com sucesso!')
      getAlocation()
      setEditing(!editing)
      setExpanded(!expanded)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao editar, verifique os dados e tente novamente.')
      setEditing(!editing)
    }
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box component="form" onSubmit={EditAlocationStock}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                <Select
                  value={subCompanyIdEdit}
                  //sx={{width:'100%'}}
                  placeholder="Selecione a empresa"
                  onChange={values => setSubCompanyIdEdit(values.target.value)}
                  sx={{ width: 1 }}
                >
                  <MenuItem disabled selected value="">
                    <em>Filial</em>
                  </MenuItem>
                  {subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Endereço</InputLabel>
                <TextField
                  sx={{ width: 1 }}
                  value={address}
                  id="standard-basic"
                  placeholder="Endereço"
                  //variant="standard"
                  onChange={values => setAddress(values.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Nome da Rua</InputLabel>
                <TextField
                  sx={{ width: 1 }}
                  value={street}
                  id="standard-basic"
                  placeholder="Nome da Rua"
                  //variant="standard"
                  onChange={values => setStreet(values.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Nome da Rua</InputLabel>
                <TextField
                  sx={{ width: 1 }}
                  value={level}
                  id="standard-basic"
                  placeholder="Andar"
                  //variant="standard"
                  onChange={values => setLevel(values.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Nome da Rua</InputLabel>
                <TextField
                  sx={{ width: 1 }}
                  id="standard-basic"
                  placeholder="Coluna"
                  //variant="standard"
                  value={column}
                  onChange={values => setColumn(values.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel id="demo-simple-select-label">Nome da Rua</InputLabel>
                <TextField
                  sx={{ width: 1 }}
                  value={serial}
                  id="standard-basic"
                  placeholder="Serial"
                  onChange={values => setSerial(values.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}></Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              mt: '1rem',
            }}
            flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
          >
            <Box
              width={{ xs: '80%', sm: '40%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button variant="contained" sx={{ width: '100%' }} type="submit">
                Salvar
              </Button>
            </Box>
            <Box
              width={{ xs: '80%', sm: '40%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                type="button"
                onClick={() => setEditing(false)}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default AlocationStockEdit
