import React, { useCallback, useContext, useMemo } from 'react'

import { useEffect, useState } from 'react'
//import Chart from "chart.js/auto";
import { Context } from '../../context/AuthContext'
import { getCountStockService, getSalesConsolidate } from '../../services/reportServices'
import { getProductAssociationService } from '../../services/productService'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import { setPermission } from '../../permissions/userPermissions'
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableFooter,
  TablePagination,
  Typography,
} from '@mui/material'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import DashCard from './components/DashCard'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { DRIVER } from '../../const/RoleConstants'
import SelectSubCompany from '../ComponentList/SelectSubCompany'

const Home = () => {
  const salesColumns = useMemo(
    () => [
      {
        Header: 'Produto',
        accessor: 'productName',
      },
      {
        Header: 'Vendedor',
        accessor: 'seller',
      },
      {
        Header: 'Total',
        accessor: 'totalAmount',
      },
      {
        Header: 'Data',
        accessor: 'emissionDate',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              {new Date(value).toLocaleDateString()} às {new Date(value).toLocaleTimeString()}
            </>
          )
        },
      },
    ],
    []
  )

  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const token = localStorage.getItem('token')
  const selectCompany = localStorage.getItem('companyId')

  const [productTotal, setProductTotal] = useState()
  const [stockTotal, setStockTotal] = useState()
  const [piecesTotal, setPiecesTotal] = useState()
  const [sellTotal, setSellTotal] = useState()

  const [sixMonths, setSixMonths] = useState([])
  const [dataSixMonths, setDataSixMonths] = useState([{ name: '', uv: '0' }])

  const [salesSixMonths, setSalesSixMonths] = useState([])
  const [dataSales, setDataSales] = useState([])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [permissionDriver, setPermissionDriver] = useState(false)
  const navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'black',
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const monthNames = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ]
  const today = new Date()
  const labels = []
  for (let i = 5; i >= 0; i -= 1) {
    let d = new Date(today.getFullYear(), today.getMonth() - i, 1)
    let month = monthNames[d.getMonth()]
    labels.push(month)
  }

  const showTotals = id => {
    console.log(subCompany, 'id')
    setStockTotal()
    getLastMonths(id)
    getSalesMonths(id)
    totalStock(id)
    totalProduct(id)
  }

  const totalStock = async id => {
    setStockTotal()
    if (id) {
      const countStockDTO = {
        subCompanyId: id,
        createDateTime: '2010-01-01T00:00:00Z',
        endDateTime: today.toISOString(),
      }
      try {
        const response = await getCountStockService(token, nameUrl, countStockDTO)
        setStockTotal(response.data.length ? response.data.length : '0')
      } catch (err) {
        console.log(err)
      }
      totalStockMonth(id)
    }
  }
  const totalProduct = async id => {
    setProductTotal()
    if (id) {
      try {
        const response = await getProductAssociationService(token, nameUrl, 10, 0, false, id)
        setProductTotal(response.data.total)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const getLastMonths = async id => {
    setPiecesTotal()

    if (id) {
      let createDateTime = new Date(today.getFullYear(), today.getMonth() - 5, 1)
        .toISOString()
        .substring(0, 10)
      let endDateTime = new Date(today.getFullYear(), today.getMonth() + 1, 0)
        .toISOString()
        .substring(0, 10)
      const countStockDTO = {
        subCompanyId: id,
        createDateTime,
        endDateTime,
      }
      try {
        const response = await getCountStockService(token, nameUrl, countStockDTO)
        setSixMonths(response.data)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const createSixMonths = dados => {
    let data = []
    for (let i = 5; i >= 0; i -= 1) {
      let startMonth = new Date(today.getFullYear(), today.getMonth() - i, 1)
      let endMonth = new Date(today.getFullYear(), today.getMonth() + 1 - i, 1)
      let value = 0
      for (let i of salesSixMonths) {
        let emissao = new Date(i.emissionDate)
        if (emissao >= startMonth && emissao <= endMonth) {
          value = value + i.totalAmount
        }
      }
      data.push(value)
    }

    const finalData = []
    data.map((item, index) => {
      finalData.push({ name: labels[index], uv: item })
    })
    setDataSixMonths(finalData)
  }
  const createLastSales = dados => {
    let data = []
    for (let i = 5; i >= 0; i -= 1) {
      let startMonth = new Date(today.getFullYear(), today.getMonth() - i, 1)
      let endMonth = new Date(today.getFullYear(), today.getMonth() + 1 - i, 1)
      let value = 0
      for (let i of sixMonths) {
        let countDate = new Date(i.createDate)
        if (countDate >= startMonth && countDate <= endMonth) {
          value++
        }
      }
      data.push(value)
    }
    const finalData = []
    data.map((item, index) => {
      finalData.push({ name: labels[index], uv: item })
    })
    setDataSales(finalData)
  }

  const getSalesMonths = async id => {
    setSellTotal()
    if (id) {
      let createDateTime = new Date(today.getFullYear(), today.getMonth() - 5, 1)
        .toISOString()
        .substring(0, 10)
      let endDateTime = new Date(today.getFullYear(), today.getMonth() + 1, 0)
        .toISOString()
        .substring(0, 10)
      const salesDTO = {
        subCompanyId: id,
        createDateTime,
        endDateTime,
      }
      try {
        const response = await getSalesConsolidate(token, nameUrl, salesDTO)
        setSalesSixMonths(response.data)
      } catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    totalSellMonth()
    createLastSales()
  }, [salesSixMonths])
  useEffect(() => {
    totalStockMonth()
    createSixMonths()
  }, [sixMonths])
  useEffect(() => {
    getPermissions()
  }, [])

  const getPermissions = useCallback(async () => {
    setPermissionDriver(setPermission(userRoles, DRIVER))
    if (setPermission(userRoles, DRIVER)) {
      navigate('/Driver')
    }
  }, [])

  const totalSellMonth = async () => {
    setSellTotal()

    const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      .toISOString()
      .substring(0, 10)
    let lastMonth = 0
    for (let i of salesSixMonths) {
      if (i.emissionDate > thisMonth) {
        lastMonth += i.totalAmount
      }
    }
    setSellTotal(`R$ ${lastMonth.toString()}`)
  }

  const totalStockMonth = async () => {
    setPiecesTotal()
    const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      .toISOString()
      .substring(0, 10)
    let lastMonth = 0
    for (let i of sixMonths) {
      if (i.createDate > thisMonth) {
        lastMonth = lastMonth + 1
      }
    }
    setPiecesTotal(lastMonth.toString())
  }

  useEffect(() => {
    if (subCompanyId) {
      showTotals(subCompanyId)
    }
  }, [subCompanyId])

  useEffect(() => {
    if (selectCompany) {
      const selectedSubCompany = subCompany.find(item => item.companyId === selectCompany)
      if (selectedSubCompany) {
        setSubCompanyId(selectedSubCompany.subCompanyId)
      }
    }
  }, [selectCompany, subCompany])

  return (
    <>
      <Box>
        <Box sx={{ mt: { xs: 8.5, md: 7.5 }, mb: 2 }}>
          <SelectSubCompany
            value={subCompanyId}
            onChange={e => {
              setSubCompanyId(e.target.value)
            }}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <DashCard
              title="TOTAL DE PRODUTOS"
              icon={<LocalMallOutlinedIcon color="primary" sx={{ fontSize: '4rem' }} />}
              numero={productTotal}
              subCompanyId={subCompanyId}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashCard
              title="TOTAL ESTOQUE (MÊS)"
              icon={<WarehouseIcon color="success" sx={{ fontSize: '4em' }} />}
              numero={stockTotal}
              subCompanyId={subCompanyId}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashCard
              title="PEÇAS CONTADAS (MÊS)"
              icon={<Inventory2OutlinedIcon sx={{ fontSize: '4em' }} color="warning" />}
              numero={piecesTotal}
              subCompanyId={subCompanyId}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashCard
              title="TOTAL VENDAS (MÊS)"
              icon={<MonetizationOnIcon sx={{ fontSize: '4em' }} color="error" />}
              numero={sellTotal}
              subCompanyId={subCompanyId}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={{ xs: 0, md: 2 }} rowSpacing={2} sx={{ m: '0 auto' }}>
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Typography variant="h6">Vendas</Typography>
            <Box sx={{ height: '30vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={dataSales} width={100} height={100}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Typography variant="h6">Contagem</Typography>
            <Box sx={{ height: '30vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={dataSixMonths}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={{ xs: 0, md: 2 }} sx={{ m: '0 auto' }}>
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Typography variant="overline">Últimas vendas</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ width: '100%' }} size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Produto</StyledTableCell>
                    <StyledTableCell align="right">Vendedor</StyledTableCell>
                    <StyledTableCell align="right">Total</StyledTableCell>
                    <StyledTableCell align="right">Data</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? salesSixMonths.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : salesSixMonths
                  ).map(row => (
                    <StyledTableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell align="right">{row.productName}</StyledTableCell>
                      <StyledTableCell align="right">{row.seller}</StyledTableCell>
                      <StyledTableCell align="right">{row.totalAmount}</StyledTableCell>
                      <StyledTableCell align="right">
                        {new Date(row.emissionDate).toISOString().substring(0, 10)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={3}
                      count={salesSixMonths.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Typography variant="overline">Últimos produtos contados</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ width: '100%' }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome do produto</TableCell>
                    <TableCell align="right">Filial</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell align="right">Data da contagem</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? sixMonths.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : sixMonths
                  ).map(row => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.productName}</TableCell>
                      <TableCell align="right">{row.subCompanyName}</TableCell>
                      <TableCell align="right">{row.stockCount}</TableCell>
                      <TableCell align="right">
                        {new Date(row.createDate).toISOString().substring(0, 10)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={3}
                      count={sixMonths.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Home
