import { useState, useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import { createZPLDefault } from '../../../services/printerZPLDefaultServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'

const PrinterZPLDefaultCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [companyId, setCompanyId] = useState('')

  const token = localStorage.getItem('token')
  const [name, setName] = useState('')
  const [density, setDensity] = useState('')
  const [height, setHeight] = useState('')
  const [width, setWidth] = useState('')
  const [laundry, setLaundry] = useState('')
  const [description, setDescription] = useState('')
  const [zpl, setZpl] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleCreate = async e => {
    e.preventDefault()

    //let dateNow = new Date()
    setLoading(true)
    setError(false)

    const data = {
      name: name,
      zpl: zpl,
      labelDensity: density,
      labelSizeHeight: height,
      labelSizeWidth: width,
      quantityLaundry: parseInt(laundry),
      quantityDescription: parseInt(description),
      companyId: companyId,
    }

    try {
      await createZPLDefault(token, nameUrl, data)
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSeverity('success')
      setTitle('Definição de ZPL cadastrada com sucesso!')
      handleFetchData()
    } catch (err) {
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
    }
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            w: 1,
          }}
        >
          <Box
            component="form"
            sx={{ mt: '2rem', display: 'flex', w: { xs: 1, md: 0.6 }, minWidth: 0.6 }}
            onSubmit={handleCreate}
          >
            <Box
              sx={{ width: '100%', alignItems: 'center' }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box
                sx={{
                  justifyContent: 'space-between',
                  border: '1px solid #cccccc',
                  p: 2,
                  minWidth: 1,
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                  <Select
                    label="Empresa"
                    value={companyId}
                    onChange={values => setCompanyId(values.target.value)}
                    sx={{ width: '100%' }}
                  >
                    {subCompany.map((item, index) => {
                      return (
                        <MenuItem value={item.companyId} key={index}>
                          {item.nameCompany}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between',
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={name}
                    onChange={values => setName(values.target.value)}
                    id="outlined-basic"
                    label="Nome"
                    variant="outlined"
                    type="text"
                  />
                  <Select
                    label="Densidade"
                    value={density}
                    onChange={values => setDensity(values.target.value)}
                    sx={{ width: '100%' }}
                  >
                    <MenuItem value={'6dpmm'}>6 dpmm (152 dpi)</MenuItem>
                    <MenuItem value={'8dpmm'}>8 dpmm (203 dpi)</MenuItem>
                    <MenuItem value={'12dpmm'}>12 dpmm (300 dpi)</MenuItem>
                    <MenuItem value={'24dpmm'}>24 dpmm (600 dpi)</MenuItem>
                  </Select>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between',
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={height}
                    onChange={values => setHeight(values.target.value)}
                    id="outlined-basic"
                    label="Altura"
                    variant="outlined"
                    type="text"
                  />
                  <TextField
                    sx={{ width: '100%' }}
                    value={width}
                    onChange={values => setWidth(values.target.value)}
                    id="outlined-basic"
                    label="Largura"
                    variant="outlined"
                    type="text"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between',
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={laundry}
                    onChange={values => setLaundry(values.target.value)}
                    id="outlined-basic"
                    label="Quantidade lavanderia"
                    variant="outlined"
                    type="text"
                  />
                  <TextField
                    sx={{ width: '100%' }}
                    value={description}
                    onChange={values => setDescription(values.target.value)}
                    id="outlined-basic"
                    label="Quantidade descrição"
                    variant="outlined"
                    type="text"
                  />
                </Box>
                <Typography variant="h6">ZPL</Typography>
                <TextField
                  sx={{ width: '100%' }}
                  value={zpl}
                  onChange={values => setZpl(values.target.value)}
                  id="outlined-basic"
                  label="ZPL"
                  variant="outlined"
                  type="text"
                  multiline
                  rows={10}
                />
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    mt: 3,
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="contained"
                    type="button"
                    onClick={() => setExpanded(false)}
                    sx={{ ml: 2 }}
                  >
                    Cancelar
                  </Button>
                  <Button variant="contained" type="submit">
                    Salvar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }} sx={{ mt: 3 }}>
            teste
          </Box> */}
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PrinterZPLDefaultCreate
