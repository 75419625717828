import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import {
  deleteMovBreakItemProcess,
  getMovBreakService,
  updateCicle,
} from '../../../../services/movBreakServices'
import { DeleteForever } from '@mui/icons-material'
import CachedIcon from '@mui/icons-material/Cached'
import { Context } from '../../../../context/AuthContext'

const MovBreakSingle = props => {
  const { nameUrl } = useContext(Context)

  const { movBreakSingle, StyledTableCell, StyledTableRow } = props
  const token = localStorage.getItem('token')

  const [movOrder, setMovOrder] = useState([])
  const [details, setDetails] = useState([])
  const [statusName, setStatusName] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState('success')
  const [title, setTitle] = useState('')
  const [alerta, setAlerta] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [openCicle, setOpenCicle] = useState(false)
  const [cicleEdit, setCicleEdit] = useState()
  const [movOrderItem, setMovOrderItem] = useState()

  const returnStatus = numero => {
    let texto = 'Aberto'

    if (numero === 1) {
      texto = 'Aberto'
    } else if (numero === 2) {
      texto = 'Completo'
    } else if (numero === 3) {
      texto = 'Em andamento'
    } else if (numero === -2) {
      texto = 'Cancelado'
    } else if (numero === -1) {
      texto = 'Rascunho'
    }
    return texto
  }

  useEffect(() => {
    getMovBreak()
  }, [movBreakSingle])

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }
  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteMovBreakItemProcess(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Ordem deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      getMovBreak()
    } catch (e) {
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
    setOpenCicle(false)
  }

  const getMovBreak = async () => {
    try {
      //setLoading(true);
      console.log(movBreakSingle, 'movBreakSingle')
      const response = await getMovBreakService(token, nameUrl, movBreakSingle)

      setMovOrder(response.data)

      const detailsInOrder = response.data.detailsMovimentationBreakOrderItemProcessModel.sort(
        (a, b) => a.ordem - b.ordem
      )
      setDetails(detailsInOrder)
      setStatusName(returnStatus(response.data.orderStatus))
      //setLoading(false)
    } catch (e) {
      setTitle('Erro ao alterar, tente novamente')
      setSeverity('error')
      setAlerta(open)
      setLoading(false)
    }
  }

  const handleUpdateCicle = (movimentationBreakOrderItemProcessId, cicle) => {
    setOpenCicle(true)
    setCicleEdit(cicle)
    setMovOrderItem(movimentationBreakOrderItemProcessId)
  }

  const fetchUpdateCicle = async () => {
    setLoading(true)
    setOpenCicle(false)
    try {
      const data = {
        movimentationBreakOrderItemProcessId: movOrderItem,
        cicle: cicleEdit,
      }
      await updateCicle(token, nameUrl, data)
      setTitle('Atualizado com sucesso!')
      setSeverity('success')
      setAlerta(true)
      setLoading(false)
      getMovBreak()
    } catch (e) {
      setTitle('Erro ao alterar, tente novamente')
      setSeverity('error')
      setAlerta(open)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2, justifyContent: 'space-between' }}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openCicle}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Qual é o ciclo?</DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              type="text"
              value={cicleEdit}
              onChange={e => setCicleEdit(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => fetchUpdateCicle()}>Salvar</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <FormControl sx={{ width: 0.5 }}>
          <TextField
            sx={{ width: '100%' }}
            value={
              movOrder.movimentationOrderNumberBihandsBreak
                ? movOrder.movimentationOrderNumberBihandsBreak
                : ''
            }
            id="outlined-basic"
            variant="outlined"
            label="Ordem Bihands"
            type="text"
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: 0.5 }}>
          <TextField
            sx={{ width: '100%' }}
            value={
              movOrder.movimentationOrderNumberOtherSystemBreak
                ? movOrder.movimentationOrderNumberOtherSystemBreak
                : ''
            }
            id="outlined-basic"
            variant="outlined"
            label="Ordem (outro sistema)"
            type="text"
            disabled
          />
        </FormControl>
      </Box>
      <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2, justifyContent: 'space-between' }}>
        <FormControl sx={{ width: 0.25 }}>
          <TextField
            sx={{ width: '100%' }}
            value={statusName ? statusName : ''}
            id="outlined-basic"
            variant="outlined"
            label="Status"
            type="text"
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: 0.33 }}>
          <TextField
            sx={{ width: '100%' }}
            value={
              new Date(movOrder.startOrder).toLocaleString()
                ? new Date(movOrder.startOrder).toLocaleString()
                : ''
            }
            id="outlined-basic"
            variant="outlined"
            label="Data Início"
            type="text"
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: 0.33 }}>
          <TextField
            sx={{ width: '100%' }}
            value={
              new Date(movOrder.finishOrder).toLocaleString()
                ? new Date(movOrder.finishOrder).toLocaleString()
                : ''
            }
            id="outlined-basic"
            variant="outlined"
            label="Última atualização"
            type="text"
            disabled
          />
        </FormControl>
      </Box>
      <TableContainer component={Paper} sx={{ mt: '2rem' }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center">Processo</StyledTableCell>
              <StyledTableCell align="center">Ciclo</StyledTableCell>
              <StyledTableCell align="center">Cavidade</StyledTableCell>
              <StyledTableCell align="center">Nome do Equipamento</StyledTableCell>
              <StyledTableCell align="center">Data Início</StyledTableCell>
              <StyledTableCell align="center">Data Término</StyledTableCell>
              <StyledTableCell align="center">Serial Atual</StyledTableCell>
              <StyledTableCell align="center">Usuario Fim</StyledTableCell>
              <StyledTableCell align="center">Usuario Inicio</StyledTableCell>
              <StyledTableCell align="center">Último Serial</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details.length > 0 &&
              details.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    align="center"
                    sx={{ display: 'flex', justifyContent: 'space-evenly' }}
                  >
                    <IconButton size="1.5em">
                      <DeleteForever
                        onClick={() => deleteAlert(row.movimentationBreakOrderItemProcessId)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </IconButton>
                    <IconButton size="1.5em">
                      <CachedIcon
                        onClick={() =>
                          handleUpdateCicle(row.movimentationBreakOrderItemProcessId, row.cicle)
                        }
                        sx={{ cursor: 'pointer' }}
                      />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.ordem}</StyledTableCell>
                  <StyledTableCell align="center">{row.processSequenceName}</StyledTableCell>
                  <StyledTableCell align="center">{row.cicle}</StyledTableCell>
                  <StyledTableCell align="center">{row.cavity || '-'}</StyledTableCell>
                  <StyledTableCell align="center">{row.equipmentName || '-'}</StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.startDate).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.finishDate).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell>{row.serialActual}</StyledTableCell>
                  <StyledTableCell align="center">{row.userNameFinish || '-'}</StyledTableCell>
                  <StyledTableCell align="center">{row.userNameStart || '-'}</StyledTableCell>
                  <StyledTableCell align="center">{row.serialLast}</StyledTableCell>
                </StyledTableRow>
              ))}
            {details.length === 0 && (
              <StyledTableRow key="semDetalhe">
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="center">Não há processos em andamento</StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default MovBreakSingle
