import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  createCompanyService,
  getPlanService,
  getSegmentService,
} from '../../../services/admServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { createClient } from '../../../services/clientService'
import { Context } from '../../../context/AuthContext'
import { Controller, useForm } from 'react-hook-form'

const ClientPCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
  companyId,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()
  const token = localStorage.getItem('token')
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyName, setSubCompanyname] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [search, setSearch] = useState('')
  const [singleProd, setSingleProd] = useState({
    codClient: '',
    name: '',
    email: '',
    cpF_CNPJ: '',
    cep: '',
    mobile: '',
    cpF_CNPJ: '',
  })
  const formatCep = cep => {
    cep = cep.replace(/\D/g, '')
    if (cep.length > 5) {
      cep = cep.replace(/(\d{5})(\d)/, '$1-$2')
    }
    return cep
  }

  const formatCpfCnpj = value => {
    const onlyNumbers = value.replace(/\D/g, '')

    if (onlyNumbers.length <= 11) {
      return onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    } else {
      return onlyNumbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    }
  }

  const isEmailValid = email => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return emailRegex.test(email)
  }

  const formatMobile = mobile => {
    mobile = mobile.replace(/\D/g, '')
    if (mobile.length > 0) {
      let formattedMobile = `(${mobile.substring(0, 2)}`

      if (mobile.length > 2) {
        formattedMobile += `) ${mobile.substring(2, 7)}`
      }

      if (mobile.length > 7) {
        formattedMobile += `-${mobile.substring(7, 11)}`
      }

      return formattedMobile
    }

    return mobile
  }

  const handleCreateClient = async object => {
    setLoading(true)

    const selectedSubCompany = subCompany.find(item => item.subCompanyId === object.subCompanyId)

    const data = {
      codClient: object.codClient,
      name: object.name,
      cpF_CNPJ: object.cpF_CNPJ,
      mobile: object.mobile,
      email: object.email,
      cep: object.cep,
      subCompanyId: object.subCompanyId,
      subCompanyname: selectedSubCompany.name,
    }
    try {
      const response = await createClient(token, nameUrl, data)
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSeverity('success')
      setTitle('Cliente cadastrado com sucesso!')
      handleFetchData(object.subCompanyId)
      reset()
      setSingleProd({
        cpF_CNPJ: '',
        cep: '',
        mobile: '',
      })
      setSubCompanyId('')
    } catch (err) {
      console.log(err)
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.2')
    }
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleSubmit(handleCreateClient)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth variant="outlined" error={!!errors.subCompanyId}>
                <InputLabel id="subCompanyId-label">Filial</InputLabel>
                <Select
                  label="Filial"
                  labelId="subCompanyId-label"
                  {...register('subCompanyId', {
                    required: 'Filial é obrigatória',
                  })}
                >
                  {subCompany.map((item, index) => (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.subCompanyId?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('codClient', {
                    required: 'Campo obrigatório',
                  })}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Codigo do Cliente"
                  error={!!errors.codClient}
                  helperText={errors.codClient?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('name', {
                    required: 'Campo obrigatório',
                  })}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Nome"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('email', {
                    require: 'Campo obrigatorio',
                    validate: value => isEmailValid(value) || 'Email inválido',
                  })}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('cpF_CNPJ', {
                    required: 'Campo obrigatório',
                  })}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="CPF/CNPJ"
                  inputProps={{ maxLength: 18 }}
                  value={singleProd.cpF_CNPJ}
                  onChange={e =>
                    setSingleProd(curr => ({ ...curr, cpF_CNPJ: formatCpfCnpj(e.target.value) }))
                  }
                  error={!!errors.cpF_CNPJ}
                  helperText={errors.cpF_CNPJ?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('cep', { required: 'Campo obrigatório' })}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="CEP"
                  inputProps={{ maxLength: 9 }}
                  value={singleProd.cep}
                  onChange={e =>
                    setSingleProd(curr => ({ ...curr, cep: formatCep(e.target.value) }))
                  }
                  error={!!errors.cep}
                  helperText={errors.cep?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('mobile', { required: 'Campo obrigatório' })}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 15 }}
                  label="Mobile"
                  value={singleProd.mobile}
                  onChange={e =>
                    setSingleProd(curr => ({ ...curr, mobile: formatMobile(e.target.value) }))
                  }
                  error={!!errors.mobile}
                  helperText={errors.mobile?.message}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      disabled={singleProd.address !== '' ? true : false}
                      sx={{ width: '100%' }}
                      value={singleProd.address}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Endereço"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          address: e.target.value,
                        }))
                      }
                      error={!!errors.address}
                      helperText={errors.address}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      disabled={singleProd.uf !== '' ? true : false}
                      sx={{ width: '100%' }}
                      value={singleProd.uf}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="UF"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          uf: e.target.value,
                        }))
                      }
                      error={!!errors.uf}
                      helperText={errors.uf}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      disabled={singleProd.city !== '' ? true : false}
                      sx={{ width: '100%' }}
                      value={singleProd.city}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Cidade"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          city: e.target.value,
                        }))
                      }
                      error={!!errors.city}
                      helperText={errors.city}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.neighborhood}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Bairro"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          neighborhood: e.target.value,
                        }))
                      }
                      error={!!errors.neighborhood}
                      helperText={errors.neighborhood}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.number}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Numero"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          number: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                </Grid>*/}

            <Grid
              item
              xs={12}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  mt: 3,
                  justifyContent: 'center',
                }}
              >
                <Button variant="contained" type="submit">
                  Salvar
                </Button>

                <Button
                  variant="contained"
                  type="button"
                  onClick={() => setExpanded(false)}
                  sx={{ ml: 2 }}
                >
                  Cancelar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ClientPCreate
