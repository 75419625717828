import { Box, Button, Container, Grid, InputLabel, MenuItem, Select, Skeleton } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { Logout } from '@mui/icons-material'
import { getCompanyService, getUniqueCompanyService } from '../../../services/admServices'

function CompanyList() {
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { company, subCompany, handleLogout } = useContext(Context)
  const [companyId, setCompanyId] = useState('')
  const [companies, setCompnies] = useState([])
  const [nameUrlItem, setNameUrlItem] = useState('')
  const [urlLogo, setUrlLogo] = useState('')

  const handleCompanyLogo = async (companyId, nameUrl) => {
    setLoading(true)
    try {
      const response = await getUniqueCompanyService(token, nameUrl, companyId)
      setUrlLogo(response.data.urlLogo)

      return response.data
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {}, [urlLogo])

  const handleCompanyChange = async e => {
    const item = e.target.value

    setCompanyId(item)
    const foundCompany = subCompany.find(company => company.companyId === item)

    // const foundCompanyLogo = companies.find(company => company.companyId === item)
    // if (foundCompanyLogo) {
    //   console.log(foundCompanyLogo.urlLogo, ' companies')

    //   setUrlLogo(companies.urlLogo)
    // }

    if (foundCompany) {
      const nameUrlItem = foundCompany.nameUrl ? foundCompany.nameUrl : '1'
      await handleCompanyLogo(item, nameUrlItem)

      setNameUrlItem(nameUrlItem)
      setLoading(false)
    }
  }

  return (
    <Box
      bgcolor={theme => theme.palette.primary.main}
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Container
        maxWidth="xs"
        sx={{
          bgcolor: theme => theme.palette.primary.contrastText,
          height: { xs: '100%', md: 'auto' },
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          borderRadius: { xs: 0, md: 2 },
          p: 4,
        }}
      >
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box textAlign="center">
                {loading ? (
                  <Skeleton width="100%" height={150} />
                ) : (
                  <img src={urlLogo} width={200} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <InputLabel shrink htmlFor sx={{ fontSize: 22 }}>
                Selecione a empresa
              </InputLabel>
              {loading ? (
                <Skeleton width="100%" height={60} />
              ) : (
                <Select fullWidth size="small" value={companyId} onChange={handleCompanyChange}>
                  {subCompany
                    .reduce((uniqueCompanies, company) => {
                      if (!uniqueCompanies.some(item => item.companyId === company.companyId)) {
                        uniqueCompanies.push(company)
                      }
                      return uniqueCompanies
                    }, [])
                    .map(company => (
                      <MenuItem value={company.companyId} key={company.companyId}>
                        {company.nameCompany}
                      </MenuItem>
                    ))}
                </Select>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                disabled={!companyId || loading}
                onClick={() => {
                  if (nameUrlItem === '1' || !nameUrlItem) {
                    localStorage.setItem('url', '1')
                    localStorage.setItem('companyId', companyId)
                  } else {
                    localStorage.setItem('url', nameUrlItem)
                    localStorage.setItem('companyId', companyId)
                  }
                  navigate('/')
                  localStorage.removeItem('selectedList')
                }}
              >
                Acessar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<Logout />}
                variant="outlined"
                fullWidth
                disabled={loading}
                onClick={handleLogout}
              >
                Sair
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  )
}

export default CompanyList
