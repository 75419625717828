import styled from '@emotion/styled'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
  Typography,
} from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../../context/AuthContext'
import { getAllReasonService } from '../../../../services/admServices'
import { consultaCEPService } from '../../../../services/api'
import {
  createOrderRouteService,
  getAllNFService,
  getAllOrdersService,
  getMovimentationOrderRouteService,
  getSingleMovRouteService,
} from '../../../../services/movimentationRouteServices'
import { searchProductService } from '../../../../services/productService'
import { colorTableDefault } from '../../../TemplateDefault'

const AddRoute = ({
  setMapScreen,
  setGeral,
  setAlerta,
  setSeverity,
  setTitle,
  resetScreen,
  setCreating,
  nfList,
  subCompanyId,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  // const [subCompanyId, setSubCompanyId] = useState('')

  const [newNFList, setNewNFList] = useState(nfList)
  const saveNFlist = nfList

  const token = localStorage.getItem('token')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [cep, setCep] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [phone, setPhone] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [observation, setObservation] = useState('')
  const [quantityTotal, setQuantityTotal] = useState('')
  const [movimentationOrderStatus, setMovimentationOrderStatus] = useState()

  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [searchNF, setSearchNF] = useState('')
  const [searchingNF, setSearchingNF] = useState(false)
  const [products, setProducts] = useState([])
  const [singleProd, setSingleProd] = useState({
    name: '',
    idProductPartner: '',
    quantity: '',
    dataExpiration: new Date(),
    dateTimeExpiration: new Date(),
    finishGood: false,
  })

  const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  const { id } = useParams()
  const [otherSystemSelected, setOtherSystemSelected] = useState()
  const [reason, setReason] = useState([])
  const [movimentationOrderRouteId, setMovimentationOrderRouteId] = useState(id)
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [reasonfororderId, setReasonfororderId] = useState('')
  const [movOrders, setMovOrders] = useState([])
  const [movRoute, setMovRoute] = useState({})
  const [itensOrder, setItensOrder] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [oldList, setOldList] = useState([])
  const vertical = 'top'
  const horizontal = 'center'
  const [inputValue, setInputValue] = useState('')
  const [heaveCep, setHeaveCep] = useState(false)
  const [incorretCep, setIncorretCep] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProducts([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.idProductPartner
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProducts(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  useEffect(() => {
    console.log(nfList)
  }, [])

  useEffect(() => {
    if (searchNF === '') {
      const checagem = setTimeout(() => {
        // setNewNFList([{ label: '', value: '' }])
        setNewNFList(saveNFlist)
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearchingNF(true)
      const checagem = setTimeout(() => {
        const dados = {
          subCompanyId: subCompanyId,
          movOrder: searchNF,
          limit: 50,
          offset: 0,
        }
        getAllNFService(token, nameUrl, dados).then(response => {
          const nfListFinal = []
          response.data.result.map(nf => {
            const obj = nf
            obj.value = nf.movimentationOrderId
            obj.label = `${nf.movimentationOrderNumberOtherSystem}`
            nfListFinal.push(obj)
          })
          setNewNFList(nfListFinal)
          setSearchingNF(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [searchNF])

  const addToList = () => {
    const testeNumber = parseInt(singleProd.quantity)
    if (isNaN(testeNumber)) {
      setSingleProd(curr => ({
        ...curr,
        quantity: 'Quantidade inválida',
      }))
      return
    }
    const repetido = itensOrder.find(x => x.idProductPartner === singleProd.idProductPartner)
    if (repetido) {
      return
    } else {
      const prod = singleProd
      prod.quantity = parseInt(singleProd.quantity)
      setItensOrder(curr => [...curr, singleProd])
    }
  }

  const getOldList = async subId => {
    try {
      const response = await getAllOrdersService(token, nameUrl, subId)
      setOldList(response.data.result)
    } catch (e) {
      console.log(e)
    }
  }

  const getMovOrder = async () => {
    setLoading(true)
    try {
      const response = await getMovimentationOrderRouteService(token, nameUrl, { routeId: id })
      setMovRoute(response.data)
      // setSubCompanyId(response.data.subCompanyId)
      setMovimentationOrderNumberOtherSystem(response.data.movimentationOrderNumberOtherSystem)
      setCep(response.data.cep)
      setAddress(response.data.address)
      setNeighborhood(response.data.neighborhood)
      setCity(response.data.city)
      setQuantityTotal(response.data.quantityTotal ? response.data.quantityTotal : '')
      setCompanyName(response.data.companyName)
      setPhone(response.data.phone)
      setMovimentationOrderStatus(response.data.movimentationOrderStatus)
      const subCNPJ = subCompany.find(x => x.subCompanyId === response.data.subCompanyId)
      setSubCompanyCNPJ(subCNPJ.cnpj)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getMovRoute = async id => {
    setLoading(true)
    try {
      const response = await getSingleMovRouteService(token, nameUrl, id)

      setMovRoute(response.data)
      setMovimentationOrderNumberOtherSystem(
        response.data.movimentationOrderNumberOtherSystem
          ? response.data.movimentationOrderNumberOtherSystem
          : ''
      )
      setCep(response.data.cep !== null ? response.data.cep : '')
      setAddress(response.data.address)
      setNeighborhood(response.data.neighborhood)
      setCity(response.data.city)
      setQuantityTotal(response.data.quantityTotal ? response.data.quantityTotal : '0')
      setCompanyName(response.data.companyName)
      setPhone(response.data.phone)
      setSubCompanyCNPJ(response.data.subCompanyCNPJ)
      setMovimentationOrderStatus(response.data.movimentationOrderStatus)
      setItensOrder(response.data.itensOrder)
      // getOldList(response.data.subCompanyId)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  // useEffect(() => {
  //   getMovOrder()
  // }, [])

  const getReason = useCallback(async () => {
    try {
      const response = await getAllReasonService(token, nameUrl)
      setReason(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [reason])

  const getMovOrders = useCallback(async () => {
    if (subCompanyId === '' || !subCompanyId) {
      return
    }
    try {
      const response = await getAllOrdersService(token, nameUrl, subCompanyId)
      setMovOrders(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [subCompanyId, token])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      //color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const createOrderRoute = async e => {
    e.preventDefault()
    const cnpjj = subCompany.find(x => x.subCompanyId === subCompanyId)
    setSubCompanyCNPJ(cnpjj.cnpj)
    setLoading(true)
    try {
      const keysToKeep = ['idProductPartner', 'quantity', 'finishGood', 'dataExpiration', 'name']
      const redux = array =>
        array.map(o =>
          keysToKeep.reduce((acc, curr) => {
            acc[curr] = o[curr]
            return acc
          }, {})
        )
      const finalList = redux(itensOrder)
      finalList.forEach(i => {
        i.subCompanyCNPJ = cnpjj.cnpj
        i.movimentationOrderNumberOtherSystem = movimentationOrderNumberOtherSystem
        i.dateTimeExpiration = i.dataExpiration
      })
      const data = {
        movimentationOrderNumberOtherSystem,
        movimentationOrderStatus: movimentationOrderStatus,
        movimentationOrderRouteId,
        address,
        city,
        companyName,
        neighborhood,
        cep,
        phone,
        reasonfororderId,
        observation,
        quantityFaction: 0,
        quantityTotal: quantityTotal || 0,
        subCompanyCNPJ: cnpjj.cnpj,
        itensOrder: finalList,
      }

      const response = await createOrderRouteService(token, nameUrl, data)

      setLoading(false)
      setSeverity('success')
      setTitle(response.data.message)
      setMapScreen({ latitude: false })
      setGeral(true)
      setAlerta(true)
      setCreating(false)
      resetScreen()
    } catch (err) {
      console.log(err)
      setLoading(false)
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
      setAlerta(true)
    }
  }

  const changeCep = async value => {
    setCep(value)
    if (value.length < 8) {
      return
    }
    try {
      const cepResponse = await consultaCEPService(value)
      setCity(cepResponse.data.localidade)
      setNeighborhood(cepResponse.data.bairro)
      setAddress(cepResponse.data.logradouro)
      setHeaveCep(true)
      setIncorretCep(false)
    } catch (e) {
      console.log(e)
      setHeaveCep(false)
      setIncorretCep(true)
    }
  }

  useEffect(() => {
    // getMovOrders()
    getReason()
  }, [subCompanyId])

  const handleClose = () => {
    setLoading(false)
  }

  const handleValidPhone = event => {
    const newValue = event.target.value
    const regex = /^[0-9]*$/ // expressão regular para aceitar apenas números

    if (regex.test(newValue)) {
      setPhone(newValue)
    }
  }

  return (
    <>
      <Box width={{ xs: 1, sm: 1, md: 0.75 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao criar o Pedido!
          </Alert>
        </Snackbar>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createOrderRoute}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-between' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Filial"
                value={subCompanyId}
                // onChange={values => setSubCompanyCNPJ(values.target.value)}
                disabled
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Razão</InputLabel>
              <Select
                label="Razão"
                value={reasonfororderId}
                onChange={e => setReasonfororderId(e.target.value)}
                error={!reasonfororderId}
                sx={{ width: '100%' }}
              >
                {reason &&
                  reason.map((item, index) => {
                    return (
                      <MenuItem value={item.reasonfororderId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
              </Select>
              {!reasonfororderId && <Typography color="red">Campo obrigatório</Typography>}
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Número da Ordem</InputLabel>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                // initialValue="123"
                // options={nfList}
                loading={searchingNF}
                options={newNFList}
                renderInput={params => <TextField {...params} />}
                // value={otherSystemSelected}
                onChange={(e, v) => {
                  if (v === null) {
                    setCep('')
                    setAddress('')
                    setNeighborhood('')
                    setCity('')
                    setQuantityTotal('')
                    setCompanyName('')
                    setPhone('')
                  } else {
                    getMovRoute(v.movimentationOrderId)
                    setMovimentationOrderNumberOtherSystem(v.movimentationOrderNumberOtherSystem)
                  }
                }}
                // inputValue={inputValue}

                // onInputChange={(event, newInputValue) => {
                //   if (newInputValue) {
                //     setOtherSystemSelected(newInputValue)
                //     setMovimentationOrderNumberOtherSystem(newInputValue)
                //   }
                //   setInputValue(newInputValue)
                // }}
                onInputChange={(e, v) => {
                  setSearchNF(v)
                }}
                getOptionLabel={option => option.movimentationOrderNumberOtherSystem}
                // sx={{ width: 1 }}
                // loadingText="Procurando..."
                // noOptionsText="Sem Ordens"
                // isOptionEqualToValue={(option, value) =>
                //   option.movimentationOrderNumberOtherSystem === otherSystemSelected
                // }
              />
            </Box>
          </Box>

          <Box
            sx={{ width: '100%', justifyContent: 'space-between' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Nome Empresa</InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={companyName}
                id="standard-basic"
                // label="Nome Empresa"
                //variant="standard"
                onChange={values => setCompanyName(values.target.value)}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Telefone</InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={phone}
                id="standard-basic"
                // label="Telefone"
                //variant="standard"
                onChange={handleValidPhone}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label" sx={incorretCep ? { color: 'red' } : ''}>
                {!incorretCep ? 'CEP' : 'Insira um CEP válido'}
              </InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={cep}
                error={incorretCep}
                id="standard-basic"
                // label="CEP"
                //variant="standard"
                onChange={values => changeCep(values.target.value)}
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-between' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Endereço</InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={address}
                id="standard-basic"
                // label="Endereço"
                //variant="standard"
                onChange={values => setAddress(values.target.value)}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Cidade</InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={city}
                disabled={incorretCep || cep.length < 8}
                id="standard-basic"
                // label="Cidade"
                //variant="standard"
                onChange={values => setCity(values.target.value)}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Bairro</InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={neighborhood}
                id="standard-basic"
                // label="Bairro"
                //variant="standard"
                disabled={incorretCep || cep.length < 8}
                onChange={values => setNeighborhood(values.target.value)}
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-between' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Observação</InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={observation}
                id="standard-basic"
                // label="Observação"
                //variant="standard"
                onChange={values => setObservation(values.target.value)}
                type="text"
              />
            </Box>

            <Box width={{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Quantidade Total</InputLabel>
              <TextField
                sx={{ width: 1 }}
                value={quantityTotal}
                id="standard-basic"
                // label="Quantidade Total"
                //variant="standard"
                onChange={values => setQuantityTotal(values.target.value)}
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: 1,
              mt: 2,
              display: 'flex',
              gap: 2,
              border: '1px solid black',
              borderRadius: 2,
              p: 2,
              flexDirection: 'column',
            }}
          >
            <Box sx={{ width: 1, gap: 2 }}>
              <Box sx={{ width: 1, display: 'flex', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, width: 1 }}>
                  <FormControl sx={{ width: 0.4 }}>
                    <Autocomplete
                      disablePortal
                      id="lista-produtos"
                      options={products}
                      //sx={{ width: 300 }}
                      loading={searching}
                      loadingText="Procurando..."
                      noOptionsText="Nenhum produto encontrado"
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.productId}>
                            {option.label}
                          </li>
                        )
                      }}
                      renderInput={params => <TextField {...params} label="Procurar produto" />}
                      onInputChange={(e, v) => {
                        setSearch(v)
                      }}
                      //onChange={(e, v) => setProductId(v)}
                      onChange={(e, v) => {
                        if (v !== null) {
                          setSingleProd(curr => ({
                            ...curr,
                            idProductPartner: v.value,
                            productName: v.name,
                            dataExpiration: v.dataExpiration ? v.dataExpiration : '',
                            subCompanyCNPJ: subCompanyCNPJ,
                            movimentationOrderNumberOtherSystem:
                              movimentationOrderNumberOtherSystem,
                          }))
                        } else {
                          setSingleProd(curr => ({
                            ...curr,
                            quantity: '',
                          }))
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ width: 0.2 }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.quantity}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Quantidade"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          quantity: e.target.value,
                        }))
                      }
                    />
                  </FormControl>

                  <Box sx={{ width: 0.25, alignItems: 'center', display: 'flex' }}>
                    <Button variant="outlined" onClick={addToList}>
                      Adicionar à lista
                    </Button>
                  </Box>
                </Box>
              </Box>
              <TableContainer component={Paper} sx={{ mt: '2rem' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell> </StyledTableCell>
                      <StyledTableCell align="center">Produto</StyledTableCell>
                      <StyledTableCell align="center">ERP</StyledTableCell>
                      <StyledTableCell align="center">Quantidade</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itensOrder.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setItensOrder(curr => curr.filter((v, i) => i !== index))}
                        >
                          <DeleteForeverIcon />
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.productName}</StyledTableCell>
                        <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                        <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* </Box> */}
            </Box>
          </Box>
          {/* terminou */}
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          ></Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => {
                  // setExpanded(false)
                  setCreating(false)
                }}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default AddRoute
