import { useCallback, useState, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Context } from '../../../context/AuthContext'
import {
  createEmployeeService,
  editEmployeeService,
  getSubCompanyCompanyIdService,
  getUniqueEmployeeService,
} from '../../../services/configServices'
import { getSegmentGroup } from '../../../services/admServices'
import { getCompanyService, getSegmentGroupService } from '../../../services/admServices'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@mui/material'

const ValidatorSchema = yup.object().shape({
  companyId: yup.string().required('Campo obrigatório.'),
  subCompanyId: yup.string().required('Campo obrigatório.'),
  nameEmployee: yup.string().required('Campo obrigatório.'),
  emailEmployee: yup.string().required('Campo obrigatório.'),
  segmentGroupId: yup.string().required('Campo obrigatório.'),
})

const Validator = yupResolver(ValidatorSchema)

const EmployeeEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  employeeId,
  handleFetchData,
}) => {
  const formMethods = useForm({
    resolver: Validator,
  })
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    reset,
  } = formMethods

  const { subCompany, userId, nameUrl } = useContext(Context)
  const [companyLimit, setCompanyLimit] = useState(500)
  const [companyOffset, setCompanyOffset] = useState(0)
  const [companyOrder, setCompanyOrder] = useState('acs')
  const [company, setCompany] = useState([])
  const [subCompanyId, setSubCompanyId] = useState('')
  const [usersId, setUsersId] = useState('')
  const [subCompanys, setSubCompanys] = useState([])
  const [companyId, setCompanyId] = useState('')
  const [segmentGroup, setSegmentGroup] = useState([])
  const [segmentId, setSegmentId] = useState('')
  const [companyData, setCompanyData] = useState('')
  const [subCompanyData, setSubCompanyData] = useState('')
  const [nameEmployee, setNameEmployee] = useState('')
  const [emailEmployee, setEmailEmployee] = useState('')
  const [permissionName, setPermissionName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [subCompanyName, setSubCompanyName] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const token = localStorage.getItem('token')

  const getEmployee = async () => {
    setLoading(true)

    try {
      const response = await getUniqueEmployeeService(token, nameUrl, employeeId)
      setValue('companyId', response.data.listSubCompany[0].companyId.toString()) // Certifique-se de converter para string se necessário
      setValue('emailEmployee', response.data.emailEmployee)
      setValue('subCompanyId', response.data.listSubCompany[0].subCompanyId.toString()) // Certifique-se de converter para string se necessário
      setValue('nameEmployee', response.data.nameEmployee)
      if (subCompany[0].companyId === '23262935-641b-49a5-ae6e-201a3d6a019d') {
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const handleGetCompany = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getCompanyService(
        token,
        nameUrl,
        companyLimit,
        companyOffset,
        companyOrder
      )
      setCompany(response.data.result)
      if (subCompany[0].companyId === '23262935-641b-49a5-ae6e-201a3d6a019d') {
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const handleGetSubCompany = useCallback(async companyId => {
    setLoading(true)
    setSubCompanyId('')
    setUsersId('')
    try {
      const response = await getSubCompanyCompanyIdService(token, nameUrl, watch('companyId'))
      setSubCompanys(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const handleSegmentGroup = async value => {
    setLoading(true)
    try {
      const response = await getSegmentGroup(token, nameUrl, 10000, 0, false, value)
      setSegmentGroup(response.data.result)
      setLoading(false)
      setSegmentId(value)
    } catch (error) {
      setLoading(false)
    }
  }

  const updateEmployee = useCallback(
    async data => {
      data.userId = employeeId
      console.log(data, ' datas')
      setLoading(true)
      try {
        editEmployeeService(token, nameUrl, data)
        setSeverity('success')
        setTitle('Colaborador editado com sucesso!')
        setLoading(false)
        setEditing(false)
        setAlerta(true)
        //handleFetchData()
        reset()
      } catch (err) {
        console.log(err)
        setSeverity('error')
        setTitle('Erro no cadastro, verifique os dados e tente novamente.')
        setLoading(false)
        setAlerta(true)
      }
    },
    [nameEmployee, emailEmployee, companyData, subCompanyData, permissionName]
  )

  useEffect(() => {
    if (employeeId) {
      getEmployee()
      handleGetCompany()
    }
  }, [employeeId, segmentId])

  useEffect(() => {
    if (subCompany[0].companyId !== '23262935-641b-49a5-ae6e-201a3d6a019d') {
      handleGetSubCompany(subCompany[0].companyId)

      const selectedCompany = company.find(item => item.companyId === subCompany[0].companyId)
      if (selectedCompany) {
        const { companyId, segmentId } = selectedCompany
        setSegmentId(segmentId)
      }
    }
  }, [subCompany, company])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao Cadastrar Colaborador!
          </Alert>
        </Snackbar>
        <Box component="form" sx={{ my: '0.5rem' }} onSubmit={handleSubmit(updateEmployee)}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              {subCompany[0].companyId !== '23262935-641b-49a5-ae6e-201a3d6a019d' ? (
                <Select
                  name="company"
                  label="Empresa"
                  value={subCompany[0].companyId}
                  error={!!errors.companyId}
                  sx={{ width: '100%' }}
                  disabled
                >
                  {company.map((item, index) => {
                    return (
                      <MenuItem value={item.companyId} key={item.companyId}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              ) : (
                <Select
                  {...register('companyId')}
                  name="company"
                  label="Empresa"
                  value={watch('companyId')}
                  onChange={values => {
                    const selectedValue = values.target.value
                    setValue('companyId', selectedValue)
                    const selectedCompany = company.find(item => item.companyId === selectedValue)
                    if (selectedCompany) {
                      const { companyId, segmentId } = selectedCompany
                      handleSegmentGroup(segmentId)
                      handleGetSubCompany(companyId)

                      clearErrors('companyId')
                    }
                  }}
                  error={!!errors.companyId}
                  sx={{ width: '100%' }}
                >
                  {company.map((item, index) => {
                    return (
                      <MenuItem value={item.companyId} key={item.companyId}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
              {!!errors.companyId && (
                <p
                  style={{
                    color: '#d32f2f',
                    fontSize: '0.75rem',
                    marginTop: '3px',
                    marginRight: '14px',
                    marginLeft: '14px',
                  }}
                >
                  {errors?.companyId?.message}
                </p>
              )}
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                {...register('subCompanyId')}
                name="subCompanyId"
                label="Filial"
                value={watch('subCompanyId')}
                onChange={values => {
                  const selectedValue = values.target.value
                  setValue('subCompanyId', selectedValue)
                  clearErrors('subCompanyId')
                  if (subCompany[0].companyId !== '23262935-641b-49a5-ae6e-201a3d6a019d') {
                    handleSegmentGroup(segmentId)
                  }
                }}
                error={!!errors.subCompanyId}
                sx={{ width: '100%' }}
              >
                {subCompanys.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
              {!!errors.subCompanyId && (
                <p
                  style={{
                    color: '#d32f2f',
                    fontSize: '0.75rem',
                    marginTop: '3px',
                    marginRight: '14px',
                    marginLeft: '14px',
                  }}
                >
                  {errors?.subCompanyId?.message}
                </p>
              )}
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                {...register('nameEmployee')}
                name="nameEmployee"
                sx={{ width: '100%' }}
                value={watch('nameEmployee')}
                onChange={values => {
                  const selectedValue = values.target.value
                  setValue('nameEmployee', selectedValue)
                  clearErrors('nameEmployee')
                }}
                id="outlined-basic"
                label="Nome do Colaborador"
                variant="outlined"
                type="text"
                error={!!errors.nameEmployee}
                helperText={errors?.nameEmployee?.message}
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                {...register('emailEmployee')}
                name="emailEmployee"
                sx={{ width: '100%' }}
                value={watch('emailEmployee')}
                onChange={values => {
                  const selectedValue = values.target.value
                  setValue('emailEmployee', selectedValue)
                  clearErrors('emailEmployee')
                }}
                id="outlined-basic"
                label="Email do Colaborador"
                variant="outlined"
                type="email"
                error={!!errors.emailEmployee}
                helperText={errors?.emailEmployee?.message}
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Grupos de segmentos</InputLabel>
              <Select
                {...register('segmentGroupId')}
                label="Grupos de segmentos"
                value={watch('segmentGroupId')}
                onChange={values => {
                  const selectedValue = values.target.value
                  setValue('segmentGroupId', selectedValue)
                  clearErrors('segmentGroupId')
                }}
                error={!!errors.segmentGroupId}
                sx={{ width: '100%' }}
              >
                {segmentGroup.map((item, index) => {
                  return (
                    <MenuItem value={item.segmentGroupId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
              {!!errors.segmentGroupId && (
                <p
                  style={{
                    color: '#d32f2f',
                    fontSize: '0.75rem',
                    marginTop: '3px',
                    marginRight: '14px',
                    marginLeft: '14px',
                  }}
                >
                  {errors?.segmentGroupId?.message}
                </p>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setEditing(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default EmployeeEdit
