import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts'
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from '@mui/material'

const PieChartWithCustomizedLabel = ({ getGrafic, title, subCompanyId, startDate, finishDate }) => {
  const [dataGrafic, setDataGrafic] = useState()
  const [loading, setLoading] = useState(false)
  const [height, setHeight] = useState('30%')

  const isXsScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const fixedColors = [
    '#008000',
    '#FF0000',
    '#0000FF',
    '#FFD700',
    '#8A2BE2',
    '#00FF00',
    '#FFA07A',
    '#00FFFF',
    '#FF69B4',
    '#7B68EE',
    '#90EE90',
    '#FF4500',
    '#32CD32',
    '#4682B4',
    '#F08080',
    '#40E0D0',
    '#D2B48C',
    '#4B0082',
    '#B0E0E6',
    '#FF6347',
  ]

  useEffect(() => {
    setHeight(isXsScreen ? '50%' : '40%')
  }, [isXsScreen])

  const standardizeData = dataArray => {
    return dataArray.map((item, index) => ({
      name: item.clientName,
      vol: item.quantity,
    }))
  }

  const handleDataGrafic = async () => {
    setLoading(true)
    try {
      const response = await getGrafic()
      const standardizedData = standardizeData(response)
      setDataGrafic(standardizedData)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleDataGrafic()
  }, [subCompanyId, startDate, finishDate, height])

  return (
    <Card style={{ width: '100%', height: '400px', position: 'relative' }}>
      <CardHeader title={title} sx={{ p: 1, textAlign: 'center', mb: 2 }} />
      <Box>
        {loading ? (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 260,
              left: '48%',
            }}
            color="primary"
          />
        ) : (
          dataGrafic && (
            <>
              <ResponsiveContainer
                width="100%"
                height={300}
                aspect={2}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <PieChart style={{ height: '100%' }}>
                  <Pie
                    data={dataGrafic}
                    cx="50%"
                    cy={height}
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="vol"
                    label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
                      const radius = innerRadius + (outerRadius - innerRadius) * 0.5
                      const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180))
                      const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180))

                      return (
                        <text
                          x={x}
                          y={y}
                          fill="white"
                          textAnchor={x > cx ? 'start' : 'end'}
                          dominantBaseline="central"
                        >
                          {`${(percent * 100).toFixed(0)}%`}
                        </text>
                      )
                    }}
                  >
                    {dataGrafic.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={fixedColors[index % fixedColors.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend style={{ marginTop: '50px' }} />
                </PieChart>
              </ResponsiveContainer>
            </>
          )
        )}
      </Box>
    </Card>
  )
}

export default PieChartWithCustomizedLabel
