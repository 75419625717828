import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useContext, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { editTagService } from '../../../services/movimentationRouteServices'

const EditTable = ({
  open,
  setOpen,
  data,
  product,
  setTitleDelete,
  setAlertSuccessDelete,
  setAlertFailedDelete,
  getLabelOrder,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [movimentationOrderItemId, setMovimentationOrderItemId] = useState(
    data.movimentationOrderItemId
  )
  const [subCompanyId, setSubCompanyId] = useState()
  const [quantity, setQuantity] = useState(data.quantity)
  const [dataExpiration, setDataExpiration] = useState(
    new Date(data.dataExpiration).toLocaleString()
  )
  const [lote, setLote] = useState(data.lote)
  const [numberPallet, setNumberPallet] = useState(data.numberPallet)
  const [loading, setLoading] = useState(false)
  const [productSelect, setProductSelect] = useState(data.productId)

  const handleClose = () => {
    setOpen(!open)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const data = {
        movimentationOrderItemId,
        subCompanyId,
        quantity,
        dataExpiration,
        lote,
        numberPallet,
        productId: productSelect,
      }
      await editTagService(token, nameUrl, data)
      setTitleDelete('Editado com sucesso!')
      setAlertSuccessDelete(true)
      setLoading(false)
      setOpen(false)
      getLabelOrder()
    } catch (e) {
      console.log(e)
      setAlertFailedDelete(true)
      setLoading(false)
    }
  }

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Editando</DialogTitle>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleSubmit}>
          <DialogContent>
            <Box
              sx={{ width: '100%', justifyContent: 'space-between' }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box sx={{ width: '100%' }}>
                <InputLabel id="filial">Filial</InputLabel>
                <Select
                  label="Filial"
                  name="filial"
                  sx={{ width: '100%' }}
                  onChange={e => setSubCompanyId(e.target.value)}
                  value={subCompanyId}
                >
                  {subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
            </Box>
            <Box
              sx={{ width: '100%', justifyContent: 'space-between', mt: 1 }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box sx={{ width: '100%' }}>
                <InputLabel id="produto">Produto</InputLabel>
                <Select
                  label="Produto"
                  name="productSelect"
                  sx={{ width: '100%' }}
                  onChange={e => setProductSelect(e.target.value)}
                  value={productSelect}
                >
                  {product.map((item, index) => {
                    return (
                      <MenuItem value={item.productId} key={index}>
                        {item.productName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
            </Box>
            <Box
              sx={{ width: '100%', justifyContent: 'space-between' }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                <TextField
                  sx={{ width: '100%', mt: '1rem' }}
                  value={quantity}
                  name="quantity"
                  id="standard-basic"
                  label="Quantidade"
                  onChange={e => setQuantity(e.target.value)}
                  variant="outlined"
                  type="text"
                />
              </Box>
              <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                <TextField
                  sx={{ width: '100%', mt: '1rem' }}
                  id="outlined-basic"
                  label="Validade"
                  variant="outlined"
                  name="dataExpiration"
                  value={dataExpiration}
                  onChange={e => setDataExpiration(e.target.value)}
                  type="month"
                  pattern="\d{2}-\d{2}"
                  min="01-20"
                  max="12-50"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{ width: '100%', justifyContent: 'space-between' }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                <TextField
                  sx={{ width: '100%', mt: '1rem' }}
                  name="lote"
                  value={lote}
                  id="outlined-basic"
                  label="Lote"
                  variant="outlined"
                  onChange={e => setLote(e.target.value)}
                  type="text"
                />
              </Box>
              <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
                <TextField
                  sx={{ width: '100%', mt: '1rem' }}
                  id="outlined-basic"
                  label="Número Pallet"
                  variant="outlined"
                  name="numberPallet"
                  value={numberPallet}
                  onChange={e => setNumberPallet(e.target.value)}
                  type="text"
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={handleClose}>
              Cancelar
            </Button>
            <Button type="submit" autoFocus>
              Salvar
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default EditTable
