import { useState, useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import { setPermission } from '../../../permissions/userPermissions'
import { createLaundry } from '../../../services/laundryServices'
import { Backdrop, Box, Button, CircularProgress, TextField } from '@mui/material'

const LaundryCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [name, setName] = useState('')
  const [image, setImage] = useState('')
  const [zpl, setZpl] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleCreate = async e => {
    e.preventDefault()
    setLoading(true)

    const data = {
      name: name,
      image: image,
      zpl: zpl,
    }

    try {
      await createLaundry(token, nameUrl, data) /***** */
      setSeverity('success')
      setTitle('Lavanderia cadastrada com sucesso!')
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      handleFetchData()
    } catch (err) {
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
      setLoading(false)
      setAlerta(true)
    }
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleCreate}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={image}
                onChange={values => setImage(values.target.value)}
                id="outlined-basic"
                label="Imagem"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ width: '100%', mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={zpl}
                onChange={values => setZpl(values.target.value)}
                id="outlined-basic"
                label="ZPL"
                variant="outlined"
                type="text"
                multiline
                rows={10}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" type="submit">
              Salvar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => setExpanded(false)}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default LaundryCreate
