import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
} from '@mui/material'
import { Buffer } from 'buffer'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { editPrinterZplService, getUniquePrinterZplService } from '../../../services/configServices'
import { labelaryService, printCodeZPl } from '../../../services/printerZPLDefaultServices'

const PrinterZplEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  printerId,
  handleFetchData,
  setExpanded,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const token = localStorage.getItem('token')
  const [name, setName] = useState('')
  const [zpl, setZpl] = useState('')
  const [createDate, setCreateDate] = useState('')
  const [active, setActive] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [zplLab, setZplLab] = useState('')
  const [previewOpen, setPreviewOpen] = useState(false)

  const vertical = 'top'
  const horizontal = 'center'

  const editPrinter = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      const printer = {
        printerZplId: printerId,
        name,
        zpl,
        createDate,
        active,
        subCompanyId,
      }

      try {
        await editPrinterZplService(token, nameUrl, printer)
        setSeverity('success')
        setTitle('ZPL editado com sucesso!')
        setLoading(false)
        setEditing(false)
        setAlerta(true)
        handleFetchData()
      } catch (err) {
        console.log(err)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente.')
        setLoading(false)
        setAlerta(true)
      }
    },
    [printerId, name, zpl, createDate, active, subCompanyId]
  )

  const visualizarZPL = async () => {
    setPreviewOpen(true)

    const zplLabelary = await labelaryService(zpl)
    console.log(zplLabelary.data)
    console.log('zplLabelary.data')
    let base64ImageString = Buffer.from(zplLabelary.data, 'binary').toString('base64')
    setZplLab('data:image/png;base64,' + base64ImageString)
  }

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  const getPrinterZpl = async () => {
    setLoading(true)
    try {
      const response = await getUniquePrinterZplService(token, nameUrl, printerId)
      setName(response.data.name)
      setZpl(response.data.zpl)
      setCreateDate(response.data.createDate)
      setActive(response.data.active)
      setSubCompanyId(response.data.subCompanyId)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const cancelEdit = () => {
    setEditing(false)
    setExpanded(false)
  }

  const handleClose = () => {
    setError(false)
    setPreviewOpen(false)
  }

  const handleZplChange = event => {
    const newZpl = event.target.value
    setZpl(newZpl)
    loadPreviewImage(newZpl)
  }

  useEffect(() => {
    getPrinterZpl()
  }, [printerId])

  useEffect(() => {
    if (zpl) {
      loadPreviewImage()
    }
  }, [zpl])

  const handlePrintZPL = async () => {
    try {
      const response = await printCodeZPl(zpl)
    } catch (error) {}
  }

  const loadPreviewImage = async () => {
    try {
      const zplLabelary = await labelaryService(zpl)
      const base64ImageString = Buffer.from(zplLabelary.data, 'binary').toString('base64')
      setZplLab('data:image/png;base64,' + base64ImageString)
    } catch (error) {
      console.error(error)
      setZplLab('')
    }
  }

  return (
    <>
      <Modal
        open={previewOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          {zplLab ? <img src={zplLab} alt="Preview" height="500px" /> : 'Carregando...'}
        </Box>
      </Modal>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao editar!
          </Alert>
        </Snackbar>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                sx={{ width: '100%' }}
                onChange={values => {
                  setSubCompanyId(values.target.value)
                }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-input-label">Nome</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                variant="outlined"
                type="text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-textarea-label">ZPL</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={zpl}
                onChange={handleZplChange}
                id="outlined-basic"
                variant="outlined"
                type="text"
                multiline
                rows={20}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%" height={500} overflow="hidden">
              <InputLabel id="demo-simple-image-label">Pré-Visualizar</InputLabel>
              <Box
                width="100%"
                height={500}
                overflow="hidden"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {zplLab ? (
                  <img
                    src={zplLab}
                    alt="Preview"
                    height="100%"
                    style={{ cursor: 'pointer' }}
                    onClick={() => visualizarZPL()}
                  />
                ) : (
                  'Carregando...'
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center', mt: 3 }}
            >
              <InputLabel>Ativo</InputLabel>
              <Checkbox value={active} onChange={() => setActive(!active)} checked={active} />
            </Box>
          </Grid>
        </Grid>
        <Box component="form" onSubmit={editPrinter}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" onClick={handlePrintZPL}>
              Imprimir
            </Button>
            <Button variant="contained" type="submit">
              Salvar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => cancelEdit(false)}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => visualizarZPL()}
              sx={{ ml: 2 }}
            >
              Pré-visualizar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PrinterZplEdit
