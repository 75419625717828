import {
  Box,
  Card,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import {
  getDashCarretesiActual,
  getDashInventoryActual,
  getDashStockActual,
  getDashStockEntry,
  getDashStockOut,
  getDashStockTopEntry,
  getDashStockTopOut,
  getEntryOutMonth,
  getEntryOutYears,
} from '../../services/dashboardServices'
import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Context } from '../../context/AuthContext'
import CardDashboard from './components/cardDashboard'
import BarGrafic from './components/pieChartWithCustomizedLabel'
import { ArrowDownward, ArrowUpward, AttachMoney, Help, Inventory } from '@mui/icons-material'
import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { colorTableDefault } from '../TemplateDefault'
import PieChartWithCustomizedLabel from './components/pieChartWithCustomizedLabel'
import GraficItem from './components/graficItem'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const bobinas = [
  { name: 'BS', vol: 100 },
  { name: 'GY', vol: 300 },
  { name: 'Pire', vol: 50 },
  { name: 'Reforma', vol: 100 },
  { name: 'Belgo', vol: 500 },
]

const carreteis = [
  { name: 'BS', country: 'Argentina', vol: 200 },
  { name: 'GY', vol: 40 },
  { name: 'Pire', vol: 40 },
  { name: 'Belgo', vol: 50 },
]

const DasboardBobinas = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(subCompany[0].subCompanyId)
  const currentMonth = moment().startOf('month')
  const firstDay = currentMonth.format('YYYY-MM-DD')
  const lastDay = currentMonth.endOf('month').format('YYYY-MM-DD')
  const [startDate, setStartDate] = useState(firstDay)
  const [finishDate, setFinishDate] = useState(lastDay)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [quantityStockTotal, setQuantityStockTotal] = useState([])
  const [quantityStockTotalEntry, setQuantityStockTotalEntry] = useState([])
  const [quantityStockTotalOut, setQuantityStockTotalOut] = useState(0)
  const [quantityStockEntryOutTotalYear, setQuantityStockEntryOutTotalYear] = useState([])
  const [quantityStockTotalEntryMonth, setQuantityStockTotalEntryMonth] = useState([])
  const [quantityTotalYear, setQuantityTotalYear] = useState(0)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getFirstDayOfYear = () => {
    const firstDay = moment().startOf('year')
    return firstDay.format('YYYY-MM-DD')
  }

  const firstDayOfYear = getFirstDayOfYear()

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const convertToMonthName = dateString => {
    const date = parse(dateString, 'MM/yyyy', new Date())
    const monthName = format(date, 'MMMM', { locale: ptBR })
    const year = format(date, 'yyyy')
    return `${capitalizeFirstLetter(monthName)}/${year}`
  }

  const handleGraficCarreteis = async () => {
    const data = {
      SubCompanyId: subCompanyId,
      StartDate: startDate,
      FinishDate: finishDate,
      EnumMovimentations: 5,
    }

    try {
      const response = await getDashCarretesiActual(token, nameUrl, data)
      setQuantityStockTotal(response.data)
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  const handleGraficInventory = async () => {
    const data = {
      SubCompanyId: subCompanyId,
    }

    try {
      const response = await getDashInventoryActual(token, nameUrl, data)
      setQuantityStockTotalEntry(response.data)
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  const totalStockEntry = async () => {
    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
      EndDateTime: finishDate,
    }

    try {
      const response = await getDashStockEntry(token, nameUrl, data)
      const total = response.data[0].quantityTotal
      setQuantityStockTotalEntry(total)
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  const totalStockOut = async () => {
    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }

    try {
      const response = await getDashStockOut(token, nameUrl, data)
      const total = response.data[0].quantityTotal
      setQuantityStockTotalOut(total)
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  const totalEntryOutMonths = async () => {
    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }

    try {
      const response = await getEntryOutMonth(token, nameUrl, data)

      const reversedResponse = response.data.reverse()

      const dataWithMonthNames = reversedResponse.map(item => ({
        ...item,
        dateAndDay: convertToMonthName(item.dateAndDay),
      }))
      dataWithMonthNames.sort((a, b) => {
        const monthA = new Date(a.dateAndDay).getMonth()
        const monthB = new Date(b.dateAndDay).getMonth()
        return monthA - monthB
      })
      dataWithMonthNames.sort((a, b) => a.months - b.months)
      return dataWithMonthNames
    } catch (error) {
      return []
    }
  }

  const totalEntryOutYear = async () => {
    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }
    try {
      const response = await getEntryOutYears(token, nameUrl, data)

      const reversedResponse = response.data.reverse()

      const dataWithMonthNames = reversedResponse.map(item => ({
        ...item,
        dateAndDay: convertToMonthName(item.dateAndDay),
      }))

      dataWithMonthNames.sort((a, b) => a.months - b.months)
      return dataWithMonthNames
    } catch (error) {
      return []
    }
  }

  const topEntry = async () => {
    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }
    try {
      const response = await getDashStockTopEntry(token, nameUrl, data)
      const dataWithMonthNames = response.data.map(item => ({
        ...item,
        dateAndDay: convertToMonthName(item.dateAndDay),
      }))
      return dataWithMonthNames
    } catch (error) {
      console.error(error)
    }
  }

  const topOut = async () => {
    const data = {
      SubCompanyId: subCompanyId,
      CreateDateTime: startDate,
      EndDateTime: finishDate,
    }
    try {
      const response = await getDashStockTopOut(token, nameUrl, data)
      const dataWithMonthNames = response.data.map(item => ({
        ...item,
        dateAndDay: convertToMonthName(item.dateAndDay),
      }))

      return dataWithMonthNames.map((item, index) => ({
        name: item.name,
        dateAndDay: item.dateAndDay,
        quantityOut: item.quantityTotal,
      }))
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box sx={{ mt: 7.5 }}>
      <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Bobinas</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }}>
          <Help />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
        }}
        flexDirection={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
      >
        <Box sx={{ mt: 0 }} width={{ xs: '100%', sm: '100%', md: '100%', lg: '45%', xl: '45%' }}>
          <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
            Filial
          </InputLabel>
          <Select
            style={{ backgroundColor: '#fff' }}
            onChange={e => setSubCompanyId(e.target.value)}
            name="companySelect"
            sx={{ width: '100%' }}
            value={subCompanyId ? subCompanyId : ''}
          >
            {subCompany &&
              subCompany.map((item, index) => {
                return (
                  <MenuItem value={item.subCompanyId} key={index}>
                    {item.name}
                  </MenuItem>
                )
              })}
          </Select>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
          }}
          width={{ xs: '100%', sm: '100%', md: '100%', lg: '45%', xl: '45%' }}
        >
          <Box sx={{ mt: 2, width: '45%' }}>
            <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
              Data de criação
            </InputLabel>
            <TextField
              style={{ backgroundColor: '#fff' }}
              variant="outlined"
              value={startDate}
              onChange={values => {
                setStartDate(values.target.value)
              }}
              sx={{ width: '100%' }}
              name="createDate"
              type="date"
              inputProps={{
                max: finishDate,
              }}
            />
          </Box>
          <Box sx={{ mt: 2, width: '45%' }}>
            <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
              Data final
            </InputLabel>
            <TextField
              style={{ backgroundColor: '#fff' }}
              variant="outlined"
              value={finishDate}
              onChange={values => setFinishDate(values.target.value)}
              sx={{ width: '100%' }}
              name="endDate"
              type="date"
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
          mt: 4,
        }}
        display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <GraficItem
              subCompanyId={subCompanyId}
              startDate={startDate}
              finishDate={finishDate}
              getGrafic={handleGraficInventory}
              title={'Inventário online de bobinas'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PieChartWithCustomizedLabel
              subCompanyId={subCompanyId}
              startDate={startDate}
              finishDate={finishDate}
              getGrafic={handleGraficCarreteis}
              title={'Carretéis armazenado no cliente (dias)'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card sx={{ width: '100%', height: '400px' }}>
              <TableContainer
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Table sx={{ width: '100%' }} aria-label="customized table">
                  <TableHead>
                    <TableRow sx={{ bgcolor: '#F4F4F6' }}>
                      <TableCell align="left" width="200px" sx={{ fontWeight: 700 }}>
                        Nome do Cliente
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 700 }}>
                        Inventário online de bobinas
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quantityStockTotalEntry
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">{row.clientName}</TableCell>
                          <TableCell align="center">{row.quantity}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={bobinas.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
              Carretéis armazenado no cliente (dias)
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card sx={{ width: '100%', height: '400px' }}>
              <TableContainer
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Table sx={{ width: '100%' }} aria-label="customized table">
                  <TableHead>
                    <TableRow sx={{ bgcolor: '#F4F4F6' }}>
                      <TableCell align="left" width="200px" sx={{ fontWeight: 700 }}>
                        Nome do Cliente
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 700 }}>
                        Carretéis armazenado no cliente (dias)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quantityStockTotal
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">{row.clientName}</TableCell>
                          <TableCell align="center">{row.quantity}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={quantityStockTotal.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default DasboardBobinas
