import React, { useContext, useEffect } from 'react'
import { useCallback, useState } from 'react'
import { createParamGroupService } from '../../../services/admServices'
import { v4 as uuidv4 } from 'uuid'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'
import { createSegmentGroup, getSegmentList } from '../../../services/permission'

const FunctionsPositionsCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
}) => {
  const token = localStorage.getItem('token')
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [selectSegment, setSelectSegment] = useState([])
  const [segmentSelect, setSegmentSelect] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const [positionIdCounter, setPositionIdCounter] = useState(() => {
    const savedCounter = localStorage.getItem('positionIdCounter')
    return savedCounter ? parseInt(savedCounter) : 1
  })
  const createFunction = async e => {
    e.preventDefault()
    const storedData = localStorage.getItem('segmentCategoryRole')
    if (storedData) {
      const dataArray = JSON.parse(storedData)
      const foundSegment = dataArray.find(item => item.companyId === subCompany[0].companyId)
      console.log(dataArray, 'dataArray')
      try {
        const data = {
          groupId: uuidv4(),
          categoryId: foundSegment.categoryId,
          companyId: subCompany[0].companyId,
          groupName: name,
          descriptionGroup: description,
          segmentCategoryRole: foundSegment.categoryRoles,
        }

        console.log(data, 'date')

        const storedData = localStorage.getItem('group')

        if (storedData) {
          const existingData = JSON.parse(storedData)
          existingData.push(data)
          localStorage.setItem('group', JSON.stringify(existingData))
        } else {
          localStorage.setItem('group', JSON.stringify([data]))
        }

        console.log(data)
      } catch (error) {}

      return foundSegment // Retorna o objeto encontrado ou null se não for encontrado
    }
    return null
  }

  const selectedSegment = async () => {
    try {
      const response = await getSegmentList(token)
      setSelectSegment(response.data.result)
    } catch (error) {
      setError(true)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao criar, verifique os dados e tente novamente.')
    }
  }

  useEffect(() => {}, [subCompanyId])

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createFunction}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box width="100%">
                <TextField
                  sx={{ width: '100%' }}
                  value={name}
                  onChange={values => setName(values.target.value)}
                  id="outlined-basic"
                  label="Nome"
                  variant="outlined"
                  type="text"
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box width="100%">
                <TextField
                  sx={{ width: '100%' }}
                  value={description}
                  onChange={values => setDescription(values.target.value)}
                  id="outlined-basic"
                  label="Descrição"
                  variant="outlined"
                  type="text"
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          ></Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default FunctionsPositionsCreate
