import React, { useContext, useEffect } from 'react'
import { useCallback, useState } from 'react'
import { createParamGroupService } from '../../../services/admServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const ListUserCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const token = localStorage.getItem('token')
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [positionFunction, setPositionFunction] = useState([])
  const [selectedPositionFunction, setSelectedPositionFunction] = useState(null)
  const [positionName, setPositionName] = useState('')
  const [login, setLogin] = useState('')
  const [collaboratorIdCounter, setCollaboratorIdCounter] = useState(() => {
    const savedCounter = localStorage.getItem('collaboratorIdCounter')
    return savedCounter ? parseInt(savedCounter) : 1
  })

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const createFunction = async e => {
    e.preventDefault()
    const position = positionFunction.find(objeto => objeto.positionId === selectedPositionFunction)
    const newFunction = {
      subCompanyId: subCompanyId,
      id: collaboratorIdCounter,
      position: {
        positionId: selectedPositionFunction,
        positionName: position.name,
      },
      name: name,
      login: login,
      createdAt: new Date(),
    }
    console.log(newFunction, 'neww')
    try {
      if (name === '' || login === '' || selectedPositionFunction === '' || subCompanyId === '') {
        setAlerta(true)
        setSeverity('error')
        setTitle('Preencha os campos obrigatorio!')
      } else {
        const existingData = localStorage.getItem('collaborator')
        let functions = []

        if (existingData) {
          functions = JSON.parse(existingData)
        }
        handleFetchData({ target: { value: subCompanyId } })
        functions.push(newFunction)
        localStorage.setItem('collaborator', JSON.stringify(functions))
        setExpanded(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Função/Cargo criado com sucesso!')

        setName('')
        setDescription('')
        setError(false)
        setCollaboratorIdCounter(prevCounter => {
          const newCounter = prevCounter + 1
          localStorage.setItem('positionIdCounter', newCounter.toString())
          return newCounter
        })
      }

      // Move the handleData call here, after updating the localStorage
    } catch (error) {
      console.log(error)
      setError(true)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao criar, verifique os dados e tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const handleFunctionPosition = () => {
    try {
      const response = localStorage.getItem('functionsFilial')
      const funcoesPorFilial = response ? JSON.parse(response) : []
      const objetoEncontrado = funcoesPorFilial.filter(objeto => objeto.id === subCompanyId)
      setPositionFunction(objetoEncontrado)
      console.log(positionFunction, 'neww')
    } catch (error) {}
  }

  useEffect(() => {
    handleFunctionPosition()
  }, [subCompanyId])

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createFunction}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Selecione a Filial</InputLabel>
                <Select
                  value={subCompanyId}
                  sx={{ width: '100%' }}
                  label="Selecione a Filial"
                  onChange={e => {
                    setSubCompanyId(e.target.value)
                    handleFunctionPosition()
                  }}
                >
                  {subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Selecione o Função/Cargo</InputLabel>
                <Select
                  sx={{ width: '100%' }}
                  label="Selecione o Função/Cargo"
                  onChange={e => setSelectedPositionFunction(e.target.value)}
                >
                  {positionFunction.length === 0 ? (
                    <MenuItem value="">Carregando... </MenuItem>
                  ) : (
                    positionFunction.map((item, index) => {
                      return (
                        <MenuItem value={item.positionId} key={item.id}>
                          {item.name}
                        </MenuItem>
                      )
                    })
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Box width="100%">
                <TextField
                  sx={{ width: '100%' }}
                  value={name}
                  onChange={values => setName(values.target.value)}
                  id="outlined-basic"
                  label="Nome"
                  variant="outlined"
                  type="text"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box width="100%">
                <TextField
                  sx={{ width: '100%' }}
                  value={login}
                  onChange={values => setLogin(values.target.value)}
                  id="outlined-basic"
                  label="Login"
                  variant="outlined"
                  type="text"
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          ></Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ListUserCreate
