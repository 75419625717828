import React, { useContext, useEffect } from 'react'
import { useCallback, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  deleteAlocationStockService,
  getAlocationStockGetAll,
  getAlocationStockService,
} from '../../../services/alocationServices'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { setPermission } from '../../../permissions/userPermissions'
import * as XLSX from 'xlsx'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AlocationStockCreate from '../../Create/AlocationStockCreate'
import AlocationStockEdit from '../../Edit/AlocationStockEdit'

//icones react-icons
import { AiFillFileExcel } from 'react-icons/ai'
import { AiFillPlusCircle } from 'react-icons/ai'

import HelpIcon from '@mui/icons-material/Help'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import {
  AlocationPartsCREATE,
  AlocationStockDELETE,
  AlocationStockEDIT,
} from '../../../const/RoleConstants'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const AlocationStock = () => {
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const token = localStorage.getItem('token')
  const [open, setOpen] = useState(false)
  const [alocationDelete, setAlocationDelete] = useState()
  const [alocation, setAlocation] = useState([])
  const [filtered, setFiltered] = useState([])
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [alocationStockId, setAlocationStockId] = useState('')

  const [permissionAlocationStockCREATE, setPermissionAlocationStockCREATE] = useState(false)
  const [permissionAlocationStockEDIT, setPermissionAlocationStockEDIT] = useState(false)
  const [permissionAlocationStockDELETE, setPermissionAlocationStockDELETE] = useState(false)

  const vertical = 'top'
  const horizontal = 'center'
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)

  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const requestSearch = searched => {
    const bihandsArray = filtered.filter(item => item.address.includes(searched))
    const otherArray = filtered.filter(item => item.subCompanyName.includes(searched))

    setAlocation(bihandsArray)
  }

  const getAlocationStock = async id => {
    setSubCompanyId(id)
    setLoading(true)
    if (id) {
      const response = await getAlocationStockService(token, nameUrl, id, subCompany[0].companyId)
      setAlocation(response.data)
      setFiltered(response.data)
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (subCompanyId) {
      getAlocationStock(subCompanyId)
    }
  }, [subCompanyId])

  const getAlocation = async () => {
    setLoading(true)
    if (subCompanyId) {
      const response = await getAlocationStockService(
        token,
        nameUrl,
        subCompanyId,
        subCompany[0].companyId
      )
      setAlocation(response.data)
      setFiltered(response.data)
      setLoading(false)
    }
    setLoading(false)
  }

  const deleteAlert = data => {
    setOpen(true)
    setAlocationDelete(data)
  }

  const handleDeleteAlocation = async () => {
    try {
      await deleteAlocationStockService(token, nameUrl, alocationDelete)
      setOpen(false)
      getAlocationStock(subCompanyId)
    } catch (e) {
      console.log(e)
    }
  }

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    const item = {
      subCompanyId: subCompanyId,
      limit: 1000,
      offset: 0,
    }
    try {
      const response = await getAlocationStockGetAll(token, nameUrl, item)
      MountDoc(response.data.total)
    } catch (e) {
      console.log(e)
      setDownloading(false)
    }
  }

  const MountDoc = async pages => {
    console.log(pages, 'pages')
    setDownloadMessage('Inicializando')
    setDownloading(true)
    let result = []

    for (let i = 0; i <= 1; i++) {
      setDownloadMessage(`Baixando ${i} de ${alocation.length}...`)
      setProgress((i / alocation.length) * 100)
      const item = {
        subCompanyId: subCompanyId,
        limit: 500,
        offset: i,
      }
      console.log(item)
      const response = await getAlocationStockGetAll(token, nameUrl, item)
      result = result.concat(response.data.result)
    }
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    const relatorioFinal = []
    alocation.map(item => {
      relatorioFinal.push({
        Rua: item.street,
        Coluna: item.colum,
        Serial: item.serial,
        Nivel: item.level,
        Filial: item.subCompanyName,
        Name: item.name,
        Endereço: item.address,
        Ativo: item.active ? 'Sim' : 'Não',
        Criado:
          new Date(item.created).toLocaleDateString() +
          'às' +
          new Date(item.created).toLocaleTimeString(),
      })
    })
    setProgress(0)
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Alocação de peças')
    XLSX.writeFile(workbook, `Alocação de peças.xlsx`)
  }

  const handleClose = () => {
    setError(false)
    setOpen(false)
    setAlerta(false)
    setEditing(false)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const getPermissions = useCallback(async () => {
    setPermissionAlocationStockCREATE(setPermission(userRoles, AlocationPartsCREATE))
    setPermissionAlocationStockEDIT(setPermission(userRoles, AlocationStockEDIT))
    setPermissionAlocationStockDELETE(setPermission(userRoles, AlocationStockDELETE))
  }, [])

  useEffect(() => {
    getPermissions()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleSelectEdit = id => {
    setExpanded(true)
    setAlocationStockId(id)
    setEditing(true)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          //key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Erro na consulta, tente novamente mais tarde
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box width={{ xs: '90%', sm: '90%', md: '50%', lg: '50%', xl: '50%' }}>
          <Dialog
            open={downloading}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            <Box
              sx={{
                height: '10rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyConent: 'center',
              }}
              width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
            >
              <DialogTitle sx={{ width: '100%' }}>{downloadMessage}</DialogTitle>
              <LinearProgressWithLabel sx={{ width: '90%' }} value={progress} />
            </Box>
          </Dialog>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDeleteAlocation()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <ViewElearning
          pageName={'AlocationStock'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Locação de Produtos</Typography>{' '}
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        {/* Criar Alocação */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            {editing ? (
              <Typography>Editando Locação</Typography>
            ) : (
              <Typography>Cadastrar Locação</Typography>
            )}
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="">
                <AlocationStockEdit
                  editing={editing}
                  setEditing={setEditing}
                  alerta={alerta}
                  setAlerta={setAlerta}
                  severity={severity}
                  setSeverity={setSeverity}
                  title={title}
                  setTitle={setTitle}
                  getAlocation={getAlocation}
                  subCompanyId={subCompanyId}
                  alocationId={alocationStockId}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="">
                <AlocationStockCreate
                  expanded={expanded}
                  setExpanded={setExpanded}
                  alerta={alerta}
                  setAlerta={setAlerta}
                  severity={severity}
                  setSeverity={setSeverity}
                  title={title}
                  setTitle={setTitle}
                  getAlocation={getAlocation}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            getAlocationStock(e.target.value)
          }}
        />
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            {alocation.length > 0 && (
              <Button
                className="buttonDocs"
                onClick={downloadData}
                variant="contained"
                endIcon={<AiFillFileExcel />}
              >
                Excel
              </Button>
            )}
          </Box>
          <PermissionComponent role="">
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
            >
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </Box>
          </PermissionComponent>
        </Box>
        <TextField
          variant="outlined"
          placeholder="Procurar por Endereço"
          type="search"
          onInput={e => requestSearch(e.target.value)}
        />
        {alocation.length > 0 && (
          <>
            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Typography mb={1}>
                {alocation.length > 1
                  ? `${alocation.length} Resultados`
                  : `${alocation.length} Resultado`}
              </Typography>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell> </StyledTableCell>
                    <StyledTableCell> </StyledTableCell>
                    <StyledTableCell sx={{ pl: 1 }} align="left">
                      Filial
                    </StyledTableCell>
                    <StyledTableCell sx={{ pl: 1 }} align="left">
                      Endereço
                    </StyledTableCell>
                    <StyledTableCell sx={{ pl: 1 }} align="left">
                      Rua
                    </StyledTableCell>
                    <StyledTableCell sx={{ pl: 1 }} align="left">
                      Andar
                    </StyledTableCell>
                    <StyledTableCell sx={{ pl: 1 }} align="left">
                      Coluna
                    </StyledTableCell>
                    <StyledTableCell sx={{ pl: 1 }} align="left">
                      Serial
                    </StyledTableCell>
                    <StyledTableCell sx={{ pl: 1 }} align="left">
                      Ativo
                    </StyledTableCell>
                    <StyledTableCell sx={{ pl: 1 }} align="left">
                      Criado
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {alocation
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <PermissionComponent role="">
                          <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                            <IconButton>
                              <ModeEditOutlineIcon
                                onClick={() => handleSelectEdit(row.alocationStockId)}
                              />
                            </IconButton>
                          </StyledTableCell>
                        </PermissionComponent>
                        <PermissionComponent role="">
                          <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                            <IconButton onClick={() => deleteAlert(row.alocationStockId)}>
                              <DeleteForeverIcon />
                            </IconButton>
                          </StyledTableCell>
                        </PermissionComponent>

                        <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                        <StyledTableCell align="left">{row.address}</StyledTableCell>
                        <StyledTableCell align="left">{row.street}</StyledTableCell>
                        <StyledTableCell align="left">{row.level}</StyledTableCell>
                        <StyledTableCell align="left">{row.column}</StyledTableCell>
                        <StyledTableCell align="left">{row.serial}</StyledTableCell>
                        <StyledTableCell align="left">{row.active ? 'Sim' : 'Não'}</StyledTableCell>
                        <StyledTableCell align="left">
                          {new Date(row.created).toLocaleDateString()} às{' '}
                          {new Date(row.created).toLocaleTimeString()}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={alocation.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default AlocationStock
