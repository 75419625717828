import React, { useContext, useCallback } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../context/AuthContext'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  Typography,
  Grid,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { colorTableDefault } from '../../TemplateDefault'
import { getUniqueTransferOrderService } from '../../../services/requestServices'
import { getBreakInfosService, getMovBreakListService } from '../../../services/movBreakServices'
import Fracionado from './components/fracionado'
import Naofrac from './components/naofrac'
import PermissionComponent from '../../../permissions'

const MovimentationBreakEdit = () => {
  const { userRoles, nameUrl } = useContext(Context)
  const params = useParams()
  const movimentationOrderId = params.id

  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)

  const [fracionado, setFracionado] = useState(false)
  const [movOrder, setMovOrder] = useState([])
  const [productName, setProductName] = useState('')
  const [clientName, setClientName] = useState('')
  const [totalQtd, setTotalQtd] = useState({ result: 0, parsed: 'Carregando' })
  const [permissionClientView, setPermissionClientView] = useState(false)

  const navigate = useNavigate()

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const getMovOrder = async () => {
    if (!movimentationOrderId || movimentationOrderId === '') {
      return
    }

    try {
      const response = await getUniqueTransferOrderService(token, nameUrl, movimentationOrderId)
      setMovOrder(response.data)
      console.log(response.data, 'datas')

      setProductName(response.data.movimentationERPitemsCollections[0].productName)
    } catch (e) {
      console.log(e)
    }
  }

  const getMovBreak = async () => {
    if (!movimentationOrderId || movimentationOrderId === '') {
      return
    }
    try {
      const response = await getMovBreakListService(token, nameUrl, movimentationOrderId)
      if (response.data.length > 0) {
        const finalFrac = response.data.sort((a, b) =>
          // a.movimentationOrderNumberOtherSystemBreak -
          // b.movimentationOrderNumberOtherSystemBreak
          a.movimentationOrderNumberOtherSystemBreak.localeCompare(
            b.movimentationOrderNumberOtherSystemBreak,
            undefined,
            { numeric: false, sensitivity: 'base' }
          )
        )
        //setFracionado(response.data);
        console.log(finalFrac, 'finalFracfinalFrac')
        setFracionado(finalFrac)
        console.log(response.data, 'testes')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getBreakInfo = async () => {
    if (!movimentationOrderId || movimentationOrderId === '') {
      return
    }
    try {
      const response = await getBreakInfosService(token, nameUrl, movimentationOrderId)
      const tipoCaixa = response.data[0].typePiece === 0 ? 'produtos' : 'caixas'
      setTotalQtd({
        result: response.data[0].result,
        parsed: `${response.data[0].result} ${tipoCaixa}`,
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getMovOrder()
    getMovBreak()
    getBreakInfo()
  }, [])

  return (
    <>
      <Box sx={{ mt: 7.5, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Ordem de Produção</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormControl sx={{ width: 1 }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={
                    movOrder.movimentationOrderNumberBihands
                      ? movOrder.movimentationOrderNumberBihands
                      : ''
                  }
                  id="outlined-basic"
                  variant="outlined"
                  label="Número Ordem Bihands"
                  type="text"
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl sx={{ width: 1 }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={
                    movOrder.movimentationOrderNumberOtherSystem
                      ? movOrder.movimentationOrderNumberOtherSystem
                      : ''
                  }
                  id="outlined-basic"
                  variant="outlined"
                  label="Número Ordem (outro sistema)"
                  type="text"
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <PermissionComponent role="PRODUCAO">
                <FormControl sx={{ width: 1 }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={movOrder.clientName ? movOrder.clientName : ''}
                    id="outlined-basic"
                    variant="outlined"
                    label="Cliente"
                    type="text"
                    disabled
                  />
                </FormControl>
              </PermissionComponent>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl sx={{ width: 1 }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={movOrder.productName ? movOrder.productName : ''}
                  id="outlined-basic"
                  variant="outlined"
                  label="Produto"
                  type="text"
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ my: 2 }} />
        {fracionado ? (
          <Fracionado
            movOrder={movOrder}
            totalQtd={totalQtd}
            getMovBreak={getMovBreak}
            fracionado={fracionado}
            StyledTableCell={StyledTableCell}
            StyledTableRow={StyledTableRow}
          />
        ) : (
          <Naofrac movOrder={movOrder} totalQtd={totalQtd} getMovBreak={getMovBreak} />
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
            sx={{ mt: '2rem', alignItems: 'center' }}
          >
            <Button
              variant="contained"
              sx={{ width: '100%' }}
              type="button"
              onClick={() => navigate('/MovBreak')}
            >
              Voltar
            </Button>
          </Box>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default MovimentationBreakEdit
