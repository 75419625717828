export const returnStatus = st => {
  // All = -99,
  if (st === -99) return 'Todos'
  // Draft = -1,
  if (st === -1) return 'Rascunho'
  // Open = 1,
  if (st === 1) return 'Aberto'
  // Waiting_Conference = 0,
  if (st === 0) return 'Aguardando conferência'
  // In_Process = 5,
  if (st === 5) return 'Em processo'
  // Completed = 2,
  if (st === 2) return 'Completado'
  // Na_Fila = 4,
  if (st === 4) return 'Na fila'
  // Importado = 3,
  if (st === 3) return 'Importado'
  // Cancel = -2,
  if (st === -2) return 'Cancelado'
  // Divergence = - 3,
  if (st === -3) return 'Com divergência'
  // Apagado = -99
  if (st === -99) return 'Apagado'
}
