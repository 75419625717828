import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  createProductAssociationService,
  searchProductService,
} from '../../../services/productService'
//import TableData from "./tables";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import HelpIcon from '@mui/icons-material/Help'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
  Grid,
} from '@mui/material'
import { styled } from '@mui/system'
import PropTypes from 'prop-types'

//icones react-icons
import PermissionComponent from '../../../permissions'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import { Add, Clear, Save } from '@mui/icons-material'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const Association = () => {
  const { subCompany, userId, nameUrl } = useContext(Context)

  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [subCompanyName, setSubCompanyName] = useState('')
  const [serial, setSerial] = useState('')
  const [validDate, setValidDate] = useState('')

  const token = localStorage.getItem('token')
  const [open, setOpen] = useState(false)
  const [productAssociation, setProductAssociation] = useState([])

  const [totalProducts, setTotalProducts] = useState(0)

  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [productName, setProductName] = useState('')
  const [alocationStockId, setAlocationStockId] = useState('')
  const [productId, setProductId] = useState('')
  const [barcode, setBarcode] = useState('')
  const [lote, setLote] = useState('')
  const [factionId, setFactionId] = useState('')
  const [laboratory, setLaboratory] = useState('')
  const [depositId, setDeposityId] = useState('')
  const [dataExpiration, setDataExpiration] = useState('')
  const [op, setOp] = useState('')
  const [opPrincipal, setOpPrincipal] = useState('')
  const [products, setProducts] = useState({})
  const [error, setError] = useState(false)
  const [alocation, setAlocation] = useState([])
  const [faction, setFaction] = useState([])
  const [deposit, setDeposit] = useState([])

  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [produtos, setProdutos] = useState([])
  const [produtosList, setProdutosList] = useState([])
  const [singleProd, setSingleProd] = useState()

  //variaveis crud
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          setProdutosList(response.data.result)
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  const changeProd = id => {
    const prod = produtosList.find(x => x.productId === id)
    const finalProd = prod
    console.log(finalProd, 'asdsa')
    setProductId(finalProd.productId)
    setProducts(finalProd)
    setSingleProd(finalProd)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
      wordBreak: 'break-word',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  const addProduct = () => {
    const isProductValid = productId !== ''

    if (!isProductValid) {
      setLoading(false)
      setTitle('Campo Produto Obrigatório!')
      setSeverity('error')
      setAlerta(true)
      return
    }
    const data = {
      subCompanyId: subCompanyId,
      subCompanyName: subCompanyName,
      productId: productId,
      productName: products.name,
      alocationStockId: alocationStockId,
      factionId: factionId,
      barcode: barcode,
      lote: lote,
      laboratory: laboratory,
      serial: serial,
      dataExpiration: dataExpiration,
    }

    setProductAssociation([...productAssociation, data])
  }

  useEffect(() => {}, [subCompanyName])

  const removeProduct = index => {
    setProductAssociation(productAssociation.filter((x, i) => i !== index))
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  }

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }
  const validateProduct = () => {
    if (productId === '') {
      return false
    } else {
      return true
    }
  }

  const createProductAssociation = async () => {
    setLoading(true)
    setError(false)

    try {
      await Promise.all(
        productAssociation.map(async item => {
          console.log(item)
          let association = {
            productName: item.productName,
            subCompanyId: item.subCompanyId,
            productId: item.productId.productId,
            barcode: item.barcode,
            lote: item.lote,
            serial: item.serial,
            laboratory: item.laboratory,
            dataExpiration: item.dataExpiration,
            userId: userId,
            op: '',
            opPrincipal: '',
          }

          const response = await createProductAssociationService(
            token,
            nameUrl,
            nameUrl,
            association
          )

          setTitle(response.data.message)
          setSeverity('success')
          setLoading(false)

          setAlerta(true)

          setProductId('')
          setSerial('')
          setBarcode('')
          setLote('')
          setLaboratory('')
          setDataExpiration('')
          setSubCompanyId('')
          setOp('')
          setOpPrincipal('')
          setDeposityId('')
          setFactionId('')
          setProductName('')
          setAlocationStockId('')
        })
      )
    } catch (err) {
      setTitle('Erro ao cadastrar, tente novamente!')
      setSeverity('error')
      setLoading(false)
      setAlerta(true)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Box width={{ xs: '90%', sm: '90%', md: '50%', lg: '50%', xl: '50%' }}>
          <Dialog open={downloading} aria-describedby="alert-dialog-description">
            <Box
              sx={{
                height: '10rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyConent: 'center',
              }}
              width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
            >
              <DialogTitle>{downloadMessage}</DialogTitle>
              <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
            </Box>
          </Dialog>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>

        <ViewElearning
          pageName={'ProductAssociation'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Associação de Produtos</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box component="form" onSubmit={createProductAssociation} sx={{ mt: '2rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <SelectSubCompany
                value={subCompanyId}
                onChange={values => {
                  const selectedSubCompany = subCompany.find(
                    item => item.subCompanyId === subCompanyId
                  )
                  if (selectedSubCompany) {
                    const subCompanyName = selectedSubCompany.name
                    setSubCompanyName(subCompanyName)
                  }
                  setSubCompanyId(values.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <Autocomplete
                  disablePortal
                  id="lista-produtos"
                  options={produtos}
                  //sx={{ width: 300 }}
                  loading={searching}
                  loadingText="Procurando..."
                  noOptionsText="Nenhum produto encontrado"
                  isOptionEqualToValue={(option, value) => option.productId === value.value}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.productId}>
                        {option.label}
                      </li>
                    )
                  }}
                  renderInput={params => <TextField {...params} label="Procurar produto" />}
                  onInputChange={(e, v) => {
                    setSearch(v)
                  }}
                  onChange={(e, v) => {
                    if (v) {
                      changeProd(v.value)
                    }
                  }}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: '100%', mt: '1rem' }}
                  value={productName}
                  id="standard-basic"
                  label="Nome do Produto"
                  variant="standard"
                  onChange={values => setProductName(values.target.value)}
                  type="text"
                />
              </Grid> */}
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Alocação</InputLabel>
                <Select
                  value={alocationStockId}
                  label="Selecione o produto"
                  onChange={values => setAlocationStockId(values.target.value)}
                >
                  {alocation.map(item => {
                    return <MenuItem value={item.alocationStockId}>{item.address}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Fábrica</InputLabel>
                <Select
                  value={factionId}
                  label="Selecione o fabrica"
                  onChange={values => setFactionId(values.target.value)}
                >
                  {faction.map(item => {
                    return <MenuItem value={item.factionId}>{item.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Depósito</InputLabel>
                <Select
                  value={depositId}
                  label="Selecione o depósito"
                  onChange={values => setDeposityId(values.target.value)}
                >
                  {deposit.map(item => {
                    return <MenuItem value={item.depositId}>{item.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={barcode}
                id="standard-basic"
                label="Código de Barras"
                variant="outlined"
                onChange={values => setBarcode(values.target.value)}
                type="text"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={lote}
                id="standard-basic"
                label="Lote"
                variant="outlined"
                onChange={values => setLote(values.target.value)}
                type="text"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {' '}
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={laboratory}
                id="standard-basic"
                label="Laboratório"
                variant="outlined"
                onChange={values => setLaboratory(values.target.value)}
                type="text"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label" sx={{ mt: '1rem' }}>
                Data de Validade
              </InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={dataExpiration}
                id="standard-basic"
                //label="Data de Validade"
                variant="outlined"
                onChange={values => setDataExpiration(values.target.value)}
                type="date"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: '100%', mt: '2.5rem' }}
                value={serial}
                id="standard-basic"
                label="Serial"
                variant="outlined"
                onChange={values => setSerial(values.target.value)}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'revert' },
                  justifyContent: 'space-between',
                  alignItems: 'end',
                  width: '100%',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    sx={{ width: { xs: '100%', md: '180px' } }}
                    onClick={() => addProduct()}
                    endIcon={<Add />}
                  >
                    Adicionar
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: { xs: '100%', md: '180px' },
                      ml: { xs: 0, md: 2 },
                      my: { xs: 2, md: 0 },
                    }}
                    endIcon={<Clear />}
                  >
                    Limpar Tabela
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  sx={{ width: { xs: '100%', md: '180px' } }}
                  onClick={createProductAssociation}
                  endIcon={<Save />}
                >
                  Salvar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', gap: 2 }}></Box>

        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell> </StyledTableCell>
                <StyledTableCell align="left">Filial</StyledTableCell>
                <StyledTableCell align="left">Nome do produto</StyledTableCell>
                <StyledTableCell align="left">Alocação</StyledTableCell>
                <StyledTableCell align="left">Depósito</StyledTableCell>
                <StyledTableCell align="left">Codigo de barras</StyledTableCell>
                <StyledTableCell align="left">Lote</StyledTableCell>
                <StyledTableCell align="left">Laboratório</StyledTableCell>
                <StyledTableCell align="left">Data de validade</StyledTableCell>
                <StyledTableCell align="left">Serial</StyledTableCell>
              </TableRow>
            </TableHead>
            {productAssociation.length > 0 && (
              <>
                <TableBody>
                  {productAssociation
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                          <PermissionComponent role="ProductAssociationDELETE">
                            <Tooltip title="Apagar" arrow>
                              <IconButton onClick={() => removeProduct(index)}>
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                        </StyledTableCell>

                        <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                        <StyledTableCell align="left">{row.productName}</StyledTableCell>
                        <StyledTableCell align="left">{row.alocationStockId}</StyledTableCell>
                        <StyledTableCell align="left">{row.factionId}</StyledTableCell>
                        <StyledTableCell align="left">{row.barcode}</StyledTableCell>
                        <StyledTableCell align="left">{row.lote}</StyledTableCell>
                        <StyledTableCell align="left">{row.laboratory}</StyledTableCell>
                        <StyledTableCell align="left">{row.dataExpiration}</StyledTableCell>
                        <StyledTableCell align="left">{row.serial}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalProducts}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default Association
