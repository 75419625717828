import { useCallback, useContext, useEffect, useState } from 'react'
import { createMovimentationOrderService } from '../../../services/productService'
import { Context } from '../../../context/AuthContext'
import { getAllReasonService } from '../../../services/admServices'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@mui/material'

const OutStockOrderCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const token = localStorage.getItem('token')
  const [outStockOrderNumberOtherSystem, setOutStockOrderOtherSystem] = useState('')
  const [reason, setReason] = useState([])
  const [reasonId, setReasonId] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const getReason = useCallback(async () => {
    try {
      const response = await getAllReasonService(token, nameUrl)
      setReason(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [reason])

  const createOutStockOrder = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      const outStockOrder = {
        reasonfororderId: reasonId,
        typeOrder: 8,
        movimentationOrderNumberOtherSystem: outStockOrderNumberOtherSystem,
        subCompanyIdFrom: subCompanyId,
        subCompanyIdTo: subCompanyId,
      }

      try {
        await createMovimentationOrderService(token, nameUrl, outStockOrder)
        setSeverity('success')
        setTitle('Saída cadastrada com sucesso!')
        setExpanded(false)
        setAlerta(true)
        setLoading(false)
        handleFetchData()
      } catch (err) {
        console.log(err)
        setSeverity('error')
        setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
        setAlerta(true)
        setLoading(false)
      }
    },
    [outStockOrderNumberOtherSystem, subCompanyId, reasonId]
  )

  useEffect(() => {
    getReason()
  }, [])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao criar o Pedido de Expedição!
          </Alert>
        </Snackbar>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createOutStockOrder}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Filial Saída"
                value={subCompanyId}
                onChange={values => setSubCompanyId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={outStockOrderNumberOtherSystem}
                onChange={values => setOutStockOrderOtherSystem(values.target.value)}
                id="outlined-basic"
                label="Número de Ordem (Outro sistema)"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          {reason.length > 0 && (
            <Box
              sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <InputLabel id="demo-simple-select-label">Razão do Pedido</InputLabel>
                <Select
                  label="Filial Entrada"
                  value={reasonId}
                  onChange={values => setReasonId(values.target.value)}
                  sx={{ width: '100%' }}
                >
                  {reason.map((item, index) => {
                    return (
                      <MenuItem value={item.reasonfororderId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
            </Box>
          )}

          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default OutStockOrderCreate
