import { useCallback, useContext, useEffect, useState } from 'react'
import {
  editIntegrationCredencialService,
  getAllIntegrationFieldsService,
  getUniqueIntegrationCredencialService,
} from '../../../services/configServices'
import { getIntegrationDefaultService } from '../../../services/admServices'
import { Context } from '../../../context/AuthContext'

import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

const IntegrationCredencialEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  integrationCredencialId,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [tokenType, setTokenType] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [clientToken, setClientToken] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [refreshToken, setRefreshToken] = useState('')
  const [expiresIn, setExpiresIn] = useState('')
  const [appId, setAppId] = useState('')
  const [active, setActive] = useState(false)
  const [createDateTime, setCreateDateTime] = useState('')
  const [integrationDefault, setIntegrationDefault] = useState([])
  const [integrationDefaultId, setIntegrationDefaultId] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [error, setError] = useState(false)
  const [allLoaded, setAllLoaded] = useState(false)
  const [integrationFields, setIntegrationFields] = useState([])
  const [loading, setLoading] = useState(false)

  const initialFields = {
    tokenType: false,
    name: false,
    email: false,
    userName: false,
    password: false,
    accessToken: false,
    clientToken: false,
    clientSecret: false,
    refreshToken: false,
    expiresIn: false,
    appId: false,
  }
  const [saveFields, setSaveFields] = useState(initialFields)

  const editIntegrationCredencial = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const credencial = {
        tokenType: tokenType,
        name: name,
        email: email,
        userName: userName,
        password: password,
        accessToken: accessToken,
        clientToken: clientToken,
        clientSecret: clientSecret,
        refreshToken: refreshToken,
        expiresIn: expiresIn,
        appId: appId,
        active: active,
        createdateTime: createDateTime,
        integrationDefaultId: integrationDefaultId,
        companyId: companyId,
        integrationCredencialId: integrationCredencialId,
      }

      try {
        editIntegrationCredencialService(token, nameUrl, credencial)
        setSeverity('success')
        setTitle('Integração editada com sucesso!')
        setEditing(false)
        setAlerta(true)
        setLoading(false)
        handleFetchData()
      } catch (err) {
        console.log(err)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente')
        setAlerta(true)
        setLoading(false)
      }
    },
    [
      tokenType,
      name,
      email,
      userName,
      password,
      accessToken,
      clientToken,
      clientSecret,
      refreshToken,
      expiresIn,
      appId,
      active,
      createDateTime,
      integrationDefaultId,
      companyId,
    ]
  )

  const getIntegrationCredencial = async () => {
    setLoading(true)
    try {
      const response = await getUniqueIntegrationCredencialService(
        token,
        nameUrl,
        integrationCredencialId
      )
      setTokenType(response.data.tokenType)
      setName(response.data.name)
      setEmail(response.data.email)
      setUserName(response.data.userName)
      setPassword(response.data.password)
      setAccessToken(response.data.accessToken)
      setClientToken(response.data.clientToken)
      setClientSecret(response.data.clientSecret)
      setRefreshToken(response.data.refreshToken)
      setExpiresIn(response.data.expiresIn)
      setAppId(response.data.appId)
      setActive(response.data.active)
      setCreateDateTime(response.data.createdateTime)
      setIntegrationDefaultId(response.data.integrationDefaultId)
      setCompanyId(response.data.companyId)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const getIntegrationDefault = useCallback(async () => {
    try {
      const response = await getIntegrationDefaultService(token, nameUrl, 100000, 0, false)
      const defaults = response.data.result
      setIntegrationDefault(defaults)
      await Promise.all(
        defaults.map(async item => {
          await getAllFields(item.integrationDefaultId)
        })
      )
      setAllLoaded(true)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const getAllFields = async idDef => {
    const response = await getAllIntegrationFieldsService(token, nameUrl, idDef)
    const final = { intId: idDef, fields: response.data.result }
    setIntegrationFields(prev => [...prev, final])
  }

  const changeIntDefault = async idDefault => {
    console.log(idDefault)
    if (idDefault == 'none') {
      setSaveFields(initialFields)
      setIntegrationDefaultId('none')
    } else {
      const findFields = integrationFields.find(x => x.intId === idDefault)
      setIntegrationDefaultId(idDefault)
      let finalFields = initialFields
      findFields.fields.map(x => {
        const key = x.type
        //setSaveFields({ ...saveFields, [x.type]: true });
        finalFields[key] = true
      })
      setSaveFields(finalFields)
    }
  }

  useEffect(() => {
    getIntegrationDefault()
    getIntegrationCredencial()
  }, [integrationCredencialId])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={editIntegrationCredencial}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select
                label="Empresa"
                value={companyId ? companyId : ''}
                onChange={values => setCompanyId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.companyId} key={index}>
                      {item.nameCompany}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Aplicativo</InputLabel>
              <Select
                label="Aplicativo"
                value={integrationDefaultId ? integrationDefaultId : ''}
                onChange={values => changeIntDefault(values.target.value)}
                sx={{ width: '100%' }}
                disabled={!allLoaded}
              >
                {integrationDefault.map((item, index) => {
                  return (
                    <MenuItem value={item.integrationDefaultId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            {saveFields.tokenType && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  value={tokenType}
                  onChange={values => setTokenType(values.target.value)}
                  id="outlined-basic"
                  label="Token Type"
                  variant="outlined"
                  type="text"
                />
              </Box>
            )}
            {saveFields.name && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  value={name}
                  onChange={values => setName(values.target.value)}
                  id="outlined-basic"
                  label="Nome"
                  variant="outlined"
                  type="text"
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            {saveFields.email && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  value={email}
                  onChange={values => setEmail(values.target.value)}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  type="email"
                />
              </Box>
            )}
            {saveFields.userName && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  value={userName}
                  onChange={values => setUserName(values.target.value)}
                  id="outlined-basic"
                  label="Nome de Usuário"
                  variant="outlined"
                  type="text"
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            {saveFields.password && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  value={password}
                  onChange={values => setPassword(values.target.value)}
                  id="outlined-basic"
                  label="Senha"
                  variant="outlined"
                  type="text"
                />
              </Box>
            )}
            {saveFields.accessToken && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  value={accessToken}
                  onChange={values => setAccessToken(values.target.value)}
                  id="outlined-basic"
                  label="Token de Acesso"
                  variant="outlined"
                  type="text"
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            {saveFields.clientToken && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  value={clientToken}
                  onChange={values => setClientToken(values.target.value)}
                  id="outlined-basic"
                  label="Token do Cliente"
                  variant="outlined"
                  type="text"
                />
              </Box>
            )}
            {saveFields.clientSecret && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  value={clientSecret}
                  onChange={values => setClientSecret(values.target.value)}
                  id="outlined-basic"
                  label="Secret do Cliente"
                  variant="outlined"
                  type="text"
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            {saveFields.refreshToken && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  value={refreshToken}
                  onChange={values => setRefreshToken(values.target.value)}
                  id="outlined-basic"
                  label="Refresh Token"
                  variant="outlined"
                  type="text"
                />
              </Box>
            )}
            {saveFields.expiresIn && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  value={expiresIn}
                  onChange={values => setExpiresIn(values.target.value)}
                  id="outlined-basic"
                  label="Expira em:"
                  variant="outlined"
                  type="text"
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            {saveFields.appId && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  value={appId}
                  onChange={values => setAppId(values.target.value)}
                  id="outlined-basic"
                  label="App ID"
                  variant="outlined"
                  type="text"
                />
              </Box>
            )}
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel>Ativo</InputLabel>
              <Checkbox value={active} onChange={() => setActive(!active)} checked={active} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setEditing(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default IntegrationCredencialEdit
