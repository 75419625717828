import { DateRange, InsertDriveFile, Inventory, Replay, TrendingDown } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'
import { format } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../context/AuthContext'
import {
  getLastInventory,
  getTotalDepreciacao,
  getTotalProduct,
  getTotalProductItens,
  getTotalProductItensCreated,
  getTotalProductItensLastUpdate,
} from '../../services/dashboardServices'
import { searchProductSubCompanyService } from '../../services/productService'
import { colorTableDefault } from '../TemplateDefault'
import { useDebounce } from '../View/Product/Components/useDebounce'
const formatDate = date => {
  const dateHr = new Date(date)
  return format(dateHr, 'dd/MM/yyyy HH:mm:ss')
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 4,
  height: '120px',
}))

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: theme.spacing(7),
  height: theme.spacing(7),
}))

const formatToBRL = value => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
}

const DashboardSebrae = () => {
  const token = localStorage.getItem('token')
  const { subCompany, company, nameUrl } = useContext(Context)
  const { debounce } = useDebounce(1000, true)

  const [totalProducts, setTotalProducts] = useState(0)
  const [totalProductsItens, setTotalProductsItens] = useState(0)
  const [totalProductsItensCreated, setTotalProductsItensCreate] = useState([])
  const [totalProductsItensLastUpdate, setTotalProductsItensLastUpdate] = useState([])
  const [lastInventory, setLastInventory] = useState('')
  const [totalDepreciacao, setTotalDepreciacao] = useState(0)
  const [product, setProduct] = useState([])
  const [isLoadingTotalProducts, setIsLoadingTotalProducts] = useState(false)
  const [isLoadingTotalProductsItens, setIsLoadingTotalProductsItens] = useState(false)
  const [isLoadingTotalProductsItensCreated, setIsLoadingTotalProductsItensCreated] =
    useState(false)
  const [isLoadingTotalProductsItensLastUpdate, setIsLoadingTotalProductsItensLastUpdate] =
    useState(false)
  const [isLoadingUltimoInventario, setIsLoadingUltimoInventario] = useState(false)
  const [isLoadingTotalDepreciacao, setIsLoadingTotalDepreciacao] = useState(false)

  const [errorTotalProducts, setErrorTotalProducts] = useState(false)
  const [errorTotalProductsItens, setErrorTotalProductsItens] = useState(false)
  const [errorTotalProductsItensCreated, setErrorTotalProductsItensCreated] = useState(false)
  const [errorTotalProductsItensLastCreated, setErrorTotalProductsItensLastCreated] =
    useState(false)
  const [errorUltimoInventario, setErrorUltimoInventario] = useState(false)
  const [errorTotalDepreciacao, setErrorTotalDepreciacao] = useState(false)

  const dataUltimoInventario = '20/08/2024'

  console.log(company, 'companycompany')
  const handleTotalProducts = async () => {
    setErrorTotalProducts(false)
    try {
      setIsLoadingTotalProducts(true)
      const response = await getTotalProduct(token, nameUrl, company.companyId)
      setTotalProducts(response.data.total)
    } catch (error) {
      setErrorTotalProducts(true)
      console.error(error)
    } finally {
      setIsLoadingTotalProducts(false)
    }
  }

  const handleTotalProductsItens = async () => {
    setErrorTotalProductsItens(false)

    try {
      setIsLoadingTotalProductsItens(true)
      const response = await getTotalProductItens(token, nameUrl, company.companyId)
      setTotalProductsItens(response.data.total)
    } catch (error) {
      console.error(error)
      setErrorTotalProductsItens(true)
    } finally {
      setIsLoadingTotalProductsItens(false)
    }
  }

  const handleTotalProductsItensCreated = async () => {
    setErrorTotalProductsItensCreated(false)

    try {
      setIsLoadingTotalProductsItensCreated(true)
      const response = await getTotalProductItensCreated(token, nameUrl, company.companyId)
      setTotalProductsItensCreate(response.data)
    } catch (error) {
      setErrorTotalProductsItensCreated(true)
      console.error(error)
    } finally {
      setIsLoadingTotalProductsItensCreated(false)
    }
  }

  const handleTotalProductsItensLastUpdate = async () => {
    setErrorTotalProductsItensLastCreated(false)

    try {
      setIsLoadingTotalProductsItensLastUpdate(true)
      const response = await getTotalProductItensLastUpdate(token, nameUrl, company.companyId)
      setTotalProductsItensLastUpdate(response.data)
    } catch (error) {
      setErrorTotalProductsItensLastCreated(true)
      console.error(error)
    } finally {
      setIsLoadingTotalProductsItensLastUpdate(false)
    }
  }

  const handleLastInventory = async () => {
    setErrorUltimoInventario(false)

    try {
      setIsLoadingUltimoInventario(true)
      const response = await getLastInventory(token, nameUrl, company.companyId)
      setLastInventory(response.data[0].dateAndDay)
    } catch (error) {
      setErrorUltimoInventario(true)
      console.error(error)
    } finally {
      setIsLoadingUltimoInventario(false)
    }
  }

  const handleTotalDepreciacao = async () => {
    setErrorTotalDepreciacao(false)

    try {
      setIsLoadingTotalDepreciacao(true)
      const response = await getTotalDepreciacao(token, nameUrl, company.companyId)
      setTotalDepreciacao(response.data[0].price)
    } catch (error) {
      setErrorTotalDepreciacao(true)
      console.error(error)
    } finally {
      setIsLoadingTotalDepreciacao(false)
    }
  }

  useEffect(() => {
    if (company.companyId) {
      handleTotalProducts()
      handleTotalProductsItens()
      handleLastInventory()
      handleTotalDepreciacao()
      handleTotalProductsItensCreated()
      handleTotalProductsItensLastUpdate()
    }
  }, [])

  return (
    <Box sx={{ mt: { xs: 10, md: 6 } }}>
      <Typography variant="h5" fontWeight={700}>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard variant="outlined" sx={{ bgcolor: '#1EA47E', position: 'relative' }}>
            {errorTotalProducts && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(8px)',
                  zIndex: 1,
                }}
              >
                <IconButton onClick={handleTotalProducts}>
                  <Replay />
                  <Typography>Carregar novamente</Typography>
                </IconButton>
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              height="100%"
              p={3}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#004d40',
                    width: '3rem',
                    height: '3rem',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Inventory sx={{ color: 'white', fontSize: 25 }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="subtitle1" color="#fff">
                    Total de Produtos
                  </Typography>
                  <Typography variant="h5" color="#fff">
                    {isLoadingTotalProducts ? <CircularProgress size={22} /> : totalProducts}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard variant="outlined" sx={{ bgcolor: '#f57c00', position: 'relative' }}>
            {errorTotalProductsItens && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(8px)',
                  zIndex: 1,
                }}
              >
                <IconButton onClick={handleTotalProductsItens}>
                  <Replay />
                  <Typography>Carregar novamente</Typography>
                </IconButton>
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              height="100%"
              p={3}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#004d40',
                    width: '3rem',
                    height: '3rem',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <InsertDriveFile sx={{ color: 'white', fontSize: 25 }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  <Typography textAlign="end" variant="subtitle1" color="#fff">
                    Total de Peças Cadastradas
                  </Typography>
                  <Typography variant="h5" color="#fff">
                    {isLoadingTotalProductsItens ? (
                      <CircularProgress size={22} />
                    ) : (
                      totalProductsItens
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard variant="outlined" sx={{ bgcolor: '#0288d1', position: 'relative' }}>
            {errorUltimoInventario && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(8px)',
                  zIndex: 1,
                }}
              >
                <IconButton onClick={handleLastInventory}>
                  <Replay />
                  <Typography>Carregar novamente</Typography>
                </IconButton>
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              height="100%"
              p={3}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#01579b',
                    width: '3rem',
                    height: '3rem',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <DateRange sx={{ color: 'white', fontSize: 25 }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="subtitle1" color="#fff">
                    Último Inventário
                  </Typography>
                  <Typography variant="h5" color="#fff">
                    {isLoadingUltimoInventario ? <CircularProgress size={22} /> : lastInventory}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard variant="outlined" sx={{ bgcolor: '#d32f2f', position: 'relative' }}>
            {errorTotalDepreciacao && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(8px)',
                  zIndex: 1,
                }}
              >
                <IconButton onClick={handleLastInventory}>
                  <Replay />
                  <Typography>Carregar novamente</Typography>
                </IconButton>
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              height="100%"
              p={3}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#b71c1c',
                    width: '3rem',
                    height: '3rem',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <TrendingDown sx={{ color: 'white', fontSize: 25 }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="subtitle1" color="#fff">
                    Total de Depreciação
                  </Typography>

                  <Typography variant="h5" color="#fff">
                    {isLoadingTotalDepreciacao ? (
                      <CircularProgress size={22} />
                    ) : (
                      formatToBRL(totalDepreciacao)
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper} variant="outlined" sx={{ borderRadius: 1, mb: 5 }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Código de barras</StyledTableCell>
                  <StyledTableCell align="right">Nome</StyledTableCell>
                  <StyledTableCell align="right">Valor de depreciação</StyledTableCell>
                  <StyledTableCell align="right">Data de Criação</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingTotalProductsItensCreated ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                    </TableCell>
                  </TableRow>
                ) : errorTotalProductsItensCreated ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="body1" color="error" sx={{ mb: 2 }}>
                        Ocorreu um erro ao carregar os itens.
                      </Typography>
                      <IconButton onClick={handleTotalProductsItensCreated} aria-label="recarregar">
                        <Replay />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : totalProductsItensCreated.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="body1" color="textSecondary">
                        Nenhum item encontrado.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  totalProductsItensCreated.map(row => (
                    <StyledTableRow key={row.idProductPartner}>
                      <StyledTableCell component="th" scope="row">
                        {row.idProductPartner}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.productName}</StyledTableCell>
                      <StyledTableCell align="right">{row.depreciationValue}</StyledTableCell>
                      <StyledTableCell align="right">{formatDate(row.created)}</StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>{' '}
        {/* <Grid item xs={12} md={6}>
          <TableContainer component={Paper} variant="outlined" sx={{ borderRadius: 1, mb: 5 }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Código de barras</StyledTableCell>
                  <StyledTableCell align="right">Nome</StyledTableCell>
                  <StyledTableCell align="right">Valor de depreciação</StyledTableCell>
                  <StyledTableCell align="right">Data de Criação</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingTotalProductsItensLastUpdate ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                    </TableCell>
                  </TableRow>
                ) : errorTotalProductsItensLastCreated ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="body1" color="error" sx={{ mb: 2 }}>
                        Ocorreu um erro ao carregar os itens.
                      </Typography>
                      <IconButton
                        onClick={handleTotalProductsItensLastUpdate}
                        aria-label="recarregar"
                      >
                        <Replay />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : totalProductsItensLastUpdate.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="body1" color="textSecondary">
                        Nenhum item encontrado.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  totalProductsItensLastUpdate.map(row => (
                    <StyledTableRow key={row.idProductPartner}>
                      <StyledTableCell component="th" scope="row">
                        {row.idProductPartner}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.productName}</StyledTableCell>
                      <StyledTableCell align="right">{row.depreciationValue}</StyledTableCell>
                      <StyledTableCell align="right">{formatDate(row.created)}</StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid> */}
      </Grid>
    </Box>
  )
}

export default DashboardSebrae
