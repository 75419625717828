import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { editPlanService, getUniquePlanService } from '../../../services/admServices'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const PlanEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  planId,
  setExpanded,
  handleFetchData,
}) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const roleGroupId = '3fa85f64-5717-4562-b3fc-2c963f66afa6'
  //não sei pq, mas está assim no create também
  const [plansCode, setPlansCode] = useState('')
  const [plansName, setPlansName] = useState('')
  const [active, setActive] = useState(true)
  const [recurrence, setRecurrence] = useState(true)
  const [price, setPrice] = useState('')
  const [urlCheckout, setUrlCheckout] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const editPlan = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const planEdited = {
        plansId: planId,
        plansCode,
        plansName,
        active,
        recurrence,
        price,
        urlCheckout,
        roleGroupId,
      }

      try {
        await editPlanService(token, nameUrl, planEdited)
        setLoading(false)
        setEditing(false)
        setAlerta(true)
        setSeverity('success')
        setTitle('Plano editado com sucesso!')
        setExpanded(false)
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro aoeditar plano, verifique os dados e tente novamente.')
      }
    },
    [plansName, plansCode, active, recurrence, price, urlCheckout]
  )

  const getPlan = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getUniquePlanService(token, nameUrl, planId)
      //const plano = response.data.find(x => x.plansId === planId)
      setPlansName(response.data.plansName)
      setPlansCode(response.data.plansCode)
      setActive(response.data.active)
      setRecurrence(response.data.recurrence)
      setUrlCheckout(response.data.urlCheckout)
      setPrice(response.data.price)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getPlan()
  }, [])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao Editar Plano!
          </Alert>
        </Snackbar>
        <Typography variant="h5" sx={{ mb: 5 }}>
          Editar Plano
        </Typography>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={editPlan}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={plansName ? plansName : ''}
                onChange={values => setPlansName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={plansCode ? plansCode : ''}
                onChange={values => setPlansCode(values.target.value)}
                id="outlined-basic"
                label="Código do Plano"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2, alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={urlCheckout ? urlCheckout : ''}
                onChange={values => setUrlCheckout(values.target.value)}
                id="outlined-basic"
                label="Url Checkout"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={price ? price : ''}
                onChange={values => setPrice(values.target.value)}
                id="outlined-basic"
                label="Preço"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-evenly',
              mt: 2,
              alignItems: 'center',
            }}
          >
            <Box sx={{ mt: 2, width: '45%' }}>
              <InputLabel>Ativo</InputLabel>
              <Checkbox
                //value={active}
                onChange={() => setActive(!active)}
                checked={active}
              />
              {active ? 'Sim' : 'Não'}
            </Box>
            <Box sx={{ mt: 2, width: '45%' }}>
              <InputLabel>Recorrente</InputLabel>
              <Checkbox
                //value={recurrence}
                onChange={() => setRecurrence(!recurrence)}
                checked={recurrence}
              />
              {recurrence ? 'Sim' : 'Não'}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PlanEdit
