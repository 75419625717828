import React from 'react'
import {
  Card,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
} from '@mui/material'

function CircularProgressComponent({ value }) {
  return (
    <>
      <Box position="absolute" top={0} right={0} zIndex={5}>
        <CircularProgress
          variant="determinate"
          value={value}
          sx={{ color: 'green', fontSize: 45 }}
        />
      </Box>
      <Box position="absolute" top={0} right={0}>
        <CircularProgress variant="determinate" value={100} sx={{ color: 'red', fontSize: 45 }} />
      </Box>
    </>
  )
}

export default CircularProgressComponent
