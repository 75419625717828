import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setPermission } from '../../../permissions/userPermissions'
import { PRODUCAO } from '../../../const/RoleConstants'
import { Context } from '../../../context/AuthContext'
import {
  getProductAll,
  getSingleProductService,
  searchProductService,
} from '../../../services/productService'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { colorTableDefault } from '../../TemplateDefault'
import { getProcess } from '../../../services/processServices'
import { AiFillPlusCircle } from 'react-icons/ai'
import { BiArrowBack } from 'react-icons/bi'
import { orderReceiveService } from '../../../services/movOrderItemERP'
import { getPrinterZplService } from '../../../services/configServices'
import { useDebounce } from '../../View/Product/Components/useDebounce'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'
import { Search } from '@mui/icons-material'

const LabelOrderClientCreate = () => {
  const { userRoles, company, nameUrl, subCompany } = useContext(Context)
  const [permissionProducao, setPermissionProducao] = useState(false)
  const { debounce } = useDebounce(1000, true)

  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [processList, setProcessList] = useState([])
  const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  const [subCompanyId, setSubCompanyId] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [observation, setObservation] = useState('')
  const [produtos, setProdutos] = useState([])
  const [search, setSearch] = useState('')
  const [searchPrintZpl, setSearchPrintZpl] = useState('')
  const [quantity, setQuantity] = useState('')
  const [lote, setLote] = useState('')
  const [datetimeExpiration, setDateTimeExpiration] = useState(new Date())
  const [numberPallet, setNumberPallet] = useState('')
  const [subCompanySelect, setSubCompanySelect] = useState('')
  const [quantityList, setQuantityList] = useState('')
  const [printZplId, setPrintZplId] = useState('')
  const [searching, setSearching] = useState(false)
  const [searchingPrintZpl, setSearchingPrintZpl] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [error, setError] = useState(false)
  const [title, setTitle] = useState()
  const [printerZpl, setPrinterZpl] = useState([])

  const [singleProd, setSingleProd] = useState({
    name: '',
    referencia: '',
    sku: '',
    quantity: '',
    idProductPartner: '',
    printerZplId: '',
  })
  const [itensOrder, setItensOrder] = useState([])
  const [idProd, setIdProd] = useState()
  const [row, setRow] = useState()
  const [rowPrintZpl, setRowPrintZpl] = useState()
  const [selectedId, setSelectedId] = useState(undefined)

  const navigate = useNavigate()

  // useEffect(() => {
  //   if (search === '') {
  //     const checagem = setTimeout(() => {
  //       setProdutos([{ label: '', value: '' }])
  //     }, 1000)
  //     return () => clearTimeout(checagem)
  //   } else {
  //     setSearching(true)
  //     const checagem = setTimeout(() => {
  //       const dados = {
  //         companyId: subCompany[0].companyId,
  //         SearchWord: search,
  //         limit: 10,
  //         offset: 0,
  //       }
  //       searchProductService(token, nameUrl, dados).then(response => {
  //         const prodsFinal = []
  //         response.data.result.map(produto => {
  //           const obj = produto
  //           obj.value = produto.productId
  //           obj.label = `${produto.name}`
  //           prodsFinal.push(obj)
  //         })
  //         setProdutos(prodsFinal)
  //         setSearching(false)
  //       })
  //     }, 1000)
  //     return () => clearTimeout(checagem)
  //   }
  // }, [search])
  useEffect(() => {
    if (company.companyId) {
      setSearching(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search])

  const handleGetAllProductsSearch = async () => {
    setSearching(true)
    const dataProduct = {
      companyId: subCompany[0].companyId,
      SearchWord: search,
      limit: 10,
      offset: 0,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProdutos(data)
        setSearching(false)
      })
    } catch (error) {
      console.error(error)
      setSearching(false)
    }
  }

  useEffect(() => {
    if (row) {
      setSingleProd({
        idProductPartner: row.idProductPartner,
        name: row.name,
        quantity: row.quantity,
        referencia: row.referencia,
        sku: row.sku,
        printerZplId: row.printerZplId,
      })
    }
  }, [row])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const getPermissions = useCallback(async () => {
    setPermissionProducao(setPermission(userRoles, PRODUCAO))
  }, [])

  useEffect(() => {
    getPermissions()
    getProcessList()

    //getProducts()
  }, [])

  useEffect(() => {
    if (subCompanyId) {
      getPrinterZpl()
    }
  }, [subCompanyId])

  const addToList = () => {
    const repetido = itensOrder.find(x => x.idProductPartner === singleProd.idProductPartner)
    if (repetido) {
      setTitle('Produto já adicionado.')
      return
    }

    if (!singleProd || !quantityList || !rowPrintZpl) {
      setTitle('Preencha todos os dados.')
      setError(true)
      return
    }

    if (singleProd && quantityList) {
      const prod = singleProd
      prod.quantity = parseInt(quantityList)
      prod.printerZplId = rowPrintZpl.printerZplId
      prod.printZplName = rowPrintZpl.name
      prod.referencia = prod.referencia
      prod.sku = prod.sku

      setItensOrder([prod, ...itensOrder])
      setTitle('')
    } else {
      setTitle('Preencha todos os dados.')
      setError(true)
    }
  }

  const handleClose = () => {
    setError(false)
  }

  const getProcessList = useCallback(async () => {
    try {
      const data = {
        subCompanyId: subCompany[0].subCompanyId,
        limit: 1000,
        offset: 0,
        order: true,
      }
      const response = await getProcess(token, nameUrl, data)
      setProcessList(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const createOrderReceive = async () => {
    setLoading(true)
    const keysToKeep = ['idProductPartner', 'quantity', 'finishGood', 'dataExpiration']
    const redux = array =>
      array.map(o =>
        keysToKeep.reduce((acc, curr) => {
          acc[curr] = o[curr]
          return acc
        }, {})
      )
    const finalList = []
    let count = 0
    for (let x = 0; x < itensOrder.length; x++) {
      count++
      const newData = {
        idProductPartner: itensOrder[x].idProductPartner,
        quantity: itensOrder[x].quantity,
        printerZplId: itensOrder[x].printerZplId,
        finishGood: itensOrder[x].finishGood,
        subCompanyCNPJ: subCompanyCNPJ,
        movimentationOrderNumberOtherSystem: movimentationOrderNumberOtherSystem,
        datetimeExpiration: '1970-01-01T23:05:51.781Z',
        dataExpiration: '1970-01-01T23:05:51.781Z',
        lote: '-',
        numberPallet: '-',
        sequence: count,
      }
      finalList.push(newData)
    }

    const data = {
      movimentationOrderNumberOtherSystem,
      observation: observation || '-',
      quantity: parseInt(quantity) || 0,
      subCompanyId: subCompanySelect,
      itensOrder: finalList,
      printerZplId: printZplId,
    }
    await orderReceiveService(token, nameUrl, data)
      .then(response => {
        navigate('/labelOrderClient')
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setTitle(error.response.data.message)
        setError(true)
      })
  }

  const handleSelectFilial = e => {
    setSubCompanySelect(e.target.value)
    setSubCompanyId(e.target.value)

    const selectCnpj = subCompany.filter(sub => sub.subCompanyId === e.target.value)
    setSubCompanyCNPJ(selectCnpj[0].cnpj)
  }

  const getPrinterZpl = async () => {
    setSearchingPrintZpl(true)
    try {
      const response = await getPrinterZplService(
        token,
        nameUrl,
        subCompanyId,
        500,
        0,
        'desc',
        searchPrintZpl
      )
      const data = response.data

      const sortedData = data.sort(
        (a, b) => new Date(b.create).getTime() - new Date(a.create).getTime()
      )

      setPrinterZpl(sortedData)
      setSearchingPrintZpl(false)
    } catch (err) {
      console.log(err)
      setSearchingPrintZpl(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      setSearchingPrintZpl(true)
      debounce(() => {
        getPrinterZpl()
      })
    }
  }, [searchPrintZpl])

  useEffect(() => {}, [itensOrder])

  return (
    <>
      <Box sx={{ mt: 7.5, display: 'flex', flexDirection: 'column' }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          //key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Cadastrar Impressão de Etiqueta</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2 }}>
          <FormControl sx={{ width: 0.5 }}>
            <InputLabel id="filial">Filial</InputLabel>
            <Select
              label="Filial"
              name="filial"
              onChange={handleSelectFilial}
              value={subCompanySelect}
            >
              {subCompany.map((item, index) => {
                return (
                  <MenuItem value={item.subCompanyId} key={index}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>{' '}
          </FormControl>

          <FormControl sx={{ width: 0.5 }}>
            <TextField
              sx={{ width: '100%' }}
              value={movimentationOrderNumberOtherSystem}
              onChange={values => setMovimentationOrderNumberOtherSystem(values.target.value)}
              id="outlined-basic"
              label="Número Order (outro sistema)"
              variant="outlined"
              type="text"
              disabled={!subCompanyId}
            />
          </FormControl>
        </Box>
        <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2 }}>
          <Box sx={{ width: 0.5, gap: 2, display: 'flex' }}>
            <FormControl sx={{ width: 1 }}>
              <TextField
                sx={{ width: '100%' }}
                value={observation}
                onChange={values => setObservation(values.target.value)}
                id="outlined-basic"
                label="Observação"
                variant="outlined"
                type="text"
                disabled={!subCompanyId}
              />
            </FormControl>
          </Box>
          <FormControl sx={{ width: 0.5 }}>
            <Autocomplete
              openText="Abrir"
              closeText="Fechar"
              clearText="Apagar"
              noOptionsText={searching ? 'Carregando...' : 'Nenhum resultado encontrado'}
              loadingText="Carregando..."
              disablePortal
              value={row}
              loading={searching}
              disabled={!subCompanyId}
              onInputChange={(_, newValue) => setSearch(newValue)}
              popupIcon={searching ? <CircularProgress color="warning" size={20} /> : undefined}
              onChange={(_, newValue) => {
                if (newValue === null) {
                  setSelectedId(null)
                  setRow(null)
                  setSearch('')
                  handleGetAllProductsSearch()
                } else {
                  setSelectedId(newValue?.productId)
                  setRow(newValue)
                  setSearch(newValue.referencia)
                }
              }}
              options={produtos}
              getOptionLabel={option => `${option.referencia} - ${option.name}`}
              renderInput={params => <TextField {...params} label="Pesquisar produtos" />}
            />
          </FormControl>
        </Box>
        {/* <Divider sx={{ width: 1, mt: 2 }} /> */}
        <Box
          sx={{
            width: 1,
            mt: 2,
            display: 'flex',
            gap: 2,
            border: '1px solid black',
            borderRadius: 2,
            p: 2,
            flexDirection: 'column',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={singleProd.name}
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Nome Produto"
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={singleProd.referencia}
                  id="outlined-basic"
                  disabled
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Referência"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {' '}
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={singleProd.sku}
                  id="outlined-basic"
                  variant="outlined"
                  label="SKU"
                  type="text"
                  fullWidth
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={quantityList}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  disabled={!subCompanyId}
                  fullWidth
                  label="Quantidade"
                  onChange={e => setQuantityList(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                openText="Abrir"
                closeText="Fechar"
                clearText="Apagar"
                noOptionsText={searchingPrintZpl ? 'Carregando...' : 'Nenhum resultado encontrado'}
                loadingText="Carregando..."
                disablePortal
                value={rowPrintZpl}
                disabled={!subCompanyId}
                loading={searchingPrintZpl}
                onInputChange={(_, newValue) => setSearchPrintZpl(newValue)}
                popupIcon={
                  searchingPrintZpl ? <CircularProgress color="warning" size={20} /> : undefined
                }
                onChange={(_, newValue) => {
                  if (newValue === null) {
                    setPrintZplId(null)
                    setRowPrintZpl(null)
                    setSearchPrintZpl('')
                    getPrinterZpl()
                  } else {
                    setRowPrintZpl(newValue)
                    setPrintZplId(newValue?.printerZplId)
                    setSearchPrintZpl(newValue.name)
                  }
                }}
                options={printerZpl}
                getOptionLabel={option => `${option.name}`}
                renderInput={params => (
                  <TextField {...params} label="Pesquisar Modelo de etiqueta" />
                )}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              width: 1,
              mt: 2,
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: 0.25 }}>
              <Button disabled={!subCompanyId} variant="outlined" onClick={addToList}>
                Adicionar à lista
              </Button>
            </Box>
            {title && <Chip color="error" label={title} />}
          </Box>
        </Box>
        {itensOrder && (
          <Box sx={{ mt: '2rem', display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6">
              {itensOrder.length} {itensOrder.length === 1 ? 'etiqueta' : 'etiquetas'} para
              cadastrar
            </Typography>
          </Box>
        )}
        <TableContainer component={Paper} sx={{ mt: 1 }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell> </StyledTableCell>
                <StyledTableCell align="center">Identificação</StyledTableCell>
                <StyledTableCell align="center">Nome Produto</StyledTableCell>
                <StyledTableCell align="center">Modelo de etiqueta</StyledTableCell>
                <StyledTableCell align="center">Referência</StyledTableCell>
                <StyledTableCell align="center">SKU</StyledTableCell>
                <StyledTableCell align="center">Quantidade</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itensOrder.map((row, index) => (
                <StyledTableRow key={row.index}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setItensOrder(itensOrder.filter((v, i) => i !== index))
                      setTitle('')
                    }}
                  >
                    <DeleteForeverIcon />
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">{row.printZplName}</StyledTableCell>
                  <StyledTableCell align="center">{row.referencia}</StyledTableCell>
                  <StyledTableCell align="center">{row.sku}</StyledTableCell>
                  <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ gap: 2, display: 'flex', mt: 2 }}>
          <Button
            variant="contained"
            color="success"
            startIcon={<AiFillPlusCircle />}
            onClick={createOrderReceive}
          >
            Salvar
          </Button>
          <Button
            color="error"
            variant="contained"
            startIcon={<BiArrowBack />}
            onClick={() => navigate('/labelOrderClient')}
          >
            Voltar
          </Button>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default LabelOrderClientCreate
