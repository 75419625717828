import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  confirmOrder,
  getProductAssociationSeearchService,
  getProductAssociationService,
  returnOrderStatus,
} from '../../../services/productService'
import { Context } from '../../../context/AuthContext'
import {
  calcPaleteService,
  editTransferOrderService,
  getIdMovimentationERP,
  getUniqueTransferOrderService,
} from '../../../services/requestServices'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Grid,
} from '@mui/material'
import { deleteTagService } from '../../../services/movimentationRouteServices'
import EditTable from './editTable'
import AddTable from './addTable'
import { Delete, DeleteOutline } from '@mui/icons-material'
import { red } from '@mui/material/colors'

const LabelOrderEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  labelOrderId,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const subCompanyId = subCompany[0].subCompanyId

  const token = localStorage.getItem('token')
  const [product, setProduct] = useState([])
  const [requestProductsConverted, setRequestProductsConverted] = useState([])
  const [selectProductId, setSelectProductId] = useState('')
  const [loading, setLoading] = useState(false)
  let requestProductsModel = {}
  const navigate = useNavigate()

  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [subCompanyIdFrom, setSubCompanyIdFrom] = useState('')
  const [movimentationOrderNumberBihands, setMovimentationOrderNumberBihands] = useState('')
  const [receiveMovimentationOrderDate, setReceiveMovimentationOrderDate] = useState('')
  const [createRequestMovimentationOrderDate, setCreateRequestMovimentationOrderDate] = useState('')
  const [typeOrderName, setTypeOrderName] = useState('')
  const [subCompanyNameFrom, setSubCompanyNameFrom] = useState('')
  const [movimentationOrderStatus, setMovimentationOrderStatus] = useState('')
  const [isReceived, setIsReceived] = useState()
  const [finalizado, setFinalizado] = useState(false)
  const [dataForTable, setDataForTable] = useState([])
  const [dataForTableData, setDataForTableData] = useState([])

  let dateTime = new Date()

  const [alertSuccess, setAlertSuccess] = useState(false)
  const [alertFailed, setAlertFailed] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [newItem, setNewItem] = useState(false)
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState()
  const [titleDelete, setTitleDelete] = useState('')
  const [titleDeleteButton, setTitleDeleteButton] = useState('')
  const [buttonAction, setButtonAction] = useState('')
  const [alertSuccessDelete, setAlertSuccessDelete] = useState(false)
  const [alertFailedDelete, setAlertFailedDelete] = useState(false)
  const [dataEdit, setDataEdit] = useState()
  const [editingTicket, setEditingTicket] = useState(false)
  const [addItem, setAddItem] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setAlertSuccess(false)
    setAlertFailed(false)
    setOpen(false)
    setAlertSuccessDelete(false)
    setAlertFailedDelete(false)
  }

  const requestProductsConverter = async (rp, movimentationOrderId) => {
    // Verifique se rp é nulo ou indefinido
    if (!rp) {
      console.error('Error: rp is null or undefined')
      return
    }

    let array = []

    // Verifique se rp é um array antes de iterar sobre ele
    if (!Array.isArray(rp)) {
      console.error('Error: rp is not an array')
      return
    }

    rp.forEach(element => {
      // Certifique-se de que element não seja nulo ou indefinido antes de acessar suas propriedades
      if (element) {
        const requestProductsModel = {
          serial: element.serial,
          createDate: element.createDate,
          productName: element.productName,
          price: element.price,
          sku: element.sku,
          movimentationOrderId: element.movimentationOrderId,
          productItensId: element.productItensId,
          createDateRead: dateTime.toISOString(),
        }

        array.push(requestProductsModel)
      }
    })
    const response = await calcPaleteService(token, nameUrl, movimentationOrderId)
    const responseData = await getIdMovimentationERP(token, nameUrl, movimentationOrderId)

    // Certifique-se de que response.data é um array antes de manipulá-lo
    if (Array.isArray(response?.data)) {
      const dataTable = response.data.map(x => {
        x.delete = false
        return x
      })

      setRequestProductsConverted(array)
      setDataForTable(dataTable)
      setDataForTableData(responseData.data)
    } else {
      console.error('Error: response.data is not an array')
    }
  }

  const getLabelOrder = async () => {
    setLoading(true)
    try {
      const response = await getUniqueTransferOrderService(token, nameUrl, labelOrderId)
      console.log(response, 'requestProductsModel')

      requestProductsConverter(
        response.data.movimentationOrderItemCollection,
        response.data.movimentationOrderId
      )
      setSubCompanyIdFrom(response.data.subCompanyIdFrom)
      setMovimentationOrderNumberOtherSystem(response.data.movimentationOrderNumberOtherSystem)
      setMovimentationOrderNumberBihands(response.data.movimentationOrderNumberBihands)
      setReceiveMovimentationOrderDate(response.data.receiveMovimentationOrderDate)
      setCreateRequestMovimentationOrderDate(response.data.createRequestMovimentationOrderDate)
      setTypeOrderName('Impressão de etiqueta')
      setSubCompanyNameFrom(response.data.subCompanyNameFrom)
      setMovimentationOrderStatus(returnOrderStatus(response.data.movimentationOrderStatus))
      if (response.data.movimentationOrderStatus === 2) {
        setFinalizado(true)
      }
      setIsReceived(response.data.isReceived)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const editLabelOrder = useCallback(async () => {
    setLoading(true)

    try {
      await editTransferOrderService(token, nameUrl, requestProductsConverted)
      setLoading(false)
      setSeverity('success')
      setTitle('Etiqueta editada com sucesso!')
      setEditing(false)
      setAlerta(true)
      handleFetchData()
    } catch (err) {
      console.log(err)
      setLoading(false)
      setSeverity('error')
      setTitle('Erro ao editar, verifique os dados e tente novamente')
      setAlerta(true)
    }
  }, [movimentationOrderNumberOtherSystem, subCompanyIdFrom, requestProductsConverted])

  const getProduct = useCallback(async () => {
    try {
      const response = await getProductAssociationSeearchService(
        token,
        10000,
        0,
        false,
        subCompanyId,
        ''
      )
      setProduct(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const addRequestProducts = useCallback(() => {
    const result = product.find(item => item.productItensId === selectProductId)
    setDataForTable([...dataForTable, result])
    setRequestProductsConverted([...requestProductsConverted, result])
  }, [product, requestProductsConverted, selectProductId])

  const removeRequestProducts = useCallback(
    productItensId => {
      setRequestProductsConverted(
        requestProductsConverted.filter(item => item.productItensId !== productItensId)
      )
      setDataForTable(dataForTable.filter(item => item.productItensId !== productItensId))
    },
    [product, requestProductsConverted, selectProductId]
  )

  useEffect(() => {
    getProduct()

    getLabelOrder()
  }, [labelOrderId])

  const handleConfirm = async () => {
    try {
      await confirmOrder(token, nameUrl, labelOrderId)
      navigate('/LabelOrder')
    } catch (e) {
      console.log(e)
      setAlertSuccess(false)
      setAlertFailed(true)
    }
  }

  const handleAlertDelete = id => {
    setTitleDelete('Tem certeza que deseja excluir esse item?')
    setOpen(true)
    setIdDelete(id)
  }

  const handleDeleteButton = () => {
    const deleteArray = dataForTable.filter(x => x.delete)
    if (deleteArray.length === 0) {
      return
    } else {
      setTitleDeleteButton(`Você deseja apagar ${deleteArray.length} entradas?`)
      setOpen(true)
    }
  }

  const handleDeleteButtonFinal = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await Promise.all(
        dataForTable
          .filter(x => x.delete)
          .map(async item => {
            await deleteTagService(token, nameUrl, item.movimentationOrderItemId)
          })
      )
      setTitleDeleteButton('Deletado com Sucesso!')
      setAlertSuccessDelete(true)
      setLoading(false)
      getLabelOrder()
    } catch (e) {
      console.log(e)
      setAlertFailedDelete(true)
      setLoading(false)
    }
  }

  const handleCheckDelete = row => {
    const newState = dataForTable.map(obj => {
      if (
        obj.movimentationOrderId === row.id &&
        obj.lote === row.lote &&
        obj.serial === row.serial
      ) {
        return { ...obj, delete: !obj.delete }
      }

      return obj
    })

    setDataForTable(newState)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteTagService(token, nameUrl, idDelete)
      setTitleDelete('Deletado com Sucesso!')
      setAlertSuccessDelete(true)
      setLoading(false)
      getLabelOrder()
    } catch (e) {
      console.log(e)
      setAlertFailedDelete(true)
      setLoading(false)
    }
  }

  const handleEdit = data => {
    setDataEdit(data)
    setEditingTicket(true)
  }

  return (
    <>
      <Box>
        {/* Add Product */}
        <Dialog
          open={newItem}
          keepMounted
          //onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
            sx={{ padding: 3 }}
          >
            <Box>
              <InputLabel id="demo-simple-select-label">Adicionar Peças</InputLabel>
              <Select
                label="Produtos"
                value={selectProductId}
                onChange={values => setSelectProductId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {product.map((item, index) => {
                  return (
                    <MenuItem value={item.productItensId} key={index}>
                      {item.productName}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button type="button" variant="contained" onClick={() => setNewItem(false)}>
                Fechar
              </Button>
              <Button
                type="button"
                variant="contained"
                sx={{ ml: 1 }}
                onClick={() => addRequestProducts()}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Dialog>

        <Dialog
          open={open}
          keepMounted
          //onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-title">{titleDeleteButton}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Não</Button>
            <Button onClick={handleDeleteButtonFinal} autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alertSuccessDelete}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {titleDelete}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alertFailedDelete}
          onClose={handleClose}
          // key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Algo deu Errado!
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical, horizontal }} open={alertSuccess} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Itens Salvos com Sucesso!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alertFailed}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Algo deu Errado!
          </Alert>
        </Snackbar>
        {editingTicket && (
          <EditTable
            open={editingTicket}
            setOpen={setEditingTicket}
            data={dataEdit}
            product={product}
            setTitleDelete={setTitleDelete}
            setAlertSuccessDelete={setAlertSuccessDelete}
            setAlertFailedDelete={setAlertFailedDelete}
            getLabelOrder={getLabelOrder}
          />
        )}

        <Box component="form" sx={{ mt: '2rem' }}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={movimentationOrderNumberBihands}
                name="movimentationOrderNumberBihands"
                id="standard-basic"
                label="Ordem Bihands"
                variant="outlined"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Ordem Outro Sistema"
                variant="outlined"
                name="MovimentationOrderNumberOtherSystem"
                value={movimentationOrderNumberOtherSystem}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="ReceiveMovimentationOrderDate"
                value={new Date(receiveMovimentationOrderDate).toLocaleString()}
                id="outlined-basic"
                label="Data do Recebimento"
                variant="outlined"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Data da Solicitação"
                variant="outlined"
                name="CreateRequestMovimentationOrderDate"
                value={new Date(createRequestMovimentationOrderDate).toLocaleString()}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="TypeOrder"
                value={typeOrderName}
                id="outlined-basic"
                label="Tipo de Pedido"
                variant="outlined"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Filial"
                variant="outlined"
                name="SubCompanyNameFrom"
                value={subCompanyNameFrom}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="MovimentationOrderStatus"
                value={movimentationOrderStatus}
                id="outlined-basic"
                label="Status da movimentação"
                variant="outlined"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Recebido"
                variant="outlined"
                name="IsReceived"
                value={isReceived ? 'Sim' : 'Não'}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            width="100%"
            sx={{
              mt: '1rem',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              width={{ xs: '100%', sm: '100%', md: '80%', lg: '80%', xl: '80%' }}
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                variant="contained"
                sx={{ width: '45%' }}
                type="button"
                onClick={() => setEditing(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                sx={{ width: '45%' }}
                type="button"
                onClick={() => setAddItem(true)}
              >
                Adicionar
              </Button>
            </Box>
          </Box>
        </Box>
        {addItem && (
          <AddTable
            labelOrderId={labelOrderId}
            movimentationOrderNumberOtherSystem={movimentationOrderNumberOtherSystem}
            subCompanyId={subCompanyId}
            getProduct={getProduct}
            getLabelOrder={getLabelOrder}
            setAddItem={setAddItem}
            setAlertSuccessDelete={setAlertSuccessDelete}
            setTitleDelete={setTitleDelete}
            setAlertFailedDelete={setAlertFailedDelete}
          />
        )}
        <Box sx={{ mt: 3 }}>
          {/* {!finalizado && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ width: '80%' }}>
                {movimentationOrderStatus === 'Aberto' ? (
                  <Box>
                    <Button
                      variant="contained"
                      width={{ xs: '40%', sm: '40%', md: '20%', lg: '20%', xl: '20%' }}
                      onClick={() => setNewItem(true)}
                    >
                      + Novo Item
                    </Button>
                    <Button
                      sx={{ ml: 1 }}
                      onClick={() => editLabelOrder()}
                      variant="contained"
                      width={{ xs: '40%', sm: '40%', md: '20%', lg: '20%', xl: '20%' }}
                    >
                      Salvar Itens
                    </Button>
                  </Box>
                ) : (
                  <Button onClick={() => handleConfirm()} variant="contained">
                    Confirmar
                  </Button>
                )}
              </Box>
            </Box>
          )} */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {' '}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h5">
                  {dataForTable.length} -
                  {dataForTable.length <= 1 ? ' Etiqueta gerada' : ' Etiquetas geradas'}
                </Typography>
              </Box>
              <TableContainer component={Paper} sx={{ mt: 1 }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <Button
                          sx={{ color: 'white', bgcolor: 'red' }}
                          onClick={handleDeleteButton}
                          disabled={dataForTable.filter(x => x.delete).length === 0}
                        >
                          Excluir
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">Codigo Produto</StyledTableCell>
                      <StyledTableCell align="center">Nome do Produto</StyledTableCell>
                      <StyledTableCell align="center">Quantidade</StyledTableCell>
                      <StyledTableCell align="center">Lote</StyledTableCell>
                      <StyledTableCell align="center">Pallet</StyledTableCell>
                      <StyledTableCell align="center">Validade</StyledTableCell>
                      <StyledTableCell align="center">Peças</StyledTableCell>
                      <StyledTableCell align="center">Serial</StyledTableCell>
                      <StyledTableCell align="center">Picking</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataForTable
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          {/* <StyledTableCell component="th" scope="row">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '80px',
                          }}
                        >
                          <DeleteForeverIcon
                            onClick={() => removeRequestProducts(row.productItensId)}
                            sx={{ cursor: 'pointer' }}
                          />
                        </Box>
                      </StyledTableCell> */}
                          <StyledTableCell component="th" scope="row">
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '80px',
                              }}
                            >
                              {/* <DeleteForeverIcon
                          
                            onClick={() => handleAlertDelete(row.movimentationOrderItemId)}
                            sx={{ cursor: 'pointer' }}
                          /> */}
                              <Checkbox
                                icon={<DeleteOutline />}
                                checkedIcon={<Delete />}
                                sx={{
                                  color: 'primary',
                                  '&.Mui-checked': {
                                    color: red.A200,
                                  },
                                }}
                                checked={row.delete}
                                onChange={() => handleCheckDelete(row)}
                              />
                              <ModeEditOutlineIcon
                                onClick={() => handleEdit(row)}
                                sx={{ cursor: 'pointer' }}
                              />
                            </Box>
                          </StyledTableCell>

                          <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                          <StyledTableCell align="center">{row.productName}</StyledTableCell>
                          <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                          <StyledTableCell align="center">{row.lote}</StyledTableCell>
                          <StyledTableCell align="center">{row.numberPallet}</StyledTableCell>
                          <StyledTableCell align="center">
                            {new Date(row.datetimeExpiration).toLocaleDateString()}
                          </StyledTableCell>
                          <StyledTableCell align="center">{row.piece}</StyledTableCell>
                          <StyledTableCell align="center">{row.serial}</StyledTableCell>
                          <StyledTableCell align="center">{row.picking}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={requestProductsConverted.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              {' '}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h5">
                  {dataForTableData.length} -
                  {dataForTableData.length <= 1 ? ' Etiqueta gerada' : ' Etiquetas geradas'}
                </Typography>
              </Box>
              <TableContainer component={Paper} sx={{ mt: 1 }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Codigo Produto</StyledTableCell>
                      <StyledTableCell align="center">Nome do Produto</StyledTableCell>
                      <StyledTableCell align="center">Quantidade</StyledTableCell>
                      <StyledTableCell align="center">Referência</StyledTableCell>
                      <StyledTableCell align="center">Pallet</StyledTableCell>
                      <StyledTableCell align="center">Peças</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataForTableData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          {/* <StyledTableCell component="th" scope="row">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '80px',
                          }}
                        >
                          <DeleteForeverIcon
                            onClick={() => removeRequestProducts(row.productItensId)}
                            sx={{ cursor: 'pointer' }}
                          />
                        </Box>
                      </StyledTableCell> */}
                          <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                          <StyledTableCell align="center">{row.productName}</StyledTableCell>
                          <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                          <StyledTableCell align="center">{row.reference}</StyledTableCell>
                          <StyledTableCell align="center">{row.numberPallet}</StyledTableCell>

                          <StyledTableCell align="center">{row.typePiece}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={requestProductsConverted.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default LabelOrderEdit
