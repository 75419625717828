import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  addProductOrderList,
  confirmOrder,
  getProductAssociationService,
  getProductCodService,
  returnOrderStatus,
  searchProductService,
} from '../../../services/productService'
import { Context } from '../../../context/AuthContext'
import {
  confirmOrderService,
  deleteProductListMovimentations,
  deleteProductMovimentationOrderByIdPartner,
  editTransferOrderService,
  getUniqueTransferOrderService,
} from '../../../services/requestServices'

import * as XLSX from 'xlsx'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { AiOutlineFileExcel } from 'react-icons/ai'
import PropTypes from 'prop-types'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { colorTableDefault } from '../../TemplateDefault'
import { Search } from '@mui/icons-material'

const TransferOrderItem = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
  movimentationOrderId,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const subCompanyId = subCompany[0].subCompanyId
  const [loading, setLoading] = useState(false)

  const token = localStorage.getItem('token')
  const [requestProductsConverted, setRequestProductsConverted] = useState([])
  const [movimentationsERPitems, setMovimentationsERPitems] = useState([])

  let requestProductsModel = {}
  let movimentationsERPitemsModel = {}

  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [subCompanyIdFrom, setSubCompanyIdFrom] = useState('')
  const [subCompanyIdTo, setSubCompanyIdTo] = useState('')
  const [movimentationOrderNumberBihands, setMovimentationOrderNumberBihands] = useState('')
  const [receiveMovimentationOrderDate, setReceiveMovimentationOrderDate] = useState('')
  const [createRequestMovimentationOrderDate, setCreateRequestMovimentationOrderDate] = useState('')
  const [typeOrderName, setTypeOrderName] = useState('')
  const [subCompanyNameFrom, setSubCompanyNameFrom] = useState('')
  const [subCompanyNameTo, setSubCompanyNameTo] = useState('')
  const [movimentationOrderStatus, setMovimentationOrderStatus] = useState()
  const [isReceived, setIsReceived] = useState()
  const [finalizado, setFinalizado] = useState(false)
  const [alocationStockDe, setAlocationStockDe] = useState('')
  const [alocationStockPara, setAlocationStockPara] = useState('')
  const [open, setOpen] = useState(false)

  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const navigate = useNavigate()

  let dateTime = new Date()

  const [alertSuccess, setAlertSuccess] = useState(false)
  const [alertFailed, setAlertFailed] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [newItem, setNewItem] = useState(false)

  const [productSelected, setProductSelected] = useState({})
  const [produtos, setProdutos] = useState([])
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState('')
  const [movimentationOrderItemId, setMovimentationOrderItemId] = useState('')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const requestProductsConverter = rp => {
    let array = []
    rp.forEach(element => {
      requestProductsModel = {
        serial: element.serial,
        createDate: element.createDate,
        productName: element.productName,
        price: element.price,
        sku: element.sku,
        movimentationOrderId: element.movimentationOrderId,
        movimentationOrderItemId: element.movimentationOrderItemId,
        productItensId: element.productItensId,
        idProductPartner: element.idProductPartner,
        createDateRead: dateTime.toISOString(),
      }

      array.push(requestProductsModel)
    })

    setRequestProductsConverted(array)
  }

  const movimentationsERPitemsCollection = useCallback(rp => {
    let array = []
    rp.forEach(element => {
      movimentationsERPitemsModel = {
        barCode: element.barCode,
        color: element.color,
        finishGood: element.finishGood,
        idProductPartner: element.idProductPartner,
        movimentationOrderId: element.movimentationOrderId,
        productName: element.productName,
        quantity: element.quantity,
        quantityFound: element.quantityFound,
        quantityFraction: element.quantityFraction,
        quantityNotFound: element.quantityNotFound,
        reference: element.reference,
        size: element.size,
        typePiece: element.typePiece,
      }

      array.push(movimentationsERPitemsModel)
    })

    setMovimentationsERPitems(array)
  }, [])

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    finishDownload()
  }

  const finishDownload = async () => {
    let result = requestProductsConverted

    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        Serial: item.serial,
        'Nome Produto': item.productName,
        Preço: parseFloat(item.price).toLocaleString('pt-br', { minimumFractionDigits: 2 }),
        SKU: item.sku,

        Criado: new Date(item.createDate || item.created).toLocaleString(),
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transferência')
    XLSX.writeFile(workbook, `Transferência.xlsx`)
  }

  const getTransferOrder = async () => {
    setLoading(true)
    try {
      const response = await getUniqueTransferOrderService(token, nameUrl, movimentationOrderId)
      requestProductsConverter(response.data.movimentationOrderItemCollection)
      movimentationsERPitemsCollection(response.data.movimentationOrderItemCollection)
      setSubCompanyIdFrom(response.data.subCompanyIdFrom)
      setSubCompanyIdTo(response.data.subCompanyIdTo)
      setMovimentationOrderNumberOtherSystem(response.data.movimentationOrderNumberOtherSystem)
      setMovimentationOrderNumberBihands(response.data.movimentationOrderNumberBihands)
      setReceiveMovimentationOrderDate(response.data.receiveMovimentationOrderDate)
      setCreateRequestMovimentationOrderDate(response.data.createRequestMovimentationOrderDate)
      setTypeOrderName('Transferência entre filiais')
      setSubCompanyNameFrom(response.data.subCompanyNameFrom)
      setSubCompanyNameTo(response.data.subCompanyNameTo)
      setMovimentationOrderStatus(returnOrderStatus(response.data.movimentationOrderStatus))
      setAlocationStockDe(response.data.alocationStockDe)
      setAlocationStockPara(response.data.alocationStockPara)
      if (
        response.data.movimentationOrderStatus === 2 ||
        response.data.movimentationOrderStatus === -2
      ) {
        setFinalizado(true)
      }
      setIsReceived(response.data.isReceived)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const editTransferOrder = useCallback(async () => {
    setAlertSuccess(false)
    setAlertFailed(false)
    setLoading(true)

    const data = requestProductsConverted.map(item => ({
      productItensId: item.productItensId,
      serial: item.serial,
      created: item.created,
      productName: item.productName,
      price: item.price,
      sku: item.sku,
      lote: item.lote,
      dataExpiration: item.dataExpiration,
      idProductPartner: item.idProductPartner,
      movimentationOrderId: movimentationOrderId,
      movimentationOrderItemId: item.movimentationOrderItemId,
    }))

    try {
      const response = await addProductOrderList(token, nameUrl, data)

      setLoading(false)
      setSeverity('success')
      setTitle('Transferencia editada com sucesso!')
      setEditing(false)
      setAlerta(true)
      handleFetchData()
    } catch (err) {
      console.log(err)
      setLoading(false)
      setSeverity('error')
      setTitle('Erro ao editar, verifique os dados e tente novamente.')
      setAlerta(true)
    }
  }, [
    movimentationOrderNumberOtherSystem,
    subCompanyIdFrom,
    subCompanyIdTo,
    requestProductsConverted,
  ])

  const handleDelete = idM => {
    setOpen(true)
    setMovimentationOrderItemId(idM)
  }

  const deleteProductOrder = async () => {
    setOpen(false)
    setLoading(true)
    const data = {
      movimentationOrderItemId: movimentationOrderItemId,
      movimentationOrderId: movimentationOrderId,
    }

    try {
      const response = await deleteProductListMovimentations(token, nameUrl, data)
      setSeverity('success')
      setTitle(response.data)
      setAlerta(true)
      getTransferOrder()
      setLoading(false)
    } catch (error) {
      console.log(error)
      setSeverity('error')
      setTitle('Desculpe, ocorreu um erro interno no servidor. Tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getTransferOrder()
    //setTreeView()
  }, [movimentationOrderId])

  const handleConfirm = async () => {
    setLoading(true)
    try {
      await confirmOrder(token, nameUrl, movimentationOrderId)
      setLoading(false)
      setSeverity('success')
      setTitle('Transferencia editada com sucesso!')
      setEditing(false)
      setAlerta(true)
      handleFetchData()
    } catch (err) {
      console.log(err)
      setLoading(false)
      setSeverity('error')
      setTitle('Erro ao editar, verifique os dados e tente novamente.')
      setAlerta(true)
    }
  }

  const handleClose = () => {
    setAlertSuccess(false)
    setAlertFailed(false)
    setOpen(false)
  }

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          CompanyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search, , subCompanyId])

  const checkIfProductExists = serialId => {
    return requestProductsConverted.some(item => item.serial === serialId)
  }

  const addProductOrder = async () => {
    const data = {
      rfidCode: selectedProduct,
      subCompanyId: subCompanyIdFrom,
    }

    try {
      setLoading(true)
      const response = await getProductCodService(token, nameUrl, data)
      const product = response.data

      const listProduct = {
        serial: product.serial,
        createDate: product.created,
        productName: product.productName,
        price: product.price,
        sku: product.sku,
        createDateRead: product.createDateRead,
        productId: product.productId,
        idProductPartner: product.idProductPartner,
        productItensId: product.productItensId,
      }

      const isDuplicate = requestProductsConverted.some(
        existingItem => existingItem.serial === product.serial
      )

      if (isDuplicate) {
        setLoading(false)
        setSeverity('error')
        setTitle(`O produto com serial ${product.serial} já está na lista.`)
        setAlerta(true)
      } else {
        setRequestProductsConverted(prevListProductEnv => [...prevListProductEnv, listProduct])

        setLoading(false)
        setSeverity('success')
        setTitle('Produto adicionado com sucesso!')
        setAlerta(true)
        setSelectedProduct('')
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
      setSeverity('error')
      setTitle('Erro ao adicionar produtos, verifique os dados e tente novamente.')
      setAlerta(true)
    }
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          mt: 7,
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Dialog
          open={downloading}
          keepMounted
          //onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Dialog
          open={newItem}
          keepMounted
          //onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
            sx={{ padding: 3 }}
          >
            {/* <Box>
              <InputLabel id="demo-simple-select-label">Adicionar Peças</InputLabel>
              <Select
                label="Adicionar Peças"
                value={selectProductId}
                onChange={values => setSelectProductId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {product.map((item, index) => {
                  return (
                    <MenuItem value={item.productItensId} key={index}>
                      {item.productName}
                    </MenuItem>
                  )
                })}
              </Select>
              </Box>*/}
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"> Deseja excluir este item?</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose}>Não</Button>
              <Button onClick={deleteProductOrder} autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>
        </Dialog>
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={alertSuccess} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Itens Salvos com Sucesso!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alertFailed}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Algo deu Errado!
          </Alert>
        </Snackbar>
        <Box component="form" sx={{ mt: '2rem' }}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={movimentationOrderNumberBihands}
                name="movimentationOrderNumberBihands"
                id="standard-basic"
                label="Ordem Bihands"
                variant="standard"
                // onChange={(values) =>
                //   setDepositName(values.target.value)
                // }
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="standard-basic"
                label="Ordem Outro Sistema"
                variant="standard"
                name="MovimentationOrderNumberOtherSystem"
                value={movimentationOrderNumberOtherSystem}
                //onChange={(values) => setIdDepositPartner(values.target.value)}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="ReceiveMovimentationOrderDate"
                value={new Date(receiveMovimentationOrderDate).toLocaleString()}
                id="standard-basic"
                label="Data do Recebimento"
                variant="standard"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="standard-basic"
                label="Data da Solicitação"
                variant="standard"
                name="CreateRequestMovimentationOrderDate"
                value={new Date(createRequestMovimentationOrderDate).toLocaleString()}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="TypeOrder"
                value={typeOrderName}
                id="standard-basic"
                label="Tipo de Pedido"
                variant="standard"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="standard-basic"
                label="Filial Saida"
                variant="standard"
                name="SubCompanyNameFrom"
                value={subCompanyNameFrom}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="subCompanyNameTo"
                value={subCompanyNameTo}
                id="standard-basic"
                label="Filial Entrada"
                variant="standard"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="standard-basic"
                label="Status da movimentação"
                variant="standard"
                name="MovimentationOrderStatus"
                value={movimentationOrderStatus ? movimentationOrderStatus : ''}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="alocationStockDe"
                value={alocationStockDe}
                id="standard-basic"
                label="Locação de"
                variant="standard"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="standard-basic"
                label="Locação para"
                variant="standard"
                name="alocationStockPara"
                value={alocationStockPara}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="IsReceived"
                value={isReceived ? 'Sim' : 'Não'}
                id="standard-basic"
                label="Recebido"
                variant="standard"
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            width={{ xs: '40%', sm: '40%', md: '20%', lg: '20%', xl: '20%' }}
            sx={{ mt: '1rem', alignItems: 'center' }}
          ></Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          {!finalizado && (
            <Box>
              <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                <Box width="100%" maxWidth="100%">
                  {movimentationOrderStatus === 'Aberto' && (
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      position="relative"
                      margin="1.5rem 0"
                    >
                      <Box width="100%" maxWidth="70%" textAlign="start">
                        <InputLabel textAlign="start" id="demo-simple-select-label">
                          Novo item
                        </InputLabel>
                      </Box>
                      <TextField
                        sx={{ width: '100%', maxWidth: '70%' }}
                        value={selectedProduct}
                        onChange={e => {
                          setSelectedProduct(e.target.value)
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            addProductOrder()
                          }
                        }}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={addProductOrder} onKe3>
                              <Search />
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                  )}

                  <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
                    <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                      <Button
                        type="button"
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={() => setEditing(false)}
                      >
                        Cancelar
                      </Button>
                    </Box>
                    {movimentationOrderStatus === 'Aberto' ? (
                      <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                        <Button
                          sx={{ width: '100%', ml: 1 }}
                          onClick={editTransferOrder}
                          variant="contained"
                        >
                          Salvar Itens
                        </Button>
                      </Box>
                    ) : (
                      <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                        <Button
                          sx={{ width: '100%', ml: 1 }}
                          onClick={() => handleConfirm()}
                          variant="contained"
                        >
                          Confirmar
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              {requestProductsConverted && (
                <Box width="100%" textAlign="end" marginTop={3}>
                  <Button onClick={downloadData}>
                    <AiOutlineFileExcel size="2.5rem" />
                  </Button>
                </Box>
              )}
            </Box>
          )}

          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="center">Serial</StyledTableCell>
                  <StyledTableCell align="center">Data de Criação</StyledTableCell>
                  <StyledTableCell align="center">Data do Recebimento</StyledTableCell>
                  <StyledTableCell align="center">Nome do Produto</StyledTableCell>
                  <StyledTableCell align="center">Preço</StyledTableCell>
                  <StyledTableCell align="center">SKU</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requestProductsConverted
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '80px',
                          }}
                        >
                          {!finalizado && (
                            <DeleteForeverIcon
                              onClick={() => handleDelete(row.movimentationOrderItemId)}
                              sx={{ cursor: 'pointer' }}
                            />
                          )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.serial}</StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(row.createDate).toLocaleString() ||
                          new Date(row.created).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">{'-'}</StyledTableCell>
                      <StyledTableCell align="center">{row.productName}</StyledTableCell>
                      <StyledTableCell align="center">{row.price}</StyledTableCell>
                      <StyledTableCell align="center">{row.sku}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={requestProductsConverted.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default TransferOrderItem
