import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { useFormik } from 'formik'
import { editProductService, getUniqueProductService } from '../../../services/productService'
import PermissionComponent from '../../../permissions'

const ProductEdit = ({
  setExpanded,
  id,
  setTitle,
  setSeverity,
  setRegister,
  setName,
  setEditing,
  getAllProducts,
}) => {
  const { userRoles, subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(false)
  const [productId, setProductId] = useState()

  const formik = useFormik({
    initialValues: {
      productId: '',
      companyId: '',
      idProductPartner: '',
      name: '',
      size: '',
      color: '',
      brand: '',
      supplier: '',
      price: '',
      sku: '',
      referencia: '',
      codColor: '',
      laboratorio: '',
      dataExpiration: '',
      barCode: '',
      picking: '',
      typeProduct: '',
      active: true,
      description: '',
      info: '',
      faviconUrl: '',
      companyName: '',
      companyCNPJ: '',
      created: '',
      link: '',
      piece: '',
      quantityTime: '',
      stockActual: '',
      typePiece: '',
      variation: '',
      installment: '',
    },

    onSubmit: async values => {
      setLoading(true)
      let dateNow = new Date()
      values.created = dateNow.toISOString()

      try {
        const response = await editProductService(token, nameUrl, values)
        console.log(response, 'values')
        formik.resetForm()
        setTitle('Produto editado com sucesso!')
        setSeverity('success')
        setRegister(true)
        getAllProducts()
        setEditing(false)
        setExpanded(false)
      } catch (e) {
        setTitle('Desculpe, ocorreu um erro interno no servidor. Tente novamente mais tarde.')
        setSeverity('error')
        setRegister(true)
        console.log(e)
      }
      setLoading(false)
    },
  })

  useEffect(() => {
    fetchCompanies()
  }, [])

  const fetchCompanies = () => {
    let arrayCompanies = []
    subCompany.forEach(element => {
      const filter = arrayCompanies.some(company => company.companyId === element.companyId)
      if (!filter) {
        arrayCompanies.push(element)
      }
    })
    setCompanies(arrayCompanies)
  }

  const onEditClick = e => {
    e.stopPropagation()
  }

  useEffect(() => {
    getProduct()
  }, [id])

  const getProduct = async () => {
    setProductId(id)
    setLoading(true)
    try {
      const response = await getUniqueProductService(token, nameUrl, id)
      formik.setValues({
        companyId: response.data.companyId,
        idProductPartner: response.data.idProductPartner,
        name: response.data.name,
        size: response.data.size,
        color: response.data.color,
        brand: response.data.brand,
        supplier: response.data.supplier,
        price: response.data.price,
        sku: response.data.sku,
        referencia: response.data.referencia,
        codColor: response.data.codColor,
        laboratorio: response.data.laboratorio,
        dataExpiration: new Date(response.data.dataExpiration),
        barCode: response.data.barCode,
        picking: response.data.picking,
        typeProduct: response.data.typeProduct,
        active: response.data.active,
        description: response.data.description,
        info: response.data.info,
        faviconUrl: response.data.faviconUrl,
        companyName: response.data.companyName,
        companyCNPJ: response.data.companyCNPJ,
        created: response.data.created,
        link: response.data.link,
        piece: response.data.piece,
        quantityTime: response.data.quantityTime,
        stockActual: response.data.stockActual,
        typePiece: response.data.typePiece,
        variation: response.data.variation,
        installment: response.data.installment,
        productId: id,
      })
      setName(response.data.name)
      setLoading(false)
    } catch (err) {
      setTitle('Desculpe, ocorreu um erro interno no servidor. Tente novamente mais tarde.')
      setSeverity('error')
      setRegister(true)
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: '2rem' }}>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
        <Select
          value={formik.values.companyId}
          //sx={{width:'100%'}}
          label="Selecione a empresa"
          name="companyId"
          onChange={formik.handleChange}
          onClick={e => onEditClick(e)}
        >
          <MenuItem disabled value="">
            <em>Empresa</em>
          </MenuItem>
          {companies.map((item, index) => {
            return (
              <MenuItem value={item.companyId} key={index}>
                {item.nameCompany}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            value={formik.values.idProductPartner}
            id="standard-basic"
            label="Identificação do ERP"
            variant="standard"
            name="idProductPartner"
            onChange={formik.handleChange}
            type="text"
            onClick={e => onEditClick(e)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Nome"
            variant="standard"
            value={formik.values.name}
            name="name"
            onChange={formik.handleChange}
            type="text"
            onClick={e => onEditClick(e)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Tamanho"
            variant="standard"
            value={formik.values.size}
            name="size"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Cor"
            variant="standard"
            value={formik.values.color}
            name="color"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Fornecedor"
            variant="standard"
            value={formik.values.supplier}
            name="supplier"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Marca"
            variant="standard"
            value={formik.values.brand}
            name="brand"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Preço"
            variant="standard"
            value={formik.values.price}
            pattern="[\d,.?!]*"
            name="price"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="SKU"
            variant="standard"
            value={formik.values.sku}
            name="sku"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Referência do Produto (Opcional)"
            variant="standard"
            value={formik.values.referencia}
            name="referencia"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <PermissionComponent role="FIELDS_BARCODE">
            <TextField
              sx={{ width: '100%', mt: '1rem' }}
              id="standard-basic"
              label="Código de barras"
              variant="standard"
              value={formik.values.barCode}
              name="barCode"
              onChange={formik.handleChange}
              onClick={e => onEditClick(e)}
              type="text"
            />
          </PermissionComponent>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Código da cor"
            variant="standard"
            value={formik.values.codColor}
            name="codColor"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Tipo Produto"
            variant="standard"
            value={formik.values.typeProduct}
            name="typeProduct"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <PermissionComponent role="FIELDS_LAB">
            <TextField
              sx={{ width: '100%', mt: '1rem' }}
              id="standard-basic"
              label="Laboratório"
              variant="standard"
              value={formik.values.laboratorio}
              name="Laboratório"
              onChange={formik.handleChange}
              onClick={e => onEditClick(e)}
              type="text"
            />
          </PermissionComponent>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <PermissionComponent role="FIELD_DATE_VALID">
            <TextField
              sx={{ width: '100%', mt: '1rem' }}
              id="standard-basic"
              label="Data de validade"
              variant="standard"
              value={formik.values.dataExpiration}
              name="dataExpiration"
              onChange={formik.handleChange}
              onClick={e => onEditClick(e)}
              type="date"
            />
          </PermissionComponent>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Posição de baixa"
            variant="standard"
            value={formik.values.picking}
            name="picking"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Quantidade tempo"
            variant="standard"
            name="quantityTime"
            value={formik.values.quantityTime}
            onChange={formik.handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Estoque atual"
            variant="standard"
            value={formik.values.stockActual}
            name="stockActual"
            onChange={formik.handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <InputLabel sx={{ mt: '1rem' }} id="demo-simple-select-label">
            Tipo Peça
          </InputLabel>
          <Select
            sx={{ width: '100%' }}
            id="standard-basic"
            label="Tipo peça"
            variant="standard"
            value={formik.values.typePiece}
            name="typePiece"
            onChange={formik.handleChange}
          >
            <MenuItem value={0}>Peça</MenuItem>
            <MenuItem value={1}>Box</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {' '}
          <TextField
            sx={{ width: '100%', mt: '1.2rem' }}
            id="standard-basic"
            label="Variação"
            variant="standard"
            value={formik.values.variation}
            name="variation"
            onChange={formik.handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <InputLabel sx={{ width: '100%', mt: '1rem' }}>Ativo</InputLabel>
          <Checkbox
            id="standard-basic"
            label="Ativo"
            variant="standard"
            value={formik.values.active}
            name="active"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="checkbox"
            checked={formik.values.active}
          />
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Typography sx={{ textAlign: 'center' }}>Opcional</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Parcelamento"
            variant="standard"
            value={formik.values.installment}
            name="description"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Descrição"
            variant="standard"
            value={formik.values.description}
            name="description"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            sx={{ width: '100%', mt: '1rem' }}
            id="standard-basic"
            label="Informações Adicionais"
            variant="standard"
            value={formik.values.info}
            name="info"
            onChange={formik.handleChange}
            onClick={e => onEditClick(e)}
            type="text"
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          mt: '1rem',
        }}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
      >
        <Box
          width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
          sx={{
            mt: '1rem',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            sx={{ justifySelf: 'center', width: { sm: 1, md: 0.5 } }}
            type="submit"
          >
            Editar produto
          </Button>
        </Box>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default ProductEdit
