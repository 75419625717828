import { Alert, Backdrop, Box, Button, CircularProgress, Snackbar, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteFaction } from '../../../services/factionService'
import { Context } from '../../../context/AuthContext'

const FactionDelete = () => {
  const { nameUrl } = useContext(Context)

  const token = localStorage.getItem('token')
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const handleClose = () => {
    setError(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await deleteFaction(token, nameUrl, id)
      navigate('/ReportFaccao')
    } catch (e) {
      console.log(e)
      setLoading(false)
      setError(true)
    }
  }

  return (
    <Box>
      <Typography variant="h5" sx={{ mt: 3 }}>
        Deseja realmente excluir esta Facção?
      </Typography>
      <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
        <Button variant="contained" onClick={() => handleDelete()}>
          Excluir
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate('/ReportFaccao')}
          sx={{ ml: 2 }}
          color="error"
        >
          Cancelar
        </Button>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={error}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Houve um erro ao excluir!
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default FactionDelete
