import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { searchProductService } from '../../../services/productService'
import { addTagService } from '../../../services/movimentationRouteServices'
import { useDebounce } from '../../View/Product/Components/useDebounce'
import { getPrinterZplService } from '../../../services/configServices'

const AddTable = ({
  labelOrderId,
  movimentationOrderNumberOtherSystem,
  subCompanyId,
  getProduct,
  getLabelOrder,
  setAddItem,
  setAlertSuccessDelete,
  setTitleDelete,
  setAlertFailedDelete,
}) => {
  const token = localStorage.getItem('token')
  const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  const { subCompany, company, nameUrl } = useContext(Context)
  const { debounce } = useDebounce(1000, true)
  const [printerZpl, setPrinterZpl] = useState([])
  const [printZplId, setPrintZplId] = useState('')

  const [produtos, setProdutos] = useState([])
  const [searching, setSearching] = useState(false)
  const [search, setSearch] = useState('')
  const [quantity, setQuantity] = useState('')
  const [lote, setLote] = useState('')
  const [dateTimeExpiration, setDateTimeExpiration] = useState(new Date())
  const [numberPallet, setNumberPallet] = useState('')
  const [loading, setLoading] = useState(false)
  const [prodSelect, setProdSelect] = useState('')
  const [row, setRow] = useState()
  const [selectedId, setSelectedId] = useState(undefined)
  const [singleProd, setSingleProd] = useState({
    name: '',
    referencia: '',
    sku: '',
    quantity: '',
    idProductPartner: '',
  })
  const [searchingPrintZpl, setSearchingPrintZpl] = useState(false)
  const [rowPrintZpl, setRowPrintZpl] = useState()
  const [searchPrintZpl, setSearchPrintZpl] = useState('')

  useEffect(() => {
    if (company.companyId) {
      setSearching(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search])

  const handleGetAllProductsSearch = async () => {
    setSearching(true)
    const dataProduct = {
      companyId: subCompany[0].companyId,
      SearchWord: search,
      limit: 10,
      offset: 0,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProdutos(data)
        setSearching(false)
      })
    } catch (error) {
      console.error(error)
      setSearching(false)
    }
  }

  useEffect(() => {
    if (row) {
      setSingleProd({
        idProductPartner: row.idProductPartner,
        name: row.name,
        quantity: row.quantity,
        referencia: row.referencia,
        sku: row.sku,
      })
    }
  }, [row])

  const handleSelectFilial = () => {
    const selectCnpj = subCompany.filter(sub => sub.subCompanyId === subCompanyId)
    setSubCompanyCNPJ(selectCnpj[0].cnpj)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const data = {
        movimentationOrderId: labelOrderId,
        subCompanyCNPJ,
        movimentationOrderNumberOtherSystem,
        productName: prodSelect.name,
        idProductPartner: prodSelect.idProductPartner,
        quantity: Number(quantity),
        idProductPartner: selectedId,
        printerZplId: printZplId,
        finishGood: true,
        dataExpiration: new Date(dateTimeExpiration + '-20'),
        dateTimeExpiration: new Date(dateTimeExpiration + '-20'),
        lote,
        numberPallet,
      }
      console.log('data ', data)
      await addTagService(token, nameUrl, data)
      getProduct()
      getLabelOrder()
      setTitleDelete('Adicionado com sucesso!')
      setAlertSuccessDelete(true)
      setAddItem(false)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setAlertFailedDelete(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    handleSelectFilial()
  }, [])

  useEffect(() => {
    if (subCompanyId) {
      getPrinterZpl()
    }
  }, [subCompanyId])

  const getPrinterZpl = async () => {
    setLoading(true)
    try {
      const response = await getPrinterZplService(
        token,
        nameUrl,
        subCompanyId,
        500,
        0,
        'desc',
        searchPrintZpl
      )
      setPrinterZpl(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      setSearchingPrintZpl(true)
      debounce(() => {
        getPrinterZpl()
      })
    }
  }, [searchPrintZpl])

  return (
    <Box sx={{ mt: 2, border: '1px solid black', p: 2 }}>
      <Typography>Adicionar Impressão</Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
          <FormControl sx={{ width: 1 }}></FormControl>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="modelos">Modelos de etiqueta</InputLabel>
              <Autocomplete
                openText="Abrir"
                closeText="Fechar"
                clearText="Apagar"
                noOptionsText={searchingPrintZpl ? 'Carregando...' : 'Nenhum resultado encontrado'}
                loadingText="Carregando..."
                disablePortal
                value={rowPrintZpl}
                disabled={!subCompanyId}
                loading={searchingPrintZpl}
                onInputChange={(_, newValue) => setSearchPrintZpl(newValue)}
                popupIcon={
                  searchingPrintZpl ? <CircularProgress color="warning" size={20} /> : undefined
                }
                onChange={(_, newValue) => {
                  if (newValue === null) {
                    setPrintZplId(null)
                    setRowPrintZpl(null)
                    setSearchPrintZpl('')
                    getPrinterZpl()
                  } else {
                    setPrintZplId(newValue?.printerZplId)
                    setRow(newValue)
                    setSearchPrintZpl(newValue.name)
                  }
                }}
                options={printerZpl}
                getOptionLabel={option => `${option.name}`}
                renderInput={params => (
                  <TextField {...params} label="Pesquisar Modelo de etiqueta" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              openText="Abrir"
              closeText="Fechar"
              clearText="Apagar"
              noOptionsText={searching ? 'Carregando...' : 'Nenhum resultado encontrado'}
              loadingText="Carregando..."
              disablePortal
              value={row}
              loading={searching}
              onInputChange={(_, newValue) => setSearch(newValue)}
              popupIcon={searching ? <CircularProgress color="warning" size={20} /> : undefined}
              onChange={(_, newValue) => {
                if (newValue === null) {
                  setSelectedId(null)
                  setRow(null)
                  setSearch('')
                  handleGetAllProductsSearch()
                } else {
                  setSelectedId(newValue?.idProductPartner)
                  setRow(newValue)
                  setSearch(newValue.referencia)
                }
              }}
              options={produtos}
              getOptionLabel={option => `${option.referencia} - ${option.name}`}
              renderInput={params => <TextField {...params} label="Pesquisar produtos" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ width: '100%' }}
              value={singleProd.name}
              id="outlined-basic"
              variant="outlined"
              type="text"
              label="Nome Produto"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ width: '100%' }}
              value={singleProd.referencia}
              id="outlined-basic"
              disabled
              variant="outlined"
              type="text"
              label="Referência"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ width: '100%' }}
              value={singleProd.sku}
              id="outlined-basic"
              variant="outlined"
              label="SKU"
              type="text"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ width: '100%' }}
              value={quantity}
              onChange={values => setQuantity(values.target.value)}
              id="outlined-basic"
              label="Quantidade"
              variant="outlined"
              type="text"
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="contained" type="submit" sx={{ mt: 2 }}>
            Salvar
          </Button>
          <Button
            variant="contained"
            type="button"
            sx={{ mt: 2 }}
            onClick={() => setAddItem(false)}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default AddTable
