import React, { useContext, useEffect, useState, useCallback } from 'react'
import { FastForwardOutlined } from '@mui/icons-material'
import { Alert, Box, Button, FormControl, Snackbar, TextField } from '@mui/material'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../../context/AuthContext'
import { createMovBreakService } from '../../../../services/movBreakServices'

const Naofrac = props => {
  const { movBreak, totalQtd, movOrder, getMovBreak } = props
  const { userId, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')

  const [partes, setPartes] = useState('')
  const [enviando, setEnviando] = useState(false)

  const [snackOpen, setSnackOpen] = useState(false)
  const [snack, setSnack] = useState({ severity: 'error', message: 'Erro' })

  const sendFraction = async () => {
    setEnviando(true)
    if (!partes) {
      setSnackOpen(true)
      setSnack({ severity: 'error', message: 'Favor preencher a quantidade de fracionamentos' })
      setEnviando(false)
      return
    }
    const data = {
      movimentationOrderNumberOtherSystem: movOrder.movimentationOrderNumberOtherSystem,
      movimentationOrderId: movOrder.movimentationOrderId,
      quantityOrder: parseInt(partes),
      userId,
    }
    try {
      const response = await createMovBreakService(token, nameUrl, data)
      setTimeout(() => {
        getMovBreak()
        setEnviando(false)
      }, 1000)
    } catch (e) {
      setSnackOpen(true)
      setSnack({ severity: 'error', message: 'Ocorreu um erro ao fracionar' })
      setEnviando(false)
    }
  }

  useEffect(() => {}, [])
  const navigate = useNavigate()
  return (
    <>
      <Alert severity="info">Esta ordem não está fracionada</Alert>
      <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2, justifyContent: 'space-between' }}>
        <FormControl sx={{ width: 0.25 }}>
          <TextField
            sx={{ width: '100%' }}
            value={movOrder.quantityTotal}
            id="outlined-basic"
            variant="outlined"
            label="Quantidade total"
            type="text"
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: 0.25 }}>
          <TextField
            sx={{ width: '100%' }}
            value={totalQtd.parsed}
            id="outlined-basic"
            variant="outlined"
            label="Quantidade items"
            type="text"
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: 0.25 }}>
          <TextField
            //sx={{ width: '100%' }}
            fullWidth
            value={partes}
            onChange={values => setPartes(values.target.value)}
            type="text"
            id="qtd-partes"
            variant="outlined"
            label="Fracionamentos"
          />
        </FormControl>
        <FormControl sx={{ width: 0.25 }}>
          <TextField
            sx={{ width: '100%' }}
            value={partes ? totalQtd.result / partes : ''}
            disabled
            type="text"
            id="qtd-partesParsed"
            variant="outlined"
            label="Items/Fração"
          />
        </FormControl>
      </Box>
      <Box sx={{ gap: 2, display: 'flex', mt: 2 }}>
        <Button
          variant="contained"
          startIcon={<FastForwardOutlined />}
          disabled={enviando}
          onClick={sendFraction}
        >
          Fracionar
        </Button>
        <Button
          color="warning"
          variant="contained"
          startIcon={<BiArrowBack />}
          onClick={() => navigate('/MovBreak')}
        >
          Voltar
        </Button>
      </Box>

      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackOpen(false)} severity={snack.severity} sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Naofrac
