import { useCallback, useContext, useEffect, useState } from 'react'
import {
  createPrinterZplService,
  editPrinterZplService,
  getUniquePrinterZplService,
} from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
} from '@mui/material'
import { labelaryService } from '../../../services/printerZPLDefaultServices'

const PrinterZplCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const token = localStorage.getItem('token')
  const [name, setName] = useState('')
  const [zpl, setZpl] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [zplLab, setZplLab] = useState('')

  const vertical = 'top'
  const horizontal = 'center'

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  const visualizarZPL = async () => {
    setPreviewOpen(true)

    const zplLabelary = await labelaryService(zpl)
    console.log(zplLabelary, 'sadsa')

    let base64ImageString = Buffer.from(zplLabelary.data, 'binary').toString('base64')

    setZplLab('data:image/png;base64,' + base64ImageString)
  }

  const createPrinterZpl = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      const printerZpl = {
        name,
        zpl,
        subCompanyId,
      }

      try {
        await createPrinterZplService(token, nameUrl, printerZpl)
        setLoading(false)
        setSeverity('success')
        setTitle('ZPL cadastrada com sucesso!')
        setExpanded(false)
        setAlerta(true)
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setSeverity('error')
        setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
        setAlerta(true)
      }
    },
    [name, zpl, subCompanyId]
  )

  const handleClose = () => {
    setError(false)
    setPreviewOpen(false)
  }

  const handleZplChange = event => {
    const newZpl = event.target.value
    setZpl(newZpl)
    loadPreviewImage(newZpl)
  }

  const loadPreviewImage = async () => {
    try {
      const zplLabelary = await labelaryService(zpl)
      if (zplLabelary && zplLabelary.data) {
        const base64ImageString = btoa(
          String.fromCharCode.apply(null, new Uint8Array(zplLabelary.data))
        )
        setZplLab('data:image/png;base64,' + base64ImageString)
      } else {
        setZplLab('')
      }
    } catch (error) {
      console.error(error)
      setZplLab('')
    }
  }

  useEffect(() => {
    if (zpl) {
      loadPreviewImage()
    }
  }, [zpl])

  return (
    <>
      <Modal
        open={previewOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          {zplLab ? <img src={zplLab} alt="Preview" height="500px" /> : 'Carregando...'}
        </Box>
      </Modal>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao criar!
          </Alert>
        </Snackbar>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                sx={{ width: '100%' }}
                onChange={values => {
                  setSubCompanyId(values.target.value)
                }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-input-label">Nome</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                variant="outlined"
                type="text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-textarea-label">ZPL</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={zpl}
                onChange={handleZplChange}
                id="outlined-basic"
                variant="outlined"
                type="text"
                multiline
                rows={20}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%" height={500} overflow="hidden">
              <InputLabel id="demo-simple-image-label">Pré-Visualizar</InputLabel>
              <Box
                width="100%"
                height={500}
                overflow="hidden"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {zplLab ? (
                  <img
                    src={zplLab}
                    alt="Preview"
                    height="100%"
                    style={{ cursor: 'pointer' }}
                    onClick={() => visualizarZPL()}
                  />
                ) : (
                  'Carregando...'
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createPrinterZpl}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" type="submit">
              Salvar
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => visualizarZPL()}
              sx={{ ml: 2 }}
            >
              Pré-visualizar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => setExpanded(false)}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PrinterZplCreate
