import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Select, InputLabel, MenuItem, Grid } from '@mui/material'

const GraficNumberStoreOpen = ({ numberStoreOpen }) => {
  const [selectValue, setSelectValue] = useState('Todos')
  const [selectValueRouter, setSelectValueRouter] = useState('Todos')
  const [data, setData] = useState([])
  const [isShowTop10, setIsShowTop10] = useState(false)

  useEffect(() => {
    if (!numberStoreOpen || numberStoreOpen.length === 0) {
      return
    }
    if (numberStoreOpen.length > 0 && selectValue === '') {
      setSelectValue('Todos')
    }
    if (numberStoreOpen.length > 0 && selectValueRouter === '') {
      setSelectValueRouter('Todos')
    }
  }, [numberStoreOpen, selectValue, selectValueRouter])

  useEffect(() => {
    handleFilter()
  }, [selectValue, selectValueRouter])

  const handleFilter = () => {
    let filter = numberStoreOpen.slice(0, 10)
    let isTop10 = true

    if (selectValue !== 'Todos') {
      filter = numberStoreOpen.filter(item => item.name === selectValue)
      isTop10 = false
      setSelectValueRouter('Todos')
    }

    if (selectValueRouter !== 'Todos') {
      filter = numberStoreOpen.filter(item => item.route === selectValueRouter)
      isTop10 = false
      if (filter.length > 10) {
        filter = filter.slice(0, 10)
      }
      setSelectValue('Todos')
    }

    setData(filter)
    setIsShowTop10(isTop10)
  }

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: isShowTop10 ? '80%' : '50%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        const value = val
        return value
      },
      offsetX: 0,
      offsetY: 0,

      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: isShowTop10
        ? numberStoreOpen.slice(0, 10).map(item => item.name)
        : data.map(item => item.name),
    },
    yaxis: {},
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,

      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  const series = [
    {
      name: 'Lojas ',
      data: isShowTop10
        ? numberStoreOpen.slice(0, 10).map(item => item.countall)
        : data.map(item => item.countall),
    },
  ]

  const uniqueRoutes = [...new Set(numberStoreOpen.map(item => item.route))]

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel id="demo-simple-select-label">Selecione a loja</InputLabel>
          <Select
            size="small"
            sx={{ width: '100%' }}
            label="Escolher a loja"
            value={selectValue}
            onChange={e => setSelectValue(e.target.value)}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            {numberStoreOpen.map((item, index) => {
              return (
                <MenuItem value={item.name} key={item.name}>
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="demo-simple-select-label">Selecione o roteiro</InputLabel>
          <Select
            size="small"
            sx={{ width: '100%' }}
            label="Selecione o roteiro"
            value={selectValueRouter}
            onChange={e => setSelectValueRouter(e.target.value)}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            {uniqueRoutes.map((name, index) => (
              <MenuItem value={name} key={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <div id="grafico">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  )
}

export default GraficNumberStoreOpen
