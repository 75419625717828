import HelpIcon from '@mui/icons-material/Help'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { Context } from '../../../context/AuthContext'
import {
  confirmOrderErp,
  getCountStockInventoryPostService,
  getOnlyOrder,
  getQuantityProduct,
} from '../../../services/reportServices'

import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
//icones react-icons
import { ArrowBack, Edit, Save } from '@mui/icons-material'
import { AiFillFileExcel } from 'react-icons/ai'
import '../../../assets/global.css'
import PermissionComponent from '../../../permissions'
import { getDepositService } from '../../../services/depositService'
import { searchProductService } from '../../../services/productService'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'

const StockAudit = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyCnpj, setSubCompanyCnpj] = useState('')
  const [depositId, setDepositId] = useState('')
  const [orders, setOrders] = useState()
  const [orderSelectedDialog, setOrderSelectedDialog] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [isLoadingDialog, setIsLoadingDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [orderSelect, setOrderSelect] = useState([])
  const [deposit, setDeposit] = useState([])
  const token = localStorage.getItem('token')
  const [countStock, setCountStock] = useState([])
  const [pagination, setPagination] = useState({})
  const [product, setProduct] = useState([])
  const [listReportCount, setListReportCount] = useState([])
  const [listCompare, setListCompare] = useState([])
  const [productTotal, setProductTotal] = useState(10)
  const [countStockOrder, setCountStockOrder] = useState(false)
  const [openDialogCompar, setOpenDialogCompar] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [errorMessage, setErrorMessage] = useState('')
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [openDialogErp, setOpenDialogErp] = useState(false)
  const [filter, setFilter] = useState('')
  const [filterOrder, setFilterOrder] = useState('')
  const [filterIdProductPartner, setFilterIdProductPartner] = useState('')
  const [editedValues, setEditedValues] = useState({})
  const [editingId, setEditingId] = useState(null)

  const handleInputChange = (id, productid, value) => {
    setEditedValues(prev => ({
      ...prev,
      [id && productid]: value,
    }))
  }

  const handleEditClick = (id, productId) => {
    if (editingId === id && productId) {
      // Se o item já está sendo editado, apenas fecha o input
      setEditingId(null)
    } else {
      // Caso contrário, inicia a edição
      setEditingId(id)
    }
  }

  const handleSave = id => {
    // Salve suas alterações e feche o input
    // Aqui você pode adicionar a lógica de salvar as mudanças no estado ou no backend
    console.log(`Saving ${id} with value ${editedValues[id]}`)
    setEditingId(null) // Fecha o input após salvar
  }

  const handleFilterChange = event => {
    setFilter(event.target.value)
  }

  const handleChange = (event, newValue) => {
    setOrderSelect(newValue)
    setErrorMessage('')
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleGetAllProductsSearch = async () => {
    setPage(0)
    setLoading(true)
    const dataProduct = {
      companyId: subCompany[0].companyId,
      limit: 10,
      offset: 0,
      order: 'desc',
      SearchWord: '',
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProduct(data)
        setProductTotal(response.data.total)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetAllProductsSearch()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  }

  const getCountStock = async () => {
    setLoading(true)

    const quantityProduct = await handleGetQuantityProduct()

    const countStockDTO = {
      subCompanyId: subCompanyId,
      movimentationOrderIds: orderSelect.map(item => item.movimentationOrderId),
    }

    try {
      const response = await getCountStockInventoryPostService(token, nameUrl, countStockDTO)
      setLoading(false)

      if (response.data.length > 0) {
        const uniqueProductsArray = Object.values(response.data)

        const updatedListCompare = uniqueProductsArray.map(mockProduct => {
          const matchingProduct = quantityProduct.find(
            product => product.idProductPartner === mockProduct.idProductPartner
          )
          if (matchingProduct) {
            return {
              ...mockProduct,
              quantity: matchingProduct.quantity,
            }
          } else {
            return {
              ...mockProduct,
              quantity: 0,
            }
          }
        })

        updatedListCompare.sort((a, b) => b.quantity - a.quantity)

        setCountStock(updatedListCompare)
        setListCompare(updatedListCompare)
        setPagination(updatedListCompare)
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const getOrders = async e => {
    await getDepositAll(e.target.value)
    setSubCompanyId(e.target.value)

    const subCompanyCnpj = subCompany.find(sub => sub.subCompanyId === e.target.value)
    setSubCompanyCnpj(subCompanyCnpj.cnpj)
    try {
      const response = await getOnlyOrder(token, nameUrl, e.target.value)
      setOrders(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleMovimentationConfirmOrder = async () => {
    setOpenDialogErp(true)
    setIsLoadingDialog(true)
    const data = {
      movimentationOrderNumberOtherSystem: orderSelectedDialog,
      subCompanyCNPJ: subCompanyCnpj,
    }
    try {
      const response = await confirmOrderErp(
        token,
        nameUrl,
        movimentationOrderNumberOtherSystem,
        data
      )
      //setOrders(response.data)
      setIsLoadingDialog(false)
      setSeverity('success')
      setTitle(response.data)
      setAlerta(true)
      setOpenDialogErp(false)
    } catch (e) {
      setIsLoadingDialog(false)
      console.log(e)
      setSeverity('error')
      setTitle('Falha ao enviar, tente novamente!')
      setAlerta(true)
      setOpenDialogErp(true)
    }
  }

  const getDepositAll = async subCompanyId => {
    const data = {
      subCompanyId: subCompanyId,
      limit: 500,
      offset: 1,
    }
    try {
      const response = await getDepositService(token, nameUrl, data)
      setDeposit(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }

  const handleGetQuantityProduct = async () => {
    try {
      const response = await getQuantityProduct(token, nameUrl, subCompanyId, depositId)
      return response.data
    } catch (error) {}
  }

  useEffect(() => {}, [countStockOrder])

  const handleClose = () => {
    setAlerta(false)
    setOpenDialogErp(false)
  }

  const processStockData = (products, orders) => {
    const productMap = {}

    products.forEach(product => {
      const { idProductPartner, productName, stockCount, quantity, movimentationOrderId } = product

      if (!productMap[idProductPartner]) {
        productMap[idProductPartner] = {
          idProductPartner,
          productName,
          quantity: quantity,
          stockCounts: {},
        }
      }

      productMap[idProductPartner].stockCounts[movimentationOrderId] = stockCount || 0
    })

    Object.values(productMap).forEach(product => {
      orders.forEach(order => {
        if (!(order.movimentationOrderId in product.stockCounts)) {
          product.stockCounts[order.movimentationOrderId] = 0
        }
      })
    })

    return Object.values(productMap)
  }

  const filteredLists = processStockData(listCompare, orderSelect)

  const filteredList = filteredLists.filter(item => {
    const matchesOrder = filterOrder
      ? item.movimentationOrderNumberOtherSystem === filterOrder
      : true
    const matchesIdProductPartner = filterIdProductPartner
      ? item.idProductPartner.includes(filterIdProductPartner)
      : true
    return matchesOrder && matchesIdProductPartner
  })

  const downloadData = async () => {
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)

    const relatorioFinal = filteredList.map(item => {
      const relatorioItem = {
        'Código ERP': item.idProductPartner,
        'Nome do Produto': item.productName,
        'Quantidade no ERP': item.quantity,
      }

      orderSelect
        .filter(order => !filter || order.movimentationOrderNumberOtherSystem === filter)
        .forEach(order => {
          relatorioItem[order.movimentationOrderNumberOtherSystem] =
            item.stockCounts[order.movimentationOrderId] || 0
        })

      return relatorioItem
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contagem de Estoque')
    XLSX.writeFile(workbook, `Contagem de Estoque.xlsx`)
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen
        sx={{ m: 2, position: 'relative' }}
      >
        <DialogTitle id="scroll-dialog-title">Auditoria de estoque</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TextField />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button variant="outlined" startIcon={<ArrowBack />} onClick={() => setOpenDialog(false)}>
            Voltar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogErp} maxWidth="xs">
        <DialogTitle>Enviar contagem para o ERP</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2, my: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Selecione o pedido</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Selecione o pedido"
                value={orderSelectedDialog}
                onChange={e => {
                  setOrderSelectedDialog(e.target.value)
                }}
              >
                {orderSelect?.map(order => (
                  <MenuItem
                    value={order.movimentationOrderNumberOtherSystem}
                    key={order.movimentationOrderNumberOtherSystem}
                  >
                    {order.movimentationOrderNumberOtherSystem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              required
              margin="dense"
              id="number"
              name="number"
              label="Informe o numero da ordem(ERP)"
              type="text"
              fullWidth
              variant="outlined"
              value={movimentationOrderNumberOtherSystem}
              onChange={e => setMovimentationOrderNumberOtherSystem(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={handleMovimentationConfirmOrder} variant="contained" type="submit">
            Enviar
          </Button>
        </DialogActions>
        {isLoadingDialog && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              color: '#fff',
              zIndex: theme => theme.zIndex.drawer + 1,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <CircularProgress color="primary" />
              <Typography color="primary">Enviando...</Typography>
            </Box>
          </Box>
        )}
      </Dialog>

      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportCountStock'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Auditoria de estoque</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            alignItems: 'start',
            justifyContent: 'space-between',
            mb: 2,
            mt: 2,
            gap: 2,
          }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Selecione a filial"
                value={subCompanyId}
                onChange={e => {
                  getOrders(e)
                  setSubCompanyId(e.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">Deposito</InputLabel>
              <Select
                label="Selecione a filial"
                value={depositId}
                onChange={e => setDepositId(e.target.value)}
                sx={{ width: '100%' }}
              >
                {deposit.map((item, index) => {
                  return (
                    <MenuItem value={item.depositId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} md={12}>
              {orders && (
                <>
                  <InputLabel id="demo-simple-select-label">Pedido</InputLabel>
                  <Autocomplete
                    multiple
                    value={orderSelect}
                    onChange={handleChange}
                    options={orders}
                    noOptionsText={'Nenhum resultado encontrado'}
                    getOptionLabel={option => option.movimentationOrderNumberOtherSystem || ''}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={errorMessage !== ''}
                        helperText={errorMessage}
                      />
                    )}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}
        >
          <Button
            sx={{ width: { xs: '100%', md: 'inherit' } }}
            variant="contained"
            onClick={getCountStock}
            disabled={!orderSelect}
          >
            Pesquisar
          </Button>
          {orders && (
            <PermissionComponent role="Button_Confirmar_SendStock">
              <Button
                sx={{ width: { xs: '100%', md: 'inherit' } }}
                variant="contained"
                onClick={() => setOpenDialogErp(true)}
              >
                Enviar contagem para o ERP
              </Button>
            </PermissionComponent>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        ></Box>
        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          {listCompare.length > 0 && (
            <>
              <Grid container spacing={2} my={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6">
                    {filteredList.length > 1
                      ? filteredList.length + ' Resultados'
                      : filteredList.length + ' Resultado'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    label="Filtrar por Código ERP"
                    value={filterIdProductPartner}
                    onChange={e => setFilterIdProductPartner(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 200 }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="filter-select-label">Filtrar por Ordem</InputLabel>
                    <Select
                      labelId="filter-select-label"
                      id="filter-select"
                      value={filter}
                      size="small"
                      label="Filtrar por Ordem"
                      fullWidth
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">
                        <em>Todos</em>
                      </MenuItem>
                      {orderSelect.map((item, idx) => (
                        <MenuItem key={idx} value={item.movimentationOrderNumberOtherSystem}>
                          {item.movimentationOrderNumberOtherSystem}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    endIcon={<AiFillFileExcel />}
                    onClick={downloadData}
                  >
                    Excel
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Código ERP</StyledTableCell>
                <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                <StyledTableCell align="center">Quantidade no ERP</StyledTableCell>
                {orderSelect
                  .filter(
                    item => !filterOrder || item.movimentationOrderNumberOtherSystem === filterOrder
                  )
                  .map((item, index) => (
                    <StyledTableCell key={index} align="center">
                      {item.movimentationOrderNumberOtherSystem}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredList.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                  <StyledTableCell align="left">{row.productName}</StyledTableCell>
                  <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                  {orderSelect
                    .filter(
                      item =>
                        !filterOrder || item.movimentationOrderNumberOtherSystem === filterOrder
                    )
                    .map((item, idx) => (
                      <StyledTableCell key={idx} align="center">
                        {editingId === item.movimentationOrderId ? (
                          <>
                            <TextField
                              type="number"
                              value={
                                editedValues[item.movimentationOrderId] ||
                                row.stockCounts[item.movimentationOrderId] ||
                                0
                              }
                              onChange={e =>
                                handleInputChange(
                                  item.movimentationOrderId,
                                  item.idProductPartner,
                                  e.target.value
                                )
                              }
                            />
                            <IconButton onClick={() => handleSave(item.movimentationOrderId)}>
                              <Save />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            {row.stockCounts[item.movimentationOrderId] || 0}
                            <IconButton
                              onClick={() =>
                                handleEditClick(item.movimentationOrderId, item.idProductPartner)
                              }
                            >
                              <Edit />
                            </IconButton>
                          </>
                        )}
                      </StyledTableCell>
                    ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default StockAudit
