import { useContext, useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ListItemText from '@mui/material/ListItemText'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { formatNumbers } from '../../../../../utils/formatNumbers'
import { getGenerateTagRfid } from '../../../../../services/productService'
import { Context } from '../../../../../context/AuthContext'

export default function ComponentQuantityEdit({
  open,
  onClose,
  product,
  onSaveQuantity,
  subCompanyId,
  isEdit,
}) {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [loading, setLoading] = useState('')
  const [valueText, setValueText] = useState('')
  const [messageError, setMessageError] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus()
      }
    }, 100)

    return () => clearTimeout(timer)
  }, [open])

  useEffect(() => {
    if (isEdit) {
      setValueText(product.serial)
    }
  }, [isEdit])

  const handleSave = async () => {
    if (valueText === '') {
      setMessageError('Digite um codigo Rfid.')
      setLoading(false)
      return
    }
    setLoading(false)

    const data = {
      ...product,
      serial: valueText,
    }
    onSaveQuantity(data)
    onClose()
    setLoading(false)
    setValueText('')
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSave()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setValueText('')
      }}
      sx={{ m: 2 }}
      fullScreen
      onEnter={() => inputRef.current.focus()}
    >
      {loading ? (
        <Box
          width="100%"
          maxHeight="500px"
          height="500px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogTitle>Associar código RFID ao produto</DialogTitle>
          <DialogContent>
            <ListItemText primary="Produto" secondary={product.name} />
            <Divider sx={{ my: 2 }} />
            <DialogContentText> Atualize o código RFID correspondente:</DialogContentText>
            <TextField
              inputRef={inputRef}
              sx={{ my: 2 }}
              autoFocus
              margin="dense"
              id="quantity"
              type="text"
              onKeyDown={handleKeyPress}
              value={valueText}
              error={!!messageError}
              helperText={messageError}
              onChange={e => setValueText(e.target.value)}
              fullWidth
              variant="outlined"
              inputProps={{ min: 1 }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onClose()
                setValueText('')
              }}
              variant="outlined"
            >
              Cancelar [C]
            </Button>
            <Button type="submit" variant="contained" onClick={handleSave}>
              Salvar [ENTER]
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
