import { Alert, Backdrop, Box, Button, CircularProgress, Snackbar, Typography } from '@mui/material'
import React, { Component, useContext, useState } from 'react'
import { useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { deletePrinterZplService } from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'

const PrinterZplDelete = ({ match }) => {
  const { nameUrl } = useContext(Context)

  const token = localStorage.getItem('token')
  const { id } = useParams()
  const printerId = id
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const deletePrinter = useCallback(async () => {
    setLoading(true)
    try {
      const response = await deletePrinterZplService(token, nameUrl, printerId)
      navigate('/PrinterZpl')
    } catch (err) {
      console.log(err)
      setLoading(false)
      setError(true)
    }
  }, [])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Typography variant="h5" sx={{ mt: 3 }}>
          Deseja realmente excluir este ZPL?
        </Typography>
        <Box sx={{ display: 'flex', mt: 3 }}>
          <Button variant="contained" onClick={() => deletePrinter()}>
            Excluir
          </Button>
          <Button variant="contained" onClick={() => navigate('/PrinterZpl')} sx={{ ml: 2 }}>
            Cancelar
          </Button>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao excluir!
          </Alert>
        </Snackbar>
      </Box>
    </>
  )
}

export default PrinterZplDelete
