import React, { useContext } from 'react'
import { useState } from 'react'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { createProcess, createProcessSequence, getProcess } from '../../../services/processServices'
import { Context } from '../../../context/AuthContext'

const ProcessCreate = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
  StyledTableCell,
  StyledTableRow,
}) => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [processoName, setProcessoName] = useState('')
  const [processoAtivo, setProcessAtivo] = useState(true)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [statusApp, setStatusApp] = useState('Pronto')
  const [oldList, setOldList] = useState([])
  const [saving, setSaving] = useState(false)
  const [singleProcessSequence, setSingleProcessSequence] = useState({
    name: '',
    order: 0,
    active: true,
  })

  const [sequenceList, setSequenceList] = useState([])

  const addToList = () => {
    const repetido = sequenceList.find(x => x.order === singleProcessSequence.order)
    if (repetido) {
      setStatusApp('Ordem já preenchida')
      return
    } else {
      const testeNumber = parseInt(singleProcessSequence.order)
      if (isNaN(testeNumber)) {
        setStatusApp(`"Ordem" inválida`)
        return
      }
      const prod = singleProcessSequence
      prod.order = parseInt(singleProcessSequence.order)
      setSequenceList(curr => [...curr, singleProcessSequence])
      setStatusApp('Adicionado')
    }
  }

  const getOldProcess = async subId => {
    try {
      const data = {
        subCompanyId: subId,
        limit: 500,
        offset: 0,
      }
      const response = await getProcess(token, nameUrl, data)

      setOldList(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }

  const saveProcess = async () => {
    getOldProcess(subCompanyId)
    sequenceList.forEach(i => {
      i.subCompanyId = subCompanyId
      i.active = true
    })

    const saveProcesso = {
      name: processoName,
      active: processoAtivo,
      subCompanyId,
    }

    try {
      setSaving(true)
      setStatusApp('Criando Processo...')
      const response = await createProcess(token, nameUrl, saveProcesso)

      const dataProcessList = {
        subCompanyId,
        limit: 500,
        offset: 0,
      }

      const response2 = await getProcess(token, nameUrl, dataProcessList)

      const newList = response2.data.result
      setStatusApp('Comparando listas...')
      const diffProcess = newList.filter(
        ({ processId: id1 }) => !oldList.some(({ processId: id2 }) => id2 === id1)
      )

      setStatusApp('Adicionando campos...')
      await Promise.all(
        sequenceList.map(async item => {
          const finalItem = item
          item.processId = diffProcess[0].processId
          await createProcessSequence(token, nameUrl, finalItem)
        })
      )
      handleFetchData()
      setExpanded(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem', width: 1 }} onSubmit={createProcess}>
          <Box sx={{ display: { md: 'flex' }, gap: 2, alignItems: 'center' }}>
            <Box sx={{ width: { xs: 1, md: 0.3 } }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Filial"
                value={subCompanyId}
                onChange={values => {
                  setSubCompanyId(values.target.value)
                }}
                sx={{ width: 1 }}
              >
                <MenuItem value="" disabled selected>
                  Filial
                </MenuItem>
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box sx={{ width: { xs: 1, md: 0.4 } }}>
              <InputLabel>Nome Processo Produtivo</InputLabel>
              <TextField
                //sx={{ width: '100%' }}
                value={processoName}
                onChange={values => setProcessoName(values.target.value)}
                id="outlined-basic"
                // label="Nome Processo"
                variant="outlined"
                type="text"
                sx={{ width: 1 }}
              />
            </Box>
            <Box sx={{ width: { xs: 1, md: 0.1 } }}>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Ativo" />
              </FormGroup>
            </Box>
          </Box>
          <Box
            sx={{
              width: 1,
              mt: 2,
              display: 'flex',
              gap: 2,
              border: '1px solid black',
              borderRadius: 2,
              p: 2,
              flexDirection: 'column',
            }}
          >
            <Box sx={{ width: 1, display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 2, width: 0.5 }}>
                <FormControl sx={{ width: 1 }}>
                  <TextField
                    sx={{ width: '100%' }}
                    //value={singleProd.name}
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    label="Nome Posto de Trabalho"
                    onChange={e =>
                      setSingleProcessSequence(curr => ({
                        ...curr,
                        name: e.target.value,
                      }))
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ width: 0.5, gap: 2, display: 'flex' }}>
                <FormControl sx={{ width: 0.5 }}>
                  <TextField
                    sx={{ width: '100%' }}
                    //value={singleProd.referencia}
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    label="Ordem"
                    onChange={e =>
                      setSingleProcessSequence(curr => ({
                        ...curr,
                        order: e.target.value,
                      }))
                    }
                  />
                </FormControl>
                <FormControl sx={{ width: 0.5 }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={statusApp}
                    id="outlined-basic"
                    variant="outlined"
                    label="Status"
                    type="text"
                    disabled
                  />
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ width: 1, display: 'flex', gap: 2 }}>
              <Button variant="outlined" onClick={addToList}>
                Adicionar Posto de Trabalho
              </Button>
            </Box>
          </Box>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ width: 0.2 }}></StyledTableCell>
                  <StyledTableCell align="center">Nome Etapa</StyledTableCell>
                  <StyledTableCell align="center">Ordem</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sequenceList.map((row, index) => (
                  <StyledTableRow key={row.index}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setSequenceList(curr => curr.filter((v, i) => i !== index))}
                    >
                      <DeleteForeverIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">{row.order}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="outlined" onClick={saveProcess} disabled={saving} sx={{ mt: 2 }}>
            Salvar
          </Button>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={saving}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ProcessCreate
