import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import HelpIcon from '@mui/icons-material/Help'
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AiFillPlusCircle } from 'react-icons/ai'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../context/AuthContext'
import PermissionComponent from '../../../permissions'
import { getClientId, searchClientService } from '../../../services/clientService'
import { createOrderProcessFullService } from '../../../services/movBreakServices'
import { getProcess } from '../../../services/processServices'
import { getSingleProductService, searchProductService } from '../../../services/productService'
import { colorTableDefault } from '../../TemplateDefault'

const MovimentationBreakCreate = () => {
  const [permissionProducao, setPermissionProducao] = useState(false)

  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [processId, setProcessId] = useState('')
  const [processList, setProcessList] = useState([])
  const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [observation, setObservation] = useState('')
  const [produtos, setProdutos] = useState([])
  const [clients, setClients] = useState([])
  const [searchProd, setSearchProd] = useState('')
  const [searchClient, setSearchClient] = useState('')
  const [quantityTotal, setQuantityTotal] = useState()
  const [searchingProd, setSearchingProd] = useState(false)
  const [searchingClient, setSearchingClient] = useState(false)
  const [subCompanyId, setSubCompanyId] = useState(subCompany[0].subCompanyId)
  const [selectedClient, setSelectedClient] = useState({
    codClient: '',
    clientId: '',
    name: '',
  })
  const [singleProd, setSingleProd] = useState({
    name: '',
    referencia: '',
    sku: '',
    quantity: '',
    finishGood: false,
  })
  const [itensOrder, setItensOrder] = useState([])
  const [permissionClientView, setPermissionClientView] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (searchProd === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearchingProd(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: searchProd,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearchingProd(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [searchProd])

  useEffect(() => {
    if (searchClient === '') {
      const checagem = setTimeout(() => {
        setClients([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearchingClient(true)
      const checagem = setTimeout(() => {
        const dados = {
          subCompanyId: subCompanyId,
          search: searchClient,
        }
        searchClientService(token, nameUrl, dados).then(response => {
          const clientsFinal = []
          response.data.map(client => {
            const obj = client
            obj.value = client.clientId
            obj.label = `${client.codClient} - ${client.name}`
            clientsFinal.push(obj)
          })
          setClients(clientsFinal)
          searchingClient(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [searchClient, singleProd.finishGood])

  useEffect(() => {}, [singleProd.finishGood])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const changeProd = async id => {
    console.log(id, 'id')
    //const prod = produtos.find(x => x.productId === id)
    const prod = await getSingleProductService(token, nameUrl, { productId: id })
    const finalProd = { ...prod.data }
    finalProd.finishGood = false
    finalProd.quantityTotal = ''
    setSingleProd(finalProd)
  }

  const changeClient = async id => {
    const Client = await getClientId(token, nameUrl, id)
    setSelectedClient({
      name: Client.data.name,
      codClient: Client.data.codClient,
      clientId: Client.data.clientId,
    })
  }

  const addToList = () => {
    const testeNumber = parseInt(singleProd.quantity)
    if (isNaN(testeNumber)) {
      setSingleProd(curr => ({
        ...curr,
        quantity: 'Quantidade inválida',
      }))
      return
    }
    const repetido = itensOrder.find(x => x.productId === singleProd.productId)
    if (repetido) {
      return
    } else {
      const prod = {
        ...singleProd,
        quantity: parseInt(singleProd.quantity),
        clientName: selectedClient.name,
      }
      setItensOrder(curr => [...curr, prod])
      if (prod.finishGood) {
        setQuantityTotal(prod.quantity)
      }
    }
  }
  useEffect(() => {
    getProcessList()
  }, [subCompanyId])

  const getProcessList = async () => {
    setLoading(true)
    try {
      const data = {
        subCompanyId,
        limit: 1000,
        offset: 0,
        order: true,
      }
      const response = await getProcess(token, nameUrl, data)
      setProcessList(response.data.result)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {}, [singleProd.finishGood])

  const createMovBreak = async () => {
    const keysToKeep = ['idProductPartner', 'quantity', 'finishGood']
    const redux = array =>
      array.map(o =>
        keysToKeep.reduce((acc, curr) => {
          acc[curr] = o[curr]
          return acc
        }, {})
      )
    const finalList = redux(itensOrder)

    finalList.forEach(i => {
      i.subCompanyCNPJ = subCompanyCNPJ
      i.movimentationOrderNumberOtherSystem = movimentationOrderNumberOtherSystem
    })
    let data
    if (selectedClient.clientId === '') {
      data = {
        movimentationOrderNumberOtherSystem,
        typeOrder: 17,
        observation,
        subCompanyCNPJ,
        processId,
        itensOrder: finalList,
      }
    } else {
      data = {
        movimentationOrderNumberOtherSystem,
        typeOrder: 17,
        observation,
        clientId: selectedClient.clientId,
        subCompanyCNPJ,
        processId,
        itensOrder: finalList,
      }
    }

    try {
      const request = await createOrderProcessFullService(token, nameUrl, data)
      navigate('/MovBreak')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Criar Ordem de Produção</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2 }}>
          <FormControl sx={{ width: 0.5 }}>
            <InputLabel id="processo-select">Processo</InputLabel>
            <Select
              label="Processos"
              onChange={e => setProcessId(e.target.value)}
              value={processId}
            >
              {processList.map(item => {
                return (
                  <MenuItem value={item.processId} key={item.processId}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 0.5 }}>
            <InputLabel id="cnpj-filial">CNPJ Filial</InputLabel>
            <Select
              label="CNPJ Filial"
              onChange={e => {
                const selectedSubCompany = subCompany.find(item => item.cnpj === e.target.value)
                if (selectedSubCompany) {
                  setSubCompanyCNPJ(selectedSubCompany.cnpj)
                  setSubCompanyId(selectedSubCompany.subCompanyId)
                } else {
                  setSubCompanyCNPJ('')
                  setSubCompanyId('')
                }
              }}
              value={subCompanyCNPJ}
              renderValue={value => `${value}`}
            >
              {subCompany.map((item, index) => {
                return (
                  <MenuItem value={item.cnpj} key={index}>
                    {item.name} - {item.cnpj}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, width: 0.5 }}>
            <FormControl sx={{ width: 0.5 }}>
              <TextField
                sx={{ width: '100%' }}
                value={movimentationOrderNumberOtherSystem}
                onChange={values => setMovimentationOrderNumberOtherSystem(values.target.value)}
                id="outlined-basic"
                label="Número Order (outro sistema)"
                variant="outlined"
                type="text"
              />
            </FormControl>
            <FormControl sx={{ width: 0.5 }}>
              <TextField
                sx={{ width: '100%' }}
                value={observation}
                onChange={values => setObservation(values.target.value)}
                id="outlined-basic"
                label="Observação"
                variant="outlined"
                type="text"
              />
            </FormControl>
          </Box>
          <FormControl sx={{ width: 0.5 }}>
            <Autocomplete
              disablePortal
              id="lista-produtos"
              options={produtos}
              //sx={{ width: 300 }}
              loading={searchingProd}
              loadingText="Procurando..."
              noOptionsText="Nenhum produto"
              renderOption={(props, option) => {
                return (
                  <li {...props} key={`product-${option.productId}`}>
                    {option.label}
                  </li>
                )
              }}
              renderInput={params => <TextField {...params} label="Procurar produto" />}
              onInputChange={(e, v) => {
                console.log(v, 'asdsa')
                setSearchProd(v)
              }}
              //onChange={(e, v) => setProductId(v)}
              onChange={(e, v) => {
                if (v && v.value) {
                  console.log(v, 'value')
                  changeProd(v.value)
                }
              }}
            />
          </FormControl>
          <PermissionComponent role="PRODUCAO">
            <FormControl sx={{ width: 0.5 }}>
              <Autocomplete
                disabled={!subCompanyCNPJ}
                disablePortal
                id="lista-produtos"
                options={clients}
                loading={searchingClient}
                loadingText="Procurando..."
                noOptionsText="Nenhum cliente"
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.clientId}>
                      {option.codClient} - {option.name}
                    </li>
                  )
                }}
                renderInput={params => <TextField {...params} label="Procurar cliente" />}
                onInputChange={(e, v) => {
                  setSearchClient(v)
                }}
                onChange={(e, v) => {
                  if (v && v.value) {
                    changeClient(v.value)
                  }
                }}
              />
            </FormControl>
          </PermissionComponent>
        </Box>
        {/* <Divider sx={{ width: 1, mt: 2 }} /> */}
        <Box
          sx={{
            width: 1,
            mt: 2,
            display: 'flex',
            gap: 2,
            border: '1px solid black',
            borderRadius: 2,
            p: 2,
            flexDirection: 'column',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: 1 }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={singleProd.name}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Nome do produto"
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl sx={{ width: 1 }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={singleProd.referencia}
                  id="outlined-basic"
                  disabled
                  variant="outlined"
                  type="text"
                  label="Referência"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl sx={{ width: 1 }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={singleProd.sku}
                  id="outlined-basic"
                  variant="outlined"
                  label="SKU"
                  type="text"
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl sx={{ width: 1 }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={singleProd.quantity}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Quantidade"
                  onChange={e =>
                    setSingleProd(curr => ({
                      ...curr,
                      quantity: e.target.value,
                    }))
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl sx={{ width: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={singleProd.finishGood}
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          finishGood: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Produto Final"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ width: 0.25 }}>
                <Button variant="outlined" onClick={addToList}>
                  Adicionar à lista
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">Nome do Produto</StyledTableCell>
                <StyledTableCell align="center">Referência</StyledTableCell>
                <StyledTableCell align="center">SKU</StyledTableCell>
                <StyledTableCell align="center">ERP</StyledTableCell>
                <StyledTableCell align="center">Quantidade</StyledTableCell>
                <StyledTableCell align="center">Produto Final</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itensOrder.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setItensOrder(curr => curr.filter((v, i) => i !== index))}
                  >
                    <DeleteForeverIcon />
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">{row.referencia}</StyledTableCell>
                  <StyledTableCell align="center">{row.sku}</StyledTableCell>
                  <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                  <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                  <StyledTableCell align="center">{row.finishGood ? 'Sim' : 'Não'}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ gap: 2, display: 'flex', mt: 2 }}>
          <Button
            variant="contained"
            color="success"
            startIcon={<AiFillPlusCircle />}
            onClick={createMovBreak}
          >
            Salvar
          </Button>
          <Button
            color="error"
            variant="contained"
            startIcon={<BiArrowBack />}
            onClick={() => navigate('/MovBreak')}
          >
            Voltar
          </Button>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default MovimentationBreakCreate
