import { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const CardDashboard = ({ handleGetDashDash }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    console.log(handleGetDashDash, 'sadssdd')
  }, [handleGetDashDash])

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {loading ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '30%',
            left: '48%',
          }}
          color="primary"
        />
      ) : error ? (
        'Deu erro'
      ) : (
        <>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={800}
              data={handleGetDashDash}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={20}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="client"
                tickFormatter={name => name.split(' ')[0]}
                scale="point"
                padding={{ left: 10, right: 10 }}
              />
              <YAxis tickFormatter={value => `R$ ${value.toLocaleString('pt-BR')}`} />
              <Tooltip
                labelFormatter={value => `Cliente: ${value}`}
                formatter={(value, name, props) => [`R$ ${value.toLocaleString('pt-BR')}`, 'Total']}
              />
              <Legend />
              <Bar
                dataKey="original_value"
                name="Contas a pagar"
                fill="#1EA47E"
                activeBar={<Rectangle fill="#1EA47E" stroke="#1EA47E" />}
              />
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </Box>
  )
}

export default CardDashboard
