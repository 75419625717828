import { Box, Container, Divider } from '@mui/material'
import Header from '../Header'
import Footer from '../Footer'
import { Context } from '../../context/AuthContext'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export let colorTableDefault = '#004d40'
export let coloButton = '#004d40'
export let colorChip = '#004d40'

const TemplateDefault = ({ children }) => {
  const {
    colorPrimary,
    colorSecondary,
    colorMenu,
    letterPrimary,
    letterSecondary,
    fontColorMenuSecondary,
    nameUrl,
  } = useContext(Context)
  colorTableDefault = colorSecondary
  colorChip = colorSecondary
  coloButton = colorPrimary

  const location = useLocation()
  const isDashboardRoute = location.pathname === '/dashboard'

  const theme = createTheme({
    palette: {
      primary: {
        main: colorPrimary,
        contrastText: letterPrimary,
      },
      secondary: {
        main: colorSecondary,
        contrastText: letterSecondary,
      },
      menu: {
        main: colorMenu,
        contrastText: letterSecondary,
      },
      text: {
        primary: letterPrimary,
        secondary: letterSecondary,
      },
      buttons: {
        main: colorSecondary,
        contrastText: fontColorMenuSecondary,
      },
      chip: {
        main: colorSecondary,
        contrastText: letterSecondary,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          color: 'buttons',
        },
      },
    },
    overrides: {
      MuiFormLabel: {
        color: letterPrimary,
      },
    },
  })
  return (
    <ThemeProvider theme={isDashboardRoute ? theme : theme}>
      <Box sx={{ width: '100%', backgroundColor: isDashboardRoute ? colorPrimary : 'inherit' }}>
        <Header />
        <Container
          sx={{
            width: '97%',
            minHeight: '94vh',
            p: { xs: 0, md: 4 },
            pt: 2,
          }}
          maxWidth={false}
        >
          {children}
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  )
}

export default TemplateDefault
