import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as XLSX from 'xlsx'
import PropTypes from 'prop-types'
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  deleteProductMovimentationOrderByIdPartner,
  editTransferOrderService,
  getUniqueTransferOrderService,
} from '../../../../services/requestServices'
import {
  confirmOrder,
  getProductAssociationService,
  returnOrderStatus,
} from '../../../../services/productService'
import { Context } from '../../../../context/AuthContext'
import {
  getEquipmentLogs,
  getEquipmentSerial,
  getUniqueEquipmentService,
} from '../../../../services/configServices'
import {
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  format,
  formatDistance,
  isFuture,
} from 'date-fns'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { FormControl } from '@mui/base'

const EquipmentDetailsLog = ({ handleFetchData }) => {
  const { subCompany, nameUrl } = useContext(Context)
  const params = useParams()
  const [subCompanyId, setSubCompanyId] = useState('')
  const token = localStorage.getItem('token')
  const [equipmentLogs, setEquipmentLogs] = useState([])
  const [equipmentSerialLogs, setEquipmentSerialLogs] = useState([])
  const navigate = useNavigate()
  const [ip, setIp] = useState('')
  const [name, setName] = useState('')
  const [port, setPort] = useState('')
  const [typeEquipmentHardware, setTypeEquipmentHardware] = useState(0)
  const [mac, setMac] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPagesSerial, setTotalPagesSerial] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [equipmentLimit, setEquipmentLimit] = useState(500)
  const [equipmentOrder, setEquipmentOrder] = useState(false)
  const [progress, setProgress] = useState(0)
  const [alertSuccess, setAlertSuccess] = useState(false)
  const [alertFailed, setAlertFailed] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageSerial, setPageSerial] = useState(0)
  const [rowsPerPageSerial, setRowsPerPageSerial] = useState(10)
  const [newItem, setNewItem] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [loading, setLoading] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangePageSerial = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleChangeRowsPerPageSerial = event => {
    setRowsPerPageSerial(+event.target.value)
    setPageSerial(0)
  }

  const formatDistanceToNowString = dateString => {
    const date = new Date(dateString)
    const now = new Date()

    const millisecondsDiff = differenceInMilliseconds(now, date)
    const minutesDiff = differenceInMinutes(now, date)
    const hoursDiff = differenceInHours(now, date)
    const daysDiff = differenceInDays(now, date)

    if (millisecondsDiff < 5000) {
      return 'há menos de 5 segundos'
    } else if (minutesDiff === 1) {
      return 'há 1 minuto'
    } else if (minutesDiff < 60) {
      return `há ${minutesDiff} minutos`
    } else if (hoursDiff === 1) {
      return 'há 1 hora'
    } else if (hoursDiff < 24) {
      return `há ${hoursDiff} horas`
    } else if (daysDiff === 1) {
      return 'há 1 dia atrás'
    } else if (daysDiff < 7) {
      return `há ${daysDiff} dias atrás`
    } else {
      return 'há mais de uma semana'
    }
  }

  const handleClose = () => {
    setAlertSuccess(false)
    setAlertFailed(false)
  }

  const getEquipmentLog = async () => {
    setLoading(true)
    try {
      const response = await getEquipmentLogs(
        token,
        params.id,
        currentPage,
        equipmentLimit,
        equipmentOrder
      )
      setEquipmentLogs(response.data.result)
      setTotalPages(response.data.total)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
    try {
      const response = await getUniqueEquipmentService(token, nameUrl, params.id)
      setIp(response.data.ip)
      setName(response.data.name)
      setPort(response.data.port)
      setTypeEquipmentHardware(response.data.typeEquipmentHardware)
      setSubCompanyId(response.data.subCompanyId)
      setMac(response.data.mac)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const handleGetEquipmentSerial = async () => {
    setLoading(true)
    try {
      const response = await getEquipmentSerial(
        token,
        params.id,
        currentPage,
        equipmentLimit,
        equipmentOrder
      )
      setEquipmentSerialLogs(response.data.result)
      console.log(response.data.result, `response`)
      setTotalPagesSerial(response.data.total)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    getEquipmentLog()
  }, [params.id])
  useEffect(() => {
    handleGetEquipmentSerial()
  }, [params.id])

  useEffect(() => {
    setTimeout(() => {
      setTypeEquipmentHardware(1)
      setLoading(false)
    }, 2000)
  }, [])

  return (
    <>
      <Box>
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={alertSuccess} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Itens Salvos com Sucesso!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alertFailed}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Algo deu Errado!
          </Alert>
        </Snackbar>
        <Box sx={{ mt: '4rem' }}>
          <Typography variant="body1" fontWeight={500}>
            Logs de equipamentos
          </Typography>
        </Box>
        <Box sx={{ mt: '1rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {subCompany.map((item, index) => {
                return (
                  <TextField
                    key={index}
                    sx={{ width: '100%', mt: '1rem' }}
                    value={
                      loading
                        ? 'Carregando...'
                        : item.subCompanyId === subCompanyId
                        ? item.name
                        : ''
                    }
                    name="filial"
                    id="standard-basic"
                    label="Filial"
                    variant="outlined"
                    disabled
                    type="text"
                    InputProps={{
                      startAdornment: loading ? (
                        <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                      ) : null,
                    }}
                  />
                )
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                name="name"
                value={loading ? 'Carregando...' : name}
                disabled
                type="text"
                InputProps={{
                  startAdornment: loading ? (
                    <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                  ) : null,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="ip"
                variant="outlined"
                name="ip"
                value={loading ? 'Carregando...' : ip}
                disabled
                type="text"
                InputProps={{
                  startAdornment: loading ? (
                    <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                  ) : null,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="porta"
                value={loading ? 'Carregando...' : port}
                id="outlined-basic"
                label="Porta"
                variant="outlined"
                disabled
                type="text"
                InputProps={{
                  startAdornment: loading ? (
                    <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                  ) : null,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <TextField
                  sx={{ width: '100%', mt: '1rem' }}
                  id="outlined-basic"
                  label="Tipo de Equipamento"
                  variant="outlined"
                  value={
                    loading
                      ? 'Carregando...'
                      : typeEquipmentHardware === 0
                      ? 'Nenhum'
                      : typeEquipmentHardware === 1
                      ? 'Impressora'
                      : typeEquipmentHardware === 2
                      ? 'Reader RFID'
                      : 'Máquina'
                  }
                  InputProps={{
                    startAdornment: loading ? (
                      <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                    ) : null,
                  }}
                  disabled
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={loading ? 'Carregando...' : mac}
                id="outlined-basic"
                label="MAC"
                variant="outlined"
                disabled
                type="text"
                InputProps={{
                  startAdornment: loading ? (
                    <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                  ) : null,
                }}
              />
            </Grid>
          </Grid>

          <Box width="100%" sx={{ mt: '1rem', display: 'flex', justifyContent: 'end' }}>
            <Button
              variant="contained"
              sx={{ maxWidth: '150px', width: '100%' }}
              type="button"
              onClick={() => navigate('/Equipment')}
            >
              Voltar
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={500}>
                Logs de equipamentos
              </Typography>
              <TableContainer component={Paper} sx={{ mt: '1rem' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left" width="50%">
                        Informação
                      </StyledTableCell>
                      <StyledTableCell align="left" width="35%%">
                        Descrição
                      </StyledTableCell>
                      <StyledTableCell align="left" width="15%">
                        Data de Criação
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {equipmentLogs
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="left">{row.information}</StyledTableCell>
                          <StyledTableCell align="left">{row.description}</StyledTableCell>
                          <StyledTableCell align="left">
                            <Tooltip
                              title={format(new Date(row.create), 'dd/MM/yyyy HH:mm:ss')}
                              arrow
                            >
                              {formatDistanceToNowString(row.create)}
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={totalPages}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={500} mt={2}>
                Serial de equipamentos
              </Typography>
              <TableContainer component={Paper} sx={{ mt: '1rem' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left" width="50%">
                        Serial
                      </StyledTableCell>
                      <StyledTableCell align="left" width="15%">
                        Data de Criação
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {equipmentSerialLogs
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="left">{row.serial}</StyledTableCell>
                          <StyledTableCell align="left">
                            <Tooltip
                              title={format(new Date(row.create), 'dd/MM/yyyy HH:mm:ss')}
                              arrow
                            >
                              {formatDistanceToNowString(row.create)}
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={totalPagesSerial}
                  rowsPerPage={rowsPerPageSerial}
                  page={pageSerial}
                  onPageChange={handleChangePageSerial}
                  onRowsPerPageChange={handleChangeRowsPerPageSerial}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default EquipmentDetailsLog
