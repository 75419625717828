import React, { useContext } from 'react'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useEffect, useState } from 'react'

import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

import {
  createAddUSer,
  createEmailGroup,
  createEmailGroupSequence,
  deleteEmailGroupSequence,
  deleteUserGroupEmail,
  editEmailGroup,
  getEmailGroupId,
  getEmailGroupSequenceAllList,
  getEmailGroupService,
  getUserGroupEmail,
} from '../../../services/groupEmailService'
import { styled } from '@mui/material'

const EmailGroupEdit = ({
  emailGroupId,
  setAlerta,
  setExpanded,
  setSeverity,
  setTitle,
  StyledTableRow,
  StyledTableCell,
  setLoading,
  handleFetchData,
}) => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [nameGroupEmail, setNameGroupEmail] = useState('')
  const [emailGroup, setEmailGroup] = useState([])
  const [error, setError] = useState(false)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [userListGroup, setUserListGroup] = useState([])
  const [active, setActive] = useState(false)
  const [isDelete, setIsDelete] = useState(false)

  const [userGroupEmail, setUserGroupEmail] = useState('')
  const [userGetGroupEmail, setUserGetGroupEmail] = useState([])
  const [statusApp, setStatusApp] = useState('Pronto')
  const [newUserIds, setNewUserIds] = useState([])

  const [saving, setSaving] = useState(false)

  const [deleteOpen, setDeleteOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')

  const [singleEmailGroupSequence, setSingleEmailGroupSequence] = useState({
    userId: '',
    nameEmployee: '',
  })
  const [sequenceList, setSequenceList] = useState([])

  const combinedList = [
    ...userListGroup.map(user => ({ ...user, type: 'user' })),
    ...sequenceList.map(employee => ({ ...employee, type: 'employee' })),
  ]
  const closeDelete = () => {
    setAlerta(false)
    setDeleteOpen(false)
  }

  const addToList = listUser => {
    const userId = listUser.userId

    if (!listUser.userId) {
      setStatusApp('Escolha um usuário válido para adicionar ao grupo de email')
      return
    }

    const userExists = userListGroup.some(user => user.userId === listUser.userId)
    if (userExists) {
      setStatusApp('Usuário já existe no grupo de e-mails')
      return
    }

    const repetido = sequenceList.find(x => x.userId === listUser.userId)
    if (repetido) {
      setStatusApp('Ordem já preenchida')
      return
    } else {
      const emptyid = listUser.userId
      if (emptyid === '') {
        setStatusApp('"Ordem" inválida')
        return
      }
      const newUser = {
        ...listUser,
      }
      setSequenceList(curr => [...curr, newUser])
      setNewUserIds(prevIds => [...prevIds, userId])

      setStatusApp('Adicionado')
    }
  }

  const handleGetEmailGroup = async () => {
    try {
      const response = await getEmailGroupId(token, nameUrl, emailGroupId)
      setEmailGroup(response.data.emailGroupUserCollection)
      setNameGroupEmail(response.data.name)
      setActive(response.data.active)
      setSubCompanyId(response.data.subCompanyId)
      getUserEmailList(response.data.subCompanyId)
      setUserListGroup(response.data.emailGroupUserCollection)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handleGetEmailGroup()
  }, [emailGroupId])

  const deleteAlert = data => {
    const userGroup = {
      emailGroupId: data.emailGroupId,
      emailGroupUserId: data.userId,
    }
    setDeleteOpen(true)
    setIdDelete(userGroup)
  }

  const getUserEmailList = async subCompanyId => {
    try {
      setLoading(true)
      const data = {
        subCompanyId: subCompanyId,
      }
      const response = await getUserGroupEmail(token, nameUrl, data)
      const users = response
      setUserGetGroupEmail(users)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const saveEmailGroup = async () => {
    const userId = combinedList.map(userId => ({
      emailGroupUserId: userId.userId,
      emailGroupId: emailGroupId,
    }))

    const emailGroupEdit = {
      emailGroupId: emailGroupId,
      name: nameGroupEmail,
      active: active,
      subCompanyId,
    }

    try {
      const response = await createAddUSer(token, nameUrl, userId)
      const responseEditEmailGroup = await editEmailGroup(token, nameUrl, emailGroupEdit)
      setLoading(true)
      handleGetEmailGroup()
      setSeverity('success')
      setTitle('Grupo salvo com sucesso!.')
      setAlerta(true)
      setExpanded(false)
      setSequenceList([])
      handleFetchData()
    } catch (err) {
      console.log(err)
      setSeverity('error')
      setTitle(
        'Desculpe-nos, o serviço está temporariamente indisponível. Por favor, tente novamente mais tarde..'
      )
      setAlerta(true)
      setExpanded(false)
    }
    setLoading(false)
  }

  const handleDeleteUser = async () => {
    try {
      const userToDelete = sequenceList.find(user => user.userId === idDelete.emailGroupUserId)

      if (userToDelete) {
        const updatedSequenceList = sequenceList.filter(
          user => user.userId !== idDelete.emailGroupUserId
        )
        setSequenceList(updatedSequenceList)
      } else {
        const response = await deleteUserGroupEmail(token, nameUrl, idDelete)
      }
      setDeleteOpen(false)
      setSeverity('success')
      setTitle('Usuario excluido com sucesso!.')
      setAlerta(true)
      setLoading(false)
      handleGetEmailGroup()
    } catch (error) {
      console.log(error)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Box>
        <Box
          component="form"
          sx={{ mt: '2rem', width: 1 }}
          //onSubmit={createProcess}
        >
          <Dialog
            open={deleteOpen}
            onClose={closeDelete}
            aria-describedby="alert-dialog-slide-description"
            sx={{ textAlign: 'center' }}
          >
            <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
            <DialogActions>
              <Button onClick={closeDelete}>Cancelar</Button>
              <Button onClick={() => handleDeleteUser()}>Sim</Button>
            </DialogActions>
          </Dialog>
          <Box sx={{ display: { md: 'flex' }, gap: 2, alignItems: 'center' }}>
            <Box sx={{ width: { xs: 1, md: 0.3 } }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Filial"
                value={subCompanyId}
                onChange={values => {
                  setSubCompanyId(values.target.value)
                  getUserEmailList(values.target.value)
                }}
                sx={{ width: 1 }}
              >
                <MenuItem value="" disabled selected>
                  Filial
                </MenuItem>
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box sx={{ width: { xs: 1, md: 0.4 } }}>
              <InputLabel>Nome do Grupo de Email</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={nameGroupEmail}
                onChange={values => setNameGroupEmail(values.target.value)}
                id="outlined-basic"
                // label="Nome Processo"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box sx={{ width: { xs: 1, md: 0.1 } }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={active}
                      onChange={event => setActive(event.target.checked)}
                    />
                  }
                  label="Ativo"
                />
              </FormGroup>
            </Box>
          </Box>
          <Box
            sx={{
              width: 1,
              mt: 2,
              display: 'flex',
              gap: 2,
              border: '1px solid black',
              borderRadius: 2,
              p: 2,
              flexDirection: 'column',
            }}
          >
            <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Escolher Usuário</InputLabel>
                    <Select
                      value={userGroupEmail}
                      label="Escolher o Usuário"
                      onChange={event => setUserGroupEmail(event.target.value)}
                    >
                      {userGetGroupEmail.map((user, index) => (
                        <MenuItem key={index} value={user}>
                          {user.nameEmployee}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={statusApp}
                    id="outlined-basic"
                    variant="outlined"
                    label="Status"
                    type="text"
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: 1, display: 'flex', gap: 2 }}>
              <Button variant="outlined" onClick={() => addToList(userGroupEmail)}>
                Adicionar usuario ao grupo de email
              </Button>
            </Box>
          </Box>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ width: 0.2 }}></StyledTableCell>
                  <StyledTableCell align="left">Nome do Grupo de Email</StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {combinedList.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        deleteAlert(row)
                      }}
                    >
                      <DeleteForeverIcon sx={{ cursor: 'pointer' }} />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.type === 'user' ? row.userName : row.nameEmployee}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.type === 'user' ? row.email : row.nameEmployee}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="outlined" onClick={saveEmailGroup} disabled={saving} sx={{ mt: 2 }}>
            Salvar Grupo
          </Button>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={saving}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>{' '}
    </>
  )
}

export default EmailGroupEdit
