import { yupResolver } from '@hookform/resolvers/yup'
import { Clear, Delete, Edit, Help, Save, Search } from '@mui/icons-material'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  tableCellClasses,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Context } from '../../../context/AuthContext'
import { postProductCodService, uplaodQuantity } from '../../../services/productService'
import { colorTableDefault } from '../../TemplateDefault'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const schema = yup.object().shape({
  subCompanyId: yup.string().required('Campo obrigatório'),
  equipmentId: yup.string().required('Campo obrigatório'),
  serial: yup.string().required('Campo obrigatório'),
  quantity: yup.string().required('Campo obrigatório'),
})

function AssociationSerials() {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      equipmentId: '',
      quantity: '',
      serial: '',
      subCompanyId: '',
    },
  })
  const serialRef = useRef(null)

  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [rowsProducts, setRowsProducts] = useState([])
  const [searchFilter, setSearchFilter] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [alert, setAlerta] = useState(false)
  const [itensData, setItensData] = useState([])
  const [editingItem, setEditingItem] = useState(null)
  const [showEditingItem, setShowEditingItem] = useState(false)
  const [fieldError, setFieldError] = useState(null)
  const serialInputRef = useRef(null)
  const [isLoadingEdit, setIsLoadingEdit] = useState('')

  useEffect(() => {
    if (showEditingItem && serialInputRef.current) {
      serialInputRef.current.focus()
      serialInputRef.current.select()
    }
  }, [showEditingItem])

  const handleEditClick = item => {
    setEditingItem({ ...item, id: item.serial })
    setShowEditingItem(true)
    setFieldError(null)
  }

  const handleSaveEdit = async editedItem => {
    setIsLoadingEdit(true)

    const dataItem = {
      rfidCode: editedItem.serial,
      subCompanyId: editedItem.subCompanyId,
    }

    try {
      const response = await postProductCodService(token, nameUrl, dataItem)

      if (response.data.length === 0) {
        setFieldError('Código não existe')
        setIsLoadingEdit(false)
        return
      } else {
        const updatedItem = {
          ...editedItem,
          quantitys: response.data[0].quantitys,
          productName: response.data[0].productName,
        }

        const updatedItems = itensData.map(item =>
          item.serial === updatedItem.serial ? updatedItem : item
        )
        setItensData(updatedItems)
        setEditingItem(null)
        setIsLoadingEdit(false)
        setShowEditingItem(false)
        setFieldError(null)
      }
    } catch (error) {
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setIsLoading(false)
      setSeverity('error')
    }
  }

  const handleCancelEdit = () => {
    setEditingItem(null)
    setShowEditingItem(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleDelete = itemToDelete => {
    const updatedRows = itensData.filter(item => item.serial !== itemToDelete.serial)
    setItensData(updatedRows)
  }

  const handleClear = () => {
    setItensData([])
  }

  const onSubmit = async data => {
    setIsLoading(true)
    data.quantity = Number(data.quantity)

    const dataItem = {
      rfidCode: data.serial,
      subCompanyId: data.subCompanyId,
    }

    try {
      const response = await postProductCodService(token, nameUrl, dataItem)

      if (response.data.length === 0) {
        setAlerta(true)

        setTitle('Código não existe')
        setSeverity('error')
        setIsLoading(false)

        return
      } else {
        const resData = {
          quantitys: response.data[0].quantitys,
          productName: response.data[0].productName,
          serial: data.serial,
          subCompanyId: data.subCompanyId,
          quantity: data.quantity,
        }
        setItensData(prevItems => [...prevItems, resData])
        setIsLoading(false)
        setValue('quantity', '')
        setValue('serial', '')
      }
    } catch (error) {
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setIsLoading(false)
      setSeverity('error')
    }
  }

  const handleSaveCodeRfidCreate = async () => {
    setLoading(true)

    const data = itensData.map(item => ({
      serial: item.serial,
      subCompanyId: item.subCompanyId,
      quantity: Number(item.quantity),
    }))

    for (let item of data) {
      try {
        const response = await uplaodQuantity(token, nameUrl, item)
        setTitle(response.data.message)
        setAlerta(true)
        setSeverity('success')
        setLoading(false)
        setItensData([])
        setValue('quantity', '')
        setValue('serial', '')
      } catch (error) {
        setTitle('Erro no sistema, tente novamente mais tarde.')
        setAlerta(true)
        setSeverity('error')
        setLoading(false)
      }
    }
  }

  return (
    <Box sx={{ mt: 7 }}>
      <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Mudar quantidade</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }}>
          <Help />
        </IconButton>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alert}
        onClose={() => setAlerta(false)}
        key={vertical + horizontal}
      >
        <Alert onClose={() => setAlerta(false)} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <Card
        variant="outlined"
        sx={{ p: 2, mb: 2 }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 0 }} error={!!errors.subCompanyId}>
                <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                <Controller
                  name="subCompanyId"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} sx={{ width: '100%' }} label="Filial" disabled={isLoading}>
                      {subCompany.map(item => (
                        <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.subCompanyId && (
                  <p
                    style={{
                      color: '#d32f2f',
                      fontSize: '0.75rem',
                      marginLeft: '14px',
                      marginTop: '3px',
                    }}
                  >
                    {errors.subCompanyId.message}
                  </p>
                )}
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 0 }} error={!!errors.equipmentId}>
                <InputLabel id="demo-simple-select-label">Escolha o equipamento</InputLabel>
                <Controller
                  name="equipmentId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      sx={{ width: '100%' }}
                      label="Selecione o tipo de impressora"
                      disabled={isLoading}
                    >
                      <MenuItem value="1">HID</MenuItem>
                    </Select>
                  )}
                />
                {errors.equipmentId && (
                  <p
                    style={{
                      color: '#d32f2f',
                      fontSize: '0.75rem',
                      marginLeft: '14px',
                      marginTop: '3px',
                    }}
                  >
                    {errors.equipmentId.message}
                  </p>
                )}
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              {...register('quantity')}
              label="Quantidade"
              disabled={isLoading}
              fullWidth
              error={!!errors.quantity}
              helperText={errors.quantity ? errors.quantity.message : ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              {...register('serial')}
              inputRef={serialRef}
              label="Serial"
              fullWidth
              disabled={isLoading}
              error={!!errors.serial}
              helperText={errors.serial ? errors.serial.message : ''}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
                gap: { xs: 0, md: 2 },
                justifyContent: 'center',
              }}
            >
              <Button
                variant="outlined"
                disabled={isLoading}
                sx={{ minWidth: '350px' }}
                onClick={handleClear}
              >
                Limpar
              </Button>
              <Button
                startIcon={isLoading && <CircularProgress size={20} />}
                variant="contained"
                sx={{ minWidth: '350px' }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? 'Adicionando...' : 'Adicionar'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={2} component="form">
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              sx={{ maxWidth: { xs: 'auto', md: '500px' }, width: '100%' }}
              type="text"
              variant="outlined"
              placeholder="Pesquisar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={searchFilter}
              onChange={e => setSearchFilter(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            gap={2}
            mt={2}
            sx={{ flexDirection: { xs: 'column', md: 'row' } }}
          >
            <Button
              onClick={() => setRowsProducts([])}
              startIcon={<Clear />}
              variant="outlined"
              disabled={itensData.length === 0}
              sx={{ width: '100%', maxWidth: { xs: 'auto', md: '250px' } }}
            >
              Limpar lista
            </Button>

            <Button
              startIcon={<Save />}
              variant="contained"
              disabled={itensData.length === 0}
              sx={{ width: '100%', maxWidth: { xs: 'auto', md: '250px' } }}
              onClick={handleSaveCodeRfidCreate}
            >
              Salvar dados
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
            <Typography variant="h5">{`Total de itens: ${itensData.length}`}</Typography>
            {/* <Typography variant="h5">{`Total de códigos gerados ${rowsFilter.reduce(
              (total, item) => total + (item.tagsRfid ? item.tagsRfid.length : 0),
              0
            )}`}</Typography> */}
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Filial</StyledTableCell>
                    <StyledTableCell align="left">Produto</StyledTableCell>
                    <StyledTableCell align="center">Quantidade para atualizar</StyledTableCell>
                    <StyledTableCell align="left">Serial</StyledTableCell>

                    <StyledTableCell align="center"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itensData.map(row => (
                    <StyledTableRow key={row.serial}>
                      {showEditingItem && editingItem?.serial === row.serial ? (
                        <>
                          <StyledTableCell align="left">
                            {subCompany.find(item => item.subCompanyId === row.subCompanyId)?.name}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.productName}</StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              type="text"
                              size="small"
                              value={editingItem.quantity}
                              onChange={e =>
                                setEditingItem({ ...editingItem, quantity: e.target.value })
                              }
                            />
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <TextField
                              size="small"
                              value={editingItem.serial}
                              onChange={e =>
                                setEditingItem({ ...editingItem, serial: e.target.value })
                              }
                              inputRef={serialInputRef}
                              error={!!fieldError}
                              helperText={fieldError}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <IconButton onClick={() => handleSaveEdit(editingItem)}>
                              <Save />
                            </IconButton>
                            <IconButton onClick={handleCancelEdit}>
                              <Clear />
                            </IconButton>
                          </StyledTableCell>
                        </>
                      ) : (
                        <>
                          <StyledTableCell align="left">
                            {subCompany.find(item => item.subCompanyId === row.subCompanyId)?.name}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.productName}</StyledTableCell>
                          <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                          <StyledTableCell align="left">{row.serial}</StyledTableCell>
                          <StyledTableCell align="center">
                            <Tooltip title="Edit">
                              <IconButton onClick={() => handleEditClick(row)}>
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton onClick={() => handleDelete(row)}>
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </>
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={itensData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default AssociationSerials
