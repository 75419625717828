import { useCallback, useContext, useEffect, useState } from 'react'
import {
  createPrinterZplService,
  editPrinterZplService,
  getUniquePrinterZplService,
} from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  FormControl,
  Autocomplete,
} from '@mui/material'
import jsPDF from 'jspdf'

import { labelaryPDFService, labelaryService } from '../../../services/printerZPLDefaultServices'
import { AiFillPlusCircle } from 'react-icons/ai'
import { getSingleProductService, searchProductService } from '../../../services/productService'
const TagLogisticsCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const token = localStorage.getItem('token')
  const [zplPdf, setZplPdf] = useState('')
  const [zpl, setZpl] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [zplLab, setZplLab] = useState('')
  const [product, setProduct] = useState('')
  const [client, setClient] = useState('')
  const [volume, setVolume] = useState('')
  const [nf, setNf] = useState('')
  const [search, setSearch] = useState('')
  const [produtos, setProdutos] = useState([])
  const [tagOptions, setTagOptions] = useState([])
  const [searching, setSearching] = useState(false)
  const [idProd, setIdProd] = useState()
  const showClientField = zpl.includes('FDCliente:')
  const showVolumeField = zpl.includes('FDVolume(s):')
  const showProductField = zpl.includes('FDProd:') || zpl.includes('FDN. Produto:')
  const showNFField = zpl.includes('FDNF:')
  const [singleProd, setSingleProd] = useState({
    name: '',
    referencia: '',
    sku: '',
    quantity: '',
    finishGood: false,
    idProductPartner: '',
    picking: '',
  })

  const vertical = 'top'
  const horizontal = 'center'

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  const handleGetOptionsTag = () => {
    const valid = handleFetchData.some(
      item => item.name === 'Etiqueta Logistica para Lojas S/ RFID'
    )

    const dataOptions = handleFetchData.filter(
      item => item.name === 'Etiqueta Logistica para Lojas S/ RFID'
    )
    console.log(dataOptions, 'datas')
    if (valid) {
      setTagOptions(dataOptions)
      setZpl(dataOptions[0].zpl)
    } else {
      setTagOptions(handleFetchData)
    }
  }

  useEffect(() => {
    handleGetOptionsTag()
  }, [handleFetchData])

  const visualizarZPL = async () => {
    setPreviewOpen(true)

    const zplLabelary = await labelaryService(zpl)
    let base64ImageString = Buffer.from(zplLabelary.data, 'binary').toString('base64')
    setZplLab('data:image/png;base64,' + base64ImageString)
  }

  const createPrinterZpl = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      const printerZpl = {
        name: client,
        zpl,
        subCompanyId,
      }

      try {
        // await createPrinterZplService(token, nameUrl, printerZpl)
        setLoading(false)
        setSeverity('success')
        setTitle('ZPL cadastrada com sucesso!')
        setExpanded(false)
        setAlerta(true)
        handleFetchData()
      } catch (err) {
        console.log(err)
        setLoading(false)
        setSeverity('error')
        setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
        setAlerta(true)
      }
    },
    [product, client, volume, zpl, nf, subCompanyId]
  )

  useEffect(() => {
    if (zpl) {
      // Replace placeholders with actual values
      const formattedZpl = zpl
        .replace('{0}', product)
        .replace('{1}', volume)
        .replace('{4}', client)
        .replace('{3}', nf)
      loadPreviewImage(formattedZpl)
    }
  }, [product, volume, client, zpl, nf])

  const handleClose = () => {
    setError(false)
    setPreviewOpen(false)
  }

  const loadPreviewImage = async value => {
    setZplPdf(value)
    try {
      const zplLabelary = await labelaryService(value)
      if (zplLabelary && zplLabelary.data) {
        const base64ImageString = btoa(
          String.fromCharCode.apply(null, new Uint8Array(zplLabelary.data))
        )
        setZplLab('data:image/png;base64,' + base64ImageString)
      } else {
        setZplLab('')
      }
    } catch (error) {
      console.error(error)
      setZplLab('')
    }
  }

  const convertZplToPdf = async () => {
    try {
      const response = await labelaryPDFService(zplPdf)
      if (response && response.data) {
        const pdfBlob = new Blob([response.data])

        const url = URL.createObjectURL(pdfBlob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'etiqueta.pdf'
        a.click()

        URL.revokeObjectURL(url)
      } else {
        console.error('Falha ao converter ZPL em PDF.')
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (zpl) {
      loadPreviewImage()
    }
  }, [zpl])

  const changeProd = async id => {
    if (id) {
      setIdProd(id)
    }
    const prod = await getSingleProductService(token, nameUrl, { productId: id ? id : idProd })
    const finalProd = { ...prod.data }
    finalProd.finishGood = true
    setProduct(finalProd.name)
  }

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  return (
    <>
      <Modal
        open={previewOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          {zplLab ? <img src={zplLab} alt="Preview" height="500px" /> : 'Carregando...'}
        </Box>
      </Modal>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao criar!
          </Alert>
        </Snackbar>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InputLabel id="demo-simple-select-label">Etiqueta</InputLabel>
              <Select
                value={zpl}
                sx={{ width: '100%' }}
                disabled={handleFetchData.some(
                  item => item.name === 'Etiqueta Logistica para Lojas S/ RFID'
                )}
                onChange={values => {
                  setZpl(values.target.value)
                }}
              >
                {tagOptions.map((item, index) => {
                  return (
                    <MenuItem value={item.zpl} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Grid>
          {showClientField && (
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InputLabel id="demo-simple-input-label">Cliente</InputLabel>
                <TextField
                  sx={{ width: '100%' }}
                  value={client}
                  onChange={values => setClient(values.target.value)}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                />
              </Box>
            </Grid>
          )}
          {showProductField && (
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-input-label">Produto</InputLabel>
              <Autocomplete
                disablePortal
                id="lista-produtos"
                options={produtos}
                autoHighlight
                autoSelect
                //sx={{ width: 300 }}
                loading={searching}
                loadingText="Procurando..."
                noOptionsText="Nenhum produto"
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.productId}>
                      {option.label}
                    </li>
                  )
                }}
                renderInput={params => <TextField {...params} />}
                onInputChange={(e, v) => {
                  setSearch(v)
                }}
                onChange={(e, v) => {
                  if (v) {
                    setProduct(v.label)
                    changeProd(v.value)
                  } else {
                    setProduct('')
                  }
                }}
              />
            </Grid>
          )}

          {showVolumeField && (
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InputLabel id="demo-simple-input-label">Volume</InputLabel>
                <TextField
                  sx={{ width: '100%' }}
                  value={volume}
                  onChange={values => setVolume(values.target.value)}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                />
              </Box>
            </Grid>
          )}

          {showNFField && (
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InputLabel id="demo-simple-input-label">Nota Fiscal</InputLabel>
                <TextField
                  sx={{ width: '100%' }}
                  value={nf}
                  onChange={values => setNf(values.target.value)}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box width="100%" height={500} overflow="hidden">
              <InputLabel id="demo-simple-image-label">Pré-Visualizar</InputLabel>
              <Box
                width="100%"
                height={500}
                overflow="hidden"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {zplLab ? (
                  <img
                    src={zplLab}
                    alt="Preview"
                    height="100%"
                    style={{ cursor: 'pointer' }}
                    onClick={() => visualizarZPL()}
                  />
                ) : (
                  'Carregando...'
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createPrinterZpl}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" endIcon={<AiFillPlusCircle />} onClick={convertZplToPdf}>
              Salvar
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => visualizarZPL()}
              sx={{ ml: 2 }}
            >
              Pré-visualizar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default TagLogisticsCreate
