import React, { useContext } from 'react'
import { useState } from 'react'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'
import { useForm } from 'react-hook-form'
import { createSensorAndPerson } from '../../../services/productService'

const CreateSensorAndPerson = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
  companyId,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl, company } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const [loading, setLoading] = useState(false)

  const handleCreateClient = async object => {
    setLoading(true)
    const data = {
      productName: object.productName,
      serial: object.serial,
      subCompanyId: subCompanyId,
      companyId: company.companyId,
    }
    try {
      const response = await createSensorAndPerson(token, nameUrl, data)
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSeverity('success')
      setTitle(response.data.message)
      handleFetchData(subCompanyId)
      reset()
      setSubCompanyId('')
    } catch (err) {
      console.log(err)
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente')
    }
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleSubmit(handleCreateClient)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth variant="outlined" error={!!errors.subCompanyId}>
                <InputLabel id="subCompanyId-label">Filial</InputLabel>
                <Select
                  label="Filial"
                  labelId="subCompanyId-label"
                  {...register('subCompanyId', {
                    required: 'Filial é obrigatória',
                  })}
                >
                  {subCompany.map((item, index) => (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.subCompanyId?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('productName', {
                    required: 'Campo obrigatório',
                  })}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Nome do produto"
                  error={!!errors.productName}
                  helperText={errors.productName?.message}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('serial', {
                    required: 'Campo obrigatório',
                  })}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Serial"
                  error={!!errors.serial}
                  helperText={errors.serial?.message}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  mt: 3,
                  justifyContent: 'center',
                }}
              >
                <Button variant="contained" type="submit">
                  Salvar
                </Button>

                <Button
                  variant="contained"
                  type="button"
                  onClick={() => setExpanded(false)}
                  sx={{ ml: 2 }}
                >
                  Cancelar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default CreateSensorAndPerson
