import { useCallback, useContext, useEffect, useState } from 'react'
import {
  getIntegrationAssociationService,
  getIntegrationCredencialService,
  integrationAssociationEditService,
} from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'

const IntegrationAssociationEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  integrationAssociationId,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [integrationCredentialFromId, setIntegrationCredentialFromId] = useState('')
  const [integrationCredentialToId, setIntegrationCredentialToId] = useState('')
  const [integrationDefault, setIntegrationDefault] = useState([])
  const [companyId, setCompanyId] = useState('')
  const [loading, setLoading] = useState(false)

  const editIntegrationAssociation = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)
      console.log('saindo ', integrationCredentialFromId, ' para ', integrationCredentialToId)
      const credencial = {
        integrationAssociationId,
        companyId,
        integrationCredentialFromId,
        integrationCredentialToId,
      }

      try {
        await integrationAssociationEditService(token, nameUrl, credencial)
        setSeverity('success')
        setTitle('Integração editada com sucesso!')
        setEditing(false)
        setAlerta(true)
        setLoading(false)
        handleFetchData()
      } catch (err) {
        console.log(err)
        setSeverity('error')
        setTitle('Erro ao editar, verifique os dados e tente novamente')
        setAlerta(true)
        setLoading(false)
      }
    },
    [companyId]
  )

  const getIntegrationAssociation = async () => {
    setLoading(true)
    try {
      const response = await getIntegrationAssociationService(
        token,
        nameUrl,
        integrationAssociationId
      )
      setIntegrationCredentialFromId(response.data.integrationCredencialFromId)
      setIntegrationCredentialToId(response.data.integrationCredencialToId)
      setCompanyId(response.data.companyId)

      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const getIntegrationCredential = async () => {
    setLoading(true)
    const limit = 500
    const offset = 0
    try {
      const response = await getIntegrationCredencialService(token, nameUrl, limit, offset)
      const defaults = response.data.result
      setIntegrationDefault(defaults)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    getIntegrationCredential()
    getIntegrationAssociation()
  }, [integrationAssociationId])

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={editIntegrationAssociation}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select
                label="Empresa"
                value={companyId}
                onChange={values => setCompanyId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.companyId} key={index}>
                      {item.nameCompany}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              justifyContent: 'space-evenly',
              mt: 2,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Credencial De:</InputLabel>
              <Select
                label="Credencial de:"
                value={integrationCredentialFromId}
                onChange={values => setIntegrationCredentialFromId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {integrationDefault.map((item, index) => {
                  return (
                    <MenuItem value={item.integrationCredencialId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Credencial Para:</InputLabel>
              <Select
                label="Credencial para:"
                value={integrationCredentialToId}
                onChange={values => setIntegrationCredentialToId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {integrationDefault.map((item, index) => {
                  return (
                    <MenuItem value={item.integrationCredencialId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setEditing(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default IntegrationAssociationEdit
