import React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { VpnKey } from '@mui/icons-material'
import Switch from '@mui/material/Switch' // Importe o componente Switch (caso não esteja importado)
import { Lock, LockOpen, ExpandMore } from '@mui/icons-material' // Importe os ícones necessários

export const ComponentTable = ({
  data,
  columns,
  placeholderSearch,
  iconBtn,
  heightTbodyMessage = '500px',
  titleBtn,
  header = true,
  numberPage = 10,
  loading,
  pagination = true,
  value,
  onChange,
  grid1,
  grid2,
  tableRow,
  additionalStyles = {},
  onclickBtn,
  user,
}) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(numberPage)

  const indexOfLastItem = (page + 1) * rowsPerPage
  const indexOfFirstItem = indexOfLastItem - rowsPerPage
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const selectedRows = []

  return (
    <Box
      sx={{
        border: '1px solid rgba(224, 224, 224, 1)',
        height: '100%',
        borderRadius: 2,
        ...additionalStyles,
      }}
    >
      {/* {header && (
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={grid1}>
              <ComponentSearch placeholder={placeholderSearch} value={value} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={grid2}>
{user && user.typeUser === 1 && (
                <ButtomTable title={titleBtn} iconBtn={iconBtn} onClick={onclickBtn} />
              )}
            </Grid>
          </Grid>
        </Box>
      )} */}

      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          borderRadius: '0px',
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          ...additionalStyles,
        }}
      >
        <TableContainer>
          {header && (
            <Typography sx={{ mb: 1, ml: 2 }}>
              {currentData.length > 1
                ? currentData.length + ' Resultados'
                : currentData.length + ' Resultado'}{' '}
            </Typography>
          )}
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.slice(0, -1).map((column, index) => (
                  <TableCell
                    sx={{ bgcolor: '#000', color: '#fff', padding: '15px 15px' }}
                    key={`column-${index}`}
                    style={{
                      maxWidth: column.maxWidth,
                      width: column.maxWidth,
                      textAlign: column.textAlignPosition || 'start',
                    }}
                  >
                    {column.heading}
                  </TableCell>
                ))}
                <TableCell
                  sx={{ bgcolor: '#000', color: '#fff', padding: '15px 15px' }}
                  key={`column-${columns.length - 1}`}
                  style={{
                    maxWidth: columns[columns.length - 1].maxWidth,
                    width: columns[columns.length - 1].maxWidth,
                    textAlign: columns[columns.length - 1].textAlignPosition || 'start',
                  }}
                >
                  {columns[columns.length - 1].heading}
                </TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody sx={{ height: heightTbodyMessage }}>
                <TableRow>
                  <TableCell
                    colSpan={columns.length + 1}
                    align="center"
                    sx={{ flexGrow: 2, p: '15px 15px' }}
                  >
                    {/* <ComponentLoading /> */}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {currentData.length > 0 && (
                  <TableBody>
                    {currentData.map((row, rowIndex) => {
                      const isSelected = selectedRows.includes(indexOfFirstItem + rowIndex)

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={`1${rowIndex}`}
                          sx={{ backgroundColor: isSelected ? '#e0f7fa' : 'inherit' }}
                        >
                          {columns.slice(0, -1).map((column, columnIndex) => (
                            <TableCell
                              style={{
                                textAlign: column.textAlignPosition,
                                padding: '15px 15px',
                              }}
                              key={`1${columnIndex}`}
                            >
                              {column.element(row)}
                            </TableCell>
                          ))}
                          <TableCell
                            style={{
                              textAlign: columns[columns.length - 1].textAlignPosition || 'start',
                              padding: '15px 15px',
                            }}
                          >
                            {columns[columns.length - 1].element(row)}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )}

                {!currentData.length && (
                  <TableBody sx={{ height: heightTbodyMessage }}>
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} align="center" sx={{ flexGrow: 2 }}>
                        {/* <CustomNoRowsOverlay /> */}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            labelRowsPerPage={'Itens por página'}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            rowsPerPageOptions={[numberPage, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </Box>
  )
}
