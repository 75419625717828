import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  MarkerF,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditRoute from './editRoute'
import RotaGeral from './rotaGeral'

const RouteMap = ({ mapScreen, geral, movRoute, rotas }) => {
  const [direcoes, setDirecoes] = useState({})
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [waypoints, setWaypoints] = useState([])
  const [legs, setLegs] = useState([])
  const gambiarra = useRef(0)
  // const [rotas, setRotas] = useState([])

  useEffect(() => {
    gambiarra.current = 0
    const wpts = []
    if (rotas.length > 0 && movRoute.latitude && movRoute.longitude) {
      const dirFinal = {
        origin: {
          lat: parseFloat(movRoute.latitude.replace(',', '.')),
          lng: parseFloat(movRoute.longitude.replace(',', '.')),
        },
        destination: {
          lat: parseFloat(movRoute.latitude.replace(',', '.')),
          lng: parseFloat(movRoute.longitude.replace(',', '.')),
        },
      }

      rotas.map(rota => {
        if (rota.latitude && rota.longitude) {
          const x = { location: { lat: null, lng: null }, stopover: true }
          x.location.lat = parseFloat(rota.latitude.replace(',', '.'))
          x.location.lng = parseFloat(rota.longitude.replace(',', '.'))
          wpts.push(x)
        }
      })
      setWaypoints(wpts)
      setDirecoes(dirFinal)
    }
  }, [rotas])

  // useEffect(() => {
  //   gambiarra.current = 0
  //   const wpts = []
  //   if (rotaTotal.length > 1) {
  //     const dirFinal = {
  //       origin: {
  //         lat: parseFloat(movRoute.latitude.replace(',', '.')),
  //         lng: parseFloat(movRoute.longitude.replace(',', '.')),
  //       },
  //       destination: {
  //         lat: parseFloat(movRoute.latitude.replace(',', '.')),
  //         lng: parseFloat(movRoute.longitude.replace(',', '.')),
  //       },
  //     }

  //     rotaTotal.map(rota => {
  //       const x = { location: { lat: null, lng: null }, stopover: true }
  //       x.location.lat = parseFloat(rota.latitude.replace(',', '.'))
  //       x.location.lng = parseFloat(rota.longitude.replace(',', '.'))
  //       wpts.push(x)
  //     })
  //     setWaypoints(wpts)
  //     setDirecoes(dirFinal)
  //   }
  // }, [rotaTotal])

  //===== GOOGLE MAPS

  const directionsCallback = response => {
    if (response !== null) {
      if (response.status === 'OK' && gambiarra.current === 0) {
        gambiarra.current++
        setDirectionsResponse(response)
        setLegs(response.routes[0].legs)
      } else {
        gambiarra.current = 0
      }
    }
  }

  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  })
  const [map, setMap] = useState(null)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_API,
  })

  const onLoad = useCallback(
    function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(center)
      map.fitBounds(bounds)

      setMap(map)
    },
    [center]
  )

  const onUnmount = useCallback(
    function callback(map) {
      setMap(null)
    },
    [center]
  )

  const containerStyle = {
    // width: '600px',
    height: '600px',
  }

  //===================

  useEffect(() => {
    if (!mapScreen.latitude) {
      return
    }
    if (mapScreen.latitude && mapScreen.longitude) {
      setCenter({
        lat: parseFloat(mapScreen.latitude.replace(',', '.')),
        lng: parseFloat(mapScreen.longitude.replace(',', '.')),
      })
    }
  }, [mapScreen])

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', p: 2 }}
      width={{ xs: 1, sm: 1, md: 0.75 }}
    >
      {!geral && (
        <Accordion disableGutters sx={{ mb: 2, width: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Informações da rota</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <EditRoute movRoute={mapScreen} />
          </AccordionDetails>
        </Accordion>
      )}
      {geral && (
        <Accordion disableGutters sx={{ mb: 2, width: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Informações da rota</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RotaGeral legs={legs} rotas={rotas} />
          </AccordionDetails>
        </Accordion>
      )}
      <Box sx={{ width: 1 }}>
        {isLoaded && !geral ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={9}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <>
              <MarkerF position={center} />
            </>
          </GoogleMap>
        ) : (
          <></>
        )}

        {isLoaded && geral && rotas.length > 0 && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            // center={center}
            zoom={8}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {/* Child components, such as markers, info windows, etc. */}
            <>
              <DirectionsService
                options={{
                  destination: direcoes.destination,
                  origin: direcoes.origin,
                  travelMode: 'DRIVING',
                  waypoints,
                }}
                callback={e => directionsCallback(e)}
              />
              {directionsResponse !== null && (
                <>
                  <DirectionsRenderer
                    options={{
                      directions: directionsResponse,
                      polylineOptions: { strokeColor: 'red', strokeOpacity: 0.8 },
                    }}
                    // directions={directionsResponse}
                  />
                </>
              )}
            </>
          </GoogleMap>
        )}
      </Box>
    </Box>
  )
}

export default RouteMap
