import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { styled, tableCellClasses } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ListItemText from '@mui/material/ListItemText'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import TablePagination from '@mui/material/TablePagination'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import InputAdornment from '@mui/material/InputAdornment'
import { formatNumbers } from '../../../../../utils/formatNumbers'
import { Context } from '../../../../../context/AuthContext'
import { Add, Delete, Edit } from '@mui/icons-material'
import { colorTableDefault } from '../../../../TemplateDefault'
import {
  createBuckCreateMobileList,
  getProductItensRfid,
} from '../../../../../services/productService'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function ComponentQuantityCreate({
  open,
  onClose,
  product,
  productRows,
  typeOrder,
  movimentationOrderNumberOtherSystem,
  message,
  subCompanyId,
  onGetCount,
}) {
  const token = localStorage.getItem('token')
  const { subCompany, userId, nameUrl } = useContext(Context)
  const [loading, setLoading] = useState('')
  const [isLoading, setIsLoading] = useState('')
  const [valueText, setValueText] = useState('')
  const [title, setTitle] = useState('')
  const [messageError, setMessageError] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [register, setRegister] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [codeList, setCodeList] = useState([])
  const vertical = 'top'
  const horizontal = 'center'
  const inputRef = useRef(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function Row(props) {
    const { row, onDelete } = props

    const handleDeleteClick = () => {
      onDelete(row)
    }

    return (
      <React.Fragment>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell align="center" width="200px">
            <Tooltip title="Apagar" arrow>
              <IconButton onClick={handleDeleteClick}>
                <Delete />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="left">{row.productName}</StyledTableCell>
          <StyledTableCell align="left">{row.sEPCHex}</StyledTableCell>
          <StyledTableCell align="left">{row.sku}</StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    )
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus()
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [open, isLoading])

  const handleAddList = async () => {
    setIsLoading(true)
    const codeExists = codeList.some(item => item.sEPCHex === valueText)

    if (codeExists) {
      setTitle('Este código RFID ja foi lido.')
      setSeverity('error')
      setValueText('')
      setRegister(true)
      setIsLoading(false)
      return
    }

    try {
      const response = await getProductItensRfid(token, nameUrl, subCompanyId, valueText)
      const newProduct = {
        sEPCHex: response.data.serial,
        productName: response.data.productName,
        sku: response.data.sku,
        subCompanyId: response.data.subCompanyId,
      }
      setCodeList(prevList => [...prevList, newProduct])
      setIsLoading(false)
      setValueText('')
    } catch (error) {
      setIsLoading(false)
      setTitle('Nenhum produto encontrado.')
      setSeverity('error')
      setValueText('')
      setRegister(true)
    }
  }

  const handleSave = async () => {
    setLoading(true)
    setOpenDialog(false)
    const data = codeList.map(item => ({
      sEPCHex: item.sEPCHex,
      subCompanyId: subCompanyId,
      movimentationOrderNumberOtherSystem: movimentationOrderNumberOtherSystem,
      userId: userId,
      typeOrder: typeOrder,
    }))

    try {
      const response = await createBuckCreateMobileList(token, nameUrl, data)
      setTitle(response.data.message)
      setSeverity('success')
      setValueText('')
      setRegister(true)
      setLoading(false)
      onGetCount()
      onClose()
      setCodeList([])
    } catch (error) {
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setRegister(true)
      setLoading(false)
    }
  }

  const handleDialog = () => {
    setOpenDialog(true)
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      return handleAddList()
    }
  }

  const handleDelete = itemToDelete => {
    const updatedRows = codeList.filter(item => item !== itemToDelete)
    setCodeList(updatedRows)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={register}
        onClose={() => setRegister(false)}
        key={vertical + horizontal}
      >
        <Alert onClose={() => setRegister(false)} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deseja realizar a contagem?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenDialog(false)}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleSave} autoFocus>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={() => {
          onClose()
          setCodeList([])
          setValueText('')
        }}
        sx={{ m: 2 }}
        fullScreen
        onEnter={() => inputRef.current.focus()}
      >
        {loading ? (
          <Box
            width="100%"
            maxHeight="100%"
            height="100%"
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <CircularProgress />
            <DialogTitle>Realizando contagem...</DialogTitle>
          </Box>
        ) : (
          <>
            <DialogTitle>Contagem de estoque</DialogTitle>
            <DialogContent>
              <Divider sx={{ my: 2 }} />
              <DialogContentText> Insira o código RFID correspondente:</DialogContentText>
              <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                <TextField
                  inputRef={inputRef}
                  sx={{ my: 2 }}
                  autoFocus
                  margin="dense"
                  id="rfid-code"
                  size="small"
                  type="text"
                  onKeyDown={handleKeyPress}
                  value={valueText}
                  disabled={isLoading}
                  error={!!messageError}
                  helperText={messageError}
                  onChange={e => setValueText(e.target.value)}
                  fullWidth
                  variant="outlined"
                  inputProps={{ min: 1 }}
                  InputProps={{
                    endAdornment: isLoading && (
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  startIcon={!isLoading && <Add />}
                  endIcon={isLoading && <CircularProgress size={20} />}
                  disabled={isLoading}
                  variant="contained"
                  sx={{ width: '250px', mb: messageError ? 2 : 0 }}
                  onClick={handleAddList}
                >
                  {isLoading ? 'Adicionando...' : 'Adicionar [ENTER]'}
                </Button>
              </Box>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left"></StyledTableCell>
                      <StyledTableCell align="left">Nome do produto</StyledTableCell>
                      <StyledTableCell align="left">Serial</StyledTableCell>
                      <StyledTableCell align="left">SKU</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {codeList
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(row => (
                        <Row key={row.serial} row={row} onDelete={handleDelete} />
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={codeList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose()
                  setCodeList([])
                  setValueText('')
                }}
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button type="submit" variant="contained" onClick={handleDialog} disabled={isLoading}>
                Enviar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}
