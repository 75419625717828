import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  createProductAssociationService,
  searchProductService,
} from '../../../services/productService'
import { setPermission } from '../../../permissions/userPermissions'

import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { getAlocationStockGetAll } from '../../../services/alocationServices'
import { getFaction } from '../../../services/factionService'
import { getDepositService } from '../../../services/depositService'
import { getAllMovBreakService, getAllMovOrderService } from '../../../services/movBreakServices'

const ProductAssociationCreate = ({
  expanded,
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
}) => {
  const { subCompany, userId, userRoles, nameUrl } = useContext(Context)

  const token = localStorage.getItem('token')
  const [products, setProducts] = useState([{ label: '', value: '' }])
  const [product, setProduct] = useState([])
  const [alocation, setAlocation] = useState([])
  const [faction, setFaction] = useState([])
  const [deposit, setDeposit] = useState([])
  const [movimentationOrder, setMovimentationOrder] = useState([])
  const [movimentationOrderBreak, setMovimentationOrderBreak] = useState([])

  const [productName, setProductName] = useState('')
  const [alocationStockId, setAlocationStockId] = useState('')
  const [subCompanyId, setSubCompanyId] = useState('')
  const [productId, setProductId] = useState('')
  const [barcode, setBarcode] = useState('')
  const [lote, setLote] = useState('')
  const [serial, setSerial] = useState('')
  const [factionId, setFactionId] = useState('')
  const [laboratory, setLaboratory] = useState('')
  const [depositId, setDeposityId] = useState('')
  const [dataExpiration, setDataExpiration] = useState('')
  const [op, setOp] = useState('')
  const [opPrincipal, setOpPrincipal] = useState('')
  const [movimentationOrderId, setMovimentationOrderId] = useState('')
  const [movimentationOrderBreakId, setMovimentaitionOrderBreakId] = useState('')
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [productValid, setProductValid] = useState(true)

  const [permissionFieldLote, setPermissionFieldLote] = useState(false)
  const [permissionFieldLab, setPermissionFieldLab] = useState(false)
  const [permissionFieldDateValid, setPermissionFieldDateValid] = useState(false)

  const validateProduct = () => {
    if (product === '') {
      setProductValid(false)
      return false
    } else {
      setProductValid(true)
      return true
    }
  }

  const createProductAssociation = async e => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    const isProductValid = validateProduct()

    if (!isProductValid) {
      setLoading(false)
      setTitle('Campo Produto Obrigatório!')
      setSeverity('error')
      setAlerta(true)
      return
    }

    let association = {
      productName: productName,
      subCompanyId: subCompanyId,
      productId: productId.productId,
      barcode: barcode,
      lote: lote,
      serial: serial,
      laboratory: laboratory,
      dataExpiration: dataExpiration,
      userId: userId,
      op,
      opPrincipal,
      //movimentationOrderId,
      //movimentationOrderBreakId,
    }

    if (alocationStockId) {
      association.alocationStockId = alocationStockId
    }
    if (factionId) {
      association.factionId = factionId
    }
    if (depositId) {
      association.depositId = depositId
    }

    try {
      const response = await createProductAssociationService(token, nameUrl, association)

      setTitle(response.data.message)
      setSeverity('success')
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      handleFetchData()

      setProduct([])
      setProductId('')
      setSerial('')
      setBarcode('')
      setLote('')
      setLaboratory('')
      setDataExpiration('')
      setSubCompanyId('')
      setOp('')
      setOpPrincipal('')
      setDeposityId('')
      setFactionId('')
      setProductName('')
      setAlocationStockId('')
    } catch (err) {
      setTitle('Erro ao cadastrar, tente novamente!')
      setSeverity('error')
      setLoading(false)
      setAlerta(true)
    }
  }

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProducts([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProducts(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  const getAlocation = async () => {
    const data = {
      subCompanyId: subCompanyId,
      limit: 500,
      offset: 1,
    }
    try {
      const response = await getAlocationStockGetAll(token, nameUrl, data)
      setAlocation(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }

  const getFactionAll = async () => {
    const data = {
      companyId: subCompany[0].companyId,
      limit: 500,
      offset: 1,
    }
    try {
      const response = await getFaction(token, nameUrl, data)
      setFaction(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }

  const getDepositAll = async () => {
    const data = {
      subCompanyId: subCompanyId,
      limit: 500,
      offset: 1,
    }
    try {
      const response = await getDepositService(token, nameUrl, data)
      setDeposit(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }

  const getMovimentationOrderAll = async () => {
    const data = {
      SubCompanyId: subCompanyId,
      limit: 500,
      offset: 1,
    }
    try {
      const response = await getAllMovOrderService(token, nameUrl, data)
      setMovimentationOrder(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }

  const getMovimentationOrderBreakAll = async id => {
    setLoading(true)
    setMovimentationOrderId(id)
    const data = {
      movimentationOrderId: id,
      limit: 500,
      offset: 1,
    }
    try {
      const response = await getAllMovBreakService(token, nameUrl, data)
      setMovimentationOrderBreak(response.data.result)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (expanded) {
      //getProductTotal()
      getAlocation()
      getFactionAll()
      getDepositAll()
      getMovimentationOrderAll()
    }
  }, [subCompanyId])

  return (
    <>
      <Box>
        <Box component="form" onSubmit={createProductAssociation} sx={{ mt: '2rem' }}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                <Select
                  value={subCompanyId}
                  label="Selecione a filial"
                  onChange={values => setSubCompanyId(values.target.value)}
                >
                  {subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <Autocomplete
                  disablePortal
                  id="lista-produtos"
                  options={products}
                  //sx={{ width: 300 }}
                  loading={searching}
                  loadingText="Procurando..."
                  noOptionsText="Nenhum produto encontrado"
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.productId}>
                        {option.label}
                      </li>
                    )
                  }}
                  renderInput={params => <TextField {...params} label="Procurar produto" />}
                  onInputChange={(e, v) => {
                    setSearch(v)
                  }}
                  onChange={(e, v) => setProductId(v)}
                />
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={productName}
                id="standard-basic"
                label="Nome do Produto"
                variant="standard"
                onChange={values => setProductName(values.target.value)}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Alocação</InputLabel>
                <Select
                  value={alocationStockId}
                  label="Selecione o produto"
                  onChange={values => setAlocationStockId(values.target.value)}
                >
                  {alocation.map(item => {
                    return <MenuItem value={item.alocationStockId}>{item.address}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Fábrica</InputLabel>
                <Select
                  value={factionId}
                  label="Selecione o fabrica"
                  onChange={values => setFactionId(values.target.value)}
                >
                  {faction.map(item => {
                    return <MenuItem value={item.factionId}>{item.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Depósito</InputLabel>
                <Select
                  value={depositId}
                  label="Selecione o depósito"
                  onChange={values => setDeposityId(values.target.value)}
                >
                  {deposit.map(item => {
                    return <MenuItem value={item.depositId}>{item.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {/* <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Movimentation Order</InputLabel>
                <Select
                  value={movimentationOrderId}
                  label="Selecione o Movimentation Order"
                  onChange={values => getMovimentationOrderBreakAll(values.target.value)}
                >
                  {movimentationOrder.map(item => {
                    return (
                      <MenuItem value={item.movimentationOrderId}>
                        {item.movimentationOrderNumberBihands}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Movimentation Order Break</InputLabel>
                <Select
                  value={movimentationOrderBreakId}
                  label="Selecione o Movimentation Order Break"
                  onChange={values => setMovimentaitionOrderBreakId(values.target.value)}
                >
                  {movimentationOrderBreak.map(item => {
                    return (
                      <MenuItem value={item.movimentationOrderBreakId}>
                        {item.movimentationOrderNumberBihandsBreak}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box> */}
          {/* <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={op}
                id="standard-basic"
                label="Operador"
                variant="standard"
                onChange={values => setOp(values.target.value)}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={opPrincipal}
                id="standard-basic"
                label="Operador Principal"
                variant="standard"
                onChange={values => setOpPrincipal(values.target.value)}
                type="text"
              />
            </Box>
          </Box> */}
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={barcode}
                id="standard-basic"
                label="Código de Barras"
                variant="standard"
                onChange={values => setBarcode(values.target.value)}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={lote}
                id="standard-basic"
                label="Lote"
                variant="standard"
                onChange={values => setLote(values.target.value)}
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={laboratory}
                id="standard-basic"
                label="Laboratório"
                variant="standard"
                onChange={values => setLaboratory(values.target.value)}
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel id="demo-simple-select-label" sx={{ mt: '1rem' }}>
                Data de Validade
              </InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={dataExpiration}
                id="standard-basic"
                //label="Data de Validade"
                variant="standard"
                onChange={values => setDataExpiration(values.target.value)}
                type="date"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={serial}
                id="standard-basic"
                label="Serial"
                variant="standard"
                onChange={values => setSerial(values.target.value)}
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              mt: '1rem',
            }}
            flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
          >
            <Box
              width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{
                mt: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                gap: 2,
              }}
            >
              <Button variant="contained" sx={{ width: '100%' }} type="submit">
                Salvar
              </Button>
            </Box>
            <Box
              width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                type="button"
                onClick={() => setExpanded(!expanded)}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ProductAssociationCreate
