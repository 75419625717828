import { useEffect, useState, useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import { setPermission } from '../../../permissions/userPermissions'
import {
  FIELDS_BARCODE,
  FIELDS_CODCOLOR,
  FIELDS_COLOR,
  FIELDS_DATAVALIDATION,
  FIELDS_LABORATORY,
  FIELDS_LOTE,
  FIELDS_SIZE,
} from '../../../const/RoleConstants'
import { createFaction } from '../../../services/factionService'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

const FaccaoCreate = ({ setExpanded, setAlerta, setSeverity, setTitle, handleFetchData }) => {
  const { subCompany, nameUrl } = useContext(Context)
  const [companyId, setCompanyId] = useState('')

  const token = localStorage.getItem('token')
  const [name, setName] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [sequence, setSequence] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleCreate = async e => {
    e.preventDefault()

    //let dateNow = new Date()
    setLoading(true)
    setError(false)

    const data = {
      companyId: companyId,
      name: name,
      cnpj: cnpj,
      sequence: sequence,
    }

    try {
      await createFaction(token, nameUrl, data) /***** */
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSeverity('success')
      setTitle('Facção cadastrada com sucesso!')
      handleFetchData()
    } catch (err) {
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
    }
  }

  useEffect(() => {
    // getPermissions()
  }, [])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleCreate}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select
                label="Empresa"
                value={companyId}
                onChange={values => setCompanyId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {subCompany.map(item => {
                  return (
                    <MenuItem key={item.companyId} value={item.companyId}>
                      {item.nameCompany}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 3 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%' }}
                value={sequence}
                onChange={values => setSequence(values.target.value)}
                id="outlined-basic"
                label="Sequência"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={cnpj}
                onChange={values => setCnpj(values.target.value)}
                id="outlined-basic"
                label="CNPJ"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" type="submit">
              Salvar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => setExpanded(false)}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default FaccaoCreate
