import React, { useContext } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useEffect, useState } from 'react'

import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import {
  createProcessSequence,
  deleteProcessSequence,
  editProcess,
  getProcessId,
  getProcessSequenceAllList,
} from '../../../services/processServices'
import { Context } from '../../../context/AuthContext'

const ProcessEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  processId,
  handleFetchData,
  setExpanded,
  StyledTableCell,
  StyledTableRow,
  setLoading,
}) => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [name, setName] = useState('')
  const [status, setStatus] = useState(false)

  const [error, setError] = useState(false)
  const [selectSubCompany, setSelectSubCompany] = useState('')
  const [active, setActive] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [create, setCreate] = useState()
  const [processSequenceList, setProcessSequenceList] = useState([])

  const [newPostoOrdem, setNewPostoOrdem] = useState('')
  const [newPostoName, setNewPostoName] = useState('')

  const [deleteOpen, setDeleteOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')

  const handleEdit = async () => {
    setLoading(true)
    const data = {
      subCompanyId: selectSubCompany,
      name,
      active,
      processId,
      create,
      isDelete,
    }
    try {
      await editProcess(token, nameUrl, data)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setError(true)
    }
  }

  const closeDelete = () => {
    setAlerta(false)
    setDeleteOpen(false)
  }

  useEffect(() => {
    handleGetProcess()
  }, [])

  const postoOrdem = value => {
    const valueFinal = value.replace(/[^\d]/, '')
    setNewPostoOrdem(valueFinal)
  }

  const handleDelete = async () => {
    setLoading(true)
    setDeleteOpen(false)
    try {
      await deleteProcessSequence(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Posto de trabalho deletado com sucesso!')

      setAlerta(true)

      handleGetProcess()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const createPosto = async () => {
    const ordemFinal = parseInt(newPostoOrdem)
    if (isNaN(ordemFinal)) {
      setNewPostoOrdem(`Ordem inválida`)
      return
    }
    setLoading(true)
    try {
      const procFinal = {
        name: newPostoName,
        order: ordemFinal,
        active: true,
        processId,
        subCompanyId: selectSubCompany,
      }
      const response = await createProcessSequence(token, nameUrl, procFinal)
      getProcessSequenceList(selectSubCompany)
      setNewPostoOrdem('')
      setNewPostoName('')
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleGetProcess = async () => {
    try {
      const response = await getProcessId(token, nameUrl, processId)
      getProcessSequenceList(response.data.subCompanyId)
      setName(response.data.name)
      setActive(response.data.active)
      setSelectSubCompany(response.data.subCompanyId)
      setIsDelete(response.data.isDelete)
      setCreate(response.data.create)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handleGetProcess()
  }, [processId])

  const deleteAlert = data => {
    setDeleteOpen(true)
    setIdDelete(data)
  }

  const getProcessSequenceList = async subId => {
    try {
      const response2 = await getProcessSequenceAllList(token, nameUrl, {
        subCompanyId: subId,
        processId: processId,
      })
      const listSequence = response2.data.sort((a, b) => a.order - b.order)

      setProcessSequenceList(listSequence)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Box>
        <Box
          component="form"
          sx={{ mt: '2rem', width: 1 }}
          //onSubmit={createProcess}
        >
          <Dialog
            open={deleteOpen}
            onClose={closeDelete}
            aria-describedby="alert-dialog-slide-description"
            sx={{ textAlign: 'center' }}
          >
            <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
            <DialogActions>
              <Button onClick={closeDelete}>Cancelar</Button>
              <Button onClick={() => handleDelete()}>Sim</Button>
            </DialogActions>
          </Dialog>
          <Box sx={{ display: { md: 'flex' }, gap: 2, alignItems: 'center' }}>
            <Box sx={{ width: { xs: 1, md: 0.3 } }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Filial"
                value={selectSubCompany}
                onChange={values => {
                  setSelectSubCompany(values.target.value)
                }}
                sx={{ width: 1 }}
              >
                <MenuItem value="" disabled selected>
                  Filial
                </MenuItem>
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box sx={{ width: { xs: 1, md: 0.4 } }}>
              <InputLabel>Nome Processo Produtivo</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                // label="Nome Processo"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box sx={{ width: { xs: 1, md: 0.1 } }}>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Ativo" />
              </FormGroup>
            </Box>
          </Box>
          <Box
            sx={{
              width: 1,
              mt: 2,
              display: 'flex',
              gap: 2,
              border: '1px solid black',
              borderRadius: 2,
              p: 2,
              flexDirection: 'column',
            }}
          >
            <Box sx={{ width: 1, display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 2, width: 0.5 }}>
                <FormControl sx={{ width: 1 }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={newPostoName}
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    label="Nome Posto de Trabalho"
                    onChange={e => setNewPostoName(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box sx={{ width: 0.5, gap: 2, display: 'flex' }}>
                <FormControl sx={{ width: 0.5 }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={newPostoOrdem}
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    label="Ordem"
                    onChange={e => setNewPostoOrdem(e.target.value)}
                  />
                </FormControl>
                <FormControl sx={{ width: 0.5 }}>
                  <TextField
                    sx={{ width: '100%' }}
                    //value={statusApp}
                    id="outlined-basic"
                    variant="outlined"
                    label="Status"
                    type="text"
                    disabled
                  />
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ width: 1, display: 'flex', gap: 2 }}>
              <Button variant="outlined" onClick={createPosto}>
                Adicionar Posto de trabalho
              </Button>
            </Box>
          </Box>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ width: 0.2 }}></StyledTableCell>
                  <StyledTableCell align="left">Nome Posto de trabalho</StyledTableCell>
                  <StyledTableCell align="left">Ordem</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {processSequenceList.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        deleteAlert(row.processSequenceId)
                      }}
                    >
                      <DeleteForeverIcon sx={{ cursor: 'pointer' }} />
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                    <StyledTableCell align="left">{row.order}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="outlined"
            // onClick={saveProcess}
            // disabled={saving}
            sx={{ mt: 2 }}
          >
            Salvar Processo
          </Button>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          //open={saving}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>{' '}
    </>
  )
}

export default ProcessEdit
