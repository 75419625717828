import { useContext, useEffect } from 'react'
import { useCallback, useState } from 'react'
import {
  createRoleCategoryService,
  createRoleService,
  editRoleCategoryService,
  getRolesCategoryIdService,
  getRolesService,
} from '../../../services/admServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
  Grid,
  FormControl,
  ListItem,
  Chip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  tableCellClasses,
  styled,
  TablePagination,
  Checkbox,
  Typography,
  IconButton,
} from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import DeleteIcon from '@mui/icons-material/Delete'
import { colorTableDefault } from '../../TemplateDefault'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Delete from '@mui/icons-material/Delete'
import { Context } from '../../../context/AuthContext'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const data = {
  name: 'Produtos',
  description: 'Meus Produtos',
  active: false,
  roleCategoryId: '6d8c47d5-515d-435b-84a8-042d23282e58',
  permissions: [
    {
      description: null,
      name: 'ADM',
      active: true,
      roleId: '63e7c7cb-e3e8-4ebe-aa9c-08dae4f0ddb8',
    },
    {
      description: null,
      name: '12345teste',
      active: true,
      roleId: '1062c78d-9567-4267-aa9e-08dae4f0ddb8',
    },
    {
      description: null,
      name: 'ACCESSPRODUCT',
      active: true,
      roleId: 'bcc6f670-6e6e-4330-d939-08daffc8ce80',
    },
  ],
}

const RulesCategoryEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  roleCategoryId,
  roleId,
  roleName,
  handleFetchData,
  setExpanded,
}) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState([])
  const [roleCategory, setRoleCategory] = useState({})
  const [roleLimit, setRoleLimit] = useState(1000)
  const [roleOffset, setRoleOffset] = useState(0)
  const [roleOrder, setRoleOrder] = useState(false)
  const [ruleOrder, setRuleOrder] = useState([])
  const [pagination, setPagination] = useState({})
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedRows, setSelectedRows] = useState([])
  const [listRules, setListRules] = useState([])
  const [listRulesId, setListRulesId] = useState([])
  const [search, setSearch] = useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getRoles = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getRolesService(token, nameUrl, roleLimit, roleOffset, roleOrder)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setRuleOrder(response.data.result)
        setRole(response.data.result)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [roleOrder])

  const handleDelete = ruleToDelete => {
    console.log('Deleting rule:', ruleToDelete)
    setSelectedRows(selectedRows.filter(id => id !== ruleToDelete.roleId))
    setListRules(listRules.filter(rule => rule.roleId !== ruleToDelete.roleId))
    setListRulesId(listRulesId.filter(ruleIdObj => ruleIdObj.roleId !== ruleToDelete.roleId))
  }

  useEffect(() => {
    getRoles()
  }, [])
  useEffect(() => {
    requestSearch()
  }, [listRules, listRulesId])

  const requestSearch = () => {
    setRuleOrder(role.filter(item => item.name.toLowerCase().includes(search.toLowerCase())))
  }

  useEffect(() => {
    requestSearch()
    console.log(search)
  }, [search])

  const handleRowClick = row => {
    const ruleId = row.id
    const data = {
      roleId: row.id,
      name: row.name,
      active: true,
      description: row.description,
    }

    if (selectedRows.includes(ruleId)) {
      setSelectedRows(selectedRows.filter(id => id !== ruleId))
      setListRules(listRules.filter(rule => rule.id !== ruleId))
      setListRulesId(listRulesId.filter(ruleIdObj => ruleIdObj.roleId !== ruleId))
    } else {
      setSelectedRows([...selectedRows, ruleId])
      setListRules([...listRules, data])
      console.log(data, ' roww')
      setListRulesId([...listRulesId, { roleId: ruleId }])
    }
    requestSearch()
  }

  const getRolesCategoryEdit = async () => {
    try {
      const response = await getRolesCategoryIdService(token, nameUrl, roleCategoryId)
      const roleCategoryData = response.data

      const initialSelectedRows = roleCategoryData.permissions.map(permission => permission.roleId)
      setSelectedRows(initialSelectedRows)

      const initialListRules = roleCategoryData.permissions.map(permission => ({
        roleId: permission.roleId,
        name: permission.name,
        description: permission.description,
        active: true,
      }))
      setListRules(initialListRules)

      const initialListRulesId = roleCategoryData.permissions.map(permission => ({
        roleId: permission.roleId,
      }))

      setListRulesId(initialListRulesId)

      setRoleCategory(roleCategoryData)
      setName(roleCategoryData.name)
      setDescription(roleCategoryData.description)
    } catch (error) {}
  }

  useEffect(() => {
    if (roleCategoryId) {
      getRolesCategoryEdit()
    }
  }, [roleCategoryId])

  const updateRoles = async () => {
    setLoading(true)

    const roleCategory = {
      name: name,
      active: true,
      roleCategoryId: roleCategoryId,
      description: description,
      permissions: listRules.map(rule => ({
        roleId: rule.roleId,
        name: rule.name,
        description: rule.description,
        active: true,
      })),
    }

    console.log(listRules, 'listRuleslistRuleslistRules')

    try {
      const response = await editRoleCategoryService(token, nameUrl, roleCategory)
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSeverity('success')
      setTitle(response.data.message)
      getRoles()
      handleFetchData()
    } catch (err) {
      console.log(err)
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao criar regra, verifique os dados e tente novamente.')
    }
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            width: 1,
            mt: 2,
            display: 'flex',
            gap: 2,
            border: '1px solid black',
            borderRadius: 2,
            p: 2,
            flexDirection: 'column',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: 1 }}>
                <TextField
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  value={name}
                  type="text"
                  label="Nome da Categoria"
                  onChange={e => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: 1 }}>
                <TextField
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  value={description}
                  type="text"
                  label="Descrição da Categoria"
                  onChange={e => setDescription(e.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ width: 1 }}>
                <Box>
                  {listRules.map((rule, index) => (
                    <Chip
                      key={rule.roleId}
                      label={rule.name} // Certifique-se de utilizar a propriedade `name`
                      onDelete={() => handleDelete(rule)}
                      color="secondary"
                      variant="filled"
                      deleteIcon={
                        <IconButton size="small">
                          <DeleteIcon />
                        </IconButton>
                      }
                      sx={{ mr: 1 }}
                    />
                  ))}
                </Box>
                <Box
                  sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center', gap: 2 }}
                >
                  <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                    <Button variant="contained" sx={{ width: '100%' }} onClick={updateRoles}>
                      Salvar
                    </Button>
                  </Box>
                  <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={() => setExpanded(false)}
                      sx={{ width: '100%', ml: 1 }}
                    >
                      Cancelar
                    </Button>
                  </Box>
                  <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={() => {
                        setListRules([])
                        setSelectedRows([])
                      }}
                      sx={{ width: '100%', ml: 1 }}
                    >
                      Limpar lista
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <TextField
              variant="outlined"
              placeholder="Procurar regra"
              value={search}
              type="search"
              onInput={e => setSearch(e.target.value)}
            />
          </Box>
          <Box display="flex">
            <Typography>
              {ruleOrder.length > 1
                ? `${ruleOrder.length} Resultados`
                : `${ruleOrder.length} Resultado`}
            </Typography>
            {selectedRows.length > 0 && (
              <Typography ml={2}>
                {selectedRows.length > 1
                  ? `${selectedRows.length} Item Selecionado`
                  : `${selectedRows.length} Itens Selecionados`}
              </Typography>
            )}
          </Box>

          {role.length > 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left">Nome</StyledTableCell>
                    <StyledTableCell align="left">Descriçãp</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ruleOrder
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          align="left"
                          sx={{ width: 100 }}
                          onClick={() => handleRowClick(row)}
                        >
                          <Checkbox checked={selectedRows.includes(row.id)} />
                        </StyledTableCell>

                        <StyledTableCell align="left" sx={{ width: 300 }}>
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.description}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={ruleOrder.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default RulesCategoryEdit
