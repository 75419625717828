import { useCallback, useEffect, useState, useContext, useMemo } from 'react'
import {
  deleteProductAssociationService,
  getProductAssociationSeearchService,
  getProductAssociationService,
} from '../../../services/productService'
import { Context } from '../../../context/AuthContext'
//import TableData from "./tables";
import * as XLSX from 'xlsx'
import { RESULTS_PER_PAGE } from '../../../utils/excel'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Autocomplete,
  Skeleton,
  Grid,
  InputAdornment,
} from '@mui/material'
import { styled } from '@mui/system'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ProductAssociationCreate from '../../Create/ProductAssociationCreate'
import HelpIcon from '@mui/icons-material/Help'

//icones react-icons
import { AiFillFileExcel } from 'react-icons/ai'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import ProductAssociationEdit from '../../Edit/ProductAssociationEdit'
import PermissionComponent from '../../../permissions'
import { useDebounce } from '../Product/Components/useDebounce'
import { useSearchParams } from 'react-router-dom'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'
import { color } from 'highcharts'
import { Search } from '@mui/icons-material'
import { formatBRL } from '../../../utils/formatBRL'

const ProductAssociation = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [editing, setEditing] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const [idEditing, setIdEditing] = useState('')
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const token = localStorage.getItem('token')
  const [open, setOpen] = useState(false)
  const { debounce } = useDebounce(500, false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedId, setSelectedId] = useState('')
  const [search, setSearch] = useState('')
  const [productAssociation, setProductAssociation] = useState([])
  const [originalList, setOriginalList] = useState([])
  const [totalProducts, setTotalProducts] = useState(0)
  const [productAssociationLimit, setProductAssociationLimit] = useState(10)
  const [productAssociationOffset, setProductAssociationOffset] = useState(0)
  const [productAssociationOrder, setProductAssociationOrder] = useState(false)
  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [page, setPage] = useState(0)
  const [isLoadingExcel, setIsLoadingExcel] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  //variaveis crud
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [expanded, setExpanded] = useState(false)

  const [error, setError] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
      wordBreak: 'break-word',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    await getProductAssociationSeearchService(
      token,
      nameUrl,
      10000,
      0,
      productAssociationOrder,
      subCompanyId,
      ''
    ).then(r => {
      setTotalPagesDownload(r.data.totalpage + 1)
      finishDownload(r.data.totalpage + 1)
    })
  }

  const finishDownload = async pages => {
    let result = []
    const totalPages = pages

    for (let i = 0; i <= totalPages; i++) {
      setDownloadMessage(`Baixando...`)
      const progress = Math.min(((i + 1) / totalPages) * 100, 100)
      setProgress(progress)

      const response = await getProductAssociationSeearchService(
        token,
        nameUrl,
        10000,
        i,
        productAssociationOrder,
        subCompanyId,
        search
      )
      result = result.concat(response.data.result)
    }

    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)

    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        Serial: item.serial,
        Filial: item.subCompanyName,
        Filial: item.subCompanyName,
        Nome: item.productName,
        Preço: `R$ ${parseFloat(item.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}`,
        SKU: item.sku,
        'Identificação ERP': item.idProductPartner,
        Lote: item.lote,
        Laboratório: item.laboratory,
        Criado: new Date(item.created).toLocaleString(),
        Ativo: item.active ? 'Sim' : 'Não',
      })
    })

    // Cria o arquivo Excel
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Associação de Produtos')
    XLSX.writeFile(workbook, `Associação de Produtos.xlsx`)

    // Redefine o progresso para 0
    setProgress(0)
  }

  // const finishDownload = async pages => {
  //   setIsLoadingExcel(true)
  //   setDownloading(true)

  //   try {
  //     await getProductAssociationSeearchService(
  //       token,
  //       150000,
  //       pageNumber - 1,
  //       productAssociationOrder,
  //       subCompanyId,
  //       search
  //     ).then(response => {
  //       const data = response.data.result
  //       const relatorioFinal = []

  //       data.forEach(item => {
  //         relatorioFinal.push({
  //           Serial: item.serial,
  //           Filial: item.subCompanyName,
  //           Nome: item.productName,
  //           Preço: `R$ ${parseFloat(item.price).toLocaleString('pt-br', {
  //             minimumFractionDigits: 2,
  //           })}`,
  //           SKU: item.sku,
  //           'Identificação ERP': item.idProductPartner,
  //           Lote: item.lote,
  //           Laboratório: item.laboratory,
  //           Criado: new Date(item.created).toLocaleString(),
  //           Ativo: item.active ? 'Sim' : 'Não',
  //         })
  //       })

  //       const workbook = XLSX.utils.book_new()
  //       const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
  //       XLSX.utils.book_append_sheet(workbook, worksheet, 'Associação de Produtos')
  //       XLSX.writeFile(workbook, `Associação de Produtos.xlsx`)

  //       setDownloadMessage('Seu arquivo está pronto.')
  //       setDownloading(false)
  //     })
  //   } catch (error) {
  //     console.error('Erro ao processar a solicitação:', error)
  //     setIsLoadingExcel(false)
  //     setDownloading(false)
  //   }
  // }
  // const getProductAssociation = async () => {
  //   setError(false)

  //   try {
  //     const response = await getProductAssociationService(
  //       token,
  //       60000,
  //       productAssociationOffset,
  //       productAssociationOrder,
  //       subCompanyId
  //     )

  //     setProductAssociation(response.data.result)
  //     setOriginalList(response.data.result)
  //     setTotalProducts(response.data.total)
  //   } catch (err) {
  //     setError(true)
  //     console.log(err)
  //   }
  // }
  // const getProductAssociation = async () => {
  //   setError(false)

  //   try {
  //     const response = await getProductAssociationService(
  //       token,
  //       60000,
  //       productAssociationOffset,
  //       productAssociationOrder,
  //       subCompanyId
  //     )

  //     setProductAssociation(response.data.result)
  //     setOriginalList(response.data.result)
  //     setTotalProducts(response.data.total)
  //   } catch (err) {
  //     setError(true)
  //     console.log(err)
  //   }
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setSearchParams(prevParams => {
      prevParams.set('page', String(newPage + 1))
      return prevParams
    })
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
    setSearchParams(prevParams => {
      prevParams.set('page', '1')
      return prevParams
    })
  }

  const pageNumber = useMemo(() => {
    return Number(searchParams.get('page') || '1')
  }, [searchParams])

  const reloadTable = async id => {
    setLoading(true)
    try {
      const response = await getProductAssociationSeearchService(
        token,
        nameUrl,

        rowsPerPage,
        pageNumber - 1,
        productAssociationOrder,
        subCompanyId,
        search
      )
      const sortedResult = response.data.result.sort((a, b) => {
        // Converta as datas de criação para objetos Date para comparação
        const dateA = new Date(a.created)
        const dateB = new Date(b.created)

        // Ordene pelo mais recente ao mais antigo
        return dateB - dateA
      })

      setProductAssociation(sortedResult)
      setOriginalList(sortedResult)
      setTotalProducts(response.data.total)
      setLoading(false)
    } catch (err) {
      setError(true)
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      setLoading(true)

      debounce(() => {
        reloadTable()
      })
    }
  }, [search, pageNumber, subCompanyId])

  const autoCompleteSelectedOptions = useMemo(() => {
    if (!selectedId) return undefined

    const selectedOptions = productAssociation.find(item => item.productItensId === selectedId)

    return selectedOptions
  }, [selectedId, productAssociation])

  const handleReload = async () => {
    setLoading(true)
    try {
      const response = await getProductAssociationService(
        token,
        nameUrl,
        productAssociationLimit,
        productAssociationOffset,
        productAssociationOrder,
        subCompanyId
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setProductAssociation(response.data.result)
        setOriginalList(response.data.result)
        setTotalProducts(response.data.total)
      }
      setLoading(false)
    } catch (err) {
      setError(true)
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    //getProductAssociation()
  }, [productAssociationOrder, subCompanyId])

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress color="secondary" variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  }

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteProductAssociationService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Associação deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      reloadTable()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Box width={{ xs: '90%', sm: '90%', md: '50%', lg: '50%', xl: '50%' }}>
          <Dialog open={downloading} aria-describedby="alert-dialog-description">
            <Box
              sx={{
                height: '10rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyConent: 'center',
              }}
              width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
            >
              <DialogTitle>{downloadMessage}</DialogTitle>
              <LinearProgressWithLabel value={progress} sx={{ width: '100%' }} />
            </Box>
          </Dialog>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <ViewElearning
          pageName={'ProductAssociation'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Associação de Produtos</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        {/* Criar Associação */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            disabled={isLoadingExcel}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing ? 'Editar Associação de Produtos' : 'Cadastrar Associação de Produtos'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="Edit_Association">
                <ProductAssociationEdit
                  setEditing={setEditing}
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  productItensId={idEditing}
                  handleFetchData={reloadTable}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="Create_Association">
                <ProductAssociationCreate
                  expanded={expanded}
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  //handleFetchData={getProductAssociation}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <SelectSubCompany
            value={subCompanyId}
            onChange={e => {
              setSubCompanyId(e.target.value)
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={6}>
              <TextField
                sx={{ width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: isLoading && (
                    <InputAdornment position="end">
                      <CircularProgress color="info" size={20} />
                    </InputAdornment>
                  ),
                }}
                label="Pesquisar"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Box width="100%" height="55px">
                {productAssociation.length > 0 && (
                  <Button
                    sx={{ width: '100%' }}
                    onClick={downloadData}
                    variant="contained"
                    size="medium"
                    disabled={isLoadingExcel}
                    startIcon={isLoadingExcel && <CircularProgress size={20} />}
                    endIcon={!isLoadingExcel && <AiFillFileExcel />}
                  >
                    {isLoadingExcel ? 'Realizando download...' : 'Excel'}
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <PermissionComponent role="Create_Association">
                <Button
                  onClick={() => setExpanded(true)}
                  variant="contained"
                  endIcon={<AiFillPlusCircle />}
                  sx={{ width: '100%' }}
                  disabled={isLoadingExcel}
                >
                  Criar
                </Button>
              </PermissionComponent>
            </Grid>
          </Grid>
        </Box>

        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <PermissionComponent role="Edit_Association">
                  <StyledTableCell>Editar</StyledTableCell>
                </PermissionComponent>
                <PermissionComponent role="Delete_Association">
                  <StyledTableCell>Apagar</StyledTableCell>
                </PermissionComponent>
                <PermissionComponent role="FIELD_ID_ERP">
                  <StyledTableCell align="left">Identificação ERP</StyledTableCell>
                </PermissionComponent>
                <StyledTableCell align="left">Filial</StyledTableCell>
                <StyledTableCell align="center">Quantidade</StyledTableCell>
                <StyledTableCell align="left">Serial</StyledTableCell>
                <StyledTableCell align="left">Nome do produto</StyledTableCell>
                <PermissionComponent role="FIELD_ALOCATION">
                  <StyledTableCell align="left">Alocação</StyledTableCell>
                </PermissionComponent>
                <PermissionComponent role="FIELD_PIECE">
                  <StyledTableCell align="left">Peça</StyledTableCell>
                </PermissionComponent>
                <PermissionComponent role="FIELD_PRICE">
                  <StyledTableCell align="left">Preço</StyledTableCell>
                </PermissionComponent>
                <PermissionComponent role="FIELD_LOTE">
                  <StyledTableCell align="left">Lote</StyledTableCell>
                </PermissionComponent>
                <PermissionComponent role="FIELD_TYPE_PIECE">
                  <StyledTableCell align="left">Tipo Peça</StyledTableCell>
                </PermissionComponent>
                <PermissionComponent role="FIELD_TIME_EXPIRATION">
                  <StyledTableCell align="left">Tempo digitado</StyledTableCell>
                </PermissionComponent>
                <PermissionComponent role="FIELD_LABORATORY">
                  <StyledTableCell align="left">Laboratório</StyledTableCell>
                </PermissionComponent>
                <PermissionComponent role="FIELD_QUANTITY_TIME">
                  <StyledTableCell align="left">Quantidade Tempo</StyledTableCell>
                </PermissionComponent>
                <StyledTableCell align="left">Ativo</StyledTableCell>
                <PermissionComponent role="FIELD_DATAEXPIRATION">
                  <StyledTableCell align="left">Data de validade</StyledTableCell>
                </PermissionComponent>
                <StyledTableCell align="left">Data</StyledTableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              Array.from({ length: 10 }).map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Array.from({ length: 17 }).map((_, colIndex) => (
                    <TableCell key={colIndex}>
                      <Skeleton animation="wave" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableBody>
                {productAssociation.map((row, index) => (
                  <StyledTableRow key={index}>
                    <PermissionComponent role="Edit_Association">
                      <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                        <Tooltip title="Editar" arrow>
                          <IconButton onClick={() => handleEdit(row.productItensId)}>
                            <ModeEditOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="Delete_Association">
                      <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                        <Tooltip title="Apagar" arrow>
                          <IconButton onClick={() => deleteAlert(row.productItensId)}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </PermissionComponent>

                    <PermissionComponent role="FIELD_ID_ERP">
                      <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                    </PermissionComponent>
                    <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                    <StyledTableCell align="center">{row.quantitys}</StyledTableCell>
                    <StyledTableCell align="left">{row.serial}</StyledTableCell>
                    <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    <PermissionComponent role="FIELD_ALOCATION">
                      <StyledTableCell align="left">{row.alocationStockName}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_PIECE">
                      <StyledTableCell align="left">{row.piece}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_PRICE">
                      <StyledTableCell align="left">{formatBRL(row.price)}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_LOTE">
                      <StyledTableCell align="left">{row.lote}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_TYPE_PIECE">
                      <StyledTableCell align="left">
                        {row.typePiece === 0 ? 'Peça' : row.typePiece === 1 ?? 'Box'}
                      </StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_TIME_EXPIRATION">
                      <StyledTableCell align="left">{row.typeTime}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_LABORATORY">
                      <StyledTableCell align="left">{row.laboratory}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_QUANTITY_TIME">
                      <StyledTableCell align="left">{row.quantityTime}</StyledTableCell>
                    </PermissionComponent>
                    <StyledTableCell align="left">{row.active}</StyledTableCell>
                    <PermissionComponent role="FIELD_DATAEXPIRATION">
                      <StyledTableCell align="left">
                        {new Date(row.dataExpiration).toLocaleString()}
                      </StyledTableCell>
                    </PermissionComponent>
                    <StyledTableCell align="left">
                      {new Date(row.created).toLocaleString()}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalProducts}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        {/* <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop> */}
      </Box>
    </>
  )
}

export default ProductAssociation
