import { useContext, useEffect, useState } from 'react'
import { editLaundry, getLaundryId } from '../../../services/laundryServices'
import { Alert, Backdrop, Box, Button, CircularProgress, Snackbar, TextField } from '@mui/material'
import { Context } from '../../../context/AuthContext'

const LaundryEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  laundryId,
  handleFetchData,
  setExpanded,
}) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [name, setName] = useState('')
  const [zpl, setZpl] = useState('')
  const [image, setImage] = useState('')
  const [created, setCreated] = useState('')

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editForm, setEditForm] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const handleEditLaundry = async e => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    const data = {
      printerLaundryId: laundryId,
      name: name,
      zpl: zpl,
      image: image,
      active: true,
      isDelete: true,
      created: created,
    }

    try {
      await editLaundry(token, nameUrl, data)
      setSeverity('success')
      setTitle('Lavanderia editada com sucesso!')
      setLoading(false)
      setEditing(false)
      setAlerta(true)
      handleFetchData()
    } catch (err) {
      setSeverity('error')
      setTitle('Erro ao editar, verifique os dados e tente novamente')
      setLoading(false)
      setAlerta(true)
    }
  }

  const getLaundry = async () => {
    setLoading(true)
    setEditForm(false)

    try {
      const response = await getLaundryId(token, nameUrl, laundryId)
      setEditForm(true)
      setName(response.data.name)
      setZpl(response.data.zpl)
      setImage(response.data.image)
      setCreated(response.data.created)
      setLoading(false)
    } catch (err) {
      setEditForm(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    getLaundry()
  }, [laundryId])

  const handleClose = () => {
    setError(false)
  }

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Houve um erro ao editar a Lavanderia!!
          </Alert>
        </Snackbar>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleEditLaundry}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={name}
                onChange={values => setName(values.target.value)}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={image}
                onChange={values => setImage(values.target.value)}
                id="outlined-basic"
                label="Imagem"
                variant="outlined"
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ width: '100%', mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={zpl}
                onChange={values => setZpl(values.target.value)}
                id="outlined-basic"
                label="ZPL"
                variant="outlined"
                type="text"
                multiline
                rows={10}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" type="submit">
              Salvar
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={() => {
                setEditing(false)
                setExpanded(false)
              }}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default LaundryEdit
