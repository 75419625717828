import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Select, InputLabel, MenuItem } from '@mui/material'

const GraficlastOrder = ({ lastOrder }) => {
  const [data, setData] = useState([])
  const [isShowTop10, setIsShowTop10] = useState(false)

  useEffect(() => {
    handleFilter()
  }, [lastOrder])

  const handleFilter = () => {
    const uniqueItems = Array.from(new Set(lastOrder.map(item => item.name))).map(name =>
      lastOrder.find(item => item.name === name)
    )
    setData(uniqueItems)
  }

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: 0,

      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: isShowTop10
        ? lastOrder.slice(0, 10).map(item => item.name)
        : data.map(item => item.name),
    },
    yaxis: {},
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,

      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  const series = [
    {
      name: 'Valor',
      data: data.map(item => item.price),
    },
  ]

  return (
    <div>
      <div id="grafico">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  )
}

export default GraficlastOrder
