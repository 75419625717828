import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../context/AuthContext'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  Paper,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { colorTableDefault } from '../../TemplateDefault'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  getAllPickingListERPService,
  getOrderService,
  pickingListFinish,
  pickingListNoSuccessService,
  pickingListSuccessService,
  sendConfirmConference,
} from '../../../services/movOrderItemERP'
import ViewElearning from '../../ViewElearning'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'

const PickingListEdit = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const params = useParams()
  const movimentationOrderId = params.id
  const [open, setOpen] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pickingEdit, setPickingEdit] = useState([])
  const [orderData, setOrderData] = useState([])
  const [productName, setProductName] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')

  const navigate = useNavigate()

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const getMovOrder = async () => {
    setLoading(true)
    const addData = []
    if (!movimentationOrderId || movimentationOrderId === '') {
      setLoading(false)
      return
    }
    try {
      const response = await getAllPickingListERPService(token, nameUrl, movimentationOrderId)
      response.data.forEach(element => {
        if (element.isConference === false) {
          addData.unshift(element)
        } else {
          addData.push(element)
        }
      })
      setPickingEdit(addData)
      setProductName(response.data.movimentationERPitemsCollections[0].productName)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getOrder = async () => {
    setLoading(true)
    try {
      const response = await getOrderService(token, nameUrl, movimentationOrderId)
      setMovimentationOrderNumberOtherSystem(response.data.movimentationOrderNumberOtherSystem)
      setOrderData(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMovOrder()
    getOrder()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleSuccess = async id => {
    setLoading(true)
    try {
      pickingListSuccessService(token, nameUrl, id)
      setTitle('Adicionado com sucesso!')
      setSeverity('success')
      setAlerta(true)
      setTimeout(() => {
        getMovOrder()
      }, 1000)
    } catch (e) {
      console.log(e)
      setTitle('Erro no sistema, tente novamente')
      setSeverity('error')
      setAlerta(true)
      setLoading(false)
    }
  }

  const handleNoSuccess = async id => {
    setLoading(true)
    try {
      pickingListNoSuccessService(token, nameUrl, id)
      setTitle('Retirado com sucesso!')
      setSeverity('success')
      setAlerta(true)
      setTimeout(() => {
        getMovOrder()
      }, 1000)
    } catch (e) {
      console.log(e)
      setTitle('Erro no sistema, tente novamente')
      setSeverity('error')
      setAlerta(true)
      setLoading(false)
    }
  }

  const handleFinish = async () => {
    setLoading(true)
    try {
      const verific = pickingEdit.find(item => item.isConference === false)
      if (verific) {
        setOpen(true)
        setLoading(false)
      } else {
        handleConfirmFinish()
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleConfirmFinish = async () => {
    try {
      await pickingListFinish(token, nameUrl, movimentationOrderId)
      setTitle('Lista atualizada com sucesso!')
      setSeverity('success')
      setAlerta(true)
      setLoading(false)
      getMovOrder()
    } catch (e) {
      console.log(e)
      setTitle('Erro no sistema, tente novamente.')
      setSeverity('error')
      setAlerta(true)
      setLoading(false)
    }
  }

  const handleAction = async () => {
    const data = {
      movimentationOrderNumberOtherSystem: movimentationOrderNumberOtherSystem,
      subCompanyCNPJ: subCompany[0].cnpj,
    }
    console.log(data, 'subCompany')

    try {
      await sendConfirmConference(token, nameUrl, data)
      setTitle('Lista atualizada com sucesso!')
      setSeverity('success')
      setAlerta(true)
      setLoading(false)
      getMovOrder()
    } catch (e) {
      console.log(e)
      setTitle('Erro no sistema, tente novamente.')
      setSeverity('error')
      setAlerta(true)
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setAlerta(false)
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column' }}
        marginTop={{ xs: 10, sm: 10, md: 7.5, lg: 7.5, xl: 7.5 }}
      >
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Lista de Separação</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Existem itens não conferidos, deseja realmente finalizar?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleConfirmFinish()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <ViewElearning
          pageName={'PickingListEdit'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: '100%', mt: 2, gap: 2 }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              gap: 2,
            }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={
                  orderData.movimentationOrderNumberBihands
                    ? orderData.movimentationOrderNumberBihands
                    : ''
                }
                id="outlined-basic"
                variant="outlined"
                label="Número Ordem Bihands"
                type="text"
                disabled
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={
                  orderData.movimentationOrderNumberOtherSystem
                    ? orderData.movimentationOrderNumberOtherSystem
                    : ''
                }
                id="outlined-basic"
                variant="outlined"
                label="Número Ordem (outro sistema)"
                type="text"
                disabled
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              gap: 2,
            }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={
                  orderData.receiveMovimentationOrderDate
                    ? new Date(orderData.receiveMovimentationOrderDate).toLocaleString()
                    : ''
                }
                id="outlined-basic"
                variant="outlined"
                label="Data Recebimento"
                type="text"
                disabled
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={
                  orderData.createRequestMovimentationOrderDate
                    ? new Date(orderData.createRequestMovimentationOrderDate).toLocaleString()
                    : ''
                }
                id="outlined-basic"
                variant="outlined"
                label="Data Solicitação"
                type="text"
                disabled
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              gap: 2,
            }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={orderData.typeOrderName ? orderData.typeOrderName : ''}
                id="outlined-basic"
                variant="outlined"
                label="Tipo de Pedido"
                type="text"
                disabled
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={orderData.subCompanyNameFrom ? orderData.subCompanyNameFrom : ''}
                id="outlined-basic"
                variant="outlined"
                label="Filial"
                type="text"
                disabled
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              gap: 2,
            }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                value={orderData.isReceived ? 'Recebido' : 'Não Recebido'}
                id="outlined-basic"
                variant="outlined"
                label="Status"
                type="text"
                disabled
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 2,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography>Listagem de Separação</Typography>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Ação</StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                  <StyledTableCell align="left">Quantidade</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pickingEdit &&
                  pickingEdit
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-evenly',
                              width: '100%',
                            }}
                          >
                            <CheckBoxIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleSuccess(row.orderMovimentationERPItemId)}
                            />
                            <CancelIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleNoSuccess(row.orderMovimentationERPItemId)}
                            />
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.productName}</StyledTableCell>
                        <StyledTableCell align="left">
                          {row.quantityFraction + ' ' + row.typePiece + ' de ' + row.productBy}
                          {/* row.quantity */}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.isConference === false ? (
                            <ThumbDownAltIcon color="error" />
                          ) : (
                            <ThumbUpAltIcon color="success" />
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={pickingEdit.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
        <Box
          sx={{
            mt: 2,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography>Lista de produtos separados</Typography>
          {orderData.length > 0 && (
            <Button variant="contained" onClick={handleAction}>
              Ação
            </Button>
          )}

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                  <StyledTableCell align="left">Serial</StyledTableCell>
                  {/* <StyledTableCell align="left">Status</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {orderData.movimentationOrderItemCollection &&
                  orderData.movimentationOrderItemCollection
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row.productName}</StyledTableCell>
                        <StyledTableCell align="left">{row.serial}</StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={
                orderData.movimentationOrderItemCollection &&
                orderData.movimentationOrderItemCollection.length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
        <Box
          sx={{ justifyContent: 'space-evenly' }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box
            width={{ xs: '100%', sm: '100%', md: '40%', lg: '40%', xl: '40%' }}
            sx={{ mt: '2rem', alignItems: 'center' }}
          >
            <Button
              variant="contained"
              sx={{ width: '100%' }}
              type="button"
              onClick={() => handleFinish()}
            >
              Finalizar
            </Button>
          </Box>
          <Box
            width={{ xs: '100%', sm: '100%', md: '40%', lg: '40%', xl: '40%' }}
            sx={{ mt: '2rem', alignItems: 'center' }}
          >
            <Button
              variant="contained"
              sx={{ width: '100%' }}
              type="button"
              onClick={() => navigate('/PickingList')}
            >
              Voltar
            </Button>
          </Box>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PickingListEdit
