import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Box,
  Backdrop,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { useContext, useEffect, useState } from 'react'
import {
  Cancel,
  DeleteForever,
  ExpandMore,
  Message,
  ModeEditOutline,
  Person,
  Visibility,
} from '@mui/icons-material'
import ApexChart from './Components/ColumnChartBasic '
import ColumnChartBasic from './Components/ColumnChartBasic '
import { Context } from '../../../context/AuthContext'
import PermissionComponent from '../../../permissions'
import { TicketEdit } from '../../Edit/TicketEdit'
import { TicketCreate } from '../../Create/TicketCreate'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import {
  getAttendantsAll,
  getListTicketsAssigned,
  getTicketsAll,
} from '../../../services/helpdeskService'
import { deepOrange } from '@mui/material/colors'
import { AttendantsCreate } from '../../Create/AttendantsCreate'
import { useNavigate } from 'react-router-dom'
import { formatDistance, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const Attendants = () => {
  const token = localStorage.getItem('token')
  const { subCompany, userId, nameUrl } = useContext(Context)
  const navigate = useNavigate()
  const [viewElearning, setViewElearning] = useState(false)
  const [attendants, setAttendants] = useState([])
  const [filter, setFilter] = useState([])

  const [page, setPage] = useState(0)
  const [totalProgress, setTotalProgress] = useState(0)
  const [totalDelay, setTotalDelay] = useState(0)
  const [totalFinish, setTotalFinish] = useState(0)
  const [totalFila, setTotalFila] = useState(0)
  const [totalCanceled, setTotalCanceled] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [title, setTitle] = useState('')
  const [tickets, setTikets] = useState([])
  const [severity, setSeverity] = useState('warning')
  const [register, setRegister] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [name, setName] = useState('')
  const [ticketId, setTicketId] = useState('')
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [searchR, setSearchR] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [loading, setLoading] = useState(false)
  const [loadingTotal, setLoadingTotal] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
    //getAllProducts(companyId)
  }
  const calculateTimeElapsed = creationDate => {
    const currentDate = new Date()
    const differenceInMilliseconds = currentDate - new Date(creationDate)
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60)
    return differenceInHours
  }

  const calculateTimeDifference = lastUpdateDate => {
    const currentDate = new Date()
    const updateDate = parseISO(lastUpdateDate)

    const timeDifference = formatDistance(updateDate, currentDate, {
      addSuffix: true,
      locale: ptBR,
    })

    return timeDifference
  }

  const getStatusChip = (status, timeElapsed, priority) => {
    if (!isTicketOverdue(priority, timeElapsed)) {
      if (status === 0) {
        return (
          <Chip
            label="Aguardando atendimento"
            style={{
              background: '#E0E7FF',
              color: '#7A76DD',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 1) {
        return (
          <Chip
            label="Em progresso"
            style={{
              background: '#FEF08A',
              color: '#A26308',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === -999) {
        return (
          <Chip
            label="Cancelado"
            style={{
              background: '#FEE2E2',
              color: '#B91C1C',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 3) {
        return (
          <Chip
            label="Finalizado"
            style={{
              background: '#F0FDF4',
              color: '#3EB469',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 2) {
        return (
          <Chip
            label="Transferência de atendentes"
            style={{
              background: '#F0FDF4',
              color: '#3EB469',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 5) {
        return (
          <Chip
            label="Aguardando retorno do cliente"
            style={{
              background: '#FEF08A',
              color: '#A26308',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 4) {
        return (
          <Chip
            label="Aguardando retorno do suporte"
            style={{
              background: '#FEF08A',
              color: '#A26308',
              borderRadius: '5px',
            }}
          />
        )
      }
    } else {
      if (status === -999 && isTicketOverdue(priority, timeElapsed)) {
        return (
          <Chip
            label="Cancelado"
            style={{
              background: '#FEE2E2',
              color: '#B91C1C',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 3 && isTicketOverdue(priority, timeElapsed)) {
        return (
          <Chip
            label="Finalizado"
            style={{
              background: '#F0FDF4',
              color: '#3EB469',
              borderRadius: '5px',
            }}
          />
        )
      } else {
        return (
          <Chip
            label="Em atraso"
            style={{
              background: '#FEE2E2',
              color: '#B91C1C',
              borderRadius: '5px',
            }}
          />
        )
      }
    }

    return null
  }

  const isTicketOverdue = (priority, timeElapsed) => {
    return (
      (priority === 1 && timeElapsed > 6) ||
      (priority === 2 && timeElapsed > 12) ||
      (priority === 3 && timeElapsed > 24)
    )
  }

  const handleGetListAttendants = async () => {
    try {
      const response = await getAttendantsAll(token)
      const uniqueAttendants = response.data.reduce((unique, attendant) => {
        const isDuplicate = unique.some(item => item.userId === attendant.userId)

        if (!isDuplicate) {
          unique.push(attendant)
        }

        return unique
      }, [])

      setAttendants(uniqueAttendants)

      await handleListTicketsAssigned(userId)
    } catch (error) {}
  }

  const handleListTicketsAssigned = async userId => {
    setLoading(true)
    setLoadingTotal(true)
    try {
      const listFIla = await getTicketsAll(token, 500, 0, 'desc', '')

      const response = await getListTicketsAssigned(token, userId)
      const data = response.data.result
      const dataListFIla = listFIla.data.result
      const canceled = data.filter(item => item.status === -999)
      const progress = data.filter(
        item => item.status === 1 || item.status === 5 || item.status === 4
      )
      const fila = dataListFIla.filter(item => item.status === 0)
      console.log(fila, 'fidlssa')
      const finish = data.filter(item => item.status === 3)

      const calculateTimeElapsedLists = (creationDate, priority) => {
        const currentDate = new Date()
        const differenceInMilliseconds = currentDate - new Date(creationDate)
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60)

        const overdue =
          (priority === 1 && differenceInHours > 6) ||
          (priority === 2 && differenceInHours > 12) ||
          (priority === 3 && differenceInHours > 24)

        return { timeElapsed: differenceInHours, overdue }
      }

      const overdueTickets = response.data.result.filter(item => {
        const { overdue } = calculateTimeElapsedLists(item.createDate, item.priority)
        return overdue && item.status !== -999 && item.status !== 3
      })

      setTotalCanceled(canceled.length)
      setTotalFila(fila.length)
      setTotalFinish(finish.length)
      setTotalProgress(progress.length)
      setTotalDelay(overdueTickets.length)
      setTikets(data)
      setLoading(false)
      setLoadingTotal(false)
    } catch (error) {
      setLoading(false)
      setLoadingTotal(false)
    }
  }

  useEffect(() => {
    handleGetListAttendants()
  }, [])

  const filterRow = () => {
    let status = 0

    if (search === 'Cancelado') {
      status = -999
    } else if (search === 'Aguardando atendimento') {
      status = 0
    } else if (search === 'Em progresso') {
      status = 1
    }

    const filtered = tickets.filter(row => {
      const ticketMatch = row.ticket && row.ticket.toLowerCase().includes(search.toLowerCase())
      const createUserNameMatch =
        row.createUserName && row.createUserName.toLowerCase().includes(search.toLowerCase())
      const assistentUserNameMatch =
        row.assistentUserName && row.assistentUserName.toLowerCase().includes(search.toLowerCase())
      const statusMatch =
        row.status !== undefined &&
        row.status.toString().toLowerCase().includes(search.toLowerCase())

      // Verifique se pelo menos uma das condições é verdadeira
      return ticketMatch || createUserNameMatch || assistentUserNameMatch || statusMatch
    })

    setFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [search, tickets])

  return (
    <Box sx={{ mt: 7.5 }}>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5" sx={{ mb: 5 }}>
          Atendentes
        </Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
          <HelpIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <FormControl sx={{ maxWidth: '700px', width: '100%', mb: 4 }}>
            <InputLabel id="demo-simple-select-label">Selecione o atendente</InputLabel>
            <Select
              value={userId}
              sx={{ width: '100%' }}
              label="Escolher o atendente"
              onChange={e => {}}
            >
              {attendants.map((item, index) => {
                return (
                  <MenuItem value={item.userId} key={index}>
                    {item.userName}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              <Box display="flex" alignItems="center">
                <Box
                  bgcolor="#F9E608"
                  width="60px"
                  height="60px"
                  borderRadius="100%"
                  display="flex"
                  alignItems="center"
                  p={2}
                  color="#fff"
                  mr={2}
                >
                  <Person />
                </Box>
                <Box>
                  <Typography variant="body1" color="#B3BDC1">
                    Tickets
                  </Typography>
                  <Typography variant="body1" color="#B3BDC1">
                    Abertos
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    {loadingTotal ? <CircularProgress size={20} color="inherit" /> : totalFila}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              <Box display="flex" alignItems="center">
                <Box
                  bgcolor="#0999FF"
                  width="60px"
                  height="60px"
                  borderRadius="100%"
                  display="flex"
                  alignItems="center"
                  p={2}
                  color="#fff"
                  mr={2}
                >
                  <Person />
                </Box>
                <Box>
                  <Typography variant="body1" color="#B3BDC1">
                    Tickets em
                  </Typography>
                  <Typography variant="body1" color="#B3BDC1">
                    progresso
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    {loadingTotal ? <CircularProgress size={20} color="inherit" /> : totalProgress}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              <Box display="flex" alignItems="center">
                <Box
                  bgcolor="#FE4F58"
                  width="60px"
                  height="60px"
                  borderRadius="100%"
                  display="flex"
                  alignItems="center"
                  p={2}
                  color="#fff"
                  mr={2}
                >
                  <Message />
                </Box>
                <Box width="100%">
                  <Typography variant="body1" color="#B3BDC1">
                    Tickets em
                  </Typography>
                  <Typography variant="body1" color="#B3BDC1">
                    atraso
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    {loadingTotal ? <CircularProgress size={20} color="inherit" /> : totalDelay}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              <Box display="flex" alignItems="center">
                <Box
                  bgcolor="#0DD8E1"
                  width="60px"
                  height="60px"
                  borderRadius="100%"
                  display="flex"
                  alignItems="center"
                  p={2}
                  color="#fff"
                  mr={2}
                >
                  <Person />
                </Box>
                <Box>
                  <Typography variant="body1" color="#B3BDC1">
                    Tickets
                  </Typography>
                  <Typography variant="body1" color="#B3BDC1">
                    Finalizados
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    {loadingTotal ? <CircularProgress size={20} color="inherit" /> : totalFinish}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              <Box display="flex" alignItems="center">
                <Box
                  bgcolor="red"
                  width="60px"
                  height="60px"
                  borderRadius="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={2}
                  color="#fff"
                  mr={2}
                >
                  <Cancel />
                </Box>
                <Box>
                  <Typography variant="body1" color="#B3BDC1">
                    Tickets
                  </Typography>
                  <Typography variant="body1" color="#B3BDC1">
                    Cancelados
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    {loadingTotal ? <CircularProgress size={20} color="inherit" /> : totalCanceled}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              <Box display="flex" alignItems="center">
                <Box
                  bgcolor="green"
                  width="60px"
                  height="60px"
                  borderRadius="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={2}
                  color="#fff"
                  mr={2}
                >
                  <Person />
                </Box>
                <Box>
                  <Typography variant="body1" color="#B3BDC1">
                    Total de
                  </Typography>
                  <Typography variant="body1" color="#B3BDC1">
                    tickets
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    {loadingTotal ? <CircularProgress size={20} color="inherit" /> : tickets.length}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ maxWidth: '700px', width: '100%', my: 4 }}>
            <TextField
              sx={{ width: '100%' }}
              value={search}
              id="outlined-basic"
              variant="outlined"
              type="text"
              label="Pesquisar"
              disabled={searching}
              onChange={e => setSearch(e.target.value)}
            />
            <Typography sx={{ width: 1, margin: '10px 0' }}>{searchR}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Atendente</StyledTableCell>
                  <StyledTableCell align="left">Ticket</StyledTableCell>
                  <StyledTableCell align="left">Assunto</StyledTableCell>
                  <StyledTableCell align="left">Filial</StyledTableCell>
                  <StyledTableCell align="left">Data de Abertura</StyledTableCell>
                  <StyledTableCell align="left">Ultima atualização</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Visualizar</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={row.attendantsId}>
                      <StyledTableCell align="left">{row.assistentUserName}</StyledTableCell>
                      <StyledTableCell align="left">{row.ticket}</StyledTableCell>
                      <StyledTableCell align="left">{row.subject}</StyledTableCell>
                      <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.createDate).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {calculateTimeDifference(row.lastUpdateDate)}
                      </StyledTableCell>{' '}
                      <StyledTableCell align="center">
                        {getStatusChip(
                          row.status,
                          calculateTimeElapsed(row.createDate),
                          row.priority
                        )}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" align="center">
                        <PermissionComponent role="">
                          <Tooltip title="Visualizar" arrow>
                            <IconButton
                              onClick={() =>
                                navigate(`/TicketViewResponse/${row.movimentationOrderTicketId}`)
                              }
                              sx={{ cursor: 'pointer' }}
                            >
                              <Visibility />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              labelRowsPerPage={'itens por página'}
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={tickets.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}
