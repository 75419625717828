import { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { deleteCompanyService, getCompanyService } from '../../../services/admServices'
import { Context } from '../../../context/AuthContext'
import { COMPANYCreate, CompanyEDIT, CompanyDELETE } from '../../../const/RoleConstants'
import HelpIcon from '@mui/icons-material/Help'
import { setPermission } from '../../../permissions/userPermissions'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material'
import CompanyCreate from '../../Create/CompanyCreate'
import CompanyEdit from '../../Edit/CompanyEdit'
import { AiFillPlusCircle } from 'react-icons/ai'

//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import PermissionComponent from '../../../permissions'

const Company = () => {
  const token = localStorage.getItem('token')
  const [company, setCompanny] = useState([])
  const [pagination, setPagination] = useState({})
  const [companyLimit, setCompanyLimit] = useState(500)
  const [companyOffset, setCompanyOffset] = useState(0)
  const [companyOrder, setCompanyOrder] = useState(false)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')

  const { userRoles, nameUrl } = useContext(Context)

  const [permissionCompanyCREATE, setPermissionCompanyCREATE] = useState(false)
  const [permissionCompanyEDIT, setPermissionCompanyEDIT] = useState(false)
  const [permissionCompanyDELETE, setPermissionCompanyDELETE] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [filterName, setFilterName] = useState('')
  const [filterCNPJ, setFilterCNPJ] = useState('')
  const [backupData, setBackupData] = useState([])
  const [notSearch, setNotSearch] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const columns = [
    {
      headerName: '',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          {permissionCompanyDELETE ? (
            <DeleteForeverIcon
              onClick={() => deleteAlert(params.row.companyId)}
              sx={{ cursor: 'pointer' }}
            />
          ) : (
            ''
          )}
          {permissionCompanyEDIT ? (
            <ModeEditOutlineIcon
              onClick={() => handleEdit(params.row.companyId)}
              sx={{ cursor: 'pointer' }}
            />
          ) : (
            ''
          )}
        </Box>
      ),
    },
    {
      field: 'name',
      headerName: 'Nome',
      flex: 3,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'segment',
      headerName: 'Segmento',
      flex: 2,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      flex: 2,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'active',
      headerName: 'Ativo',
      flex: 2,
      valueGetter: params => (params.row.active ? 'Sim' : 'Não'),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'created',
      headerName: 'Criado',
      flex: 2,
      valueGetter: params => new Date(params.row.created).toLocaleString(),
      headerClassName: 'super-app-theme--header',
    },
  ]

  const getPermissions = useCallback(async () => {
    setPermissionCompanyCREATE(setPermission(userRoles, COMPANYCreate))
    setPermissionCompanyEDIT(setPermission(userRoles, CompanyEDIT))
    setPermissionCompanyDELETE(setPermission(userRoles, CompanyDELETE))
  }, [])

  const getCompany = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getCompanyService(
        token,
        nameUrl,
        companyLimit,
        companyOffset,
        companyOrder
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setCompanny(response.data.result)
        setBackupData(response.data.result)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [companyOrder])

  const getCompanyPage = useCallback(async (offset, order) => {
    try {
      const response = await getCompanyService(token, nameUrl, companyLimit, offset, order)
      setCompanny(response.data.result)
      setPagination(response.data)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const pageButtons = () => {
    let totalPages = Math.floor(pagination.total / pagination.limit)

    if (pagination.total % pagination.limit != 0) {
      totalPages = totalPages + 1
    }

    return totalPages
  }

  useEffect(() => {
    getPermissions()
    getCompany()
  }, [companyOrder])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteCompanyService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Empresa deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      getCompany()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    const handleFilter = () => {
      if (filterName !== '') {
        setNotSearch(false)
        const newData = backupData
        const filter = newData.filter(data =>
          data.name.toLowerCase().includes(filterName.toLowerCase())
        )
        if (filter.length > 0) {
          setCompanny(filter)
        } else {
          setNotSearch(true)
        }
      } else setCompanny(backupData)
    }
    handleFilter()
  }, [filterName])

  useEffect(() => {
    const handleFilter = () => {
      if (filterCNPJ !== '') {
        setNotSearch(false)
        const newData = backupData
        console.log(newData)
        const filter = newData.filter(data => data.cnpj.toString().includes(filterCNPJ))
        if (filter.length > 0) {
          setCompanny(filter)
        } else {
          setNotSearch(true)
        }
      } else setCompanny(backupData)
    }
    handleFilter()
  }, [filterCNPJ])

  const handleClearFilter = () => {
    setFilterName('')
    setFilterCNPJ('')
    setCompanny(backupData)
  }

  return (
    <>
      <Box sx={{ mt: 7.5, height: 'fit-content' }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Empresas</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>
        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editando Empresa' : 'Cadastrar Empresa'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="CompanyEDIT">
                <CompanyEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  companyId={idEditing}
                  handleFetchData={getCompany}
                  setExpanded={setExpanded}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="CompanyCreate">
                <CompanyCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={getCompany}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              ml: 1,
            }}
          >
            <PermissionComponent role="CompanyCREATE">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        {company.length > 0 && (
          <>
            <Box
              sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box
                width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  variant="outlined"
                  label={!notSearch ? 'Filtrar Nome' : 'Empresa não encontrada'}
                  value={filterName}
                  onChange={values => setFilterName(values.target.value)}
                  sx={{ width: '100%' }}
                  // name="endDate"
                  type="text"
                  error={notSearch}
                />
              </Box>
              <Box
                width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  variant="outlined"
                  label={!notSearch ? 'Filtrar CNPJ' : 'Empresa não encontrada'}
                  value={filterCNPJ}
                  onChange={values => setFilterCNPJ(values.target.value)}
                  sx={{ width: '100%' }}
                  // name="endDate"
                  type="text"
                  error={notSearch}
                />
              </Box>
              <Button variant="contained" onClick={() => handleClearFilter()} sx={{ mt: 2 }}>
                Limpar filtros
              </Button>
            </Box>
            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell> </StyledTableCell>
                    <StyledTableCell align="left">Nome</StyledTableCell>
                    <StyledTableCell align="left">Segmento</StyledTableCell>
                    <StyledTableCell align="left">Plano</StyledTableCell>
                    <StyledTableCell align="left">CNPJ</StyledTableCell>
                    <StyledTableCell align="left">Ativo</StyledTableCell>
                    <StyledTableCell align="left">Criado</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {company
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'left',
                              justifyContent: 'space-between',
                              width: '80px',
                            }}
                          >
                            <PermissionComponent role="CompanyDELETE">
                              <Tooltip title="Apagar" arrow>
                                <IconButton
                                  onClick={() => deleteAlert(row.companyId)}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                            </PermissionComponent>
                            <PermissionComponent role="CompanyEDIT">
                              <Tooltip title="Editar" arrow>
                                <IconButton
                                  onClick={() => handleEdit(row.companyId)}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <ModeEditOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </PermissionComponent>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.name}</StyledTableCell>
                        <StyledTableCell align="left">{row.segment}</StyledTableCell>
                        <StyledTableCell align="left">{row.planName}</StyledTableCell>
                        <StyledTableCell align="left">{row.cnpj}</StyledTableCell>
                        <StyledTableCell align="left">{row.active ? 'Sim' : 'Não'}</StyledTableCell>
                        <StyledTableCell align="left">
                          {new Date(row.created).toLocaleString()}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={company.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>

          // <Box
          //   sx={{
          //     mt:'2rem',
          //     minHeight: 600,
          //     width: '100%',
          //     '& .super-app-theme--header': {
          //       backgroundColor: colorTableDefault,
          //       width:'100%'
          //     },
          //   }}
          // >
          //   <DataGrid
          //     sx={{width:'100%'}}
          //     autoHeight
          //     // autoPageSize
          //     rows={company}
          //     columns={columns}
          //     getRowId={(row) => row.companyId}
          //     initialState={{
          //       pagination: {
          //         paginationModel: {
          //           pageSize: 5,
          //         },
          //       },
          //     }}
          //     pageSizeOptions={[5, 10, 25]}
          //     disableRowSelectionOnClick
          //     localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          //   />
          // </Box>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default Company
