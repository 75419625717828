import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  editIntegrationAssociationService,
  getIntegrationCredencialService,
} from '../../../services/configServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'

const IntegrationAssociationCreate = ({
  expanded,
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)

  const token = localStorage.getItem('token')
  const [integrationCredentialFromId, setIntegrationCredentialFromId] = useState('')
  const [integrationCredentialToId, setIntegrationCredentialToId] = useState('')
  const [integrationDefault, setIntegrationDefault] = useState([])
  const [companyId, setCompanyId] = useState('')
  const [loading, setLoading] = useState(false)

  const initialFields = {
    tokenType: false,
    name: false,
    email: false,
    userName: false,
    password: false,
    accessToken: false,
    clientToken: false,
    clientSecret: false,
    refreshToken: false,
    expiresIn: false,
    appId: false,
  }
  const [saveFields, setSaveFields] = useState(initialFields)

  const createIntegrationAssociation = async e => {
    e.preventDefault()
    setLoading(true)

    const credencial = {
      companyId,
      integrationCredentialFromId,
      integrationCredentialToId,
    }

    try {
      await editIntegrationAssociationService(token, nameUrl, credencial)
      setSeverity('success')
      setTitle('Integração cadastrada com sucesso!')
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      handleFetchData()
    } catch (err) {
      console.log(err)
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
      setLoading(false)
      setAlerta(true)
    }
  }

  const getIntegrationCredential = async () => {
    setLoading(true)
    const limit = 500
    const offset = 0
    try {
      const response = await getIntegrationCredencialService(token, nameUrl, limit, offset)
      const defaults = response.data.result
      setIntegrationDefault(defaults)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (expanded) {
      getIntegrationCredential()
    }
  }, [expanded])

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={createIntegrationAssociation}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select
                label="Empresa"
                value={companyId}
                onChange={values => setCompanyId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.companyId} key={index}>
                      {item.nameCompany}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              justifyContent: 'space-evenly',
              mt: 2,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Credencial De:</InputLabel>
              <Select
                label="Credencial de:"
                value={integrationCredentialFromId}
                onChange={values => setIntegrationCredentialFromId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {integrationDefault.map((item, index) => {
                  return (
                    <MenuItem value={item.integrationCredencialId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Credencial Para:</InputLabel>
              <Select
                label="Credencial para:"
                value={integrationCredentialToId}
                onChange={values => setIntegrationCredentialToId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {integrationDefault.map((item, index) => {
                  return (
                    <MenuItem value={item.integrationCredencialId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'center' }}>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button type="submit" variant="contained" sx={{ width: '100%' }}>
                Salvar
              </Button>
            </Box>
            <Box width={{ xs: '45%', sm: '45%', md: '20%', lg: '20%', xl: '20%' }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setExpanded(false)}
                sx={{ width: '100%', ml: 1 }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default IntegrationAssociationCreate
