import React, { useContext, useEffect } from 'react'
import { useState } from 'react'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { createProcess, createProcessSequence, getProcess } from '../../../services/processServices'
import { Context } from '../../../context/AuthContext'
import {
  createAddUSer,
  createEmailGroup,
  createEmailGroupSequence,
  getEmailGroupService,
  getUserGroupEmail,
  getUserGroupEmailService,
} from '../../../services/groupEmailService'

const EmailGroup = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
  StyledTableCell,
  StyledTableRow,
}) => {
  const token = localStorage.getItem('token')
  const { subCompany, userName, nameUrl } = useContext(Context)
  const [emailGroupName, setEmailGroupName] = useState('')
  const [emailGroupAtivo, setEmailGroupNameAtivo] = useState(true)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [userNameCompany, setUserNameCompany] = useState('')
  const [userGetGroupEmail, setUserGetGroupEmail] = useState([])
  const [userGroupEmail, setUserGroupEmail] = useState('')
  const [statusApp, setStatusApp] = useState('Pronto')
  const [oldList, setOldList] = useState([])
  const [emailUser, setEmailUser] = useState([])
  const [saving, setSaving] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [singleEmailGroupSequence, setSingleEmailGroupSequence] = useState({
    userId: '',
    nameEmployee: '',
  })

  const [sequenceList, setSequenceList] = useState([])

  const addToList = listUser => {
    const repetido = sequenceList.find(x => x.userId === listUser.userId)
    if (repetido) {
      setStatusApp('Ordem já preenchida')
      return
    } else {
      const emptyid = listUser.userId
      if (emptyid === '') {
        setStatusApp('"Ordem" inválida')
        return
      }
      const prod = {
        ...singleEmailGroupSequence,
        userId: listUser.userId,
        nameEmployee: listUser.nameEmployee,
      }
      setSequenceList(curr => [...curr, prod])
      setStatusApp('Adicionado')
    }
  }

  const resetFields = () => {
    setEmailGroupName('')
    setEmailGroupNameAtivo(true)
    setSubCompanyId('')
    setUserNameCompany('')
    setUserGetGroupEmail([])
    setUserGroupEmail('')
    setSingleEmailGroupSequence({
      name: '',
      active: true,
      filial: userNameCompany,
    })
    setSingleEmailGroupSequence(curr => ({
      ...curr,
      order: 0,
    }))
    setSequenceList([])
  }

  const getListEmailUser = async subId => {
    try {
      const data = {
        subCompanyId: subId,
      }
      const response = await getEmailGroupService(token, nameUrl, data)
      setEmailUser(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {}, [emailUser, userGroupEmail, emailGroupName, emailGroupAtivo, sequenceList])

  const getUserEmailList = async subCompanyId => {
    try {
      const data = {
        subCompanyId: subCompanyId,
        limit: 500,
        offset: 0,
      }
      const response = await getEmailGroupService(token, nameUrl, data)

      setOldList(response.data)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const saveEmailGroup = async () => {
    getUserEmailList(subCompanyId)
    sequenceList.forEach(i => {
      i.subCompanyId = subCompanyId
      i.active = true
    })
    const saveEmailGrupo = {
      name: emailGroupName,
      active: emailGroupAtivo,
      subCompanyId,
    }

    try {
      setSaving(true)
      setStatusApp('Criando Grupo de Email...')
      const response = await createEmailGroup(token, nameUrl, saveEmailGrupo)

      const dataEmailGroupList = {
        subCompanyId,
        limit: 500,
        offset: 0,
      }
      const response2 = await getEmailGroupService(token, nameUrl, dataEmailGroupList)

      const userGroup = sequenceList

      const newList = userGroup.map(usuario => ({
        emailGroupUserId: usuario.userId,
        emailGroupId: response.data.emailGroupId,
      }))

      await createAddUSer(token, nameUrl, newList)

      handleFetchData()
      resetFields()
      setExpanded(false)
      setSeverity('success')
      setTitle('Grupo de Email criado com sucesso!')
      setAlerta(true)
      setSaving(false)
    } catch (err) {
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
      setSaving(false)
      resetFields()
      setExpanded(false)
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {}, [])
  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} sx={{ width: '100%' }}>
            Criando com Sucesso
          </Alert>
        </Snackbar>
        <Box component="form" sx={{ mt: '2rem', width: 1 }} onSubmit={createProcess}>
          <Box sx={{ display: { md: 'flex' }, gap: 2, alignItems: 'center' }}>
            <Box sx={{ width: { xs: 1, md: 0.3 } }}>
              <InputLabel id="demo-simple-select-label1">Filial</InputLabel>
              <Select
                value={subCompanyId}
                sx={{ width: '100%' }}
                onChange={values => {
                  setSubCompanyId(values.target.value)
                  getListEmailUser(values.target.value)
                }}
              >
                <MenuItem value="" disabled selected>
                  Filial
                </MenuItem>
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box sx={{ width: { xs: 1, md: 0.4 } }}>
              <InputLabel>Nome do Grupo de Email</InputLabel>
              <TextField
                //sx={{ width: '100%' }}
                value={emailGroupName}
                onChange={values => setEmailGroupName(values.target.value)}
                id="outlined-basic"
                // label="Nome Processo"
                variant="outlined"
                type="text"
                sx={{ width: 1 }}
              />
            </Box>
            <Box sx={{ width: { xs: 1, md: 0.1 } }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={emailGroupAtivo}
                      onChange={event => setEmailGroupNameAtivo(event.target.checked)}
                    />
                  }
                  label="Ativo"
                />
              </FormGroup>
            </Box>
          </Box>
          <Box
            sx={{
              width: 1,
              mt: 2,
              display: 'flex',
              gap: 2,
              border: '1px solid black',
              borderRadius: 2,
              p: 2,
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Escolher Usuário</InputLabel>
                    <Select
                      value={userGroupEmail}
                      label="Escolher o Usuário"
                      onChange={event => setUserGroupEmail(event.target.value)}
                    >
                      {emailUser.map((user, index) => (
                        <MenuItem key={index} value={user}>
                          {user.nameEmployee}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={statusApp}
                    id="outlined-basic"
                    variant="outlined"
                    label="Status"
                    type="text"
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: 1, display: 'flex', gap: 2 }}>
              <Button variant="outlined" onClick={() => addToList(userGroupEmail)}>
                Adicionar Grupo de Email
              </Button>
            </Box>
          </Box>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell sx={{ width: 0.2 }} align="center"></StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {sequenceList.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setSequenceList(curr => curr.filter((v, i) => i !== index))}
                    >
                      <DeleteForeverIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.nameEmployee}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="outlined" onClick={saveEmailGroup} disabled={saving} sx={{ mt: 2 }}>
            Salvar
          </Button>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={saving}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default EmailGroup
